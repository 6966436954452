/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));


const FORMAT_DATE = "dd-MM-yyyy";

const ListFormReportModal = ({ visible, listForm, closeModal, onChoose }) => {
  const customStyles = {
    content: {
      width: "30%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      title={'Chọn loại báo cáo'}
      centered
      open={visible}
      width={"30%"}
      style={{ top: 20 }}
      footer={null} 
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
          {listForm?.map((form) => {
            return (
              <div
                className="d-flex px-3 py-3"
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={(e) => {
                  onChoose(form);
                }}
              >
                {form?.title?.toUpperCase()}
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
      </div>
    </Modal>
    // <Modal
    //   isOpen={visible}
    //   onRequestClose={closeModal}
    //   contentLabel="My dialog"
    //   className="my-modal"
    //   overlayClassName="my-modal-overlay"
    //   style={customStyles}
    // >
    //   <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
    //     <h4 style={{ color: "white" }}>Chọn loại báo cáo</h4>
    //     <button type="button" className="close" onClick={closeModal}>
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //   </div>
    //   <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
    //     {listForm?.map((form) => {
    //       return (
    //         <div
    //           className="d-flex px-3 py-3"
    //           style={{ cursor: "pointer", fontWeight: "bold" }}
    //           onClick={(e) => {
    //             onChoose(form);
    //           }}
    //         >
    //           {form?.title?.toUpperCase()}
    //         </div>
    //       );
    //     })}
    //   </div>
    //   <div class="modal-footer">
    //     <button
    //       type="button"
    //       class="btn btn-default"
    //       data-dismiss="modal"
    //       onClick={closeModal}
    //     >
    //       Đóng
    //     </button>
    //   </div>
    // </Modal>
  );
};

export default ListFormReportModal;
