import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { DatePicker, SelectPicker } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { shiftServices } from "../../services/shiftServices";
import { useParams } from "react-router-dom";
import { constants } from "../../constants";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Switch from "react-ios-switch";
import { toast } from "react-toastify";
import StaffStatusModal from "./child/StaffStatusModal";
import StaffStatusWatchedModal from "./child/StaffStatusWatchedModal";
import dayjs from "dayjs";
import { isToday } from "date-fns";
import { Button, Space } from "antd";

const ContractEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const area_id = queryParams.get("area_id");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const [types, setTypes] = useState([]);
  const [total_salary, setTotalSalary] = useState("");
  const [support_productive, setSupportProductive] = useState("");
  const [support_result, setSupportResult] = useState("");
  const [pc_food, setPcFood] = useState("");
  const [pc_phone, setPcPhone] = useState("");
  const [pc_move, setPcMove] = useState("");
  const [salary, setSalary] = useState("");
  const [salaryPosion, setSalaryPosion] = useState("");
  const [seniority_limit, setSeniorityLimit] = useState("");
  const [dependentAnother, setDependentAnother] = useState("");
  const [tax, setTax] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const [optionLabel, setOptionLabel] = useState({
    typeLabel: "",
    salaryLabel: "",
  });
  const [contactDetail, setContactDetail] = useState(null);
  const [isCheckedBHXH, setIsCheckedBHXH] = useState(false);
  const [isChooseGT, setIsCheckedGT] = useState(false);
  const [isChooseCK, setIsCheckedCK] = useState(false);
  const [leave, setLeave] = useState(false);
  const [probation, setProbation] = useState(false);
  const [congdoan, setCongDoan] = useState(false);
  const [supportConfig, setSupportConfig] = useState([]);
  const [spConfigChooses, setSpConfigChooses] = useState([]);

  const [totalSalaryFirst, setTotalSalaryFirst] = useState(null);
  const [bhxhFirst, setBhxhFirst] = useState(null);
  const [supportsFirst, setSupportsFirst] = useState(null);
  const [supportProductiveFirst, setSupportProductiveFirst] = useState(null);
  const [supportResultFirst, setSupportResultFirst] = useState(null);

  useEffect(() => {
    fetchOptionContact();
    fetchContactDetail();
    fetchSupportConfig();
    getStaffStatus();
  }, []);

  const fetchSupportConfig = async () => {
    try {
      const response = await shiftServices.fetchSupportConfig(areaId);
      setSupportConfig(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOptionContact = async () => {
    try {
      const response = await shiftServices.fetchOptionContact(areaId);

      const listType = response.data.type.map((type) => ({
        label: type.label,
        value: type.key,
      }));
      setTypes(listType);

      const listInsurance = response.data.insurance.map((insurance) => ({
        label: insurance.label,
        value: insurance.key,
      }));

      const listTax = response.data.tax.map((tax) => ({
        label: tax.label,
        value: tax.key,
      }));
      setTax(listTax);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContactDetail = async () => {
    try {
      const response = await shiftServices.fetchContactDetail(areaId, id);
      setContactDetail(response.data);

      let listKey = [];
      if (response.data === null) {
        listKey = ["BHTN", "BHXH", "BHYT"];
      } else {
        reset({
          ...response.data,
          start:
            response.data.start &&
            !isNaN(new Date(response.data.start).getTime())
              ? new Date(response.data.start)
              : null,
          end:
            response.data.end && !isNaN(new Date(response.data.end).getTime())
              ? new Date(response.data.end)
              : null,
          dependent_count:
            response.data.dependent_count === null
              ? 0
              : response.data.dependent_count,
          salary:
            response.data.salary_key === "day" ? "" : response.data.salary,
          total_salary:
            response.data.salary_key === "hour"
              ? ""
              : response.data.total_salary,
          position_salary: response.data.position_salary,
          support_result:
            response.data.salary_key === "hour"
              ? ""
              : response.data.support_result,
          support_productive:
            response.data.salary_key === "hour"
              ? ""
              : response.data.support_productive,
          pc_food: response.data.pc_food,
          pc_phone: response.data.pc_phone,
          pc_move: response.data.pc_move,
          kpi_percent: response.data.kpi_percent,
          annual_leave: response.data.annual_leave,
          unpaid_leave: response.data.unpaid_leave,
          compassionate_leave: response.data.compassionate_leave,
          marriage_leave: response.data.marriage_leave,
          seniority_percent: response.data.seniority_percent,
          supports: response.data.supports.map((suport) => suport.id),
        });
        setLeave(response.data.leave ?? false);
        setProbation(response.data.probation ?? false);
        setDependentAnother(formatNumberV2(response.data.dependent_another));
        setSalary(formatNumberV2(response.data.salary));
        setTotalSalary(formatNumberV2(response.data.total_salary));
        setSupportProductive(formatNumberV2(response.data.support_productive));
        setSupportResult(formatNumberV2(response.data.support_result));
        setPcFood(formatNumberV2(response.data.pc_food));
        setPcPhone(formatNumberV2(response.data.pc_phone));
        setPcMove(formatNumberV2(response.data.pc_move));
        setSeniorityLimit(
          formatNumberV2(Math.ceil(response.data.seniority_limit ?? 0))
        );
        setSalaryPosion(formatNumberV2(response.data.position_salary));
        setSpConfigChooses(response.data.supports.map((suport) => suport.id));
        setOptionLabel({
          typeLabel: response.data.type_label,
          salaryLabel: response.data.salary_label,
        });
        response.data.BHTN && listKey.push("BHTN");
        response.data.BHXH && listKey.push("BHXH");
        response.data.BHYT && listKey.push("BHYT");
        setIsCheckedBHXH(response.data.BHXH);
        setIsCheckedGT(response.data.tax === "LT");
        setIsCheckedCK(response.data.tax === "CK");
        setTotalSalaryFirst(response.data.total_salary);
        setSupportProductiveFirst(response.data.support_productive);
        setSupportResultFirst(response.data.support_result);
        setBhxhFirst(response.data.BHXH);
        setSupportsFirst(response.data.supports.map((suport) => suport.id));
        setCongDoan(response.data.cong_doan);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFotmat = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name);
    if (name == "total_salary") {
      setTotalSalary(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "pc_food") {
      setPcFood(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "pc_phone") {
      setPcPhone(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "pc_move") {
      setPcMove(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "support_result") {
      setSupportResult(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "support_productive") {
      setSupportProductive(value == 0 ? 0 : formatNumberV2(value));
    }
    if (name == "salary") {
      setSalary(value == 0 ? 0 : formatNumberV2(value));
    }

    if (name == "position_salary") {
      setSalaryPosion(value == 0 ? 0 : formatNumberV2(value));
    }
    if (name == "seniority_limit") {
      setSeniorityLimit(value == 0 ? 0 : formatNumberV2(value));
    }
    if (name == "dependent_another") {
      setDependentAnother(value == 0 ? 0 : formatNumberV2(value));
    }
  };

  const formatNumberV2 = (str) => {
    if (str === undefined || str === null) return "";
    const strFormat = str
      .toString()
      .replace(/[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "");
    if (Number(strFormat) >= 1000) {
      return strFormat
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ".") + prev;
        });
    } else if (Number(strFormat) > 0 && Number(strFormat) < 1000) {
      return Number(strFormat);
    } else {
      return "";
    }
  };

  const deleteTimekeepingStaff = async (staffId) => {
    try {
      const response = await shiftServices.deleteTimekeepingStaff({
        body: {
          list_staff_id: [staffId],
        },
        areaId: areaId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values, isForce) => {
    try {
      const body = {
        staff_id: id,
        type_key: values.type_key,
        type_label: optionLabel.typeLabel,
        salary_key: getValues("type_key") === "part_time" ? "hour" : "day",
        salary_label: getValues("type_key") === "part_time" ? "Giờ" : "Ngày",
        start: moment(values.start || new Date()).format(
          constants.FORMAT_YYYY_MM_DD
        ),
        end: moment(values.end || new Date()).format(
          constants.FORMAT_YYYY_MM_DD
        ),
        images: [],
        BHXH: isForce == true ? values.BHXH : isCheckedBHXH,
        BHYT: isForce == true ? values.BHXH : isCheckedBHXH,
        BHTN: isForce == true ? values.BHXH : isCheckedBHXH,
        tax: values.tax,
        kpi_percent: values.kpi_percent,
        seniority_percent: values.seniority_percent,
        dependent_count: values.dependent_count,
        annual_leave: values.annual_leave == "" ? 0 : values.annual_leave,
        unpaid_leave: values.unpaid_leave == "" ? 0 : values.unpaid_leave,
        compassionate_leave:
          values.compassionate_leave == "" ? 0 : values.compassionate_leave,
        marriage_leave: values.marriage_leave == "" ? 0 : values.marriage_leave,
        position_salary:
          isForce == true
            ? values.position_salary !== null && values.position_salary !== ""
              ? values.position_salary.toString().replaceAll(".", "")
              : null
            : salaryPosion !== null && salaryPosion !== ""
            ? salaryPosion.toString().replaceAll(".", "")
            : null,
        salary:
          getValues("type_key") === "part_time"
            ? salary !== null && salary !== ""
              ? parseFloat(salary.toString().replaceAll(".", ""))
              : null
            : null,

        seniority_limit:
          getValues("type_key") === "part_time"
            ? seniority_limit !== null && seniority_limit !== ""
              ? seniority_limit.toString().replaceAll(".", "")
              : 0
            : 0,
        dependent_another:
          values.tax === "LT"
            ? dependentAnother !== null && dependentAnother !== ""
              ? parseFloat(dependentAnother.toString().replaceAll(".", "") || 0)
              : null
            : 0,
        total_salary:
          isForce == true
            ? values.total_salary
            : getValues("type_key") === "part_time"
            ? null
            : total_salary !== null && total_salary !== ""
            ? total_salary.toString().replaceAll(".", "")
            : null,
        support_productive:
          isForce == true
            ? values.support_productive
            : getValues("type_key") === "part_time"
            ? null
            : support_productive !== null && support_productive !== ""
            ? support_productive.toString().replaceAll(".", "")
            : null,
        support_result:
          isForce == true
            ? values.support_result
            : getValues("type_key") === "part_time"
            ? null
            : support_result !== null && support_result !== ""
            ? support_result.toString().replaceAll(".", "")
            : null,
        pc_food:
          getValues("type_key") === "part_time"
            ? null
            : pc_food !== null && pc_food !== ""
            ? pc_food.toString().replaceAll(".", "")
            : null,
        pc_phone:
          getValues("type_key") === "part_time"
            ? null
            : pc_phone !== null && pc_phone !== ""
            ? pc_phone.toString().replaceAll(".", "")
            : null,
        pc_move:
          getValues("type_key") === "part_time"
            ? null
            : pc_move !== null && pc_move !== ""
            ? pc_move.toString().replaceAll(".", "")
            : null,
        supports: isForce == true ? values.supports : spConfigChooses,
        leave: leave,
        probation: probation,
        cong_doan: congdoan,
      };
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      if (leave == true) {
        deleteTimekeepingStaff(id);
      }

      const response =
        contactDetail === null
          ? await shiftServices.createContact(body, area_id)
          : await shiftServices.updateContact(
              body,
              contactDetail.area_id,
              contactDetail.id
            );

      if (response.success) {
        setContactDetail(response.data);
        // toast.success(response.msg);
        // history.goBack();
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
    }
  };
  /// change pending
  const [staffStatus, setStaffStatus] = useState([]);
  const [modalData, setModalData] = useState({
    method: null,
    visible: false,
  });

  const checkStaffStatus = async (values) => {
    var listChange = [];

    values.total_salary =
      total_salary !== null && total_salary !== ""
        ? total_salary.toString().replaceAll(".", "")
        : null;

    values.support_productive =
      support_productive !== null && support_productive !== ""
        ? support_productive.toString().replaceAll(".", "")
        : null;

    values.support_result =
      support_result !== null && support_result !== ""
        ? support_result.toString().replaceAll(".", "")
        : null;

    values.BHXH = isCheckedBHXH;
    values.supports = spConfigChooses;

    if (values.total_salary != totalSalaryFirst) {
      listChange.push({
        content: `Lương đang chờ thay đổi --> ${formatNumberV2(
          values.total_salary
        )}`,
        total_salary_to: values.total_salary,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: true,
      });
    }

    if (values.support_productive != supportProductiveFirst) {
      listChange.push({
        content: `Lương P2 đang chờ thay đổi --> ${formatNumberV2(
          values.support_productive
        )}`,
        support_productive_to: values.support_productive,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: true,
      });
    }
    console.log(values.support_result);
    console.log(supportResultFirst);
    if ((values.support_result ?? '') != (supportResultFirst ?? '')) {
      listChange.push({ 
        content: `Lương P3 đang chờ thay đổi --> ${formatNumberV2(
          values.support_result
        )}`,
        support_result_to: values.support_result,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: true,
      });   
    }

    if (values.BHXH != bhxhFirst) {
      listChange.push({
        content: `BHXH đang chờ thay đổi --> ${
          values.BHXH == true ? "Có BHXH" : "Không BHXH"
        }`,
        BHXH_to: values.BHXH,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: false,
      });
    }

    if (JSON.stringify(values.supports) != JSON.stringify(supportsFirst)) {
      listChange.push({
        content: `Phụ cấp đang chờ thay đổi --> ${values.supports
          .map((e) => supportConfig.filter((s) => s.id == e)[0]?.title)
          .join(", ")}`,
        supports_to: values.supports,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: false,
      });
    }

    if (contactDetail == null) {
      await onSubmit(values);
      toast.success("Cập nhật thành công");
      history.goBack();
      return;
    }

    if (
     ( values.total_salary ?? '') != (totalSalaryFirst ?? '') ||
      (values.BHXH ?? '') != (bhxhFirst ?? '') ||
      JSON.stringify(values.supports) != JSON.stringify(supportsFirst) ||
      (values.support_productive ?? '') !=( supportProductiveFirst ?? '') ||
      (values.support_result ?? '') != (supportResultFirst ?? '') 
    ) {
      setModalData({
        method: "STATUS",
        visible: true,
        listChange: listChange,
      });
    } else {
      onSubmit(values);
    }
  };

  const handleCreateSalary = async () => {
    try {
      const salaryBody = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        list_data: [
          {
            staff_id: id,
            kpi_percent: 100,
            another_plus: 0,
            another_minus: 0,
            use_education_kpi: true,
            BHXH: 0,
            tax: 0,
            list_support_default_id: [],
            list_bonus_default_id: [],
            list_bonus: [],
          },
        ],
      };
      const response = await shiftServices.createSalary({
        areaId: areaId,
        body: salaryBody,
      });

      if (response.success) {
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("Lỗi khi tạo bảng lương");
    } finally {
      // dispatch({
      //   type: constants.CHANGE_LOADING,
      //   typeLoading: constants.NONE_LOADING,
      // });
    }
  };

  const addStaffStatus = async (
    effective_date,
    is_save_salary_table,
    values,
    content
  ) => {
    try {
      const body = {
        total_salary_to: values.total_salary_to,
        support_productive_to: values.support_productive_to,
        support_result_to: values.support_result_to,
        BHXH_to: values.BHXH_to,
        supports_to: values.supports_to,
        staff_id: id,
        content: content,
        is_save_salary_table: is_save_salary_table,
        effective_date: effective_date?.format("YYYY-MM-DD"),
      };
      const response = await shiftServices.addStaffStatus(body);
    } catch (error) {
      console.log(error);
    }
  };

  const getStaffStatus = async () => {
    try {
      const response = await shiftServices.getStaffStatus(id);
      setStaffStatus(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStaffStatus = async (id) => {
    try {
      const response = await shiftServices.deleteStaffStatus(id);
      getStaffStatus();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center">
        <Space size="large">
          <h4 className="h4 title_content mb-0 text-gray-800">
            Chỉnh sửa hợp đồng
          </h4>
          {getValues("type_key") === "part_time" ? (
            <></>
          ) : (
            <p>
              {`Tổng lương ${formatNumberV2(
                parseFloat(support_productive ?? 0) +
                  parseFloat(support_result ?? 0) +
                  parseFloat(pc_food ?? 0) +
                  parseFloat(pc_phone ?? 0) +
                  parseFloat(pc_move ?? 0),
                true
              )}`}
            </p>
          )}
        </Space>
        <div className="d-flex align-items-center">
          {(staffStatus ?? []).length > 0 && (
            <Button
              style={{ color: "#1ecc78", borderColor: "#1ecc78" }}
              onClick={(e) => {
                setModalData({
                  method: "STATUS_WATCHED",
                  visible: true,
                  data: staffStatus,
                });
              }}
            >{`Đang chờ thay đổi`}</Button>
          )}

          <div style={{ width: "10px" }}></div>

          <div
            style={{ color: "red", fontWeight: "700" }}
          >{`Cập nhật gần nhất: ${moment
            .utc(contactDetail?.updated_at)
            .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}`}</div>
        </div>
      </div>
      <div className="card shadow mb-4 p-4">
        <form onSubmit={handleSubmit(checkStaffStatus)}>
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <label>Loại hợp đồng*</label>
                <Controller
                  name="type_key"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        data={types}
                        placeholder="-- Chọn loại hợp đồng --"
                        block
                        cleanable={false}
                        value={value}
                        onSelect={(val, item) => {
                          onChange(val);
                          // if (val === "full_time") {
                          //   setValue("count_day_work", "");
                          // }
                          setOptionLabel({
                            ...optionLabel,
                            typeLabel: item.label,
                          });
                        }}
                      />
                    );
                  }}
                />
                <div className="error-message">{errors?.type_key?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày bắt đầu*</label>
                <div>
                  <Controller
                    name="start"
                    control={control}
                    rules={{ required: "Không được rỗng" }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          cleanable={false}
                          block
                          value={value}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                </div>
                <div className="error-message">{errors?.start?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày kết thúc*</label>
                <div>
                  <Controller
                    name="end"
                    control={control}
                    rules={{ required: "Không được rỗng" }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          cleanable={false}
                          block
                          value={value}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                  <div className="error-message">{errors?.end?.message}</div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>% Lương tính vào KPI*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("kpi_percent", {
                    required: "Không được rỗng",
                  })}
                />
                <div className="error-message">
                  {errors?.kpi_percent?.message}
                </div>
              </div>
            </div>
            {getValues("type_key") === "part_time" ? (
              <div
                className="col-3"
                style={{
                  display:
                    getValues("type_key") === "part_time" ? "block" : "none",
                }}
              >
                <div className="form-group">
                  <label>Mức lương theo giờ*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="salary"
                    placeholder=""
                    autoComplete="off"
                    value={salary}
                    {...register("salary", {
                      required:
                        getValues("type_key") === "part_time"
                          ? "Không được rỗng"
                          : false,
                    })}
                    onChange={handleChangeFotmat}
                    disabled={getValues("type_key") !== "part_time"}
                  />
                  <div className="error-message">{errors?.salary?.message}</div>
                </div>
              </div>
            ) : (
              <>
                {/* <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Tổng mức lương*</label>
                    <input
                      type="text"
                      name="total_salary"
                      className="form-control"
                      placeholder=""
                      value={total_salary}
                      autoComplete="off"
                      {...register("total_salary", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.total_salary?.message}
                    </div>
                  </div>
                </div> */}
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Mức lương P2*</label>
                    <input
                      type="text"
                      name="support_productive"
                      className="form-control"
                      placeholder=""
                      value={support_productive}
                      autoComplete="off"
                      {...register("support_productive", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.support_productive?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Mức lương P3*</label>
                    <input
                      type="text"
                      name="support_result"
                      className="form-control"
                      placeholder=""
                      value={support_result}
                      autoComplete="off"
                      // {...register("support_result", {
                      //   required: "Không được rỗng",
                      // })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.support_result?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp cơm*</label>
                    <input
                      type="text"
                      name="pc_food"
                      className="form-control"
                      placeholder=""
                      value={pc_food}
                      autoComplete="off"
                      {...register("pc_food", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_food?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp điện thoại*</label>
                    <input
                      type="text"
                      name="pc_phone"
                      className="form-control"
                      placeholder=""
                      value={pc_phone}
                      autoComplete="off"
                      {...register("pc_phone", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_phone?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp đi lại*</label>
                    <input
                      type="text"
                      name="pc_move"
                      className="form-control"
                      placeholder=""
                      value={pc_move}
                      autoComplete="off"
                      {...register("pc_move", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_move?.message}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-3">
              <div className="form-group">
                <label>Bảo hiểm</label>
                <div className="mb-2">
                  <input
                    type="checkbox"
                    checked={isCheckedBHXH}
                    onChange={(e) => setIsCheckedBHXH(e.target.checked)}
                  />
                  <span> BHXH</span>
                </div>
                {getValues("type_key") === "part_time" ? (
                  <></>
                ) : (
                  <div className="form-group">
                    <label>Lương vị trí (P1)*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="position_salary"
                      value={salaryPosion}
                      autoComplete="off"
                      {...register("position_salary", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.position_salary?.message}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label>Thuế*</label>
                {tax?.map((tax, index) => {
                  return (
                    <div key={index} className="mb-2">
                      <input
                        type="radio"
                        value={tax.value}
                        {...register("tax", {
                          required: "Phải chọn đối thuế",
                        })}
                        onChange={() => {
                          if (tax.value == "LT") {
                            setIsCheckedGT(true);
                          } else {
                            setIsCheckedGT(false);
                          }

                          if (tax.value == "CK") {
                            setIsCheckedCK(true);
                          } else {
                            setIsCheckedCK(false);
                          }
                        }}
                      />
                      <span> {tax.label}</span>
                    </div>
                  );
                })}
                <div className="error-message">{errors?.tax?.message}</div>
                <div
                  className="col"
                  style={{
                    display: isChooseCK === false ? "block" : "none",
                  }}
                >
                  <div className="form-group">
                    <label>Khoản miễn thuế khác</label>
                    <input
                      type="text"
                      className="form-control"
                      name="dependent_another"
                      value={dependentAnother}
                      placeholder=""
                      autoComplete="off"
                      {...register("dependent_another")}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.dependent_another?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  style={{
                    display: isChooseGT === true ? "block" : "none",
                  }}
                >
                  <div className="form-group">
                    <label>Số người phụ thuộc</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      {...register("dependent_count", {
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Phải là số nguyên dương",
                        },
                      })}
                    />
                    <div className="error-message">
                      {errors?.dependent_count?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Phụ cấp</label>
                {supportConfig?.map((sp, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <input
                        type="checkbox"
                        checked={spConfigChooses.includes(sp?.id)}
                        onChange={(e) => {
                          if (spConfigChooses.includes(sp?.id) === true) {
                            setSpConfigChooses(
                              spConfigChooses.filter((e) => e !== sp?.id)
                            );
                          } else {
                            setSpConfigChooses([...spConfigChooses, sp?.id]);
                          }
                        }}
                      />
                      <span> {sp?.title}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            {getValues("type_key") === "part_time" ? (
              <div className="col-3">
                <div className="form-group">
                  <label>% Thâm niên</label>
                  <input
                    name="seniority_percent"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("seniority_percent", {
                      required: "Không được rỗng",
                    })}
                  />
                  <div className="error-message">
                    {errors?.seniority_percent?.message}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {getValues("type_key") === "part_time" ? (
              <div className="col-3">
                <div className="form-group">
                  <label>Giới hạn lương thâm niên</label>
                  <input
                    type="text"
                    name="seniority_limit"
                    className="form-control"
                    placeholder=""
                    value={seniority_limit}
                    autoComplete="off"
                    {...register("seniority_limit", {
                      required: "Không được rỗng",
                    })}
                    onChange={handleChangeFotmat}
                  />
                  <div className="error-message">
                    {errors?.seniority_limit?.message}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ có lương*</label>
                <input
                  name="annual_leave"
                  step="any"
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("annual_leave")}
                />
                <div className="error-message">
                  {errors?.annual_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ không lương*</label>
                <input
                  name="unpaid_leave"
                  min="0"
                  step="any"
                  type="number"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("unpaid_leave")}
                />
                <div className="error-message">
                  {errors?.unpaid_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ tang*</label>
                <input
                  name="compassionate_leave"
                  type="number"
                  min="0"
                  step="any"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("compassionate_leave")}
                />
                <div className="error-message">
                  {errors?.compassionate_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày kết hôn*</label>
                <input
                  name="marriage_leave"
                  type="number"
                  step="any"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("marriage_leave")}
                />
                <div className="error-message">
                  {errors?.marriage_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="w-100 align-items-start d-flex justify-content-between  ">
                <div
                  className="color-gray-10 fw-semi-bold"
                  style={{
                    width: "25%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                  }}
                >
                  Nghỉ việc&nbsp;
                </div>
                <div
                  style={{
                    width: "75%",
                    alignSelf: "center",
                    display: "flex",
                  }}
                >
                  <Switch
                    checked={leave}
                    onChange={(checked) => {
                      if (leave === true) {
                        setLeave(false);
                      } else {
                        setLeave(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="w-100 align-items-start d-flex justify-content-between">
                <div
                  className="color-gray-10 fw-semi-bold"
                  style={{
                    width: "25%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                  }}
                >
                  Thử việc&nbsp;
                </div>
                <div
                  style={{
                    width: "75%",
                    alignSelf: "center",
                    display: "flex",
                  }}
                >
                  <Switch
                    checked={probation}
                    onChange={(checked) => {
                      if (probation === true) {
                        setProbation(false);
                      } else {
                        setProbation(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="w-100 align-items-start d-flex justify-content-between">
                <div
                  className="color-gray-10 fw-semi-bold"
                  style={{
                    marginTop: "10px", 
                    marginBottom: "10px", 
                    fontWeight: "700",
                  }}
                >
                  Công đoàn&nbsp;
                </div>
                <div
                  style={{
                    width: "75%",
                    alignSelf: "center",
                    display: "flex",
                  }}
                >
                  <Switch
                    checked={congdoan}
                    onChange={(checked) => {
                      if (congdoan === true) {
                        setCongDoan(false);
                      } else {
                        setCongDoan(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-3"></div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success mr-2"
              style={{ fontSize: "14px" }}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>Cập nhật hợp
              đồng
            </button>
          </div>
        </form>
      </div>
      <StaffStatusModal
        open={modalData.visible && modalData.method === "STATUS"}
        supportConfig={supportConfig}
        onOk={async (listChange) => {
          const formValues = getValues();

          /// lấy các trường có thể thay đổi trừ total_salary, BHXH, supports

          var formToDay = {
            ...formValues,
            total_salary: null,
            support_productive: null,
            support_result: null,
            BHXH: null,
            supports: null,
          };

          /// lọc list có thay đổi ngày hôm nay

          var listChangeToDay = listChange.filter((e) => {
            return isToday(new Date(e.effective_date));
          });

          console.log(listChangeToDay);

          /// tìm các trường cập nhật ngày hôm nay

          var totalSalaryToDay = listChangeToDay.find((e) => {
            return e.total_salary_to != null;
          });

          var totalSupportProductiveToDay = listChangeToDay.find((e) => {
            return e.support_productive_to != null;
          });

          var totalSupportResultToDay = listChangeToDay.find((e) => {
            return e.support_result_to != null;
          });

          var bhxhToDay = listChangeToDay.find((e) => {
            return e.BHXH_to != null;
          });

          var supportsToDay = listChangeToDay.find((e) => {
            return e.supports_to != null;
          });

          var createSalarys = listChangeToDay.filter((e) => {
            return e.is_save_salary_table == true;
          });

          if (createSalarys != null && createSalarys.length > 0) {
            await handleCreateSalary();
          }

          if (totalSalaryToDay != null) {
            formToDay = {
              ...formToDay,
              total_salary: totalSalaryToDay.total_salary_to,
            };
            setTotalSalary(totalSalaryToDay.total_salary_to);
          } else {
            formToDay = {
              ...formToDay,
              total_salary: totalSalaryFirst,
            };
            setTotalSalary(totalSalaryFirst);
          }

          if (totalSupportProductiveToDay != null) {
            formToDay = {
              ...formToDay,
              support_productive:
                totalSupportProductiveToDay.support_productive_to,
            };
            setSupportProductive(
              totalSupportProductiveToDay.support_productive_to
            );
          } else {
            formToDay = {
              ...formToDay,
              support_productive: supportProductiveFirst,
            };
            setSupportProductive(supportProductiveFirst);
          }

          if (totalSupportResultToDay != null) {
            formToDay = {
              ...formToDay,
              support_result: totalSupportResultToDay.support_result_to,
            };
            setSupportResult(totalSupportResultToDay.support_result_to);
          } else {
            formToDay = {
              ...formToDay,
              support_result: supportResultFirst,
            };
            setSupportResult(supportResultFirst);
          }

          if (bhxhToDay != null) {
            formToDay = {
              ...formToDay,
              BHXH: bhxhToDay.BHXH_to,
              BHTN: bhxhToDay.BHXH_to,
              BHYT: bhxhToDay.BHXH_to,
            };
            setIsCheckedBHXH(bhxhToDay.BHXH_to);
          } else {
            formToDay = {
              ...formToDay,
              BHXH: bhxhFirst,
              BHTN: bhxhFirst,
              BHYT: bhxhFirst,
            };
            setIsCheckedBHXH(bhxhFirst);
          }

          if (supportsToDay != null) {
            formToDay = {
              ...formToDay,
              supports: supportsToDay.supports_to,
            };
            setSpConfigChooses(supportsToDay.supports_to);
          } else {
            formToDay = {
              ...formToDay,
              supports: supportsFirst,
            };
            setSpConfigChooses(supportsFirst);
          }

          /// cập nhật hđ ngày hôm nay

          console.log(formToDay);

          await onSubmit(formToDay, true);

          /// lọc list không có thay đổi ngày hôm nay

          var listChangeNotToDay = listChange.filter((e) => {
            return !isToday(new Date(e.effective_date));
          });

          /// cập nhật hđ không phải ngày hôm nay

          await Promise.all(
            listChangeNotToDay.map((e) => {
              if (e.total_salary_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    BHXH_to: null,
                    supports_to: null,
                    total_salary_to: e.total_salary_to,
                    support_productive_to: null,
                    support_result_to: null,
                  },
                  e.content
                );
              }

              if (e.support_productive_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    BHXH_to: null,
                    supports_to: null,
                    support_productive_to: e.support_productive_to,
                    total_salary_to: null,
                    support_result_to: null,
                  },
                  e.content
                );
              }

              if (e.support_result_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    BHXH_to: null,
                    supports_to: null,
                    support_productive_to: null,
                    total_salary_to: null,
                    support_result_to: e.support_result_to,
                  },
                  e.content
                );
              }

              if (e.BHXH_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    supports_to: null,
                    total_salary_to: null,
                    BHXH_to: e.BHXH_to,
                    support_productive_to: null,
                    support_result_to: null,
                  },
                  e.content
                );
              }

              if (e.supports_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    total_salary_to: null,
                    BHXH_to: null,
                    supports_to: e.supports_to,
                    support_productive_to: null,
                    support_result_to: null,
                    content: e.content,
                  },
                  e.content
                );
              }
            })
          ).catch((error) => {
            console.error("Có lỗi xảy ra khi chạy các hàm gọi API", error);
          });

          /// cập nhật trạng thái hợp đồng

          setTimeout(() => {
            getStaffStatus();
            fetchContactDetail();
          }, 1000);

          toast.success("Cập nhật thành công");

          setModalData({
            visible: false,
            method: "",
          });
        }}
        listChangeInput={modalData.listChange}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></StaffStatusModal>
      <StaffStatusWatchedModal
        open={modalData.visible && modalData.method === "STATUS_WATCHED"}
        onOk={(id) => {
          deleteStaffStatus(id);
        }}
        listChangeInput={modalData.data}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></StaffStatusWatchedModal>
    </div>
  );
};

export default ContractEdit;
