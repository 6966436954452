import React from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");

const DeleteModal = ({ visible, title, closeModal, onSubmit }) => {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        Bạn có chắc chắn muốn xoá bảng lương này không
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Đóng
        </button>
        <button type="button" className="btn btn-info" onClick={onSubmit}>
          Xóa
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
