import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export default function Item(props) {
  const { v, index } = props;



  return (
    <tr className="hover-product even">
      <td className="sorting_1">{index}</td>
      <td className="sorting_1">
      <Link  to={`/staff/${v.user?.id}?key=staff`}>{v.user?.staff_code}</Link>
        </td>
      <td className="sorting_1">
      <Link  to={`/staff/${v.user?.id}?key=staff`}>{v.user?.name}</Link>

       </td>
      <td className="sorting_1">{v.area?.name}</td>

      <td className="sorting_1">{v.feedback}</td>
      <td className="sorting_1">
        <img
          src={v.image_url}
          class="img-responsive"
          alt="Image"
          width="100px"
          height="100px"
        />
      </td>
      <td className="sorting_1">
        {moment(v.created_at).format("DD-MM-YYYY HH:mm:ss")}
      </td>
    </tr>
  );
}
