import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { BiExport } from "react-icons/bi";
import { BiImport } from "react-icons/bi";
import { Space, Table, Button, Popconfirm, Input } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { evaluationServices } from "../../../services/evaluationServices";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PermissionChecker from "../../../components/PermissionChecker";
import AddQCEvaluateModal from "./child/AddQuestionPeriModal";
import ImportExcelModel from "./child/ImportExcelModel";
import { exportExcel, readFile } from "../../../helpers/sheet";

export default function QCEvaluatePage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const fileElm = React.useRef(null);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [qcEvaluate, setQCEvaluate] = useState(null);
  const [loading, setLoading] = useState(null);
  const { id } = useParams();

  var currentUrl = window.location.href;
  var url = new URL(currentUrl);
  var isReport = url.searchParams.get("is_report");

  const getQCEvaluate = async (id) => {
    setLoading(true);

    try {
      const response = await evaluationServices.getQCEvaluate(id);
      console.log(response.data);
      setQCEvaluate(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteQCEvaluate = async (questionID) => {
    setLoading(true);
    try {
      const response = await evaluationServices.deleteQCEvaluate(questionID);
      getQCEvaluate(id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getQCEvaluate(id);
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: isReport ? "Tiêu chí báo cáo" : "Tiêu chí đánh giá",
      dataIndex: "title",
      key: "title",
      render: (_, title) => <p>{`${title?.title}`}</p>,
    },
    isReport ? (
      <></>
    ) : (
      {
        title: "Tổng điểm chuẩn áp dụng trong nhóm đánh giá",
        dataIndex: "title",
        key: "title",
        render: (_, title) => <p>{`${title?.standard_percent}`}</p>,
      }
    ),
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_update ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: qcEvaluate,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
        </Space>
      ),
    },
  ];

  const fillable = [
    { key: "title", display_name: "Câu hỏi" },
    { key: "standard_percent", display_name: "Tỉ lệ áp dụng" },
    { key: "level", display_name: "Mức độ đánh giá" },
  ];

  function exportData() {
    exportExcel(
      "Danh sách câu hỏi ",
      qcEvaluate ?? [],
      ["Tiêu chí đánh giá", "Tỉ lệ áp dụng", "Mức độ đánh giá"],
      fillable
    );
  }

  async function onChangeExcel(evt) {
    console.log(evt);
    var readData = await readFile(evt);

    const header = [
      { value: "title", title: "Tiêu chí đánh giá" },
      { value: "standard_percent", title: "Tỉ lệ áp dụng" },
      { value: "level", title: "Mức độ đánh giá" },
    ];
    setModal({
      method: "IMPORT",
      visible: true,
      dataExcel: readData,
      header: header,
    });
  }

  async function importQuestionExcel(data) {
    let hasError = false;
    if (data.length != 0) {
      data.forEach((value, key) => {
        if ((value?.title ?? "") === "" || value?.title === undefined) {
          toast.error(`Dòng ${key + 1} không có tiêu chí đánh giá`);
          hasError = true;
          return;
        }
        if (isReport != 1) {
          if (
            (value?.standard_percent ?? "") === "" ||
            value?.standard_percent === undefined
          ) {
            toast.error(`Dòng ${key + 1} không có tỉ lệ áp dụng`);
            hasError = true;
            return;
          }
          if ((value?.level ?? "") === "" || value?.level === undefined) {
            toast.error(`Dòng ${key + 1} không có mức độ đánh giá`);
            hasError = true;
            return;
          }
        }
      });
    } else {
      toast.error(`File không có dữ liệu`);
      hasError = true;
      return;
    }

    if (hasError) {
      return;
    }

    setLoading(true);
    try {
      let dataImport = {
        qc_evaluate_group_id: id,
        list_data: data?.map((item) => {
          return {
            standard_percent: item.standard_percent,
            title: item.title,
            level: item.level,
          };
        }),
      };

      const response = await evaluationServices.updateQCEvaluate(dataImport);
      getQCEvaluate(id);
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <h4 className="h4 title_content mb-0 text-gray-800">
          {isReport
            ? "Danh sách tiêu chí báo cáo"
            : "Danh sách tiêu chí đánh giá"}
        </h4>
        <Space>
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_manager ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<BiImport />}
                style={{ backgroundColor: "#1ebae9" }}
                onClick={(e) => {
                  fileElm.current.click();
                }}
              >
                Import Excel
              </Button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_manager ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<BiExport />}
                style={{ backgroundColor: "#e91e63" }}
                onClick={(e) => {
                  exportData();
                }}
              >
                Export Excel
              </Button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_add ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<MdAdd />}
                onClick={(e) => {
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: null,
                  });
                }}
              >
                Thêm câu hỏi
              </Button>
            ),
          })}
          <input
            ref={fileElm}
            id="file-excel-import"
            type="file"
            name="name"
            style={{ display: "none" }}
            onChange={onChangeExcel}
          />
        </Space>
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(qcEvaluate ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            index: index,
            data: e,
            standard_percent: e.standard_percent ?? "--:--",
            title: e.title ?? "--:--",
            level: e.level ?? "--:--",
          };
        })}
        pagination={false}
      />
      <AddQCEvaluateModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        qcEvaluateGroupId={id}
        isReport={isReport}
        onOk={() => {
          getQCEvaluate(id);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
        testPeriGroupId={id}
      />
      <ImportExcelModel
        open={
          modal.visible && modal.method === "IMPORT" && modal.dataExcel != null
        }
        data={modal.dataExcel}
        header={modal.header}
        onOk={(data) => {
          importQuestionExcel(data);
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
