import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { SelectPicker, DatePicker } from "rsuite";
import { emailRegex, phoneRegex } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";
import { Image } from "antd";
import "./styles.css";

const Profile = ({ profile }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dataGender = [
    { label: "Nam", value: 1 },
    { label: "Nữ", value: 2 },
    { label: "Không xác định", value: 0 },
  ];

  const areaId = useSelector((state) => state.area.areaId);
  const [role, setRole] = useState([]);

  const fetchRole = async (areaId) => {
    try {
      const response = await shiftServices.getRole({ areaId });
      const result = response.data.map((item) => ({
        label: item.title,
        value: item.id,
      }));
      setRole(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (profile) {
      reset({ ...profile });
    }
  }, [profile]);

  const onSubmit = (value) => {};
  useEffect(() => {
    fetchRole(areaId);
  }, []);

  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        <div>
          <Image
            width={150}
            height={150}
            src={profile?.avatar}
            className="rounded-circle"
            fallback="https://i.imgur.com/X9ifjHu.jpeg"
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Họ và tên*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("name", {
                  required: "Không để trống!",
                })}
                disabled
              />
              <div className="error-message">{errors?.name?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Email*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("email", {
                  required: "Không để trống!",
                  pattern: {
                    value: emailRegex,
                    message: "Email không hợp lệ",
                  },
                })}
                disabled
              />
              <div className="error-message">{errors?.email?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Số điện thoại*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("phone_number", {
                  required: "Không để trống!",
                  pattern: {
                    value: phoneRegex,
                    message: "Số điện thoại không hợp lệ",
                  },
                })}
                disabled
              />
              <div className="error-message">
                {errors?.phone_number?.message}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Mã nhân viên*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("staff_code", {
                  required: "Không để trống!",
                })}
                disabled
              />
              <div className="error-message">{errors?.email?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Giới tính*</label>
              <Controller
                name="sex"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={dataGender}
                      value={value}
                      placeholder="-- Chọn giới tính --"
                      block
                      cleanable={false}
                      onChange={(val) => onChange(val)}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.sex?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày vào làm*</label>
              <input
                type="start_join_time"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("start_join_time", {
                  required: "Không để trống!",
                })}
                disabled
              />
              <div className="error-message">
                {errors?.start_join_time?.message}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Chi nhánh*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={profile?.area?.name ?? ""}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Phòng ban*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={profile?.department?.name}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Phân quyền*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={profile?.role_user?.title}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Vị trí</label>
              <input
                name="name"
                type="text"
                disabled
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("position")}
              />
              <div className="error-message">{errors?.position?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày sinh*</label>
              <input
                type="birthday"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={profile?.birthday}
              />
            </div>
          </div>
          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin CMND/CCCD:
              </span>
            </div>
            <div className="col-3 d-flex">
              <div className="col-6 pb-2">
                <Image
                  width={110}
                  height={110}
                  src={profile?.cmnd_front}
                  fallback="https://i.imgur.com/X9ifjHu.jpeg"
                />
              </div>
              <div className="col-6 pb-2">
                <Image
                  width={110}
                  height={110}
                  src={profile?.cmnd_back_side}
                  fallback="https://i.imgur.com/X9ifjHu.jpeg"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số CMND</label>
                <input
                  name="cmnd"
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  autoComplete="off"
                  {...register("cmnd")}
                />
                <div className="error-message">{errors?.cmnd?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Nơi cấp</label>
                <input
                  name="issued_by"
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled
                  autoComplete="off"
                  {...register("issued_by")}
                />
                <div className="error-message">
                  {errors?.issued_by?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày cấp</label>
                <input
                  name="date_range"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("date_range")}
                />

                <div className="error-message">
                  {errors?.date_range?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Địa chỉ thường trú</label>
                <input
                  name="permanent_address"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("permanent_address")}
                />
                <div className="error-message">
                  {errors?.permanent_address?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Địa chỉ tạm trú</label>
                <input
                  name="temporary_address"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("temporary_address")}
                />
                <div className="error-message">
                  {errors?.temporary_address?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Dân tộc</label>
                <input
                  name="nation"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("nation")}
                />
                <div className="error-message">{errors?.nation?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tôn giáo</label>
                <input
                  name="religion"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("religion")}
                />
                <div className="error-message">{errors?.religion?.message}</div>
              </div>
            </div>
          </div>
          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin tài khoản ngân hàng:
              </span>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label>Tên ngân hàng</label>
                <input
                  name="bank_name"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_name")}
                />
                <div className="error-message">
                  {errors?.bank_name?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số tài khoản</label>
                <input
                  name="bank_number"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_number")}
                />
                <div className="error-message">
                  {errors?.bank_number?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tên người thụ hưởng</label>
                <input
                  name="bank_account_name"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_account_name")}
                />
                <div className="error-message">
                  {errors?.bank_account_name?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tên chi nhánh</label>
                <input
                  name="bank_branch"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_branch")}
                />
                <div className="error-message">
                  {errors?.bank_branch?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin học vấn:
              </span>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Trường học</label>
                <input
                  name="school"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("school")}
                />
                <div className="error-message">{errors?.school?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Trình độ học vấn</label>
                <input
                  name="level"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("level")}
                />
                <div className="error-message">{errors?.level?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Chuyên ngành</label>
                <input
                  name="specialized"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("specialized")}
                />
                <div className="error-message">
                  {errors?.specialized?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Khoá</label>
                <input
                  name="course"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("course")}
                />
                <div className="error-message">{errors?.course?.message}</div>
              </div>
            </div>
          </div>
          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin sức khỏe:
              </span>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Chiều cao (cm)</label>
                <input
                  name="height"
                  type="number"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("height")}
                />
                <div className="error-message">{errors?.height?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Cân nặng (kg)</label>
                <input
                  name="weight"
                  type="number"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("weight")}
                />
                <div className="error-message">{errors?.weight?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Bệnh bẩm sinh</label>
                <input
                  name="congenital"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("congenital")}
                />
                <div className="error-message">
                  {errors?.congenital?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="col-8">Đã kết hôn</label>
                <input
                  name="married"
                  type="checkbox"
                  disabled
                  style={{ width: 20, height: 20 }}
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("married")}
                />
                <div className="error-message">{errors?.married?.message}</div>
              </div>
            </div>
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin thuế:
              </span>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Mã số thuế</label>
                <input
                  name="tax_code"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("tax_code")}
                />
                <div className="error-message">{errors?.tax_code?.message}</div>
              </div>
            </div>
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin BHXH:
              </span>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số BHXH</label>
                <input
                  name="bhyt_code"
                  type="text"
                  disabled
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bhyt_code")}
                />
                <div className="error-message">
                  {errors?.bhyt_code?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Nơi KCB ban đầu</label>
                <input
                  name="healthcare_first_place"
                  type="text"
                  className="form-control"
                  disabled
                  placeholder=""
                  autoComplete="off"
                  {...register("healthcare_first_place")}
                />
                <div className="error-message">
                  {errors?.healthcare_first_place?.message}
                </div>
              </div>
            </div>
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin nghỉ việc:
              </span>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ việc</label>
                <input
                  type="date_leave"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  disabled
                  value={profile?.date_leave}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Lý do nghỉ việc</label>
                <input
                  name="leave_reason"
                  type="text"
                  className="form-control"
                  disabled
                  placeholder=""
                  autoComplete="off"
                  {...register("leave_reason")}
                />
                <div className="error-message">
                  {errors?.leave_reason?.message}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end">
          <button
            className="btn btn-success mr-2"
            disabled
            style={{ fontSize: "14px" }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true"></i>Cập nhật
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default Profile;
