import { useState } from "react";
import { secondsToTime } from "../../../../../helpers";
import TimeWorkingModal from "../TimeWorkingModal";

const TableJobBoard = ({ salaryOfStaff, queryParams }) => {
  const [modal, setModal] = useState({
    visible: false,
    fromOvertime: null,
    fromForm: null,
    fromUser: null,
  });
  const [fromUser, setFromUser] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const displayTime = (seconds) => {
    const timeConvert = seconds
      ? secondsToTime(seconds)
      : {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };

    return `${timeConvert.hours} giờ ${timeConvert.minutes} phút ${timeConvert.seconds} giây`;
  };

  return (
    <div>
      <div className="form-group">
        <label>Ngày công thực tế:</label>
        <input
          value={salaryOfStaff?.staff?.contract?.count_day_work || 0}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      
      <div className="form-group">
        <label>Giờ công tiêu chuẩn:</label>
        <input
          value={displayTime(salaryOfStaff?.time_default)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Giờ công thực tế:</label>
        <input
          value={displayTime(salaryOfStaff?.work_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: null,
              fromForm: null,
              fromUser: 0,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công chấm hộ:</label>
        <input
          value={displayTime(salaryOfStaff?.household_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: 1,
              fromForm: null,
              fromUser: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công được thêm:</label>
        <input
          value={displayTime(salaryOfStaff?.add_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: 1,
              fromForm: null,
              fromUser: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công quên chấm công:</label>
        <input
          value={displayTime(salaryOfStaff?.bonus_form_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: null,
              fromForm: 1,
              fromUser: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công nghỉ phép:</label>
        <input
          value={displayTime(salaryOfStaff?.leave_form_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: null,
              fromForm: 1,
              fromUser: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công công tác:</label>
        <input
          value={displayTime(salaryOfStaff?.trip_form_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: null,
              fromForm: 1,
              fromUser: null,
            });
          }}
        />
      </div><div className="form-group">
        <label>Giờ công tăng ca/thêm giờ:</label>
        <input
          value={displayTime(salaryOfStaff?.over_form_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setModal({
              visible: true,
              fromOvertime: null,
              fromForm: 1,
              fromUser: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Giờ công lễ tết:</label>
        <input
          value={displayTime(salaryOfStaff?.ot_time)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số giờ đi làm sớm:</label>
        <input
          value={displayTime(salaryOfStaff?.time_start_early)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số giờ đi làm muộn:</label>
        <input
          value={displayTime(salaryOfStaff?.time_start_late)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số giờ về sớm:</label>
        <input
          value={displayTime(salaryOfStaff?.time_end_early)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số giờ về muộn:</label>
        <input
          value={displayTime(salaryOfStaff?.time_end_late)}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số lần quên check in:</label>
        <input
          value={salaryOfStaff?.no_check_in || ""}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <div className="form-group">
        <label>Số lần quên checkout:</label>
        <input
          value={salaryOfStaff?.no_check_out ?? 0}
          type="text"
          className="form-control"
          autoComplete="off"
          readOnly
        />
      </div>
      <TimeWorkingModal
        visible={modal.visible}
        data={queryParams}
        fromOverTime={modal.fromOvertime}
        fromForm={modal.fromForm}
        fromUser={modal.fromUser}
        closeModal={() => {
          setModal({visible:false, fromForm: null, fromUser: null, fromOvertime:null});
          setRefreshData(!refreshData);
        }}
        refreshData={refreshData}
      />
    </div>
  );
};

export default TableJobBoard;
