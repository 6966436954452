import React from "react";
import { useEffect } from "react";
import { Modal } from "antd";
import { Button, Form, Input } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { evaluationServices } from "../../../services/evaluationServices";

const AddQCEvaluateGroupModal = ({
  open,
  closeModal,
  onOk,
  dataInput,
  isReport,
  qcEvaluateMainId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (dataInput != null) {
      onFill();
    } else {
      onReset();
    }
  }, [open]);

  const createQCEvaluateGroup = async (data) => {
    setLoading(true);
    try {
      const response = await evaluationServices.createQCEvaluateGroup(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateQCEvaluateGroup = async (data, id) => {
    setLoading(true);
    try {
      const response = await evaluationServices.updateQCEvaluateGroup(id, data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    console.log(values);

    if (dataInput != null) {
      updateQCEvaluateGroup(values, dataInput.id);
    } else {
      values.qc_evaluate_main_id = qcEvaluateMainId;
      createQCEvaluateGroup(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 20,
      span: 28,
    },
  };

  return (
    <Modal
      title={
        dataInput?.id != null
          ? `${isReport ? "Sửa nhóm báo cáo" : "Sửa nhóm đánh giá"}`
          : `${isReport ? "Sửa nhóm báo cáo" : "Thêm nhóm đánh giá"}`
      }
      centered
      open={open}
      width={500}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Form.Item
            name="name"
            label={isReport ? "Tên nhóm báo cáo" : "Tên nhóm đánh giá"}
            className="mb-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={isReport ? "Nhập tên nhóm báo cáo" : "Nhập tên nhóm đánh giá"} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddQCEvaluateGroupModal;
