import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";
import SearchInput from "../../../../components/SeachInput";
import { convertNumberToDateStr } from "../../../../helpers";
import { shiftServices } from "../../../../services/shiftServices";

const ModalContentSchedule = ({ handleChoice }) => {
  const [shifts, setShifts] = useState([]);
  const [shiftSelected, setShiftSelected] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);

  //   const onSubmitSearch = () => {};
  //   const onChangeSearch = () => {};
  //   const links = [
  //     {
  //       active: true,
  //       url: "2",
  //       label: "1",
  //     },
  //     {
  //       active: true,
  //       url: "2",
  //       label: "1",
  //     },
  //     {
  //       active: true,
  //       url: "2",
  //       label: "1",
  //     },
  //   ];
  //   const handleClickItem = () => {};
  const fetchShifts = async (areaId) => {
    try {
      const response = await shiftServices.fetchShifts(areaId);
      setShifts(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchShifts(areaId);
  }, [areaId]);

  const handleAddShift = (shift, check) => {
    let arrShifts = [...shiftSelected];
    if (check) {
      arrShifts.push(shift);
    } else {
      arrShifts = arrShifts.filter((shiftItem) => shiftItem.id !== shift.id);
    }
    handleChoice(arrShifts);
    setShiftSelected(arrShifts);
  };

  const customStyles = {
    content: {
      width: "80%",
      height: "100%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <div  style={customStyles}>
     <table
        className="table no-footer dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th></th>
            <th>Tên ca</th>
            <th>Thời gian bắt đầu</th>
            <th>Thời gian kết thúc</th>
            <th>Ngày trong tuần</th>
          </tr>
        </thead>
        <tbody>
          {shifts?.map((shift) => {
            return (
              <tr key={shift.id} className="hover-product even">
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleAddShift(shift, e.target.checked)
                    }
                  />
                </td>
                <td>{shift?.name}</td>
                <td>
                  {convertNumberToDateStr(
                    shift?.start_work_hour,
                    shift?.start_work_minute
                  )}
                </td>
                <td>
                  {convertNumberToDateStr(
                    shift?.end_work_hour,
                    shift?.end_work_minute
                  )}
                </td>
                <td>
                  {shift?.days_of_week_list?.map((day, index) => {
                    return (
                      <span key={index} className="pr-1">
                        {day === 8 ? "CN" : `T${day},`}
                      </span>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
  </div>
  );
};

export default ModalContentSchedule;
