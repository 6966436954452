import React, { useState } from "react";
import { Steps, Panel, ButtonGroup, Button } from "rsuite";
import Contract from "./child/Contract";
import Employee from "./child/Employee";

const StaffContract = () => {
  const [step, setStep] = useState(0);
  const [newStaff, setNewStaff] = useState(null);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">
        Tạo nhân viên - Hợp đồng
      </h4>
      <div className="card shadow p-3">
        <Steps current={step}>
          <Steps.Item title="Thêm nhân viên" />
          <Steps.Item title="Tạo hợp đồng" />
        </Steps>
        <hr />
        <Panel>
          {step === 0 ? (
            <Employee
              handleSuccess={(value) => {
                setNewStaff(value);
                onNext();
              }}
            />
          ) : (
            <Contract newStaff={newStaff} />
          )}
        </Panel>
        <hr />
        {/* <ButtonGroup>
          <Button onClick={onNext} disabled={step === 1}>
            Tiếp tục
          </Button>
        </ButtonGroup> */}
      </div>
    </div>
  );
};

export default StaffContract;
