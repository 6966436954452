/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spin, Table } from "antd";
import { Input, Select } from "antd";
import { reportServices } from "../../../services/reportServices";
import { exportQcExcel } from "../../../helpers/sheet_qc";
import { evaluationServices } from "../../../services/evaluationServices";

const ChooseReportQcViewModal = ({ open, closeModal, data }) => {
  useEffect(() => {
    console.log(data);
  }, [open]);

  const areas = useSelector((state) => state.area.areas);
  const areaId = useSelector((state) => state.area.areaId);
  const [areaSelected, setAreaSelected] = useState([]);
  const [qcEvaluateMains, setQCEvaluateMains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qcChoose, setQcChoose] = useState(null);
  const [dataChange, setDataChange] = useState(null);


  useEffect(() => {
    var item = [];

    var areas = [];

    (data ?? []).forEach((d, index) => {
      (d.qc_evaluetes ?? []).forEach((e, index2) => {
        (e.area_results ?? []).forEach((result, index3) => {
          if (!areas.includes(result.area_name)) {
            areas.push(result.area_name);
          }

          item.push({
            key: `${e.id}`,
            index: index + 1,
            data: e,
            group: d.name,
            title: e.title,
            level: e.level,
            area_id: result.area_id,
            area_name: result.area_name,
            done_number: result.done_number,
            faild_number: result.faild_number,
            point_total: result.point_total,
          });
        });
      });
    });

    const sortedData = item.sort((a, b) => a.area_id - b.area_id);

    setDataChange(sortedData);

    console.log(sortedData);
  }, [open]);

  const columns = [
    {
      title: "Chi nhánh",
      dataIndex: "area_name",
      key: "area_name",
      render: (text, record, index) => {
        if (index === 0 || dataChange[index - 1].area_id !== record.area_id) {
          const groupData = dataChange.filter(
            (data) => data.area_id === record.area_id
          );

          return {
            children: text,
            props: {
              rowSpan: groupData.length,
            },
          };
        } else {
          return {
            children: null,
            props: {
              rowSpan: 0,
            },
          };
        }
      },
    },
    {
      title: "Câu hỏi",
      dataIndex: "title",
      key: "title",
      render: (title) => <p>{title}</p>,
    },
    {
      title: "Mức độ",
      dataIndex: "level",
      key: "level",
      render: (level) => <p>{level}</p>,
    },
    {
      title: "Đạt",
      dataIndex: "done_number",
      key: "done_number",
      render: (done_number) => <p>{done_number || 0}</p>,
    },
    {
      title: "Không đạt",
      dataIndex: "faild_number",
      key: "faild_number",
      render: (faild_number) => <p>{faild_number || 0}</p>,
    },
    {
      title: "Điểm",
      dataIndex: "point_total",
      key: "point_total",
      render: (point_total) => <p>{point_total || 0}</p>,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      render: (text) => <p>{text}</p>,
    },
  ];

  return (
    <Modal
      title={"Báo cáo"}
      centered
      open={open}
      width={1500}
      style={{ top: 20 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={(dataChange ?? []).map((e, index) => {
            return {
              key: index + 1,
              index: index + 1,
              data: e,
              area_name: e.area_name ?? 0,
              group: e.name,
              title: e.title,
              level: e.level,
              area_name: e.area_name,
              area_id: e.area_id,
              done_number: e.done_number,
              faild_number: e.faild_number,
              point_total: e.point_total,
              note: e.note,
              image: e.image,
            };
          })}
          pagination={false}
        />
      </div>
    </Modal>
  );
};

export default ChooseReportQcViewModal;
