import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertNumberToDateStr } from "../../../../helpers";
import { shiftServices } from "../../../../services/shiftServices";
import Modal from "react-modal";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

const ChooseShiftModal = ({
  visible,
  chooseOne,
  shiftsInput,
  onSave,
  closeModal,
}) => {
  const [shifts, setShifts] = useState([]);
  const [shiftSelected, setShiftSelected] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const fetchShifts = async (areaId) => {
    try {
      const response = await shiftServices.fetchShifts(areaId);
      setShifts(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (shifts.length === 0) {
      fetchShifts(areaId);
    }
    if (shiftsInput != null) {
      console.log(shiftsInput);
      setShiftSelected(shiftsInput);
    }
  }, [visible]);

  const handleAddShift = (shift, check) => {
    if (chooseOne === true) {
      setShiftSelected([shift]);
    } else {
      let arrShifts = [...shiftSelected];
      if (check) {
        arrShifts.push(shift);
      } else {
        arrShifts = arrShifts.filter((shiftItem) => shiftItem.id !== shift.id);
      }
      setShiftSelected(arrShifts);
    }
  };

  const customStyles = {
    content: {
      width: "60%",
      height: "auto%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>Chọn ca làm</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "70%", margin: "10px" }}>
        <div className="table-responsive mt-2">
          <div id="dataTable_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table no-footer dataTable"
              id="dataTable"
              width="100%"
              cellSpacing={0}
              aria-describedby="dataTable_info"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Tên ca</th>
                  <th>Thời gian bắt đầu</th>
                  <th>Thời gian kết thúc</th>
                  <th>Ngày trong tuần</th>
                </tr>
              </thead>
              <tbody>
                {shifts?.map((shift) => {
                  return (
                    <tr key={shift.id} className="hover-product even">
                      <td>
                        <input
                          type="checkbox"
                          checked={shiftSelected
                            .map((e) => e.id)
                            .includes(shift.id)}
                          onChange={(e) =>
                            handleAddShift(shift, e.target.checked)
                          }
                        />
                      </td>
                      <td>{shift?.name}</td>
                      <td>
                        {convertNumberToDateStr(
                          shift?.start_work_hour,
                          shift?.start_work_minute
                        )}
                      </td>
                      <td>
                        {convertNumberToDateStr(
                          shift?.end_work_hour,
                          shift?.end_work_minute
                        )}
                      </td>
                      <td>
                        {shift?.days_of_week_list?.map((day, index) => {
                          return (
                            <span key={index} className="pr-1">
                              {day === 8 ? "CN" : `T${day},`}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            if (shiftSelected.length === 0) {
              toast.error("Chưa chọn ca");
              return;
            }
            onSave(shiftSelected);
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
    </Modal>
  );
};

export default ChooseShiftModal;
