/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { constants } from "../../../../constants";
import { Spin, Modal } from "antd";
import { reportSysServices } from "../../../../services/reportSysServices";
import { Bar, Line } from "react-chartjs-2";
import { MdArrowForwardIos } from "react-icons/md";
import DatePickerModal from "./DatePickerModal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartDetailModal = ({ open, type, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areas = useSelector((state) => state.area.areas);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [dataChart, setDataChart] = useState(null);
  const [dataChartCP, setDataChartCP] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [titleChart, SetTitleChart] = useState("Số lượng nhân viên mới");

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  useEffect(() => {
    setFilter({
      type: "day",
      from_time: getFirstAndLastDayInMonth().firstDay,
      to_time: getFirstAndLastDayInMonth().lastDay,
      area_compare_id: null,
      area_id: areaId,
    });
  }, [open]);

  useEffect(() => {
    if (open) {
      getDataChart(type);
    }
  }, [filter]);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  var options = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Số lượng nhân viên mới",
      },
    },
  };

  const getDataChart = (type) => {
    switch (type) {
      case constants.NEW_STAFF:
        SetTitleChart("Số lượng nhân viên mới");
        return getStaffChart();
      case constants.PROBATION:
        SetTitleChart("Số lượng nhân viên thử việc");
        return getProbationStaffChart();
      case constants.LEAVE_STAFF:
        SetTitleChart("Số lượng nhân viên nghỉ việc");
        return getLeaveStaffChart();
      case constants.WORKING_HOURS:
        SetTitleChart("Số giờ làm việc thực tế");
        return getWorkingHoursChart();
      case constants.WORKING_MONEY:
        SetTitleChart("Số tiền lương thực tế");
        return getWorkingMoneyChart();
      case constants.OVERTIME:
        SetTitleChart("Số giờ làm thêm");
        return getWorkingOvertimeChart();
      case constants.REWARD:
        SetTitleChart("Số nhân viên được khen thưởng");
        return getProposeRewardsChart();
      case constants.ADJUST_SALARY:
        SetTitleChart("Số nhân viên được điều chỉnh lương");
        return getProposeSalaryChart();
      case constants.ADVANCEMENT:
        SetTitleChart("Số nhân viên được đề xuất thăng chức");
        return getProposeaAvancementChart();
      case constants.DISCIPLINE:
        SetTitleChart("Số nhân viên bị kỷ luật");
        return getProposeDisciplineChart();
      default:
        return getStaffChart();
    }
  };

  const getStaffChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getStaffChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số nhân viên mới`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getLeaveStaffChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getLeaveStaffChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số nhân viên nghỉ việc`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProbationStaffChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProbationStaffChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lượng nhân viên thử việc`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_staff ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getWorkingHoursChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getWorkingHoursChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số giờ làm việc thực tế`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => (item.total_second ?? 0) / 3600
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => (item.total_second ?? 0) / 3600
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getWorkingMoneyChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getWorkingHoursChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số tiền lương thực tế`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_salary ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_salary ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getWorkingOvertimeChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getWorkingOvertimeChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số giờ làm thêm`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else { 
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => (item.total_working_overtime ?? 0) / 3600
      );
      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => (item.total_working_overtime ?? 0) / 3600
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
      }

    
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeRewardsChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeRewardsChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label:`Tổng số nhân viên được khen thưởng`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else { 

        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
      }

    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeSalaryChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeSalaryChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label:`Tổng số nhân viên được điều chỉnh lương`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else { 
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
      }

     
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeaAvancementChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeaAvancementChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label:`Tổng số nhân viên được đề xuất thăng chức`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else { 

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
      filter?.type == "month"
        ? moment(item.time ?? Date()).format("MM-YYYY")
        : filter?.type == "year"
        ? moment(item.time ?? Date()).format("YYYY")
        : moment(item.time ?? Date()).format("DD-MM-YYYY")
    );

    

    var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
      (item) => item.total_form ?? 0
    );

    var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
      (item) => item.total_form ?? 0
    );

    if (filter.area_compare_id == null) {
      setDataChart({
        labels,
        datasets: [
          {
            label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
            data: dataStaff,
            backgroundColor: "#96DBAF",
          },
        ],
      });
    } else {
      setDataChart({
        labels,
        datasets: [
          {
            label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
            data: dataStaff,
            borderColor: "#96DBAF",
            backgroundColor: "#96DBAF",
          },
          {
            label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
            data: dataStaffCP,
            borderColor: "#febf89",
            backgroundColor: "#febf89",
          },
        ],
      });
    }
      }

    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeDisciplineChart = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeDisciplineChart({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label:`Tổng số nhân viên bị kỷ luật`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else { 
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );
      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
      }

    
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getTypeString = () => {
    if (filter?.type == "month") return "tháng";
    if (filter?.type == "year") return "Năm";
    if (filter?.type == "day") return "ngày";
    if (filter?.type == "quarter") return "quý";
    return "Ngày";
  };

  return (
    <Modal
      title=""
      centered
      open={open}
      width="70%"
      onOk={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
      <div className="d-flex">
          <span
            className="px-3 pb-3 d-flex justify-content-start align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal({
                ...modal,
                method: "PICKER",
                visible: true,
                hideCompare:
                  type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                typeChart: type,
              });
            }}
          >
            Theo {getTypeString()}{" "}
            {filter?.type == "month"
              ? moment(filter.from_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.from_time ?? Date()).format("YYYY")
              : moment(filter.from_time ?? Date()).format("DD-MM-YYYY")}{" "}
            đến{" "}
            {filter?.type == "month"
              ? moment(filter.to_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.to_time ?? Date()).format("YYYY")
              : moment(filter.to_time ?? Date()).format("DD-MM-YYYY")}
            <MdArrowForwardIos className="ml-1" size={13} />
          </span>
          {filter.area_compare_id != null ? (
            <div
              onClick={() => {
                setModal({
                  ...modal,
                  method: "PICKER",
                  visible: true,
                  hideCompare:
                    type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                  typeChart: type,
                });
              }}
            >{`Đang so sánh: ${
              areas.data.filter((a) => a.id === filter.area_id)[0].name ?? ""
            } với ${
              areas.data.filter((a) => a.id === filter.area_compare_id)[0]
                .name ?? ""
            }`}</div>
          ) : (
            <div
              style={{ cursor: "pointer", color: "#096dd9" }}
              onClick={() => {
                setModal({
                  ...modal,
                  method: "PICKER",
                  visible: true,
                  hideCompare:
                    type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                  typeChart: type,
                });
              }}
            >{`Chi nhánh: ${
              areas.data.filter((a) => a.id === filter.area_id)[0]?.name ?? ""
            }`}</div>
          )}
          
        </div>
        <div className="d-flex justify-content-center">
          {dataChart === null || refresh == true ? (
            <Spin style={{ textAlign: "center" }} />
          ) : (dataChart.datasets ?? []).length == 1 ? (
            <Bar
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth:
                  filter.area_compare_id == null || dataChartCP === null
                    ? "100%"
                    : "49%",
              }}
            />
          ) : (
            <Line
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth: "100%",
              }}
            />
          )}
        </div>
        <DatePickerModal
          visible={modal.visible && modal.method === "PICKER"}
          closeModal={() => {
            handleCloseModal();
          }}
          filterInput={filter}
          onOk={(value, type, areaChart, areaCompare) => {
            setFilter({
              ...filter,
              type: type,
              area_id: areaChart,
              area_compare_id: areaCompare,
              from_time: value[0],
              to_time: value[1],
            });
            handleCloseModal();
          }}
        ></DatePickerModal>
      </div>
    </Modal>
  );
};

export default ChartDetailModal;
