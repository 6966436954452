export default function InfoIcon({ className, ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest} 
    >
      <path
        d="M10.625 9.1665C10.625 8.82134 10.3452 8.5415 10 8.5415C9.65483 8.5415 9.375 8.82134 9.375 9.1665V14.1665C9.375 14.5117 9.65483 14.7915 10 14.7915C10.3452 14.7915 10.625 14.5117 10.625 14.1665V9.1665Z"
        fill="currentColor "
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0001 1.0415C5.05253 1.0415 1.04175 5.05229 1.04175 9.99984C1.04175 14.9474 5.05253 18.9582 10.0001 18.9582C14.9477 18.9582 18.9584 14.9474 18.9584 9.99984C18.9584 5.05229 14.9477 1.0415 10.0001 1.0415ZM2.29175 9.99984C2.29175 5.74265 5.74289 2.2915 10.0001 2.2915C14.2572 2.2915 17.7084 5.74265 17.7084 9.99984C17.7084 14.257 14.2572 17.7082 10.0001 17.7082C5.74289 17.7082 2.29175 14.257 2.29175 9.99984Z"
        fill="currentColor "
      />
      <path
        d="M10.8334 6.66634C10.8334 7.12657 10.4603 7.49967 10.0001 7.49967C9.53983 7.49967 9.16675 7.12657 9.16675 6.66634C9.16675 6.20611 9.53983 5.83301 10.0001 5.83301C10.4603 5.83301 10.8334 6.20611 10.8334 6.66634Z"
        fill="currentColor "
      />
    </svg>
  );
}
