import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import { Space, Table, Tag, Button, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../../services/franchiseServices";
import AddCateModal from "./child/AddCateModal";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { toast } from "react-toastify";
import moment from "moment/moment";
const { Search } = Input;

export default function FranchiseCate() {
  const areaId = useSelector((state) => state.area.areaId);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const [loading, setLoading] = useState(null);
  const [checkInit, setCheckInit] = useState(true);

  const [dataSource, setDataSource] = useState([]);

  const getFranchiseCate = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getFranchiseCate(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );

      setDataSource(
        (response?.data ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            name: e.name ?? "--:--",
            description: e?.description ?? "--:--",
            updated_at: e.updated_at ?? "--:--",
          };
        })
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteFranchiseCate = async (id) => {
    try {
      const response = await franchiseServices.deleteFranchiseCate(id);
      getFranchiseCate(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFranchiseCate();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      key: "sort",
    },
    {
      title: "Tên loại file",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Mô tả loại file",
      dataIndex: "description",
      key: "description",
      render: (text) => <a>{text}</a>,
    },
    {
      title:"Thời gian cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (_, record) => <a>{moment(record?.updated_at ?? Date.now()).format('DD-MM-YYYY HH:mm')}</a>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={(e) => {
              console.log(record);
              setModal({
                method: "ADD",
                visible: true,
                data: {
                  id: record.key,
                  name: record.name,
                  description: record.description,
                },
              });
            }}
          >
            Sửa
          </a>
          <Popconfirm
            title="Xoá câu hỏi ?"
            description="Bạn có chắc chắn muốn xoá loại file này chứ ?"
            onConfirm={() => {
              deleteFranchiseCate(record.key);
            }}
            onCancel={() => {}}
            okText="Có"
            cancelText="Không"
          >
            <a style={{ color: "red" }}>Xoá</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const sortFileCate = async () => {
    try {
      const response = await franchiseServices.sortFileCate({
        sort_id: dataSource.map((i) => i.key),
      });
      
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (checkInit) {
      setCheckInit(false);
      return;
    }
    sortFileCate();
  }, [dataSource]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div></div>
        <Button
          type="primary"
          shape="round"
          size={20}
          icon={<MdAdd />}
          onClick={(e) => {
            setModal({
              method: "ADD",
              visible: true,
              data: null,
            });
          }}
        >
          Thêm loại file
        </Button>
      </div>
      <div style={{ height: "20px" }}></div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={loading}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
          />
        </SortableContext>
      </DndContext>
      <AddCateModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getFranchiseCate(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
