import { constants as c } from "../constants";
const initialState = {
   list: {
      data: [],
      status: c.NONE,
   },
   info: {
      data: {},
      status: c.NONE,
   },
};

export function newsComment(state = initialState, action) {
   switch (action.type) {
      case c.GET_ALL_NEWS_COMMENT_SUCCESS:
         return {
            ...state,
            list: {
               ...action.data,
               status: c.SUCCESS,
            },
         };
      case c.RESET_NEWS_COMMENT_LIST_STATUS:
         return {
            ...state,
            list: {
               status: c.NONE,
            },
         };
      case c.GET_ALL_NEWS_COMMENT_FAILURE:
         return {
            ...state,
            list: {
               status: c.FAILURE,
            },
         };
      default:
         return state;
   }
}
