import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import moment from "moment";
import { reportSysServices } from "../../../services/reportSysServices";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDetailOperateModal from "./child/ChartDetailOperateModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ReportOperatePage = () => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  useEffect(() => {
    getProposePayment();
    getProposeRepair();
    getProposeHrSupport();
    getProposeBuyEquipment();
    getProposeCancelOrder();
    getProposeTransportationStuff();
    getRateCancelOrder();
  }, [areaId]);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const [dataPayChart, setDataPayChart] = useState(null);
  const [dataRepairChart, setDataRepairChart] = useState(null);
  const [dataSupportChart, setDataSupportChart] = useState(null);
  const [dataBuyChart, setDataBuyChart] = useState(null);
  const [dataCancelChart, setDataCancelChart] = useState(null);
  const [totalMoneyCancelChart, setTotalMoneyCancelChart] = useState(null);
  const [dataTranferChart, setDataTranferChart] = useState(null);
  const [dataTypeOrderCancelChart, setDataTypeOrderCancelChart] =
    useState(null);
  const [dataRateOrderCancelChart, setDataRateOrderCancelChart] =
    useState(null);

  const getTypeOrder = (type) => {
    switch (type) {
      case 0:
        return "Nhóm món cơm";
      case 1:
        return "Nhóm món cá";
      case 2:
        return "Nhóm món rau";
      case 3:
        return "Nhóm món thịt";
      case 4:
        return "Nhóm món bò";
      case 5:
        return "Nhóm món khác";
      case 6:
        return "CCDC";
      case 7:
        return "Đồ dùng";
      case 8:
        return "Nguyên Vật Liệu";
      default:
        return "Nhóm món cơm";
    }
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const getProposePayment = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposePayment({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataPayChart({
          labels,
          datasets: [
            {
              label: `Tổng số tiền thanh toán`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money ?? 0
        );

        setDataPayChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getProposeRepair = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposeRepair({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataRepairChart({
          labels,
          datasets: [
            {
              label: `Tổng số tiền thanh toán`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataRepairChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getProposeHrSupport = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposeHrSupport({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataSupportChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần hỗ trợ`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataSupportChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getProposeBuyEquipment = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposeBuyEquipment({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataBuyChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần đề xuất mua CCDC,TSCD`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataBuyChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getProposeCancelOrder = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposeCancelOrder({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataCancelChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần huỷ hàng`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setTotalMoneyCancelChart({
          labels,
          datasets: [
            {
              label: `Tổng số tiền huỷ hàng`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        var dataTotalMoney = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money ?? 0
        );
        setDataCancelChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setTotalMoneyCancelChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataTotalMoney,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }

      setDataTypeOrderCancelChart({
        labels,
        datasets: [
          {
            label: `${getTypeOrder(0)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_0 ?? 0
            ),
            borderColor: "#96DBAF",
            backgroundColor: "#96DBAF",
          },
          {
            label: `${getTypeOrder(1)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_1 ?? 0
            ),
            borderColor: "#febf89",
            backgroundColor: "#febf89",
          },
          {
            label: `${getTypeOrder(2)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_2 ?? 0
            ),
            borderColor: "#077c59",
            backgroundColor: "#077c59",
          },
          {
            label: `${getTypeOrder(3)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_3 ?? 0
            ),
            borderColor: "#2c44a7",
            backgroundColor: "#2c44a7",
          },
          {
            label: `${getTypeOrder(4)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_4 ?? 0
            ),
            borderColor: "#8f3ec9",
            backgroundColor: "#8f3ec9",
          },
          {
            label: `${getTypeOrder(5)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_5 ?? 0
            ),
            borderColor: "#ac487a",
            backgroundColor: "#ac487a",
          },
          {
            label: `${getTypeOrder(6)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_6 ?? 0
            ),
            borderColor: "#d56668",
            backgroundColor: "#d56668",
          },
          {
            label: `${getTypeOrder(7)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_7 ?? 0
            ),
            borderColor: "#95b66a",
            backgroundColor: "#95b66a",
          },
          {
            label: `${getTypeOrder(8)}`,
            data: (response?.data?.charts[0]?.data ?? []).map(
              (item) => item.type_8 ?? 0
            ),
            borderColor: "#6a86b6",
            backgroundColor: "#6a86b6",
          },
        ],
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const getRateCancelOrder = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getRateCancelOrder({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        setDataRateOrderCancelChart({
          labels,
          datasets: [
            {
              label: `${getTypeOrder(0)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_0 ?? 0
              ),
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${getTypeOrder(1)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_1 ?? 0
              ),
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
            {
              label: `${getTypeOrder(2)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_2 ?? 0
              ),

              borderColor: "#077c59",
              backgroundColor: "#077c59",
            },
            {
              label: `${getTypeOrder(3)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_3 ?? 0
              ),
              borderColor: "#2c44a7",
              backgroundColor: "#2c44a7",
            },
            {
              label: `${getTypeOrder(4)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_4 ?? 0
              ),
              borderColor: "#8f3ec9",
              backgroundColor: "#8f3ec9",
            },
            {
              label: `${getTypeOrder(5)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_5 ?? 0
              ),
              borderColor: "#ac487a",
              backgroundColor: "#ac487a",
            },
            {
              label: `${getTypeOrder(6)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_6 ?? 0
              ),
              borderColor: "#d56668",
              backgroundColor: "#d56668",
            },
            {
              label: `${getTypeOrder(7)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_7 ?? 0
              ),
              borderColor: "#95b66a",
              backgroundColor: "#95b66a",
            },
            {
              label: `${getTypeOrder(8)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_8 ?? 0
              ),
              borderColor: "#6a86b6",
              backgroundColor: "#6a86b6",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        setDataRateOrderCancelChart({
          labels,
          datasets: [
            {
              label: `${getTypeOrder(0)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_0 ?? 0
              ),
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${getTypeOrder(1)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_1 ?? 0
              ),
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
            {
              label: `${getTypeOrder(2)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_2 ?? 0
              ),
              borderColor: "#077c59",
              backgroundColor: "#077c59",
            },
            {
              label: `${getTypeOrder(3)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_3 ?? 0
              ),
              borderColor: "#2c44a7",
              backgroundColor: "#2c44a7",
            },
            {
              label: `${getTypeOrder(4)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_4 ?? 0
              ),
              borderColor: "#8f3ec9",
              backgroundColor: "#8f3ec9",
            },
            {
              label: `${getTypeOrder(5)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_5 ?? 0
              ),
              borderColor: "#ac487a",
              backgroundColor: "#ac487a",
            },
            {
              label: `${getTypeOrder(6)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_6 ?? 0
              ),
              borderColor: "#d56668",
              backgroundColor: "#d56668",
            },
            {
              label: `${getTypeOrder(7)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_7 ?? 0
              ),
              borderColor: "#95b66a",
              backgroundColor: "#95b66a",
            },
            {
              label: `${getTypeOrder(8)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_8 ?? 0
              ),
              borderColor: "#6a86b6",
              backgroundColor: "#6a86b6",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const getProposeTransportationStuff = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getProposeTransportationStuff({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataTranferChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần điều chuyển hàng hoá`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataTranferChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const options = {
    responsive: true,
    aspectRatio: 1.7,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tổng số nhân viên toàn hệ thống",
      },
    },
  };

  const chartWidget = (title, dataChart, type) => {
    var options = {
      responsive: true,
      scales: {
        xAxes: {
          barPercentage: 1.6,
          grid: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            font: {
              size: 11,
            },

            major: {
              enable: false,
            },
          },
        },
      },
      aspectRatio: 1.5,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: title,
        },
      },
    };
    return (
      <div className="col-6 mb-5">
        {type == constants.TYPE_MONEY_CANCEL_ORDER ? (
          <Line
            onClick={() => {
              setModal({
                method: "DEATAIL",
                type: type,
                visible: true,
              });
            }}
            options={{
              responsive: true,
              aspectRatio: 1.5,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Tiền huỷ hàng theo từng loại",
                },
              },
            }}
            data={dataTypeOrderCancelChart}
            style={{
              maxWidth: "100%",
            }}
          />
        ) : type == constants.TYPE_RATE_CANCEL_ORDER ? (
          <Line
            onClick={() => {
              setModal({
                method: "DEATAIL",
                type: type,
                visible: true,
              });
            }}
            options={{
              responsive: true,
              aspectRatio: 1.5,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Tỉ lệ huỷ hàng theo từng loại",
                },
              },
            }}
            data={dataRateOrderCancelChart}
            style={{
              maxWidth: "100%",
            }}
          />
        ) : (
          <Bar
            options={options}
            onClick={() => {
              setModal({
                method: "DEATAIL",
                type: type,
                visible: true,
              });
            }}
            data={dataChart}
          />
        )}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Thống kê vận hành
        </h4>
      </div>
      <div className="row mb-2 pt-4">
        {dataPayChart == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số tiền thanh toán",
            dataPayChart,
            constants.PAYMENT
          )
        )}
        {dataRepairChart == null ? (
          <></>
        ) : (
          chartWidget("Tổng số lần sửa chữa", dataRepairChart, constants.REPAIR)
        )}
        {dataSupportChart == null ? (
          <></>
        ) : (
          chartWidget("Tổng số lần hỗ trợ", dataSupportChart, constants.SUPPORT)
        )}
        {dataBuyChart == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số lần đề xuất mua CCDC,TSCD",
            dataBuyChart,
            constants.BUY_EQUIPMENT
          )
        )}

        {dataTranferChart == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số lần điều chuyển hàng hoá",
            dataTranferChart,
            constants.TRANFER
          )
        )}
        {dataCancelChart == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số lần huỷ hàng",
            dataCancelChart,
            constants.CANCEL_ORDER
          )
        )}

        {totalMoneyCancelChart == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số tiền huỷ hàng",
            totalMoneyCancelChart,
            constants.CANCEL_ORDER
          )
        )}
        {dataTypeOrderCancelChart == null ? (
          <></>
        ) : (
          chartWidget(
            "",
            dataTypeOrderCancelChart,
            constants.TYPE_MONEY_CANCEL_ORDER
          )
        )}
        {dataRateOrderCancelChart == null ? (
          <></>
        ) : (
          chartWidget(
            "",
            dataRateOrderCancelChart,
            constants.TYPE_RATE_CANCEL_ORDER
          )
        )}
      </div>
      <ChartDetailOperateModal
        open={modal.visible && modal.method === "DEATAIL"}
        type={modal.type}
        closeModal={() => {
          handleCloseModal();
        }}
      ></ChartDetailOperateModal>
    </div>
  );
};

export default ReportOperatePage;
