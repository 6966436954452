import React, { useEffect, useRef } from "react";
import "./style.css";

const Rating = () => {
  const ref = useRef(null);
  useEffect(() => {
    resize();
  }, []);

  const resize = () => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <label className="star-rating">☆</label>
        <label className="star-rating">☆</label>
        <label className="star-rating">☆</label>
        <label className="star-rating">☆</label>
        <label className="star-rating">☆</label>
      </div>
      <div className="things-to-improve">
        <label>Những điều cần cải thiện</label>
        <div className="option-improve">
          <p className="improve-item">không nhiệt tình</p>
          <p className="improve-item">không dắt xe</p>
          <p className="improve-item">không thân thiện</p>
        </div>
      </div>
      <textarea
        ref={ref}
        className="form-control textarea"
        value={
          "Mình ăn các quán ở Phan Bội Châu, royal, Hoàng đạo thuý thích nhất ở Phan bội Châu, không gian đẹp, món ngon, bgio cug ăn ốc om chuối đậu và tráng miệng chè chuối. bgio cug ăn ốc om chuối đậu và tráng miệng chè chuối. bgio cug ăn ốc om chuối đậu và tráng miệng chè chuối"
        }
        autoComplete="off"
        disabled
      />
    </>
  );
};

const CustomerBranchEvaluationPage = () => {
  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Đánh giá chi nhánh Văn phòng của khách hàng Nguyễn Văn Hoàng
        </h4>
      </div>
      <div className="card shadow mb-4">
        <div className="card-body">
          <form>
            <div className="form-group">
              <label>1. Họ tên khách hàng</label>
              <input className="form-control" autoComplete="off" disabled />
            </div>
            <div className="form-group">
              <label>2. Số điện thoại</label>
              <input className="form-control" autoComplete="off" disabled />
            </div>
            <div className="form-group">
              <label>3. Cảm nhận về bảo vệ của khách</label>
              <Rating />
            </div>
            <div className="form-group">
              <label>4. Cảm nhận về món ăn của khách</label>
              <Rating />
            </div>

            <div className="form-group">
              <label>4. Cảm nhận về nhân viên phục vụ của khách</label>
              <Rating />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerBranchEvaluationPage;
