import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";
import { constants } from "../../../../constants";
import { formatMoneyVND } from "../../../../helpers";
import { shiftServices } from "../../../../services/shiftServices";
import { SelectPicker } from "rsuite";
import { areaAction } from "../../../../actions/areaAction";
import { userActions } from "../../../../actions/userActions";
import { async } from "@firebase/util";
import SearchInput from "../../../../components/SeachInput";

const ModalContentEmployee = ({ handleChoice }) => {
  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [employeeSelected, setEmployeeSelected] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const [hasMore, setHasmore] = useState(true);
  const dispatch = useDispatch();
  const [employeesInit, setEmployeesInit] = useState([]);
  const [filterDepartment, setFilterDepartment] = useState();
  const departmentList = useSelector((state) => state.staff.department_list);
  const [checkedEmployee, setCheckedEmployee] = useState(false);

  const areas = useSelector((state) => state.area.areas);

  useEffect(() => {
    fetchEmployeeByArea(1, true, "");
    setEmployeeSelected([]);
  }, [areaId]);

  // const fetchEmployeeByArea = async (areaId) => {
  //   try {
  //     const response = await shiftServices.fetchEmployeeByArea(areaId);
  //     setEmployees(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function handleChangeArea(value) {
    dispatch(areaAction.changeAreaId(value));
    dispatch(userActions.getReportNumber(value));
  }

  const handleChoiceAllEmployee = (checked) => {
    if (checked) {
      const emp = employees.filter(
        (e) =>
          e.contract != null && moment(e?.contract?.end).isAfter(moment.now())
      );
      emp.forEach((e) => {
        if (employeeSelected.map((s) => s.id).includes(e.id) == false) {
          employeeSelected.push(e);
        }
      });
    } else {
      const emp = employees.filter(
        (e) =>
          e.contract != null && moment(e?.contract?.end).isAfter(moment.now())
      );
      emp.forEach((e) => {
        var index = employeeSelected.findIndex((s) => s.id == e.id);
        employeeSelected.splice(index, 1);
      });
    }
    handleChoice(employeeSelected);
    setCheckedEmployee(checked);
  };

  function onChangeDepartment(e) {
    var { value } = e.target;

    if (value == "") {
      setEmployees(employeesInit);

      console.log(employeesInit.length);
      checkChooseAll(true);
    } else {
      const staffByDepartment = employeesInit.filter(
        (staff) => staff.department_id == e.target.value
      );

      setEmployees(staffByDepartment);
      console.log(staffByDepartment.length);
      checkChooseAll();
    }
    setFilterDepartment(value);
  }

  const handleClickItem = (page) => {
    fetchEmployeeByArea(page, false,"");
  };

  const checkChooseAll = () => {
    const emp = employees.filter(
      (e) =>
        e.contract != null && moment(e?.contract?.end).isAfter(moment.now())
    );
    if (emp.length != employeeSelected.length) {
      setCheckedEmployee(false);
    }
  };

  const handleAddEmployee = (employee, check) => {
    let arrEmployee = [...employeeSelected];
    if (check) {
      arrEmployee.push(employee);
    } else {
      arrEmployee = arrEmployee.filter(
        (employeeItem) => employeeItem.id !== employee.id
      );
    }
    handleChoice(arrEmployee);
    setEmployeeSelected(arrEmployee);
  };

  const onSubmitSearch = (value) => {
    fetchEmployeeByArea(1, false, value);
  };

  const fetchEmployeeByArea = async (page, changeArea, search) => {
    const queryString = `?page=${page}&show_contract=true&search=${search}`;

    try {
      if (areaId == constants.AREA_ALL_ID) {
        if (changeArea == true) {
          setEmployees([]);
          setEmployeesInit([]);
        }

        const res = await shiftServices.fetchStaffsManager({
          queryString,
        });

        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
        setData(res.data);
        setEmployees(res.data.data);
        setEmployeesInit(res.data.data);
        setHasmore(res.data.next_page_url === null ? false : true);
        setCheckedEmployee(false);
      } else {
        const queryString = `?search=${search}`;
        const res = await shiftServices.fetchEmployeeByArea(
          areaId,
          queryString
        );
        setEmployees(res.data);
        setEmployeesInit(res.data);
        setData(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  return (
    <div>
      {/* <SearchInput
        placeHolder={"Tìm kiếm Ca chấm công"}
        onSubmitSearch={onSubmitSearch}
        onChange={onChangeSearch}
      ></SearchInput> */}
      <div className="table-responsive mt-3">
        <div id="scrollableDiv" className="dataTables_wrapper no-footer">
          <div className="d-flex  justify-content-between align-items-center mb-3">
            <div className="card-header py-3">
              <div className="input-group mb-6" style={{ padding: "0px 20px" }}>
                <input
                  type="search"
                  value={searchValue}
                  name="searchValue"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Tìm kiếm"
                  style={{
                    maxWidth: "400px",
                    minWidth: "100px",
                  }}
                />
                <div className="input-group-append">
                  <div
                    className="btn btn-primary"
                    onClick={() => fetchEmployeeByArea(1, false, searchValue)}
                  >
                    <i className="fa fa-search" />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{ justifyContent: "space-between" }}
              ></div>
            </div>
            <SelectPicker
              className="ml-2 select-area"
              data={areas?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              value={areaId}
              cleanable={false}
              placeholder="-- Chọn chi nhánh --"
              block
              onChange={handleChangeArea}
            />
            <div
              className="sorting_disabled"
              rowSpan={1}
              colSpan={1}
              aria-label="Phòng ban"
              style={{ maxWidth: "200px" }}
            >
              <select
                name="deparment_id"
                value={filterDepartment}
                onChange={onChangeDepartment}
                type="text"
                class="form-control"
                id="txtPhone_branch"
              >
                <option value="" selected>
                  --Phòng ban--
                </option>
                <option value="">Tất cả</option>
                {departmentList.data?.map((v, i) => {
                  return <option value={v.id}>{v.name}</option>;
                })}
              </select>
            </div>
            <div>Đã chọn {employeeSelected?.length}</div>

            <div className="d-flex align-items-center">
              <span className="mr-2">Chọn tất cả</span>
              <input
                type="checkbox"
                value={checkedEmployee}
                checked={checkedEmployee}
                onChange={(e) => handleChoiceAllEmployee(e.target.checked)}
              />
            </div>
            <div className="d-flex align-items-center">
              <button
                style={{
                  border: "1px solid",
                }}
                class="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  setEmployeeSelected([]);
                  setCheckedEmployee(false);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
                Bỏ chọn tất cả
              </button>
            </div>
          </div>
          <table
            className="table no-footer dataTable"
            id="dataTable"
            width="100%"
            cellSpacing={0}
            aria-describedby="dataTable_info"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Tên nhân viên</th>
                <th>Tên đăng nhập</th>
                <th>Chi nhánh</th>
                <th>Lương theo giờ</th>
                <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {employees?.map((employee) => {
                const checkChoose = employeeSelected
                  ?.map((e) => e.id)
                  .includes(employee.id);
                return (
                  <tr
                    key={employee.key}
                    className="hover-product even"
                    style={{
                      backgroundColor: moment(employee?.contract?.end).isAfter(
                        moment.now()
                      )
                        ? "white"
                        : "#ffd3d3",
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={checkChoose}
                        disabled={
                          moment(employee?.contract?.end).isAfter(moment.now())
                            ? false
                            : true
                        }
                        onChange={(e) =>
                          handleAddEmployee(employee, e.target.checked)
                        }
                      />
                    </td>
                    <td>{employee?.name}</td>
                    <td>{employee?.staff_code}</td>
                    <td>{getAreaById(employee?.area_id).name}</td>
                    <td>
                      {formatMoneyVND(employee?.contract?.salary ?? 0, true)}
                    </td>
                    <td>
                      {employee?.contract == null
                        ? "Chưa có hợp đồng"
                        : moment(employee?.contract?.end).isAfter(moment.now())
                        ? "Còn hợp đồng"
                        : "Hết hạn hợp đồng"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data && data?.links?.length > 0 && (
            <Pagination
              to={data?.to}
              handleClickItem={handleClickItem}
              links={data?.links}
              lastPage={data?.last_page}
              currentPage={data?.current_page}
            ></Pagination>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContentEmployee;
