import React, { useState } from "react";
import ViewDate from "../../../components/ViewDate";
import { useHistory } from "react-router-dom";
import ItemSchedule from "./child/ItemSchedule";
import ItemEmployee from "./child/ItemEmployee";
import ModalUID from "../../../components/ModalUID";
import ModalContentSchedule from "./child/ModalContentSchedule";
import ModalContentEmployee from "./child/ModalContentEmployee";
import moment from "moment";
import { constants } from "../../../constants";
import { shiftServices } from "../../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PutALot = () => {
  const history = useHistory();
  const [modal, setModal] = useState({
    title: "Ca Chấm Công",
    method: "CREATE",
    content: "",
    size: "xl",
    children: null,
    action: null,
  });
  const [dataTemp, setDataTemp] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [date, setDate] = useState(null);
  const [checked, setChecked] = useState(false);
  const areaId = useSelector((state) => state.area.areaId);
  const dispatch = useDispatch();

  const handleOnSave = () => {
    if (modal.action === "SHIFT") {
      setShifts(dataTemp);
    } else {
      setEmployees(dataTemp);
    }
    window.$(".modal").modal("hide");
  };

  const handleRemoveShift = (shiftId) => {
    const result = shifts.filter((shift) => shift.id !== shiftId);
    setShifts(result);
  };

  const handleRemoveEmployee = (employeeId) => {
    const result = employees.filter((employee) => employee.id !== employeeId);
    setEmployees(result);
  };

  const validateCreateWorkSchedule = () => {
    if (employees?.length === 0) {
      return false;
    } else if (shifts.length === 0) {
      return false;
    } else {
      const startDate = new Date(
        moment(date.startDate).format(constants.FORMAT_YYYY_MM_DD)
      ).getTime();
      const endDate = new Date(
        moment(date.endDate).format(constants.FORMAT_YYYY_MM_DD)
      ).getTime();
      const currentDate = new Date(
        moment(new Date()).format(constants.FORMAT_YYYY_MM_DD)
      ).getTime();
      if (startDate >= currentDate && endDate < startDate) {
        return false;
      }
    }
    return true;
  };

  const handleCreateWorkSchedule = async () => {
    try {
      if (validateCreateWorkSchedule()) {
        var listBranchId = [];

        employees
          .map((e) => e.area_id)
          .forEach((area_id) => {
            if (listBranchId.includes(area_id) == false) {
              listBranchId.push(area_id);
            }
          });

        console.log(listBranchId);

        listBranchId.forEach(async (area_id) => {
          const listStaffId = employees
            .filter((e) => e.area_id == area_id)
            .map((employee) => employee.id);
          const listShiftId = shifts.map((shift) => shift.id);
          const startTime =
            date === null
              ? moment(new Date()).format(constants.FORMAT_YYYY_MM_DD)
              : moment(date?.startDate).format(constants.FORMAT_YYYY_MM_DD);
          const endTime =
            date === null
              ? moment(new Date()).format(constants.FORMAT_YYYY_MM_DD)
              : moment(date?.endDate).format(constants.FORMAT_YYYY_MM_DD);

          const customDate =
            (date.customDate ?? []).length > 0
              ? date.customDate.map((date) => {
                  return moment(date.toDate()).format(
                    constants.FORMAT_YYYY_MM_DD
                  );
                })
              : [];

          const body = {
            start_time: startTime,
            end_time: endTime,
            list_staff_id: listStaffId,
            list_shift_id: listShiftId,
            custom_date: customDate,
            is_override: checked,
          };

          console.log(body);
          const response = await shiftServices.createWorkManySchedule(
            body,
            area_id
          );
          if (response.code === 200) {
            setEmployees([]);
            setShifts([]);
            toast.success(response.msg);
          } else {
            toast.error(response.msg);
          }
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  function handleChange(event) {
    setChecked(event.target.checked);
  }

  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">Tạo lịch làm việc</h4>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <div className="d-flex" style={{ fontSize: "16px", color: "red" }}>
            <input type="checkbox" checked={checked} onChange={handleChange} />
            <div style={{ width: "10px" }}></div>
            Ghi đè lịch làm việc của nhân viên được chọn trong ca hiện tại
          </div>
          <div style={{ height: "10px" }}></div>
          <div style={{ fontWeight: "bold" }}>Thời gian :</div>

          <div>
            <ViewDate
              className="d-flex mt-2 align-items-center"
              type={constants.MONTH}
              showMulti={true}
              handleChange={(date) => {
                setDate(date);
              }}
            />
          </div>
          <div className="mt-2" style={{ fontWeight: "700" }}>
            Ca làm việc :
          </div>
          {shifts?.length > 0 ? (
            <div className="table-responsive mt-2">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table no-footer dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Tên ca</th>
                      <th>Thời gian bắt đầu</th>
                      <th>Thời gian kết thúc</th>
                      <th>Phút đi trễ cho phép</th>
                      <th>Phút về sớm cho phép</th>
                      <th> Ngày trong tuần</th>
                      <th>Hành động</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shifts?.map((shift) => {
                      return (
                        <ItemSchedule
                          key={shift.id}
                          shift={shift}
                          handleRemove={(shiftId) => handleRemoveShift(shiftId)}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="mt-2 text-danger">Chưa chọn ca làm việc</div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-primary mt-3"
              data-toggle="modal"
              data-target="#modalUID"
              onClick={() =>
                setModal({
                  ...modal,
                  title: "Ca chấm công",
                  method: "UPDATE",
                  action: "SHIFT",
                  children: (
                    <ModalContentSchedule
                      handleChoice={(value) => {
                        setDataTemp(value);
                      }}
                    />
                  ),
                })
              }
            >
              <i className="fa fa-plus" aria-hidden="true"></i>Thêm ca
            </button>
          </div>
          <div className="mt-2" style={{ fontWeight: "700" }}>
            Đối tượng áp dụng:
          </div>

          {employees?.length > 0 ? (
            <div className="table-responsive mt-3">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table no-footer dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Tên nhân viên</th>
                      <th>Tên đăng nhập</th>
                      <th>Số điện thoại</th>
                      <th>Lương theo giờ</th>
                      <th>Chi nhánh</th>
                      <th>Hành động</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees?.map((employee) => {
                      return (
                        <ItemEmployee
                          key={employee.id}
                          employee={employee}
                          handleRemoveEmployee={(employeeId) =>
                            handleRemoveEmployee(employeeId)
                          }
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="mt-2 text-danger">Chưa chọn đối tượng áp dụng</div>
          )}

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-primary mt-3"
              data-toggle="modal"
              data-target="#modalUID"
              onClick={() =>
                setModal({
                  ...modal,
                  title: "Nhân viên",
                  method: "UPDATE",
                  action: "EMPLOYEE",
                  size: "xl",
                  children: (
                    <ModalContentEmployee
                      handleChoice={(value) => {
                        setDataTemp(value);
                      }}
                    />
                  ),
                })
              }
            >
              <i className="fa fa-plus" aria-hidden="true"></i>Thêm Nhân viên
            </button>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-success mr-2"
              style={{ fontSize: "14px" }}
              onClick={handleCreateWorkSchedule}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>Tạo
            </button>
            <button
              className="btn btn-success"
              style={{ fontSize: "14px" }}
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>Trở lại
            </button>
          </div>
        </div>
      </div>
      <ModalUID
        size={modal.size}
        method={modal.method}
        title={modal.title}
        handleOnSave={() => handleOnSave()}
        showFooter={modal.showFooter}
      >
        {modal.children}
      </ModalUID>
    </div>
  );
};

export default PutALot;
