import React, { Component, useState } from "react";

export default function SearchInput(props) {
   const { placeHolder } = props;
   const [searchValue, setSearchValue] = useState("");

   function onSave(e) {
      e.preventDefault();
      props.onSubmitSearch(searchValue);
      // setSearchValue("");
   }

   return (
      <form onSubmit={onSave}>
         <div className="input-group mb-6" style={{ padding: "0px 20px" }}>
            <input
               type="search"
               value={searchValue}
               name="txtSearch"
               onChange={(e) => {
                  setSearchValue(e.target.value);
               }}
               className="form-control"
               placeholder={placeHolder}
               style={{
                  maxWidth: "400px",
                  minWidth: "100px",
               }}
            />
            <div className="input-group-append">
               <button className="btn btn-primary" type="submit">
                  <i className="fa fa-search" />
               </button>
            </div>
         </div>
      </form>
   );
}
