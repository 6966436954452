/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../constants";
import { convertObjToQueryString } from "../../helpers";
import { reviewServices } from "../../services/reviewServices";
import DeleteModal from "./components/DeleteModal";
import QuestionDetailModal from "./components/QuestionDetailModal";
import "./styles.css";
import { toast } from "react-toastify";

const Reviews = () => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  const [modal, setModal] = useState({
    method: "",
    visible: false,
    title: "",
    questionDetail: null,
  });
  const [isReload, setIsReload] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, [isReload, areaId]);

  const fetchQuestions = async () => {
    try {
      const params = {
        area_id: areaId,
      };
      const response = await reviewServices.fetchReviewQuestions(
        convertObjToQueryString(params)
      );
      setQuestions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveQuestion = async (data) => {
    try {
      let response = null;

      if (modal.method === "CREATE" || modal.method === "UPDATE") {
        const options = [];

        data?.list_option.forEach((item) => {
          if (item.trim()) {
            options.push(item);
          }
        });

        const questionUpdate = { ...data, options };
        if (modal.method === "CREATE") {
          response = await reviewServices.createReviewQuestions(questionUpdate);
        } else if (modal.method === "UPDATE") {
          response = await reviewServices.updateReviewQuestions(
            questionUpdate.id,
            questionUpdate
          );
        }
      } else if (modal.method === "DELETE") {
        response = await reviewServices.deleteReviewQuestions(
          modal.questionDetail?.id
        );
      }

      if (response.success) {
        setIsReload(!isReload);
        handleCloseModal();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      let msg = "";

      if (modal.method === "CREATE") {
        msg = "Lỗi khi tạo câu hỏi";
      } else if (modal.method === "UPDATE") {
        msg = "Lỗi khi cập nhật câu hỏi";
      } else if (modal.method === "DELETE") {
        msg = "Lỗi khi xóa câu hỏi";
      }
      toast.error(msg);
     
    }
  };

  const handleCloseModal = () => {
    setModal({
      method: "",
      visible: false,
      title: "",
      questionDetail: null,
    });
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Câu hỏi đánh giá
        </h4>
        <button
          class={`btn btn-info btn-icon-split btn-sm`}
          onClick={() => {
            setModal({
              title: "Thêm câu hỏi",
              method: "CREATE",
              visible: true,
              questionDetail: null,
            });
          }}
        >
          <span class="icon text-white-50">
            <i class="fas fa-plus"></i>
          </span>
          <span class="text">Thêm câu hỏi</span>
        </button>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên câu hỏi</th>
                    <th>Danh sách lựa chọn</th>
                    <th className="text-center">Ngày cập nhật</th>
                    <th className="text-center">Trạng thái</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {questions?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>
                        {Array.isArray(item?.list_option) &&
                          item?.list_option?.join(", ")}
                      </td>
                      <td className="text-center">
                        {moment(item.updated_at).format("DD/MM/YYYY hh:mm")}
                      </td>
                      <td
                        className={`text-center ${
                          item.is_active ? "text-success" : "text-danger"
                        }`}
                      >
                        {item.is_active ? "Hiện" : "Ẩn"}
                      </td>
                      <td className="group-action-btn">
                        <button
                          class={`btn-info btn-sm btn-edit`}
                          onClick={() =>
                            setModal({
                              method: "UPDATE",
                              visible: true,
                              title: "Chỉnh sửa câu hỏi",
                              questionDetail: item,
                            })
                          }
                        >
                          <span className="icon text-white mr-1">
                            <i class="fas fa-edit"></i>
                          </span>
                          <span className="">Sửa</span>
                        </button>
                        <button
                          class={`btn-sm btn-trash`}
                          onClick={() =>
                            setModal({
                              method: "DELETE",
                              visible: true,
                              title: "Xóa câu hỏi",
                              questionDetail: item,
                            })
                          }
                        >
                          <span className="icon text-white mr-1">
                            <i className="fas fa-trash"></i>
                          </span>
                          <span className="btn-trash-text">Xoá</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <QuestionDetailModal
        visible={
          modal.visible &&
          (modal.method === "CREATE" || modal.method === "UPDATE")
        }
        title={modal.title}
        onSubmit={handleSaveQuestion}
        questionDetail={modal.questionDetail}
        closeModal={handleCloseModal}
      />
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={modal.title}
        closeModal={handleCloseModal}
        onSubmit={handleSaveQuestion}
      />
    </div>
  );
};

export default Reviews;
