/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";

import { Modal, Select, Space } from "antd";
import { useSelector } from "react-redux";
import { reportServices } from "../../../../services/reportServices";
import { exportQcExcel } from "../../../../helpers/sheet_qc";

const ExportExcelQcModal = ({ open, closeModal, onSuccess, id }) => {
  const [loading, setLoading] = useState(false);
  const areas = useSelector((state) => state.area.areas);
  const [areaSelected, setAreaSelected] = useState([]);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const { Option } = Select;

  const exportCourse = async (id) => {
    try {
      var res = await reportServices.exportReportQc(id);
      console.log(res);
      exportQcExcel(res.data.qc_evaluete_groups);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={"Xuất excel:"}
        centered
        open={open}
        width={600}
        style={{ top: 20 }}
        onCancel={() => {
          closeModal();
        }}
        onOk={() => {
          exportCourse(id);
          closeModal();
        }}
        
      >
        <div className="d-flex justify-content-center align-items-center">
          <Space>
            <p>Chọn chi nhánh</p>
            <Select
              placeholder="Chọn chi nhánh"
              mode="multiple"
              value={areaSelected}
              style={{ width: 400 }}
              onChange={(v) => {
                if (v.includes("999999999999999999")) {
                  console.log(v.filter((e) => e != "999999999999999999"));
                  setAreaSelected(v.filter((e) => e == "999999999999999999"));

                  return;
                }
                setAreaSelected(v);
              }}
            >
              {areas?.data?.map((item, index) => {
                return <Option value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default ExportExcelQcModal;
