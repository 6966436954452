/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Space, Image } from "antd";
import { Input, Select } from "antd";
import { areaServices } from "../../../services/areaServices";
import moment from "moment";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Table } from "rsuite";
import { CheckOutlined } from "@material-ui/icons";
import "../style.css";
const { Column, ColumnGroup, HeaderCell, Cell } = Table;

const QcHistoryModal = ({ open, closeModal, onOk, data }) => {
  useEffect(() => {
    setDataQc(data);
  }, [open]);

  const [dataQc, setDataQc] = useState(null);

  const ItemGroup = ({ group }) => {
    const [open, setOpen] = useState(true);

    return (
      <div
        className="d-flex flex-column justify-content-start"
        style={{
          border: "1px solid #e8e8e8",
          borderRadius: "5px",
          padding: "10px",
          margin: "10px 0px",
        }}
      >
        <div
          className="d-flex justify-content-start align-items-center py-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Space>
            <p>{`${group?.group_qc_name ?? ""}`}</p>

            <p style={{ color: "red", fontSize: "16px" }}>
              {`${(group?.list_standard ?? []).reduce(
                (a, b) => a + (b["standard_percent"] || 0),
                0
              )}`}
            </p>
          </Space>
          {open ? (
            <BiChevronDown size={20}></BiChevronDown>
          ) : (
            <BiChevronRight size={20}></BiChevronRight>
          )}
        </div>
        {open && (
          <div>
            {(group?.list_standard ?? []).map((item) => {
              return (
                <div
                  style={{
                    border: "1px solid #e8e8e8",
                    borderRadius: "5px",
                    padding: "10px",
                    margin: "10px 0px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        item?.level == "Lỗi nhẹ"
                          ? "#49cc98"
                          : item?.level == "Lỗi nặng"
                          ? "#cc6c49"
                          : "#cc4949",
                      color: "white",
                      fontWeight: "500",
                      paddingRight: 8,
                      paddingLeft: 8,
                      borderRadius: 5,
                      marginBottom: 10,
                      width: "fit-content",
                    }}
                  >
                    {`${item?.level ?? ""}`}
                  </div>

                  <p>{`${item?.qc_title ?? ""}`}</p>
                  <p>{`Tỉ trọng tiêu chí: ${item?.standard_percent ?? ""}%`}</p>
                  <p>{`Số điểm đã đánh giá: ${item?.percent ?? ""}`}</p>
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 15,
                      backgroundColor: "#49cc98",
                      color: "white",
                      fontWeight: "500",
                      paddingRight: 5,
                      paddingLeft: 5,
                      borderRadius: 5,
                    }}
                  >
                    {item.percent_from_group}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={dataQc?.isReport ? "Lịch sử QC báo cáo" : "Lịch sử QC đánh giá"}
      centered
      open={open}
      onOk={() => {}}
      onCancel={() => {
        closeModal();
      }}
      width={"80%"}
      style={{
        height: "80vh",
      }}
      wrapClassName="test"
    >
      <div>
        {!dataQc?.isReport && (
          <p style={{ fontSize: "20px", textAlign: "center" }}>
            Tổng điểm:{" "}
            <span style={{ color: "red", fontWeight: "500" }}>
              {dataQc?.resultPercent}
            </span>
          </p>
        )}
        <p>
          Người đánh giá: <span>{dataQc?.assessor ?? "--:--"}</span>
        </p>
        <p>
          Thời gian:{" "}
          <span>
            {moment(dataQc?.createdAt).format("HH:MM DD/MM/YYYY" ?? "--:--")}
          </span>
        </p>
        {/* {dataQc?.qc_check_history_list?.map((item) => {
          return <ItemGroup group={item}></ItemGroup>;
        })} */}
        <div style={{ height: "100%", overflow: "hidden" }}>
          <Table
            data={dataQc?.data || []}
            fillHeight
            // autoHeight
            headerHeight={80}
            bordered
            cellBordered
            wordWrap="break-word"
            affixHeader
            affixHorizontalScrollbar
          >
            {!dataQc?.isReport && (
              <Column align="center" verticalAlign="middle" width={250} fixed>
                <HeaderCell>Mức độ</HeaderCell>
                <Cell dataKey="level" />
              </Column>
            )}
            <Column
              align="center"
              verticalAlign="middle"
              width={250}
              fixed
              flexGrow={2}
            >
              <HeaderCell>Câu hỏi</HeaderCell>
              <Cell dataKey="title" />
            </Column>
            {!dataQc?.isReport && (
              <>
                {/* <Column
                  align="center"
                  verticalAlign="middle"
                  width={100}
                  fixed
                  flexGrow={1}
                >
                  <HeaderCell>Tỉ trọng</HeaderCell>
                  <Cell dataKey="percent" />
                </Column> */}
                <Column align="center" verticalAlign="middle" width={80} fixed>
                  <HeaderCell>Đạt</HeaderCell>
                  <Cell>
                    {(rowData) =>
                      rowData.passed && (
                        <span>
                          <CheckOutlined />
                        </span>
                      )
                    }
                  </Cell>
                </Column>
                <Column align="center" verticalAlign="middle" width={80} fixed>
                  <HeaderCell>Không đạt</HeaderCell>
                  <Cell>
                    {(rowData) =>
                      rowData.failed && (
                        <span>
                          <CheckOutlined />
                        </span>
                      )
                    }
                  </Cell>
                </Column>
              </>
            )}

            <Column align="center" verticalAlign="middle" width={100} fixed>
              <HeaderCell>Điểm</HeaderCell>
              <Cell>
                {(rowData) =>
                  !dataQc?.isReport ? rowData.percent : rowData.totalPoints
                }
              </Cell>
            </Column>

            {dataQc?.isReport && (
              <>
                <Column align="center" verticalAlign="middle" width={180} fixed>
                  <HeaderCell>Ca làm việc</HeaderCell>
                  <Cell dataKey="shiftName" />
                </Column>

                <Column align="center" verticalAlign="middle" width={80} fixed>
                  <HeaderCell>Ghi chú</HeaderCell>
                  <Cell dataKey="note" />
                </Column>
                <Column
                  align="center"
                  verticalAlign="middle"
                  width={80}
                  fixed
                  flexGrow={1}
                >
                  <HeaderCell>Hình ảnh</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateRows: "2fr",
                            gridTemplateColumns: "repeat(6, 1fr)",
                            gap: "10px",
                          }}
                        >
                          {rowData.images.map((image) => (
                            <>
                              <Image
                                width={25}
                                height={25}
                                src={image}
                                alt={image}
                              />
                            </>
                          ))}
                        </div>
                      );
                    }}
                  </Cell>
                </Column>
              </>
            )}
          </Table>
        </div>
      </div>
    </Modal>
  );
};

export default QcHistoryModal;
