import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";
import { evaluationServices } from "../../../../services/evaluationServices";

const AddQCEvaluateModal = ({
  open,
  closeModal,
  onOk,
  dataInput,
  isReport,
  qcEvaluateGroupId,
}) => {
  const [loading, setLoading] = useState(false);
  const [percentUsed, setPercentUsed] = useState(0);

  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        //  getTestPeriDetail(dataInput.id);
      }
    } else {
      // onReset();
    }
  }, [open]);

  const createQCEvaluate = async (data) => {
    setLoading(true);
    try {
      const response = await evaluationServices.createQCEvaluate(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateQCEvaluate = async (data) => {
    setLoading(true);
    try {
      const response = await evaluationServices.updateQCEvaluate(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    console.log(values);

    // tính tổng standard_percent trong mỗi item

    let sum = 0;

    values.list_data.map((item) => {
      sum += parseFloat(item.standard_percent);
    });

    console.log(sum);

    if (sum > 100) {
      toast.error(
        "Tổng điểm chuẩn áp dụng trong nhóm đánh giá phải nhỏ hơn hoặc bằng 100"
      );
      return;
    }

    if (dataInput != null) {
      let data = {
        qc_evaluate_group_id: qcEvaluateGroupId,
        list_data: values?.list_data,
      };
      updateQCEvaluate(data);
    } else {
      let data = {
        qc_evaluate_group_id: qcEvaluateGroupId,
        list_data: values?.list_data,
      };
      createQCEvaluate(data);
    }
  };

  const onFill = () => {
    let sum = 0;
    dataInput.map((item) => {
      sum += parseFloat(item.standard_percent);
    });
    setPercentUsed(sum);
    console.log(
      dataInput?.map((item) => {
        return {
          title: item.title,
          standard_percent: item.standard_percent,
          level: item.level ?? "",
        };
      })
    );
    form.setFieldsValue({
      list_data: dataInput?.map((item) => {
        return {
          id: item.id,
          title: item.title,
          standard_percent: item.standard_percent,
          level: item.level,
        };
      }),
    });

    console.log(form.getFieldValue("list_data"));
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={
        dataInput?.id != null
          ? isReport ?  "Sửa tiêu chí báo cáo" : "Sửa tiêu chí đánh giá"
          :  isReport ?  "Thêm tiêu chí báo cáo" : "Thêm tiêu chí đánh giá"
      }
      centered
      open={open}
      width={"auto"}
      style={{ top: 20, minWidth: isReport ? 500 : 1000 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.List name="list_data">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      rules={[
                        {
                          required: true,
                          message: "Không được để trống",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "600px" }}
                        placeholder="Nhập nội dung đánh giá"
                      />
                    </Form.Item>
                    {isReport ? (
                      <></>
                    ) : (
                      <Form.Item
                        {...restField}
                        name={[name, "standard_percent"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Số điểm áp dụng"
                          type="number"
                          onChange={(e) => {
                            let sum = 0;
                            form.getFieldValue("list_data").map((item) => {
                              sum += parseFloat(item?.standard_percent);
                            });
                            console.log(sum);
                            setPercentUsed(sum);
                          }}
                        />
                      </Form.Item>
                    )}
                    {isReport == false && <Form.Item
                      name={[name, "level"]}
                      rules={[
                        {
                          required: true,
                          message: "Không được để trống",
                        },
                      ]}
                    >
                      <Select
                        placeholder={ isReport ?  "Chọn mức độ báo cáo" : "Chọn mức độ đánh giá"}
                        //  onChange={onGenderChange}
                        allowClear
                      >
                        <Option value="Lỗi nhẹ">Lỗi nhẹ</Option>
                        <Option value="Lỗi nặng">Lỗi nặng</Option>
                        <Option value="Nghiêm trọng">Nghiêm trọng</Option>
                      </Select>
                    </Form.Item>}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm tiêu chí
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
              <Button
                type="default"
                onClick={() => {
                  onReset();
                }}
              >
                Reset
              </Button>
              {isReport ? (
                <></>
              ) : (
                <p>{`Tổng điểm chuẩn: ${percentUsed || 0}`}</p>
              )}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddQCEvaluateModal;
