import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../actions/chatActions";
import MainContent from "./MainContent";
import SidebarLeft from "./SidebarLeft/index.jsx";
import { StyledBoxChat, StyledButton } from "./index.style";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ModalSelectUser from "./Common/modalSelectsUser/index.jsx";
import CloseIcon from "./icons/CloseIcon.jsx";
import ZoomInIcon from "./icons/ZoomInIcon.jsx";
import ZoomOutIcon from "./icons/ZoomOutIcon.jsx";
import DoubleArrowRight from "./icons/DoubleArrowRight.jsx";
import DoubleArrowLeft from "./icons/DoubleArrowLeft.jsx";
import ModalUserInfo from "./Common/modalUserInfo/index.jsx";

export default function ChatHome() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { modalSelectUser, isHideMainContent, modalUserInfo, isShowBox } =
    useSelector((state) => state.chat);

  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    dispatch(a.getRoomChatList());
    // Hàm để loal lại danh sách room chat
    const reconnect = () => {
      dispatch(a.getRoomChatList());
    };

    const reconnectInterval = setInterval(reconnect, 60000);

    // Xóa interval khi component unmount để tránh memory leak
    return () => clearInterval(reconnectInterval);
  }, []);

  const MessageIcon = () => (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_480_21)">
        <path
          d="M12.2916 0C19.0692 0 24.5832 5.22374 24.5832 11.6447C24.5832 13.8892 23.9088 16.0633 22.6297 17.9432C22.8718 20.7626 23.5208 22.8556 24.4632 23.8471C24.5877 23.9782 24.6189 24.1788 24.5402 24.3444C24.4705 24.4923 24.3271 24.5833 24.1735 24.5833C24.1546 24.5833 24.1358 24.582 24.1165 24.579C23.9506 24.5544 20.0959 23.9705 17.3078 22.2764C15.7246 22.9488 14.0382 23.2895 12.2916 23.2895C5.51393 23.2895 -0.000115196 18.0657 -0.000115196 11.6447C-0.000115196 5.22374 5.51393 0 12.2916 0ZM18.0277 13.3699C18.9315 13.3699 19.6666 12.5962 19.6666 11.6447C19.6666 10.6933 18.9315 9.91959 18.0277 9.91959C17.1238 9.91959 16.3888 10.6933 16.3888 11.6447C16.3888 12.5962 17.1238 13.3699 18.0277 13.3699ZM12.2916 13.3699C13.1954 13.3699 13.9304 12.5962 13.9304 11.6447C13.9304 10.6933 13.1954 9.91959 12.2916 9.91959C11.3877 9.91959 10.6527 10.6933 10.6527 11.6447C10.6527 12.5962 11.3877 13.3699 12.2916 13.3699ZM6.55544 13.3699C7.45929 13.3699 8.19433 12.5962 8.19433 11.6447C8.19433 10.6933 7.45929 9.91959 6.55544 9.91959C5.65159 9.91959 4.91655 10.6933 4.91655 11.6447C4.91655 12.5962 5.65159 13.3699 6.55544 13.3699Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_480_21">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="matrix(-1 0 0 1 25 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const onToggleScreen = (value) => {
    dispatch(a.toggleFullscreen(value));
    setFullScreen(value);
  };

  const toggleMainContent = (value) => {
    dispatch(a.toggleMainContent(value));
  };

  const handleClassBoxChat = () => {
    if (isFullScreen && isHideMainContent) {
      return "fullscreen hide-main-content";
    }
    if (!isFullScreen && isHideMainContent) {
      return "hide-main-content";
    }
    if (isFullScreen && !isHideMainContent) {
      return "fullscreen";
    }
    return "";
  };

  if (!profile?.id) return null;

  return (
    <div>
      {!isShowBox ? (
        <StyledButton>
          <div
            onClick={() => {
              dispatch(a.toggleShowBox(true));
            }}
          >
            <MessageIcon />
          </div>
        </StyledButton>
      ) : (
        <StyledBoxChat>
          <div id="box-chat-container" className={handleClassBoxChat()}>
            <SidebarLeft />
            <MainContent />
            <div
              style={{
                width: "100%",
                background: "#eaedf0",
                height: "20px",
                position: "absolute",
                top: "-20px",
                boxShadow: "0 1px 2px #00000033",
              }}
            >
              <CloseIcon
                className="btn-close"
                onClick={() => {
                  dispatch(a.toggleShowBox(false));
                }}
              />
              {!isFullScreen ? (
                <ZoomOutIcon
                  className="btn-zoom"
                  onClick={() => onToggleScreen(true)}
                />
              ) : (
                <ZoomInIcon
                  className="btn-zoom"
                  onClick={() => onToggleScreen(false)}
                />
              )}
              {isHideMainContent ? (
                <DoubleArrowRight
                  className="btn-hide"
                  onClick={() => toggleMainContent(false)}
                />
              ) : (
                <DoubleArrowLeft
                  className="btn-hide"
                  onClick={() => toggleMainContent(true)}
                />
              )}
            </div>
            {modalSelectUser.isOpen ? <ModalSelectUser /> : null}
            {modalUserInfo.isOpen ? <ModalUserInfo /> : null}
          </div>
        </StyledBoxChat>
      )}
    </div>
  );
}
