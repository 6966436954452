/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import { convertNumberToDateStr } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";
import DeleteModal from "./components/DeleteModal";
import WorkShiftModal from "./components/WorkShiftModal";
import "./style.css";
import PermissionChecker from "../../../components/PermissionChecker";
import { Space, Table } from "antd";
import { toast } from "react-toastify";

const shiftDefault = {
  name: "",
  code: "",
  days_of_week: [],
  area_id: 0,
  start_break_hour: 12,
  start_break_minute: 0,
  end_break_hour: 13,
  end_break_minute: 0,
  start_work_hour: 7,
  start_work_minute: 0,
  end_work_hour: 18,
  end_work_minute: 0,
  minutes_early_leave_allow: 0,
  minutes_late_allow: 0,
  access_tracking_start: 0,
  access_tracking_end: 0,
};

const FORMAT_DATE = "HH:mm";
const ShiftPage = () => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
  });

  const [shifts, setShifts] = useState([]);
  const [shiftDetail, setShiftDetail] = useState(shiftDefault);
  const [isReload, setIsReload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchShiftsData();
  }, [isReload, areaId]);

  const fetchShiftsData = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const resShifts = await shiftServices.fetchShifts(areaId);
      setShifts(resShifts);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const convertDateOfWeek = (dateOfWeekList) => {
    let dateOfWeekStr = "";
    if (Array.isArray(dateOfWeekList)) {
      dateOfWeekList.forEach((item, index) => {
        dateOfWeekStr = `${dateOfWeekStr} ${item !== 8 ? `T${item}` : "CN"}${
          index !== dateOfWeekList.length - 1 ? "," : ""
        }`;
      });
    }
    return dateOfWeekStr;
  };

  const handleOnSave = async (data) => {
    console.log(data);
    try {
      setLoading(true);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      let shiftUpdate = data;
      data.scale && (data.scale = parseInt(data.scale));
      shiftUpdate.days_of_week = shiftUpdate.days_of_week_list;

      let response = null;

      if (modal.method === "CREATE") {
        response = await shiftServices.createShift({
          areaId: areaId,
          body: shiftUpdate,
        });
      } else if (modal.method === "UPDATE") {
        response = await shiftServices.updateShift({
          id: shiftUpdate.id,
          areaId: areaId,
          body: shiftUpdate,
        });
      } else if (modal.method === "DELETE") {
        response = await shiftServices.deleteShift({
          id: shiftDetail.id,
          areaId: areaId,
        });
      }
      if (response.success) {
        setIsReload(!isReload);
        handleCloseModal();
        toast.success(response.msg);
      } else {
        toast.success(response.msg);
      }
    } catch (error) {
      let msg = "";

      if (modal.method === "CREATE") {
        msg = "Lỗi khi tạo ca làm việc";
      } else if (modal.method === "UPDATE") {
        msg = "Lỗi khi cập nhật ca làm việc";
      } else if (modal.method === "DELETE") {
        msg = "Lỗi khi xóa ca làm việc";
      }
      toast.error(msg);
     
    } finally {
      setLoading(false);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const getTrackingStr = (value) => {
    const date = moment(value, FORMAT_DATE);

    return date.isValid() ? date.format(FORMAT_DATE) : "";
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (stt) => <p>{stt}</p>,
    },
    {
      title: "Tên ca",
      dataIndex: "name",
      key: "name",
      render: (name) => <p>{name}</p>,
    },
    {
      title: "Thời gian ca",
      dataIndex: "time",
      key: "time",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Thời gian nghỉ giữa ca",
      dataIndex: "break_time",
      key: "break_time",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Thời gian cho phép chấm công",
      dataIndex: "permission_time",
      key: "permission_time",
      render: (text) => <p>{`${text}`}</p>,
    },
    {
      title: "Ngày trong tuần",
      dataIndex: "days_of_week",
      key: "days_of_week",
      render: (text) => <p>{`${text}`}</p>,
    },
    {
      title: "Cập nhật lần cuối",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space>
          {PermissionChecker({
            decent: reportNumber.permissions?.shifts_edit ?? false,
            child: (
              <button
                class={`btn-info btn-sm btn-edit`}
                onClick={() => {
                  setShiftDetail(record?.data);
                  setModal({
                    title: `Chỉnh sửa ca chấm công`,
                    method: "UPDATE",
                    visible: true,
                  });
                }}
                data-toggle="modal"
                data-target="#modalShift"
              >
                <span className="icon text-white mr-1">
                  <i class="fas fa-edit"></i>
                </span>
                <span className="">Sửa</span>
              </button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.shifts_delete ?? false,
            child: (
              <button
                class={`btn-sm btn-trash`}
                onClick={() => {
                  setModal({
                    title: `Thông báo`,
                    method: "DELETE",
                    visible: true,
                  });
                  setShiftDetail(record?.data);
                }}
                data-toggle="modal"
                data-target="#modalUID"
              >
                <span className="icon text-white mr-1">
                  <i className="fas fa-trash"></i>
                </span>
                <span className="btn-trash-text">Xoá</span>
              </button>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <h4 className="h4 title_content mb-0 text-gray-800">Ca làm việc</h4>
        {PermissionChecker({
          decent: reportNumber.permissions?.shifts_add ?? false,
          child: (
            <button
              class={`btn btn-info btn-icon-split btn-sm`}
              data-toggle="modal"
              data-target="#modalShift"
              onClick={() => {
                setShiftDetail(shiftDefault);
                setModal({
                  title: "Thêm ca chấm công",
                  method: "CREATE",
                  visible: true,
                });
              }}
            >
              <span class="icon text-white-50">
                <i class="fas fa-plus"></i>
              </span>
              <span class="text">Thêm ca</span>
            </button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(shifts?.data ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            data: e,
            stt: index + 1,
            name: e.name ?? "--:--",
            time: `${convertNumberToDateStr(
              e.start_work_hour,
              e.start_work_minute
            )} - ${convertNumberToDateStr(e.end_work_hour, e.end_work_minute)}`,
            break_time: `${convertNumberToDateStr(
              e.start_break_hour,
              e.start_break_minute
            )} - ${convertNumberToDateStr(
              e.end_break_hour,
              e.end_break_minute
            )}`,
            permission_time: `${getTrackingStr(
              e.access_tracking_start
            )} - ${getTrackingStr(e.access_tracking_end)}`,
            days_of_week: convertDateOfWeek(e.days_of_week_list),
            updated_at: moment.utc(e.updated_at).format("DD-MM-YYYY HH:mm"),
          };
        })}
        pagination={false}
      />
      <WorkShiftModal
        visible={
          modal.visible &&
          (modal.method === "CREATE" || modal.method === "UPDATE")
        }
        title={modal.title}
        closeModal={handleCloseModal}
        onSubmit={handleOnSave}
        shiftDetail={shiftDetail}
      />
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={"Thông báo"}
        onSubmit={handleOnSave}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

export default ShiftPage;
