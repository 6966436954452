import React, { Component, useState } from "react";

export default function Pagination(props) {

   const {currentPage , lastPage} = props;

   function changePage(url, label, index, type) {
      var page = 1;
      console.log(url , currentPage , lastPage)
      if (url == null) {
         if (type === "PREVIOUS") {
            if (currentPage === 1) return;
            console.log("heheh1",parseInt(currentPage) - 1)

            props.handleClickItem(parseInt(currentPage) -1);
         } else {
            if (currentPage === lastPage) return;
            props.handleClickItem(parseInt(currentPage) + 1);
         }
      } else {
         props.handleClickItem(parseInt(label));
      }

   }

   function showItem(label, url, active, index, type) {
      return (
         <li
            class={`page-item ${active === true ? "active" : ""} `}
            onClick={() => changePage(url, label, index, type)}
         >
            <a class="page-link">{label}</a>
         </li>
      );
   }

   console.log(props.links);

   return (
      <div style={{ display: "flex", "justify-content": "end" }}>
         <nav aria-label="Page navigation" class="float-pagination IKITECH">
            <ul class="pagination  tab-pagination pg-blue">
               {props.links?.map((v, i) => {
                  if (i === 0) {
                     return showItem("Trước", null, v.active, i, "PREVIOUS");
                  } else if (i === props.links.length - 1) {
                     return showItem("Sau", null, v.active, i, "NEXT");
                  } else {
                     return showItem(v.label, v.url, v.active, i, null);
                  }
               })}
            </ul>
         </nav>
      </div>
   );
}
