/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import "./../style.css";
import moment from "moment";
import ChooseShiftModal from "../../../Procedure/OperateFormPage/child/ChooseShiftModal";
import { shiftServices } from "../../../../services/shiftServices";
import { constants } from "../../../../constants";
import { BsCalendar3 } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { FaArrowsAltH } from "react-icons/fa";
import { FaCodeBranch } from "react-icons/fa";
import { MdPhoneAndroid } from "react-icons/md";

Modal.setAppElement("#root");

const FORMAT_DATE = "DD/MM/YYYY";
const HistoryCheckInOutModal = ({
  visible,
  shiftInput,
  staffInput,
  closeModal,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areaId = useSelector((state) => state.area.areaId);
  const dispatch = useDispatch();

  const [shift, setShift] = useState(null);

  useEffect(() => {
    console.log(shiftInput);
    setShift(shiftInput);
  }, [visible]);

  const [modal, setModal] = useState({
    title: "",
    method: "",
    visible: false,
    shiftsInput: [],
  });

  const handleCloseModal = () => {
    setModal({
      ...modal,
      title: "",
      method: "",
      visible: false,
      shiftsInput: [],
    });
  };

  const customStyles = {
    content: {
      width: "70%",
      height: "auto",
      maxHeight: "90%",

      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const itemData = (icon, title, sub) => {
    return (
      <div
        className="d-flex "
        style={{
          alignItems: "center",
          margin: "30px",
        }}
      >
        <div className="item-data-container">{icon}</div>
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {title}
          </div>
          <div
            style={{
              color: "grey",
            }}
          >
            {sub}
          </div>
        </div>
      </div>
    );
  };

  function convertNumberToDateStr(start, end) {
    if (
      start !== null &&
      start !== undefined &&
      end !== null &&
      end !== undefined
    ) {
      return `${start.toString().padStart(2, "0")}:${end
        .toString()
        .padStart(2, "0")}`;
    }

    return "";
  }

  function timeBetWeen(timeStart, timeEnd, breakStart, breakEnd) {
    // Chuyển đổi giờ 1 và giờ 2 thành đối tượng Date
    var date1 = new Date("2023-06-28T" + timeStart);
    var date2 = new Date("2023-06-28T" + timeEnd);
    var break1 =
      breakStart == ""
        ? new Date("2023-06-28" + breakStart)
        : new Date("2023-06-28T" + breakStart);
    var break2 =
      breakEnd == ""
        ? new Date("2023-06-28" + breakEnd)
        : new Date("2023-06-28T" + breakEnd);

    // Tính khoảng cách giữa 2 giờ dưới dạng milliseconds
    var spaceTime = Math.abs(date1 - date2);

    // Tính khoảng cách giữa 2 giờ dưới dạng milliseconds
    var spaceBreak = Math.abs(break1 - break2);

    var space = spaceTime - spaceBreak;

    // Chuyển đổi khoảng cách từ milliseconds sang giờ và phút
    var hour = Math.floor(space / 3600000);
    var minus = Math.floor((space % 3600000) / 60000);

    // Trả về kết quả dưới dạng chuỗi

    if (hour == 0 && minus == 0) {
      return "0 tiếng";
    }

    if (hour == 0 && minus != 0) {
      return minus + " phút";
    }

    if (hour != 0 && minus == 0) {
      return hour + " tiếng";
    }

    return hour + " tiếng " + minus + " phút";
  }

  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>{"Ca làm"}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="d-flex" style={{ width: "100%" }}>
        <div className="col-3">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {imageError || (staffInput?.avatar ?? "") === "" ? (
              <div className="avatar-placeholder">
                <div
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  {staffInput?.name && staffInput?.name.charAt(0).toUpperCase()}
                </div>
                <div></div>
              </div>
            ) : (
              <img
                src={staffInput?.avatar}
                alt="Avatar"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>
          {itemData(
            <BsCalendar3></BsCalendar3>,
            `${shift?.name ?? ""}`,
            "Tên ca làm"
          )}
          {itemData(
            <MdTimer style={{ fontSize: "20px" }}></MdTimer>,
            `${convertNumberToDateStr(
              shift?.start_work_hour,
              shift?.start_work_minute
            )} - ${convertNumberToDateStr(
              shift?.end_work_hour,
              shift?.end_work_minute
            )}`,
            "Giờ làm việc"
          )}
          {itemData(
            <FaArrowsAltH></FaArrowsAltH>,
            timeBetWeen(
              `${convertNumberToDateStr(
                shift?.start_work_hour,
                shift?.start_work_minute
              )}`,
              `${convertNumberToDateStr(
                shift?.end_work_hour,
                shift?.end_work_minute
              )}`,
              `${convertNumberToDateStr(
                shift?.start_break_hour,
                shift?.start_break_minute
              )}`,
              `${convertNumberToDateStr(
                shift?.end_break_hour,
                shift?.end_break_minute
              )}`
            ),
            "Độ dài ca"
          )}
          {itemData(
            <FaCodeBranch></FaCodeBranch>,
            `${
              areas.data?.filter((area) => area.id == shift?.area_id)[0]
                ?.name ?? "---:---"
            }`,
            "Chi nhánh"
          )}
        </div>
        <div className="col-9" style={{ borderLeft: "1px solid #d6d8e0" }}>
          <div
            style={{ fontSize: "22px", fontWeight: "bold", marginTop: "30px" }}
          >
            {`${staffInput?.name} - ${moment(staffInput?.date).format(
              FORMAT_DATE
            )}`}
          </div>
          <div className="my-3">VÀO/RA</div>
          <div className="d-flex justify-content-between align-item-center">
            <div
              className="d-flex"
              style={{
                alignItems: "center",
                color: "#1ecc78",
                fontWeight: "bold",
              }}
            >
              <div>
                {(shift?.histories ?? []).length > 0
                  ? moment(new Date(shift?.histories[0].time_tracking)).format(
                      "HH:mm:ss"
                    )
                  : ""}
              </div>
              <MdPhoneAndroid></MdPhoneAndroid>
            </div>
            <div
              className="d-flex"
              style={{ alignItems: "center", color: "red", fontWeight: "bold" }}
            >
              <div>
                {shift?.histories.length >= 2 &&
                shift?.histories[shift?.histories.length - 1].is_checkin ===
                  false
                  ? moment(
                      new Date(
                        shift?.histories[
                          shift?.histories.length - 1
                        ].time_tracking
                      )
                    ).format("HH:mm:ss")
                  : "--:--"}
              </div>
              <MdPhoneAndroid></MdPhoneAndroid>
            </div>
            <div
              style={{ color: "#1ecc78", fontWeight: "550", cursor: "pointer" }}
            >
              Sửa thời gian
            </div>
          </div>
          <div className="my-3">LỊCH SỬ</div>
          <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
            <table
              cellPadding={10}
              style={{ borderSpacing: "7px", borderCollapse: "separate" }}
            >
              <colgroup>
                <col style={{ width: "5%", minWidth: 50 }} />
                <col style={{ width: "5%", minWidth: 150 }} />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>
                      <div>
                        <span>STT</span>
                        <span />
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div>
                        <span>Thời gian</span>
                        <span />
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div>
                        <span>Loại thiết bị</span>
                        <span />
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div>
                        <span>Loại</span>
                        <span />
                      </div>
                    </span>
                  </th>
                  <th>
                    <span>
                      <div>
                        <span>Người thực hiện</span>
                        <span />
                      </div>
                    </span>
                  </th>

                  <th>
                    <span>
                      <div>
                        <span>Ghim</span>
                        <span />
                      </div>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(shift?.histories || []).map((e, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <div>
                          {moment(new Date(e?.time_tracking)).format(
                            "HH:mm:ss"
                          )}
                        </div>
                      </td>
                      <td className="">Điện thoại</td>
                      <td className="">
                        <span>GPS</span>
                      </td>
                      <td>
                        <span className="d-flex">
                          {imageError || (staffInput?.avatar ?? "") === "" ? (
                            <div className="avatar-item">
                              <div
                                style={{
                                  fontWeight: "bold",
                                  color: "white",
                                  fontSize: "15px",
                                }}
                              >
                                {staffInput?.name &&
                                  staffInput?.name.charAt(0).toUpperCase()}
                              </div>
                              <div></div>
                            </div>
                          ) : (
                            <img
                              src={staffInput?.avatar}
                              alt="Avatar"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "20%",
                                objectFit: "cover",
                                border: "1px solid #ccc",
                              }}
                              onError={onError}
                            />
                          )}
                          <span className="ml-2 mt-1">{staffInput?.name}</span>
                        </span>
                      </td>
                      <span>
                        {" "}
                        {e.area_tracking ??
                          areas.data?.filter((area) => area.id == e?.area_id)[0]
                            ?.name ??
                          "---:---"}
                      </span>
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ChooseShiftModal
        visible={modal.visible && modal.method === "ADD_SHIFT"}
        shiftsInput={(modal.shiftsInput ?? []).map((e) => {
          return { ...e, id: e.shift_id ?? e.id };
        })}
        chooseOne={false}
        onSave={(shiftsData) => {
          // console.log(shiftsData);
          // setShifts(shiftsData);
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></ChooseShiftModal>
    </Modal>
  );
};

export default HistoryCheckInOutModal;
