/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import * as XLSX from "xlsx";

import { Button, Checkbox, Modal, Select, Space, Switch } from "antd";
import {
  exportMasterDataToExcel,
  exportStaffExcel,
} from "../../../../helpers/sheet_staff";
import { ca, da } from "date-fns/locale";
import { shiftServices } from "../../../../services/shiftServices";
import { useSelector } from "react-redux";
import { constants } from "../../../../constants";
import { set } from "lodash";

const ExportExcelModal = ({ open, closeModal, roles, onSuccess }) => {
  const areas = useSelector((state) => state.area.areas);
  const departmentList = useSelector((state) => state.staff.department_list);
  const [exportMaster, setExportMaster] = React.useState(false);

  const onChange = (e) => {
    setDataExport({
      ...dataExport,
      [e.target.name]: e.target.checked,
    });
    console.log(dataExport);
  };
  const [dataExport, setDataExport] = React.useState({
    id: true,
    staff_code: true,
    name: true,
    email: false,
    phone_number: true,
    sex: false,
    position: true,
    department_id: true,
    start_join_time: true,
    area_id: true,
    birthday: false,
    cmnd: false,
    date_range: false,
    issued_by: false,
    permanent_address: false,
    temporary_address: false,
    bank_number: false,
    bank_name: false,
    bank_account_name: false,
    bank_branch: false,
    school: false,
    level: false,
    specialized: false,
    course: false,
    height: false,
    weight: false,
    congenital: false,
    married: false,
    nation: false,
    religion: false,
    role_id: false,
    date_leave: false,
    leave_reason: false,
    healthcare_first_place: false,
    tax_code: false,
    bhyt_code: false,
    /// contract
    type_key: false,
    start: false,
    end: false,
    salary: false,
    BHXH: false,
    tax: false,
    dependent_count: false,
    dependent_another: false,
    position_salary: false,
    support_productive: false,
    support_result: false,
    cong_doan: false,
    pc_food: false,
    pc_phone: false,
    pc_move: false,
    annual_leave: false,
    unpaid_leave: false,
    compassionate_leave: false,
    marriage_leave: false,
    seniority_percent: false,
    seniority_limit: false,
    supports: false,
   
  });

  const fixedDataExport = [
    "id",
    "staff_code",
    "name",
    "phone_number",
    "position",
    "department_id",
    "start_join_time",
    "area_id",
  ];

  const [areaSelected, setAreaSelected] = React.useState(null);

  const areaId = useSelector((state) => state.area.areaId);
  const [loading, setLoading] = React.useState(false);
  const [excelData, setExcelData] = React.useState(null);
  const downloadLinkRef = React.useRef();

  const Option = Select.Option;

  useEffect(() => {
    if (areaId !== null) {
      setAreaSelected(areaId);
    }
  }, [areaId]);

  const exportMasterData = async () => {
    try {
      const resExport = await shiftServices.exportMasterData(
        areaSelected == constants.AREA_ALL_ID ? -1 : areaSelected
      );
      exportMasterDataToExcel(resExport.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const exportStaff = async () => {
    if (exportMaster) {
      exportMasterData();
      return;
    }

    setLoading(true);
    try {
      const resExport = await shiftServices.exportExcel({
        areaId: areaSelected == constants.AREA_ALL_ID ? -1 : areaSelected,
      });

      var keyShow = [];

      for (const key in dataExport) {
        if (dataExport[key] === true) {
          keyShow.push(key);
        }
      }

      console.log(keyShow);
      console.log(resExport.data);

      exportStaffExcel(
        resExport.data,
        keyShow,
        areas.data,
        departmentList.data,
        roles,
        () => {
          closeModal();
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={"Chọn các trường cần xuất excel:"}
      centered
      open={open}
      width={800}
      style={{ top: 20 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {}}
      footer={[
        <Button
          key="back"
          onClick={() => {
            closeModal();
          }}
        >
          Thoát
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            exportStaff();
          }}
        >
          {loading ? "Đang export data" : "Export"}
        </Button>,
      ]}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <Space>
          <p>Xuất file Master:</p>
          <Switch
            checked={exportMaster}
            onChange={(v) => {
              setExportMaster(v);
            }}
          />
          <p>Chi nhánh:</p>
          <Select
            placeholder="Chọn chi nhánh"
            value={areaSelected}
            style={{ width: "150px" }}
            onChange={(v) => {
              setAreaSelected(v);
            }}
          >
            {(areas?.data ?? []).map((e) => {
              return <Option value={e.id}>{e.name}</Option>;
            })}
          </Select>
        </Space>
        <div style={{ height: 20 }}></div>
        {!exportMaster && (
          <Checkbox
            name="all"
            onChange={(v) => {
              const newDataExport = { ...dataExport };
              for (const key in newDataExport) {
                if (fixedDataExport.includes(key)) continue;
                if (Object.hasOwnProperty.call(newDataExport, key)) {
                  newDataExport[key] = v.target.checked;
                }
              }
              setDataExport(newDataExport);
              console.log(newDataExport);
            }}
            style={{ fontWeight: 600 }}
          >
            Chọn tất cả
          </Checkbox>
        )}
        {!exportMaster && <div style={{ height: 20 }}></div>}
        {!exportMaster && (
          <Space size="large" align="start">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <b className="mb-2">Thông tin nhân viên:</b>
              <Space align="start">
                <Space direction="vertical">
                  <Checkbox
                    checked={dataExport.email}
                    name="email"
                    onChange={onChange}
                  >
                    Email
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.sex}
                    name="sex"
                    onChange={onChange}
                  >
                    Giới tính
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.role_id}
                    name="role_id"
                    onChange={onChange}
                  >
                    Phân quyền
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.birthday}
                    name="birthday"
                    onChange={onChange}
                  >
                    Ngày sinh
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.cmnd}
                    name="cmnd"
                    onChange={onChange}
                  >
                    Số CMND/CCCD
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.issued_by}
                    name="issued_by"
                    onChange={onChange}
                  >
                    Nơi cấp
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.date_range}
                    name="date_range"
                    onChange={onChange}
                  >
                    Ngày cấp
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.permanent_address}
                    name="permanent_address"
                    onChange={onChange}
                  >
                    Địa chỉ thường trú
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.temporary_address}
                    name="temporary_address"
                    onChange={onChange}
                  >
                    Địa chỉ tạm trú
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.nation}
                    name="nation"
                    onChange={onChange}
                  >
                    Dân tộc
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.religion}
                    name="religion"
                    onChange={onChange}
                  >
                    Tôn giáo
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.bank_name}
                    name="bank_name"
                    onChange={onChange}
                  >
                    Tên ngân hàng
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.bank_number}
                    name="bank_number"
                    onChange={onChange}
                  >
                    Số tài khoản
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.bank_account_name}
                    name="bank_account_name"
                    onChange={onChange}
                  >
                    Tên người thụ hưởng
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.bank_branch}
                    name="bank_branch"
                    onChange={onChange}
                  >
                    Tên chi nhánh
                  </Checkbox>
                </Space>
                <Space direction="vertical">
                  <Checkbox
                    checked={dataExport.school}
                    name="school"
                    onChange={onChange}
                  >
                    Trường học
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.level}
                    name="level"
                    onChange={onChange}
                  >
                    Trình độ học vấn
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.specialized}
                    name="specialized"
                    onChange={onChange}
                  >
                    Chuyên ngành
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.course}
                    name="course"
                    onChange={onChange}
                  >
                    Khoá
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.height}
                    name="height"
                    onChange={onChange}
                  >
                    Chiều cao (cm)
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.weight}
                    name="weight"
                    onChange={onChange}
                  >
                    Cân nặng (kg)
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.congenital}
                    name="congenital"
                    onChange={onChange}
                  >
                    Bệnh bẩm sinh
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.married}
                    name="married"
                    onChange={onChange}
                  >
                    Đã kết hôn
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.tax_code}
                    name="tax_code"
                    onChange={onChange}
                  >
                    Mã số thuế
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.date_leave}
                    name="date_leave"
                    onChange={onChange}
                  >
                    Ngày nghỉ việc
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.leave_reason}
                    name="leave_reason"
                    onChange={onChange}
                  >
                    Lý do nghỉ việc
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.bhyt_code}
                    name="bhyt_code"
                    onChange={onChange}
                  >
                    Mã số BHYT
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.healthcare_first_place}
                    name="healthcare_first_place"
                    onChange={onChange}
                  >
                    Nơi đăng ký KCB ban đầu
                  </Checkbox>
                </Space>
              </Space>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <b className="mb-2">Hợp đồng nhân viên:</b>
              <Space align="start">
                <Space direction="vertical">
                  <Checkbox
                    checked={dataExport.type_key}
                    name="type_key"
                    onChange={onChange}
                  >
                    Loại hợp đồng
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.start}
                    name="start"
                    onChange={onChange}
                  >
                    Ngày bắt đầu
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.end}
                    name="end"
                    onChange={onChange}
                  >
                    Ngày kết thúc
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.support_productive}
                    name="support_productive"
                    onChange={onChange}
                  >
                    Mức lương P2
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.pc_food}
                    name="pc_food"
                    onChange={onChange}
                  >
                    Mức lương P3
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.pc_move}
                    name="pc_move"
                    onChange={onChange}
                  >
                    Phụ cấp đi lại
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.support_result}
                    name="support_result"
                    onChange={onChange}
                  >
                    Phụ cấp ăn trưa
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.pc_phone}
                    name="pc_phone"
                    onChange={onChange}
                  >
                    Phụ cấp điện thoại
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.cong_doan}
                    name="cong_doan"
                    onChange={onChange}
                  >
                    Tham gia công đoàn
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.salary}
                    name="salary"
                    onChange={onChange}
                  >
                    Lương theo giờ
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.position_salary}
                    name="position_salary"
                    onChange={onChange}
                  >
                    Lương vị trí
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.seniority_limit}
                    name="seniority_limit"
                    onChange={onChange}
                  >
                    Hạn mức phụ cấp thâm niên
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.seniority_percent}
                    name="seniority_percent"
                    onChange={onChange}
                  >
                    % Phụ cấp thâm niên
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.BHXH}
                    name="BHXH"
                    onChange={onChange}
                  >
                    Bảo hiểm
                  </Checkbox>
                </Space>
                <Space direction="vertical">
                  <Checkbox
                    checked={dataExport.tax}
                    name="tax"
                    onChange={onChange}
                  >
                    Thuế
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.dependent_another}
                    name="dependent_another"
                    onChange={onChange}
                  >
                    Khoản miễn thuế khác
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.dependent_count}
                    name="dependent_count"
                    onChange={onChange}
                  >
                    Số người phụ thuộc
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.supports}
                    name="supports"
                    onChange={onChange}
                  >
                    Phụ cấp
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.annual_leave}
                    name="annual_leave"
                    onChange={onChange}
                  >
                    Ngày nghỉ có lương
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.unpaid_leave}
                    name="unpaid_leave"
                    onChange={onChange}
                  >
                    Ngày nghỉ không lương
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.compassionate_leave}
                    name="compassionate_leave"
                    onChange={onChange}
                  >
                    Ngày nghỉ tang
                  </Checkbox>
                  <Checkbox
                    checked={dataExport.marriage_leave}
                    name="marriage_leave"
                    onChange={onChange}
                  >
                    Ngày kết hôn
                  </Checkbox>
                </Space>
              </Space>
            </div>
          </Space>
        )}
      </div>
    </Modal>
  );
};

export default ExportExcelModal;
