import { constants as c } from "../constants";
import { userServices as s } from "../services/userServices";
import { appServices as a } from "../services/appServices";
import history from "../history";
import { areaAction } from "./areaAction";
import { staffAction } from "./staffActions";

function messageInfo(res) {
  return {
    type: c.UID_SUCCESS,
    info: {
      status: res.success,
      msg: res.msg,
    },
  };
}

function accountLogin(info) {
  return (dispatch) => {
    s.accountLogin(info).then((res) => {
      if (res.code === 201) {
        dispatch(success(res));
      } else {
        dispatch(messageInfo(res));
        dispatch(failure(res.msg));
      }
    });
  };
  function success(res) {
    localStorage.setItem("tokenInfo", JSON.stringify(res.data.token));
    localStorage.setItem("userInfo", JSON.stringify(res.data.user));
    window.location.href = `/`;
  }
  function failure(msg) {
    return { type: c.LOGIN_FAILURE, msg };
  }
}

function accountUpdate(imgUpload, info) {
  return async (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    var avatar = info.avatar;
    if (imgUpload) {
      var resImage = await a.uploadImg(imgUpload);
      if (resImage.success === true) {
        avatar = resImage.data.image_url;
      } else {
        dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
        return;
      }
    }
    var res = await s.accountUpdate({ ...info, avatar });
    dispatch(messageInfo(res));
    if (res.code === 200 || res.code === 201) {
      dispatch(success(res.data));
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
    } else {
      dispatch(failure(res.msg));
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
    }
    function success(data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      return { type: c.GET_PROFILE, data: data };
    }
    function failure(msg) {
      return { type: c.ACCOUNT_UPDATE_FAILURE, msg };
    }
  };
}

function accountChangePw(info) {
  return (dispatch) => {
    s.accountChangePw(info).then((res) => {
      dispatch(messageInfo(res));
      if (res.code === 201) {
        {
          window.$(".modal").modal("hide");
        }
      } else {
        dispatch(failure(res.msg, res.code));
      }
    });
  };

  function failure(msg, code) {
    return { type: c.CHANGE_PASSWORD_FAILURE, msg, code };
  }
}

function getUserInfo() {
  return (dispatch) => {
    s.getUserInfo().then((res) => {
      if (res.success === false) {
        {
          dispatch({
            type: c.GET_PROFILE_FAILURE,
            data: {},
          });
        }
      } else {
        dispatch({
          type: c.GET_PROFILE,
          data: res.data,
        });
        dispatch(userActions.getReportNumber(res?.data?.area_id, true));
        dispatch(staffAction.getAllDepartments());
        localStorage.setItem("userInfo", JSON.stringify(res?.data));
      }
    });
  };
}

function getReportNumber(areaId, init) {
  return (dispatch) => {
      if (areaId == c.AREA_ALL_ID) {
        s.getReportNumberManager().then((res) => {
          if (res.success === false) {
            {
              dispatch({
                type: c.GET_REPORT_NUMBER_FAILURE,
                data: {},
              });
            }
          } else {
            if (init === true) {
              dispatch(areaAction.getAllArea(res.data, areaId, init));
            }
            dispatch({
              type: c.GET_REPORT_NUMBER,
              data: res.data,
            });
          }
        });
      } else {
        s.getReportNumber(areaId).then((res) => {
          if (res.success === false) {
            {
              dispatch({
                type: c.GET_REPORT_NUMBER_FAILURE,
                data: {},
              });
            }
          } else {
            if (init === true) {
              dispatch(areaAction.getAllArea(res.data, areaId, init));
             
            }
            dispatch({
              type: c.GET_REPORT_NUMBER,
              data: res.data,
            });
            
          }
        });
      }

   
  };
}

function forgotPassword(info) {
  return (dispatch) => {
    s.checkPhoneEmail(info).then((res) => {
      dispatch(messageInfo(res));

      if (res.code === 201) {
        history.push(`/forgot_password/otp?phone_number=${info.phone_number}`);
      } else {
      }
    });
  };
}

function getPassword(info) {
  return (dispatch) => {
    s.getPassword(info).then((res) => {
      dispatch(messageInfo(res));

      if (res.code === 201) {
        history.push(`/login`);
      } else {
      }
    });
  };
}

export const userActions = {
  accountLogin,
  accountUpdate,
  accountChangePw,
  getUserInfo,
  forgotPassword,
  getPassword,
  getReportNumber
};
