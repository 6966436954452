import React, { useState, useEffect } from "react";
import ModalUID from "../../../components/ModalUID";
import "./style.css";
import { useHistory } from "react-router-dom";
import ViewDate from "../../../components/ViewDate";
import { shiftServices } from "../../../services/shiftServices";
import moment from "moment";
import { constants } from "../../../constants";
import { convertNumberToDateStr } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { BiFirstPage, BiExpandHorizontal } from "react-icons/bi";
import { toast } from "react-toastify";
import PermissionChecker from "../../../components/PermissionChecker";
import { Space } from "antd";

const WorkSchedule = () => {
  const [modal, setModal] = useState({
    title: "Thông báo",
    method: "UPDATE",
    content: "",
  });

  const [calendarShift, setCalendarShift] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesInit, setEmployeesInit] = useState([]);
  const [dateByType, setDateByType] = useState([new Date()]);
  const [shiftSelected, setShiftSelected] = useState([]);
  const [dateSelected, setDateSelected] = useState(null);
  const [checkedEmployee, setCheckedEmployee] = useState(false);
  const [listHide, setListHide] = useState([]);
  const [dateSearch, setDateSearch] = useState(null);
  const areaId = useSelector((state) => state.area.areaId);
  const dispatch = useDispatch();
  const reportNumber = useSelector((state) => state.reportNumber.report);

  useEffect(() => {
    if (dateSearch !== null) {
      fetchCalendarShift(dateSearch);
      getDateByType(dateSearch);
    }
  }, [dateSearch, areaId]);

  useEffect(() => {
    fetchCalendarShift({
      startDate: getFirstAndLastDayInMonth().firstDay,
      endDate: getFirstAndLastDayInMonth().lastDay,
    });
    fetchEmployees(areaId);
  }, [areaId]);

  useEffect(() => {
    if (shiftSelected.length < employees.length) {
      setCheckedEmployee(false);
    } else {
      setCheckedEmployee(true);
    }
  }, [shiftSelected, employees]);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const fetchCalendarShift = async (date) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      let date_from = moment(date.startDate).format(
        constants.FORMAT_YYYY_MM_DD
      );
      let date_to = moment(date.endDate).format(constants.FORMAT_YYYY_MM_DD);
      const response = await shiftServices.fetchCalendarShift(
        areaId,
        `?date_from=${date_from}&date_to=${date_to}`
      );
      setCalendarShift(response.data);

      var listHideNew = [];

      for (let i = 0; i < response.data.length; i++) {
        let shouldContinue = false;
        const timekeeping = response.data[i].timekeeping;
        for (let e = 0; e < timekeeping.length; e++) {
          if (timekeeping[e].staff_work.length !== 0) {
            shouldContinue = true;
            break;
          }
        }
        if (shouldContinue) {
          continue;
        } else {
          listHideNew.push(i);
        }
      }
      setListHide(listHideNew);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };
  const fetchEmployees = async (areaID) => {
    try {
      const response = await shiftServices.fetchEmployeeByArea(areaID);

      const staffByAreaId = response.data.filter(
        (staff) => staff.area_id === areaID
      );

      setEmployees(staffByAreaId);
      setEmployeesInit(staffByAreaId);
    } catch (error) {
      console.log(error);
    }
  };

  const getDateByType = (date) => {
    let result = [];
    if (date.typeDate === constants.DAY) {
      result.push(date.startDate);
    } else if (date.typeDate === constants.WEEK) {
      const first = date.startDate;
      for (let i = 0; i <= 6; i++) {
        result.push(moment(first).add(i, "days").toDate());
      }
    } else {
      const y = date.startDate.getFullYear(),
        m = date.startDate.getMonth();
      const firstDay = new Date(y, m, 1);
      const lastDay = new Date(y, m + 1, 0).getDate();
      for (let i = 0; i < lastDay; i++) {
        result.push(moment(firstDay).add(i, "days").toDate());
      }
    }
    setDateByType(result);
  };

  const handleChoiceAllEmployee = (checked) => {
    let staffIds = [];
    if (checked) {
      staffIds = employees?.map((staff) => {
        return staff.id;
      });
    } else {
      staffIds = [];
    }
    setCheckedEmployee(checked);
    setShiftSelected(staffIds);
  };

  const handleChoiceEmployee = (emp) => {
    if (moment(emp?.contract.end).isAfter(moment.now()) == true) {
      let arrShiftSelected = [...shiftSelected];
      let id = emp.id;
      if (arrShiftSelected.length === 0) {
        arrShiftSelected.push(id);
      } else {
        const shift = arrShiftSelected.find((shift) => shift === id);
        if (shift) {
          arrShiftSelected = arrShiftSelected.filter((shift) => shift !== id);
        } else {
          arrShiftSelected.push(id);
        }
      }
      setShiftSelected(arrShiftSelected);
    } else {
      toast.error("Nhân viên đã hết hạn hợp đồng");
     
    }
  };

  const handleUpdateEmployee = async () => {
    try {
      const body = {
        list_staff_id: shiftSelected,
        shift_id: dateSelected.shiftId,
        date: dateSelected.date,
      };
      const response = await shiftServices.updateEmployeeForShift(areaId, body);

      if (response.code === 200) {
        fetchCalendarShift(dateSearch);
        setShiftSelected([]);
        toast.success(response.msg);
     
        window.$(".modal").modal("hide");
      } else {
        toast.error(response.msg);
       
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDayNameFromDate = (date) => {
    const days = new Map([
      ["Monday", "Thứ 2"],
      ["Tuesday", "Thứ 3"],
      ["Wednesday", "Thứ 4"],
      ["Thursday", "Thứ 5"],
      ["Friday", "Thứ 6"],
      ["Saturday", "Thứ 7"],
      ["Sunday", "Chủ nhật"],
    ]);
    return days.get(moment(date).format("dddd"));
  };

  function onChangeDepartment(e) {
    var { value } = e.target;

    if (value == "") {
      setEmployees(employeesInit);
    } else {
      const staffByDepartment = employeesInit.filter(
        (staff) => staff.department_id == e.target.value
      );

      setEmployees(staffByDepartment);
    }
    setFilterDepartment(value);
  }

  const [filterDepartment, setFilterDepartment] = useState();
  const departmentList = useSelector((state) => state.staff.department_list);
  const history = useHistory();

  const ModalContent = () => (
    <div className="view-employee">
      <div className="d-flex  justify-content-between align-items-center">
        <div
          className="sorting_disabled"
          rowSpan={1}
          colSpan={1}
          aria-label="Phòng ban"
          style={{ maxWidth: "200px" }}
        >
          <select
            name="deparment_id"
            value={filterDepartment}
            onChange={onChangeDepartment}
            type="text"
            class="form-control"
            id="txtPhone_branch"
          >
            <option value="" selected>
              --Phòng ban--
            </option>
            <option value="">Tất cả</option>
            {departmentList.data?.map((v, i) => {
              return <option value={v.id}>{v.name}</option>;
            })}
          </select>
        </div>
        <div>
          {shiftSelected?.length || 0}/{employees?.length} Nhân viên
        </div>

        <div className="d-flex align-items-center">
          <span className="mr-2">Tất cả nhân viên</span>
          <input
            type="checkbox"
            value={checkedEmployee}
            checked={checkedEmployee}
            onChange={(e) => handleChoiceAllEmployee(e.target.checked)}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap mt-4">
        {employees?.map((employee) => {
          const checkEmployee = shiftSelected?.findIndex(
            (shift) => shift === employee?.id
          );
          return (
            <div
              key={employee?.id}
              onClick={() => handleChoiceEmployee(employee)}
              className="employee-box"
            >
              <div className={checkEmployee !== -1 ? "active" : ""}>
                {employee?.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
      >
        <Space size={'middle'}>
          <h4 className="h4 title_content mt-2 text-gray-800">
            Sắp xếp lịch làm việc
          </h4>
          <ViewDate
            className="d-flex"
            type={constants.MONTH}
            handleChange={(date) => {
              setDateSearch(date);
            }}
          />
        </Space>
        {PermissionChecker({
          decent: reportNumber.permissions?.calender_shifts_add ?? false,
          child: (
            <button
              style={{
                border: "1px solid",
              }}
              class="btn btn-primary btn-sm"
              type="button"
              onClick={() => history.push("/work-chedule/putALot")}
            >
              <span class="icon text-white mr-1">
                <i class="fas fa-plus"></i>
              </span>
              Xếp ca
            </button>
          ),
        })}
      </div>
      <div className="pt-3">
        <div
          style={{
            height: `85vh`,
            overflow: "auto",
          }}
        >
          <table class="table table-work-schedule">
            <tbody>
              <tr
                style={{
                  position: "sticky",
                  left: 0,
                  backgroundColor: "white",
                  zIndex: 100,
                }}
              >
                <th
                  scope="row"
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    background: "white",
                    border: "1px solid #d9d7ce",
                  }}
                >
                  Lịch
                </th>
                {calendarShift[0]?.timekeeping?.map((t, index) => {
                  return (
                    <td key={index}>
                      {getDayNameFromDate(t.date)}, {}
                      {moment(t.date).format(constants.FORMAT_DD_MM_YYYY)}
                    </td>
                  );
                })}
              </tr>
              {calendarShift?.length > 0 &&
                calendarShift?.map((calender, index) => {
                  return listHide.includes(index) == true ? (
                    <tr
                      onClick={() => {
                        setListHide(listHide.filter((i) => i !== index));
                      }}
                    >
                      <th
                        scope="row"
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 999,
                          background: "white",
                          border: "1px solid #d9d7ce",
                        }}
                      >
                        <BiExpandHorizontal
                          size={20}
                          color={"blue"}
                        ></BiExpandHorizontal>
                      </th>
                      {calender?.timekeeping?.map((t, index) => {
                        const date = moment(t.date).format(
                          constants.FORMAT_YYYY_MM_DD
                        );
                        const current = moment(new Date()).format(
                          constants.FORMAT_YYYY_MM_DD
                        );
                        return (
                          <td style={{ width: "40px", minWidth: "unset" }}></td>
                        );
                      })}
                    </tr>
                  ) : (
                    <tr key={calender?.id}>
                      <th
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 999,
                          background: "white",
                          border: "1px solid #d9d7ce",
                        }}
                        scope="row"
                        onClick={() => {
                          const listHideNew = [...listHide, index];
                          console.log(listHideNew);
                          setListHide(listHideNew);
                          console.log(listHide.includes(index));
                        }}
                      >
                        <span className="d-flex">
                          {" "}
                          <div>
                            {calender?.name} (
                            {convertNumberToDateStr(
                              calender?.start_work_hour,
                              calender?.start_work_minute
                            )}
                            -
                            {convertNumberToDateStr(
                              calender?.end_work_hour,
                              calender?.end_work_minute
                            )}
                            )
                          </div>
                          <BiFirstPage size={20} color={"red"}></BiFirstPage>
                        </span>
                      </th>
                      {calender?.timekeeping?.map((t, index) => {
                        const date = moment(t.date).format(
                          constants.FORMAT_YYYY_MM_DD
                        );
                        const current = moment(new Date()).format(
                          constants.FORMAT_YYYY_MM_DD
                        );
                        return (
                          <td
                            key={index}
                            onClick={() => {
                              if (
                                (reportNumber.permissions
                                  ?.calender_shifts_edit ?? false) === true
                              ) {
                                window.$(".modal").modal("show");
                                setModal({
                                  ...modal,
                                  title: `${
                                    calender?.name
                                  } (${convertNumberToDateStr(
                                    calender?.start_work_hour,
                                    calender?.start_work_minute
                                  )}
                          - ${convertNumberToDateStr(
                            calender?.end_work_hour,
                            calender?.end_work_minute
                          )}
                          )${getDayNameFromDate(t.date)}, ${
                                    date.split("-")[2]
                                  } tháng ${date.split("-")[1]} ${
                                    date.split("-")[0]
                                  }`,
                                });
                                if (t.date) {
                                  const staffIds = t?.staff_work?.map(
                                    (staff) => {
                                      return staff.id;
                                    }
                                  );
                                  setShiftSelected(staffIds);
                                }
                                setDateSelected({
                                  date: date,
                                  shiftId: calender?.id,
                                });
                              } else {
                                toast.error("Bạn không có quyền truy cập");
                              }
                            }}
                          >
                            {t?.staff_work?.length > 0 ? (
                              t?.staff_work?.map((staff, index) => {
                                return (
                                  <div key={staff.id}>
                                    <div>{index < 3 && staff.name}</div>
                                  </div>
                                );
                              })
                            ) : (
                              <span style={{ opacity: 0 }}>1</span>
                            )}
                            <div className="d-flex justify-content-end">
                              <span className="extra-employee">
                                {t?.staff_work?.length > 3 &&
                                  `...+${t?.staff_work?.length - 3} Nhân viên`}
                              </span>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <ModalUID
        size={modal.size}
        method={modal.method}
        title={modal.title}
        handleOnSave={() => handleUpdateEmployee()}
        showFooter={modal.showFooter}
      >
        <ModalContent />
      </ModalUID>
    </div>
  );
};

export default WorkSchedule;
