import { constants as c } from "../constants";

const initialState = {
  report: {
    data: [],
    status: c.NONE,
  },
};
export function reportNumber(state = initialState, action) {
  switch (action.type) {
    case c.GET_REPORT_NUMBER:
      return {
        ...state,
        report: { ...action.data, status: c.SUCCESS },
      };
    case c.GET_REPORT_NUMBER_FAILURE:
      return {
        ...state,
        report: { status: c.FAILURE },
      };

    default:
      return state;
  }
}
