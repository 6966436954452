import React, { useEffect, useState } from "react";
import { Space, Table, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import moment from "moment";
import { useSelector } from "react-redux";
import PermissionChecker from "../../../components/PermissionChecker";
const { Search } = Input;

export default function FranchiseContactPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(null);

  const getContact = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getContact(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );
      setContacts(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteContact = async (id) => {
    try {
      const response = await franchiseServices.deleteContact(id);
      getContact(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <a>{record?.title ?? "--:--"}</a>,
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Hình ảnh",
      dataIndex: "images_url",
      key: "images_url",
      render: (_, record) => (
        <div>
          {record?.images_url ??
            []?.map((e) => {
              return (
                <img
                  src={e}
                  style={{
                    height: "50px",
                    width: "50px",
                    paddingRight: "10px",
                    fit: "cover",
                    borderRadius: "10px",
                  }}
                ></img>
              );
            })}
        </div>
      ),
    },

    {
      title: "Ngày hỏi",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <a>{moment(record.created_at).format("DD-MM-YYYY HH:mm") ?? "--:--"}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_contact_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá câu hỏi ?"
                description="Bạn có chắc chắn muốn xoá câu hỏi này chứ ?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteContact(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm câu hỏi"
          onSearch={(v) => {
            getContact(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            style: {
              background: record.status === 0 ? "#f6f6f6" : "default",
            },
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: record.data,
              });
            },
          };
        }}
        dataSource={(contacts?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            title: e.title ?? "--:--",
            content: e.content ?? "--:--",
            images_url: e.images_url ?? "--:--",
            created_at: e?.created_at,
            data: e,
          };
        })}
        pagination={{
          total: contacts?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getContact(e.current);
        }}
      />
    </div>
  );
}
