export default function ReplyIcon({ className, ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <g clip-path="url(#clip0_500_21)">
        <path
          d="M15.5 14.6668C15.32 14.6668 15.1507 14.5695 15.0613 14.4062L15.0473 14.3808C13.552 11.6802 10.7027 10.0002 7.612 10.0002H6.66667V12.8335C6.66667 13.0342 6.546 13.2155 6.36133 13.2942C6.178 13.3715 5.962 13.3328 5.81867 13.1922L0.152 7.69218C0.0546667 7.59818 0 7.46884 0 7.33351C0 7.19818 0.0546667 7.06884 0.152 6.97484L5.81867 1.47484C5.96267 1.33484 6.178 1.29484 6.36133 1.37284C6.546 1.45151 6.66667 1.63284 6.66667 1.83351V4.66817C11.8287 4.75751 16 8.98418 16 14.1668C16 14.3955 15.8453 14.5948 15.624 14.6515C15.5827 14.6622 15.5413 14.6668 15.5 14.6668ZM6.16667 9.00018H7.612C10.396 9.00018 13.0047 10.2195 14.788 12.2742C13.9253 8.49484 10.5367 5.66684 6.5 5.66684H6.16667C5.89067 5.66684 5.66667 5.44284 5.66667 5.16684V3.01551L1.218 7.33351L5.66667 11.6515V9.50018C5.66667 9.22418 5.89067 9.00018 6.16667 9.00018Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_21">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
