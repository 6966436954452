import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledMessage } from "./Message.style";
import { handleShowTime, handleShowTimeMess } from "../../../../../ultis/date";
import { chatActions as a } from "../../../../../actions/chatADActions";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Dropdown, Image } from "antd";
import { toast } from "react-toastify";
import {
  CopyIcon,
  DeleteIcon,
  PinIcon,
  ReplyIcon,
  ShareIcon,
} from "../../icons";
import { FileDownloader } from "../fileDownloader";

const typeMess = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "custom",
  VIDEO: "video",
};

const Message = ({ message, nextMessage, prevMessage, handleMoveToMessage }) => {
  const dispatch = useDispatch();
  const roomSelected = useSelector((state) => state.chatAD.roomSelected);
  const profile = useSelector((state) => state.user.profile);

  const { is_group } = roomSelected;
  const {
    content,
    updated_at,
    created_at,
    status,
    type,
    user,
    message_reply,
    message_file,
    hide_all,
  } = message;
  const { id, avatar, latest_req_time, name } = user;

  const isShowAvatar = nextMessage?.user?.id === id;
  const isSamePerson = prevMessage?.user?.id === id;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.success("Đã sao chép tin nhắn");
      })
      .catch((error) => {
        toast.error("Có lỗi sao chép:", error);
      });
  };

  const onSelectReplyMessage = () => {
    dispatch(a.handleSelectReplyMessage(message));
  };

  const onPinMessage = () => {
    const params = {
      room_chat_id: roomSelected.id,
      message_id: message.id,
    };
    dispatch(a.handleAddPinMessage(params));
  };

  const onDeleteMessage = () => {
    const messageUpdate = {
      ...message,
      hide_for_user: true,
      hide_all: true,
      user_id: message.user.id,
      message_id: message.id,
    };
    dispatch(a.updateMessage(messageUpdate));
  };

  const onRedirectMessage = () => {
    dispatch(
      a.toggleModalSelectsUser({ isOpen: true, type: "forward", data: message })
    );
  };

  const items = [
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={onSelectReplyMessage}
        >
          <ReplyIcon style={{ color: "#72777B" }} />
          <p style={{ marginLeft: "16px" }}>Trả lời</p>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={copyToClipboard}
        >
          <CopyIcon />
          <p style={{ marginLeft: "16px" }}>Copy tin nhắn</p>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="d-flex align-item-center gap-3" onClick={onPinMessage}>
          <PinIcon />
          <p style={{ marginLeft: "16px" }}>Ghim</p>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          className="d-flex align-item-center gap-3"
          onClick={onRedirectMessage}
        >
          <ShareIcon />
          <p style={{ marginLeft: "16px" }}>Chuyển tiếp</p>
        </div>
      ),
      key: "3",
    },
    {
      label:
        profile.id === message.user.id ? (
          <div
            className="d-flex align-item-center action-item__delete"
            onClick={onDeleteMessage}
          >
            <DeleteIcon />
            <p style={{ marginLeft: "16px" }}>Thu hồi</p>
          </div>
        ) : null,
      key: "4",
    },
  ];

  const renderContentMess = () => {
    if (typeMess.TEXT === type)
      return (
        <pre
          className="message-content__content"
          style={{ fontFamily: "Mulish, sans-serif", whiteSpace: "pre-wrap" }}
        >
          {content}
        </pre>
      );
    if (typeMess.IMAGE === type) {
      return (
        <div className="message-content__content">
          <Image
            src={message_file.length ? message_file[0].url_file : ""}
            alt="image"
          />
        </div>
      );
    }
    if (typeMess.FILE === type) {
      return (
        <div className="message-content__content">
          <FileDownloader
            fileUrl={message_file.length ? message_file[0].url_file : ""}
            fileName={message_file[0]?.name}
          />
        </div>
      );
    }
    if (typeMess.VIDEO === type) {
      return (
        <div className="message-content__content">
          <video
            src={message_file.length ? message_file[0].url_file : ""}
            controls
            style={{ width: "300px" }}
          />
        </div>
      );
    }
  };

  const handleShowContentReply = (lastMessage, type) => {
    switch (type) {
      case "image":
        return "Hình ảnh";
        case "video":
        return "Video";
      case "custom":
        return "Tệp đính kèm";
      default:
        return lastMessage;
    }
  }

  return (
    <StyledMessage>
      <div
        className={`message ${isShowAvatar ? "isShowAvatar" : ""} ${
          profile?.id === id ? "send" : ""
        } ${isSamePerson ? "isSamePerson" : ""}`}
      >
        {profile?.id !== id && (
          <div className="message-avatar">
            <img
              src={
                avatar ||
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
              }
              alt="avatar"
            />
          </div>
        )}
        <div
          className={`message-content ${profile?.id === id ? "send" : ""} ${
            type === typeMess.TEXT || type === typeMess.FILE ? "isText" : ""
          } ${hide_all ? "hide-all" : ""}`}
        >
          {hide_all ? (
            <>
              {is_group && !isShowAvatar && (
                <p className="message-content__name">{name}</p>
              )}
              <p className="message-revoked">Tin nhắn đã được thu hồi</p>
            </>
          ) : (
            <>
              {message_reply?.id && (
                <div className="message-reply" onClick={() => handleMoveToMessage(message_reply.id)}>
                  <div className="message-reply__content">
                    <p style={{ fontWeight: "600" }}>
                      {message_reply.user.name}
                    </p>
                    <p style={{ marginTop: "2px", color: "#7589a3" }}>
                      {handleShowContentReply(message_reply.content, message_reply.type)}
                    </p>
                  </div>
                </div>
              )}
              {is_group && !isShowAvatar && (
                <p className="message-content__name">{name}</p>
              )}
              {renderContentMess()}
              <p className="message-content__time">
                {handleShowTimeMess(created_at)}
              </p>
              {/* <div className="message-content__action">
                <Dropdown
                  menu={{ items }}
                  trigger={["hover"]}
                  placement={profile?.id === id ? "bottomRight" : "bottomLeft"}
                >
                  <MoreHorizIcon fontSize="small" color="#7589a3" />
                </Dropdown>
              </div> */}
            </>
          )}
        </div>
      </div>
    </StyledMessage>
  );
};
export default React.memo(Message);
