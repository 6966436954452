import React, { useState } from "react";
import moment from "moment";
import { constants } from "../../../constants";
import { IoMdAddCircleOutline } from "react-icons/io";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";

export default function HistoryItem({ data, index, onClick }) {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  return (
    <tr
      style={{ height: "auto", borderBottom: "1px solid lightgrey" }}
    
    >
      <td className="text-start">{index + 1}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="py-3" style={{ marginRight: "16px" }}>
            {imageError || data?.staff_action?.avatar === null ? (
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f6c23e",
                  marginRight: "0px",
                }}
              >
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {`${data?.staff_action?.name ?? ""}`.charAt(0).toUpperCase()}
                </div>
              </div>
            ) : (
              <img
                src={data?.staff_action?.avatar ?? ""}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>
          <div>
            <div style={{ fontWeight: "bold", color: "black" }}>
              {data?.staff_action?.name ?? ""}
            </div>
            <div style={{ fontSize: "13px" }}>
              {data?.staff_action?.phone_number ?? ""}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div>
          {data?.action == "create" ? (
            <div className="d-flex align-item-center">
              <IoMdAddCircleOutline size={20} color="#1f89e5"></IoMdAddCircleOutline>
              <div style={{ width: "10px" }}></div>
              <div style={{color:"#1f89e5"}}> Tạo mới</div>
            </div>
          ) : data?.action == "update" ? (
            <div className="d-flex align-item-center">
              <TbEdit size={20} color="#1ecc78"></TbEdit> <div style={{ width: "10px" }}></div>
              <div style={{color:"#1ecc78"}}> Chỉnh sửa</div>
            </div>
          ) : (
            <div className="d-flex align-item-center">
              <RiDeleteBinLine size={20} color="#e71313"></RiDeleteBinLine>{" "}
              <div style={{ width: "10px" }}></div>
              <div style={{color:"#e71313"}}> Xoá</div>
            </div>
          )}
        </div>
      </td>
      <td className="pr-5">{data?.action_desc ?? ""}</td>
      <td>
        <div style={{ padding: "10px", overflow: "hidden" }}>
          {(data?.data ?? []).map((e) => {
            return <div>{`${e.sub_column ?? ""}: ${e.old_value ?? '--:--'}`}</div>;
          })}
        </div>
      </td>
      <td>
        <div style={{ padding: "10px", overflow: "hidden" }}>
          {(data?.data ?? []).map((e) => {
            return <div>{`${e.sub_column ?? ""}: ${e.new_value ?? '--:--'}`}</div>;
          })}
        </div>
      </td>

      <td>
        {moment.utc(data?.created_at).format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
      </td>
    </tr>
  );
}
