/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { formatMoneyVND } from "../../../../helpers";
import { shiftServices } from "../../../../services/shiftServices";
Modal.setAppElement("#root");

const SupportModal = ({ visible, onSubmit, supportConfigSelected, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);

  const [selectAll, setSelectAll] = useState(false);
  const [listSupportConfig, setListSupportConfig] = useState();
  const [supportConfigSelect, setSupportConfigSelect] = useState([]);

  useEffect(() => {
    fetchSupportConfig();
  }, [areaId]);

  useEffect(() => {
    handleCheckAll();
  }, [supportConfigSelect]);

  useEffect(() => {
    setSupportConfigSelect([...supportConfigSelected]);
  }, []);

  const fetchSupportConfig = async () => {
    try {
      const response = await shiftServices.fetchSupportConfig(areaId);
      if (response.success) {
        setListSupportConfig(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckAll = () => {
    if ((listSupportConfig??[]).length > 0) {
      let total = 0;
      (listSupportConfig??[]).forEach((item) => {
        let equal = supportConfigSelect.find((value) => value.id === item.id);
        if (equal) {
          total++;
        }
      });
      if (total === (listSupportConfig??[]).length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectStaff = (e, item) => {
    const selectSet = new Set(supportConfigSelect);

    if (e.target.checked) {
      selectSet.add(item);
    } else {
      selectSet.delete(item);
    }

    setSupportConfigSelect([...selectSet]);
  };

  const handleSelectAllStaff = (e) => {
    if (e.target.checked) {
      setSupportConfigSelect(listSupportConfig);
    } else {
      setSupportConfigSelect([]);
    }
  };

  const checkSelectStaff = (id) => {
    return supportConfigSelect.some((item) => item.id === id);
  };

  const handleSubmit = () => {
    onSubmit(supportConfigSelect);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="staff-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>Chọn cấu hình thưởng</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div>
        <div className="modal-body">
          <div className="form-group">
            <div className="d-flex justify-content-between mb-3">
            <p className="font-weight-bold">
                Đã chọn: <span>{supportConfigSelect?.length}</span>
              </p>
            
            </div>
            <div>
              <div className="table-responsive">
                <div
                  id="dataTable_wrapper"
                  className="dataTables_wrapper no-footer"
                  style={{ maxHeight: "300px" }}
                >
                  <table
                    className="table no-footer dataTable"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                    aria-describedby="dataTable_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={(e) => handleSelectAllStaff(e)}
                          />
                        </th>
                        <th>Tiêu đề</th>
                        <th>Chi tiết</th>
                        <th>Số tiền (VNĐ)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(listSupportConfig ?? []).map((item, index) => (
                        <tr key={index}> 
                          <td>
                            <input
                              type="checkbox"
                              name=""
                              checked={checkSelectStaff(item.id)}
                              onChange={(e) => handleSelectStaff(e, item)}
                            />
                          </td>
                          <td>{item?.title || ""}</td>
                          <td>{item?.description || ""}</td>
                          <td>
                            {item?.value ? formatMoneyVND(item.value) : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button type="submit" className="btn btn-info" onClick={handleSubmit}>
            Lưu
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SupportModal;
