import { Tabs } from "antd";

import ShiftPage from "./ShiftPage";
import ShiftGroupPage from "./ShiftGroupPage";

export default function ShiftManager() {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: "CA LÀM VIỆC",
          key: "1",
          children: <ShiftPage></ShiftPage>,
        },
        {
          label: "NHÓM CA LÀM VIỆC",
          key: "2",
          children: <ShiftGroupPage></ShiftGroupPage>,

          // ReportTestCourse(),
        },
      ]}
    />
  );
}
