import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, TabList, Tabs } from "react-tabs";
import { areaServices } from "../../services/areaServices";
import HistoryReviews from "./child/HistoryReviews";
import Questions from "./child/Questions";
import ReportReviews from "./child/ReportReviews";

const ReviewsFromCustomer = () => {
  const areaId = useSelector((state) => state.area.areaId);

  const [area, setArea] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    getAreaById(areaId);
  }, [areaId]);

  const getAreaById = async (id) => {
    try {
      const response = await areaServices.getAreaById(id);
      setArea(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderView = () => {
    switch (tabIndex) {
      case 0:
        return <ReportReviews />;
      case 1:
        return <HistoryReviews />;
      case 2:
        return <Questions />;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          {`Danh sách đánh giá từ khách hàng cho chi nhánh ${area?.name || ""}`}
        </h4>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Báo cáo đánh giá</Tab>
              <Tab>Lịch sử đánh giá</Tab>
              <Tab>Câu hỏi</Tab>
            </TabList>
          </Tabs>
        </div>
        <div className="card-body">{renderView()}</div>
      </div>
    </div>
  );
};

export default ReviewsFromCustomer;
