import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import moment from "moment";
import AddMeetingModal from "./child/AddMeetingModal";
import MeetingModal from "./child/MeetingModal";
import { useSelector } from "react-redux";
import PermissionChecker from "../../../components/PermissionChecker";
const { Search } = Input;

export default function FranchiseMeetingPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [meetings, setMeetings] = useState(null);
  const [loading, setLoading] = useState(null);

  const getMeeting = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getMeeting(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );
      setMeetings(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteMeeting = async (id) => {
    try {
      const response = await franchiseServices.deleteMeeting(id);
      getMeeting(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMeeting();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <a>{record?.title ?? "--:--"}</a>,
    },
    {
      title: "Ngày tham gia",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <a>{moment(record.date).format("DD-MM-YYYY HH:mm") ?? "--:--"}</a>
      ),
    },
    {
      title: "Người tham gia",
      dataIndex: "invited_staffs",
      key: "invited_staffs",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_meeting_edit ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: record.data,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_meeting_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá thông tin ?"
                description="Bạn có chắc chắn muốn xoá lịch hẹn này chứ ?"
                onConfirm={() => {
                  deleteMeeting(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm cuộc họp"
          onSearch={(v) => {
            getMeeting(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_meeting_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Thêm cuộc họp
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            style: {
              background: record.status === 0 ? "#f6f6f6" : "default",
            },
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: record.data,
              });
            },
          };
        }}
        dataSource={(meetings?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            title: e.title ?? "--:--",
            content: e?.content ?? "--:--",
            date: e?.date,
            invited_staffs:
              e?.invited_staffs?.map((e) => e.name).join(", ") ?? "--:--",
            data: e,
          };
        })}
        pagination={{
          total: meetings?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getMeeting(e.current);
        }}
      />
      <AddMeetingModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getMeeting(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <MeetingModal
        open={modal.visible && modal.method === "WATCH"}
        dataInput={modal.data}
        onOk={() => {
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
