import { Tabs } from "antd";
import React from "react";

export default function ModalEmotion({ feel, groupReactions }) {
  const renderItem = (user, reaction) => {
    return (
      <div
        className="reaction-item"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "8px 20px 8px 0",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img
            src={
              user?.avatar ||
              "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
            }
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
            }}
            alt="avatar"
          />
          <span>{user.name}</span>
        </div>
        <div>{reaction}</div>
      </div>
    );
  };

  const renderChildren = (value) => {
    const { reaction, users } = value;

    return (
      <div style={{ minHeight: "300px" }}>
        {users.map((user) => (
          <div>{renderItem(user, reaction)}</div>
        ))}
      </div>
    );
  };

  const renderChildrenAll = (options) => {
    return (
      <div style={{ minHeight: "300px" }}>
        {options.map((option) => {
          const { reaction, users } = option;

          return (
            <div>
              {users.map((user) => (
                <div>{renderItem(user, reaction)}</div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const initialOptions = (groupReactions) => {
    const newTabOptions = [
      {
        label: (
          <div
            style={{
              width: "80px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Tất cả <span>{feel.length}</span>
          </div>
        ),
        key: "all",
        children: renderChildrenAll(feel),
      },
    ];
    groupReactions.forEach((group) => {
      const newTab = {
        label: (
          <div
            style={{
              width: "80px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {group.reaction} <span>{group.count}</span>
          </div>
        ),
        key: group.reaction,
        children: renderChildren(group),
      };
      newTabOptions.push(newTab);
    });
    return newTabOptions;
  };

  return (
    <div style={{ width: "100%" }}>
      <Tabs tabPosition={"left"} items={initialOptions(groupReactions)} />
    </div>
  );
}
