import React from "react";
import Modal from "react-modal";
import ColumnCheckInOut from "./ColumnCheckInOut";

const HistoryTimeKeepingModal = ({ visible, histories, title, closeModal }) => {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mt-2 history-time-keeping-box">
          {histories?.map((item, index) => (
            <ColumnCheckInOut history={item} key={index} />
          ))}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Đóng
        </button>
      </div>
    </Modal>
  );
};

export default HistoryTimeKeepingModal;
