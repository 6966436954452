import React from "react";
import { convertNumberToDateStr } from "../../../../helpers";

const ItemSchedule = ({ shift, handleRemove }) => {
  return (
    <tr className="hover-product even">
      <td>{shift?.name}</td>
      <td>
        {convertNumberToDateStr(
          shift?.start_work_hour,
          shift?.start_work_minute
        )}
      </td>
      <td>
        {convertNumberToDateStr(shift?.end_work_hour, shift?.end_work_minute)}
      </td>
      <td>{shift?.minutes_late_allow}</td>
      <td>{shift?.minutes_early_leave_allow}</td>
      <td>
        {shift?.days_of_week_list?.map((day, index) => {
          return (
            <span key={index} className="pr-1">
              {day === 8 ? "CN" : `T${day},`}
            </span>
          );
        })}
      </td>
      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          onClick={() => handleRemove(shift?.id)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          xóa
        </button>
      </td>
    </tr>
  );
};

export default ItemSchedule;
