import styled from "styled-components";

export const StyledRoomList = styled.div`
  .style-room-List {
    height: 500px;
    overflow-y: scroll;
    transition: all 0.3s ease;
    // display: flex;  
    // flex-direction: column-reverse;
    ::-webkit-scrollbar {
      border-radius: 10px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d1e3f9;
      opacity: 0.5;
    }
    &.fullScreen {
      height: 100%;
      max-height: 630px;
    }
    .room-item-container {
      display: flex;
      gap: 8px;
      padding-left: 16px;
      padding-right: 4px;
      height: 72px;
      align-items: center;
      &:hover {
        background-color: #f3f5f6;
        cursor: pointer;
      }
    }
    .room-item-container.selected {
      background-color: #e5efff;
    }
    .rl-room {
      &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__content {
        flex: 1;
        &__name {
          font-size: 16px;
          line-height: 20px;
          color: #081c36;
          margin-bottom: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__message {
          font-size: 14px;
          font-weight: 400;
          color: #7589a3;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__time {
          font-size: 10px;
          font-weight: 400;
          color: #7589a3;
          margin-top: 0;
        }
        &__dot {
          margin-top: 0;
          display: none;
          font-size: 4px;
          padding: 2px;
          &:hover {
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              background-color: #dfe2e7;
            }
            border-radius: 50%;
          }
        }
      }
      &:hover {
        // .rl-room__content__time {
        //   display: none;
        // }
        // .rl-room__content__dot {
        //   display: block;
        // }
      }
    }
    .rl-room.isUnread {
      .rl-room__content {
        &__name {
          font-weight: 600;
        }
        &__message {
          color: #000;
        }
      }
    }
    .text-truncate-1 {
      display: block;
      // display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 180px;
      height: 20px;
      line-height: 20px;
      white-space: nowrap;
    }
  }
`;
