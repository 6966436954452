import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
// import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { areaServices } from "../../../services/areaServices";
import { shiftServices } from "../../../services/shiftServices";
import { procedureServices } from "../../../services/procedureServices";
import { constants } from "../../../constants";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const InitProcedure = ({
  visible,
  procedureInput,
  closeModal,
  procedure_config_id,
  onSave,
  procedureConfigName,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const departments = useSelector((state) => state.staff.department_list);
  const [listArea, setListArea] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [incidentAreas, setIncidentAreas] = useState([]);
  const [incidentDepartments, setIncidentDepartments] = useState([]);
  const [listStaffRelate, setListStaffRelate] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({
    option_relate: 0,
  });

  useEffect(() => {
    if (procedureInput?.title !== null) {
      setListArea(
        procedureInput.areas.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      setListDepartment(
        procedureInput.departments.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      setTitle(procedureInput?.title);
    } else {
      setTitle("");
      setListArea([]);
      setListDepartment([]);
      setIncidentAreas([]);
      setIncidentDepartments([]);
      console.log(procedureConfigName);
    }

    if (procedureConfigName == "Biên bản/Thông tin") {
      if (procedureInput?.incident_areas) {
        setIncidentAreas(
          procedureInput.incident_areas.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }
      if (procedureInput?.incident_departments) {
        setIncidentDepartments(
          procedureInput.incident_departments.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }
      if (procedureInput?.incident_staffs) {
        setListStaffRelate(
          procedureInput.incident_staffs.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }

      if ((procedureInput?.incident_staffs ?? []).length > 0) {
        setData({ ...data, option_relate: 0 });
      } else {
        setData({ ...data, option_relate: 1 });
      }
    }
  }, [procedureInput]);

  const fetchStaffsManager = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAreas = async (search, __loadedOptions, { page }) => {
    try {
      const res = await areaServices.fetchAreas();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const createProcedure = async () => {
    if (title === "") {
      toast.error("Chưa nhập tên gợi nhớ");

      return;
    }

    if (listArea.length === 0 && procedureConfigName != "Biên bản/Thông tin") {
      toast.error("Chưa chọn chi nhánh");
      return;
    }

    if (
      listDepartment.length === 0 &&
      procedureConfigName != "Biên bản/Thông tin"
    ) {
      toast.error("Chưa chọn phòng ban");
      return;
    }

    const data = {
      procedure_config_id: procedure_config_id,
      is_active: true,
      title: title,
      list_area_id:
        procedureConfigName == "Biên bản/Thông tin"
          ? areas.data.map((e) => e.id).slice(1)
          : listArea.map((e) => e.value),
      list_department_id:
        procedureConfigName == "Biên bản/Thông tin"
          ? departments.data.map((e) => e.id)
          : listDepartment.map((e) => e.value),
      list_area_incident_id: incidentAreas.map((e) => e.value),
      list_department_incident_id: incidentDepartments.map((e) => e.value),
      list_staff_incident_id: listStaffRelate.map((e) => e.value),
    };

    try {
      const response = await procedureServices.createProcedure({ data: data });
      onSave(response.data);
      closeModal("load");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const updateProcedure = async () => {
    if (title === "") {
      toast.error("Chưa nhập tên gợi nhớ");

      return;
    }

    if (listArea.length === 0 && procedureConfigName != "Biên bản/Thông tin") {
      toast.error("Chưa chọn chi nhánh");
      return;
    }

    if (
      listDepartment.length === 0 &&
      procedureConfigName != "Biên bản/Thông tin"
    ) {
      toast.error("Chưa chọn phòng ban");
      return;
    }

    const data = {
      is_active: true,
      title: title,
      list_area_id:
        procedureConfigName == "Biên bản/Thông tin"
          ? areas.data.map((e) => e.id).slice(1)
          : listArea.map((e) => e.value),
      list_department_id:
        procedureConfigName == "Biên bản/Thông tin"
          ? departments.data.map((e) => e.id)
          : listDepartment.map((e) => e.value),
      list_area_incident_id: incidentAreas.map((e) => e.value),
      list_department_incident_id: incidentDepartments.map((e) => e.value),
      list_staff_incident_id: listStaffRelate.map((e) => e.value),
    };
    try {
      const response = await procedureServices.updateProcedure({
        procedureId: procedureInput.id,
        data: data,
      });
      onSave(response.data);
      closeModal("load");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <Modal
      title={
        procedureConfigName == "Biên bản/Thông tin" ? "Người nhận" : "Khởi tạo"
      }
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 500, maxWidth: 1000 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
          console.log(procedureInput?.title);
        if (procedureInput?.title === null) {
          createProcedure();
        } else {
          updateProcedure();
        }
      }}
    >
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div
          className="d-flex mb-3"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Tên gợi nhớ</div>
          <div style={{ width: "80%" }}>
            <input
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
            />
          </div>
        </div>
        {procedureConfigName != "Biên bản/Thông tin" ? (
          <>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div style={{ flex: 1 }}>Chi nhánh</div>
              <div style={{ width: "80%" }}>
                <AsyncPaginate
                  value={listArea}
                  loadOptions={fetchAreas}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setListArea}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 99999,
                    }),
                  }}
                  menuPortalTarget={document.body}
                  debounceTimeout={500}
                  isClearable
                  isSearchable
                />
              </div>
            </div>
            <div
              className="d-flex"
              style={{ alignItems: "center", marginTop: "20px" }}
            >
              <div style={{ flex: 1 }}>Phòng ban</div>
              <div style={{ width: "80%" }}>
                <AsyncPaginate
                  value={listDepartment}
                  loadOptions={fetchAllDepartments}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={setListDepartment}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 99999,
                    }),
                  }}
                  menuPortalTarget={document.body}
                  debounceTimeout={500}
                  isClearable
                  isSearchable
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex">
              <div className="mb-2 mr-5">
                <input
                  className="mr-2"
                  type="radio"
                  checked={data?.option_relate === 0}
                  onChange={(e) => {
                    setData({ ...data, option_relate: 0 });
                  }}
                />
                <span>Theo những cá nhân</span>
              </div>
              <div className="mb-2">
                <input
                  className="mr-2"
                  type="radio"
                  checked={data?.option_relate === 1}
                  onChange={(e) => {
                    setData({ ...data, option_relate: 1 });
                  }}
                />
                <span>Theo chi nhánh/ Phòng ban</span>
              </div>
            </div>
            {data?.option_relate === 0 ? (
              <AsyncPaginate
                value={listStaffRelate}
                loadOptions={fetchStaffsManager}
                isMulti
                closeMenuOnSelect={false}
                onChange={(e) => {
                  setListStaffRelate(e);
                  setIncidentAreas([]);
                  setIncidentDepartments([]);
                }}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            ) : (
              <>
                <div className="d-flex" style={{ alignItems: "center" }}>
                  <div style={{ flex: 1 }}>Chi nhánh nhận</div>
                  <div style={{ width: "80%" }}>
                    <AsyncPaginate
                      value={incidentAreas}
                      loadOptions={fetchAreas}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        setIncidentAreas(e);
                        setListStaffRelate([]);
                      }}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      debounceTimeout={500}
                      isClearable
                      isSearchable
                    />
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <div style={{ flex: 1 }}>Phòng ban nhận</div>
                  <div style={{ width: "80%" }}>
                    <AsyncPaginate
                      value={incidentDepartments}
                      loadOptions={fetchAllDepartments}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        setIncidentDepartments(e);
                        setListStaffRelate([]);
                      }}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      debounceTimeout={500}
                      isClearable
                      isSearchable
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default InitProcedure;
