import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { StyledRoomList } from "./RoomList.style";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Badge, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShowTime,
  handleShowtimeRoom,
  handleTimeToMinutes,
} from "../../../../../ultis/date";
import { chatActions as a } from "../../../../../actions/chatADActions";
import { io } from "socket.io-client";
import { debounce } from "lodash";

export default function RoomList({ profileInput }) {
  const dispatch = useDispatch();
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const profile = useSelector((state) => state.user.profile);
  const roomSelected = useSelector((state) => state.chatAD.roomSelected);
  const roomList = useSelector((state) => state.chatAD.roomChatListByUserId);
  console.log('roomList: ', roomList);
  const userId = useSelector((state) => state.chatAD.userId);
  console.log('userId:1111 ', userId);
  const allRoomListInfo = useSelector((state) => state.chatAD.allRoomListInfo);
  const fullScreen = useSelector((state) => state.chatAD.fullscreen);
  const [time, setTime] = useState(new Date());

  const roomChatRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const socket = io("https://crm-api.studyguide.dev:6442", {
  //    rejectUnauthorized: false,
  //    });
  //   if (profile) {
  //     socket.on(`chat:box_chat:${profile.id}`, (roomChats) => {
  //       console.log("roomChats: ", roomChats);
  //       dispatch(a.handleChangeRoomList(roomChats));
  //     });
  //   }
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const handleScroll = useCallback(
    debounce((currentPage, userId) => {
      if (roomChatRef.current.scrollTop > 600) {
        fetchMoreRoomList(currentPage, userId);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (allRoomListInfo.current_page < 1 && !hasMoreItems) return;
    const handleRealScroll = () => {
      handleScroll(allRoomListInfo.current_page, userId);
    };

    roomChatRef.current.addEventListener("scroll", handleRealScroll);
    return () => {
      if (roomChatRef.current) {
        roomChatRef.current.removeEventListener("scroll", handleRealScroll);
      }
    };
  }, [handleScroll, allRoomListInfo.current_page, hasMoreItems, userId]);

  const handleSelectRoom = (room) => {
    dispatch(a.selectedRoomChat(room));
    if (room.seen) return;
    const query = "?is_read=true";
    dispatch(a.getOneRoomChat(room.id, query));
  };

  const handleShowLastMessage = (lastMessage, type) => {
    switch (type) {
      case "image":
        return "Hình ảnh";
      case "video":
        return "Video";
      case "custom":
        return "Tệp đính kèm";
      default:
        return lastMessage;
    }
  };

  const renderOnline = () => {
    return (
      <span
        style={{
          color: "green",
          fontWeight: "600",
          letterSpacing: "1px",
          display: "flex",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            background: "green",
            display: "block",
          }}
        ></span>
        online
      </span>
    );
  };

  const handleShowRoomNameAvatar = (room) => {
    if (room.is_group) {
      const roomName =
        room.name_room || room?.users?.map((user) => user.name).join(", ");
      return { name: roomName, avatar: room.room_avatar };
    } else {
      const user = room?.users?.filter((user) => user.id !== userId)[0];
      return { name: user?.name, avatar: user?.avatar };
    }
  };

  const handleRenderStatusRoom = (room) => {
    let lastMinutes = 9999;
    if (room.is_group) {
      if (room.list_last_message.length === 0)
        lastMinutes = handleTimeToMinutes(room.updated_at);
      else
        lastMinutes = handleTimeToMinutes(room.list_last_message[0].created_at);
    } else {
      const otherUser = room.users.find((obj) => obj.id !== userId);
      const latestReqTime = otherUser?.latest_req_time;
      if (
        handleTimeToMinutes(latestReqTime) < 3 &&
        room.list_last_message.length > 0
      ) {
        lastMinutes = handleTimeToMinutes(latestReqTime);
      }
    }
    if (room.is_group) {
      if (lastMinutes < 3) {
        return renderOnline();
      } else return handleShowTime(room.updated_at);
    } else {
      if (lastMinutes < 3) {
        return renderOnline();
      } else if (room.list_last_message.length === 0) {
        return handleShowTime(room.updated_at);
      } else {
        return handleShowTime(room.list_last_message[0].created_at);
      }
    }
  };

  const handleGetRoomList = (page, userId) => {
    const query = `?user_id=${userId}&page=${page}`;
    dispatch(a.getRoomChatListByUserId(query));
  };

  const fetchMoreRoomList = (currentPage, userId) => {
    if (currentPage === allRoomListInfo.last_page) {
      sethasMoreItems(false);
      return;
    }
    handleGetRoomList(allRoomListInfo.current_page + 1, userId);
  };

  return (
    <StyledRoomList>
      <div
        className={
          fullScreen ? "fullScreen style-room-List" : "style-room-List"
        }
        id="scrollableRoomList"
        ref={roomChatRef}
      >
        {/* <div className="room-list-container" > */}
        {roomList && roomList.length ? (
          <InfiniteScroll
            // loadMore={fetchMoreRoomList}
            dataLength={roomList?.length}
            // next={fetchMoreRoomList}
            // hasMore={hasMoreItems}
            inverse={true}
            // style={{ display: "flex", flexDirection: "column-reverse" }}
            scrollableTarget="scrollableMessageList"
            loader={
              <div style={{ textAlign: "center", height: "36px" }}>
                <Spin></Spin>
              </div>
            }
          >
            {roomList && roomList.length
              ? roomList.map((room, index) => {
                  if (!room?.id) return null;
                  const { id, seen, list_last_message, updated_at } = room;

                  let lastMessageObj = null;

                  if (list_last_message?.length) {
                    lastMessageObj = list_last_message[0];
                  }
                  const { content, type, user, hide_all } =
                    lastMessageObj ?? {};
                  return (
                    <div
                      key={id}
                      className={`room-item-container rl-room ${
                        roomSelected?.id === id && "selected"
                      } ${!seen && "isUnread"}`}
                      onClick={() => handleSelectRoom(room)}
                    >
                      <div className="rl-room__avatar">
                        <img
                          src={
                            handleShowRoomNameAvatar(room).avatar ||
                            "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
                          }
                          alt="avatar room"
                        />
                      </div>
                      <div className="rl-room__content">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="rl-room__content__name text-truncate-1">
                            {handleShowRoomNameAvatar(room).name || "Khách"}
                          </p>
                          <p className="rl-room__content__time">
                            {handleRenderStatusRoom(room)}
                          </p>
                          <p className="rl-room__content__dot">
                            <MoreHorizIcon fontSize="small" color="#7589a3" />
                          </p>
                        </div>
                        {list_last_message?.length ? (
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="rl-room__content__message text-truncate-1">
                              {profile.id === user.id ? "Bạn: " : ""}
                              {hide_all
                                ? "Đã thu hồi tin nhắn"
                                : handleShowLastMessage(content, type)}
                            </p>
                            <Badge
                              dot={!seen}
                              style={{ marginRight: "4px", marginTop: "6px" }}
                            />
                          </div>
                        ) : (
                          <p className="rl-room__content__message text-truncate-1">
                            Chưa có tin nhắn nào
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
            {}
          </InfiniteScroll>
        ) : null}
        {/* </div> */}
      </div>
    </StyledRoomList>
  );
}
