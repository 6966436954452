import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalUID from "../../../components/ModalUID";
import ViewDate from "../../../components/ViewDate";
import { constants } from "../../../constants";
import { convertNumberToDateStr, formatDate } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";

const WorkScheduleEmployee = () => {
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [dateSearch, setDateSearch] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [area, setArea] = useState(null);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    title: "Chi tiết lịch làm việc",
    method: "",
    content: "",
  });
  const [calendarDetail, setCalendarDetail] = useState(null);
  const fetchCalendarStaff = async (date) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const dateFrom = formatDate(date.startDate, constants.FORMAT_YYYY_MM_DD);
      const dateTo = formatDate(date.endDate, constants.FORMAT_YYYY_MM_DD);
      const response = await shiftServices.fetchCalenderStaff(
        reportNumber.current_user.area_id,
        `?from=${dateFrom}&to=${dateTo}`
      );
      setCalendars(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };
  const fetchDetailCalendarStaff = async (id) => {
    try {
      const response = await shiftServices.fetchCalenderStaffById(areaId, id);
      setCalendarDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };
  const getDayNameFromDate = (date) => {
    const days = new Map([
      ["Monday", "Thứ 2"],
      ["Tuesday", "Thứ 3"],
      ["Wednesday", "Thứ 4"],
      ["Thursday", "Thứ 5"],
      ["Friday", "Thứ 6"],
      ["Saturday", "Thứ 7"],
      ["Sunday", "Chủ nhật"],
    ]);
    return days.get(moment(date).format("dddd"));
  };

  const fetchAreaById = async (id) => {
    try {
      const response = await shiftServices.fetchAreaById(id);
      setArea(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchCalendarStaff({
      startDate: getFirstAndLastDayInMonth().firstDay,
      endDate: getFirstAndLastDayInMonth().lastDay,
    });
    fetchAreaById(areaId);
  }, []);

  useEffect(() => {
    if (dateSearch) {
      fetchCalendarStaff(dateSearch);
    }
  }, [dateSearch]);

  const checkTime = (calendar) => {
    if (
      calendar?.shift?.length === 0 &&
      new Date().getTime() > new Date(calendar?.date).getTime()
    ) {
      return "Chưa đến ca";
    }
    if (calendar?.shift?.length === 0) {
      return "Nghỉ";
    }

    for (const shift of calendar?.shift) {
      if (shift?.histories?.length === 0) {
        return "Chưa chấm công";
      }
      const time = `${calendar?.date} ${convertNumberToDateStr(
        calendar?.start_work_hour,
        calendar?.start_work_minute
      )}`;
      if (
        new Date(shift?.histories[0]?.time_tracking).getTime() >
        new Date(time).getTime()
      ) {
        return "Đến muộn";
      }
    }

    return "Đúng giờ";
  };

  const checkColor = (calendar) => {
    if (
      calendar?.shift?.length === 0 &&
      new Date().getTime() > new Date(calendar?.date).getTime()
    ) {
      return "#D9D9D9";
    }
    if (calendar?.shift?.length === 0) {
      return "#D9D9D9";
    }

    for (const shift of calendar?.shift) {
      if (shift?.histories?.length === 0) {
        return "#EE5353";
      }
      const time = `${calendar?.date} ${convertNumberToDateStr(
        calendar?.start_work_hour,
        calendar?.start_work_minute
      )}`;
      if (
        new Date(shift?.histories[0]?.time_tracking).getTime() >
        new Date(time).getTime()
      ) {
        return "#EF8D8D";
      }
    }

    return "#D1A818";
  };

  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">
        Lịch làm việc của nhân viên
      </h4>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <ViewDate
            className="d-flex justify-content-between"
            handleChange={(date) => setDateSearch(date)}
            type={constants.MONTH}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <table
                className="table no-footer dataTable table-bordered"
                width="100%"
                cellSpacing={0}
              >
                <tbody>
                  {calendars?.map((calendar, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {getDayNameFromDate(new Date(calendar?.date))},{" "}
                          {calendar?.date}
                        </td>
                        {calendar?.shift?.map((shift) => {
                          return (
                            <td
                              key={shift?.id}
                              onClick={() => {
                                fetchDetailCalendarStaff(shift?.id);
                                window.$(".modal").modal("show");
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-center ">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {shift?.name}
                                    </div>
                                    <span className="mx-2">-</span>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {convertNumberToDateStr(
                                        shift?.start_work_hour,
                                        shift?.start_work_minute
                                      )}{" "}
                                      -{" "}
                                      {convertNumberToDateStr(
                                        shift?.end_work_hour,
                                        shift?.end_work_minute
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {shift?.histories[0]?.time_tracking?.slice(
                                        12,
                                        16
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        color: "green",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {shift?.histories.length > 0 ? " - " : ""}
                                    </div>
                                    <div
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {shift?.histories?.length > 0
                                        ? shift.histories[
                                            shift.histories.length - 1
                                          ]?.is_checkin
                                          ? "---"
                                          : shift.histories[
                                              shift.histories.length - 1
                                            ]?.time_tracking?.slice(12, 16)
                                        : null}
                                    </div>
                                  </div>

                                  <div className="mt-0">
                                    <span>
                                      <i
                                        className="fa fa-map-marker"
                                        aria-hidden="true"
                                        style={{ color: "red" }}
                                      ></i>
                                      <span className="ml-1">{area?.name}</span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                      style={{
                                        width: "140px",
                                        height: "35px",
                                        textAlign: "center",
                                        backgroundColor: checkColor(calendar),
                                        borderRadius: "6px",
                                        cursor: "pointer",
                                        margin: "0px 0px",
                                        padding: "6px",
                                        fontWeight: "bold",
                                        color: "white",
                                        fontSize:
                                          checkTime(calendar) == "Nghỉ"
                                            ? "16px"
                                            : null,
                                      }}
                                    >
                                      {checkTime(calendar)}
                                    </div>
                              </div>
                            </td>
                          );
                        })}
                        {calendar?.shift?.length === 0 &&
                          new Date().getTime() >
                            new Date(calendar?.date).getTime() && (
                            <td
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "green",
                              }}
                            >
                              Nghỉ
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalUID
        size={modal.size}
        method={modal.method}
        title={modal.title}
        handleOnSave={() => {}}
        showFooter={modal.showFooter}
      >
        <h5>Thông tin ca làm</h5>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Tên ca làm:
          </span>
          <span>{calendarDetail?.name}</span>
        </div>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Giờ bắt đầu:
          </span>
          <span>
            {calendarDetail?.date}{" "}
            {convertNumberToDateStr(
              calendarDetail?.start_work_hour,
              calendarDetail?.start_work_minute
            )}
          </span>
        </div>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Giờ kết thúc:
          </span>
          <span>
            {calendarDetail?.date}{" "}
            {convertNumberToDateStr(
              calendarDetail?.end_work_hour,
              calendarDetail?.end_work_minute
            )}
          </span>
        </div>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Được phép chấm công:
          </span>
          <span>
            {calendarDetail?.access_tracking_start} -{" "}
            {calendarDetail?.access_tracking_end}
          </span>
        </div>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Chi nhánh:
          </span>
          <span>{calendarDetail?.area?.name}</span>
        </div>
        <h5 className="mt-4">Thông tin chấm công</h5>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Giờ chấm công vào:
          </span>
          <span>{calendarDetail?.area?.name}</span>
        </div>
        <div className="mt-3">
          <span className="mr-2" style={{ fontWeight: "600" }}>
            Giờ chấm công ra:
          </span>
          <span>{calendarDetail?.area?.name}</span>
        </div>
        <h5 className="mt-4">Thông tin chấm công</h5>
        {calendarDetail?.histories?.map((item) => {
          return (
            <div
              className="mt-3"
              key={item?.id}
              style={{ color: item?.is_checkin ? "green" : "red" }}
            >
              <span>
                {item?.time_tracking?.slice(12, 16)} -{" "}
                {item?.is_checkin ? "Vào làm" : "Tan ca"}
              </span>
            </div>
          );
        })}
      </ModalUID>
    </div>
  );
};

export default WorkScheduleEmployee;
