import React, { useState } from "react";
import { useSelector } from "react-redux";

const RoomChatItem = ({ room, wasInRoom = false }) => {
  const profile = useSelector((state) => state.user.profile);
  const [isChecked, setChecked] = useState(wasInRoom);

  const handleSelectRoom = () => {
    if(!wasInRoom) {
      setChecked(!isChecked);
    }
  }

  const handleShowRoomNameAvatar = () => {
    if(!room) return;
    if (room?.is_group) {
      const roomName = room.name_room || room?.users?.map((user) => user.name).join(", ");
      return {name : roomName, avatar: room.room_avatar};
    } else {
      if(room.users) {
        const user = room?.users?.filter((user) => user.id !== profile.id)[0];
      return {name: user?.name, avatar: user?.avatar};
      }
      return {
        name: room?.name,
        avatar: room?.avatar
      }
    }
  }
  
  return (
    <div
      className="d-flex align-items-center room-chat-item-container"
      style={{
        gap: "8px",
        padding: "4px 8px",
        backgroundColor: isChecked ? "#f5f5f5" : "",
      }}
      onClick={handleSelectRoom}
    >
      <input type="radio" checked={isChecked} />
      <img
        src={handleShowRoomNameAvatar().avatar || "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"}
        alt="avatar"
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
      <p className="text-truncate-1">{handleShowRoomNameAvatar().name || "Khách"}</p>
    </div>
  );
}

export default React.memo(RoomChatItem);
