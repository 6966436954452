/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCaretRight } from "react-icons/ai";
import InitProcedure from "./InitProcedure";
import { AiOutlinePlus, AiOutlineCloseCircle } from "react-icons/ai";
import { BsArrowDown } from "react-icons/bs";
import ProcessProcedure from "./ProcessProcedure";
import { constants } from "../../../constants";
import DialogYesNo from "./DialogYesNo";
import { Modal } from "antd";
import OverrideProcedureModal from "./OverrideProcedureModal";
import { toast } from "react-toastify";
import { probationServices } from "../../../services/probationServices";

const FORMAT_DATE = "MM-yyyy";
const CreateProbation = ({
  title,
  visible,
  procedureConfigId,
  procedureId,
  closeModal,
  onSuccess,
  procedureConfigName,
}) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    process: null,
    indexInput: null,
    dataInput: null,
  });

  const [procedure, setProcedure] = useState({
    title: null,
    process: [{ id: null, approvers: [] }],
  });

  useEffect(() => {
    if (procedureId !== null) {
      getProcedureDetail(procedureId);
    } else {
      if (visible === true) {
        setProcedure({
          title: null,
          process: [{ id: null, approvers: [] }],
        });
      }
    }
  }, [procedureId, visible]);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const getProcedureDetail = async (id) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await probationServices.getProcedureDetail({
        procedureId: id,
      });

      if (res.data.process.length === 0) {
        const dataNew = { ...res.data, process: [{ id: null, approvers: [] }] };
        setProcedure(dataNew);
      } else {
        setProcedure(res.data);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  const deleteProcess = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await probationServices.deleteProcess({
        id: id,
      });
      setModal({
        ...modal,
        title: "",
        method: "",
        visible: false,
        process: null,
      });
      getProcedureDetail(procedure.id);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  const widgetInit = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="shadow card"
          style={{ minWidth: "18rem", maxWidth: "30rem", borderRadius: "15px" }}
        >
          <div
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f6c23e",
              color: "white",
              fontWeight: "bold",
              borderRadius: "4px",
            }}
          >
             Khởi tạo
          </div>
          <div
            className="card-body d-flex"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => {
              setModal({
                title: `Thêm mới quy trình`,
                method: "CREATE",
                visible: true,
              });
            }}
          >
            <div className="one-line">
              {procedure?.title === null ? "Chọn chi nhánh" : procedure.title}
            </div>
            <AiFillCaretRight color="grey"></AiFillCaretRight>
          </div>
        </div>
        <div
          style={{ width: "1px", height: "10px", backgroundColor: "grey" }}
        ></div>
        <button
          type="button"
          style={{
            borderRadius: "50%",
            height: "30px",
            width: "30px",
            backgroundColor: "#f6c23e",
            color: "white",
          }}
          onClick={() => {
            const processNew = procedure.process;
            processNew.splice(0, 0, { id: null, approvers: [] });
            setProcedure({
              ...procedure,
              process: processNew,
            });
          }}
        >
          <AiOutlinePlus></AiOutlinePlus>
        </button>
        <BsArrowDown color="grey"></BsArrowDown>
      </div>
    );
  };

  const widgetProcess = () => {
    return (procedure?.process ?? []).map((e, index) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            className="shadow card "
            style={{
              minWidth: "18rem",
              maxWidth: "30rem",
              borderRadius: "15px",
            }}
          >
            <div
              style={{
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f6c23e",
                color: "white",
                borderRadius: "4px",
                paddingRight: "10px",
                paddingLeft: "10px",
                fontWeight: "bold",
              }}
            >
              <div>Người duyệt</div>
              <AiOutlineCloseCircle
                size={20}
                onClick={() => {
                  if (e.id === null) {
                    const processNew = procedure.process;
                    processNew.splice(index, 1);
                    setProcedure({
                      ...procedure,
                      process: processNew,
                    });
                  } else {
                    setModal({
                      title: `Bạn có chắc chắn muốn xoá bước duyệt này chứ ?`,
                      method: "DELETE-PROCESS",
                      visible: true,
                      process: e,
                    });
                  }
                }}
              ></AiOutlineCloseCircle>
            </div>
            <div
              className="card-body d-flex"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => {
                if (procedure?.title !== null) {
                  setModal({
                    title: `Người duyệt`,
                    method: "PROCESS",
                    visible: true,
                    process: e,
                    indexInput: index,
                  });
                } else {
                  toast.error("Chưa khởi tạo");
                }
              }}
            >
              <div className="one-line">
                {(e?.approvers ?? []).length === 0
                  ? "Vui lòng chọn Người duyệt"
                  : e.approvers.map((p) => {
                      return <div>{p.name}</div>;
                    })}
              </div>
              <AiFillCaretRight color="grey"></AiFillCaretRight>
            </div>
          </div>
          <div
            style={{
              width: "1px",
              height: "10px",
              backgroundColor: "grey",
            }}
          ></div>
          <button
            type="button"
            style={{
              borderRadius: "50%",
              height: "30px",
              width: "30px",
              backgroundColor: "#f6c23e",
              color: "white",
            }}
            onClick={() => {
              const processNew = procedure.process;
              processNew.splice(index + 1, 0, { id: null, approvers: [] });
              setProcedure({
                ...procedure,
                process: processNew,
              });
            }}
          >
            <AiOutlinePlus></AiOutlinePlus>
          </button>
          <BsArrowDown color="grey"></BsArrowDown>
        </div>
      );
    });
  };

  // const handleCloseModal = () => {
  //   setModal({ ...modal, title: "", method: "", visible: false });
  // };

  // const [modal, setModal] = useState({
  //   key: "OVERRIDE",
  //   visible: false,
  //   processInput: null,
  //   dataInput: null,
  // });

  return (
    <Modal
      title={
        procedureId != null
          ? "Chỉnh sửa quy trình duyệt"
          : "Thêm quy trình duyệt"
      }
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 800 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        closeModal();
      }}
    >
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        {widgetInit()}
        {widgetProcess()}
      </div>
      <InitProcedure
        visible={modal.visible && modal.method === "CREATE"}
        title={modal.title}
        procedure_config_id={procedureConfigId}
        procedureInput={procedure}
        onSave={(data) => {
          getProcedureDetail(data.id);
        }}
        closeModal={(v) => {
          handleCloseModal();
          if (v == "load") {
            onSuccess();
          }
        }}
        procedureConfigName={procedureConfigName}
        // onSubmit={handleSubmitStaffModal}
        // onSave={() => {
        //   convertData(dataTemp);
        // }}
      />
      <ProcessProcedure
        visible={modal.visible && modal.method === "PROCESS"}
        title={modal.title}
        procedureInput={procedure}
        processInput={modal.process}
        onSave={(data) => {
          handleCloseModal();
          getProcedureDetail(procedure.id);
          if (
            (data?.listTo ?? []).length === 0 ||
            (data?.listFrom ?? []).length === 0
          ) {
            return;
          }
          setModal({
            visible: true,
            title: `Bạn có muốn thay đổi người duyệt cho các phiếu chưa được duyệt không ?`,
            method: "YES-NO-VERRRIDE",
            dataInput: data,
            procedureId: procedureId,
            process: modal.process,
          });
        }}
        closeModal={handleCloseModal}
        indexInput={modal.indexInput}
      />
      <DialogYesNo
        visible={
          modal.visible &&
          (modal.method === "DELETE-PROCESS" ||
            modal.method === "YES-NO-VERRRIDE")
        }
        title={modal.title}
        yes={(data) => {
          if (modal.method === "YES-NO-VERRRIDE") {
            setModal({
              visible: true,
              method: "OVERRIDE",
              dataInput: modal.dataInput,
              process: modal.process,
            });
          } else {
            deleteProcess({ id: modal.process.id });
          }
        }}
        no={handleCloseModal}
      />
      <OverrideProcedureModal
        visible={modal.visible && modal.method === "OVERRIDE"}
        closeModal={handleCloseModal}
        dataInput={modal.dataInput}
        processInput={modal.process}
        procedureId={procedureId}
      ></OverrideProcedureModal>
    </Modal>
  );
};

export default CreateProbation;
