import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
// import InfoIcon from "@mui/icons-material/Info";
import RoomChatPin from "./RoomChatPin";
import { InfoIcon } from "../../icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { chatActions as a } from "../../../../actions/chatActions";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { handleShowTime, handleTimeToMinutes } from "../../../../ultis/date";
import { handleShowRoomNameAvatar } from "../../utils";

export default function Header({
  setOpenDrawerPin,
  setOpenDrawerInfo,
  setOpenDrawerSearchMess,
  openDrawerSearchMess,
}) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const { room_chat_pin, is_group, users } = roomSelected;

  const handleOpenModalAddMember = () => {
    dispatch(a.toggleModalSelectsUser({ isOpen: true, type: "addMember" }));
  };

  const handleRenderStatusRoom = (room) => {
    let lastMinutes = 9999;
    if (room.is_group) {
      return false;
    }
    const otherUser = room.users.find((obj) => obj.id !== profile.id);
    const latestReqTime = otherUser?.latest_req_time;
    if (
      handleTimeToMinutes(latestReqTime) < 3 &&
      room.list_last_message.length > 0
    ) {
      lastMinutes = handleTimeToMinutes(latestReqTime);
    }
    if (isNaN(handleTimeToMinutes(latestReqTime)))
      return <p className="number-member">Chưa truy cập</p>;

    if (lastMinutes < 3) {
      return (
        <p
          className="number-member"
          style={{ color: "green", fontWeight: "600", letterSpacing: "1px" }}
        >
          online
        </p>
      );
    } else {
      return (
        <p className="number-member">
          Truy cập {handleShowTime(latestReqTime)} trước
        </p>
      );
    }
  };

  const handleShowAvatarDotOnline = (room) => {
    let lastMinutes = 9999;
    if (room.is_group) {
      if (room.list_last_message.length === 0)
        lastMinutes = handleTimeToMinutes(room.updated_at);
      else
        lastMinutes = handleTimeToMinutes(room.list_last_message[0].created_at);
    } else {
      const otherUser = room.users.find((obj) => obj.id !== profile.id);
      const latestReqTime = otherUser?.latest_req_time;
      if (
        handleTimeToMinutes(latestReqTime) < 3 &&
        room.list_last_message.length > 0
      ) {
        lastMinutes = handleTimeToMinutes(latestReqTime);
      }
    }

    if (lastMinutes < 3) {
      return true;
    }

    return false;
  };

  return (
    <div className="header-chat-container">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "10px" }}
      >
        <div className="d-flex align-items-center chat-info">
          <div className="chat-info__avatar">
            <img
              src={
                handleShowRoomNameAvatar(roomSelected, profile).avatar ||
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
              }
              onError={(e)=>{
                e.target.onerror = null; 
                e.target.src = "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
              }}
              alt=""
            />
            {handleShowAvatarDotOnline(roomSelected) && (
              <div
                style={{
                  width: "11px",
                  height: "11px",
                  borderRadius: "50%",
                  background: "green",
                  border: "1px solid white",
                  position: "absolute",
                  right: "-2px",
                  bottom: "6px",
                }}
              ></div>
            )}
          </div>
          <div className="chat-info__name">
            <p className="text-truncate-1">
              {handleShowRoomNameAvatar(roomSelected, profile).name || "Khách"}
            </p>
            {is_group ? (
              <p className="number-member">{users?.length} thành viên</p>
            ) : (
              handleRenderStatusRoom(roomSelected)
            )}
          </div>
        </div>
        <div className="chat-action">
          <div style={{ marginTop: 16 }}></div>
          {room_chat_pin && room_chat_pin?.length ? (
            <div
              className="room-chat-pin"
              onClick={() => setOpenDrawerPin(true)}
            >
              <RoomChatPin />
            </div>
          ) : null}
          {is_group && (
            <div
              className="btn chat-action__add"
              title={`Thêm thành viên`}
              onClick={handleOpenModalAddMember}
            >
              <PersonAddAltIcon style={{ color: "#36A2EB" }} />
            </div>
          )}
          <div
            className="btn chat-action__search"
            title="Tìm kiếm"
            onClick={() => setOpenDrawerSearchMess(true)}
          >
            <SearchIcon style={{ color: "#36A2EB" }} />
          </div>
          <div
            className="btn chat-action__info"
            title="Thông tin đoạn chat"
            onClick={() => setOpenDrawerInfo(true)}
          >
            <InfoIcon style={{ color: "#36A2EB" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
