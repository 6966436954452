import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  info: {
    status: c.NONE,
  },
  managers: {
    data: [],
    status: c.NONE,
  },
  

  department_list: {
    data: [],
    status: c.NONE,
  },
};
export function staff(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_STAFFS_SUCCESS:
      return {
        ...state,
        list: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_MANAGERS_DEPARTMENT_SUCCESS:
      return {
        ...state,
        managers: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

      case c.GET_ALL_MANAGERS_DEPARTMENT_FAILURE:
        return {
          ...state,
          managers: {
            data: [],
            status: c.FAILURE,
          },
        };
    case c.RESET_ALL_MANAGERS_DEPARTMENT_STATUS:
      return {
        ...state,
        managers: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_STAFFS_LIST_STATUS:
      return {
        ...state,
        list: {
          status: c.NONE,
        },
      };

    case c.RESET_DEPARTMENT_LIST_STATUS:
      return {
        ...state,
        department_list: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_STAFF_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
          status: c.SUCCESS,
        },
      };

      case c.RESET_STAFF_STATUS:
        return {
          ...state,
          info: {
            status: c.NONE,
          },
        };

    case c.GET_ALL_STAFFS_FAILURE:
      return {
        ...state,
        list: {
          status: c.FAILURE,
        },
      };
    case c.GET_STAFF_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
