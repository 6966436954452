import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { SelectPicker, DatePicker } from "rsuite";
import { shiftServices } from "../../../services/shiftServices";
import { areaServices } from "../../../services/areaServices";
import "../style.css";
import { emailRegex, phoneRegex } from "../../../helpers";
import moment from "moment";
import { constants } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { toast } from "react-toastify";
import UploadImageSingle from "../../../components/UploadImageSingle";

const Employee = ({ handleSuccess }) => {
  const dataGender = [
    { label: "Nam", value: 1 },
    { label: "Nữ", value: 2 },
    { label: "Không xác định", value: 0 },
  ];
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();

  const [cmndFont, setCmndFont] = useState(null);
  const [cmndBack, setCmndBack] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const handleReset = () => {
    reset({
      name: "",
      username: "",
      email: "",
      password: "",
      phone: "",
      sex: null,
      position: "",
      address: "",
      startJoin: null,
      department: null,
      staffCode: "",
      province: null,
      district: null,
      warn: null,
    });
  };

  const onSubmit = async (values) => {
    try {
      const currentDate = moment(new Date()).format(
        constants.FORMAT_YYYY_MM_DD
      );
      if (valueStaffCode === null || valueStaffCode === "") {
        toast.error("Chưa nhập mã nhân viên");
        return;
      }
      console.log(values);
      const body = {
        name: values.name,
        area_id: values.areaId,
        role_id: values.role,
        user_name: values.username,
        email: values.email === "" ? null : values.email,
        password: values.password,
        phone_number: values.phone,
        sex: values.sex,
        position: values.position,
        address: values.address,
        email_verified_at: currentDate,
        start_join_time: moment(values.startJoin).format(
          constants.FORMAT_YYYY_MM_DD
        ),
        department_id: values.department,
        staff_code: valueStaffCode,
        avatar: "",
        province_id: null,
        district_id: null,
        township_id: null,
        province_name: null,
        district_name: null,
        township_name: null,
        updated_at: currentDate,
        created_at: currentDate,
        birthday: moment(values.birthday).format(constants.FORMAT_YYYY_MM_DD),
        cmnd: values.cmnd,
        cmnd_font: cmndFont,
        cmnd_back_side: cmndBack,
        date_range: values?.date_range,
        issued_by: values?.issued_by,
        permanent_address: values?.permanent_address,
        temporary_address: values?.temporary_address,
        nation: values?.nation,
        religion: values?.religion,
        bank_name: values?.bank_name,
        bank_number: values?.bank_number,
        bank_account_name: values?.bank_account_name,
        bank_branch: values?.bank_branch,
        school: values?.school,
        course: values?.course,
        level: values?.level,
        specialized: values?.specialized,
        height: values?.height,
        weight: values?.weight,
        congenital: values?.congenital,
        married: values?.married,
      };

      const response = await shiftServices.createEmployee(body, values.areaId);
      if (response.success) {
        toast.success(response.msg);
        handleSuccess(response.data);
        handleReset();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
     // toast.error(error.response.data.msg);
      console.log(error.response);
    }
  };

  // department
  const [departments, setDepartments] = useState([]);

  const fetchAllDepartment = async () => {
    try {
      const response = await shiftServices.fetchAllDepartments();
      const result = response.data.map((department) => ({
        label: department.name,
        value: department.id,
      }));
      setDepartments(result);
    } catch (error) {
      console.log(error);
    }
  };
  // area
  const [areas, setAreas] = useState([]);
  const fetchAllArea = async () => {
    try {
      const response = await areaServices.fetchAreas();
      const result = response.data.map((area) => ({
        label: area.name,
        value: area.id,
      }));
      setAreas(result);
    } catch (error) {
      console.log(error);
    }
  };

  // role

  const [roles, setRoles] = useState([]);
  const fetchAllAllRole = async () => {
    console.log("sssxasxasxasx");
    try {
      const response = await shiftServices.getRole({ areaId });
      const result = response.data.map((role) => ({
        label: role.title,
        value: role.id,
      }));
      setRoles(result);
      var role = result.filter((e) => {
        return e.label === "Nhân Viên";
      });
      reset({ role: role[0].value });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllAllRole();
    fetchAllDepartment();
    fetchAllArea();
  }, []);

  useEffect(() => {
    reset({ areaId: areaId });
  }, [areaId]);

  const [valueStaffCode, setValueStaffCode] = useState("");

  function handleInputNameToStaffCode(event) {
    var text = generateEmployeeCode(event.target.value);
    setValueStaffCode(text);
  }

  function handleStaffCode(event) {
    setValueStaffCode(event.target.value);
  }

  function generateEmployeeCode(fullName) {
    if (fullName !== null && fullName !== "") {
      // Lấy thời gian hiện tại ở định dạng "ddMMyyyyHHmm"
      const timeCode = format(new Date(), "yyMMdd");

      if (fullName.toString().includes(" ")) {
        // Lấy tên và họ của nhân viên
        const listName = fullName.split(" ");
        var nameCode = "";

        listName.forEach((e) => {
          if (e !== null && typeof e[0] !== "undefined") {
            nameCode = nameCode + e[0];
          }
        });
        if (nameCode != "") {
          nameCode.toUpperCase();
        }

        // Tạo mã nhân viên bằng cách ghép tên mã và thời gian
        const employeeCode =
          (nameCode !== "" ? nameCode.toUpperCase() : "") + timeCode;

        return employeeCode;
      } else {
        const nameCode = fullName[0].toUpperCase();
        const employeeCode = nameCode + timeCode;
        return employeeCode;
      }
    }

    return "";
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Họ và tên*</label>
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("name", {
                  required: "Không được rỗng",
                  onChange: handleInputNameToStaffCode,
                })}
              />
              <div className="error-message">{errors?.name?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Số điện thoại*</label>
              <input
                name="phone"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("phone", {
                  required: "Không được rỗng",
                  pattern: {
                    value: phoneRegex,
                    message: "Số điện thoại không hợp lệ",
                  },
                })}
              />
              <div className="error-message">{errors?.phone?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Mật khẩu*</label>
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("password", {
                  required: "Không được rỗng",
                })}
              />
              <div className="error-message">{errors?.password?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Email</label>
              <input
                name="email"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("email", {
                  pattern: {
                    value: emailRegex,
                    message: "Email không hợp lệ",
                  },
                })}
              />
              <div className="error-message">{errors?.email?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày vào làm*</label>
              <div>
                <Controller
                  name="startJoin"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <DatePicker
                        value={value}
                        block
                        cleanable={false}
                        onChange={(date) => {
                          onChange(date);
                        }}
                        format="dd/MM/yyyy"
                      />
                    );
                  }}
                />
              </div>
              <div className="error-message">{errors?.startJoin?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Chi nhánh*</label>
              <Controller
                name="areaId"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={areas}
                      placeholder="-- Chọn chi nhánh --"
                      block
                      cleanable={false}
                      value={value}
                      onChange={(val) => onChange(val)}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.areaId?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Phòng ban*</label>
              <Controller
                name="department"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={departments}
                      placeholder="-- Chọn Phòng ban --"
                      block
                      cleanable={false}
                      value={value}
                      onChange={(val) => onChange(val)}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.department?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Phân quyền*</label>
              <Controller
                name="role"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={roles}
                      placeholder="-- Chọn phân quyền --"
                      block
                      cleanable={false}
                      value={value}
                      onChange={(val) => {
                        if (
                          (reportNumber.permissions?.choose_role ?? false) ==
                          true
                        ) {
                          onChange(val);
                        } else {
                          toast.error("Bạn không có quyền truy cập");
                        }
                      }}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.department?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Giới tính*</label>
              <Controller
                name="sex"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={dataGender}
                      value={value}
                      placeholder="-- Chọn giới tính --"
                      block
                      cleanable={false}
                      onChange={(val) => onChange(val)}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.sex?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Mã nhân viên*</label>
              <input
                name="staffCode"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="true"
                value={valueStaffCode}
                onChange={handleStaffCode}
              />
              <div className="error-message">{errors?.staffCode?.message}</div>
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <label>Chức danh</label>
              <input
                name="position"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                {...register("position")}
              />
              <div className="error-message">{errors?.position?.message}</div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày sinh*</label>
              <div>
                <Controller
                  name="birthday"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <DatePicker
                        value={value}
                        block
                        cleanable={false}
                        onChange={(date) => onChange(date)}
                        format="dd/MM/yyyy"
                      />
                    );
                  }}
                />
              </div>
              <div className="error-message">{errors?.birthday?.message}</div>
            </div>
          </div>
          <div className="col-12 my-2">
            <span style={{ fontSize: 14, fontWeight: "700", color: "red" }}>
              CÁC TRƯỜNG PHÍA DƯỚI CÓ THỂ BỎ QUA:
            </span>
          </div>

          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin CMND/CCCD:
              </span>
            </div>
            <div className="col-3  d-flex">
              <div className="col-6">
                <UploadImageSingle
                  title={"Ảnh mặt trước"}
                  onDone={(e) => {
                    setCmndFont(e);
                  }}
                />
              </div>
              <div className="col-6">
                <UploadImageSingle
                  title={"Ảnh mặt sau"}
                  onDone={(e) => {
                    setCmndBack(e);
                  }}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số CMND</label>
                <input
                  name="cmnd"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("cmnd")}
                />
                <div className="error-message">{errors?.cmnd?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Nơi cấp</label>
                <input
                  name="issued_by"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("issued_by")}
                />
                <div className="error-message">
                  {errors?.issued_by?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày cấp</label>
                <div>
                  <Controller
                    name="date_range"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          value={value}
                          block
                          cleanable={false}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                </div>
                <div className="error-message">
                  {errors?.date_range?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Địa chỉ thường trú</label>
                <input
                  name="permanent_address"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("permanent_address")}
                />
                <div className="error-message">
                  {errors?.permanent_address?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Địa chỉ tạm trú</label>
                <input
                  name="temporary_address"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("temporary_address")}
                />
                <div className="error-message">
                  {errors?.temporary_address?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Dân tộc</label>
                <input
                  name="nation"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("nation")}
                />
                <div className="error-message">{errors?.nation?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tôn giáo</label>
                <input
                  name="religion"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("religion")}
                />
                <div className="error-message">{errors?.religion?.message}</div>
              </div>
            </div>
          </div>

          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin tài khoản ngân hàng:
              </span>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label>Tên ngân hàng</label>
                <input
                  name="bank_name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_name")}
                />
                <div className="error-message">
                  {errors?.bank_name?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số tài khoản</label>
                <input
                  name="bank_number"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_number")}
                />
                <div className="error-message">
                  {errors?.bank_number?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tên người thụ hưởng</label>
                <input
                  name="bank_account_name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_account_name")}
                />
                <div className="error-message">
                  {errors?.bank_account_name?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Tên chi nhánh</label>
                <input
                  name="bank_branch"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("bank_branch")}
                />
                <div className="error-message">
                  {errors?.bank_branch?.message}
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin học vấn:
              </span>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label>Trường học</label>
                <input
                  name="school"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("school")}
                />
                <div className="error-message">{errors?.school?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Trình độ học vấn</label>
                <input
                  name="level"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("level")}
                />
                <div className="error-message">{errors?.level?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Chuyên ngành</label>
                <input
                  name="specialized"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("specialized")}
                />
                <div className="error-message">
                  {errors?.specialized?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Khoá</label>
                <input
                  name="course"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("course")}
                />
                <div className="error-message">{errors?.course?.message}</div>
              </div>
            </div>
          </div>

          <div className="col-11 row mx-5">
            <div className="col-12 my-3">
              <span
                style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
              >
                Thông tin sức khỏe:
              </span>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label>Chiều cao (cm)</label>
                <input
                  name="height"
                  type="number"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("height")}
                />
                <div className="error-message">{errors?.height?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Cân nặng (kg)</label>
                <input
                  name="weight"
                  type="number"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("weight")}
                />
                <div className="error-message">{errors?.weight?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Bệnh bẩm sinh</label>
                <input
                  name="congenital"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("congenital")}
                />
                <div className="error-message">
                  {errors?.congenital?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="col-8">Đã kết hôn</label>
                <input
                  name="married"
                  type="checkbox"
                  style={{ width: 20, height: 20 }}
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("married")}
                />
                <div className="error-message">{errors?.married?.message}</div>
              </div>
            </div>
          </div>

          {/* <div className="col-4">
            <div className="form-group">
              <label>Thành phố/Tỉnh*</label>
              <Controller
                name="province"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={provinces}
                      placeholder="-- Chọn Thành phố/Tỉnh --"
                      block
                      cleanable={false}
                      value={value}
                      onSelect={(val, item) => {
                        setAddressLabel({
                          ...addressLabel,
                          provinceLabel: item.label,
                        });
                        onChange(val);
                        fetchDistricts(val);
                        setWarns([]);
                      }}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.province?.message}</div>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label>Quận/Huyện*</label>
              <Controller
                name="district"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={districts}
                      placeholder="-- Chọn Quận/Huyện --"
                      block
                      value={value}
                      cleanable={false}
                      onSelect={(val, item) => {
                        setAddressLabel({
                          ...addressLabel,
                          provinceLabel: item.label,
                        });
                        onChange(val);
                        fetchWarns(val);
                      }}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.province?.message}</div>
            </div>
          </div> */}
          {/* <div className="col-4">
            <div className="form-group">
              <label>Phường/Xã*</label>
              <Controller
                name="warn"
                control={control}
                rules={{ required: "Không được rỗng" }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <SelectPicker
                      data={warns}
                      placeholder="-- Chọn Phường/Xã --"
                      block
                      value={value}
                      cleanable={false}
                      onSelect={(val, item) => {
                        onChange(val);
                        setAddressLabel({
                          ...addressLabel,
                          warnLabel: item.label,
                        });
                      }}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.warn?.message}</div>
            </div>
          </div> */}
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-success mr-2" style={{ fontSize: "14px" }}>
            <i className="fa fa-floppy-o" aria-hidden="true"></i>Tạo nhân viên
          </button>
        </div>
      </form>
    </div>
  );
};

export default Employee;
