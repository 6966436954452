/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ColumnCheckInOut from "./components/ColumnCheckInOut";
import JobBoardModal from "./components/JobBoardModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ViewDate from "../../../components/ViewDate";
import { constants } from "../../../constants";
import { shiftServices } from "../../../services/shiftServices";
import HistoryTimeKeepingModal from "./components/HistoryTimeKeepingModal";
import TimekeepingAndRecognitionModal from "./components/TimekeepingAndRecognitionModal";
import "./style.css";
import SearchInput from "../../../components/SeachInput";
import { Link, useHistory } from "react-router-dom";
import {
  convertObjToQueryString,
  formatMoneyVND,
  secondsToTime,
} from "../../../helpers";
import { name } from "xlsx-populate/lib/RichText";
import PermissionChecker from "../../../components/PermissionChecker";
import { toast } from "react-toastify";
import ResetDataStaffModal from "./components/ResetDataStaffModal";
import Pagination from "../../../components/Pagination";

const DATE_FORMAT_DDMMYYYY_HHMMSS = "YYYY-MM-DD HH:mm:ss";

const JobBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const areaId = useSelector((state) => state.area.areaId);

  const [isRefresh, setIsRefresh] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [jobBoards, setJobBoards] = useState([]);
  const [jobAll, setJobAll] = useState([]);
  const [query, setQuery] = useState(null);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [typeDateFiller, setTypeDateFiller] = useState(constants.MONTH);
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
    histories: [],
  });

  useEffect(() => {
    if (query != null) {
      fetchCalculateJobBoard(areaId, convertObjToQueryString(query));
    }
  }, [areaId, query, isRefresh]);

  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const handleClickItem = (index) => {
    setQuery({
      ...query,
      page: index,
    });
  };

  const fetchCalculateJobBoard = async (areaId, queryString) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.fetchCalculateAll({
          queryString: queryString,
        });
        console.log((response.data?.data ?? []).map((e) => e.total_salary));
        setJobBoards(response.data?.data);
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.fetchCalculate({
          areaId,
          queryString,
        });
        console.log((response.data ?? []).map((e) => e.total_seconds).reduce((accumulator, currentValue) => accumulator + currentValue, 0));
        console.log((response.data ?? []).map((e) => e.total_salary).reduce((accumulator, currentValue) => accumulator + currentValue, 0));
        setStaffs(null);
        setJobBoards(response.data);
        setJobAll(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleSaveBonusLess = async (data) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if ((data.list_staff_id ?? []).length === 0) {
        toast.error("Chưa chọn nhân viên");
      
        return false;
      }

      if ((data.custom_day ?? []).length === 0) {
        toast.error("Chưa chọn ngày bổ sung");
      
        return false;
      }

      if (
        (data.custom_day ?? []).length === 1 &&
        (data.list_staff_id ?? []).length === 1
      ) {
        if (data.checkout_time.getTime() <= data.checkin_time.getTime()) {
          toast.error("Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc");
          
          return false;
        }
      }

      const bonusLessUpdate = {
        is_bonus: true,
        checkin_time: moment(data.checkin_time)
          .format(DATE_FORMAT_DDMMYYYY_HHMMSS)
          .toString(),
        checkout_time: moment(data.checkout_time)
          .format(DATE_FORMAT_DDMMYYYY_HHMMSS)
          .toString(),
        reason: data.reason,
        is_over_time: data.is_over_time,
        list_staff_id: data.list_staff_id,
        custom_day: data.custom_day.map((e) =>
          moment(e.toDate()).format("YYYY-MM-DD")
        ),
      };

      const response = await shiftServices.createBonusLess({
        areaId: areaId,
        body: bonusLessUpdate,
      });

      if (response.success) {
        handleCloseModal();
        toast.success(response.msg);
        setIsRefresh(!isRefresh);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      let msg = "Lỗi khi thêm bớt công cho nhân viên";
      toast.error(msg);
     
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const handleChangeDate = (date) => {
    if (query == null) {
      setQuery({
        ...query,
        date_from: moment()
          .startOf("month")
          .format(constants.FORMAT_YYYY_MM_DD),
        date_to: moment().endOf("month").format(constants.FORMAT_YYYY_MM_DD),
        page: 1,
      });
    } else {
      setQuery({
        ...query,
        date_from: moment(date.startDate).format(constants.FORMAT_YYYY_MM_DD),
        date_to: moment(date.endDate).format(constants.FORMAT_YYYY_MM_DD),
        page: 1,
      });
    }
  };

  const handleChangeTypeDate = (type) => {
    setTypeDateFiller(type);
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const countTotalSalary = (data) => {
    return data.reduce((total, currentValue) => {
      return total + currentValue.total_salary;
    }, 0);
  };

  const handleExportSalary = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.exportSalary({ areaId });
      if (response.success) {
        const link = response.data?.link;
        const tagA = document.createElement("a");
        tagA.href = link;
        tagA.download = "salary.xlsx";
        tagA.click();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      let msg = "Lỗi khi export bảng lương";
      toast.error(msg);
     
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  function removeVietnameseTones(str) {
    // Gộp nhiều dấu space thành 1 space
    str = str.replace(/\s+/g, " ");
    // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
    str = str.trim();
    // bắt đầu xóa dấu tiếng việt  trong chuỗi
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  }

  const onSubmitSearch = (value) => {
    if (areaId == constants.AREA_ALL_ID) {
      setQuery({
        ...query,
        search: value,
        page: 1,
      });
    } else {
      var result = jobAll.filter((job) =>
        removeVietnameseTones(job.staff.name ?? "")
          .toLowerCase()
          .includes(removeVietnameseTones(value.toLowerCase()))
      );

      setJobBoards(result);
    }
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">Bảng công</h4>
        <div className="d-flex">
          {PermissionChecker({
            decent: reportNumber.permissions?.reset_contract ?? false,
            child: (
              <button
                class={`btn btn-success btn-icon-split btn-sm`}
                onClick={() => {
                  setModal({
                    title: "Chạy lại dữ liệu bảng công",
                    method: "RESET",
                    visible: true,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">Chạy lại dữ liệu bảng công</span>
              </button>
            ),
          })}
          <div style={{ width: "10px" }}></div>
          {PermissionChecker({
            decent: reportNumber.permissions?.calculate_edit ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                onClick={() => {
                  setModal({
                    title: "Thêm bớt công",
                    method: "CREATE",
                    visible: true,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">Thêm bớt công</span>
              </button>
            ),
          })}
        </div>
      </div>

      <div className="card shadow mb-4 d-flex">
        <div className="d-flex align-items-center mt-4">
          <SearchInput
            placeHolder={"Tìm kiếm nhân viên"}
            onSubmitSearch={onSubmitSearch}
            // onChange={onChangeSearch}
          ></SearchInput>
          <ViewDate
            className="d-flex mt-0  ml-2 align-items-center"
            customDate={true}
            type={constants.MONTH}
            handleChange={handleChangeDate}
            onChangeType={handleChangeTypeDate}
          />
        </div>

        <div className="card-header d-flex align-items-center mx-3 mt-3 mb-0">
          <h6 className="font-weight-bold">{`Tổng lương: ${
            jobBoards &&
            (formatMoneyVND(countTotalSalary(jobBoards), true) || 0)
          }đ`}</h6>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper" className="table-job-board">
              <table
                className="table no-footer dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr>
                    <th className="">Nhân viên</th>
                    {typeDateFiller === constants.DAY && <th>Vào/Tan ca</th>}
                    <th>Số giờ làm</th>
                  </tr>
                </thead>
                <tbody>
                  {jobBoards?.map((item, index) => {
                    const displayTotalTime = item.total_seconds
                      ? secondsToTime(item.total_seconds)
                      : {
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        };
                    return (
                      <tr
                        key={index}
                        className="table-row"
                        onClick={(e) => {
                          if (
                            (reportNumber.permissions?.calculate_edit ??
                              false) == true
                          ) {
                            if (e.target.closest(".history")) return;
                            if (areaId == constants.AREA_ALL_ID) {
                              history.push({
                                pathname: "/timekeeping-history",
                                state: item,
                              });
                            } else {
                              history.push({
                                pathname: "/timekeeping-history",
                                state: item.staff,
                              });
                            }
                          } else {
                            toast.error("Bạn không có quyền truy cập");
                          }
                        }}
                      >
                        <td className="align-items-center">
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "16px" }}>
                              {imageError || item.staff?.avatar === null ? (
                                <div
                                  className="avatar-placeholder"
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "5px",
                                    objectFit: "cover",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#f6c23e",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      color: "white",
                                    }}
                                  >
                                    {areaId == constants.AREA_ALL_ID
                                      ? item?.name &&
                                        item.name.charAt(0).toUpperCase()
                                      : item.staff?.name &&
                                        item.staff?.name
                                          .charAt(0)
                                          .toUpperCase()}
                                  </div>
                                  <div></div>
                                </div>
                              ) : (
                                <img
                                  src={
                                    areaId == constants.AREA_ALL_ID
                                      ? item?.avatar
                                      : item.staff?.avatar
                                  }
                                  alt="Avatar"
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                    border: "1px solid #ccc",
                                  }}
                                  onError={onError}
                                />
                              )}
                            </div>
                            <div>
                              <div
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {areaId == constants.AREA_ALL_ID
                                  ? item.name
                                  : item.staff?.name || ""}
                              </div>
                              <div className="d-flex align-items-center">
                                <i
                                  class="fa-regular fa-clock"
                                  style={{
                                    color: "#f6c23e",
                                  }}
                                ></i>{" "}
                                <div
                                  style={{
                                    width: "5px",
                                  }}
                                ></div>
                                <div style={{ fontSize: "12px" }}>
                                  {areaId == constants.AREA_ALL_ID
                                    ? `${formatMoneyVND(
                                        item?.contract?.salary ?? 0,
                                        true
                                      )}`
                                    : `${formatMoneyVND(
                                        item.staff?.contract?.salary ?? 0,
                                        true
                                      )}
                              ₫/h`}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <p className="text-center">
                            
                          </p> */}
                        </td>
                        {typeDateFiller === constants.DAY && (
                          <td
                            className="history"
                            onClick={() => {
                              setModal({
                                title: "Lịch sử chấm công",
                                method: "HISTORY",
                                visible: true,
                                histories: item?.history_checkin_checkout,
                              });
                            }}
                          >
                            {item?.history_checkin_checkout?.map(
                              (item, index) => (
                                <ColumnCheckInOut
                                  history={item}
                                  key={index}
                                  style={{ marginBottom: "10px" }}
                                />
                              )
                            )}
                          </td>
                        )}
                        <td>
                          <p>{`${displayTotalTime.hours} giờ ${
                            displayTotalTime.minutes
                          } phút ${
                            displayTotalTime.seconds
                          } giây - ${formatMoneyVND(
                            item.total_salary,
                            true
                          )}đ`}</p>
                          {/* <a
                            onClick={() => {
                              setModal({
                                title: "Chấm công ghi nhận",
                                method: "RECOGNITION",
                                visible: true,
                              });
                            }}
                            href="#"
                          >
                            Chi tiết
                          </a> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {staffs && staffs?.links?.length > 0 && (
                <Pagination
                  to={staffs?.to}
                  handleClickItem={handleClickItem}
                  links={staffs?.links}
                  lastPage={staffs?.last_page}
                  currentPage={staffs?.current_page}
                ></Pagination>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
      <JobBoardModal
        visible={modal.visible && modal.method === "CREATE"}
        title={modal.title}
        staffs={staffs}
        closeModal={handleCloseModal}
        onSubmit={handleSaveBonusLess}
      />
      <ResetDataStaffModal
        visible={modal.visible && modal.method === "RESET"}
        onSuccess={() => {
          fetchCalculateJobBoard(areaId, convertObjToQueryString(query));
        }}
        closeModal={handleCloseModal}
        // onSubmit={handleSaveBonusLess}
      />
      <HistoryTimeKeepingModal
        visible={modal.visible && modal.method === "HISTORY"}
        title={modal.title}
        histories={modal.histories}
        closeModal={handleCloseModal}
      />
      <TimekeepingAndRecognitionModal
        visible={modal.visible && modal.method === "RECOGNITION"}
        title={modal.title}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

export default JobBoard;
