/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { convertObjToQueryString } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";
import { staffsServices } from "../../../services/staffsServices";
Modal.setAppElement("#root");

const QuestionInput = ({
  index,
  value,
  onChange,
  onAddQuestion,
  onDeleteQuestion,
  isDelete,
}) => {
  return (
    <div className="d-flex align-items-center mt-2 mb-2" key={index}>
      <input
        className="form-control"
        type="text"
        value={value}
        onChange={(e) => onChange(e, index)}
        placeholder="Thêm lựa chọn"
      />
      <div className="icon-group">
        <i
          class="fa fa-trash-o ml-2 question-group-icon text-danger"
          aria-hidden="true"
          onClick={() => onDeleteQuestion(index)}
        ></i>
        {!isDelete && (
          <i
            class="fa fa-plus-circle ml-2 question-group-icon text-success"
            aria-hidden="true"
            onClick={onAddQuestion}
          ></i>
        )}
      </div>
    </div>
  );
};

const questionDefault = {
  id: null,
  title: "",
  list_option: [""],
  is_active: 1,
  sort: 1,
  staff_id: null,
};

const QuestionDetailModal = ({
  visible,
  title,
  onSubmit,
  questionDetail,
  closeModal,
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: questionDefault,
  });

  const areaId = useSelector((state) => state.area.areaId);

  const [selectStaff, setSelectStaff] = useState(null);

  useEffect(() => {
    if (visible) {
      if (questionDetail) {
        reset(questionDetail);
        if (questionDetail?.staff_id) {
          loadSelectStaff(questionDetail.staff_id);
        }
      }
    } else {
      reset(questionDefault);
      setSelectStaff(null);
    }
  }, [questionDetail]);

  const loadStaffOptions = async (search, __loadedOptions, { page }) => {
    const params = {
      page: page,
      area_id: areaId,
    };

    try {
      const res = await staffsServices.getAllStaffs(
        convertObjToQueryString(params)
      );
      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const loadSelectStaff = async (id) => {
    try {
      const response = await staffsServices.getStaffInfo(id);
      setSelectStaff({
        value: response.data.id,
        label: response.data.name,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelect = (selectValue) => {
    setSelectStaff(selectValue);
  };

  const handleAddQuestion = () => {
    reset({
      ...getValues(),
      list_option: [...getValues("list_option"), ""],
    });
  };

  const handleDeleteQuestion = (index) => {
    const options = getValues("list_option");
    options.splice(index, 1);

    reset({
      ...getValues(),
      list_option: [...options],
    });
  };

  const handleOnchangeOptionByIndex = (e, index) => {
    const options = getValues("list_option");
    options[index] = e.target.value;

    reset({
      ...getValues(),
      list_option: [...options],
    });
  };

  const onSubmitForm = (data) => {
    onSubmit({ ...data, staff_id: selectStaff.value });
  };

  const setStyleValidate = (name) =>
    errors[name] ? { border: "1px solid red" } : null;
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="form-group">
            <label>
              Tên câu hỏi <span className="text-danger">*</span>
            </label>
            <input
              name="title"
              type="text"
              className="form-control"
              placeholder="Nhập tên câu hỏi"
              autoComplete="off"
              style={setStyleValidate("title")}
              {...register("title", {
                required: true,
              })}
            />
            {errors?.title?.type === "required" && (
              <small className="text-danger">Vui lòng nhập tên câu hỏi</small>
            )}
          </div>
          <div className="form-group">
            <label>Chọn nhân viên</label>
            <AsyncPaginate
              placeholder="-- Chọn nhân viên --"
              value={selectStaff}
              loadOptions={loadStaffOptions}
              onChange={onChangeSelect}
              additional={{
                page: 1,
              }}
              debounceTimeout={500}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              isClearable
              isSearchable
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="mb-0" style={{ fontWeight: "750" }}>
                Danh sách lựa chọn
              </label>
              {!getValues("list_option")?.length > 0 && (
                <i
                  class="fa fa-plus-circle ml-2 question-group-icon text-success"
                  aria-hidden="true"
                  onClick={handleAddQuestion}
                ></i>
              )}
            </div>
            {getValues("list_option")?.map((item, index) => (
              <QuestionInput
                index={index}
                value={item}
                isDelete={index !== getValues("list_option").length - 1}
                onAddQuestion={handleAddQuestion}
                onDeleteQuestion={handleDeleteQuestion}
                onChange={handleOnchangeOptionByIndex}
              />
            ))}
          </div>
          <div className="form-group d-flex align-item-center">
            <label className="mb-0">Trạng thái</label>
            <input
              type="checkbox"
              className="ml-2"
              value={getValues("is_active") ? true : false}
              checked={getValues("is_active") ? true : false}
              onChange={(e) =>
                reset({ ...getValues(), is_active: e.target.checked ? 1 : 0 })
              }
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={closeModal}
            >
              Đóng
            </button>
            <button type="submit" className="btn btn-info">
              Lưu
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default QuestionDetailModal;
