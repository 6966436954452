import { Tabs } from "antd";
import FranchiseReport from "./Report/FranchiseReport";
import FranchiseReportCate from "./FranchiseReportCate/FranchiseReportCate";

export default function FranchiseReportPage() {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
        label: "DANH SÁCH BÁO CÁO",
          key: "1",
          children: FranchiseReport(),
        }, 
        {
          label: "LOẠI BÁO CÁO",
          key: "2",
          children: FranchiseReportCate(),
        },
      ]}
    />
  );
}
