/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { shiftServices } from "../../../../services/shiftServices";
import { formatMoneyVND } from "../../../../helpers";
import { constants } from "../../../../constants";
Modal.setAppElement("#root");

const SalaryDetailModal = ({ visible, title, id, closeModal }) => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  const [salary, setSalary] = useState();
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    if (visible) {
      getSalaryById(id);
    } else {
      setSalary();
    }
  }, [id, visible]);

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " giờ " : " giờ ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " phút " : " phút ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " giây" : " giây") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  const getSalaryById = async (id) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.getSalaryById({ areaId, id });
      setSalary(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  function getSupport() {
    return (salary?.supports ?? []).length != 0
      ? (salary?.supports ?? [])
          .map((e) => e.value ?? 0)

          .reduce((v, e) => v + e)
      : 0;
  }

  function itemSupport() {
    var result = null;

    if ((salary.supports ?? []).isNotEmpty) {
      if (salary.supports.length > 0) {
        result = salary.supports.map((e, index) => {
          var titles = e.title;
          var v = e.value;
          return (
            <div className="form-group">
              <div className="d-flex align-items-center">
                <label className="col-lg-8 pl-0 pr-0">titles</label>
                <input
                  value={formatMoneyVND(v ?? 0)}
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  readOnly
                />
              </div>
            </div>
          );
        });
      }
    }

    return result;
  }

  const customStyles = {
    content: {
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="modal-body">
          <div className="form-group">
            <label>Tháng áp dụng:</label>
            <input
              value={`${salary?.month || 0}-${salary?.year || 0}`}
              type="text"
              className="form-control"
              autoComplete="off"
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Thời gian:</label>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Chức vụ:</label>
              <input
                value={salary?.staff?.position || ""}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Phòng ban:</label>
              <input
                value={salary?.staff?.department?.name || ""}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Chi nhánh:</label>
              <input
                value={salary?.area?.name || ""}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Ngày công chuẩn:</label>
              <input
                value={salary?.count_day_work ?? 0}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Giờ công chuẩn:</label>
              <input
                value={
                  salary?.time_default
                    ? (salary.time_default / 3600).toFixed(2)
                    : 0
                }
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Giờ công thực tế:</label>
              <input
                value={secondsToHms(salary?.work_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Giờ công chấm hộ, thêm giờ:
              </label>
              <input
                value={secondsToHms(
                  (salary?.household_time ?? 0) + (salary?.add_time ?? 0)
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Quên chấm công được duyệt:
              </label>
              <input
                value={secondsToHms(salary?.bonus_form_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Nghỉ phép được duyệt:
              </label>
              <input
                value={secondsToHms(salary?.leave_form_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Công tác được duyệt:</label>
              <input
                value={secondsToHms(salary?.trip_form_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Tăng ca/thêm giờ được duyệt:
              </label>
              <input
                value={secondsToHms(salary?.over_form_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Giờ làm lễ:</label>
              <input
                value={secondsToHms(salary?.ot_time || 0)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>

          {showTime ? (
            (salary?.shift_money ?? []).map((e) => {
              return (
                <div className="form-group">
                  <div className="d-flex align-items-center">
                    <label
                      className="col-lg-8 pl-0 pr-0"
                      style={{ color: "blue" }}
                    >
                      {e?.shift_name ?? ""}
                    </label>
                    <input
                      value={secondsToHms(e?.time || 0)}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700", color: "blue" }}
                onClick={(e) => {
                  setShowTime(!showTime);
                }}
              >
                TỔNG GIỜ CÔNG (Xem chi tiết):
              </label>
              <input
                value={secondsToHms(
                  (salary?.work_time ?? 0) +
                    (salary?.leave_form_time ?? 0) +
                    (salary?.trip_form_time ?? 0) +
                    (salary?.bonus_form_time ?? 0) +
                    (salary?.over_form_time ?? 0) +
                    (salary?.ot_time ?? 0) +
                    (salary?.household_time ?? 0) +
                    (salary?.add_time ?? 0)
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              height: "10px",
            }}
          ></div>
          <div className="form-group">
            <label>THU NHẬP:</label>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Lương theo giờ công chuẩn:
              </label>
              <input
                value={formatMoneyVND(salary?.work_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Lương được chấm hộ, thêm giờ:
              </label>
              <input
                value={formatMoneyVND(
                  (salary?.household_money ?? 0) + (salary?.add_money ?? 0),
                  true
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Lương quên chấm công:
              </label>
              <input
                value={formatMoneyVND(salary?.bonus_form_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương nghỉ phép:</label>
              <input
                value={formatMoneyVND(salary?.leave_form_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương công tác:</label>
              <input
                value={formatMoneyVND(salary?.trip_form_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương vị trí (P1):</label>
              <input
                value={formatMoneyVND(salary?.position_salary ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương năng lực (P2):</label>
              <input
                value={formatMoneyVND(salary?.support_productive ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">KPI đạt được:</label>
              <input
                value={formatMoneyVND(salary?.kpi_percent ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương kết quả (P3):</label>
              <input
                value={formatMoneyVND(salary?.support_result ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          {showTime ? (
            (salary?.shift_money ?? []).map((e) => {
              return (
                <div className="form-group">
                  <div className="d-flex align-items-center">
                    <label
                      className="col-lg-8 pl-0 pr-0"
                      style={{ color: "blue" }}
                    >
                      {`${e?.shift_name ?? ""} - ${formatMoneyVND(
                        e?.money_shift ?? 0,
                        true
                      )}`}
                    </label>
                    <input
                      value={formatMoneyVND(e?.money ?? 0, true)}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700", color: "blue" }}
                onClick={(e) => {
                  setShowTime(!showTime);
                }}
              >
                Tổng lương theo giờ công thực tế (1) (Xem chi tiết):
              </label>
              <input
                value={formatMoneyVND(salary?.salary_with_kpi ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương tăng ca:</label>
              <input
                value={formatMoneyVND(salary?.over_form_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Lương làm lễ:</label>
              <input
                value={formatMoneyVND(salary?.ot_money ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700" }}
              >
                Tổng lương tăng ca (2):
              </label>
              <input
                value={formatMoneyVND(
                  (salary?.over_form_money ?? 0) + (salary?.ot_money ?? 0),
                  true
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>

          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Cơm trưa (50%):</label>
              <input
                value={formatMoneyVND(salary?.pc_food ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Xăng xe (30%):</label>
              <input
                value={formatMoneyVND(salary?.pc_move ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Điện thoại (20%):</label>
              <input
                value={formatMoneyVND(salary?.pc_phone ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Thâm niên:</label>
              <input
                value={formatMoneyVND(salary?.seniority ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          {(salary?.supports ?? []).map((e) => {
            return (
              <div className="form-group">
                <div className="d-flex align-items-center">
                  <label className="col-lg-8 pl-0 pr-0">{e?.title ?? ""}</label>
                  <input
                    value={formatMoneyVND(e?.value ?? 0, true)}
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    readOnly
                  />
                </div>
              </div>
            );
          })}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700" }}
              >
                Tổng lương phụ cấp (3):
              </label>
              <input
                value={formatMoneyVND(
                  ((salary?.pc_food ?? 0) +
                    (salary?.pc_move ?? 0) +
                    (salary?.pc_phone ?? 0) + (salary?.seniority ?? 0) +
                    getSupport()),
                  true
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>

          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>

          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700" }}
              >
                Tạm ứng (4):
              </label>
              <input
                value={formatMoneyVND(salary?.total_bonus ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Cộng khác:</label>
              <input
                value={formatMoneyVND(salary?.another_plus ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Trừ khác:</label>
              <input
                value={formatMoneyVND(salary?.another_minus ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Trừ không đạt KPI đào tạo:
              </label>
              <input
                value={formatMoneyVND(salary?.education_kpi_minus ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700" }}
              >
                TỔNG THU NHẬP (1) + (2) + (3) + (4) + (5) + (6):
              </label>
              <input
                value={formatMoneyVND(salary?.salary_provisional ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <label>KHOẢN TRỪ:</label>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">BHXH</label>
              <input
                value={formatMoneyVND(salary?.BHXH ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">BHYT</label>
              <input
                value={formatMoneyVND(salary?.BHYT ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">BHTN</label>
              <input
                value={formatMoneyVND(salary?.BHTN ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
         
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label
                className="col-lg-8 pl-0 pr-0"
                style={{ fontWeight: "700" }}
              >
                Bảo hiểm:
              </label>
              <input
                value={formatMoneyVND(
                  (salary?.BHXH ?? 0) +
                    (salary?.BHYT ?? 0) +
                    (salary?.BHTN ?? 0),
                  true
                )}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Công đoàn</label>
              <input
                value={formatMoneyVND(salary?.cong_doan ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #D3D3D3",
              width: "100%",
              paddingBottom: "0px",
            }}
          ></div>
          <div className="form-group" style={{ paddingTop: "10px" }}>
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Thuế</label>
              <input
                value={formatMoneyVND(salary?.tax ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">
                Khác (Khoản phát sinh tính vào lương thực nhận)
              </label>
              <input
                value={formatMoneyVND(salary?.another ?? 0, true)}
                type="text"
                className="form-control"
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div
          className="salary-receives"
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          <label className="salary-receives-text">
            Lương thực nhận: {formatMoneyVND(salary?.salary ?? 0, true)} VNĐ
          </label>
        </div>
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Đóng
        </button>
      </div>
    </Modal>
  );
};

export default SalaryDetailModal;
