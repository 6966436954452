import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, SelectPicker } from "rsuite";
import { emailRegex, phoneRegex } from "../../helpers";
import { areaServices } from "../../services/areaServices";
import { shiftServices } from "../../services/shiftServices";
import { useParams } from "react-router-dom";
import { constants } from "../../constants";
import moment from "moment";
import history from "../../history";
import UploadImageSingle from "../../components/UploadImageSingle";
import StaffStatusModal from "./child/StaffStatusModal";
import { isToday } from "date-fns";
import { toast } from "react-toastify";
import { Button } from "antd";
import { get, set } from "lodash";
import StaffStatusWatchedModal from "./child/StaffStatusWatchedModal";
import dayjs from "dayjs";

const dataGender = [
  { label: "Nam", value: 1 },
  { label: "Nữ", value: 2 },
  { label: "Không xác định", value: 0 },
];

const StaffEdit = () => {
  const areaId = useSelector((state) => state.area.areaId);
  const [cmndFront, setCmndFront] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [cmndBack, setCmndBack] = useState(null);
  const [staffAreaId, setStaffAreaId] = useState(null);
  const [staffDepartmentId, setStaffDepartmentId] = useState(null);
  const [dataFormFirst, setDataFormFirst] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm();

  const [modalData, setModalData] = useState({
    method: null,
    visible: false,
  });

  // department
  const [departments, setDepartments] = useState([]);
  const [getPassword, setGetPassword] = useState(false);

  const fetchAllDepartment = async () => {
    try {
      const response = await shiftServices.fetchAllDepartments();
      const result = response.data.map((department) => ({
        label: department.name,
        value: department.id,
      }));
      setDepartments(result);
    } catch (error) {
      console.log(error);
    }
  };
  // area
  const [areas, setAreas] = useState([]);
  const [staffStatus, setStaffStatus] = useState([]);
  const fetchAllArea = async () => {
    try {
      const response = await areaServices.fetchAreas();
      const result = response.data.map((area) => ({
        label: area.name,
        value: area.id,
      }));
      setAreas(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getStaffStatus = async () => {
    try {
      const response = await shiftServices.getStaffStatus(id);
      setStaffStatus(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStaffStatus = async (id) => {
    try {
      const response = await shiftServices.deleteStaffStatus(id);
      getStaffStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const [roles, setRoles] = useState([]);
  const fetchAllAllRole = async () => {
    try {
      const response = await shiftServices.getRole({ areaId });
      const result = response.data.map((role) => ({
        label: role.title,
        value: role.id,
      }));
      setRoles(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployeeById = async () => {
    try {
      const response = await shiftServices.fetchEmployeeById(areaId, id);
      reset({
        ...response.data,
        start_join_time: isNaN(new Date(response.data.start_join_time))
          ? null
          : new Date(response.data.start_join_time),
        birthday: isNaN(new Date(response.data.birthday))
          ? null
          : new Date(response.data.birthday),
        date_range: isNaN(new Date(response.data.date_range))
          ? null
          : new Date(response.data.date_range),
        date_leave: isNaN(new Date(response.data.date_leave))
          ? null
          : new Date(response.data.date_leave),
      });
      setCmndFront(response.data.cmnd_front);
      setCmndBack(response.data.cmnd_back_side);
      setAvatar(response.data.avatar);
      setStaffAreaId(response.data.area_id);
      setStaffDepartmentId(response.data.department_id);
      setDataFormFirst(getValues());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllDepartment();
    fetchAllArea();
    fetchAllAllRole();
    fetchEmployeeById();
    getStaffStatus();
  }, []);

  const onSubmit = async (values, isForce) => {
    try {
      const currentDate = new Date();
      if (!departments.map((e) => e.value).includes(values.department_id)) {
        toast.error("Phòng ban không hợp lệ");
        return;
      }

      const body = {
        name: values.name,
        user_name: values.username,
        email: values.email,
        password: values.password,
        phone_number: values.phone_number,
        sex: values.sex,
        position: values.position,
        address: values.address,
        start_join_time: moment(values.start_join_time).format(
          constants.FORMAT_YYYY_MM_DD_HH_MM_SS
        ),
        department_id:
          isForce == true ? values.department_id : values.department_id,
        staff_code: values.staff_code,
        avatar: values?.avatar,
        province_id: null,
        district_id: null,
        township_id: null,
        province_name: null,
        district_name: null,
        township_name: null,
        birthday: moment(values.birthday).format(constants.FORMAT_YYYY_MM_DD),
        cmnd: values.cmnd,
        date_range: values?.date_range,
        date_leave: values?.date_leave,
        leave_reason: values?.leave_reason,
        healthcare_first_place: values?.healthcare_first_place,
        bhyt_code: values?.bhyt_code,
        tax_code: values?.tax_code,
        issued_by: values?.issued_by,
        permanent_address: values?.permanent_address,
        temporary_address: values?.temporary_address,
        role_id: values?.role_id,
        area_id: isForce == true ? values.area_id : values?.area_id,
        cmnd_front: cmndFront ?? "",
        cmnd_back_side: cmndBack ?? "",
        avatar: avatar ?? "",
        nation: values?.nation,
        religion: values?.religion,
        bank_name: values?.bank_name,
        bank_number: values?.bank_number,
        bank_account_name: values?.bank_account_name,
        bank_branch: values?.bank_branch,
        school: values?.school,
        course: values?.course,
        level: values?.level,
        specialized: values?.specialized,
        height: values?.height,
        weight: values?.weight,
        congenital: values?.congenital,
        married: values?.married,
      };

      const response = await shiftServices.updateEmployee(body, areaId, id);
      if (response.success) {
        // if (isShowSuccess != false) {
        //   toast.success(response.msg);
        //   history.goBack();
        // }
      } else {
        toast.error(response.msg);
      }
    } catch (error) {}
  };

  const checkStaffStatus = async (values) => {
    var listChange = [];

    if (values.area_id != staffAreaId) {
      listChange.push({
        content: `Chi nhánh đang chờ thuyên chuyển --> ${
          areas?.find((e) => e.value == values.area_id)?.label
        }`,
        area_to: values.area_id,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: true,
      });
    }

    if (values.department_id != staffDepartmentId) {
      listChange.push({
        content: `Phòng ban đang chờ thuyên chuyển --> ${
          departments?.find((e) => e.value == values.department_id)?.label
        }`,
        department_to: values.department_id,
        effective_date: dayjs(Date.now()),
        is_save_salary_table: false,
      });
    }

    console.log(listChange);

    if (
      values.area_id != staffAreaId ||
      values.department_id != staffDepartmentId
    ) {
      setModalData({
        method: "STATUS",
        visible: true,
        listChange: listChange,
      });
    } else {
      await onSubmit(values);
      toast.success("Cập nhật thành công");
    }
  };

  const addStaffStatus = async (
    effective_date,
    is_save_salary_table,
    values,
    content
  ) => {
    try {
      const body = {
        area_to: values.area_to,
        department_to: values.department_to,
        staff_id: id,
        is_save_salary_table: is_save_salary_table,
        content: content,
        effective_date: effective_date?.format("YYYY-MM-DD"),
      };
      const response = await shiftServices.addStaffStatus(body);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateSalary = async () => {
    try {
      const salaryBody = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        list_data: [
          {
            staff_id: id,
            kpi_percent: 100,
            another_plus: 0,
            another_minus: 0,
            use_education_kpi: true,
            BHXH: 0,
            tax: 0,
            list_support_default_id: [],
            list_bonus_default_id: [],
            list_bonus: [],
          },
        ],
      };
      const response = await shiftServices.createSalary({
        areaId: areaId,
        body: salaryBody,
      });

      if (response.success) {
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("Lỗi khi tạo bảng lương");
    } finally {
      // dispatch({
      //   type: constants.CHANGE_LOADING,
      //   typeLoading: constants.NONE_LOADING,
      // });
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Chỉnh sửa nhân viên
        </h4>
        <div className="d-flex align-items-center">
          {(staffStatus ?? []).length > 0 && (
            <Button
              style={{ color: "#1ecc78", borderColor: "#1ecc78" }}
              onClick={(e) => {
                setModalData({
                  method: "STATUS_WATCHED",
                  visible: true,
                  data: staffStatus,
                });
              }}
            >{`Đang chờ thay đổi`}</Button>
          )}
          <div style={{ width: "10px" }}></div>
          <button
            className="px-3"
            style={{
              fontSize: "14px",
              backgroundColor: "red",
              borderRadius: "7px",
              color: "white",
              height: "35px",
            }}
            onClick={(e) => {
              setGetPassword(!getPassword);
            }}
          >
            <i className="fa fa-floppy-o" aria-hidden="true"></i>Lấy lại mật
            khẩu
          </button>
          <div style={{ width: "10px" }}></div>
          {getPassword === true ? (
            <div className="form-group" style={{ width: "200px" }}>
              <input
                name="password"
                type="text"
                className="form-control"
                placeholder="Nhập mật khẩu"
                autoComplete="off"
                {...register("password")}
              />
              <div className="error-message">{errors?.password?.message}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="card shadow mb-4 p-4">
        <form onSubmit={handleSubmit(checkStaffStatus)}>
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <label>Họ và tên*</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("name", {
                    required: "Không được rỗng",
                  })}
                />
                <div className="error-message">{errors?.name?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Số điện thoại*</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("phone_number", {
                    required: "Không được rỗng",
                    pattern: {
                      value: phoneRegex,
                      message: "Số điện thoại không hợp lệ",
                    },
                  })}
                />
                <div className="error-message">
                  {errors?.phone_number?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("email", {
                    pattern: {
                      value: emailRegex,
                      message: "Email không hợp lệ",
                    },
                  })}
                />
                <div className="error-message">{errors?.email?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày vào làm*</label>
                <div>
                  <Controller
                    name="start_join_time"
                    control={control}
                    rules={{ required: "Không được rỗng" }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          cleanable={false}
                          value={value}
                          block
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                </div>
                <div className="error-message">
                  {errors?.start_join_time?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Giới tính*</label>
                <Controller
                  name="sex"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        cleanable={false}
                        data={dataGender}
                        value={value}
                        placeholder="-- Chọn giới tính --"
                        block
                        onChange={(val) => onChange(val)}
                      />
                    );
                  }}
                />
                <div className="error-message">{errors?.sex?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Mã nhân viên*</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("staff_code", {
                    required: "Không được rỗng",
                  })}
                />
                <div className="error-message">
                  {errors?.staffCode?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Chi nhánh*</label>
                <Controller
                  name="area_id"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        data={areas}
                        cleanable={false}
                        placeholder="-- Chọn chi nhánh --"
                        block
                        value={value}
                        onChange={(val) => onChange(val)}
                      />
                    );
                  }}
                />
                <div className="error-message">{errors?.areaId?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Phân quyền*</label>
                <Controller
                  name="role_id"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        data={roles}
                        placeholder="-- Chọn phân quyền --"
                        block
                        cleanable={false}
                        value={value}
                        onChange={(val) => onChange(val)}
                      />
                    );
                  }}
                />
                <div className="error-message">{errors?.role_id?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Phòng ban*</label>
                <Controller
                  name="department_id"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        cleanable={false}
                        data={departments}
                        placeholder="-- Chọn Phòng ban --"
                        block
                        value={value}
                        onChange={(val) => onChange(val)}
                      />
                    );
                  }}
                />
                <div className="error-message">
                  {errors?.department?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Vị trí</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("position")}
                />
                <div className="error-message">{errors?.position?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày sinh*</label>
                <div>
                  <Controller
                    name="birthday"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          value={value}
                          block
                          cleanable={false}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                </div>
                <div className="error-message">{errors?.birthday?.message}</div>
              </div>
            </div>
            <div className="col-12 my-2">
              <span style={{ fontSize: 14, fontWeight: "700", color: "red" }}>
                CÁC TRƯỜNG PHÍA DƯỚI CÓ THỂ BỎ QUA:
              </span>
            </div>

            <div className="col-11 row mx-5">
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin CMND/CCCD:
                </span>
              </div>
              <div className="col-3 d-flex">
                <div className="col-4">
                  <UploadImageSingle
                    imageInput={avatar}
                    title={"Avata"}
                    onDone={(e) => {
                      setAvatar(e);
                    }}
                  />
                </div>
                <div className="col-4">
                  <UploadImageSingle
                    imageInput={cmndFront}
                    title={"Ảnh mặt trước"}
                    onDone={(e) => {
                      setCmndFront(e);
                    }}
                  />
                </div>
                <div className="col-4">
                  <UploadImageSingle
                    imageInput={cmndBack}
                    title={"Ảnh mặt sau"}
                    onDone={(e) => {
                      setCmndBack(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Số CMND</label>
                  <input
                    name="cmnd"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("cmnd")}
                  />
                  <div className="error-message">{errors?.cmnd?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Nơi cấp</label>
                  <input
                    name="issued_by"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("issued_by")}
                  />
                  <div className="error-message">
                    {errors?.issued_by?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Ngày cấp</label>
                  <div>
                    <Controller
                      name="date_range"
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <DatePicker
                            value={value}
                            block
                            cleanable={false}
                            onChange={(date) => onChange(date)}
                            format="dd/MM/yyyy"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="error-message">
                    {errors?.date_range?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Địa chỉ thường trú</label>
                  <input
                    name="permanent_address"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("permanent_address")}
                  />
                  <div className="error-message">
                    {errors?.permanent_address?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Địa chỉ tạm trú</label>
                  <input
                    name="temporary_address"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("temporary_address")}
                  />
                  <div className="error-message">
                    {errors?.temporary_address?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Dân tộc</label>
                  <input
                    name="nation"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("nation")}
                  />
                  <div className="error-message">{errors?.nation?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Tôn giáo</label>
                  <input
                    name="religion"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("religion")}
                  />
                  <div className="error-message">
                    {errors?.religion?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 row mx-5">
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin tài khoản ngân hàng:
                </span>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label>Tên ngân hàng</label>
                  <input
                    name="bank_name"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("bank_name")}
                  />
                  <div className="error-message">
                    {errors?.bank_name?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Số tài khoản</label>
                  <input
                    name="bank_number"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("bank_number")}
                  />
                  <div className="error-message">
                    {errors?.bank_number?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Tên người thụ hưởng</label>
                  <input
                    name="bank_account_name"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("bank_account_name")}
                  />
                  <div className="error-message">
                    {errors?.bank_account_name?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Tên chi nhánh</label>
                  <input
                    name="bank_branch"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("bank_branch")}
                  />
                  <div className="error-message">
                    {errors?.bank_branch?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 row mx-5">
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin học vấn:
                </span>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label>Trường học</label>
                  <input
                    name="school"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("school")}
                  />
                  <div className="error-message">{errors?.school?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Trình độ học vấn</label>
                  <input
                    name="level"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("level")}
                  />
                  <div className="error-message">{errors?.level?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Chuyên ngành</label>
                  <input
                    name="specialized"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("specialized")}
                  />
                  <div className="error-message">
                    {errors?.specialized?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Khoá</label>
                  <input
                    name="course"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("course")}
                  />
                  <div className="error-message">{errors?.course?.message}</div>
                </div>
              </div>
            </div>

            <div className="col-11 row mx-5">
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin sức khỏe:
                </span>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Chiều cao (cm)</label>
                  <input
                    name="height"
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("height")}
                  />
                  <div className="error-message">{errors?.height?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Cân nặng (kg)</label>
                  <input
                    name="weight"
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("weight")}
                  />
                  <div className="error-message">{errors?.weight?.message}</div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Bệnh bẩm sinh</label>
                  <input
                    name="congenital"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("congenital")}
                  />
                  <div className="error-message">
                    {errors?.congenital?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label className="col-8">Đã kết hôn</label>
                  <input
                    name="married"
                    type="checkbox"
                    style={{ width: 20, height: 20 }}
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("married")}
                  />
                  <div className="error-message">
                    {errors?.married?.message}
                  </div>
                </div>
              </div>

              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin thuế:
                </span>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Mã số thuế</label>
                  <input
                    name="tax_code"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("tax_code")}
                  />
                  <div className="error-message">
                    {errors?.tax_code?.message}
                  </div>
                </div>
              </div>
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin BHXH:
                </span>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Số BHXH</label>
                  <input
                    name="bhyt_code"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("bhyt_code")}
                  />
                  <div className="error-message">
                    {errors?.bhyt_code?.message}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Nơi KCB ban đầu</label>
                  <input
                    name="healthcare_first_place"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("healthcare_first_place")}
                  />
                  <div className="error-message">
                    {errors?.healthcare_first_place?.message}
                  </div>
                </div>
              </div>
              <div className="col-12 my-3">
                <span
                  style={{ fontSize: 14, fontWeight: "700", color: "#10a0b5" }}
                >
                  Thông tin nghỉ việc:
                </span>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Ngày nghỉ việc</label>
                  <div>
                    <Controller
                      name="date_leave"
                      control={control}
                      // rules={{ required: "Không được rỗng" }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <DatePicker
                            cleanable={false}
                            value={value}
                            block
                            onChange={(date) => onChange(date)}
                            format="dd/MM/yyyy"
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Lý do nghỉ việc</label>
                  <input
                    name="leave_reason"
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("leave_reason")}
                  />
                  <div className="error-message">
                    {errors?.leave_reason?.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success mr-2"
              style={{ fontSize: "14px" }}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>Cập nhật nhân
              viên
            </button>
          </div>
        </form>
      </div>
      <StaffStatusModal
        open={modalData.visible && modalData.method === "STATUS"}
        onOk={async (listChange) => {
          const formValues = getValues();

          /// lấy các trường có thể thay đổi trừ area_id và department_id

          var formToDay = {
            ...formValues,
            area_id: null,
            department_id: null,
          };

          /// lọc list có thay đổi ngày hôm nay

          var listChangeToDay = listChange.filter((e) => {
            return isToday(new Date(e.effective_date));
          });

          console.log(listChangeToDay);

          /// tìm các trường cập nhật ngày hôm nay

          var areaToDay = listChangeToDay.find((e) => {
            return e.area_to != null;
          });

          var departmentToDay = listChangeToDay.find((e) => {
            return e.department_to != null;
          });

          var createSalarys = listChangeToDay.filter((e) => {
            return e.is_save_salary_table == true;
          });

          if (createSalarys != null && createSalarys.length > 0) {
            await handleCreateSalary();
          }

          if (areaToDay != null) {
            formToDay = {
              ...formToDay,
              area_id: areaToDay.area_to,
            };
          } else {
            formToDay = {
              ...formToDay,
              area_id: staffAreaId,
            };
          }

          if (departmentToDay != null) {
            formToDay = {
              ...formToDay,
              department_id: departmentToDay.department_to,
            };
          } else {
            formToDay = {
              ...formToDay,
              department_id: staffDepartmentId,
            };
          }

          /// cập nhật hđ ngày hôm nay

          console.log(formToDay);

          await onSubmit(formToDay, true);

          /// lọc list không có thay đổi ngày hôm nay

          var listChangeNotToDay = listChange.filter((e) => {
            return !isToday(new Date(e.effective_date));
          });

          /// cập nhật hđ không phải ngày hôm nay

          await Promise.all(
            listChangeNotToDay.map((e) => {
              if (e.area_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    department_to: null,
                    area_to: e.area_to,
                  },
                  e.content
                );
              }

              if (e.department_to != null) {
                return addStaffStatus(
                  e.effective_date,
                  e.is_save_salary_table,
                  {
                    ...formValues,
                    department_to: e.department_to,
                    area_to: null,
                  },
                  e.content
                );
              }
            })
          ).catch((error) => {
            console.error("Có lỗi xảy ra khi chạy các hàm gọi API", error);
          });

          /// cập nhật trạng thái hợp đồng

          setTimeout(() => {
            getStaffStatus();
            fetchEmployeeById();
          }, 1000);

          toast.success("Cập nhật thành công");

          setModalData({
            visible: false,
            method: "",
          });
        }}
        listChangeInput={modalData.listChange}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></StaffStatusModal>
      <StaffStatusWatchedModal
        open={modalData.visible && modalData.method === "STATUS_WATCHED"}
        onOk={(id) => {
          deleteStaffStatus(id);
        }}
        listChangeInput={modalData.data}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></StaffStatusWatchedModal>
    </div>
  );
};

export default StaffEdit;
