import React, { useEffect, useState } from "react";

export default function ModalCreate() {




  return (
      <form role="form" >
        <div class="form-group">
          <label for="product_name">Bạn có muốn xóa học viên này không?</label>
      
        </div>
 
      </form>
  );
}
