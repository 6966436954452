import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { toast } from "react-toastify";

const FileUploader = ({ title, maxCount, onDone }) => {
  const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
  const [fileUrls, setFileUrls] = useState([]);

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("File phải nhỏ hơn 20MB!");
      return false;
    }
    return isLt5M;
  };

  const props = {
    name: "file",
    maxCount: maxCount ?? 3,
    multiple: true,
    beforeUpload,
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("image", file);
      fetch("https://crm-api.studyguide.dev/api/upload_v2/files", {
        method: "POST",
        headers: {
          token: tokenInfo,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // setFileUrls([...fileUrls, data?.data?.image_url]);
          onSuccess(data, file);
        })
        .catch((error) => {
          onError(error, file);
        });
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        onDone(info.fileList.map((e) => e.response?.data?.image_url ?? ""));
        setFileUrls(
          info.fileList.map((e) => e.response?.data?.image_url ?? "")
        );
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Upload   {...props} >
      
      <Button icon={<UploadOutlined />}>{title ?? "Upload File"}</Button>
    </Upload>
  );
};
export default FileUploader;
