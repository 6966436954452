import React from "react";
import { useEffect } from "react";
import { Checkbox, Modal } from "antd";
import { Button, Form, Input } from "antd";
import { evaluationServices } from "../../../../services/evaluationServices";
import { shiftServices } from "../../../../services/shiftServices";
import { AsyncPaginate } from "react-select-async-paginate";

const AddQcEvaluateMainModal = ({
  open,
  closeModal,
  onOk,
  dataInput,
  isReport,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [isReportCheckout, setIsReportCheckout] = React.useState(false);
  const [listDepartment, setListDepartment] = React.useState([]);

  useEffect(() => {
    if (dataInput != null) {
      console.log(dataInput.departments.length);
      if (dataInput.departments.length != 0) {
        setIsReportCheckout(true);
        setListDepartment(
          dataInput.departments.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      } else {
        setIsReportCheckout(false);
      }
      onFill();
    } else {
      onReset();
    }
  }, [open]);

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const createQCEvaluateMain = async (data) => {
    setLoading(true);
    try {
      const response = await evaluationServices.createQCEvaluateMain(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateQCEvaluateMain = async (data, id) => {
    setLoading(true);
    try {
      const response = await evaluationServices.updateQCEvaluateMain(id, data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    console.log(values);
    if (isReport) {
      values = {
        ...values,
        is_report: true,
        department_ids: listDepartment.map((e) => e.value),
      };
    }

    if (dataInput != null) {
      updateQCEvaluateMain(values, dataInput.id);
    } else {
      createQCEvaluateMain(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 21,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 20,
      span: 28,
    },
  };

  return (
    <Modal
      title={
        dataInput?.id != null
          ? `${isReport ? "Sửa bài báo cáo" : "Sửa bài đánh giá"}`
          : `${isReport ? "Thêm bài báo cáo" : "Thêm bài đánh giá"}`
      }
      centered
      open={open}
      width={500}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Form.Item
            name="name"
            label={isReport ? "Tên bài báo cáo" : "Tên bài đánh giá"}
            className="mb-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input
              placeholder={
                isReport ? "Nhập tên bài báo cáo" : "Nhập tên bài đánh giá"
              }
            />
          </Form.Item>
          {isReport == true && (
            <Checkbox
              className="mb-3"
              checked={isReportCheckout}
              onChange={(e) => {
                setIsReportCheckout(e.target.checked);
              }}
            >
              Báo cáo Checkout
            </Checkbox>
          )}
          {isReportCheckout == true && (
            <div className="d-flex mb-3">
              <p className="col-3">Chi nhánh</p>
              <AsyncPaginate
                className="col-9"
                value={listDepartment}
                loadOptions={fetchAllDepartments}
                isMulti
                closeMenuOnSelect={false}
                onChange={setListDepartment}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            </div>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddQcEvaluateMainModal;
