import { useSelector, useDispatch } from "react-redux";
import UIDPopup from "./popUp/child/UIDPopup";
import { constants as c } from "../constants";
import "./popUp/style.css";
function Popup() {
  const dispatch = useDispatch();

  function handleClosePopup() {
    dispatch({ type: c.CHANGE_POPUP, popupType: c.NO_POPUP });
  }

  const currentPopup = useSelector((state) => state.app.currentPopup);
  var info = useSelector((state) => state.app.messageInfo);

  const popups = {
    [c.NO_POPUP]: <div></div>,
   // [c.UID_POPUP]: <UIDPopup info={info} handleClosePopup={handleClosePopup} />,
  };

  return popups[currentPopup];
}
export default Popup;
