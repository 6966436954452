export default function PinIcon({ className, ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0501 6.3498L9.6501 1.9498C9.4501 1.7498 9.1501 1.7498 8.9501 1.9498L7.3501 3.5498C7.1001 3.7998 7.2001 4.0998 7.3501 4.2498L7.7001 4.5998L6.2001 6.0998C5.4501 5.9498 3.4001 5.5998 2.3001 6.6998C2.1001 6.8998 2.1001 7.1998 2.3001 7.3998L5.1501 10.2498L2.0001 13.3998C1.8001 13.5998 1.8001 13.8998 2.0001 14.0998C2.2001 14.2998 2.5501 14.2498 2.7001 14.0998L5.8501 10.9498L8.7001 13.7998C9.0001 14.0498 9.3001 13.9498 9.4001 13.7998C10.5001 12.6998 10.1501 10.6498 10.0001 9.8998L11.5001 8.3998L11.8501 8.7498C12.0501 8.9498 12.3501 8.9498 12.5501 8.7498L14.1501 7.1498C14.2501 6.8498 14.2501 6.5498 14.0501 6.3498ZM12.1001 7.5998L11.7501 7.2498C11.5501 7.0498 11.2501 7.0498 11.0501 7.2498L9.0001 9.3498C8.8501 9.4998 8.8001 9.6498 8.8501 9.8498C9.0001 10.3998 9.2501 11.7498 8.8501 12.6498L3.3501 7.1498C4.2001 6.7498 5.6001 6.9998 6.1501 7.1498C6.3001 7.1998 6.5001 7.1498 6.6501 6.9998L8.7001 4.9498C9.0001 4.6498 8.8501 4.3998 8.7001 4.2498L8.3501 3.8998L9.3001 2.9998L13.0001 6.6998L12.1001 7.5998Z"
        fill="#72777B"
      />
    </svg>
  );
}
