import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getAllFeedback(queryString="") {
   const requestOptions = {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
   };
   return fetch(`${c.API_URL}/staff_feedback${queryString}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         // window.location.href = "/"
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

function getFeedbackInfo(id) {
   const requestOptions = {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
   };
   return fetch(`${c.API_URL}/staff_feedback/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

function getAllBadges() {
   const requestOptions = {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
   };
   return fetch(`${c.API_URL}/badges`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

function createFeedback(info) {
   const requestOptions = {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
      body: JSON.stringify(info),
   };
   
   return fetch(`${c.API_URL}/staff_feedback`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

function deleteFeedback(id) {
   const requestOptions = {
      method: "DELETE",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
   };
   return fetch(`${c.API_URL}/staff_feedback/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

function updateFeedback(id, info) {
   const requestOptions = {
      method: "PUT",
      headers: {
         "Content-Type": "application/json",
         token: tokenInfo ? tokenInfo : "",
      },
      body: JSON.stringify(info),
   };
   return fetch(`${c.API_URL}/staff_feedback/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
         console.log(json);
         return json;
      })
      .catch((err) => {
         console.log(err);
         return {};
      });
}

export const feedbackServices = {
   getAllFeedback,
   getFeedbackInfo,
   createFeedback,
   deleteFeedback,
   updateFeedback,
   getAllBadges
};
