export default function FileIcon({ className, ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <g clip-path="url(#clip0_500_100)">
        <path
          d="M22.7676 8.73571L10.5705 20.7974C8.54949 22.7962 5.27252 22.7962 3.25153 20.7974C1.23055 18.7987 1.23055 15.5586 3.25153 13.5599L14.2295 2.70444C15.5772 1.37222 17.7609 1.37222 19.1086 2.70444C20.4562 4.03662 20.4562 6.19729 19.1086 7.52947L8.13054 18.3849C7.45717 19.0515 6.36484 19.0515 5.69061 18.3849C5.01723 17.7193 5.01723 16.639 5.69061 15.9724L15.4487 6.32319L14.2287 5.11695L4.47152 14.767C3.12391 16.0992 3.12391 18.2599 4.47152 19.592C5.81913 20.9243 8.00292 20.9243 9.35053 19.592L20.3286 8.73657C22.3496 6.73787 22.3496 3.49776 20.3286 1.49902C18.3076 -0.499674 15.0306 -0.499674 13.0096 1.49902L1.42159 12.9576L1.46358 12.9996C-0.641389 15.6759 -0.456338 19.5449 2.03158 22.0045C4.51951 24.4642 8.43041 24.6484 11.1376 22.5657L11.1796 22.6077L23.9867 9.94281L22.7676 8.73571Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_100">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
