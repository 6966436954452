import React, { useState, useEffect } from "react";
import "./style.css";
import { Tab, Tabs, TabList } from "react-tabs";
import SearchInput from "../../../components/SeachInput";
import Request from "./child/Request";
import Device from "./child/Device";
import { shiftServices } from "../../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import { toast } from "react-toastify";

const RequestPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [listRequest, setListRequest] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const [devices, setDevices] = useState([]);
  const dispatch = useDispatch();

  // const onSubmitSearch = () => {};
  // const onChangeSearch = () => {};

  const handleRequest = async (requestId, status) => {
    try {
      const response = await shiftServices.handleRequest(areaId, requestId, {
        status,
      });
      if (response.code === 200) {
        fetchRequests();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      toast.error(error);
    
    }
  };

  const renderViewRequestTable = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Request
            listRequest={listRequest}
            handleRequest={(requestId, status) =>
              handleRequest(requestId, status)
            }
          />
        );
      case 1:
        return (
          <Device devices={devices} handleSucccess={() => fetchDevice()} />
        );
      default:
        break;
    }
  };
  const fetchRequests = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if (areaId == constants.AREA_ALL_ID) {
        const request = await shiftServices.fetchRequestManager();
        setListRequest(request.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const request = await shiftServices.fetchRequest(areaId);
        setListRequest(request.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const fetchDevice = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.fetchDeviceManager(areaId);
        setDevices(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.fetchDevice(areaId);
        setDevices(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [areaId]);

  

  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">Xử lý yêu cầu</h4>{" "}
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <Tabs
            defaultIndex={0}
            onSelect={(index) => {
              setTabIndex(index);
              if (index === 0) {
                fetchRequests();
              } else {
                fetchDevice();
              }
            }}
          >
            <TabList>
              <Tab>Danh sách yêu cầu chấm công từ xa</Tab>
              <Tab>Danh sách thiết bị yêu cầu</Tab>
            </TabList>
          </Tabs>
        </div>
        {/* <SearchInput
          placeHolder={"Tìm kiếm nhân viên"}
          onSubmitSearch={onSubmitSearch}
          onChange={onChangeSearch}
        ></SearchInput> */}
        <div className="card-body">{renderViewRequestTable()}</div>
      </div>
    </div>
  );
};

export default RequestPage;
