/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider, Modal, Spin } from "antd";
import { Select,  DatePicker as a  } from "antd";
import { reportServices } from "../../../services/reportServices";
import { evaluationServices } from "../../../services/evaluationServices";
import ChooseReportQcViewModal from "./ChooseReportQcViewModal";
import { toast } from "react-toastify";
import customParseFormat from "dayjs/plugin/customParseFormat";
import viVN from "antd/locale/vi_VN";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import moment from "moment";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = a;

const ReportQcViewModal = ({ open, closeModal, onOk }) => {
  useEffect(() => {}, [open]);

  const areas = useSelector((state) => state.area.areas);
  const areaId = useSelector((state) => state.area.areaId);
  const [areaSelected, setAreaSelected] = useState([]);
  const [qcEvaluateMains, setQCEvaluateMains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qcChoose, setQcChoose] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    data: null,
  });

  const { Option } = Select;

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const exportCourse = async (id) => {
    console.log(moment(dateRange[0] ?? Date()).format("YYYY-MM-DD"));
    console.log(moment(dateRange[1] ?? Date()).format("YYYY-MM-DD"));
    if (!dateRange) {
      return toast.error("Vui lòng chọn thời gian");
    }

    if (areaSelected.length == 0) {
      return toast.error("Vui lòng chọn chi nhánh");
    }
    if (!qcChoose) {
      return toast.error("Vui lòng chọn bài đánh giá");
    }
    try {
      var res = await reportServices.exportReportQc(
        qcChoose,
        areaSelected,
        moment(dateRange[0].toDate() ?? Date()).format("YYYY-MM-DD"),
        moment(dateRange[1].toDate() ?? Date()).format("YYYY-MM-DD")
      );
      setModal({
        title: "Xem báo cáo",
        method: "SHOW",
        visible: true,
        data: res.data.qc_evaluete_groups,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getQCEvaluateMain = async () => {
    try {
      setLoading(true);
      const resShifts = await evaluationServices.getQCEvaluateMain();
      setQCEvaluateMains(resShifts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (value, type) => {
    setDateRange(value);
  };

  useEffect(() => {
    getQCEvaluateMain();
    setAreaSelected([areaId]);
  }, []);

  return (
    <>
      <Modal
        title={"Báo cáo"}
        centered
        open={open}
        onOk={() => {
          exportCourse(qcChoose);
        }}
        onCancel={() => {
          closeModal();
        }}
      >
        <div>
          {loading ? (
            <Spin></Spin>
          ) : (
            <>
              <div
                className="d-flex justify-content-between align-items-center mb-3"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {}}
              >
                <span>Theo ngày</span>{" "}
                <ConfigProvider locale={viVN}>
                  <RangePicker
                    onChange={(value) => {
                      handleDateChange(value, "day");
                    }}
                    value={dateRange}
                  />
                </ConfigProvider>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span>Chọn chi nhánh:</span>
                <div style={{ width: "10px" }}></div>
                <Select
                  placeholder="Chọn chi nhánh"
                  mode="multiple"
                  value={areaSelected}
                  style={{ width: 350 }}
                  onChange={(v) => {
                    if (v.includes("999999999999999999")) {
                      console.log(v.filter((e) => e != "999999999999999999"));
                      setAreaSelected(
                        v.filter((e) => e == "999999999999999999")
                      );

                      return;
                    }
                    setAreaSelected(v);
                  }}
                >
                  {areas?.data?.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span>Chọn bài đánh giá, báo cáo:</span>
                <div style={{ width: "10px" }}></div>
                <Select
                  value={qcChoose}
                  placeholder="Chọn bài đánh giá, báo cáo"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setQcChoose(e);
                  }}
                >
                  {qcEvaluateMains?.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </div>
            </>
          )}
        </div>
      </Modal>
      <ChooseReportQcViewModal
        open={modal.visible && modal.method === "SHOW"}
        data={modal.data}
        onOk={() => {
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </>
  );
};

export default ReportQcViewModal;
