import React, { useState, useEffect } from "react";
import { shiftServices } from "../../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import ChooseAreaModal from "./ChooseAreaModal";
import { toast } from "react-toastify";

const ListTimekeepingArea = ({ profileInput }) => {
  const [listTimekeepingArea, setListTimekeepingArea] = useState([]);
  const [profile, setProfile] = useState(null);
  const [areaSelected, setAreaSelected] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(profileInput);
    setProfile(profileInput);
  }, [profileInput]);

  useEffect(() => {
    fetchListTimekeepingArea();
  }, [profile]);

  const fetchListTimekeepingArea = async () => {
    try {
      const response = await shiftServices.fetchListTimekeepingArea(
        profile.area_id,
        profile.id
      );
      setListTimekeepingArea(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addTimekeepingArea = async () => {
    if (areaSelected?.id === null) {
      toast.error("Chưa chọn chi nhánh");
      return;
    }

    try {
      const response = await shiftServices.addTimekeepingArea(
        areaId,
        profile.id,
        areaSelected.id
      );
      toast.success(response.msg);
    
      handleCloseModal();
      fetchListTimekeepingArea();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTimekeepingArea = async (areaTimekeepingId) => {
    try {
      const response = await shiftServices.deleteTimekeepingArea(
        areaId,
        profile.id,
        areaTimekeepingId
      );
      toast.success(response.msg);
   
      fetchListTimekeepingArea();
    } catch (error) {
      console.log(error);
    }
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    process: null,
    indexInput: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Danh sách chi nhánh cho phép chấm công
        </h4>
        <button
          className="btn btn-info btn-icon-split btn-sm show"
          onClick={(e) => {
            if (
              (reportNumber.permissions?.area_timekeeping_manager ?? false) ==
              true
            ) {
              setModal({
                
                visible: true,
              });
            } else {
              toast.error("Bạn không có quyền truy cập");
            }
          }}
        >
          <span className="icon text-white-50">
            <i className="fas fa-plus" />
          </span>
          <span className="text">Thêm chi nhánh</span>
        </button>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>STT</th>
                    <th>Chi nhánh</th>
                    <th>Địa chỉ</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {listTimekeepingArea?.map((area, index) => {
                    return (
                      <tr key={index} className="table-row">
                        <td>{index + 1}</td>
                        <td>{area?.name ?? ""}</td>
                        <td>{area?.address ?? ""}</td>
                        <td className="group-action-btn">
                          <button
                            style={{
                              border: "1px solid",
                            }}
                            class="btn btn-danger btn-sm"
                            type="button"
                            onClick={() => {
                              if (
                                (reportNumber.permissions
                                  ?.area_timekeeping_manager ?? false) == true
                              ) {
                                deleteTimekeepingArea(area?.id);
                              } else {
                                toast.error("Bạn không có quyền truy cập");
                              }
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            Xóa
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ChooseAreaModal
        visible={modal.visible}
        listAreaCurrent={listTimekeepingArea}
        closeModal={handleCloseModal}
        handleChoice={(value) => {
          setAreaSelected(value);
        }}
        onSave={(e) => {
          addTimekeepingArea();
        }}
      />
    </div>
  );
};

export default ListTimekeepingArea;
