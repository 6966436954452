import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ModalUID from "../../../../components/ModalUID";
import {constants} from "../../../../constants";
import {shiftServices} from "../../../../services/shiftServices";
import ItemDevice from "./ItemDevice";
import { toast } from "react-toastify";

const Device = ({devices}) => {
    const [deviceId, setDeviceId] = useState({
      id: null,
      status: null,
    });
    const [modal, setModal] = useState({
      title: "Thông báo",
      method: "DELETE",
      content: "",
      size: "sm",
      message: "",
    });
    const areaId = useSelector((state) => state.area.areaId);
    const dispatch = useDispatch();

    const handleRemoveDevice = async () => {
      try {
        const response = await shiftServices.removeDevice(areaId, deviceId.id, {
          status: modal.method === "ACCEPT" ? 2 : 0,
        });
        if (response.code === 200) {
          toast.success(response.msg);
        } else {
          dispatch({
            type: constants.FAILURE,
            code: response.code,
            message: response.message,
          });
        }
      } catch (error) {
        toast.error(error);
      }
    };

    return (
      <>
        <div>
          <div className="table-responsive">
            <div id="dataTable_wrapper" className="dataTables_wrapper no-footer">
              <table
                className="table no-footer dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
                style={{width: "100%"}}
              >
                <thead>
                <tr>
                  <th>Id thiết bị</th>
                  <th>Tên nhân viên</th>
                  <th>Tên điện thoại</th>
                  <th>Trạng thái</th>
                  <th>Số thiết bị</th>
                  <th>Hành động</th>
                </tr>
                </thead>
                <tbody>
                {devices?.map((device, index) => {
                  return (
                    <ItemDevice
                      key={device?.id}
                      device={device}
                      handleAccept={(message, method, deviceId) => {
                        setModal({...modal, message, method});
                        setDeviceId({id: deviceId, status: 2});
                      }}
                      handleCancel={(message, method, deviceId) => {
                        setModal({...modal, message, method});
                        setDeviceId({id: deviceId, status: 0});
                      }}
                    />
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ModalUID
          size={modal.size}
          method={modal.method}
          title={modal.title}
          handleOnSave={() => {
            handleRemoveDevice();
            window.$(".modal").modal("hide");
          }}
          showFooter={modal.showFooter}
        >
          <div>{modal.message}</div>
        </ModalUID>
      </>
    );
  }
;

export default Device;
