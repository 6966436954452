import React, { useState } from "react";
import Pagination from "../../../../components/Pagination";
import Item from "./Item";
import ModalUID from "../../../../components/ModalUID";
import { shiftServices } from "../../../../services/shiftServices";

const Request = ({ listRequest, handleRequest }) => {
  const [request, setRequest] = useState({
    id: null,
    status: null,
  });
  const [modal, setModal] = useState({
    title: "Thông báo",
    method: "CREATE",
    content: "",
    size: "sm",
    message: "",
  });
  const ModalContent = () => <div>{modal.message}</div>;
  return (
    <>
      <div>
        <div className="table-responsive">
          <div id="dataTable_wrapper" className="dataTables_wrapper no-footer">
            <table
              className="table no-footer dataTable"
              id="dataTable"
              width="100%"
              cellSpacing={0}
              aria-describedby="dataTable_info"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên nhân viên</th>
                  <th>Vào/Tan ca</th>
                  <th>Thời gian check</th>
                  <th>Lý do</th>
                  <th>Trạng thái</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {listRequest[0]?.histories?.map((request, index) => {
                  return (
                    <Item
                      request={request}
                      stt={index + 1}
                      handleAccept={(message, method, requestId) => {
                        setModal({ ...modal, message, method });
                        setRequest({ id: requestId, status: 2 });
                      }}
                      handleCancel={(message, method, requestId) => {
                        setModal({ ...modal, message, method });
                        setRequest({ id: requestId, status: 0 });
                      }}
                      name={listRequest[0]?.name}
                    />
                  );
                })}
              </tbody>
            </table>
            {/* <Pagination
              to="/"
              handleClickItem={handleClickItem}
              links={links}
              lastPage={1}
              currentPage={1}
            ></Pagination> */}
          </div>
        </div>
      </div>

      <ModalUID
        size={modal.size}
        method={modal.method}
        title={modal.title}
        handleOnSave={() => {
          handleRequest(request.id, request.status);
          window.$(".modal").modal("hide");
        }}
        showFooter={modal.showFooter}
      >
        <ModalContent />
      </ModalUID>
    </>
  );
};

export default Request;
