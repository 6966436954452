import React from "react";
import { useEffect } from "react";
import { Modal } from "antd";
import { Button, Form, Input } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { shiftServices } from "../../../../services/shiftServices";

const AddShiftGroupModal = ({ open, closeModal, onOk, dataInput }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (dataInput != null) {
      onFill();
    } else {
      onReset();
    }
  
  }, [open]);

  const addShiftGroup = async (data) => {
    setLoading(true);
    try {
      const response = await shiftServices.addShiftGroup(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateShiftGroup = async (data, id) => {
    setLoading(true);
    try {
      const response = await shiftServices.updateShiftGroup(id, data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    console.log(values);

    if (dataInput != null) {
      updateShiftGroup(values, dataInput.id);
    } else {
      addShiftGroup(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  return (
    <Modal
      title={
        dataInput?.id != null ? "Sửa nhóm ca làm việc" : "Thêm nhóm ca làm việc"
      }
      centered
      open={open}
      width={500}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Form.Item
            name="name"
            label="Tên nhóm ca làm việc"
            className="mb-3"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tên nhóm ca làm việc"} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddShiftGroupModal;
