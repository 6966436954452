/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { shiftServices } from "../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { formatMoneyVND } from "../../helpers";
import DeleteModal from "./child/DeleteModal";
import BonusConfigModal from "./child/BonusConfigModal";
import { constants } from "../../constants";
import { toast } from "react-toastify";

const bonusConfigDefault = {
    title: "",
    description: "",
    value: null,
  };
const BonusConfig = () => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  const [bonusConfig, setBonusConfig] = useState();
  const [bonusConfigDetail, setBonusConfigDetail] = useState();
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
  });
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    fetchBonusConfig();
  }, [areaId, isReload]);

  const fetchBonusConfig = async () => {
    try {
      const response = await shiftServices.fetchBonusConfig({ areaId });
      setBonusConfig(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSave = async (data) => {
    try {
      let response = null;

      if (modal.method === "CREATE") {
        response = await shiftServices.createBonusConfig({
          areaId: areaId,
          body: data,
        });
      } else if (modal.method === "UPDATE") {
        response = await shiftServices.updateBonusConfig({
          id: data.id,
          areaId: areaId,
          body: data,
        });
      } else if (modal.method === "DELETE") {
        response = await shiftServices.deleteBonusConfig({
          id: bonusConfigDetail.id,
          areaId: areaId,
        });
      }
      if (response.success) {
        setBonusConfigDetail();
        setIsReload(!isReload);
        handleCloseModal();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      let msg = "";

      if (modal.method === "CREATE") {
        msg = "Lỗi khi tạo cấu hình thưởng";
      } else if (modal.method === "UPDATE") {
        msg = "Lỗi khi cập nhật cấu hình thưởng";
      } else if (modal.method === "DELETE") {
        msg = "Lỗi khi xóa cấu hình thưởng";
      }
      toast.error(msg);
     
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">Cấu hình thưởng</h4>
        <button
          class={`btn btn-info btn-icon-split btn-sm`}
          onClick={() => {
            setBonusConfigDetail(bonusConfigDefault);
            setModal({
              title: `Thêm cấu hình thưởng`,
              method: "CREATE",
              visible: true,
            });
          }}
        >
          <span class="icon text-white-50">
            <i class="fas fa-plus"></i>
          </span>
          <span class="text">Thêm cấu hình thưởng</span>
        </button>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Mô tả</th>
                    <th className="text-center">Số tiền thưởng (VNĐ)</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {bonusConfig?.map((item, index) => (
                    <tr key={index} className="table-row">
                      <td>{index + 1}</td>
                      <td>{item?.title || ""}</td>
                      <td>{item?.description || ""}</td>
                      <td className="text-center">
                        {item?.value ? formatMoneyVND(item.value) : ""}
                      </td>
                      <td className="group-action-btn">
                        <button
                          class={`btn-info btn-sm btn-edit`}
                          onClick={() => {
                            setBonusConfigDetail(item);
                            setModal({
                              title: `Chỉnh sửa`,
                              method: "UPDATE",
                              visible: true,
                            });
                          }}
                          data-toggle="modal"
                          data-target="#modalShift"
                        >
                          <span className="icon text-white mr-1">
                            <i class="fas fa-edit"></i>
                          </span>
                          <span className="">Sửa</span>
                        </button>
                        <button
                          class={`btn-sm btn-trash`}
                          onClick={() => {
                            setBonusConfigDetail(item);
                            setModal({
                              title: `Thông báo`,
                              method: "DELETE",
                              visible: true,
                            });
                          }}
                        >
                          <span className="icon text-white mr-1">
                            <i className="fas fa-trash"></i>
                          </span>
                          <span className="btn-trash-text">Xoá</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={"Thông báo"}
        onSubmit={handleOnSave}
        closeModal={handleCloseModal}
      />
      <BonusConfigModal
        visible={
          modal.visible &&
          (modal.method === "CREATE" || modal.method === "UPDATE")
        }
        title={modal.title}
        bonusConfigDetail={bonusConfigDetail}
        onSubmit={handleOnSave}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

export default BonusConfig;
