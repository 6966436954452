import styled from "styled-components";

export const StyledButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f6c23e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
`;

export const StyledBoxChat = styled.div`
  #box-chat-container {
    width: 1000px;
    height: 580px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    right: 2px;
    transition: all 0.3s ease-in-out;
    // border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
    display: flex;
    &.fullscreen {
      width: 99.5%;
      height: 97.5%;
    }
    &.hide-main-content {
      width: 300px
    }
    .ant-modal-mask {
      position: absolute !important;
    }
    .ant-modal-wrap {
      position: absolute !important;
    }
    .ant-modal-content {
      padding: 0px;
    }
    .ant-modal-close {
      top: 10px;
      inset-inline-end: 10px;
    }
    .ant-modal-header {
      margin-bottom: 4px;
      padding: 8px 16px;
      border-bottom: 1px solid #ddd;
    }
    .btn-close {
      position: absolute;
      top: 4px;
      left: 5px;
      cursor: pointer;
      font-size: 18px;
      width: 13px;
      height: 13px;
      &:hover {
        color: #e74a3b;
        cursor: pointer
      }
    }
    .btn-zoom {
      position: absolute;
      top: 4px;
      left: 23px;
      width: 13px;
      height: 13px;
      font-size: 17px;
      cursor: pointer;
      &:hover {
        color: #07bc0c;
        cursor: pointer
      }
    }
    .btn-hide {
      position: absolute;
      top: 4px;
      left: 42px;
      width: 13px;
      height: 13px;
      font-size: 17px;
      cursor: pointer;
      &:hover {
        color: #07bc0c;
        cursor: pointer
      }
    }
  }
`;
