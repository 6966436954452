/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { formatMoneyVND } from "../../../../helpers";
import { shiftServices } from "../../../../services/shiftServices";
Modal.setAppElement("#root");

const BonusModal = ({ visible, onSubmit, bonusConfigIds, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);

  const [selectAll, setSelectAll] = useState(false);
  const [bonusConfig, setBonusConfig] = useState();
  const [bonusConfigSelect, setBonusConfigSelect] = useState([]);

  useEffect(() => {
    fetchBonusConfig();
  }, [areaId]);

  useEffect(() => {
    handleCheckAll();
  }, [bonusConfigSelect]);

  useEffect(() => {
    setBonusConfigSelect([...bonusConfigIds]);
  }, []);

  const fetchBonusConfig = async () => {
    try {
      const response = await shiftServices.fetchBonusConfig({ areaId });
      setBonusConfig(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckAll = () => {
    if (bonusConfig?.data?.length > 0) {
      let total = 0;
      bonusConfig.data.forEach((item) => {
        let equal = bonusConfigSelect.find((value) => value === item.id);
        if (equal) {
          total++;
        }
      });
      if (total === bonusConfig.data.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectStaff = (e, id) => {
    const selectSet = new Set(bonusConfigSelect);

    if (e.target.checked) {
      selectSet.add(id);
    } else {
      selectSet.delete(id);
    }

    setBonusConfigSelect([...selectSet]);
  };

  const handleSelectAllStaff = (e) => {
    if (e.target.checked) {
      const ids = bonusConfig?.data?.map((item) => item.id);
      setBonusConfigSelect(ids);
    } else {
      setBonusConfigSelect([]);
    }
  };

  const checkSelectStaff = (id) => {
    return bonusConfigSelect.some((item) => item === id);
  };

  const handleSubmit = () => {
    onSubmit(bonusConfigSelect, bonusConfig?.data);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="staff-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>Chọn cấu hình thưởng</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div>
        <div className="modal-body">
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label className="font-weight-bold">Chọn cấu hình thưởng</label>
              <p className="font-weight-bold">
                Đã chọn: <span>{bonusConfigSelect?.length}</span>
              </p>
            </div>
            <div>
              <div className="table-responsive">
                <div
                  id="dataTable_wrapper"
                  className="dataTables_wrapper no-footer"
                  style={{ maxHeight: "300px" }}
                >
                  <table
                    className="table no-footer dataTable"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                    aria-describedby="dataTable_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={(e) => handleSelectAllStaff(e)}
                          />
                        </th>
                        <th>Tiêu đề</th>
                        <th>Chi tiết</th>
                        <th>Số tiền (VNĐ)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusConfig?.data?.map((item, index) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              name=""
                              checked={checkSelectStaff(item.id)}
                              onChange={(e) => handleSelectStaff(e, item.id)}
                            />
                          </td>
                          <td>{item?.title || ""}</td>
                          <td>{item?.description || ""}</td>
                          <td>
                            {item?.value ? formatMoneyVND(item.value) : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button type="submit" className="btn btn-info" onClick={handleSubmit}>
            Lưu
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BonusModal;
