/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { constants as c } from "../constants";
import IncidentModal from "../pages/Incident/child/IncidentModal";
import FormModal from "../pages/Procedure/FormPage/child/FormModal";
import ReportFormModal from "../pages/Procedure/ReportFormPage/child/ReportFormModal";
import OperateFormModal from "../pages/Procedure/OperateFormPage/child/OperateFormModal";

Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const ModalNoti = () => {
  const dispatch = useDispatch();
  function handleClosePopup() {
    dispatch({ type: c.MODAL_NOTI_HIDE });
  }

  const stateNavi = useSelector((state) => state.notiNavi);
  const departmentList = useSelector((state) => state.staff.department_list);

  const modals = {
    [c.HAS_SALARY]: <div></div>,
    [c.NEW_SHIFTS]: <div></div>,
    [c.INCIDENT_1]: (
      <IncidentModal
        visible={stateNavi.show}
        incident={{
          id: stateNavi.reference_value,
        }}
        departmentsInput={
          Array.isArray(departmentList?.data)
            ? departmentList?.data.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            : []
        }
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleClosePopup}
      ></IncidentModal>
    ),
    [c.INCIDENT_2]: (
      <IncidentModal
        visible={stateNavi.show}
        incident={{
          id: stateNavi.reference_value,
        }}
        departmentsInput={
          Array.isArray(departmentList?.data)
            ? (departmentList?.data ?? []).map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            : []
        }
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleClosePopup}
      ></IncidentModal>
    ),
    [c.TYPE_PERSONNEL_FORM]: (
      <FormModal
        visible={stateNavi.show}
        formId={stateNavi.reference_value}
        departmentsInput={
          Array.isArray(departmentList?.data)
            ? departmentList?.data.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            : []
        }
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleClosePopup}
      ></FormModal>
    ),
    [c.TYPE_OPERATE_FORM]: (
      <OperateFormModal
        visible={stateNavi.show}
        formId={stateNavi.reference_value}
        departmentsInput={
          Array.isArray(departmentList?.data)
            ? departmentList?.data.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            : []
        }
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleClosePopup}
      ></OperateFormModal>
    ),
    [c.TYPE_REPORT_FORM]: (
      <ReportFormModal
        visible={stateNavi.show}
        formId={stateNavi.reference_value}
        departmentsInput={
          Array.isArray(departmentList?.data)
            ? departmentList?.data.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            : []
        }
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleClosePopup}
      ></ReportFormModal>
    ),
    [c.HAS_SHIFT]: <div></div>,
    [c.NONE_NOTI]: <div></div>,
  };

  return (
    <div className="modal center" style={{ background: "none" }}>
      <div style={{ zIndex: 100, position: "fixed" }}>
        {modals[stateNavi?.type_noti ?? c.NONE_NOTI]}
      </div>
    </div>
  );
};

export default ModalNoti;
