import React from "react";
import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { Col, Upload, Row, Space, Avatar, Divider, Spin, Button } from "antd";

import "suneditor/dist/css/suneditor.min.css";
import { franchiseServices } from "../../../../services/franchiseServices";
import SunEditor from "suneditor-react";
import moment from "moment";
import { toast } from "react-toastify";
import { UploadOutlined } from "@ant-design/icons";

const MailModal = ({ open, closeModal, onOk, dataInput }) => {
  const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
  const [contentText, setContentText] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [mailData, setMailData] = useState(null);
  const [mailMessData, setMailMessData] = useState([]);
  const [expandedMain, setExpanedMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (dataInput != null) {
      if (open == true) {
        setMailData(null);
        setMailMessData(null);
        getMailDetail(dataInput.id);
        getMailMess(dataInput.id);
      }
    } else {
    }
  }, [open]);

  const getMailDetail = async (id) => {
    try {
      const response = await franchiseServices.getMailDetail(id);
      setMailData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMailMess = async (id) => {
    setLoadingInit(true);
    try {
      const response = await franchiseServices.getMailMess(id);
      setMailMessData(response.data);
      if (response.data?.length == 0) {
        setExpanedMain(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingInit(false);
  };

  const addMailMess = async () => {
    setLoading(true);
    try {
      const response = await franchiseServices.addMailMess({
        mail_franchise_id: dataInput.id,
        content: contentText,
        file_url: fileUrl,
      });
      getMailMess(dataInput.id);
      setContentText("");
      setFileUrl(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ItemMess = ({ mess, expandedInput, updateContent }) => {
    const [expanded, setExpaned] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (expanded != null) {
        setExpaned(expandedInput);
      }
      if (mess.content == null) {
        getMailMessDetail(mess.id);
      }
    }, []);

    const getMailMessDetail = async (id) => {
      setLoading(true);
      try {
        const response = await franchiseServices.getMailMessDetail(id);
        updateContent(response.data.content);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    return (
      <div>
        <Divider />
        <div className="d-flex mr-2">
          <Col>
            <Avatar
              src={mess?.staff?.avatar ?? "--:--"}
              size={45}
              className="mr-2"
            />
          </Col>
          <Col flex="auto">
            <Row
              onClick={(e) => {
                setExpaned(!expanded);
              }}
            >
              <Space direction="vertical" align="start">
                <p>{mess?.staff?.name ?? "--:--"}</p>
                <span style={{ color: "grey", fontSize: "13px" }}>
                  {mess?.staff.id == mailData?.staff_id
                    ? `Đến: ${
                        mailData?.staffs_to?.map((e) => e.name).join(", ") ??
                        "--:--"
                      }`
                    : `Đến: ${mailData?.staff?.name ?? "--:--"}`}
                </span>
              </Space>
              <Col flex={"auto"}></Col>
              <p style={{ color: "grey", fontSize: "13px", marginTop: "0px" }}>
                {moment(mess?.created_at).format("DD-MM HH:mm") ?? "--:--"}
              </p>
            </Row>
            {expanded ? (
              loading ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    background: "#f5f5f5",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spin></Spin>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      background: "#f5f5f5",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: mess.content ?? "",
                    }}
                  ></div>
                  {mess?.file_url != null ? (
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "10px",
                        justifyContent: "start",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                      onClick={(x) => {
                        downloadFile(mess?.file_url);
                      }}
                    >
                      <img
                        src={"/images/folder.png"}
                        style={{ width: "25px", height: "25px" }}
                      ></img>
                      <div style={{ width: "10px" }}></div>
                      <span style={{ fontSize: "11px" }}>
                        {`${mess?.file_url}`.replace(
                          "https://crm-api.studyguide.dev/i/",
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            ) : (
              <></>
            )}
          </Col>
        </div>
      </div>
    );
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("File phải nhỏ hơn 20MB!");
      return false;
    }
    return isLt5M;
  };




  const props = {
    name: "file",
    maxCount: 1,
    beforeUpload,
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("image", file);
      fetch("https://crm-api.studyguide.dev/api/upload_v2/files?type=franchise", {
        method: "POST",
        headers: {
          token: tokenInfo,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setFileUrl(data.data?.image_url);
          onSuccess(data, file);
        })
        .catch((error) => {
          onError(error, file);
        });
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      title={"Thông tin trao đổi"}
      centered
      open={open}
      width={1500}
      style={{ top: 10 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      {loadingInit ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spin></Spin>
        </div>
      ) : (
        <div
          className="mt-4"
          style={{
            overflowY: "auto",
            height: "auto",
            maxHeight: "79vh",
            margin: "10px",
          }}
        >
          <div className="d-flex mr-2">
            <Col>
              <Avatar
                src={mailData?.staff?.avatar ?? "--:--"}
                size={45}
                className="mr-2"
              />
            </Col>
            <Col flex="auto">
              <Row
                onClick={(e) => {
                  setExpanedMain(!expandedMain);
                }}
              >
                <Space direction="vertical" align="start">
                  <p>{mailData?.staff?.name ?? "--:--"}</p>
                  <span style={{ color: "grey", fontSize: "13px" }}>
                    {`Đến: ${
                      mailData?.staffs_to?.map((e) => e.name).join(", ") ??
                      "--:--"
                    }`}
                  </span>
                </Space>
                <Col flex={"auto"}></Col>
                <p
                  style={{ color: "grey", fontSize: "13px", marginTop: "0px" }}
                >
                  {moment(mailData?.created_at).format("DD-MM HH:mm") ??
                    "--:--"}
                </p>
              </Row>

              {expandedMain ? (
                <div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      background: "#f5f5f5",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: mailData?.content ?? "",
                    }}
                  ></div>
                  {mailData?.file_urls != null ? (
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "10px",
                        justifyContent: "start",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                      onClick={(x) => {
                        downloadFile(mailData?.file_urls);
                      }}
                    >
                      <img
                        src={"/images/folder.png"}
                        style={{ width: "25px", height: "25px" }}
                      ></img>
                      <div style={{ width: "10px" }}></div>
                      <span style={{ fontSize: "11px" }}>
                        {`${mailData?.file_urls}`.replace(
                          "https://crm-api.studyguide.dev/images/",
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Col>
          </div>
          {(mailMessData ?? []).map((e, index) => {
            const expandedInput = index === mailMessData.length - 1; // Kiểm tra xem có phải là dòng cuối cùng hay không
            return (
              <ItemMess
                key={e.id}
                mess={e}
                expandedInput={expandedInput}
                updateContent={(v) => {
                  mailMessData[index].content = v;
                }}
              />
            );
          })}
          <Divider />
          {isReply ? (
            <div className="d-flex mt-2 mr-2">
              <Col>
                <Avatar
                  src={mailData?.staff?.avatar ?? "--:--"}
                  size={45}
                  className="mr-2"
                />
              </Col>
              <Col flex="auto">
                {loading ? (
                  <></>
                ) : (
                  <SunEditor
                    name="content"
                    showToolbar={true}
                    setDefaultStyle="height: auto"
                    defaultValue={contentText}
                    setOptions={{
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "fontColor",
                          "textStyle",
                          "outdent",
                          "align",
                          "horizontalRule",
                          "list",
                          "lineHeight",
                          "table",
                          "link",
                          "image",
                          "video",
                          "audio",
                          "fullScreen",
                          "preview",
                        ],
                      ],
                    }}
                    onChange={(e) => {
                      console.log(e);
                      setContentText(e);
                    }}
                  />
                )}
                <div className="mt-2"></div>
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                </Upload>
                <Space>
                  <Button
                    type="primary"
                    className="mt-3"
                    onClick={(e) => {
                      addMailMess();
                    }}
                  >
                    Gửi
                  </Button>

                  <Button
                    type="dashed"
                    className="mt-3"
                    onClick={(e) => {
                      setIsReply(false);
                    }}
                  >
                    Huỷ
                  </Button>
                </Space>
              </Col>
            </div>
          ) : (
            <Button
              type="primary"
              onClick={(e) => {
                setIsReply(true);
              }}
            >
              Trả lời
            </Button>
          )}
          <div style={{ height: "100px" }}></div>
        </div>
      )}
    </Modal>
  );
};

export default MailModal;
