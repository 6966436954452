import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectPicker, DatePicker } from "rsuite";
import { shiftServices } from "../../../services/shiftServices";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { constants } from "../../../constants";
import { useHistory } from "react-router-dom";
import { procedureServices } from "../../../services/procedureServices";
import { toast } from "react-toastify";

const Contract = ({ newStaff }) => {
  const [types, setTypes] = useState([]);
  const [salary, setSalary] = useState("");
  const [total_salary, setTotalSalary] = useState("");
  const [support_productive, setSupportProductive] = useState("");
  const [support_result, setSupportResult] = useState("");
  const [pc_food, setPcFood] = useState("");
  const [pc_phone, setPcPhone] = useState("");
  const [pc_move, setPcMove] = useState("");
  const [salaryPosion, setSalaryPosion] = useState("");
  const [dependentAnother, setDependentAnother] = useState("");
  const [insurances, setInsurances] = useState([]);
  const [tax, setTax] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const [keyInsurance, setKeyInsurance] = useState(new Set([]));
  const [supportConfig, setSupportConfig] = useState([]);
  const [keySupport, setKeySupport] = useState([]);
  const [optionLabel, setOptionLabel] = useState({
    typeLabel: "",
    salaryLabel: "",
  });
  const [contactDetail, setContactDetail] = useState(null);
  const [contactFotmat, setContactFotmat] = useState({
    salary: "",
    total_salary: "",
    position_salary: "",
    dependent_another: "",
  });

  const [isCheckedBHXH, setIsCheckedBHXH] = useState(false);
  const [isChooseGT, setIsCheckedGT] = useState(false);
  const [isChooseCK, setIsCheckedCK] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm();

  const fetchOptionContact = async () => {
    try {
      const response = await shiftServices.fetchOptionContact(areaId);
      const listType = response.data.type.map((type) => ({
        label: type.label,
        value: type.key,
      }));
      setTypes(listType);
      const listInsurance = response.data.insurance.map((insurance) => ({
        label: insurance.label,
        value: insurance.key,
      }));
      setInsurances(listInsurance);
      const listKey = listInsurance.map((key) => key.value);
      setKeyInsurance(new Set(listKey));
      const listTax = response.data.tax.map((tax) => ({
        label: tax.label,
        value: tax.key,
      }));
      setTax(listTax);
    } catch (error) {}
  };

  const fetchSupportConfig = async () => {
    try {
      const response = await shiftServices.fetchSupportConfig(areaId);
      setSupportConfig(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getSettingLeave();
    fetchOptionContact();
    fetchSupportConfig();
  }, [areaId]);

  const onSubmit = async (values) => {
    console.log(values);
    try {
      const body = {
        staff_id: newStaff.id,
        type_key: getValues("type_key"),
        type_label: optionLabel.typeLabel,
        salary_key: getValues("type_key") === "part_time" ? "hour" : "day",
        salary_label: getValues("type_key") === "part_time" ? "Giờ" : "Ngày",
        start: moment(values.start).format(constants.FORMAT_YYYY_MM_DD),
        end: moment(values.end).format(constants.FORMAT_YYYY_MM_DD),
        images: [],
        BHXH: isCheckedBHXH,
        BHYT: isCheckedBHXH,
        BHTN: isCheckedBHXH,
        tax: isChooseGT === true ? "LT" : isChooseCK === true ? "CK" : "KT",
        dependent_count: values.dependent_count,
        kpi_percent: values.kpi_percent,
        annual_leave: values.annual_leave == "" ? 0 : values.annual_leave,
        unpaid_leave: values.unpaid_leave == "" ? 0 : values.unpaid_leave,
        compassionate_leave:
          values.compassionate_leave == "" ? 0 : values.compassionate_leave,
        marriage_leave: values.marriage_leave == "" ? 0 : values.marriage_leave,
        position_salary:
          isCheckedBHXH === true
            ? salaryPosion !== null && salaryPosion !== ""
              ? salaryPosion.toString().replaceAll(".", "")
              : null
            : null,
        salary:
          getValues("type_key") === "part_time"
            ? salary !== null && salary !== ""
              ? parseFloat(salary.toString().replaceAll(".", ""))
              : null
            : null,
        dependent_another:
          values.tax === "LT"
            ? dependentAnother !== null && dependentAnother !== ""
              ? parseFloat(dependentAnother.toString().replaceAll(".", "") || 0)
              : null
            : 0,
        total_salary:
          getValues("type_key") === "part_time"
            ? null
            : total_salary !== null && total_salary !== ""
            ? total_salary.toString().replaceAll(".", "")
            : null,
        support_productive:
          getValues("type_key") === "part_time"
            ? null
            : support_productive !== null && support_productive !== ""
            ? support_productive.toString().replaceAll(".", "")
            : null,
        support_result:
          getValues("type_key") === "part_time"
            ? null
            : support_result !== null && support_result !== ""
            ? support_result.toString().replaceAll(".", "")
            : null,

        pc_food:
          getValues("type_key") === "part_time"
            ? null
            : pc_food !== null && pc_food !== ""
            ? pc_food.toString().replaceAll(".", "")
            : null,

        pc_phone:
          getValues("type_key") === "part_time"
            ? null
            : pc_phone !== null && pc_phone !== ""
            ? pc_phone.toString().replaceAll(".", "")
            : null,
        pc_move:
          getValues("type_key") === "part_time"
            ? null
            : pc_move !== null && pc_move !== ""
            ? pc_move.toString().replaceAll(".", "")
            : null,
        supports: keySupport,
      };
      const response =
        contactDetail === null
          ? await shiftServices.createContact(body, areaId)
          : await shiftServices.updateContact(body, areaId, contactDetail.id);
      if (response.success) {
        setContactDetail(response.data);
        toast.success(response.msg);
        history.goBack();
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFotmat = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name);
    if (name == "total_salary") {
      setTotalSalary(formatNumberV2(value));
    }

    if (name == "pc_food") {
      setPcFood(formatNumberV2(value));
    }

    if (name == "pc_phone") {
      setPcPhone(formatNumberV2(value));
    }

    if (name == "pc_move") {
      setPcMove(formatNumberV2(value));
    }

    if (name == "support_result") {
      setSupportResult(formatNumberV2(value));
    }

    if (name == "support_productive") {
      setSupportProductive(formatNumberV2(value));
    }

    if (name == "salary") {
      setSalary(formatNumberV2(value));
    }

    if (name == "position_salary") {
      setSalaryPosion(formatNumberV2(value));
    }
    if (name == "dependent_another") {
      setDependentAnother(formatNumberV2(value));
    }
  };

  const formatNumberV2 = (str) => {
    if (str === undefined || str === null) return "";
    const strFormat = str
      .toString()
      .replace(/[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "");
    if (Number(strFormat) >= 1000) {
      return strFormat
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ".") + prev;
        });
    } else if (Number(strFormat) > 0 && Number(strFormat) < 1000) {
      return Number(strFormat);
    } else {
      return "";
    }
  };

  const getSettingLeave = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.getSettingLeave();
      reset({
        ...getValues(),
        unpaid_leave: response.data[0]?.value,
        compassionate_leave: response.data[1]?.value,
        marriage_leave: response.data[2]?.value,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">Tạo hợp đồng</h4>
      <div className="card shadow mb-4 p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <label>Loại hợp đồng*</label>
                <Controller
                  name="type_key"
                  control={control}
                  rules={{ required: "Không được rỗng" }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectPicker
                        data={types}
                        placeholder="-- Chọn loại hợp đồng --"
                        block
                        cleanable={false}
                        value={value}
                        onSelect={(val, item) => {
                          onChange(val);
                          // if (val === "full_time") {
                          //   setValue("count_day_work", "");
                          // }
                          setOptionLabel({
                            ...optionLabel,
                            typeLabel: item.label,
                          });
                        }}
                      />
                    );
                  }}
                />
                <div className="error-message">{errors?.type_key?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày bắt đầu*</label>
                <div>
                  <Controller
                    name="start"
                    control={control}
                    rules={{ required: "Không được rỗng" }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          cleanable={false}
                          block
                          value={value}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                </div>
                <div className="error-message">{errors?.start?.message}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày kết thúc*</label>
                <div>
                  <Controller
                    name="end"
                    control={control}
                    rules={{ required: "Không được rỗng" }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <DatePicker
                          cleanable={false}
                          block
                          value={value}
                          onChange={(date) => onChange(date)}
                          format="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                  <div className="error-message">{errors?.end?.message}</div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>% Lương tính vào KPI*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("kpi_percent", {
                    required: "Không được rỗng",
                  })}
                />
                <div className="error-message">
                  {errors?.kpi_percent?.message}
                </div>
              </div>
            </div>
            {getValues("type_key") === "part_time" ? (
              <div
                className="col-3"
                style={{
                  display:
                    getValues("type_key") === "part_time" ? "block" : "none",
                }}
              >
                <div className="form-group">
                  <label>Mức lương theo giờ*</label>
                  <input
                    type="text"
                    name="salary"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    {...register("salary", {
                      required:
                        getValues("type_key") === "part_time"
                          ? "Không được rỗng"
                          : false,
                    })}
                    value={salary}
                    onChange={handleChangeFotmat}
                    disabled={getValues("type_key") !== "part_time"}
                  />
                  <div className="error-message">{errors?.salary?.message}</div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {getValues("type_key") === "part_time" ? (
              <></>
            ) : (
              <>
                {/* <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Tổng mức lương*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      {...register("total_salary", {
                        required: "Không được rỗng",
                      })}
                      value={total_salary}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.total_salary?.message}
                    </div>
                  </div>
                </div> */}
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Mức lương P2*</label>
                    <input
                      type="text"
                      name="support_productive"
                      className="form-control"
                      placeholder=""
                      value={support_productive}
                      autoComplete="off"
                      {...register("support_productive", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.support_productive?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Mức lương P3*</label>
                    <input
                      type="text"
                      name="support_result"
                      className="form-control"
                      placeholder=""
                      value={support_result}
                      autoComplete="off"
                      {...register("support_result", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.support_result?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp cơm*</label>
                    <input
                      type="text"
                      name="pc_food"
                      className="form-control"
                      placeholder=""
                      value={pc_food}
                      autoComplete="off"
                      {...register("pc_food", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_food?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp điện thoại*</label>
                    <input
                      type="text"
                      name="pc_phone"
                      className="form-control"
                      placeholder=""
                      value={pc_phone}
                      autoComplete="off"
                      {...register("pc_phone", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_phone?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col-3"
                  style={{
                    display:
                      getValues("type_key") === "part_time" ? "none" : "block",
                  }}
                >
                  <div className="form-group">
                    <label>Phụ cấp đi lại*</label>
                    <input
                      type="text"
                      name="pc_move"
                      className="form-control"
                      placeholder=""
                      value={pc_move}
                      autoComplete="off"
                      {...register("pc_move", {
                        required: "Không được rỗng",
                      })}
                      onChange={handleChangeFotmat}
                    />
                    <div className="error-message">
                      {errors?.pc_move?.message}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="col-4">
              <label>Bảo hiểm</label>
              <div className="mb-2">
                <input
                  type="checkbox"
                  checked={isCheckedBHXH}
                  onChange={(e) => setIsCheckedBHXH(e.target.checked)}
                />
                <span> BHXH</span>
              </div>
              <div className="form-group">
                  <div
                    className="col"
                    style={{
                      display: isCheckedBHXH === true ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label>Lương vị trí*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        autoComplete="off"
                        {...register("position_salary", {
                          required: "Không được rỗng",
                        })}
                        value={salaryPosion}
                        onChange={handleChangeFotmat}
                      />
                      <div className="error-message">
                        {errors?.position_salary?.message}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Thuế*</label>
                {tax?.map((tax, index) => {
                  return (
                    <div key={index} className="mb-2">
                      <input
                        type="radio"
                        value={tax.value}
                        {...register("tax", {
                          required: "Phải chọn đối thuế",
                        })}
                        onChange={() => {
                          if (tax.value == "LT") {
                            setIsCheckedGT(true);
                          } else {
                            setIsCheckedGT(false);
                          }

                          if (tax.value == "CK") {
                            setIsCheckedCK(true);
                          } else {
                            setIsCheckedCK(false);
                          }
                        }}
                      />
                      <span> {tax.label}</span>
                    </div>
                  );
                })}
                <div className="error-message">{errors?.tax?.message}</div>
                <div
                  className="col"
                  style={{
                    display: isChooseCK === false ? "block" : "none",
                  }}
                >
                  <div className="form-group">
                    <label>Khoản miễn thuế khác</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      {...register("dependent_another")}
                      onChange={handleChangeFotmat}
                      value={dependentAnother}
                    />
                    <div className="error-message">
                      {errors?.dependent_another?.message}
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  style={{
                    display: isChooseGT === true ? "block" : "none",
                  }}
                >
                  <div className="form-group">
                    <label>Số người phụ thuộc</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      {...register("dependent_count", {
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Phải là số nguyên dương",
                        },
                      })}
                    />
                    <div className="error-message">
                      {errors?.dependent_count?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ có lương*</label>
                <input
                  name="annual_leave"
                  type="number"
                  min="0"
                  step="any"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("annual_leave")}
                />
                <div className="error-message">
                  {errors?.annual_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ không lương*</label>
                <input
                  name="unpaid_leave"
                  min="0"
                  type="number"
                  step="any"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("unpaid_leave")}
                />
                <div className="error-message">
                  {errors?.unpaid_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày nghỉ tang*</label>
                <input
                  name="compassionate_leave"
                  type="number"
                  min="0"
                  step="any"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("compassionate_leave")}
                />
                <div className="error-message">
                  {errors?.compassionate_leave?.message}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Ngày kết hôn*</label>
                <input
                  name="marriage_leave"
                  type="number"
                  step="any"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("marriage_leave")}
                />
                <div className="error-message">
                  {errors?.marriage_leave?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success mr-2"
              style={{ fontSize: "14px" }}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>Cập nhật hợp
              đồng
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contract;
