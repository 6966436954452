/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Button, Popconfirm, Table, Space } from "antd";
// import AddShiftGroupModal from "./components/AddShiftGroupModal";
import { evaluationServices } from "../../services/evaluationServices";
import PermissionChecker from "../../components/PermissionChecker";
import AddQCEvaluateGroupModal from "./child/AddQCEvaluateGroupModal";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../history";

const QCEvaluateGroupPage = () => {
  const { id } = useParams();
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
  });

  const [qcEvaluateGroups, setQCEvaluateGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  var currentUrl = window.location.href;
  var url = new URL(currentUrl);
  var isReport = url.searchParams.get("is_report");

  useEffect(() => {
    getQCEvaluateGroup();
  }, []);

  const getQCEvaluateGroup = async () => {
    try {
      setLoading(true);
      const resShifts = await evaluationServices.getQCEvaluateGroup(id);
      setQCEvaluateGroups(resShifts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteQCEvaluateGroup = async (id) => {
    try {
      setLoading(true);
      const resShifts = await evaluationServices.deleteQCEvaluateGroup(id);
      await getQCEvaluateGroup();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (stt) => <p>{stt}</p>,
    },
    {
      title: isReport ? "Tên nhóm báo cáo" : "Tên nhóm đánh giá",
      dataIndex: "name",
      key: "name",
      render: (name) => <p>{name}</p>,
    },
    isReport != 1 ? (
      {
        title: "Tổng số điểm chuẩn",
        dataIndex: "total_standard_percent",
        key: "total_standard_percent",
        render: (total_standard_percent) => <p>{total_standard_percent}</p>,
      }
    ) : (
      <></>
    ),
    {
      title: "Tổng số tiêu chí",
      dataIndex: "qc_evaluetes_count",
      key: "qc_evaluetes_count",
      render: (qc_evaluetes_count) => <p>{qc_evaluetes_count}</p>,
    },
    {
      title: "Cập nhật lần cuối",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Col>
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_add ?? false,
            child: (
              <Button
                onClick={(e) => {
                  history.push(
                    `/qc-evaluate/${record?.key}?is_report=${
                      isReport ?? ""
                    }`
                  );
                }}
              >
               {isReport ? "Tạo tiêu chí báo cáo" : "Tạo tiêu chí đánh giá"}
              </Button>
            ),
          })}

          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_group_update ?? false,
            child: (
              <Button
                className="mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    title: `Chỉnh sửa nhóm đánh giá`,
                    method: "UPDATE",
                    visible: true,
                    data: record?.data,
                    isReport: record?.is_report,
                  });
                }}
              >
                Sửa
              </Button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_group_delete ?? false,
            child: (
              <Popconfirm
                className="mt-2"
                title="Xoá nhóm đánh giá ?"
                description="Bạn có chắc chắn muốn xoá nhóm đánh giá này chứ ?"
                onConfirm={() => {
                  deleteQCEvaluateGroup(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <Button danger={true}>Xoá</Button>
              </Popconfirm>
            ),
          })}
        </Col>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Space size="large">
          <h4 className="h4 title_content mb-0 text-gray-800">
            {" "}
            {isReport ? "Nhóm báo cáo" : "Nhóm đánh giá"}
          </h4>
          {(qcEvaluateGroups ?? []).length != 0 && isReport != true ? (
            <h6>{`Tổng số điểm chuẩn hiện tại: ${qcEvaluateGroups?.reduce(
              (a, b) => a + (b["total_standard_percent"] || 0),
              0
            )}`}</h6>
          ) : (
            <></>
          )}
          {(qcEvaluateGroups ?? []).length != 0 && isReport != true ? (
            <h6 style={{ color: "red" }}>
              {qcEvaluateGroups?.reduce(
                (a, b) => a + (b["total_standard_percent"] || 0),
                0
              ) != 100
                ? "===> Tổng số điểm chuẩn chưa đạt 100"
                : ""}
            </h6>
          ) : (
            <></>
          )}
        </Space>
        {PermissionChecker({
          decent: reportNumber.permissions?.qc_evaluate_group_add ?? false,
          child: (
            <button
              class={`btn btn-info btn-icon-split btn-sm`}
              data-toggle="modal"
              data-target="#modalShift"
              onClick={() => {
                setModal({
                  title: "",
                  method: "ADD",
                  isReport: isReport,
                  visible: true,
                });
              }}
            >
              <span class="icon text-white-50">
                <i class="fas fa-plus"></i>
              </span>
              <span class="text">
                {isReport ? "Thêm nhóm báo cáo" : " Thêm nhóm đánh giá"}
              </span>
            </button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(qcEvaluateGroups ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            data: e,
            stt: index + 1,
            name: e.name ?? "--:--",
            is_report: e.is_report,
            total_standard_percent: e.total_standard_percent ?? 0,
            qc_evaluetes_count: e.qc_evaluetes_count ?? 0,
            updated_at: moment.utc(e.updated_at).format("DD-MM-YYYY HH:mm"),
          };
        })}
        pagination={false}
      />
      <AddQCEvaluateGroupModal
        open={
          modal.visible && (modal.method === "ADD" || modal.method === "UPDATE")
        }
        qcEvaluateMainId={id}
        dataInput={modal.data}
        isReport={modal.isReport}
        onOk={() => {
          getQCEvaluateGroup();
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
};

export default QCEvaluateGroupPage;
