/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { shiftServices } from "../../../../services/shiftServices";
import { formatMoneyVND, formatNumberV2 } from "../../../../helpers";
import { constants } from "../../../../constants";
import colors from "react-multi-date-picker/plugins/colors";
import { toast } from "react-toastify";
Modal.setAppElement("#root");

const UpdateSalaryTable = ({
  salaryTableInput,
  visible,
  closeModal,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  const [salaryTable, setSalaryTable] = useState({});

  useEffect(() => {
    console.log(salaryTableInput);
    setSalaryTable({
      id: salaryTableInput?.id,
      month: salaryTableInput?.month,
      year: salaryTableInput?.year,
      salary: salaryTableInput?.salary,
      kpi_percent: formatNumberV2(salaryTableInput?.kpi_percent),
      another_minus: formatNumberV2(salaryTableInput?.another_minus),
      another_plus: formatNumberV2(salaryTableInput?.another_plus),
    });
  }, [salaryTableInput, visible]);

  //   function getSupport() {
  //     return (salary.supports ?? []).isNotEmpty
  //       ? (salary.supports ?? [])
  //           .map((e) => e.value ?? 0)
  //           .toList()
  //           .reduce((v, e) => v + e)
  //       : 0;
  //   }

  //   function itemSupport() {
  //     var result = null;

  //     if ((salary.supports ?? []).isNotEmpty) {
  //       if (salary.supports.length > 0) {
  //         result = salary.supports.map((e, index) => {
  //           var titles = e.title;
  //           var v = e.value;
  //           return (
  //             <div className="form-group">
  //               <div className="d-flex align-items-center">
  //                 <label className="col-lg-8 pl-0 pr-0">titles</label>
  //                 <input
  //                   value={formatMoneyVND(v ?? 0)}
  //                   type="text"
  //                   className="form-control"
  //                   autoComplete="off"
  //                   readOnly
  //                 />
  //               </div>
  //             </div>
  //           );
  //         });
  //       }
  //     }

  //     return result;
  //   }

  const customStyles = {
    content: {
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const handleFotmat = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "kpi_percent") {
      setSalaryTable({ ...salaryTable, kpi_percent: formatNumberV2(value) });
    }
    if (name == "another_plus") {
      setSalaryTable({ ...salaryTable, another_plus: formatNumberV2(value) });
    }
    if (name == "another_minus") {
      setSalaryTable({ ...salaryTable, another_minus: formatNumberV2(value) });
    }
  };

  const updateSalary = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const bodyRq = {
        kpi_percent:
          (salaryTable.kpi_percent ?? "") == ""
            ? "0"
            : salaryTable.kpi_percent.toString().replaceAll(".", ""),
        another_minus:
          (salaryTable.another_minus ?? "") == ""
            ? "0"
            : salaryTable.another_minus.toString().replaceAll(".", ""),
        another_plus:
          (salaryTable.another_plus ?? "") == ""
            ? "0"
            : salaryTable.another_plus.toString().replaceAll(".", ""),
        list_bonus: [],
      };

      const response = await shiftServices.updateSalary({
        body: bodyRq,
        areaId: areaId,
        salaryId: salaryTable.id,
      });

      if (response.success) {
        onSuccess();
        closeModal();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error("Lỗi cập nhật bảng lương");
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{"Cập nhật bảng lương" || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="modal-body">
          <div className="form-group">
            <label>Tháng áp dụng:</label>
            <input
              value={`${salaryTable?.month || 0}-${salaryTable?.year || 0}`}
              type="text"
              className="form-control"
              autoComplete="off"
              readOnly
            />
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">KPI đạt được</label>
              <input
                value={salaryTable?.kpi_percent}
                type="number"
                name="kpi_percent"
                min="0"
                max="100"
                className="form-control"
                autoComplete="off"
                onChange={handleFotmat}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Cộng khác</label>
              <input
                value={salaryTable?.another_plus}
                type="text"
                name="another_plus"
                className="form-control"
                autoComplete="off"
                onChange={handleFotmat}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="col-lg-8 pl-0 pr-0">Trừ khác</label>
              <input
                value={salaryTable?.another_minus}
                type="text"
                name="another_minus"
                className="form-control"
                autoComplete="off"
                onChange={handleFotmat}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div
          className="salary-receives"
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          <label className="salary-receives-text">
            Lương thực nhận: {formatMoneyVND(salaryTable?.salary)} VNĐ
          </label>
        </div>
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Thoát
        </button>
        <button
          type="button"
          className="btn btn-default"
          style={{ color: "red" }}
          onClick={(e) => {
            updateSalary();
          }}
        >
          Cập nhật
        </button>
      </div>
    </Modal>
  );
};

export default UpdateSalaryTable;
