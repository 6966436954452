/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfigProvider,
  DatePicker,
  Modal,
  Popconfirm,
  Space,
  Switch,
} from "antd";
import { Button, Form, Input, Select } from "antd";
import viVN from "antd/locale/vi_VN";
import dayjs from "dayjs";
import moment from "moment";
import { set } from "lodash";

const StaffStatusWatchedModal = ({
  open,
  closeModal,
  onOk,
  listChangeInput,
}) => {
  const areaId = useSelector((state) => state.area.areaId);
  const areas = useSelector((state) => state.area.areas);
  const departmentList = useSelector((state) => state.staff.department_list);
  const [listChange, setListChange] = useState(null);
  //  const [effectiveDate, setEffectiveDate] = useState(null);

  const [form] = Form.useForm();

  const disabledDate = (current) => {
    // Disable days before today
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    if (listChangeInput != null) {
      setListChange(listChangeInput);
    } else {
      //     setEffectiveDate(dayjs(Date.now()));
    }
  }, [open]);

  return (
    <Modal
      title={"Thông tin chỉnh sửa chờ tới hiệu lực"}
      centered
      //width={"600px"}
      open={open}
      onOk={(e) => {
        onOk();
      }}
      onCancel={() => {
        closeModal();
      }}
      footer={<></>}
    >
      <div>
        {(listChange ?? []).map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-content-start align-items-center flex-column"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #e8e8e8",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex justify-content-start align-items-center">
              <span>{item.content}</span>
              </div>
              <div style={{ width: "15px" }} />
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <span>Ngày hiệu lực</span>
                <div style={{ width: "15px" }} />
                <span>{moment(item.effective_date).format("DD-MM-YYYY")}</span>
              </div>
              <div style={{ width: "15px" }} />
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <span>Tạo bảng lương</span>
                <div style={{ width: "15px" }} />
                <Switch
                  checked={item.is_save_salary_table}
                  onChange={(v) => {
                    // setListChange((prevListChange) => {
                    //   const updatedList = [...prevListChange];
                    //   updatedList[index] = {
                    //     ...updatedList[index],
                    //     is_save_salary_table: v,
                    //   };
                    //   return updatedList;
                    // });
                  }}
                />
              </div>
              <Space>
                <Popconfirm
                  title="Huỷ quá trình chuyển chi nhánh?"
                  description="Bạn có chắc chắn muốn xoá huỷ quá trình này chứ ?"
                  onConfirm={() => {
                    setListChange(listChange.filter((i) => i.id != item.id));
                    if (listChange.length == 1) {
                      closeModal();
                    }
                    onOk(item.id);
                  }}
                  onCancel={() => {}}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button style={{ color: "red", borderColor: "red" }}>
                    Huỷ quá trình
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default StaffStatusWatchedModal;
