/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { shiftServices } from "../../../../services/shiftServices";
import { BsCalendarRange } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { constants } from "../../../../constants";
import moment from "moment";

Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const ShiftChoose = ({ visible, staffId, date, closeModal, onSubmit }) => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  useEffect(() => {
    if (visible == true) {
      console.log(staffId, date);
      fetchTimekeepingStaff(staffId, date);
    }
  }, [visible, staffId, date]);

  const [listTimekeeping, setListTimekeeping] = useState([]);

  const customStyles = {
    content: {
      width: "40%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const fetchTimekeepingStaff = async (id, date) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await shiftServices.fetchTimekeepingStaff(
        areaId,
        `?staff_id=${id}&date=${moment(date).format(
          constants.FORMAT_YYYY_MM_DD
        )}`
      );

      setListTimekeeping(res.data);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  function convertNumberToDateStr(start, end) {
    if (
      start !== null &&
      start !== undefined &&
      end !== null &&
      end !== undefined
    ) {
      return `${start.toString().padStart(2, "0")}:${end
        .toString()
        .padStart(2, "0")}`;
    }

    return "";
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>Chọn ca bổ sung</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="d-flex flex-column flex-wrap">
          <label>Vui lòng cho biết bạn muốn chọn ca chấm công nào?</label>
          {listTimekeeping.map((e) => {
            return (
              <div
                onClick={() => {
                  onSubmit(e);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="d-flex justify-content-start align-items-center">
                  <BsCalendarRange />
                  <div style={{ width: "5px" }}></div>
                  <div>{e?.name ?? ""}</div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <MdTimer />
                  <div style={{ width: "5px" }}></div>
                  <div>{`${convertNumberToDateStr(
                    e?.start_work_hour,
                    e?.start_work_minute
                  )} - ${convertNumberToDateStr(
                    e?.end_work_hour,
                    e?.end_work_minute
                  )}`}</div>
                </div>
              </div>
            );
          })}
        </div>
        
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            closeModal();
          }}
          class="btn btn-info"
        >
          Xác nhận
        </button>
      </div>
    </Modal>
  );
};

export default ShiftChoose;
