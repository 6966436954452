import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { constants } from "../../../constants";
import { procedureServices } from "../../../services/procedureServices";
import { shiftServices } from "../../../services/shiftServices";
import { Modal } from "antd";
import OverrideProcedureModal from "./OverrideProcedureModal";
import { toast } from "react-toastify";

const ProcessProcedure = ({
  visible,
  procedureInput,
  processInput,
  closeModal,
  indexInput,
  onSave,
}) => {
  const dispatch = useDispatch();
  const [listStaffApprover, setListStaffApprover] = useState([]);
  const [listViewer, setListViewer] = useState([]);
  const [process, setProcess] = useState({
    procedure_id: null,
    type: "need_all",
    list_approver_id: [],
    list_viewer_id: [],
  });

  const [listStaffApproverInit, setListStaffApproverInit] = useState([]);
  const [listStaffApproverNew, setListStaffApproverNew] = useState([]);

  useEffect(() => {
    if (processInput?.id !== null) {
      setProcess(processInput);
      setListStaffApprover(
        processInput?.approvers.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      /// dùng để check khởi tạo list người duyệt ban đầu
      setListStaffApproverInit(
        processInput?.approvers.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      setListViewer(
        processInput?.viewers.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
    } else {
      if (procedureInput !== null) {
        setListStaffApprover([]);
        /// dùng để check khởi tạo list người duyệt ban đầu
        setListStaffApproverInit([]);
        setListViewer([]);
        setProcess({ ...process, procedure_id: procedureInput.id });
      }
    }
  }, [processInput]);

  const fetchStaffsManager = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });
      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const createProcess = async () => {
    if (listStaffApprover.length === 0) {
      toast.error("Chưa chọn người duyệt");

      return;
    }

    console.log(process.type);

    if (typeof process === "undefined" || (process?.type ?? "") === "") {
      toast.error("Chưa chọn phương thức duyệt");

      return;
    }

    const data = {
      procedure_id: procedureInput.id,
      index: indexInput,
      type: process.type,
      list_approver_id: listStaffApprover.map((e) => e.value),
      list_viewer_id: listViewer.map((e) => e.value),
    };

    try {
      const response = await procedureServices.createProcess({ data: data });
      if (response.code != 401) {
        onSave(response.data);
        closeModal();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const updateProcess = async () => {
    if (listStaffApprover.length === 0) {
      toast.error("Chưa chọn người duyệt");
      
      return;
    }

    if ((process?.type ?? "") === "") {
      console.log(process.type);
      toast.error("Chưa chọn phương thức duyệt");
     
      return;
    }

    const data = {
      procedure_id: procedureInput.id,
      index: indexInput,
      type: process.type,
      list_approver_id: listStaffApprover.map((e) => e.value),
      list_viewer_id: listViewer.map((e) => e.value),
    };

    try {
      const response = await procedureServices.updateProcess({
        id: process.id,
        data: data,
      });
      // truyền listFrom và listTo để check xem có thay đổi người duyệt hay không ở màn createProcedure
      onSave(checkChange(listStaffApproverInit, listStaffApproverNew));
    } catch (error) {
      console.log(error);
      toast.error(error);
    
    }
  };

  const checkChange = (listInit, listNew) => {
    /// merge 2 mảng mới và cũ để tìm các phần tử bị thêm vào và xóa đi

    const mergedArray = listInit.concat(
      listNew.filter(
        (item) => !listInit?.map((e) => e.value).includes(item.value)
      )
    );

    /// tìm các phần tử bị xoá

    const listFrom = mergedArray.filter(
      (item) => !listNew?.map((e) => e.value).includes(item.value)
    );

    /// tìm các phần tử được thêm

    const listTo = mergedArray.filter(
      (item) => !listInit?.map((e) => e.value).includes(item.value)
    );

    return {
      listFrom: listFrom,
      listTo: listTo,
    };
  };

  return (
    <Modal
      title={"Người duyệt"}
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 700, maxWidth: 1000 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        if (processInput.id !== null) {
          updateProcess();
        } else {
          createProcess();
        }
      }}
    >
      <div style={{ overflowY: "auto", height: "100%", margin: "20px" }}>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div style={{ flex: 1 }}>Người duyệt</div>
          <div style={{ width: "80%" }}>
            <AsyncPaginate
              value={listStaffApprover}
              loadOptions={fetchStaffsManager}
              isMulti
              closeMenuOnSelect={false}
              onChange={(v) => {
                setListStaffApprover(v);
                setListStaffApproverNew(v);
              }}
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              menuPortalTarget={document.body}
              debounceTimeout={500}
              isClearable
              isSearchable
              placeholder={"Chọn người duyệt"}
            />
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "40%",
              backgroundColor: "#0505050f",
              height: "1px",
            }}
          ></div>
          <div className="mx-2">Phê duyệt</div>
          <div
            style={{
              width: "40%",
              backgroundColor: "#0505050f",
              height: "1px",
            }}
          ></div>
        </div>
        <div className="form-group d-flex" style={{ marginTop: "20px" }}>
          <div style={{ flex: 1 }}>Phương thức duyệt*</div>
          <div style={{ width: "80%" }}>
            <div className="mb-2">
              <input
                type="radio"
                checked={process?.type === "need_all"}
                onChange={(e) => {
                  setProcess({ ...process, type: "need_all" });
                }}
              />
              <span> Cần tất cả người duyệt đồng ý</span>
            </div>
            <div className="mb-2">
              <input
                type="radio"
                checked={process?.type === "need_only"}
                onChange={(e) => {
                  setProcess({ ...process, type: "need_only" });
                }}
              />
              <span> Chỉ cần 1 người duyệt đồng ý hoặc từ chối</span>
            </div>
          </div>
        </div>
        <div
          className="d-flex"
          style={{ alignItems: "center", marginTop: "20px" }}
        >
          <div style={{ flex: 1 }}>Người được xem</div>
          <div style={{ width: "80%" }}>
            <AsyncPaginate
              value={listViewer}
              loadOptions={fetchStaffsManager}
              isMulti
              closeMenuOnSelect={false}
              onChange={setListViewer}
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              menuPortalTarget={document.body}
              debounceTimeout={500}
              isClearable
              isSearchable
              placeholder={"Chọn người được xem"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProcessProcedure;
