/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { DatePicker as a, ConfigProvider, Divider } from "antd";
import { Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import customParseFormat from "dayjs/plugin/customParseFormat";
import viVN from "antd/locale/vi_VN";
import "dayjs/locale/vi";
import dayjs from "dayjs";
import { constants } from "../../../../constants";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = a;

const DatePickerModal = ({ visible, filterInput, closeModal, onOk }) => {
  const [dateRange, setDateRange] = useState(null);
  const [monthRange, setMonthRange] = useState(null);
  const [quarterRange, setQuarterRange] = useState(null);
  const [yearRange, setYearRange] = useState(null);
  const [returnRange, setReturnRange] = useState(null);
  const [typeDate, setTypeDate] = useState(null);

  const handleDateChange = (value, type) => {
    if (type == "day") {
      setDateRange(value);
      setMonthRange(null);
      setQuarterRange(null);
      setYearRange(null);
    }

    if (type == "month") {
      setMonthRange(value);
      setDateRange(null);
      setQuarterRange(null);
      setYearRange(null);
    }

    if (type == "quarter") {
      setQuarterRange(value);
      setDateRange(null);
      setMonthRange(null);
      setYearRange(null);
    }

    if (type == "year") {
      setYearRange(value);
      setDateRange(null);
      setMonthRange(null);
      setQuarterRange(null);
    }

    setReturnRange(value);
    setTypeDate(type);
  };
  const areas = useSelector((state) => state.area.areas);
  const [areaChart, setAreaChart] = useState(null);
  const [areaCompare, setAreaCompare] = useState(null);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    if (filterInput != null) {
      setAreaChart(filterInput.area_id);
      setAreaCompare(filterInput.area_compare_id);

      if (filterInput.type == "day") {
        setDateRange([
          dayjs(filterInput.from_time),
          dayjs(filterInput.to_time),
        ]);
        setMonthRange(null);
        setQuarterRange(null);
        setYearRange(null);
      }

      if (filterInput.type == "month") {
        setMonthRange([
          dayjs(filterInput.from_time),
          dayjs(filterInput.to_time),
        ]);
        setDateRange(null);
        setQuarterRange(null);
        setYearRange(null);
      }

      if (filterInput.type == "quarter") {
        setQuarterRange([
          dayjs(filterInput.from_time),
          dayjs(filterInput.to_time),
        ]);
        setDateRange(null);
        setMonthRange(null);
        setYearRange(null);
      }

      if (filterInput.type == "year") {
        setYearRange([
          dayjs(filterInput.from_time),
          dayjs(filterInput.to_time),
        ]);
        setDateRange(null);
        setMonthRange(null);
        setQuarterRange(null);
      }

      setReturnRange([
        dayjs(filterInput.from_time),
        dayjs(filterInput.to_time),
      ]);
      setTypeDate(filterInput.type);
    }
  }, [visible]);

  return (
    <Modal
      title="Chọn thời gian"
      centered
      open={visible}
      onOk={() => {
        onOk(
          returnRange?.map((item) => item.toDate()),
          typeDate,
          areaChart,
          areaCompare
        );
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        <div
          className="d-flex justify-content-between align-items-center py-1"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={() => {}}
        >
          <span>Theo ngày</span>{" "}
          <ConfigProvider locale={viVN}>
            <RangePicker
              onChange={(value) => {
                handleDateChange(value, "day");
              }}
              value={dateRange}
            />
          </ConfigProvider>
        </div>
        <div
          className="d-flex justify-content-between align-items-center py-1"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={() => {}}
        >
          <span className="mr-3">Theo tháng</span>
          <ConfigProvider locale={viVN}>
            <RangePicker
              picker="month"
              onChange={(value) => {
                handleDateChange(value, "month");
              }}
              value={monthRange}
            />
          </ConfigProvider>
        </div>
        <div
          className="d-flex justify-content-between align-items-center py-1"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={() => {}}
        >
          <span>Theo quý</span>
          <ConfigProvider locale={viVN}>
            <RangePicker
              picker="quarter"
              onChange={(value) => {
                handleDateChange(value, "quarter");
              }}
              value={quarterRange}
            />
          </ConfigProvider>
        </div>
        <div
          className="d-flex justify-content-between align-items-center py-1"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={() => {}}
        >
          <span>Theo năm</span>
          <ConfigProvider locale={viVN}>
            <RangePicker
              picker="year"
              onChange={(value) => {
                handleDateChange(value, "year");
              }}
              value={yearRange}
            />
          </ConfigProvider>
        </div>
        <Divider />
        <div className="d-flex justify-content-between align-items-center">
          So sánh:
          <div className="mr-2">
            <Select
              style={{
                width: 150,
              }}
              value={areaChart}
              onChange={setAreaChart}
              options={(areas.data ?? []).map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
            />
          </div>
          {areaChart == constants.AREA_ALL_ID ? <></> : <div>với</div>}
          {areaChart == constants.AREA_ALL_ID ? (
            <></>
          ) : (
            <div className="ml-2">
              <Select
                style={{
                  width: 150,
                }}
                value={areaCompare}
                onChange={setAreaCompare}
                options={(areas.data ?? []).slice(1).map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                })}
              />
            </div>
          )}
        </div>
        <Divider />
      </div>
    </Modal>
  );
};

export default DatePickerModal;
