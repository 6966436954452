/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input, } from "antd";

import "suneditor/dist/css/suneditor.min.css";
import { franchiseServices } from "../../../../../services/franchiseServices";

const AddReportCateModal = ({ open, closeModal, onOk, dataInput }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
 

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
     
      }
    } else {
      onReset();
    }
  }, [open]);

  const addReportCate = async (data) => {
    setLoading(true);
    try {
      const response = await franchiseServices.addReportCate(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateReportCate = async (data, id) => {
    try {
      const response = await franchiseServices.updateReportCate(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    if (dataInput != null) {
      updateReportCate(values, dataInput.id);
    } else {
      addReportCate(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
      description: dataInput?.description ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 19,
      span: 16,
    },
  };

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa loại thống kê báo cáo" : "Thêm loại thống kê báo cáo"}
      centered
      open={open}
      width={800}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="mt-4">
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Tên phân loại"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tên thống kê báo cáo"} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
          >
            <Input placeholder={"Nhập mô tả"} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddReportCateModal;
