import React, { Component } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { constants as c } from "../constants";
import { Spin } from "antd";
export default function UID_Loading() {
  var showLoading = useSelector((state) => state.app.loadingIcon);

  return (
    <>
      {showLoading === c.SHOW_LOADING && (
        <Spin className="donut multi" style={{ textAlign: "center" }} />
      )}
    </>
  );
}
