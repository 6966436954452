import React, { useState } from "react";
import moment from "moment";
import { constants } from "../../../../constants";
import { formatMoneyVND } from "../../../../helpers";
import { useSelector } from "react-redux";

export default function FormItem({
  data,
  index,
  isSelected,
  onChoose,
  onClick,
  onClickStaffApprove,
  onClickStaffViewer,
  accept,
  cancel,
}) {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };


  const infoForm = (type) => {
    return (
      <span>
        <p>
          <span style={{ fontWeight: "600" }}>
            Danh sách câu hỏi đánh giá:{" "}
          </span>
        </p>
        {(data?.evaluation_question ?? []).slice(0, 3).map((e) => {
          return <p style={{ paddingRight: "15px" }}>{e?.question ?? ""}</p>;
        })}
      </span>
    );
  };


  return (
    <tr
      style={{
        height: "140px",
        borderBottom: "1px solid lightgrey",
        paddingBottom: "10px",
      }}
      onClick={(e) => {
        onClick(data.id);
      }}
    >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onClick={(e) => {
            e.stopPropagation();
            onChoose();
          }}
        />
      </td>
      <td className="text-center">{index + 1}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            {imageError || data.staff?.avatar === null ? (
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f6c23e",
                }}
              >
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {`${data?.staff?.name ?? ""}`.charAt(0).toUpperCase()}
                </div>
              </div>
            ) : (
              <img
                src={data.staff?.avatar}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>

          <div>
            <div style={{ fontWeight: "bold", color: "black" }}>
              {data?.staff?.name ?? ""}
            </div>
            <div style={{ fontSize: "13px" }}>{data.title ?? ""}</div>
          </div>
        </div>
      </td>
      <td>{infoForm(data.type)}</td>
      <td>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.approvers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffApprove(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.viewers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffViewer(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
      </td>
      <td style={{ textAlign: "right" }}>
        {moment.utc(data?.created_at).format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
      </td>
      {data?.status === 1 && checkPermission(data?.process) &&
              data?.is_reviewed == true ? (
        <td style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                accept();
              }}
            >
              Chấp nhận
            </button>
          </div>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}
