/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "rsuite";
import { constants } from "../../../../constants";
import { shiftServices } from "../../../../services/shiftServices";
import ItemEmployee from "../../WorkSchedule/child/ItemEmployee";
import ModalContentEmployee from "../../WorkSchedule/child/ModalContentEmployee";
import BonusModal from "./BonusModal";
import ItemStaff from "./ItemStaff";
import StaffModal from "./StaffModal";
import SupportModal from "./SupportModal";
import { toast } from "react-toastify";
Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const SalaryCreateModal = ({
  visible,
  title,
  salary,
  onSuccess,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  const [staffIds, setStaffIds] = useState([]);
  const [bonusConfigIds, setBonusConfigIds] = useState([]);
  const [bonusConfig, setBonusConfig] = useState([]);
  const [listSupportConfig, setListSupportConfig] = useState([]);
  const [applyDate, setApplyDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [modal, setModal] = useState({
    staff: false,
    support: false,
  });

  const [dataTemp, setDataTemp] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (salary && visible) {
      setStaffIds([salary.staff_id]);
      fetchSupportConfig();
      setApplyDate({
        month: salary.month,
        year: salary.year,
      });
    }
  }, [salary]);

  useEffect(() => {
    fetchSupportConfig();
  }, []);

  const handleCloseModal = () => {
    setModal({
      staff: false,
      support: false,
    });
  };

  const handleSubmitStaffModal = (ids) => {
    var data = ids.map((e, index) => {
      return {
        staff_id: e,
        kpi_percent: 100,
      };
    });

    setStaffIds(data);
    handleCloseModal();
  };

  const handleSubmitBonusConfigModal = (supportConfigSelected) => {
    console.log(modal.staff_id);

    const updatedStaff = (employees ?? []).map((e) => {
      if (e.staff_id == modal.staff_id) {
        var data = e;
        data.list_support_default_id = supportConfigSelected;

        return { ...e, ...data };
      }
      return e;
    });

    // Cập nhật state của component
    setEmployees(updatedStaff);
    console.log(employees);
    handleCloseModal();
  };

  const handleChangeDate = (date) => {
    if (moment.isDate(date)) {
      setApplyDate({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
  };

  const handleSetDefaultForm = () => {
    setStaffIds([]);
    setBonusConfigIds([]);
    setApplyDate({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    });
  };

  const handleCreateSalary = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const listBonus = bonusConfig.map((item) => ({
        title: item.title,
        description: item.description,
        value: item.value,
      }));
      const salaryBody = {
        year: applyDate.year,
        month: applyDate.month,
        list_data: employees.map((e) => {
          return {
            staff_id: e.staff_id,
            kpi_percent: e.kpi_percent.toString().replaceAll(".", ""),
            another_plus: e.another_plus.toString().replaceAll(".", ""),
            another_minus: e.another_minus.toString().replaceAll(".", ""),
            use_education_kpi: e.use_education_kpi == null ? true : e.use_education_kpi,
            BHXH: e.BHXH.toString().replaceAll(".", ""),
            tax: e.tax.toString().replaceAll(".", ""),
            list_support_default_id: e.list_support_default_id.map((e) => e.id),
            list_bonus_default_id: bonusConfigIds,
            list_bonus: listBonus,
          };
        }),
      };
      const response = await shiftServices.createSalary({
        areaId: areaId,
        body: salaryBody,
      });

      if (response.success) {
        closeModal();
        onSuccess();
        handleSetDefaultForm();
        toast.success(response.msg);
       
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("Lỗi khi tạo bảng lương");
      
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const onSwitch = (checked, employeeId) => {
    const updatedStaff = (employees ?? []).map((e) => {
      if (e.staff_id == employeeId) {
        var data = e;
        data.use_education_kpi = checked;
        return { ...e, ...data };
      }
      return e;
    });

    setEmployees(updatedStaff);
    handleCloseModal();
  };

  const handleRemoveEmployee = (employeeId) => {
    const result = employees.filter(
      (employee) => employee.staff_id !== employeeId
    );
    setEmployees(result);
  };

  const customStyles = {
    content: {
      width: "80%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const convertData = (dataTemp) => {
    const dataNew = dataTemp.map((e) => {
      if (!employees.map((emp) => emp.id).includes(e.id)) {
        return {
          staff_id: e.id,
          name: e.name,
          kpi_percent: 100,
          another_plus: 0,
          another_minus: 0,
          BHXH: 0,
          tax: 0,
          list_support_default_id: [],
        };
      }
    });

    setEmployees(dataNew);
  };

  const formatNumberV2 = (str) => {
    if (str === undefined || str === null) return "";
    const strFormat = str
      .toString()
      .replace(/[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "");
    if (Number(strFormat) >= 1000) {
      return strFormat
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ".") + prev;
        });
    } else if (Number(strFormat) > 0 && Number(strFormat) < 1000) {
      return Number(strFormat);
    } else {
      return "";
    }
  };

  const handleData = (e, indexEmp) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "kpi_percent") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
      console.log(newListEmp);
    }

    if (name == "another_plus") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);
      setEmployees(newListEmp);
    }

    if (name == "another_minus") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);

      setEmployees(newListEmp);
    }

    if (name == "BHXH") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);

      setEmployees(newListEmp);
    }

    if (name == "tax") {
      const newListEmp = employees.reduce((prevData, currentData, index) => {
        const newData =
          indexEmp === index
            ? {
                ...currentData,
                [name]: formatNumberV2(value),
              }
            : currentData;
        return [...prevData, newData];
      }, []);

      setEmployees(newListEmp);
    }
  };

  const fetchSupportConfig = async () => {
    try {
      const response = await shiftServices.fetchSupportConfig(areaId);

      if (response.success) {
        setListSupportConfig(response.data);
      }
    } catch (error) {
      console.log("error support config" + error);
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{"Thêm mới bảng lương" || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ margin: "10px" }}
      >
        <div>
          <label>
            Tháng áp dụng <span className="text-danger">*</span>
          </label>
          <div>
            <DatePicker
              value={moment(
                `${applyDate.month}-${applyDate.year}`,
                FORMAT_DATE
              ).toDate()}
              format={FORMAT_DATE}
              cleanable={false}
              onChange={handleChangeDate}
            />
          </div>
        </div>
        <div>
          <label>
            Đối tượng áp dụng<span className="text-danger">*</span>
          </label>
          <div
            className="d-flex align-items-center btn-add"
            onClick={() => setModal({ ...modal, staff: true })}
          >
            <i
              class="fa fa-plus-circle ml-2 question-group-icon text-success"
              aria-hidden="true"
            ></i>
            <span className="ml-1">
              Thêm nhân viên{" "}
              <span className="font-weight-bold">
                (Đã chọn: {employees.length} nhân viên)
              </span>
            </span>
          </div>
        </div>
        <div></div>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div
          className="form-group"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {employees?.length > 0 ? (
            <div className="table-responsive mt-3">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table no-footer dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Tên nhân viên</th>
                      <th>KPI đạt được</th>
                      <th>Khoản cộng</th>
                      <th>Khoản trừ</th>
                      <th>Tiền BHXH</th>
                      <th>Tiền Thuế</th>
                      <th>Phụ cấp</th>
                      <th>Tính KPI đào tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees?.map((employee, index) => {
                      return (
                        <ItemStaff
                          key={employee.id}
                          employee={employee}
                          onChange={(e) => {
                            handleData(e, index);
                            // console.log(employees);
                          }}
                          handleRemoveEmployee={(employeeId) =>
                            handleRemoveEmployee(employeeId)
                          }
                          onSwitch={(checked, employeeId) => {
                            onSwitch(checked, employeeId);
                          }}
                          listSupportConfig={
                            employee.list_support_default_id ?? []
                          }
                          chooseSupport={(listSupportConfig) => {
                            setModal({
                              ...modal,
                              support: true,
                              staff_id: employee.staff_id,
                              listSupportConfig: listSupportConfig,
                            });
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="mt-2 text-danger center">
              Chưa chọn đối tượng áp dụng
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Đóng
        </button>
        <button
          type="submit"
          className="btn btn-info"
          onClick={handleCreateSalary}
        >
          Lưu
        </button>
      </div>
      <StaffModal
        visible={modal.staff}
        staffIds={staffIds}
        closeModal={handleCloseModal}
        onSubmit={handleSubmitStaffModal}
        handleChoice={(value) => {
          console.log(value);
          setDataTemp(value);
        }}
        onSave={() => {
          convertData(dataTemp);
        }}
      />
      <SupportModal
        visible={modal.support}
        supportConfigSelected={modal.listSupportConfig ?? []}
        closeModal={handleCloseModal}
        onSubmit={handleSubmitBonusConfigModal}
      />
    </Modal>
  );
};

export default SalaryCreateModal;
