import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import * as helpers from "../../../helpers";
export default function Item(props) {
  const { v, index } = props;
  const {
    id,
    name,
    email,
    phone_number,
    sex,
    position,
    department,
    created_at,
  } = v.user || {};
  var diff = Date.now() - Date.parse(created_at);
  var time = helpers.convertMsToDMY(diff);

  return (
    <tr className="hover-product even">
      <td>
        <input
          type="checkbox"
          name=""
          checked={props.checkedStaff(id)}
          onChange={(e) => props.changeSelectStaffs(e, id)}
        />
      </td>{" "}
      <td className="sorting_1">{id}</td>
      <td>
        <Link to={`/staff/edit/${id}?key=staff`}>{name}</Link>
      </td>
      <td>{phone_number}</td>
      <td>{email}</td>
      <td>{department?.name}</td>
      <td style={{ display: "flex" }}>
        <button
          onClick={() => props.handleDelete(v.id)}
          data-toggle="modal"
          data-target="#modalUID"
          className="btn btn-danger btn-sm show"
          style={{ marginLeft: "10px" }}
        >
          <i className="fa fa-trash" /> Xóa
        </button>
      </td>
    </tr>
  );
}
