import { constants as c } from "../constants";
const userInfo = localStorage.getItem("userInfo");
const tokenInfo = localStorage.getItem("tokenInfo");
const profile = localStorage.getItem("profile");

const initialState = {
  userInfo: userInfo && userInfo !== "undefined" ? JSON.parse(userInfo) : null,
  tokenInfo:
    tokenInfo && userInfo !== "undefined" ? JSON.parse(tokenInfo) : null,
  profile: profile
    ? {
        ...JSON.parse(profile),
        status: c.LOADING,
      }
    : {
        status: c.LOADING,
      },
};
export function user(state = initialState, action) {
  switch (action.type) {
    case c.LOGIN_SUCCESS:
      return {
        ...state,
        status_reg: c.LOGIN_SUCCESS,
        tokenInfo: action.tokenInfo,
      };
    case c.LOGIN_FAILURE:
      return {
        ...state,
        message: action.msg,
        msg_code: action.msg_code,
      };
    case c.GET_PROFILE:
      return {
        ...state,
        profile: { ...action.data, status: c.SUCCESS },
      };
      case c.GET_PROFILE_FAILURE:
         return {
           ...state,
           profile: {status: c.FAILURE },
         };
      
    default:
      return state;
  }
}
