/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { constants } from "../../../../constants";
import { Spin, Modal } from "antd";
import { reportSysServices } from "../../../../services/reportSysServices";
import { Bar, Line } from "react-chartjs-2";
import { MdArrowForwardIos } from "react-icons/md";
import DatePickerModal from "./DatePickerModal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartDetailOperateModal = ({ open, type, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areas = useSelector((state) => state.area.areas);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [dataChart, setDataChart] = useState(null);
  const [dataChartCP, setDataChartCP] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [titleChart, SetTitleChart] = useState("Số lượng nhân viên mới");

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  useEffect(() => {
    setFilter({
      type: "day",
      from_time: getFirstAndLastDayInMonth().firstDay,
      to_time: getFirstAndLastDayInMonth().lastDay,
      area_compare_id: null,
      area_id: areaId,
    });
  }, [open]);

  useEffect(() => {
    if (open) {
      getDataChart(type);
    }
  }, [filter]);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  var options = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Số lượng nhân viên mới",
      },
    },
  };

  const getDataChart = (type) => {
    switch (type) {
      case constants.REVENUE:
        return getReportRevenue(type);
      case constants.BUDGET:
        SetTitleChart("Tổng doanh thu nộp về công ty");
        return getReportBudget();
      case constants.INCIDENT:
        SetTitleChart("Tổng số nhân viên bị lập biên bản");
        return getReportIncidents();  
        case constants.INCIDENT_INFORMATION:
        SetTitleChart("Tổng số thông tin đã gửi");
        return getReportInformation();
      default:
        return getReportRevenue(type);
    }
  };

  const getReportRevenue = async (type) => {
    setRefresh(true);
    console.log(type);
    try {
      const response = await reportSysServices.getReportRevenue({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_revenue ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_revenue ?? 0
      );

      if (type == constants.REVENUE) {
        SetTitleChart("Tổng doanh thu");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_revenue ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_revenue ?? 0
        );
      }

      if (type == constants.CASH) {
        SetTitleChart("Tổng doanh thu tiền mặt");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_cash ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_cash ?? 0
        );
      }
      if (type == constants.MONEY_TRANSFER) {
        SetTitleChart("Tổng doanh thu chuyển khoản");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money_transfer ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_money_transfer ?? 0
        );
      }
      if (type == constants.MONEY_SWIPE) {
        SetTitleChart("Tổng doanh thu quẹt thẻ");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money_swipe ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_money_swipe ?? 0
        );
      }
      if (type == constants.SHOPEE) {
        SetTitleChart("Tổng doanh thu Shopee");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_shopee ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_shopee ?? 0
        );
      }
      if (type == constants.GRAB) {
        SetTitleChart("Tổng doanh thu Grab");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_grab ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_grab ?? 0
        );
      }

      if (type == constants.BEAMIN) {
        SetTitleChart("Tổng doanh thu Beamin");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_beamin ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_beamin ?? 0
        );
      }
      if (type == constants.BEFOOD) {
        SetTitleChart("Tổng doanh thu Befood");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_befood ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_befood ?? 0
        );
      }

      if (type == constants.ORTHER) {
        SetTitleChart("Tổng doanh thu khác");
        dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_other_revenue ?? 0
        );
        dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_other_revenue ?? 0
        );
      }

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getReportBudget = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getReportBudget({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month"
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_money_tip ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_money_tip ?? 0
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getReportIncidents = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getReportIncidents({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month" 
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getReportInformation = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getReportIncidents({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }&is_incident=false`,
      });

      var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
        filter?.type == "month" 
          ? moment(item.time ?? Date()).format("MM-YYYY")
          : filter?.type == "year"
          ? moment(item.time ?? Date()).format("YYYY")
          : moment(item.time ?? Date()).format("DD-MM-YYYY")
      );

      var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
        (item) => item.total_form ?? 0
      );

      if (filter.area_compare_id == null) {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        setDataChart({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
              data: dataStaffCP,
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getTypeString = () => {
    if (filter?.type == "month") return "tháng";
    if (filter?.type == "year") return "Năm";
    if (filter?.type == "day") return "ngày";
    if (filter?.type == "quarter") return "quý";
    return "Ngày";
  };

  return (
    <Modal
      title=""
      centered
      open={open}
      width="70%"
      onOk={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        <div className="d-flex">
          <span
            className="px-3 pb-3 d-flex justify-content-start align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal({ ...modal, method: "PICKER", visible: true });
            }}
          >
            Theo {getTypeString()}{" "}
            {filter?.type == "month"
              ? moment(filter.from_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.from_time ?? Date()).format("YYYY")
              : moment(filter.from_time ?? Date()).format("DD-MM-YYYY")}{" "}
            đến{" "}
            {filter?.type == "month"
              ? moment(filter.to_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.to_time ?? Date()).format("YYYY")
              : moment(filter.to_time ?? Date()).format("DD-MM-YYYY")}
            <MdArrowForwardIos className="ml-1" size={13} />
          </span>
          {filter.area_compare_id != null ? (
            <div>{`Đang so sánh: ${
              areas.data.filter((a) => a.id === filter.area_id)[0].name ?? ""
            } với ${
              areas.data.filter((a) => a.id === filter.area_compare_id)[0]
                .name ?? ""
            }`}</div>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex justify-content-center">
          {dataChart === null || refresh == true ? (
            <Spin style={{ textAlign: "center" }} />
          ) : (dataChart.datasets ?? []).length == 1 ? (
            <Bar
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth:
                  filter.area_compare_id == null || dataChartCP === null
                    ? "100%"
                    : "49%",
              }}
            />
          ) : (
            <Line
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth: "100%",
              }}
            />
          )}
        </div>
        <DatePickerModal
          visible={modal.visible && modal.method === "PICKER"}
          closeModal={() => {
            handleCloseModal();
          }}
          filterInput={filter}
          onOk={(value, type, areaChart, areaCompare) => {
            setFilter({
              ...filter,
              type: type,
              area_id: areaChart,
              area_compare_id: areaCompare,
              from_time: value[0],
              to_time: value[1],
            });
            handleCloseModal();
          }}
        ></DatePickerModal>
      </div>
    </Modal>
  );
};

export default ChartDetailOperateModal;
