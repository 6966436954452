import { constants as c } from "../constants";
import { feedbackServices as s } from "../services/feedbackServices";
import { appServices as a } from "../services/appServices";

import history from "../history";

function messageInfo(res, showMessage = true) {
  //showMessage này chỉ dùng nếu check phân quyền, chuyển sang false để check
  if (showMessage !== true) {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_FAILURE,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  }
  else
  {
    if (res.msg_code === "NOT_AUTHORIZATION") {
      window.location.href = `/${res.code}?code=${res.code}&msg=${res.msg}`;
      return;
    }
    return {
      type: c.UID_SUCCESS,
      info: {
        status: res.success,
        msg: res.msg,
      },
    };
  }

}

function getAllFeedback(queryString) {
  return (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    s.getAllFeedback(queryString).then((res) => {
      dispatch(messageInfo(res, false));
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));

      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
    });
  };
  function success(data) {
    return { type: c.GET_ALL_FEEDBACK_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_ALL_FEEDBACK_FAILURE, code, message };
  }
}

// function getFeedbackInfo(id) {
//   return (dispatch) => {
//     dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
//     s.getFeedbackInfo(id).then((res) => {
//       dispatch(messageInfo(res, false));
//       if (res.code === 200) dispatch(success(res.data));
//       else dispatch(failure(res.code, res.msg));

//       dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });

//     });
//   };
//   function success(data) {
//     return { type: c.GET_FEEDBACK_SUCCESS, data };
//   }
//   function failure(code, message) {
//     return { type: c.GET_FEEDBACK_FAILURE, code, message };
//   }
// }

function createFeedback(imgUpload, info) {
  return async (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    var image_URL = null;
    if (imgUpload) {
      var resImage = await a.uploadImg(imgUpload);
      if (resImage.success === true) {
        image_URL = resImage.data.image_url;
      } else {
        dispatch(messageInfo(resImage, true));
        dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
        return;
      }
    }
    var res = await s.createFeedback({ ...info, image_URL });
    dispatch(messageInfo(res));
    if (res.code === 200 || res.code === 201) {
      dispatch({ type: c.RESET_FEEDBACK_LIST_STATUS });
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });

      history.goBack();
    }
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
  };
}

function updateFeedback(imgUpload, info, id) {
  return async (dispatch) => {
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
    var image_URL = info.image_URL;
    if (imgUpload) {
      var resImage = await a.uploadImg(imgUpload);
      if (resImage.success === true) {
        image_URL = resImage.data.image_url;
      } else {
        dispatch(messageInfo(resImage, true));
        dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
        return;
      }
    }
    var res = await s.updateFeedback(id, { ...info, image_URL });
    dispatch(messageInfo(res));
    if (res.code === 200 || res.code === 201) {
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
      dispatch({ type: c.RESET_FEEDBACK_LIST_STATUS });
      history.goBack();
    }
    dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
  };
}

function deleteFeedback(id) {
  return (dispatch) => {
    s.deleteFeedback(id).then((res) => {
      dispatch({ type: c.CHANGE_LOADING, typeLoading: c.SHOW_LOADING });
      dispatch(messageInfo(res));
      if (res.code === 201 || res.code === 200) {
        window.$(".modal").modal("hide");
        dispatch({ type: c.CHANGE_LOADING, typeLoading: c.NONE_LOADING });
        dispatch({ type: c.RESET_FEEDBACK_LIST_STATUS });
      } else dispatch(failure(res.code, res.msg));
    });
  };
  function failure(code, msg) {
    return { type: c.FAILURE, code, msg };
  }
}

export const feedbackAction = {
  getAllFeedback,
  // getFeedbackInfo,
  createFeedback,
  updateFeedback,
  deleteFeedback,
};
