export default function ShareIcon({ className, ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <path
        d="M0.99995 15.5002H0.98095C0.859865 15.4962 0.744351 15.4483 0.65588 15.3656C0.567409 15.2828 0.511999 15.1707 0.49995 15.0502C0.48345 14.8702 0.0639503 10.5592 2.71645 7.61318C4.18745 5.97868 6.29995 5.10418 8.99995 5.00918V3.00018C8.99992 2.90328 9.02804 2.80846 9.0809 2.72725C9.13376 2.64603 9.20908 2.58193 9.29769 2.54273C9.38631 2.50354 9.48441 2.49093 9.58006 2.50646C9.6757 2.52198 9.76478 2.56497 9.83645 2.63018L15.3365 7.63018C15.388 7.67704 15.4292 7.73416 15.4574 7.79788C15.4856 7.8616 15.5001 7.9305 15.5001 8.00018C15.5001 8.06985 15.4856 8.13876 15.4574 8.20248C15.4292 8.26619 15.388 8.32332 15.3365 8.37018L9.83645 13.3702C9.76478 13.4354 9.6757 13.4784 9.58006 13.4939C9.48441 13.5094 9.38631 13.4968 9.29769 13.4576C9.20908 13.4184 9.13376 13.3543 9.0809 13.2731C9.02804 13.1919 8.99992 13.0971 8.99995 13.0002V10.9537C7.44195 10.8402 2.27345 10.7927 1.49195 15.0897C1.47099 15.2049 1.41026 15.3091 1.32035 15.3841C1.23044 15.4591 1.11705 15.5002 0.99995 15.5002ZM9.99995 4.13068V5.50018C9.99995 5.63279 9.94727 5.75996 9.85351 5.85373C9.75974 5.9475 9.63256 6.00018 9.49995 6.00018C6.85495 6.00018 4.82295 6.76768 3.45945 8.28218C2.46754 9.45352 1.82967 10.8831 1.62045 12.4037C4.10795 9.32368 9.49995 9.99468 9.56595 10.0037C9.68622 10.0197 9.79656 10.0789 9.8764 10.1702C9.95625 10.2616 10.0002 10.3789 9.99995 10.5002V11.8697L14.2565 8.00018L9.99995 4.13068Z"
        fill="#72777B"
      />
    </svg>
  );
}
