import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import * as XLSX from "xlsx";
import moment from "moment";
import { formatMoneyVND } from ".";
import { ar } from "date-fns/locale";
import { toast } from "react-toastify";

function convertNumberToColumnName(number) {
  const base = "A".charCodeAt(0); // Chữ cái 'A' tương ứng với mã ASCII 65
  let columnName = "";

  while (number > 0) {
    const modulo = (number - 1) % 26;
    columnName = String.fromCharCode(base + modulo) + columnName;
    number = parseInt((number - modulo) / 26, 10);
  }

  return columnName;
}

function exportQcExcel(data) {
  if (data.length > 0) {
    var row = [
      [
        "",
        "",
        "Tần suất",
        "Tần suất",
        "Tần suất",
        ...(data[0].qc_evaluetes[0].area_results ?? [])
          .map((e) => e.area_name)
          .flatMap((element) => Array(4).fill(element)),
      ],
    ]; // là 1 hàng trên excel

    var rowHandle = handleData(data);
    row = [...row, ...rowHandle];

    console.log(row);

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      sheet1.cell("A1").value(row);
      const range = sheet1.usedRange();
      range.style("border", true);
      range.style("horizontalAlignment", "left");

      sheet1.range(`C1:E1`).merged(true).style({
        horizontalAlignment: "center",
        verticalAlignment: "center",

        bold: true,
      });

      (data[0].qc_evaluetes[0].area_results ?? [])
        .map((e) => e.area_name)
        .forEach((element, index) => {
          sheet1
            .range(
              `${convertNumberToColumnName(
                6 + index * 4
              )}1:${convertNumberToColumnName(6 + index * 4 + 3)}1`
            )
            .merged(true)
            .style({
              horizontalAlignment: "center",
              verticalAlignment: "center",

              bold: true,
            });
        });

      for (let i = 1; i <= row.length - 1; i++) {
        console.log(row[i]);
        if (row[i].length > 0) {
          if (!row[i][0].includes(".")) {
            sheet1.row(i + 1).height(30);
            sheet1.row(i + 1).style({
              horizontalAlignment: "center",
              verticalAlignment: "center",
              fontSize: 12,
              bold: true,

              fill: "F6B50A",
            });
          }
        }
      }
      const column = sheet1.column(2);
      column.style({ wrapText: true });
      column.width(30); // set width for each column
      for (let i = 1 + 2; i <= row[0].length; i++) {
        const column = sheet1.column(i);
        column.style({ wrapText: true });
        column.width(10); // set width for each column
      } 
      return workbook.outputAsync().then((res) => {
        saveAs(res, "KetQuaQCTheoChiNhanh.xlsx");
        // onDone(); 
      });
    });
  }
}

const handleData = (data) => {
  console.log(data);
  var row = [];

  data.forEach((e, index) => {
    var item = [];
    item.push(toRoman(index + 1));
    item.push(e.name);
    item.push("Đạt");
    item.push("Không đạt");
    item.push("Tỷ lệ đạt");

    (e.qc_evaluetes[0].area_results ?? [])
      .map((e) => e.area_name)
      .forEach((result) => {
        item.push("Đạt");
        item.push("Điểm");
        item.push("Ghi chú");
        item.push("Hình ảnh");
      });

    row.push(item);

    (e.qc_evaluetes ?? []).map((e, index2) => {
      var item2 = [];
      item2.push(`${toRoman(index + 1)}.${index2 + 1}`);
      item2.push(e.title);
      // tính toán tần suất
      item2.push("");
      item2.push("");
      item2.push("");
      // dữ liệu của chi nhánh
      (e.area_results ?? []).forEach((result) => {
        item2.push(result.done_number);
        item2.push(result.point_total);
        item2.push(result?.note ?? "");
        item2.push(result?.image ?? "");
      });
      row.push(item2);
    });
  });

  return row;
};

function toRoman(num) {
  const romanNumerals = {
    1: "I",
    4: "IV",
    5: "V",
    9: "IX",
    10: "X",
    40: "XL",
    50: "L",
    90: "XC",
    100: "C",
    400: "CD",
    500: "D",
    900: "CM",
    1000: "M",
  };

  let result = "";
  Object.keys(romanNumerals)
    .reverse()
    .forEach((value) => {
      const intValue = parseInt(value, 10);
      while (num >= intValue) {
        result += romanNumerals[value];
        num -= intValue;
      }
    });

  return result;
}

export { exportQcExcel };
