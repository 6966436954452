import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  permisson: {
    data: [],
    status: c.NONE,
 },

  role_permission_detail: {
    data: [],
    status: c.NONE,
  },
  role_permission: {
    data: [],
    status: c.NONE,
  },
  role_list: {
    data: [],
    status: c.NONE,
  },
  user_permission: {
    data: [],
    status: c.NONE,
  },
  info: {
    detail: {},
    status: c.NONE,
  },
};
export function decentralization(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_DECENTRALIZATIONS_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
      case c.GET_ALL_PERMISSIONS_SUCCESS:
        console.log("action >>>", action);
        return {
           ...state,
           permisson: {
               data: action.data,
              status: c.SUCCESS,
           },
        };
     case c.RESET_PERMISSIONS_LIST_STATUS:
        return {
           ...state,
           permisson: {
              status: c.NONE,
           },
        };
  
     case c.GET_ALL_PERMISSIONS_FAILURE:
        return {
           ...state,
           permisson: {
              status: c.FAILURE,
           },
        };

    case c.GET_ALL_ROLE_PERMISSION_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        role_permission: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_ROLE_PERMISSION_DETAIL_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        role_permission_detail: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_ROLE_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        role_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_USER_PERMISSION_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        user_permission: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_DECENTRALIZATIONS_LIST_STATUS:
      return {
        ...state,
        list: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_USER_PERMISSION_LIST_STATUS:
      return {
        ...state,
        user_permission: {
          status: c.NONE,
        },
      };
    case c.RESET_ROLE_PERMISSION_DETAIL_STATUS:
      return {
        ...state,
        role_permission_detail: {
          status: c.NONE,
        },
      };
    case c.RESET_ROLE_PERMISSION_LIST_STATUS:
      return {
        ...state,
        role_permission: {
          status: c.NONE,
        },
      };
    case c.RESET_ROLE_LIST_STATUS:
      return {
        ...state,
        role_list: {
          status: c.NONE,
        },
      };
    case c.GET_DECENTRALIZATION_SUCCESS:
      return {
        ...state,
        info: {
          detail: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_DECENTRALIZATIONS_FAILURE:
      return {
        ...state,
        list: {
          status: c.FAILURE,
        },
      };
    case c.GET_ALL_ROLE_PERMISSION_FAILURE:
      return {
        ...state,
        role_permission: {
          status: c.FAILURE,
        },
      };
    case c.GET_ALL_ROLE_FAILURE:
      return {
        ...state,
        role_list: {
          status: c.FAILURE,
        },
      };
    case c.GET_ALL_USER_PERMISSION_FAILURE:
      return {
        ...state,
        user_permission: {
          status: c.FAILURE,
        },
      };
    case c.GET_DECENTRALIZATION_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
