import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FilterFilled } from "@ant-design/icons";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input, Select } from "antd";
import { franchiseServices } from "../../../../services/franchiseServices";
import AddReportModal from "./child/AddReportModal";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import ReportModal from "./child/ReportModal";
import PermissionChecker from "../../../../components/PermissionChecker";
const { Search } = Input;

export default function FranchiseReport() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [fileList, setFileList] = useState(null);
  const [loading, setLoading] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [stateFilterCate, setStateFilterCate] = useState(false);
  const [filter, setFilter] = useState({
    customer_id: null,
    category_id: null,
  });

  const [cate, setCate] = useState(null);

  const getReport = async (page, search, cate_id) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getReport(
        `?search=${search ?? ""}&page=${page ?? 1}&category_id=${cate_id ?? ""}`
      );
      setFileList(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const getReportCate = async () => {
    try {
      const response = await franchiseServices.getReportCate();
      setCate(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReport = async (id) => {
    try {
      const response = await franchiseServices.deleteReport(id);
      getReport(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReport();
    getReportCate();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "báo cáo";
    tagA.target = "_blank";
    tagA.click();
  }

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  useEffect(() => {
    getReport(1, filter?.customer_id, filter?.category_id);
  }, [filter]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Khách hàng",
      dataIndex: "customer",
      key: "customer",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <AsyncPaginate
            value={customers}
            placeholder="Tìm kiếm khách hàng"
            loadOptions={getCustomerFranchise}
            isMulti={false}
            closeMenuOnSelect={false}
            onChange={(v) => {
              setFilter({
                ...filter,
                customer_id: v,
              });
              setCustomers(v);
            }}
            additional={{
              page: 1,
            }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 99999,
              }),
              width: "auto",
            }}
            menuPortalTarget={document.body}
            debounceTimeout={500}
            isClearable
            isSearchable
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filter?.customer_id !== null ? "#1677ff" : undefined,
          }}
        />
      ),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Loại báo cáo",
      dataIndex: "report_cate",
      key: "report_cate",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <Select
              showSearch
              placeholder="Loại báo cáo"
              optionFilterProp="children"
              style={{
                width: 300,
              }}
              onChange={(v) => {
                setFilter({
                  ...filter,
                  category_id: v,
                });
                setStateFilterCate(false);
              }}
              filterOption={filterOption}
              options={cate?.map((e) => {
                return {
                  value: e.id,
                  label: e.name,
                };
              })}
            />
            <Button
              onClick={() => {
                setFilter({
                  ...filter,
                  category_id: null,
                });

                setStateFilterCate(false);
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterDropdownOpen: stateFilterCate,
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filter?.category_id !== null ? "#1677ff" : undefined,
          }}
          onClick={() => {
            setStateFilterCate(!stateFilterCate);
          }}
        />
      ),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <a>{moment(text ?? Date.now()).format("DD-MM-YYYY HH:mm")}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) =>
        PermissionChecker({
          decent: reportNumber.permissions?.franchise_report_delete ?? false,
          child: (
            <Popconfirm
                title="Xoá báo cáo ?"
                description="Bạn có chắc chắn muốn xoá báo cáo này chứ ?"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={(e) => {
                  deleteReport(record.key);
                }}
                onCancel={() => {}}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
          ),
        }),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm báo cáo"
          onSearch={(v) => {
            getReport(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_report_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Thêm báo cáo
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: record.data,
              });
            },
          };
        }}
        dataSource={(fileList?.data ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            title: e.title ?? "--:--",
            data: e,
            content: e?.content ?? "--:--",
            file_url: e?.file_url ?? "--:--",
            image_url: e?.image_url ?? "--:--",
            report_cate: e.report_cate?.name ?? "--:--",
            report_cate_id: e.report_cate_id ?? "--:--",
            customer: e.customer?.name ?? "--:--",
            created_at: e.created_at,
          };
        })}
        pagination={{
          total: fileList?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getReport(e.current);
        }}
      />
      <AddReportModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getReport(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <ReportModal
        open={modal.visible && modal.method === "WATCH"}
        dataInput={modal.data}
        onOk={() => {
          getReport(1);

          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
