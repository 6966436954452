import { Modal } from "antd";

export default function DialogYesNo({ visible, title, yes, no }) {


  return (
    <Modal
      title={"Thông báo"}
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20 }}
      onCancel={() => {
        no();
      }}
      onOk={() => {
        yes();
      }}
    >
      <div style={{ padding: "10px", fontSize: "20px" }}>{title}</div>
    </Modal>
  );
}
