/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");


const TypeUnitModal = ({ visible, closeModal, onChoose }) => {
  const customStyles = {
    content: {
      width: "30%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  var list = [
    "Kg",
    "Trái",
    " Đôi",
    "Cái",
    "Lon",
    "Chai",
    "Bịch",
    "Cuộn",
    " Cây",
    "Gói",
    "Thùng",
    "Can",
    "Lốc",
    "Phần",
  ];

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>Chọn nguyên vật liệu</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        {list.map((unit) => {
          return (
            <div
              className="d-flex px-3 py-3"
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={(e) => {
                onChoose(unit);
              }}
            >
              {unit}
            </div>
          );
        })}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
      </div>
    </Modal>
  );
};

export default TypeUnitModal;
