export const menu2 = [
  {
    label: "Nhân viên",

    children: [
      {
        label: "Danh sách nhân viên",
        key: "/staff-temp",
      },
      {
        label: "Đánh giá thử việc",
        key: "/probation-review",
      },
    ],
  },
  {
    label: "Chấm công",
    children: [
      {
        label: "Lịch làm việc công ty",
        key: "/work-chedule",
      },
      {
        label: "Bảng công",
        key: "/job-board",
      },
      {
        label: "Bảng lương",
        key: "/salary",
      },
      {
        label: "Lịch sử chấm công",
        key: "/timekeeping-history",
      },
      {
        label: "Xếp ca và Chấm công",
        key: "/timekeeping-table",
      },
      {
        label: "Lịch sử chấm hộ",
        key: "/from-user-history-page",
      },
    ],
  },
  {
    label: "Yêu cầu",
    children: [
      {
        label: "Nhân sự",
        key: "/personnel-form",
      },
      {
        label: "Vận hành",
        key: "/operate-form",
      },
      {
        label: "Báo cáo",
        key: "/report-form",
      },
    ],
  },
  {
    label: "Chi nhánh",
    key: "/area-page",
  },
  {
    label: "Biên bản/TT",
    key: "/incident-page",
  },
  {
    label: "Đào tạo",
    key: "/education-page",
    children: [
      // {
      //   key: "train",
      //   key: "/all-result",
      //   label: "Tổng hợp kết quả",
      //   setOpenKey: ["/report", "/report_"],
      //   class: "report_general",
      // },
      {
        key: "/train/test_peri/report",
        label: "Tổng hợp kết quả",
      },
      {
        label: "Nhóm khoá học",
        key: "/train/group_type",
      },
      // {
      //   key: "train",
      //
      //
      //   label: "Bài Test định kỳ",
      //
      //   key: "/train/term_quiz",
      // },
      {
        label: "Bài Test định kỳ",
        key: "/train/test_peri",
      },

      // {
      //   label: "Đánh giá",
      //   key: "/evaluation",
      // },

      { label: "Bình luận bài học", key: "/lesson/comments" },
      { label: "Bình luận bài viết", key: "/news/comments" },
      { label: "Góp ý", key: "/feedback" },
    ],
  },
  {
    label: "Nhượng quyền",
    key: "franchise",
    children: [
      {
        label: "Danh sách khách hàng",
        key: "/franchise-customer",
      },
      {
        label: "File lưu trữ",
        key: "/franchise-files",
      },
      {
        label: "Báo cáo khách hàng",
        key: "/franchise-reports",
      },
      {
        label: "Trao đổi thông tin",
        key: "/franchise-mails",
      },
      {
        label: "Lịch họp",
        key: "/franchise-meetings",
      },
      {
        label: "Thông tin, tin tức",
        key: "/franchise-post",
      },
      {
        label: "Câu hỏi thường gặp",
        key: "/franchise-help",
      },
      {
        label: "Liên hệ và hỗ trợ",
        key: "/franchise-contact",
      },
      {
        label: "DS đăng ký nhượng quyền",
        key: "/franchise-register",
      },
    ],
  },
  {
    label: "Thiết lập",
    key: "setting",
    children: [
      // {
      //   class: "notification_list",
      //
      //   label: "Thông báo",
      //
      //   key: "/notification",
      // },
      {
        label: "Phòng ban",
        key: "/department",
      },
      {
        label: "Chi nhánh",
        key: "/branch",
      },
      {
        label: "Ca - Nhóm ca",
        key: "/work-shift",
      },
      {
        label: "Cấu hình thưởng",
        key: "/bonus-config",
      },
      {
        label: "Phụ cấp trong hợp đồng",
        key: "/allowances-contract",
      },
      {
        label: "Cài đặt ngày lễ",
        key: "/setup-ot",
      },
      {
        label: "Cài đặt phân quyền",
        key: "/permission",
      },
      {
        label: "Cài đặt yêu cầu",
        key: "/procedure",
      },
      {
        label: "Cài đặt quy trình thử việc",
        key: "/probation",
      },
      {
        label: "Cài đặt mã khuyến mãi",
        key: "/coupon",
      },
      {
        label: "Cài đặt trợ giúp",
        key: "/help-page",
      },
      {
        label: "Lịch sử hệ thống",
        key: "/history-systems",
      },
      {
        label: "Đánh giá QC",
        key: "/qc-evaluate-main",
      },
      {
        label: "Bài viết - Tin tức",
        key: "/news",
      },
    ],
  },
];

export const getPermissionUtils = (name, permissions) => {
  var report = permissions;

  var per = false;

  if (name === "/work-shift") {
    per = report?.shifts_manager ?? false;
  }

  if (name === "/work-chedule") {
    per = report?.calender_shifts_manager ?? false;
  }

  if (name === "/job-board") {
    per = report?.calculate_manager ?? false;
  }

  if (name === "/from-user-history-page") {
    per = report?.calculate_manager ?? false;
  }

  if (name === "/salary") {
    per = report?.salary_manager ?? false;
  }

  if (name === "/timekeeping-history") {
    per = report?.timekeeping_manager ?? false;
  }

  if (name === "/timekeeping-table") {
    per = report?.table_timekeeping_manager ?? false;
  }

  if (name === "/all-result") {
    per = report?.training_all_result ?? false;
  }

  if (name === "/train/test_peri/report") {
    per = report?.training_group ?? false;
  }

  if (name === "/train/group_type") {
    per = report?.training_test ?? false;
  }

  if (name === "/train/test_peri") {
    per = report?.training_post ?? false;
  }

  if (name === "/news") {
    per = report?.training_review ?? false;
  }

  if (name === "/lesson/comments") {
    per = report?.training_cmt ?? false;
  }

  if (name === "/news/comments") {
    per = report?.training_cmt_post ?? false;
  }

  if (name === "/feedback") {
    per = report?.training_help ?? false;
  }

  if (name === "/department") {
    per = report?.department_manager ?? false;
  }

  if (name === "/branch") {
    per = report?.area_manager ?? false;
  }

  if (name === "/bonus-config") {
    per = report?.bonus_config_manager ?? false;
  }

  if (name === "/allowances-contract") {
    per = report?.support_config_manager ?? false;
  }

  if (name === "/setup-ot") {
    per = report?.ot_manager ?? false;
  }

  if (name === "/permission") {
    per = report?.decent_manager ?? false;
  }

  if (name === "/procedure") {
    per = report?.form_manager ?? false;
  }

  if (name === "/coupon") {
    per = report?.coupon_manager ?? false;
  }

  if (name === "/help-page") {
    per = report?.help_manager ?? false;
  }

  if (name === "/history-systems") {
    per = report?.history_system_manager ?? false;
  }

  if (name === "/qc-evaluate-main") {
    per = report?.personal_form_manager ?? false;
  }

  if (name === "/franchise-customer") {
    per = report?.operate_form_manager ?? false;
  }

  if (name === "/report-form") {
    per = report?.report_form_manager ?? false;
  }

  if (name === "/franchise-customer") {
    per = report?.franchise_customer_manager ?? false;
  }

  if (name === "/franchise-files") {
    per = report?.franchise_file_manager ?? false;
  }

  if (name === "/franchise-reports") {
    per = report?.franchise_report_manager ?? false;
  }

  if (name === "/franchise-mails") {
    per = report?.franchise_mail_manager ?? false;
  }

  if (name === "/franchise-meetings") {
    per = report?.franchise_meeting_manager ?? false;
  }

  if (name === "/franchise-post") {
    per = report?.franchise_post_manager ?? false;
  }

  if (name === "/franchise-help") {
    per = report?.franchise_help_manager ?? false;
  }

  if (name === "/franchise-contact") {
    per = report?.franchise_contact_manager ?? false;
  }

  if (name === "/franchise-register") {
    per = report?.franchise_register_manager ?? false;
  }

  ///

  if (name === "/staff-temp") {
    per = report?.staff_manager ?? false;
  }

  if (name === "/personnel-form") {
    per = report?.personal_form_manager ?? false;
  }
  if (name === "/operate-form") {
    per = report?.operate_form_manager ?? false;
  }

  if (name === "/report-form") {
    per = report?.report_form_manager ?? false;
  }

  if (name === "/incident-page") {
    per = report?.incident_manager ?? false;
  }

  if (name === "/area-page") {
    per = report?.area_manager ?? false;
  }

  if (name === "Đánh giá từ khách hàng") {
    per = true;
  }

  if (name === "/qc-evaluate-main") {
    per = report?.qc_evaluate_main_manager ?? false;
  }

  if (name === "/probation") {
    per = report?.probation_manager ?? false;
  }

  if (name === "/probation-review") {
    per = report?.probation_manager ?? false;
  }

  return per;
};
