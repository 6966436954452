import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import Item from "./child/Item";
import { Link } from "react-router-dom";
import Delete from "./child/Delete";
import ModalUID from "../../components/ModalUID";
import { constants as c } from "../../constants";
import { useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { feedbackAction } from "../../actions/feedbackActions";
import SearchInput from "../../components/SeachInput";
import { branchAction } from "../../actions/branchActions";

export default function StaffPage() {
  const dispatch = useDispatch();

  const feedback = useSelector((state) => state.feedback.list);
  const [filterArea, setfilterArea] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const badges = useSelector((state) => state.app.badges);
  const branchList = useSelector((state) => state.branch.branch_list);

  const des = badges.decentralisations || {};

  const post_create = des.post_create;
  const post_update = des.post_update;
  const post_delete = des.post_delete;

  const [state, setState] = useState({
    modal: {
      componentAction: null,
      title: "",
      method: null,
    },
  });

  function onChangeSearch(value) {
    setSearchValue(value);
  }

  function onSubmitSearch(value) {
    dispatch(feedbackAction.getAll(`?search=${value}`));
    setSearchValue(value);
  }

  useEffect(() => {
    if (feedback.status === c.NONE) {
      dispatch(feedbackAction.getAllFeedback());
    }
    if (branchList.status === c.NONE) {
      dispatch(branchAction.getAllBranchs());
    }
  });
  

  const { modal } = state;

  function handleDelete(id) {
    setState({
      ...state,
      delete_id: id,

      modal: {
        componentAction: <Delete></Delete>,
        title: "Xóa bài viết",
        method: "DELETE",
      },
    });
  }

  function onChangeArea(e) {
   var { value } = e.target;
   var query = "?params=true";
   query = query + (value === "" ? "" : `?area_id=${value}`);
   dispatch(feedbackAction.getAllFeedback(value === "" ? "" : `?area_id=${value}`));
   setfilterArea(value);
 }

  function handleDelModal() {
    dispatch(feedbackAction.deletefeedback(state.delete_id));
  }
  function handleClickItem(index) {
    dispatch(feedbackAction.getAllFeedback(`?page=${index}`));
  }
  const perPage = feedback.meta?.per_page;
  const currentPage = feedback.meta?.current_page;
  return (
    <>
      <div className="container-fluid">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="h4 title_content mb-0 text-gray-800">Góp ý</h4>{" "}
        </div>

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table no-footer dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting_disabled sorting_asc"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="STT"
                      >
                        STT
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Họ và tên"
                      >
                        MSNV
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Họ và tên"
                      >
                        Tên nhân viên
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Phòng ban"
                        style={{ maxWidth: "200px" }}
                      >
                        <select
                          name="deparment_id"
                          value={filterArea}
                          onChange={onChangeArea}
                          type="text"
                          class="form-control"
                          id="txtPhone_branch"
                        >
                          <option value="" selected>
                            --Chi nhánh--
                          </option>
                          <option value="">Tất cả</option>
                          {branchList.data?.map((v, i) => {
                            return <option value={v.id}>{v.name}</option>;
                          })}
                        </select>
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Tên tài khoản"
                      >
                        Nội dung đánh giá
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Số điện thoại"
                      >
                        Hình ảnh
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Số điện thoại"
                      >
                        Ngày tạo
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {feedback.data?.map((v, i) => {
                      return (
                        <Item
                          v={v}
                          key={i}
                          index={(currentPage - 1) * perPage + i + 1}
                        ></Item>
                      );
                    })}
                  </tbody>
                </table>

                {feedback.meta && feedback.meta?.links.length > 0 && (
                  <Pagination
                    to={feedback.meta?.to}
                    handleClickItem={handleClickItem}
                    links={feedback?.meta.links}
                    lastPage={feedback?.meta.last_page}
                    currentPage={feedback?.meta.current_page}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalUID
          method={modal.method}
          title={modal.title}
          handleOnSave={handleDelModal}
        >
          {" "}
          {modal.componentAction}{" "}
        </ModalUID>
      </div>
    </>
  );
}
