/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { constants } from "../../../../constants";
import { shiftServices } from "../../../../services/shiftServices";
import { areaServices } from "../../../../services/areaServices";
import { appServices } from "../../../../services/appServices";
import { DatePicker, Uploader } from "rsuite";
import AddProductModal from "./AddProductModal";
import { procedureServices } from "../../../../services/procedureServices";
import { formatMoneyVND, formatNumberV2 } from "../../../../helpers";
import TypeShiftsModal from "./TypeShiftsModal";
import { Modal } from "antd";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const FORMAT_DATE = "dd-MM-yyyy";

const ReportCreateModal = ({ visible, type, title, closeModal, onSuccess }) => {
  const areas = useSelector((state) => state.area.areas);
  const userInfo = useSelector((state) => state.user.profile);
  const [listStaffCreate, setListStaffCreate] = useState([]);
  const [areaChoose, setAreaChoose] = useState(null);
  const [areaToChoose, setAreaToChoose] = useState(null);
  const [departmentChoose, setDepartmentChoose] = useState(null);
  const [listDataImage, setListDataImage] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [object, setObject] = useState({});

  const [data, setData] = useState({
    staff_id: null,
    type: null,
    title: null,
    reason: null,
    file_url: null,
    images: [],
    money: null,
    scale: null,
    area_id: null,
    content: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(type);
    setListStaffCreate({
      value: userInfo.id,
      label: `${userInfo.name}`,
    });
    setAreaChoose({
      value: userInfo.area_id,
      label: `${
        areas.data?.filter((area) => area.id == userInfo?.area_id)[0]?.name ||
        "Chọn chi nhánh"
      }`,
    });
    setData({
      title: title,
      staff_id: userInfo.id,
      staff_propose_id: userInfo.id,
      staff_handover_id: userInfo.id,
      type: type,
      reason: null,
      file_url: null,
      images: [],
      money: null,
      scale: null,
      area_id: null,
      content: null,
      report_date: Date.now(),
      report_budget: [],
      list_product: [],
    });
    setListDataImage([]);
    setFileList([]);
    setFile([]);
    setObject({
      is_hot: type === constants.TYPE_PROPOSE_REPAIR ? false : null,
      payment_method:
        type === constants.TYPE_PROPOSE_PAYMENT ? "Tiền mặt" : null,
      area: areas.data?.filter((area) => area.id == userInfo?.area_id)[0]?.name,
      list_product: [],
    });
  }, [type, visible]);

  const customStyles = {
    content: {
      width: "50%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const [modal, setModal] = useState({
    title: "",
    type: null,
    visible: false,
    product: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", visible: false, product: null });
  };

  const fetchStaffsManagerCreate = async (
    search,
    __loadedOptions,
    { page }
  ) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAreas = async (search, __loadedOptions, { page }) => {
    try {
      const res = await areaServices.fetchAreas();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const [fileList, setFileList] = React.useState([]);
  const [file, setFile] = React.useState([]);
  const uploader = React.useRef();
  const uploaderFile = React.useRef();

  async function sendImage(image, name) {
    var imageData = null;
    if (image) {
      imageData = new FormData();
      imageData.append("image", image);
    }
    var resImage = await appServices.uploadImg(imageData);

    listDataImage.push(resImage.data?.image_url);

    setListDataImage(listDataImage);
  }

  async function sendFile(file, name) {
    console.log(file);
    if (file == null) return;
    var imageData = null;
    if (file) {
      imageData = new FormData();
      imageData.append("image", file);
    }
    var resImage = await appServices.uploadDocs(imageData);
    fileUrl.push(resImage.data?.image_url);
    setFileUrl(fileUrl);
  }

  const getTotalExp = () => {
    var total = 0.0;
    (data.report_budget ?? []).forEach((e) => {
      total =
        total +
        (e.quantity || 0) * (e.price || 0).toString().replaceAll(".", "");
    });

    return total;
  };

  function getTotalOtherRevenue() {
    let total = 0;
    const listProduct = object.list_product ?? [];

    for (const element of listProduct) {
      total +=
        (element?.quantity ?? 0) *
        parseFloat((element?.money ?? 0).toString().replaceAll(".", ""));
    }

    console.log(total);
    return total;
  }

  const getTotal = () => {
    var total = 0.0;
    (data.report_budget ?? []).forEach((e) => {
      total =
        total +
        (e.quantity || 0) * (e.price || 0).toString().replaceAll(".", "");
    });

    return (
      parseFloat((data.early_day_fund || 0).toString().replaceAll(".", "")) +
      parseFloat((data.supplemental_fund || 0).toString().replaceAll(".", "")) +
      -total
    );
  };

  const infoProduct = (type) => {
    switch (type) {
      case constants.TYPE_REPORT_BUDGET:
        return (
          <div>
            {(data?.report_budget || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Nội dung chi:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{e?.quantity ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Đơn giá:</div>
                      <div>{formatNumberV2(e?.price || "")}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tổng tiền:</div>
                      <div>
                        {formatNumberV2(
                          (e?.price ?? 0).toString().replaceAll(".", "") *
                            (e?.quantity ?? 0)
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = data?.report_budget;
                      list.splice(index, 1);
                      setData({ ...data, report_budget: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );
    }
  };

  const infoProductRevenue = () => {
    return (
      <div>
        {(object?.list_product || []).map((e, index) => {
          return (
            <div className="d-flex align-item-center justify-content-between mt-3">
              <div className="item-product col-11">
                <div className="d-flex justify-content-between">
                  <div className="col-2 text-product">Nội dung:</div>
                  <div>{e?.name ?? "--:--"}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-2 text-product">Số lượng:</div>
                  <div>{`${e?.quantity ?? "--:--"}`}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-2 text-product">Đơn giá:</div>
                  <div>{e?.money}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-2 text-product">Tổng tiền:</div>
                  <div>
                    {formatNumberV2(
                      (e?.money ?? 0).toString().replaceAll(".", "") *
                        (e?.quantity ?? 0)
                    )}
                  </div>
                </div>
              </div>
              <button
                style={{
                  border: "1px solid",
                  height: "30px",
                }}
                class="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  var list = object?.list_product;
                  list.splice(index, 1);
                  setObject({ ...object, list_product: list });
                  setData({ ...data, other_revenue: getTotalOtherRevenue() });
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
                xóa
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const infoForm = (type) => {
    switch (type) {
      case constants.TYPE_REPORT_IN_DAY:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tình hình nhân sự:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.staff_status ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      staff_status: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Sự cố/góp ý khách hàng:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.problem ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      problem: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chất lượng hàng hóa:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.quality ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      quality: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Góp ý/cải tiến:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.feedback ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      feedback: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Khó khăn đang gặp phải:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.hard ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      hard: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Mức độ thực hiện tiêu chuẩn 4C:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.c4 ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      c4: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Nhân viên thái độ xấu với khách:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.bad_staff ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      bad_staff: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Nhân viên vi phạm nội quy:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.violate_rules_staff ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      violate_rules_staff: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Món hết vào giờ cao điểm:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.out_dishes ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      out_dishes: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Doanh thu:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.revenue ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      revenue: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_REVENUE:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ca làm:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.shift_type ?? ""}
                  onClick={(e) => {
                    setModal({
                      title: `Chọn ca làm`,
                      method: "SHIFT_TYPE",
                      visible: true,
                    });
                  }}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng doanh thu:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.revenue ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      revenue: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tiền mặt:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.cash ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cash: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chuyển khoản:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.money_transfer ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      money_transfer: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Quẹt thẻ:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.money_swipe ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      money_swipe: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Grab:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.grap ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      grap: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Shopee Food:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.shopee ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      shopee: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Beamin:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.beamin ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      beamin: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Bee Food:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.bee ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      bee: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Doanh thu khác:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.other_revenue ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      other_revenue: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng chi:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.expenditure ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      expenditure: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Doanh thu tiền mặt còn lại:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.revenue_remain_cash ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      revenue_remain_cash: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_BUDGET:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <label
              style={{ fontSize: "16px", fontWeight: "700", color: "red" }}
            >
              I. Doanh thu:
            </label>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tồn doanh thu TM đầu ngày:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.cash_begin_day ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cash_begin_day: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Doanh thu bán hàng TM trong ngày:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.cash_in_day ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cash_in_day: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-2 mb-3 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thu tiền mặt khác
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm thông tin`,
                    method: "ADD_PRODUCT",
                    type: null,
                    visible: true,
                  });
                }}
                class="btn btn-info"
              >
                Thêm thông tin +
              </button>
            </div>
            {infoProductRevenue()}
            <div className="row my-4">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng thu tiền mặt khác:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatMoneyVND(data?.other_revenue ?? 0, 2)}
                  onChange={(e) => {}}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Nộp doanh thu về công ty:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.tip ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      tip: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng tiền:
              </div>
              <div
                className="col-9"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                {formatMoneyVND(
                  parseFloat(
                    (data.cash_begin_day ?? "0").toString().replaceAll(".", "")
                  ) +
                    parseFloat(
                      (data.cash_in_day ?? "0").toString().replaceAll(".", "")
                    ) +
                    parseFloat(
                      (data.other_revenue ?? "0").toString().replaceAll(".", "")
                    ) -
                    parseFloat(
                      ((data.tip ?? "") == "" ? "0" : data.tip)
                        .toString()
                        .replaceAll(".", "")
                    )
                )}
              </div>
            </div>

            <label
              style={{ fontSize: "16px", fontWeight: "700", color: "red" }}
            >
              II. Quỹ:
            </label>
            <div className="row mb-3 mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng quỹ đầu ngày:
              </div>
              <div className="col-9">
                <input
                  name="early_day_fund"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.early_day_fund ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      early_day_fund: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3 mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Bổ sung quỹ:
              </div>
              <div className="col-9">
                <input
                  name="supplemental_fund"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.supplemental_fund ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      supplemental_fund: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-2 mb-3 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin chi
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm thông tin`,
                    method: "ADD_PRODUCT",
                    type: type,
                    visible: true,
                  });
                }}
                class="btn btn-info"
              >
                Thêm thông tin +
              </button>
            </div>
            {infoProduct(type)}
            <div className="row mb-3 mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi quỹ tiền mặt trong ngày:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatNumberV2(getTotalExp())}
                  onChange={(e) => {
                    setData({
                      ...data,
                      expenditure: formatNumberV2(e.target.value),
                    });
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tồn quỹ tiền mặt cuối ngày:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatMoneyVND(getTotal())}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_INVENTORY:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_INVOICE:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );

      case constants.TYPE_REPORT_STAFF_DAY:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày báo cáo:
              </div>
              <div className="col-5">
                <DatePicker
                  value={data?.report_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setData({ ...data, report_date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Sự cố công việc trong ngày:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.problem ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      problem: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Mức độ hoàn thành công việc:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.process_task ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      process_task: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Góp ý/phản ánh của khách hàng:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.feedback ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      feedback: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Khó khăn đang gặp phải:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.hard ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      hard: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tình hình nhân sự:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.staff_status ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      staff_status: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Món hết vào giờ cao điểm:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.out_dishes ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      out_dishes: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Doanh thu:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={data?.revenue ?? ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      revenue: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ghi chú:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.content ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  const createReportForm = async () => {
    var check = validatorForm(type);

    if (check == false) {
      return;
    }

    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });

    await Promise.all([
      ...fileList.map((e) => {
        const file = e.blobFile;
        return sendImage(file);
      }),
      ...file.map((e) => {
        const up = e.blobFile;
        return sendFile(up);
      }),
    ]).catch((error) => {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      console.error("Có lỗi xảy ra khi chạy các hàm gọi API", error);
    });

    console.log(listDataImage);

    setData({ ...data, content: JSON.stringify(object) });

    var content = {
      area: areaChoose?.label || "",
      staff_status: object.staff_status,
      problem: object.problem,
      quality: object.quality,
      feedback: object.feedback,
      hard: object.hard,
      c4: object.c4,
      bad_staff: object.bad_staff,
      violate_rules_staff: object.violate_rules_staff,
      out_dishes: object.out_dishes,
      content: object.content,
      shift_type: object.shift_type,
      process_task: object.process_task,
      list_product: (object.list_product ?? []).map((e) => {
        return {
          name: e?.name,
          quantity: e?.quantity,
          money: (e?.money || "").toString().replaceAll(".", ""),
        };
      }),

      ///////////////
      area_to: areaToChoose?.label || "",
      department: departmentChoose?.label || "",
      money: (object.money || "").toString().replaceAll(".", ""),
      shifts: object.shifts?.name,
      reason: object.reason,
      title: object.title,
      payment_method: object.payment_method,
      name_account_bank: object.name_account_bank,
      name_bank: object.name_bank,
      bank_number: object.bank_number,
      date: moment(object?.date).format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS),
    };

    var body = {
      title: data.title,
      tip: (data.tip || "").toString().replaceAll(".", ""),
      area_id: areaChoose?.value || "",
      staff_id: listStaffCreate.value,
      shift_type:
        object.shift_type == "SÁNG" ? 0 : object.shift_type == "CHIỀU" ? 1 : "",
      staff_propose_id: listStaffCreate.value,
      staff_handover_id: listStaffCreate.value,
      report_date: moment(data?.report_date).format(
        constants.FORMAT_YYYY_MM_DD_HH_MM_SS
      ),
      report_budget: (data.report_budget ?? []).map((e) => {
        return {
          name: e?.name,
          quantity: e?.quantity,
          price: (e?.price || "").toString().replaceAll(".", ""),
        };
      }),
      revenue: (data.revenue || "").toString().replaceAll(".", ""),
      cash: (data.cash || "").toString().replaceAll(".", ""),
      money_transfer: (data.money_transfer || "")
        .toString()
        .replaceAll(".", ""),
      money_swipe: (data.money_swipe || "").toString().replaceAll(".", ""),
      grap: (data.grap || "").toString().replaceAll(".", ""),
      shopee: (data.shopee || "").toString().replaceAll(".", ""),
      beamin: (data.beamin || "").toString().replaceAll(".", ""),
      bee: (data.bee || "").toString().replaceAll(".", ""),
      other_revenue: (data.other_revenue || "").toString().replaceAll(".", ""),
      early_day_fund: (data.early_day_fund || "")
        .toString()
        .replaceAll(".", ""),
      supplemental_fund: (data.supplemental_fund || "")
        .toString()
        .replaceAll(".", ""),
      expenditure: getTotalExp(),
      revenue_remain_cash: (data.revenue_remain_cash || "")
        .toString()
        .replaceAll(".", ""),
      cash_begin_day: (data.cash_begin_day || "")
        .toString()
        .replaceAll(".", ""),
      cash_in_day: (data.cash_in_day || "").toString().replaceAll(".", ""),
      cash_last_day: getTotal(),
      type: data.type,
      file_url: fileUrl,
      images: listDataImage,
      content: JSON.stringify(content),
    };

    try {
      const res = await procedureServices.createReportForm({
        data: body,
      });

      if (res?.success === true) {
        onSuccess();
        setFileUrl([]);
        setListDataImage([]);
        toast.success("Thành công");
      } else {
        toast.error(res?.msg || "");
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  const validatorForm = (type) => {
    var content = {
      list_product: object.list_product,
      staff_status: object.staff_status,
      problem: object.problem,
      quality: object.quality,
      feedback: object.feedback,
      process_task: object.process_task,
      hard: object.hard,
      content: object.content,
      area: areaChoose?.label || "",
      area_to: areaToChoose?.label || "",
      department: departmentChoose?.label || "",
      money: (object.money || "").toString().replaceAll(".", ""),
      shifts: object.shifts?.name,
      shift_type: object.shift_type,
      title: object.title,
      date:
        object.date == null
          ? null
          : moment(object?.date).format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS),
    };

    if (type === constants.TYPE_REPORT_IN_DAY) {
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
      if ((data?.report_date || "") === "") {
        toast.error("Chưa chọn ngày báo cáo");
        return false;
      }
      if ((content?.staff_status || "") === "") {
        toast.error("Chưa nhập tình hình nhân sự");
        return false;
      }
      if ((content?.problem || "") === "") {
        toast.error("Chưa nhập sự cố/góp ý khách hàng");
        return false;
      }

      if ((content?.quality || "") === "") {
        toast.error("Chưa nhập chất lượng hàng hóa");
        return false;
      }

      if ((content?.feedback || "") === "") {
        toast.error("Chưa nhập góp ý/cải tiến");
        return false;
      }

      if ((content?.hard || "") === "") {
        toast.error("Chưa nhập khó khăn đang gặp phải");
        return false;
      }

      if ((data?.revenue || "") === "") {
        toast.error("Chưa nhập doanh thu");
        return false;
      }
    }

    if (type === constants.TYPE_REPORT_REVENUE) {
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
      if ((data?.report_date || "") === "") {
        toast.error("Chưa chọn ngày báo cáo");
        return false;
      }
      if ((content?.shift_type || "") === "") {
        toast.error("Chưa chọn ca làm");
        return false;
      }
      if ((data?.revenue || "") === "") {
        toast.error("Chưa nhập tổng doanh thu");
        return false;
      }

      if ((data?.cash || "") === "") {
        toast.error("Chưa nhập tiền mặt");
        return false;
      }

      if ((data?.money_transfer || "") === "") {
        toast.error("Chưa nhập chuyển khoản");
        return false;
      }

      if ((data?.money_swipe || "") === "") {
        toast.error("Chưa nhập quyẹt thẻ");
        return false;
      }

      if ((data?.grap || "") === "") {
        toast.error("Chưa nhập tiền grab");
        return false;
      }

      if ((data?.shopee || "") === "") {
        toast.error("Chưa nhập tiền shopee food");
        return false;
      }

      if ((data?.beamin || "") === "") {
        toast.error("Chưa nhập tiền beamin");
        return false;
      }

      if ((data?.bee || "") === "") {
        toast.error("Chưa nhập tiền Bee Food");
        return false;
      }

      if ((data?.other_revenue || "") === "") {
        toast.error("Chưa nhập doanh thu khác");
        return false;
      }

      if ((data?.expenditure || "") === "") {
        toast.error("Chưa nhập tổng chi");
        return false;
      }

      if ((data?.revenue_remain_cash || "") === "") {
        toast.error("Chưa nhập doanh thu tiền mặt còn lại");
        return false;
      }
    }

    if (type === constants.TYPE_REPORT_INVENTORY) {
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
      if ((data?.report_date || "") === "") {
        toast.error("Chưa chọn ngày báo cáo");
        return false;
      }
      if ((content?.content || "") === "") {
        toast.error("Chưa nhập ghi chú");
        return false;
      }
    }

    if (type === constants.TYPE_REPORT_INVOICE) {
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
      if ((data?.report_date || "") === "") {
        toast.error("Chưa chọn ngày báo cáo");
        return false;
      }
      if ((content?.content || "") === "") {
        toast.error("Chưa nhập ghi chú");
        return false;
      }
    }
  };

  return (
    <Modal
      title={title ?? "Thông tin yêu cầu"}
      centered
      open={visible}
      width={"60%"}
      style={{ top: 20 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <div className="ml-4">
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-1">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Người lập phiếu
              </div>
              <div className="col-9">
                <AsyncPaginate
                  value={listStaffCreate}
                  loadOptions={fetchStaffsManagerCreate}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  onChange={setListStaffCreate}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 99999,
                    }),
                  }}
                  menuPortalTarget={document.body}
                  debounceTimeout={500}
                  isClearable
                  isSearchable
                />
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          {infoForm(type)}
        </div>
        <Uploader
          multiple
          headers={{ token: tokenInfo, "Content-Type": "multipart/form-data" }}
          listType="picture"
          fileList={fileList}
          autoUpload={false}
          onChange={setFileList}
          onSuccess={(e) => {}}
          ref={uploader}
          action={`${constants.API_URL}/upload_v2/files?type=report`}
          accept=".png,.jpeg,.jpg,.webp"
        >
          <button
            style={{ height: "66px", width: "66px", justifyContent: "center" }}
          >
            <div>
              <CameraRetroIcon />
              <div style={{ fontSize: "10px" }}>Chọn ảnh</div>
            </div>
          </button>
        </Uploader>
        <div style={{ height: "10px" }}></div>
        <Uploader
          multiple={true}
          headers={{ token: tokenInfo, "Content-Type": "multipart/form-data" }}
          fileList={file}
          autoUpload={false}
          onChange={setFile}
          onSuccess={(e) => {}}
          ref={uploaderFile}
          action={`${constants.API_URL}/upload/docs`}
          locale={{
            uploadFile: "Upload file",
            upload: "File đính kèm",
            uploading: "Uploading...",
            removeFile: "Remove file",
            retry: "Retry",
            cancel: "Cancel",
            drag: "Drag file to here",
            choose: "Choose file",
            invalidType: "Invalid file type",
            invalidSize: "File size too large",
            uploadFailed: "Upload failed",
            retryUpload: "Retry upload",
            uploadSuccess: "Upload success",
          }}
        />
        <div
          className="my-4"
          style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
        ></div>

        <div className="my-4"></div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            createReportForm();
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
      <AddProductModal
        visible={modal.visible && modal.method === "ADD_PRODUCT"}
        productInput={modal.product}
        title={modal.title}
        type={modal?.type}
        onSave={(e) => {
          if (modal.type == null) {
            var list = object.list_product;
            list.push(e);
            setObject({ ...object, list_product: list });
            setData({ ...data, other_revenue: getTotalOtherRevenue() });
            return;
          }
          var list = data.report_budget;
          list.push(e);
          setData({ ...data, report_budget: list });
        }}
        closeModal={handleCloseModal}
      ></AddProductModal>
      <TypeShiftsModal
        visible={modal.visible && modal.method === "SHIFT_TYPE"}
        onChoose={(e) => {
          setObject({ ...object, shift_type: e });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></TypeShiftsModal>
    </Modal>
  );
};

export default ReportCreateModal;
