/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider, DatePicker, Modal, Switch } from "antd";
import { Button, Form, Input, Select } from "antd";
import viVN from "antd/locale/vi_VN";
import dayjs from "dayjs";
import { formatMoneyVND } from "../../../helpers";

const StaffStatusModal = ({
  open,
  closeModal,
  onOk,
  listChangeInput,
  supportConfig,
}) => {
  const areaId = useSelector((state) => state.area.areaId);
  const areas = useSelector((state) => state.area.areas);
  const departmentList = useSelector((state) => state.staff.department_list);
  const departments = useSelector((state) => state.area.departments);
  const [listChange, setListChange] = useState(null);

  const disabledDate = (current) => {
    // Disable days before today
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    if (listChangeInput != null) {
      console.log(listChangeInput);
      console.log(supportConfig);

      setListChange(listChangeInput);
    } else {
      //  setEffectiveDate(dayjs(Date.now()));
    }
  }, [open]);

  return (
    <Modal
      title={"Chọn ngày hiệu lực sửa đổi"}
      centered
      width={"600px"}
      open={open}
      onOk={(e) => {
        onOk(listChange);
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        <span style={{ fontSize: 14, color: "red" }}>
          Bạn vừa thay đổi một số thông tin nhân viên sau:
        </span>
        <div style={{ height: "10px" }}></div>
        {(listChange ?? []).map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-content-start align-items-center flex-column"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #e8e8e8",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex justify-content-start align-items-center">
                <span>{item.content}</span>
              </div>
              <div style={{ width: "15px" }} />
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <span>Ngày hiệu lực</span>
                <div style={{ width: "15px" }} />
                <ConfigProvider locale={viVN}>
                  <DatePicker
                    value={item.effective_date}
                    disabledDate={disabledDate}
                    onChange={(value) => {
                      setListChange((prevListChange) => {
                        const updatedList = [...prevListChange];
                        updatedList[index] = {
                          ...updatedList[index],
                          effective_date: value,
                        };
                        return updatedList;
                      });
                    }}
                    format={"DD-MM-YYYY"}
                  />
                </ConfigProvider>
              </div>
              <div style={{ width: "15px" }} />
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <span>Tạo bảng lương</span>
                <div style={{ width: "15px" }} />
                <Switch
                  checked={item.is_save_salary_table}
                  onChange={(v) => {
                    setListChange((prevListChange) => {
                      const updatedList = [...prevListChange];
                      updatedList[index] = {
                        ...updatedList[index],
                        is_save_salary_table: v,
                      };
                      return updatedList;
                    });
                  }}
                />
              </div>
            </div>
          );
        })}

        <div style={{ height: "10px" }}></div>
      </div>
    </Modal>
  );
};

export default StaffStatusModal;
