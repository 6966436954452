import React, { useState } from "react";
import moment from "moment";
import { constants } from "../../../../constants";
import { formatMoneyVND } from "../../../../helpers";
import { useSelector } from "react-redux";

export default function FormItem({
  data,
  index,
  isSelected,
  onChoose,
  onClick,
  onClickStaffApprove,
  onClickStaffViewer,
  accept,
  cancel,
}) {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  const getReasonTitle = (type) => {
    if (type == constants.TYPE_ANNUAL_LEAVE) {
      return "Nghỉ phép năm";
    }

    if (type == constants.TYPE_UNPAID_LEAVE) {
      return "Nghỉ ko lương";
    }

    if (type == constants.TYPE_COMPASSIONATE_LEAVE) {
      return "Nghỉ đám tang người thân";
    }

    if (type == constants.TYPE_MARRIAGE_LEAVE) {
      return "Nghỉ kết hôn";
    }
    return "";
  };

  const infoForm = (type) => {
    var formData;
    if (data?.content != null && data?.content != "") {
      formData = JSON.parse(data?.content);
    }

    switch (type) {
      case constants.TYPE_PROPOSE_REWARD:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Nội dung: </span>
              <span>{formData?.content ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức thưởng: </span>
              <span>{formatMoneyVND(formData?.price ?? 0)}</span>
            </p>
          </span>
        );

      case constants.TYPE_SUPPORT:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Loại phụ cấp: </span>
              <span>{formData?.support_type ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức phụ cấp: </span>
              <span>{`${data.support?.title ?? ""} - ${formatMoneyVND(
                data.support?.value ?? 0
              )}đ`}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_ADVANCEMENT:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vị trí mới: </span>
              <span>{formData?.position_new ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức lương mới: </span>
              <span>{formatMoneyVND(formData?.money_new ?? 0)}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_ADJUST_SALARY:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức lương cũ: </span>
              <span>{formatMoneyVND(formData?.money_old ?? 0)}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức lương mới: </span>
              <span>{formatMoneyVND(formData?.money_new ?? 0)}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_DISCIPLINE:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_TRANSFER:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Nhân viên: </span>
              <span>{data?.staff_propose?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Chi nhánh chuyển: </span>
              <span>{formData?.area_new ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Phòng ban chuyển: </span>
              <span>{formData?.department_new ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Mức lương mới: </span>
              <span>{formatMoneyVND(formData?.money_new ?? 0)}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_RESIGNATION:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Ngày nghỉ việc: </span>
              <span>
                {" "}
                {moment(formData?.date_resignation, "YYYY-MM-DD").format(
                  constants.FORMAT_DD_MM_YYYY
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Người ban giao: </span>
              <span>{formData?.staff_handover ?? "---:---"}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_RECRUITMENT:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
              <span>{data?.area?.name ?? "---:---"}</span>
            </p>
          </span>
        );

      case constants.TYPE_PROPOSE_UNIFORM:
        return ( 
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
              <span>{data?.area?.name ?? "---:---"}</span>
            </p>
          </span>
        );

      case constants.TYPE_OVERTIME:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Ca làm: </span>
              <span>{data?.timekeeping?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vào ca: </span>
              <span>
                {moment(new Date(data.from)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ra ca: </span>
              <span>
                {moment(new Date(data.to)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
          </span>
        );

      case constants.TYPE_BUSINESS_TRIP:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Địa điểm: </span>
              <span>{data?.another ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ca làm: </span>
              <span>{data?.timekeeping?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vào ca: </span>
              <span>
                {moment(new Date(data.from)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ra ca: </span>
              <span>
                {moment(new Date(data.to)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
          </span>
        );

      case constants.TYPE_ADVANCE_SALARY:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Số tiền ứng: </span>
              <span>{formatMoneyVND(data?.money ?? 0)}</span>
            </p>
          </span>
        );

      case constants.TYPE_LEAVE:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Loại nghỉ phép: </span>
              <span>{getReasonTitle(data.reason_type)}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ca làm: </span>
              <span>{data?.timekeeping?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vào ca: </span>
              <span>
                {moment(new Date(data.from)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ra ca: </span>
              <span>
                {moment(new Date(data.to)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
          </span>
        );

      default:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Ca làm: </span>
              <span>{data?.timekeeping?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vào ca: </span>
              <span>
                {moment(new Date(data.from)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ra ca: </span>
              <span>
                {moment(new Date(data.to)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
          </span>
        );
        break;
    }
  };

  const infoReason = (type) => {
    var formData;
    if (data?.content != null && data?.content != "") {
      formData = JSON.parse(data?.content);
    }

    switch (type) {
      case constants.TYPE_PROPOSE_DISCIPLINE:
        return (
          <div style={{ paddingRight: "10px" }}>
            {formData?.content ?? "---:---"}
          </div>
        );

      case constants.TYPE_PROPOSE_RESIGNATION:
        return (
          <div style={{ paddingRight: "10px" }}>
            {formData?.reason ?? "---:---"}
          </div>
        );

      case constants.TYPE_PROPOSE_UNIFORM:
        return (
          <div style={{ paddingRight: "10px" }}>
            Bấm để xem chi tiết đồng phục
          </div>
        );
      default:
        return <div style={{ paddingRight: "10px" }}>{data?.reason ?? ""}</div>;
        break;
    }
  };

  return (
    <tr
      style={{ height: "120px", borderBottom: "1px solid lightgrey" }}
      onClick={(e) => {
        onClick(data.id);
      }}
    >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onClick={(e) => {
            e.stopPropagation();
            onChoose();
          }}
        />
      </td>
      <td className="text-center">{index + 1}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            {imageError || data.staff?.avatar === null ? (
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f6c23e",
                }}
              >
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {`${data?.staff?.name ?? ""}`.charAt(0).toUpperCase()}
                </div>
              </div>
            ) : (
              <img
                src={data.staff?.avatar}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>

          <div>
            <div style={{ fontWeight: "bold", color: "black" }}>
              {data?.staff?.name ?? ""}
            </div>
            <div style={{ fontSize: "13px" }}>{data.title ?? ""}</div>
          </div>
        </div>
      </td>
      <td>{infoForm(data.type)}</td>
      <td>{infoReason(data.type)}</td>
      <td>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.approvers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffApprove(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.viewers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffViewer(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
      </td>
      <td style={{ textAlign: "right" }}>
        {moment.utc(data?.created_at).format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
      </td>
      {data?.status === 1 && checkPermission(data?.process) ? (
        <td style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                accept();
              }}
            >
              Chấp nhận
            </button>
          </div>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}
