import React from "react";

const Item = ({handleAccept, handleCancel, request, stt, name}) => {
  const checkStatus = (status) => {
    switch (status) {
      case 0:
        return "Từ chối";
      case 1:
        return "Chờ duyệt";
      case 2:
        return "Đồng ý";
      default:
        break;
    }
  };
  return (
    <tr className="hover-product even" style={{backgroundColor: request?.is_checkin ? "#F0FFF0" : "#FFE4E1"}}>
      <td>{stt}</td>
      <td>{name}</td>
      <td>{request?.is_checkin ? <div style={{color: "green"}}>Vào ca</div> :
        <div>Tan ca</div>}</td>
      <td>{request?.time_tracking}</td>
      <td>{request?.reason}</td>
      <td>{checkStatus(request?.status)}</td>
      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-primary btn-sm"
          type="button"
          data-toggle="modal"
          data-target="#modalUID"
          onClick={() =>
            handleAccept(
              `Bạn có chắc chắn đông ý chấm công từ xa của: ${request?.staff?.name}`,
              "ACCEPT",
              request.id
            )
          }
        >
          <i className="fa fa-thumbs-up" aria-hidden="true"></i>
          Đồng ý
        </button>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          data-toggle="modal"
          data-target="#modalUID"
          onClick={() =>
            handleCancel(
              `Bạn có chắc chắn hủy chấm công từ xa của: ${request?.staff?.name}`,
              "DELETE",
              request.id
            )
          }
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          Hủy
        </button>
      </td>
    </tr>
  );
};

export default Item;
