import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input, Tag } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import AddHelpModal from "./child/AddHelpModal";
import PermissionChecker from "../../../components/PermissionChecker";
import { useSelector } from "react-redux";
const { Search } = Input;

export default function FranchiseHelpPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [helps, setHelps] = useState(null);
  const [loading, setLoading] = useState(null);

  const getHelp = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getHelp(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );
      setHelps(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteHelp = async (id) => {
    try {
      const response = await franchiseServices.deleteHelp(id);
      getHelp(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHelp();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "Câu hỏi",
      dataIndex: "title",
      width: 400,
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Từ khoá tìm kiếm",
      key: "tags",
      dataIndex: "tags",
      width: 400,
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag} className="mt-2">
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Nội dung trả lời",
      dataIndex: "content",
      key: "content",
      render: (_) => <p>xem chi tiết</p>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_help_edit ?? false,
            child: (
              <a
                onClick={(e) => {
                  console.log(record);
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: record.data,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}

          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_help_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá câu hỏi ?"
                description="Bạn có chắc chắn muốn xoá câu hỏi này chứ ?"
                onConfirm={() => {
                  deleteHelp(record.key);
                }}
                onCancel={() => {}}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm câu hỏi hỗ trợ"
          onSearch={(v) => {
            getHelp(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_help_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Thêm câu hỏi
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(helps?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            title: e.title ?? "--:--",
            tags: (e.key_word ?? "").split(",") ?? [],
            content: e.content ?? "--:--",
            data: e,
          };
        })}
        pagination={{
          total: helps?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getHelp(e.current);
        }}
      />
      <AddHelpModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getHelp(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
