import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { staffsServices } from "../../../services/staffsServices";
import moment from "moment";
import { Pagination, Tabs } from "antd";
import { formatMoneyVND } from "../../../helpers";

const HistoryStaff = ({ profileInput }) => {
  const [historyContract, setHistoryContract] = useState([]);
  const [totalContracrt, setTotalContract] = useState([]);
  const [historyStaff, setHistoryStaff] = useState([]);
  const [historyStaffDecent, setHistoryStaffDecent] = useState([]);
  const [historyAdvancement, setHistoryAdvancement] = useState([]);
  const [historyTrip, setHistoryTrip] = useState([]);
  const [total, setTotal] = useState(null);
  const [totalDecent, setTotalDecent] = useState(null);
  const [totalAdvancement, setTotalAdvancement] = useState(null);
  const [totalTrip, setTotalTrip] = useState(null);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(profileInput);
    setProfile(profileInput);
  }, [profileInput]);

  useEffect(() => {
    if (profile?.id !== undefined) {
      getHistoryLogStaff(1);
      getHistoryLogStaffDecent(1);
      getHistoryLogStaffFormAdvancement(1);
      getHistoryLogStaffFormTrip(1);
      getHistoryContract(1);
    }
  }, [profile]);

  const getHistoryLogStaff = async (page, type) => {
    try {
      const response = await staffsServices.getHistoryLogStaff(
        profile?.id,
        page,
        0
      );
      setHistoryStaff(response.data?.data);
      setTotal(response.data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryLogStaffDecent = async (page) => {
    try {
      const response = await staffsServices.getHistoryLogStaff(
        profile?.id,
        page,
        1
      );
      setHistoryStaffDecent(response.data?.data);
      setTotalDecent(response.data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryLogStaffFormAdvancement = async (page) => {
    try {
      const response = await staffsServices.getHistoryLogStaffForm(
        profile?.id,
        page,
        "advancement"
      );
      setHistoryAdvancement(response.data?.data);
      setTotalAdvancement(response.data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryLogStaffFormTrip = async (page) => {
    try {
      const response = await staffsServices.getHistoryLogStaffForm(
        profile?.id,
        page,
        "business_trip"
      );
      setHistoryTrip(response.data?.data);
      setTotalTrip(response.data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryContract = async (page) => {
    try {
      const response = await staffsServices.getHistoryContract(
        profile?.id,
        page
      );
      setHistoryContract(response.data?.data);
      setTotalContract(response.data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid">
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Chi nhánh",
            key: "1",
            children: (
              <div>
                {(historyStaff ?? []).map((e) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 0 5px 0px #0000001a",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {e.content ?? "--:--"}
                      </span>

                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Người chỉnh sửa: {e.staff?.name ?? "--:--"}
                      </span>
                      <span>
                        Thời gian chỉnh sửa:{" "}
                        {moment(e.created_at).format("HH:MM DD/MM/YYYY") ??
                          "--:--"}
                      </span>
                    </div>
                  );
                })}
                <Pagination
                  defaultCurrent={1}
                  total={total ?? 0}
                  pageSize={20}
                  onChange={(e) => {
                    getHistoryLogStaff(e);
                  }}
                />
              </div>
            ),
          },
          {
            label: "Phân quyền",
            key: "2",
            children: (
              <div>
                {(historyStaffDecent ?? []).map((e) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 0 5px 0px #0000001a",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {e.content ?? "--:--"}
                      </span>

                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Người chỉnh sửa: {e.staff?.name ?? "--:--"}
                      </span>
                      <span>
                        Thời gian chỉnh sửa:{" "}
                        {moment(e.created_at).format("HH:MM DD/MM/YYYY") ??
                          "--:--"}
                      </span>
                    </div>
                  );
                })}
                <Pagination
                  defaultCurrent={1}
                  total={totalDecent ?? 0}
                  pageSize={20}
                  onChange={(e) => {
                    getHistoryLogStaffDecent(e);
                  }}
                />
              </div>
            ),
          },
          {
            label: "Thăng tiến",
            key: "3",
            children: (
              <div>
                {(historyAdvancement ?? []).map((data) => {
                  var e = JSON.parse(data?.content ?? "");
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 0 5px 0px #0000001a",
                        gap: "5px",
                      }}
                    >
                      <div className="d-flex">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Vị trí cũ: {e.position_old ?? "--:--"}
                        </span>
                        <div className="mx-5">{`->`}</div>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Vị trí mới: {e.position_new ?? "--:--"}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Lương cũ: {formatMoneyVND(e.money_old ?? 0, 2)}
                        </span>
                        <div className="mx-5">{`->`}</div>
                        <span
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Lương mới: {formatMoneyVND(e.money_new ?? 0, 2)}
                        </span>
                      </div>
                      <span>
                        {moment(data?.created_at).format("HH:MM DD/MM/YYYY") ??
                          "--:--"}
                      </span>
                    </div>
                  );
                })}
                <Pagination
                  defaultCurrent={1}
                  total={totalDecent ?? 0}
                  pageSize={20}
                  onChange={(e) => {
                    getHistoryLogStaffFormAdvancement(e);
                  }}
                />
              </div>
            ),
          },
          {
            label: "Công tác",
            key: "4",
            children: (
              <div>
                {(historyTrip ?? []).map((e) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 0 5px 0px #0000001a",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Địa điểm công tác: {e.another ?? "--:--"}
                      </span>
                      <div className="d-flex">
                        <span>
                          Thời gian bắt đầu:{" "}
                          {moment(e?.from).format("DD/MM/YYYY") ?? "--:--"}
                        </span>
                        <div className="mx-5">{`->`}</div>
                        <span>
                          Thời gian kết thúc:{" "}
                          {moment(e?.to).format("DD/MM/YYYY") ?? "--:--"}
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Lý do: {e.reason ?? "--:--"}
                      </span>
                      <span>
                        {moment(e?.created_at).format("HH:MM DD/MM/YYYY") ??
                          "--:--"}
                      </span>
                    </div>
                  );
                })}
                <Pagination
                  defaultCurrent={1}
                  total={totalDecent ?? 0}
                  pageSize={20}
                  onChange={(e) => {
                    getHistoryLogStaffFormTrip(e);
                  }}
                />
              </div>
            ),
          },
          {
            label: "Tăng giảm lương",
            key: "5",
            children: (
              <div>
                {(historyContract ?? []).map((e) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                        boxShadow: "0 0 5px 0px #0000001a",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {e.content ?? "--:--"}
                      </span>

                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Người chỉnh sửa: {e.staff?.name ?? "--:--"}
                      </span>
                      <span>
                        Thời gian chỉnh sửa:{" "}
                        {moment(e.created_at).format("HH:MM DD/MM/YYYY") ??
                          "--:--"}
                      </span>
                    </div>
                  );
                })}
                <Pagination
                  defaultCurrent={1}
                  total={totalContracrt ?? 0}
                  pageSize={20}
                  onChange={(e) => {
                    getHistoryContract(e);
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default HistoryStaff;
