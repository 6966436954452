import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { constants } from "../../../../constants";
import { formatNumberV2 } from "../../../../helpers";
import { DatePicker } from "rsuite";

Modal.setAppElement("#root");
const FORMAT_DATE = "dd-MM-yyyy";
const AddProductModal = ({
  visible,
  type,
  productInput,
  closeModal,
  onSave,
}) => {
  const [product, setProduct] = useState({
    name: "",
    quantity: null,
    unit: "KG",
    description: "",
  });

  useEffect(() => {
    setProduct(productInput);
  }, [productInput]);

  const validatorProduct = () => {
    if (product?.name == "") {
      toast.error("Chưa nhập tên");
      return;
    }

    if (product?.quantity == "") {
      toast.error("Chưa nhập số lượng");
      return;
    }
    onSave(product);
    setProduct(null);
    closeModal();
  };

  const customStyles = {
    content: {
      width: "40%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const [modal, setModal] = useState({
    method: "",
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, visible: false });
  };

  const infoForm = (type) => {
    switch (type) {
      case constants.TYPE_REPORT_BUDGET:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Nội dung chi</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số tiền</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        price: formatNumberV2(e.target.value),
                      });
                    }}
                    value={product?.price}
                  />
                </div>
              </div>
            </div>
          </div>
        );

        default: 
        return  (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Nội dung</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số tiền</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        money: formatNumberV2(e.target.value),
                      });
                    }}
                    value={product?.money}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="ant-modal-content">
        <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
          <h4 style={{ color: "white" }}>Thêm thiết bị</h4>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
          {infoForm(type)}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            validatorProduct();
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
    </Modal>
  );
};

export default AddProductModal;
