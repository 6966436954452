import CloseIcon from "@mui/icons-material/Close";
import { Button, Drawer, Input, Spin } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { chatActions as a } from "../../../../../actions/chatADActions";
import { constants as c } from "../../../../../constants";
import { FileIcon, ImageIcon, ReplyIcon } from "../../icons";
import Message from "../Message";
import ModalSelectUser from "../modalSelectsUser";
import { StyledRoomChat } from "./RoomChat.style";
import { debounce, set, throttle } from "lodash";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ReactGiphySearchbox from "react-giphy-searchbox";

const typeSendMess = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "custom",
  VIDEO: "video",
};

const typeSendFile = {
  IMAGE: 0,
  VIDEO: 1,
  FILE: 2,
};

const RoomChat = ({
  profileInput,
  setOpenDrawerPin,
  OpenDrawerPin,
  openDrawerSearchMess,
  setOpenDrawerSearchMess,
}) => {
  const dispatch = useDispatch();
  const {
    messageReplySelected,
    roomSelected,
    messageList,
    allMessageInfo,
    searchMessageList,
    modalSelectUser,
    dataTyping,
  } = useSelector((state) => state.chatAD);

  const profile = useSelector((state) => state.user.profile);
  const token = JSON.parse(localStorage.getItem("tokenInfo"));

  const [valueInput, setValueInput] = useState("");
  const [currentPageOlder, setCurrentPageOlder] = useState(1);
  const [currentPageNewer, setCurrentPageNewer] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchKeyMess, setSearchKeyMess] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const inputRef = useRef(null);
  const inputImageRef = useRef(null);
  const roomChatRef = useRef(null);
  const typingRef = useRef(null);

  const { list_last_message, room_chat_pin, users } = roomSelected;
  // useEffect(() => {
  //   if (messageReplySelected?.id) {
  //     // inputRef.current.focus();
  //   }
  // }, [messageReplySelected?.id]);

  const handleScroll = useCallback(
    debounce((currentPage) => {
      if (roomChatRef.current.scrollTop > -2) {
        fetchMessageNewer(currentPage);
      }
    }, 500),
    [roomSelected?.id]
  );

  useEffect(() => {
    if (currentPageNewer < 1) return;
    const handleRealScroll = () => {
      handleScroll(currentPageNewer);
    };

    roomChatRef.current.addEventListener("scroll", handleRealScroll);
    return () => {
      if (roomChatRef.current) {
        roomChatRef.current.removeEventListener("scroll", handleRealScroll);
      }
    };
  }, [handleScroll, currentPageNewer, roomSelected?.id]);

  useEffect(() => {
    if (!roomSelected?.id) return;
    const socket = io("https://crm-api.studyguide.dev:6442", {
      rejectUnauthorized: false,
    });
    if (profile) {
      socket.on(`chat:message_to_group:${roomSelected.id}`, (messages) => {
        console.log("messages: ", messages);
        // nếu tin nhắn là của mình hoặc là cảm xúc hoặc là typing thì không gửi hàm đi
        if (
          messages?.data?.user_id === profile.id ||
          messages?.user?.id === profile?.id ||
          messages.feel?.length
        )
          return;
        if (messages?.id || messages?.data) {
          dispatch(a.handleReceiveMessage(messages));
        }
      });
    }
    setCurrentPageOlder(1);
    setCurrentPageNewer(1);
    setOpenDrawerPin(false);
    setOpenDrawerSearchMess(false);
    if (list_last_message && list_last_message.length < 20) setHasMore(false);
    else setHasMore(true);
    if (roomChatRef && roomChatRef.current) {
      roomChatRef.current.scrollTop = -10;
    }

    // inputRef.current.focus();
    return () => {
      socket.disconnect();
    };
  }, [roomSelected?.id]);

  const handleGetMessageList = (page, way = "first", keyword = "") => {
    const query = `?user_id=${profileInput?.id}&page=${page}&limit=20&search=${keyword}`;
    dispatch(a.getMessageList(roomSelected?.id, query, way));
  };

  const handleImageClick = () => {
    inputImageRef.current.click();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        setValueInput(valueInput + "\\n");
      } else {
        e.preventDefault();
        handleSendMessage(typeSendMess.TEXT);
      }
    }
  };

  const handleSendMessage = (type) => {
    if (!valueInput.trim() || valueInput.trim().length === 0) return;
    onSend(valueInput, type);
    // inputRef.current.innerText = "";
    if (roomChatRef && roomChatRef.current) {
      roomChatRef.current.scrollTop = 0;
    }

    setValueInput("");
  };

  const handleMessageFileType = (type) => {
    if (!type) return typeSendFile.IMAGE;
    if (type.startsWith("image/")) return typeSendFile.IMAGE;
    if (type.startsWith("video/")) return typeSendFile.VIDEO;
    return typeSendFile.FILE;
  };

  const handleMessageType = (type) => {
    if (!type) return typeSendMess.IMAGE;
    if (type.startsWith("image/")) return typeSendMess.IMAGE;
    if (type.startsWith("video/")) return typeSendMess.VIDEO;
    return typeSendMess.FILE;
  };

  const sendTypingStatus = () => {
    if (!isTyping) {
      const socket = io("https://crm-api.studyguide.dev:6442", {
        rejectUnauthorized: false,
      });
      setIsTyping(true);
      const param = {
        room_id: roomSelected.id,
        data: {
          user_id: profile.id,
          status: "typing",
        },
      };
      socket.emit("chat:typing", param);
      // socket.disconnect();
    }
    typingRef.current = setTimeout(() => {
      const socket = io("https://crm-api.studyguide.dev:6442", {
        rejectUnauthorized: false,
      });
      const param = {
        room_id: roomSelected.id,
        data: {
          user_id: profile.id,
          status: "typed",
        },
      };
      socket.emit("chat:typing", param);
      // socket.disconnect();
      setIsTyping(false);
    }, 1000);
  };

  const throttledTyping = throttle(sendTypingStatus, 500);

  function handleChangeInput(e) {
    clearTimeout(typingRef.current);
    setValueInput(e.target.innerText);
    throttledTyping();
  }

  const fetchMoreMessage = () => {
    if (messageList.length >= allMessageInfo.total) {
      setHasMore(false);
      return;
    }
    handleGetMessageList(currentPageOlder + 1, "older");
    setCurrentPageOlder((pag) => pag + 1);
  };

  const fetchMessageNewer = (currentPage) => {
    if (currentPage < 2) return;
    if (roomChatRef && roomChatRef.current) {
      roomChatRef.current.scrollTop = -500;
    }
    handleGetMessageList(currentPage - 1, "newer");
    setCurrentPageNewer((pag) => pag - 1);
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    const formData = new FormData();
    formData.append("image", file[0]);
    const messageFile = {
      name: file[0]?.name,
      size: file[0]?.size,
      type: handleMessageFileType(file[0]?.type),
    };
    axios
      .post(`${c.API_URL}/upload_v2/files`, formData, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        onSend(
          { ...messageFile, url_file: response.data.data.image_url },
          handleMessageType(file[0]?.type)
        );
        if (roomChatRef && roomChatRef.current) {
          roomChatRef.current.scrollTop = 0;
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const onSend = (content, type) => {
    const time = moment().utcOffset("+07:00");
    const params = {
      id: uuidv4(),
      content: type === typeSendMess.TEXT ? content.trim() : null,
      type: type,
      room_chat_id: roomSelected.id,
      // "status": "sent",
      feel: null,
      hide_for_user: false,
      hide_all: false,
      page: 0,
      created_at: time.toString(),
      updated_at: time.toString(),
      message_reply: messageReplySelected?.id ? messageReplySelected : null,
      message_reply_id: messageReplySelected?.id || null,
      user: {
        id: profile.id,
        name: profile.name,
        avatar: profile.avatar,
        // latest_req_time: "2023-10-11 11:31:20",
      },
      message_file: type !== typeSendMess.TEXT ? [content] : null,
    };

    dispatch(a.createMessage(params));
    handleRemoveReplyMessage();
  };

  const handleSearchMessage = () => {
    handleGetMessageList(1, "search", searchKeyMess);
  };

  const handleRemoveReplyMessage = () => {
    // inputRef.current.focus();
    dispatch(a.handleSelectReplyMessage({}));
  };

  const scrollToMessage = (messageId) => {
    const messageEl = roomChatRef.current.querySelector(
      `[data-message-id="${messageId}"]`
    );

    if (messageEl) {
      messageEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const contentEl = messageEl.querySelector(".message-content");

      contentEl.classList.add("highlight");
      setTimeout(() => {
        contentEl.classList.remove("highlight");
      }, 4000);
    }
  };

  const handleMoveToMessage = (messageId) => {
    const messageEl = roomChatRef.current.querySelector(
      `[data-message-id="${messageId}"]`
    );
    setOpenDrawerPin(false);
    setOpenDrawerSearchMess(false);
    if (messageEl) {
      scrollToMessage(messageId);
      return;
    }
    const onSuccess = (res) => {
      const queryString = `?page=${res.page}`;
      dispatch(
        a.getMessageList(
          res.room_chat_id,
          queryString,
          "first",
          messageId,
          scrollToMessage
        )
      );
      setCurrentPageOlder(res.page);
      setCurrentPageNewer(res.page);
    };
    dispatch(a.callPageMessage(messageId, onSuccess));
  };

  const handleShowReplyMessage = (lastMessage, type) => {
    switch (type) {
      case "image":
        return "Hình ảnh";
      case "video":
        return "Video";
      case "custom":
        return "Tệp đính kèm";
      default:
        return lastMessage;
    }
  };

  const renderMessageListLink = (dataList) => {
    if (dataList?.length) {
      return dataList.map((item, index) => {
        const { user } = item;
        return (
          <div
            key={index}
            className="pin-message message"
            onClick={() => handleMoveToMessage(item?.message?.id || item.id)}
          >
            <div className="message__avatar">
              <img src={user?.avatar} alt="avatar" />
            </div>
            <div className="message__content">
              <p className="message__name">{user?.name}</p>
              <p className="message__content">
                {handleShowReplyMessage(
                  item?.message?.content || item.content,
                  item?.message?.type || item.type
                )}
              </p>
            </div>
          </div>
        );
      });
    }
    return <p style={{ textAlign: "center" }}>Không có tin nhắn nào</p>;
  };

  return (
    <StyledRoomChat>
      <div
        className="view-chat-container"
        id="scrollableMessageList"
        ref={roomChatRef}
      >
        {messageList && messageList.length ? (
          <InfiniteScroll
            dataLength={messageList?.length}
            next={fetchMoreMessage}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true} //
            hasMore={hasMore}
            // height={450}
            loader={
              <div style={{ textAlign: "center", height: "36px" }}>
                <Spin></Spin>
              </div>
            }
            scrollableTarget="scrollableMessageList"
            endMessage={
              <p style={{ textAlign: "center", marginBottom: "8px" }}>
                Cuối cùng của đoạn hội thoại
              </p>
            }
          >
            {messageList.map((message, index) => {
              return (
                <div key={message.id} data-message-id={message.id}>
                  <Message
                    message={message}
                    nextMessage={
                      index + 1 <= messageList.length
                        ? messageList[index + 1]
                        : null
                    }
                    prevMessage={index - 1 >= 0 ? messageList[index - 1] : null}
                    handleMoveToMessage={handleMoveToMessage}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        ) : (
          <div>
            <ReactGiphySearchbox
              apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
              onSelect={(item) => console.log(item)}
              masonryConfig={[
                { columns: 2, imageWidth: 110, gutter: 5 },
                { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 },
              ]}
            />
          </div>
        )}
      </div>

      {OpenDrawerPin && (
        <Drawer
          title="Danh sách ghim"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawerPin(false)}
          open={OpenDrawerPin}
          getContainer={false}
        >
          <div className="list-pin-message">
            {renderMessageListLink(room_chat_pin)}
          </div>
        </Drawer>
      )}
      {openDrawerSearchMess && (
        <Drawer
          title="Tìm kiếm tin nhắn"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawerSearchMess(false)}
          open={openDrawerSearchMess}
          getContainer={false}
        >
          <div className="list-pin-message">
            <div
              className="d-flex justify-content-center"
              style={{ gap: "8px", marginBottom: "16px" }}
            >
              <Input
                placeholder="Tìm kiếm tin nhắn"
                onChange={(e) => setSearchKeyMess(e.target.value)}
              />
              <Button type="primary" onClick={handleSearchMessage}>
                Tìm kiếm
              </Button>
            </div>
            {renderMessageListLink(searchMessageList)}
          </div>
        </Drawer>
      )}
      <div className="input-chat-container">
        {dataTyping?.status === "typing" && (
          <div
            style={{
              fontSize: "12px",
              color: "#000",
              background: "#fff",
              padding: "0 8px",
              width: "fit-content",
              position: "absolute",
              top: "-20px",
            }}
          >
            {users.find((user) => user.id === Number(dataTyping.user_id))?.name}{" "}
            đang soạn tin <MoreHorizIcon fontSize="small" color="#7589a3" />
          </div>
        )}
        {messageReplySelected?.id && (
          <div className="message-reply">
            <div className="d-flex align-items-center" style={{ gap: "8px" }}>
              <ReplyIcon style={{ color: "#3390EC" }} />
              <div
                style={{ borderLeft: "2px solid #3390EC", paddingLeft: "8px" }}
              >
                <p className="message__name">
                  {messageReplySelected.user.name}
                </p>
                <p className="message__content">
                  {handleShowReplyMessage(
                    messageReplySelected.content,
                    messageReplySelected.type
                  )}
                </p>
              </div>
            </div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleRemoveReplyMessage}
            />
          </div>
        )}
        {/* <div
          ref={inputRef}
          className="input-chat"
          contentEditable
          innerText={valueInput}
          onInput={handleChangeInput}
          onKeyUp={handleKeyDown}
        ></div>
        <div className="input-chat-action">
          <div
            className="input-chat-action__image"
            onClick={handleImageClick}
            title="Gửi ảnh hoặc video"
          >
            <ImageIcon style={{ width: "18px", height: "18px" }} />
            <input
              type="file"
              ref={inputImageRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
          <div
            className="input-chat-action__image"
            onClick={handleImageClick}
            title="Gửi file"
          >
            <FileIcon
              style={{ width: "18px", height: "18px", marginLeft: "8px" }}
            />
            <input
              type="file"
              ref={inputImageRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              // accept="image/*"
            />
          </div>
          <p
            className={`input-chat-action__send ${
              !valueInput.trim() && `disabled`
            } `}
            onClick={() => handleSendMessage("text")}
          >
            Gửi
          </p>
        </div> */}
        {modalSelectUser.isOpen ? <ModalSelectUser /> : null}
      </div>
    </StyledRoomChat>
  );
};

export default React.memo(RoomChat);
