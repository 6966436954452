import React, { useState } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import HistoryReviewItem from "./child/HistoryReviewItem";

const HistoryReview = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const renderView = () => {
    switch (tabIndex) {
      case 0:
        return <HistoryReviewItem />;

      default:
        break;
    }
  };
  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Danh sách đánh giá chi nhánh Văn phòng
        </h4>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Lịch sử đánh giá</Tab>
            </TabList>
          </Tabs>
        </div>
        {/* <SearchInput
        placeHolder={"Tìm kiếm nhân viên"}
        onSubmitSearch={onSubmitSearch}
        onChange={onChangeSearch}
      ></SearchInput> */}
        <div className="card-body">{renderView()}</div>
      </div>
    </div>
  );
};

export default HistoryReview;
