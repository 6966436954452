import React from "react";
import { useSelector } from "react-redux";
import { formatMoneyVND } from "../../../../helpers";
import { SelectPicker } from "rsuite";
import { Switch } from "antd";

const ItemStaff = ({
  employee,
  handleRemoveEmployee,
  onSwitch,
  onChange,
  listSupportConfig,
  chooseSupport,
}) => {
  const areas = useSelector((state) => state.area.areas);

  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  return (
    <tr className="hover-product even">
      <td>{employee?.name}</td>
      <td>
        <input
          name="kpi_percent"
          type="number"
          min="0"
          max="100"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.kpi_percent}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="another_plus"
          type="text"
          className="form-control"
          placeholder=""
          style={{width: 120 }}
          autoComplete="off"
          value={employee.another_plus}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="another_minus"
          type="text"
          className="form-control"
          style={{width: 120 }}
          placeholder=""
          value={employee.another_minus}
          autoComplete="off"
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="BHXH"
          type="text"
          className="form-control"
          placeholder=""
          style={{width: 120 }}
          autoComplete="off"
          value={employee.BHXH}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="tax"
          type="text"
          className="form-control"
          placeholder=""
          style={{width: 120 }}
          autoComplete="off"
          value={employee.tax}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="support"
          type="text"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={(listSupportConfig ?? []).map((e) => e.title).join(", ")}
          onClick={(e) => {
            chooseSupport(listSupportConfig);
          }}
          readOnly
        />
      </td>
      <td>
        <Switch
          name="use_education_kpi"
          checked={employee?.use_education_kpi ?? true}
          onChange={(checked) => {
            onSwitch(checked, employee?.staff_id);
          }}
        />
      </td>
      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          onClick={() => handleRemoveEmployee(employee?.staff_id)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          xóa
        </button>
      </td>
    </tr>
  );
};

export default ItemStaff;
