import { FileIcon2 } from "../../icons";

export const FileDownloader = ({ fileUrl, fileName }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        background: "white",
        padding: "8px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <a
        href={fileUrl}
        download={fileName}
        target="_blank"
        rel="noreferrer"
        
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          background: "white",
        }}
      >
        <div>
          <FileIcon2 style={{ color: "#F7C30D" }} />
        </div>
        <button style={{ background: "white" }}>{fileName}</button>
      </a>
    </div>
  );
};
