/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { DatePicker } from "rsuite";
import { constants } from "../../../constants";
import { AsyncPaginate } from "react-select-async-paginate";
import { shiftServices } from "../../../services/shiftServices";

const DatesInput = ({
  index,
  value,
  onChange,
  onAddDate,
  onDeleteDate,
  isDelete,
}) => {
  return (
    <div className="d-flex align-items-center mt-2 mb-2" key={value?.id}>
      <DatePicker
        className="select-date"
        value={value?.date ? new Date(value.date) : null}
        cleanable={false}
        format="dd-MM-yyyy"
        placement="top"
        block
        onChange={(value) =>
          onChange(moment(value).format(constants.FORMAT_YYYY_MM_DD), index)
        }
      />
      <div className="icon-group">
        <i
          class="fa fa-trash-o ml-2 dates-group-icon text-danger"
          aria-hidden="true"
          onClick={() => onDeleteDate(index)}
        ></i>
        {!isDelete && (
          <i
            class="fa fa-plus-circle ml-2 dates-group-icon text-success"
            aria-hidden="true"
            onClick={onAddDate}
          ></i>
        )}
      </div>
    </div>
  );
};

const SetupOTModal = ({
  visible,
  title,
  setupOTDetail,
  onSubmit,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const [listDepartment, setListDepartment] = useState([]);
  const [listDepartmentCurrent, setListDepartmentCurrent] = useState([]);

  useEffect(() => {
    if (visible) {
      fetchAllDepartmentsInit();
      reset(setupOTDetail);
      setListDepartment(
        (setupOTDetail?.departments?? []).map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
    } else {
      reset();
    }
    
  }, [setupOTDetail, visible]);

  useEffect(() => {

    if (typeof listDepartment !== 'undefined') {
      if (listDepartmentCurrent.length !== 0) {
        var data = [];
        console.log(listDepartment)


        listDepartment.forEach((e) => {
          data.push(
            listDepartmentCurrent.filter((d) => {
              return d.id === e.value;
            })[0]
          );
        });

        reset({
          ...getValues(),
          departments: data,
        });
      }
    }
    
  }, [listDepartment]);

  const fetchAllDepartmentsInit = async () => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      setListDepartmentCurrent(res.data);
    
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      setListDepartmentCurrent(res.data);
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const handleAddDate = () => {
    reset({
      ...getValues(),
      dates: [...getValues("dates"), ""],
    });
  };

  const handleDeleteDate = (index) => {
    const options = getValues("dates");
    options.splice(index, 1);

    reset({
      ...getValues(),
      dates: [...options],
    });
  };

  const handleOnchangeOptionByIndex = (value, index) => {
    const options = getValues("dates");
    options[index] = {
      ...options[index],
      date: value,
    };

    reset({
      ...getValues(),
      dates: [...options],
    });
  };

  const setStyleValidate = (name) =>
    errors[name] ? { border: "1px solid red" } : null;

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="form-group">
            <label>
              Tên <span className="text-danger">*</span>
            </label>
            <input
              name="title"
              type="text"
              className="form-control"
              placeholder="Nhập tên"
              autoComplete="off"
              style={setStyleValidate("title")}
              {...register("title", {
                required: true,
              })}
            />
            {errors?.title?.type === "required" && (
              <small className="text-danger">Vui lòng nhập tên</small>
            )}
          </div>
          <div className="form-group">
            <label>Mô tả</label>
            <input
              name="title"
              type="text"
              className="form-control"
              placeholder="Nhập mô tả"
              autoComplete="off"
              style={setStyleValidate("description")}
              {...register("description", {
                required: false,
              })}
            />
          </div>
          <div className="form-group">
            <label>
              Hệ số lương <span className="text-danger">*</span>
            </label>
            <input
              name="value"
              type="number"
              className="form-control"
              placeholder="Nhập hệ số lương"
              autoComplete="off"
              style={setStyleValidate("scale")}
              {...register("scale", {
                required: true,
              })}
            />
            {errors?.scale?.type === "required" && (
              <small className="text-danger">Vui lòng nhập hệ số lương</small>
            )}
          </div>
          <div
            className="form-group"
            style={{ alignItems: "center", marginTop: "0px" }}
          >
            <label>
              Phòng ban <span className="text-danger">*</span>
            </label>

            <div style={{ width: "100%" }}>
              <AsyncPaginate
                value={listDepartment}
                loadOptions={fetchAllDepartments}
                isMulti
                closeMenuOnSelect={false}
                onChange={(e) => {
                  setListDepartment(e);
                }}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="mb-0" style={{ fontWeight: "750" }}>
                Danh sách ngày chấm công
              </label>
              {!getValues("list_option")?.length > 0 && (
                <i
                  class="fa fa-plus-circle ml-2 question-group-icon text-success"
                  aria-hidden="true"
                  onClick={handleAddDate}
                ></i>
              )}
            </div>
            {getValues("dates")?.map((item, index) => (
              <DatesInput
                index={index}
                value={item}
                isDelete={index !== getValues("dates").length - 1}
                onAddDate={handleAddDate}
                onDeleteDate={handleDeleteDate}
                onChange={handleOnchangeOptionByIndex}
              />
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button type="submit" className="btn btn-info">
            Lưu
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SetupOTModal;
