import moment from "moment";
import React from "react";

const ColumnCheckInOut = ({ history, key, style }) => {
  const getClassNameAndTextForStatus = (status) => {
    switch (status) {
      case 0:
        return {
          className: "text-danger",
          text: "Từ chối",
        };
      case 1:
        return {
          className: "text-info",
          text: "Chờ duyệt",
        };
      case 2:
        return {
          className: "text-success",
          text: "Đồng ý",
        };
      default:
        return {
          className: "",
          text: "",
        };
    }
  };

  const classNameAndText = getClassNameAndTextForStatus(history?.status);

  return (
    <div
      className={`column-in-out ${
        !history?.is_checkin ? "column-in-out-off-shift" : ""
      }`}
      style={style}
      key={key}
    >
      <div className="d-flex">
        <span
          className={`circle-box ${
            !history?.is_checkin ? "circle-box-off-shift" : ""
          }`}
        />
        <p className="time-check-in-text">
          {`${history?.is_checkin ? "Vào làm: " : "Tan làm: "}`}
          <span className="check-in-hours-text">
            {history?.time_tracking
              ? moment(new Date(history.time_tracking)).format("hh:mm:ss")
              : ""}
          </span>
        </p>
      </div>
      <div className="d-flex mt-1">
        <p className="text-danger">
          {`${history?.remote_timekeeping ? " (Từ xa) " : ""}`}
          <span className="reason-text">{`Lý do: ${
            history?.reason || ""
          }`}</span>
        </p>
      </div>
      <p>
        {`Trạng thái: `} <span>{`${classNameAndText.text}`}</span>
      </p>
    </div>
  );
};

export default ColumnCheckInOut;
