/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Form, Input, Select } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";
import { set } from "lodash";
import da from "date-fns/esm/locale/da/index";

const SeniorityFilterModal = ({ open, closeModal, onOk, dataInput }) => {
  const areaId = useSelector((state) => state.area.areaId);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      setFrom(dataInput.seniority_from);
      setTo(dataInput.seniority_to);
    } else {
      setFrom(null);
      setTo(null);
    }
  }, [open]);

  return (
    <Modal
      title={"Lọc thâm niên"}
      centered
      open={open}
      hildren={form}
      onOk={(e) => {
        onOk(from, to);
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <span className="mb-2">Khoảng ngày bắt đầu</span>
          <div style={{ height: "5px" }} />
          <Input
            type="number"
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
            placeholder="Nhập số khoảng ngày bắt đầu"
            maxLength={16}
          />
        </div>
        <div style={{ width: "40px" }} />
        <div>
          <span>Khoảng ngày kết thúc</span>
          <div style={{ height: "5px" }} />
          <Input
            type="number"
            value={to}
            onChange={(e) => {
              setTo(e.target.value);
            }}
            placeholder="Nhập số khoảng ngày kết thúc"
            maxLength={16}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SeniorityFilterModal;
