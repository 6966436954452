export default function ImageIcon({ className, ...rest }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <g clip-path="url(#clip0_500_57)">
        <path
          d="M15.6999 0H2.3001C1.0318 0 0 1.03184 0 2.30013V15.6999C0 16.9682 1.0318 18 2.3001 18H15.6999C16.9682 18 18 16.9682 18 15.6999V2.30013C18 1.03184 16.9682 0 15.6999 0ZM16.9398 15.6999C16.9398 16.3836 16.3836 16.9398 15.6999 16.9398H2.3001C1.61641 16.9398 1.06021 16.3836 1.06021 15.6999V13.7661L4.54964 10.7971C4.67698 10.6887 4.86274 10.6877 4.99127 10.7944L7.17708 12.6095C7.38784 12.7845 7.69721 12.7701 7.89089 12.5762L13.0845 7.37473C13.1784 7.28068 13.2878 7.27154 13.3449 7.27446C13.4019 7.27738 13.51 7.2977 13.5938 7.40088L16.9398 11.5208L16.9398 15.6999ZM16.9398 9.839L14.4167 6.73239C14.1665 6.42428 13.7957 6.23591 13.3992 6.21555C13.0031 6.19552 12.6147 6.34465 12.3342 6.62555L7.48238 11.4849L5.66866 9.97882C5.14276 9.54211 4.38325 9.54664 3.86258 9.98965L1.06021 12.374V2.30013C1.06021 1.61645 1.61641 1.06024 2.3001 1.06024H15.6999C16.3836 1.06024 16.9398 1.61645 16.9398 2.30013V9.839Z"
          fill="black"
        />
        <path
          d="M5.66618 2.21484C4.25659 2.21484 3.10986 3.36164 3.10986 4.77116C3.10986 6.18071 4.25662 7.32748 5.66618 7.32748C7.07573 7.32748 8.2225 6.18071 8.2225 4.77116C8.2225 3.36161 7.07577 2.21484 5.66618 2.21484ZM5.66618 6.26727C4.8412 6.26727 4.17007 5.5961 4.17007 4.77116C4.17007 3.94618 4.8412 3.27505 5.66618 3.27505C6.49116 3.27505 7.16229 3.94622 7.16229 4.77116C7.16229 5.5961 6.49116 6.26727 5.66618 6.26727Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_57">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
