import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Badge, SelectPicker } from "rsuite";
import { areaAction } from "../actions/areaAction";
import { userActions } from "../actions/userActions";
import { constants as c, constants } from "../constants";
import { shiftServices } from "../services/shiftServices";
import { Dropdown, Space } from "antd";

const HeaderProfile = () => {
  const dispatch = useDispatch();
  const [stateNotify, setStateNotify] = useState(false);
  const [notifys, setNotifys] = useState([]);
  const history = useHistory();
  const userInfo = useSelector((state) => state.user.profile);
  const areas = useSelector((state) => state.area.areas);

  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const fetchNotify = async (areaIdSelect) => {
    try {
      if (areaIdSelect == constants.AREA_ALL_ID) {
        const response = await shiftServices.listNotifyManager();
        setNotifys(response.data.data);
      } else {
        const response = await shiftServices.listNotify(areaIdSelect);
        setNotifys(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const readNotify = async (areaId, notificationId) => {
    try {
      if (areaId == constants.AREA_ALL_ID) {
      } else {
        const response = await shiftServices.readNotify({
          areaId,
          notificationId,
        });
        fetchNotify(areaId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function logout() {
    window.localStorage.clear();
    window.location.reload();
  }

  function handleChangeArea(value) {
    dispatch(areaAction.changeAreaId(value));
    dispatch(userActions.getReportNumber(value));
    fetchNotify(value);
  }

  const items = [
    {
      key: "1",
      label: <a href="/profile">Tài khoản</a>,
    },
    {
      key: "2",
      label: (
        <a
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          Đăng xuất
        </a>
      ),
    },
  ];
  return (
    <Space>
      <SelectPicker
        style={{ minWidth: "150px" }}
        data={areas?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        value={areaId}
        cleanable={false}
        placeholder="-- Chọn chi nhánh --"
        block
        onChange={handleChangeArea}
      />
      <div style={{ width: "30px" }}>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <img
            className="rounded-circle"
            src={userInfo?.avatar ?? "https://isempai.net/images/people.png"}
            style={{ height: "30px", width: "30px" }}
          />
        </Dropdown>
      </div>
      <div style={{ position: "relative" }}>
        <Badge
          content={
            reportNumber.noti_unread == 0 ? false : reportNumber.noti_unread
          }
        >
          <div
            style={{ cursor: "pointer", color: "#F9C114" }}
            onClick={() => {
              setStateNotify(!stateNotify);
              fetchNotify(areaId);
            }}
          >
            <i
              className="fa fa-bell"
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            ></i>
          </div>
        </Badge>
        <div
          style={{
            position: "absolute",
            left: "-285px",
            background: "white",
            borderRadius: "4px",
            border: "1px solid #ccc",
            display: stateNotify ? "" : "none",
            zIndex: 10,
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          {notifys?.length > 0 ? (
            <div
              style={{
                width: "300px",
              }}
            >
              {notifys?.map((notify, index) => {
                return (
                  <div
                    className="d-flex"
                    style={{
                      borderBottom:
                        notifys?.length - 1 === index
                          ? "none"
                          : "1px solid #ccc",
                      backgroundColor:
                        notify.is_read == false ? "#fffacb" : "white",
                    }}
                    onClick={() => {
                      readNotify(notify.area_id, notify.id);

                      if (notify?.type === c.HAS_SALARY) {
                        history.push(`/salary`);
                        return;
                      }

                      dispatch({
                        type: c.MODAL_NOTI_SHOW,
                        type_noti: notify?.type,
                        reference_value: notify?.reference_value,
                      });
                    }}
                  >
                    {notify.is_read == false ? (
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{
                          fontSize: "10px",
                          color: "hsl(214deg 89% 52%)",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                        }}
                      ></i>
                    ) : (
                      <div></div>
                    )}
                    <div
                      style={{
                        flex: "1",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div>{notify?.title}</div>
                      <div style={{ fontSize: "11px" }}>
                        {moment(new Date(notify.created_at)).format(
                          c.FORMAT_DD_MM_YYYY_HH_MM_SS
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ width: "300px" }}>
              <div style={{ padding: "10px" }}>Chưa có thông báo nào</div>
            </div>
          )}
        </div>
      </div>
      <div style={{width:10}}> </div>
    </Space>
  );
};
export default HeaderProfile;
