import styled from "styled-components";

export const StyledRoomInfo = styled.div`
    .space {
        height: 8px;
        background-color: #f0f2f5;
        width: 100%;
        margin-top: 16px
    }
  .room-info {
    text-align: center;
    padding-top: 20px;
    &__avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin: 4px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__action {
      display: flex;
      justify-content: center;
      gap: 10px;
      .action-item {
        width: 70px;
        .btn {
          width: 36px;
          height: 36px;
          padding: 6px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          border-radius: 50%;
          &:hover {
            background-color: #dfe2e7;
          }
        }
      }
    }
    .text-truncate-1 {
      display: block;
      // display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 280px;
      height: 20px;
      line-height: 20px;
    }
  }
`;
