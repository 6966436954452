import React from "react";
import { useSelector } from "react-redux";
import { SelectPicker } from "rsuite";
import Switch from "react-ios-switch";
import { Space } from "antd";

const ItemStaffReset = ({ employee, handleRemoveEmployee, onChange }) => {
  const areas = useSelector((state) => state.area.areas);

  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        marginTop: "10px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "15px",
        marginBottom: "10px",
      }}
    >
      <b style={{ fontSize: "16px" }}>{employee?.name}</b>
      <Space size="large">
        <Space className="my-1" direction="vertical">
          <p>Trở về thực tế</p>
          <Switch
            checked={employee.is_reality ?? false}
            onChange={(checked) => {
              onChange(!(employee.is_reality ?? false));
            }}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Hợp đồng</p>
          <SelectPicker
            // style={{ width: "200px" }}
            data={[
              {
                label: "Full time",
                value: "full_time",
              },
              {
                label: "Part time",
                value: "part_time",
              },
            ]}
            value={employee.type_key ?? "full_time"}
            cleanable={false}
            searchable={false}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Ngày công chuẩn</p>
          <input
            name="count_day_work"
            type="number"
            min="0"
            max="31"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.count_day_work}
            onChange={onChange}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Giờ công chuẩn</p>
          <input
            name="standard_working_hour"
            type="number"
            min="0"
            max="24"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.standard_working_hour}
            onChange={onChange}
          />
        </Space>
        {(employee.type_key ?? "full_time") == "full_time" ? (
          <>
            <Space className="my-1" direction="vertical">
              <p>Lương vị trí P1</p>
              <input
                name="position_salary"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.position_salary}
                onChange={onChange}
              />
            </Space>
            <Space className="my-1" direction="vertical">
              <p>Lương P2</p>
              <input
                name="support_productive"
                type="text"
                min="0"
                max="24"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.support_productive}
                onChange={onChange}
              />
            </Space>
            <Space className="my-1" direction="vertical">
              <p>Lương P3</p>
              <input
                name="support_result"
                type="text"
                min="0"
                max="24"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.support_result}
                onChange={onChange}
              />
            </Space>
          </>
        ) : (
          <Space className="my-1" direction="vertical">
            <p>Lương theo giờ</p>
            <input
              name="salary"
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
              value={employee.salary}
              onChange={onChange}
            />
          </Space>
        )}
      </Space>
      <Space size="large">
        {(employee.type_key ?? "full_time") == "full_time" ? (
          <>
            <Space className="my-1" direction="vertical">
              <p>Phụ cấp cơm</p>
              <input
                name="pc_food"
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.pc_food}
                onChange={onChange}
              />
            </Space>
            <Space className="my-1" direction="vertical">
              <p>Phụ cấp đi lại</p>
              <input
                name="pc_move"
                type="text"
                min="0"
                max="24"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.pc_move}
                onChange={onChange}
              />
            </Space>
            <Space className="my-1" direction="vertical">
              <p>Phụ cấp điện thoại</p>
              <input
                name="pc_phone"
                type="text"
                min="0"
                max="24"
                className="form-control"
                placeholder=""
                autoComplete="off"
                value={employee.pc_phone}
                onChange={onChange}
              />
            </Space>
          </>
        ) : (
          <></>
        )}
        <Space className="my-1" direction="vertical">
          <p>Lương KPI %</p>
          <input
            name="kpi_percent"
            type="number"
            min="0"
            max="100"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.kpi_percent}
            onChange={onChange}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>BHXH</p>
          <Switch
            checked={employee.BHXH ?? false}
            onChange={(checked) => {
              onChange(!(employee.BHXH ?? false), "BHXH");
            }}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Công đoàn</p>
          <Switch
            checked={employee.cong_doan ?? false}
            onChange={(checked) => {
              onChange(!(employee.cong_doan ?? false), "cong_doan");
            }}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Thuế</p>
          <SelectPicker
            // style={{ width: "200px" }}
            data={[
              {
                label: "Cam kết",
                value: "CK",
              },
              {
                label: "Khấu trừ",
                value: "KT",
              },
              {
                label: "Giảm trừ",
                value: "LT",
              },
            ]}
            value={employee.tax ?? "CK"}
            cleanable={false}
            searchable={false}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </Space>
        <Space className="my-1" direction="vertical">
          <p>Số người phụ thuộc</p>
          <input
            name="dependent_count"
            type="number"
            min="0"
            max="10"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.dependent_count}
            onChange={onChange}
          />
        </Space>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          onClick={() => handleRemoveEmployee(employee?.staff_id)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          xóa
        </button>
      </Space>
    </div>
  );

  return (
    <tr className="hover-product even">
      <td>{employee?.name}</td>
      <td>
        <Switch
          checked={employee.is_reality ?? false}
          onChange={(checked) => {
            onChange(!(employee.is_reality ?? false));
          }}
        />
      </td>
      <td>
        <SelectPicker
          // style={{ width: "200px" }}
          data={[
            {
              label: "Full time",
              value: "full_time",
            },
            {
              label: "Part time",
              value: "part_time",
            },
          ]}
          value={employee.type_key ?? "full_time"}
          cleanable={false}
          searchable={false}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </td>
      <td>
        <input
          name="count_day_work"
          type="number"
          min="0"
          max="31"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.count_day_work}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="standard_working_hour"
          type="number"
          min="0"
          max="24"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.standard_working_hour}
          onChange={onChange}
        />
      </td>

      {(employee.type_key ?? "full_time") == "full_time" ? (
        <td>
          <input
            name="total_salary"
            type="text"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.total_salary}
            onChange={onChange}
          />
        </td>
      ) : (
        <td>
          <input
            name="salary"
            type="text"
            className="form-control"
            placeholder=""
            autoComplete="off"
            value={employee.salary}
            onChange={onChange}
          />
        </td>
      )}
      <td>
        <input
          name="position_salary"
          type="text"
          min="0"
          max="24"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.position_salary}
          onChange={onChange}
        />
      </td>
      <td>
        <input
          name="kpi_percent"
          type="number"
          min="0"
          max="100"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.kpi_percent}
          onChange={onChange}
        />
      </td>
      <td>
        <Switch
          checked={employee.BHXH ?? false}
          onChange={(checked) => {
            onChange(!(employee.BHXH ?? false), "BHXH");
          }}
        />
      </td>
      <td>
        <SelectPicker
          // style={{ width: "200px" }}
          data={[
            {
              label: "Cam kết",
              value: "CK",
            },
            {
              label: "Khấu trừ",
              value: "KT",
            },
            {
              label: "Giảm trừ",
              value: "LT",
            },
          ]}
          value={employee.tax ?? "CK"}
          cleanable={false}
          searchable={false}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </td>
      <td>
        <input
          name="dependent_count"
          type="number"
          min="0"
          max="10"
          className="form-control"
          placeholder=""
          autoComplete="off"
          value={employee.dependent_count}
          onChange={onChange}
        />
      </td>
      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          onClick={() => handleRemoveEmployee(employee?.staff_id)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          xóa
        </button>
      </td>
    </tr>
  );
};

export default ItemStaffReset;
