import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const requestOptions = ({ method, bodyData }) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo || "",
    },
  };
  bodyData && (options.body = JSON.stringify(bodyData));

  return options;
};

/* ------------------ Review Questions Api ----------------------------------- */
async function fetchReviewQuestions(queryString = "") {
  return fetch(
    `${c.API_URL}/review/question${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function createReviewQuestions(bodyData) {
  return fetch(
    `${c.API_URL}/review/question`,
    requestOptions({ method: "POST", bodyData: bodyData })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function updateReviewQuestions(id, bodyData) {
  return fetch(
    `${c.API_URL}/review/question/${id}`,
    requestOptions({ method: "PUT", bodyData: bodyData })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

async function deleteReviewQuestions(id) {
  return fetch(
    `${c.API_URL}/review/question/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

export const reviewServices = {
  fetchReviewQuestions,
  createReviewQuestions,
  updateReviewQuestions,
  deleteReviewQuestions,
};
