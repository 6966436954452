import { constants as c } from "../constants";

const initialState = {
   list: {
      data: [],
      status: c.NONE,
   },
   info: {
      detail: {},
      status: c.NONE,
   },
};

export function scoreboard(state = initialState, action) {
   switch (action.type) {
      case c.GET_ALL_SCOREBOARD_SUCCESS:
         console.log("action >>>", action);
         return {
            ...state,
            list: {
               data: action.data,
               status: c.SUCCESS,
            },
         };
      default:
         return state;
   }
}
