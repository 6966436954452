import React from "react";
import RoomList from "../Common/RoomList";
import ContactSearch from "./ContactSearch";
import { StyledSidebarLeft } from "./SidebarLeft.style";

export default function SidebarLeft() {
  return (
    <StyledSidebarLeft>
      <ContactSearch />
      <RoomList />
    </StyledSidebarLeft>
  );
}
