import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../../../actions/chatActions";
import { CheckOutlined } from "@material-ui/icons";
import { Modal, Spin } from "antd";

export default function ViewedUsers({ list_last_message }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    loadingFile,
  } = useSelector((state) => state.chat);

  if (!list_last_message || !list_last_message.length) return null;
  if (list_last_message[0].user.id !== profile.id) return null;

  const { list_user } = list_last_message[0];

  if (!list_user || !list_user.length) return null;

  const list_user_viewed = list_user.filter(
    (user) => user.id !== profile.id && user.status === "seen"
  );

  if (loadingFile) {
    return (
      <div style={{ marginLeft: "auto", marginTop: "12px" }}>
        <Spin spinning={true}></Spin>
        <span style={{ marginLeft: "8px" }}>Đang gửi</span>
      </div>
    );
  }

  if (!list_user_viewed.length)
    return (
      <div
        style={{
          display: "flex",
          gap: "2px",
          alignItems: "center",
          justifyContent: "end",
          color: "#7589a3",
        }}
      >
        <CheckOutlined fontSize="small" color="#7589a3" />
        Đã gửi
      </div>
    );

  const handleOpenModalUserInfo = (user) => {
    dispatch(a.toggleModalUserInfo({ isOpen: true, data: user }));
  };

  const renderItem = (user) => {
    return (
      <div
        className="reaction-item"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "8px 20px 8px 16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <img
            src={
              user?.avatar ||
              "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
            }
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
            }}
            alt="avatar"
          />
          <span>{user.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: "flex", gap: "4px", justifyContent: "end" }}>
      {list_user_viewed.slice(0, 9).map((user, index) => {
        return (
          <div
            className="message-avatar"
            onClick={() => handleOpenModalUserInfo(user)}
            style={{
              cursor: "pointer",
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "0 0 0 1px #fff",
              position: "relative",
            }}
          >
            <img
              src={
                user.avatar ||
                "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
              }}
              alt="avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
              }}
            />
          </div>
        );
      })}
      {list_user_viewed.length > 9 && (
        <div
          className="message-avatar"
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            overflow: "hidden",
            boxShadow: "0 0 0 1px #fff",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "8px",
            color: "#fff",
            fontWeight: "bold",
            backgroundColor: "#7589a3",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          +{list_user_viewed.length - 9}
        </div>
      )}
      {isModalOpen && (
        <Modal
          title="Đã xem"
          open={isModalOpen}
          getContainer={"#box-chat-container"}
          onCancel={() => setIsModalOpen(false)}
          footer={null}
          width={500}
          styles={{ minHeight: "400px" }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "2px",
              flexWrap: "wrap",
            }}
          >
            {list_user_viewed.map((user) => (
              <div>{renderItem(user)}</div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}
