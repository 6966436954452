import { Button, Image, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../../../actions/chatActions";

export default function ModalUserInfo() {
  const dispatch = useDispatch();
  const modalUserInfo = useSelector((state) => state.chat.modalUserInfo);
  const profile = useSelector((state) => state.user.profile);

  const handleCloseModal = () => {
    dispatch(a.toggleModalUserInfo({ isOpen: false }));
  };
  const handleSendMessage = () => {
    const onSuccess = (roomChat) => {
      dispatch(a.toggleModalUserInfo({ isOpen: false }));
      if (roomChat) {
        dispatch(a.selectedRoomChat(roomChat));
      } else {
        const paramsCreateRoom = {
          name_room: "",
          room_avatar: "",
          user_ids: [modalUserInfo.data.id, profile.id],
        };
        dispatch(a.createRoomChat(paramsCreateRoom));
      }
    };
    dispatch(a.checkSingleRoom({ staff_id: modalUserInfo.data.id }, onSuccess));
  };

  const renderContentModal = () => {
    const { id, avatar, latest_req_time, name } = modalUserInfo.data;
    return (
      <div
        style={{
          padding: "20px",
          textAlign: "center",
          paddingBottom: "40px",
          paddingTop: "30px",
        }}
      >
        <Image
          src={
            avatar ||
            "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
          }}
          width={100}
          height={100}
          style={{ borderRadius: "50%" }}
        />
        <p
          style={{ fontSize: "20px", fontWeight: "600", margin: "10px 0 30px" }}
        >
          {name}
        </p>
        <Button type="primary" onClick={handleSendMessage}>
          Gửi tin nhắn
        </Button>
      </div>
    );
  };

  if (!modalUserInfo.isOpen) return null;
  return (
    <div>
      <Modal
        title={"Thông tin tài khoản"}
        open={modalUserInfo.isOpen}
        onCancel={handleCloseModal}
        getContainer={"#box-chat-container"}
        styles={{ padding: "16px", width: "300px", paddingBottom: "30px" }}
        width={300}
        heigh={500}
        footer={null}
        centered={true}
      >
        {renderContentModal()}
        {/* <Spin spinning={modalUserInfo.loading}>{renderContentModal()}</Spin> */}
      </Modal>
    </div>
  );
}
