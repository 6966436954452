/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { areaServices } from "../../../services/areaServices";

const ReportReviews = () => {
  const [reports, setReports] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);

  const fetchReport = async () => {
    try {
      const response = await areaServices.fetchReportReviewAreas(areaId);
      setReports(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchReport();
  }, [areaId]);

  return (
    <div className="table-responsive">
      <div id="dataTable_wrapper">
        <table
          className="table no-footer dataTable"
          id="dataTable"
          width="100%"
          cellSpacing={0}
          aria-describedby="dataTable_info"
        >
          <thead>
            <tr>
              <th>STT</th>
              <th>Câu hỏi</th>
              <th>Báo cáo</th>
              <th>Số sao trung bình</th>
            </tr>
          </thead>
          <tbody>
            {reports?.map((report, index) => {
              return (
                <tr key={report?.id}>
                  <td>{index + 1}</td>
                  <td>{report?.question}</td>
                  <td>
                    {report?.report?.map((option, i) => {
                      return (
                        <div key={i}>
                          <div>
                            {option?.option}: (
                            <span className="text-danger">{option?.count}</span>
                            )
                          </div>
                        </div>
                      );
                    })}
                  </td>
                  <td>{report?.avg_star || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportReviews;
