import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UID_Loading from "./components/UID_Loading";
import history from "./history";
import Popup from "./components/Popup";
import StudyPage from "./pages/Study";
import FeedbackPage from "./pages/feedback";
import { constants as c, constants } from "./constants";
import { userActions } from "./actions/userActions";
import JobBoard from "./pages/Timekeeping/JobBoard";
import RequestPage from "./pages/Timekeeping/RequestPage";
import WorkSchedule from "./pages/Timekeeping/WorkSchedule";
import PutALot from "./pages/Timekeeping/WorkSchedule/PutALot";
import StaffContract from "./pages/Staff/StaffContract";
import EvaluationBranchPage from "./pages/Branch/EvaluationBranchPage";
import CustomerBranchEvaluationPage from "./pages/Branch/CustomerBranchEvaluationPage";
import Reviews from "./pages/Reviews";
import StaffEdit from "./pages/Staff/StaffEdit";
import ContactEdit from "./pages/Staff/ContractEdit";
import WorkLocation from "./pages/Timekeeping/WorkLocation";
import HistoryReviewStaff from "./pages/Staff/HistoryReview";
import ReviewsFromCustomer from "./pages/ReviewsFromCustomer";
import StaffPageTemp from "./pages/Timekeeping/Staff/StaffPage";
import Salary from "./pages/Timekeeping/Salary";
import ProfileNew from "./pages/Profile/ProfileNew";
import TimeKeepingHistory from "./pages/Timekeeping/TimeKeepingHistory";
import WorkScheduleEmployee from "./pages/Timekeeping/WorkScheduleEmployee";
import BonusConfig from "./pages/BonusConfig";
import AllowancesContract from "./pages/AllowancesContract";
import SetupOT from "./pages/SetupOT";
import Permisstion from "./pages/Permisstion";
import TimekeepingTable from "./pages/Timekeeping/ManagerTimekeeping";
import Procedure from "./pages/Procedure";
import FormPage from "./pages/Procedure/FormPage";
import { ToastContainer, toast } from "react-toastify";
import IncidenPage from "./pages/Incident";
import OperateFormPage from "./pages/Procedure/OperateFormPage";
import ReportFormPage from "./pages/Procedure/ReportFormPage";
import ModalNoti from "./components/ModalNoti";
import HistorySystemsPage from "./pages/HistorySystems";
import ReportPersonnelPage from "./pages/ReportSystem/ReportPersonalPage";
import ReportOperatePage from "./pages/ReportSystem/ReportOperatePage";
import ReportReportPage from "./pages/ReportSystem/ReportReportPage";
import AreaDetail from "./pages/Branch/AreaDetail";
import HelpPage from "./pages/Help/HelpPage";
import FranchisePage from "./pages/Franchise/FranchiseFile/FranchisePage";
import FranchiseReportPage from "./pages/Franchise/FranchiseReport/FranchiseReportPage";
import FranchiseMailPage from "./pages/Franchise/FranchiseMail/FranchiseMailPage";
import FranchiseCustomerPage from "./pages/Franchise/FranchiseCutomer/FranchiseCustomerPage";
import FranchiseMeetingPage from "./pages/Franchise/FranchiseMeeting/FranchiseMeetingPage";
import FranchisePostPage from "./pages/Franchise/FranchisePost/FranchisePostPage";
import FranchiseHelpPage from "./pages/Franchise/FranchiseHelp/FranchiseHelpPage";
import FranchiseContactPage from "./pages/Franchise/FranchiseContact/FranchiseContactPage";
import FranchiseRegisterPage from "./pages/Franchise/FranchiseRegister/FranchiseRegisterPage";
import ChatHome from "./pages/Chat";
import { Layout, Menu } from "antd";
import { getPermissionUtils, menu2 } from "./ultis/menu";
import HeaderProfile from "./components/HeaderProfile";
import ShiftManager from "./pages/Timekeeping/ShiftPage";
import QCEvaluateGroupPage from "./pages/QCEvalueGroup/QCEvaluateGroupPage";
import QCEvaluatePage from "./pages/QCEvalueGroup/QCEvaluate/QCEvaluatePage";
import QCEvaluateMainPage from "./pages/QCEvalueGroup/QCEvaluateMain/QCEvaluateMainPage";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { socket } from "./pages/Chat/socket/socket";
import ReportQualityControl from "./pages/ReportQualityControl";
import Probation from "./pages/Probation";
import FormProbationPage from "./pages/Probation/FormPage";
import FromUserHistoryPage from "./pages/FromUserHistory/FromUserHistoryPage";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const { Header, Content } = Layout;
const GroupTypePage = React.lazy(() => import("./pages/Train/GroupType"));
const HomePage = React.lazy(() => import("./pages/Home"));
const Banner = React.lazy(() => import("./pages/Banner"));
const QuizExamPage = React.lazy(() => import("./pages/Train/QuizExam"));

const StaffPage = React.lazy(() => import("./pages/Staff"));

const ReportCourseStaff = React.lazy(() =>
  import("./pages/Staff/Report/ReportCourse")
);

const ReportQuizByCourse = React.lazy(() =>
  import("./pages/Staff/Report/ReportQuizByCourse")
);
const ReportLessonByCourse = React.lazy(() =>
  import("./pages/Staff/Report/ReportLesson")
);
const StaffCreatePage = React.lazy(() => import("./pages/Staff/CreatePage"));
const StaffReportDetailPeriodic = React.lazy(() =>
  import("./pages/Staff/Report/Detail")
);
const StaffReportEvaluationDetail = React.lazy(() =>
  import("./pages/Staff/StaffReportEvaluationDetail")
);
const ReportCourseDetail = React.lazy(() =>
  import("./pages/Staff/ReportCourseDetail")
);
const StaffEditPage = React.lazy(() => import("./pages/Staff/EditPage"));
const StaffReportPage = React.lazy(() => import("./pages/Staff/ReportPage"));
const ReportEvaluationPage = React.lazy(() =>
  import("./pages/Staff/ReportEvaluationPage")
);
const ReportEvaluationDetailPage = React.lazy(() =>
  import("./pages/Staff/ReportEvaluationDetailPage")
);
const ReportTestCoursePage = React.lazy(() =>
  import("./pages/Staff/ReportTestCoursePage")
);
const StaffReportDetailPage = React.lazy(() =>
  import("./pages/Staff/ReportPageDetail")
);

const NewsPage = React.lazy(() => import("./pages/News"));
const NewsCreatePage = React.lazy(() => import("./pages/News/CreatePage"));
const NewsEditPage = React.lazy(() => import("./pages/News/EditPage"));

const EvaluationPage = React.lazy(() => import("./pages/Evaluation"));

const EvaluationCreatePage = React.lazy(() =>
  import("./pages/Evaluation/CreatePage")
);

const EvaluationEditPage = React.lazy(() =>
  import("./pages/Evaluation/EditPage")
);

const QuestionEvaluationPage = React.lazy(() =>
  import("./pages/Evaluation/Question")
);

const EvaluationQuizExamPage = React.lazy(() =>
  import("./pages/Evaluation/QuizExam")
);

const NewsCommentPage = React.lazy(() => import("./pages/NewsComment"));
const LessonCommentPage = React.lazy(() => import("./pages/LessonComment"));
const LessonDocumentPage = React.lazy(() => import("./pages/Document"));
const LessonReportUserWatchsPage = React.lazy(() =>
  import("./pages/ReportUserWatchs")
);

const NotificationPage = React.lazy(() => import("./pages/Notification"));
const NotificationCreatePage = React.lazy(() =>
  import("./pages/Notification/CreatePage")
);
const NotificationEditPage = React.lazy(() =>
  import("./pages/Notification/EditPage")
);

const DecentralizationPage = React.lazy(() =>
  import("./pages/Decentralization")
);
const DecentralizationCreatePage = React.lazy(() =>
  import("./pages/Decentralization/CreatePage")
);
const DecentralizationEditPage = React.lazy(() =>
  import("./pages/Decentralization/EditPage")
);

const CoursePage = React.lazy(() => import("./pages/Train/Course"));
const CourseDepartment = React.lazy(() =>
  import("./pages/Department/CourseDepartment")
);
const ManagerDepartment = React.lazy(() =>
  import("./pages/Department/ManagerDepartment")
);

const LessonPage = React.lazy(() => import("./pages/Train/Lesson"));

const LessonQuizPage = React.lazy(() => import("./pages/Train/LessonQuiz"));
const ChapterQuizPage = React.lazy(() => import("./pages/Train/ChapterQuiz"));

const TermQuizPage = React.lazy(() => import("./pages/Train/TermQuiz"));
const TestPeriGroupPage = React.lazy(() => import("./pages/Train/TestPeri"));
const QuestionPeriPage = React.lazy(() =>
  import("./pages/Train/TestPeri/Question_Peri/QuestionPeriPage")
);
const Coupon = React.lazy(() => import("./pages/Coupon"));
const LoginPage = React.lazy(() => import("./pages/Auth/LoginPage"));

const ForgotPasswordPage = React.lazy(() =>
  import("./pages/Auth/ForgotPassword/ForgotPasswordPage")
);
const Otp = React.lazy(() => import("./pages/Auth/ForgotPassword/Otp"));
const ReportTestPage = React.lazy(() =>
  import("./pages/Train/ReportTest/ReportTestPage")
);

const ScoreboardPage = React.lazy(() => import("./pages/ScoreboardPage"));

const QuestionPage = React.lazy(() => import("./pages/Train/Question"));
const DetailAnswerPage = React.lazy(() => import("./pages/Train/DetailAnswer"));

const ResultPage = React.lazy(() => import("./pages/Train/Result"));

const ChapterPage = React.lazy(() => import("./pages/Train/Chapter"));
const ProfilePage = React.lazy(() => import("./pages/Profile"));

const DepartmentPage = React.lazy(() => import("./pages/Department"));
const Branch = React.lazy(() => import("./pages/Branch"));

const NotFound = React.lazy(() => import("./components/NotFound"));

function App(props) {
  const location = window.location.pathname;

  const isLoginPage = [
    "/login",
    "/register",
    "/forgot_password/otp",
    "/forgot_password",
  ].includes(location);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const roomIdPrev = useRef();

  const firebaseConfig = {
    apiKey: "AIzaSyAK-22p3TrQ1ovqrdzSbnSlXLe0Qzdz1Cw",
    authDomain: "comnieu-app.firebaseapp.com",
    projectId: "comnieu-app",
    storageBucket: "comnieu-app.appspot.com",
    messagingSenderId: "15418002499",
    appId: "1:15418002499:web:4b9c72cd7ff0155acc58f6",
    measurementId: "G-PZGFTNC1M1",
  };

  async function updateFCMToken(data) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: tokenInfo ? tokenInfo : "",
      },
      body: JSON.stringify({
        fcm: data,
      }),
    };

    return fetch(
      `https://crm-api.studyguide.dev/api/personnel/${31}/notification/fcm`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => json)
      .catch((err) => {
        throw err;
      });
  }

  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey:
            "BHuyD5wio1TkJ6eagS9OpUu9VWpXcTnwrDDp6vcjTr4IO981dQwbcKcagkSolfP2MKTxhWepk0uYQkhte7WYNi4",
        }).then((currentToken) => {
          if (currentToken) {
            console.log("currentToken: ", currentToken);
            updateFCMToken(currentToken);
          } else {
            console.log("Can not get token");
          }
        });
      } else {
        console.log("Do not have permission!");
      }
    });
  }

  useEffect(() => {
    requestPermission();
    dispatch(userActions.getUserInfo());
  }, []);

  useEffect(() => {
    roomIdPrev.current = roomSelected?.id;
    const app = initializeApp(firebaseConfig);
    const messagings = getMessaging(app);
    if (messagings != null) {
      onMessage(messagings, (message) => {
        if (!socket.connected) {
          console.log("Đang thử kết nối lại socket ...");
          socket.disconnect();
          socket.connect();
        }

        console.log("Firebase Messaging!", message.data);
        console.log("message: ", message.data?.reference_value);
        console.log("message: ", roomIdPrev.current);

        if (message.data?.reference_value == roomIdPrev.current) {
          return;
        }
        new Notification(message.notification.title, {
          body: message.notification.body,
        });
      });
    }
  }, [roomSelected]);

  useEffect(() => {
    if (isLoginPage === false) {
      if (profile.status == c.FAILURE) {
        window.location.href = `/login`;
        return;
      }
    }
  });

  const ReconnectInterval = 60000;

  useEffect(() => {
    socket.connect();
    // Hàm để reconnect
    const reconnect = () => {
      console.log("Attempting to reconnect...");
      socket.disconnect(); // Ngắt kết nối
      socket.connect(); // Thực hiện lại kết nối
    };

    // Sự kiện khi kết nối thành công
    socket.on("connect", () => {
      console.log("Socket connected");
    });

    // Sự kiện khi kết nối bị mất
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    // Gọi hàm reconnect theo chu kỳ (1 phút)
    const reconnectInterval = setInterval(reconnect, ReconnectInterval);

    // Xóa interval khi component unmount để tránh memory leak
    return () => clearInterval(reconnectInterval);

    // socket.connect();
    // socket.on("connect", () => {
    //   console.log("socket connected");
    // });
    // socket.on("disconnect", () => {
    //   console.log("socket has been disconnected...");
    // });
  }, []);

  function showRoutes() {
    return (
      <React.Suspense fallback={<p></p>}>
        <Switch>
          <Route path="/login" exact component={LoginPage} />

          <Route path="/forgot_password" exact component={ForgotPasswordPage} />
          <Route path="/forgot_password/otp" exact component={Otp} />

          <Route path="/scoreboard" exact component={ScoreboardPage} />

          <Route path="/train/course" exact component={CoursePage} />
          <Route
            path="/department/course/:id"
            exact
            component={CourseDepartment}
          />
          <Route
            path="/department/manager/:id"
            exact
            component={ManagerDepartment}
          />

          <Route path="/banner_home" exact component={Banner} />
          <Route path="/lesson_docs/:id" exact component={LessonDocumentPage} />
          <Route
            path="/lesson_report_user_watchs/:id"
            exact
            component={LessonReportUserWatchsPage}
          />

          <Route
            path="/train/:courseId/chapter_quiz"
            exact
            component={ChapterQuizPage}
          />
          <Route path="/train/:id/lesson" exact component={LessonPage} />
          <Route path="/train/question" exact component={QuestionPage} />
          <Route
            path="/train/detail_answer/:id"
            exact
            component={DetailAnswerPage}
          />
          <Route path="/train/result" exact component={ResultPage} />
          <Route path="/train/term_quiz" exact component={TermQuizPage} />
          <Route path="/train/test_peri" exact component={TestPeriGroupPage} />
          <Route
            path="/train/test_peri/question-peri/:id"
            exact
            component={QuestionPeriPage}
          />
          <Route
            path="/train/test_peri/report"
            exact
            component={ReportTestPage}
          />
          <Route path="/train/group_type" exact component={GroupTypePage} />

          <Route
            path="/train/chapter/:courseId"
            exact
            component={ChapterPage}
          />

          <Route
            path="/decentralization"
            exact
            component={DecentralizationPage}
          />
          <Route
            path="/decentralization/create"
            component={DecentralizationCreatePage}
          />
          <Route
            path="/decentralization/edit/:id"
            component={DecentralizationEditPage}
          />

          <Route path="/staff" exact component={StaffPage} />
          <Route
            path="/staff/report/:staffId/report_course"
            exact
            component={ReportCourseStaff}
          />
          <Route
            path="/staff/report_quiz_by_course/:staffId"
            exact
            component={ReportQuizByCourse}
          />
          <Route
            path="/staff/report/:staffId/course/:courseId/report_lesson"
            exact
            component={ReportLessonByCourse}
          />
          <Route path="/staff/create" component={StaffCreatePage} />
          <Route
            path="/staff/report/:staffId/detail_report_periodic"
            component={StaffReportDetailPeriodic}
          />
          <Route
            path="/staff/report_evaluation/:id/detail_periodic"
            component={StaffReportEvaluationDetail}
          />
          <Route
            path="/staff/report_course_detail/:staffId"
            component={ReportCourseDetail}
          />
          {/* <Route path="/staff/edit/:id" component={StaffEditPage} /> */}
          <Route path="/staff/:id" component={StaffEdit} exact />
          <Route path="/contract/:id" component={ContactEdit} exact />
          <Route path="/staff/report/:id" exact component={StaffReportPage} />

          <Route
            path="/staff/report_evaluation/:id"
            exact
            component={ReportEvaluationPage}
          />
          <Route
            path="/staff/report_evaluation_detail/:id"
            exact
            component={ReportEvaluationDetailPage}
          />
          <Route
            path="/staff/:id/report_test_course/:termquiz_id"
            exact
            component={ReportTestCoursePage}
          />
          <Route
            path="/staff/report/detail/:id"
            component={StaffReportDetailPage}
          />

          <Route path="/staff/create" component={StaffCreatePage} />
          <Route path="/staff/edit/:id" component={StaffEditPage} />
          <Route path="/staff/report/:id" exact component={StaffReportPage} />
          <Route
            path="/staff/report_evaluation/:id"
            exact
            component={ReportEvaluationPage}
          />
          <Route
            path="/staff/report_evaluation_detail/:id"
            exact
            component={ReportEvaluationDetailPage}
          />
          <Route
            path="/staff/:id/report_test_course/:termquiz_id"
            exact
            component={ReportTestCoursePage}
          />
          <Route
            path="/staff/report/detail/:id"
            component={StaffReportDetailPage}
          />
          <Route path="/staff/review/:id" component={HistoryReviewStaff} />

          <Route path="/course/:id/study" exact component={StudyPage} />
          <Route path="/train/quiz_class/:id" exact component={QuizExamPage} />

          <Route path="/profile" component={ProfileNew} />

          <Route path="/news" exact component={NewsPage} />
          <Route path="/feedback" exact component={FeedbackPage} />

          <Route path="/news/create" component={NewsCreatePage} />
          <Route path="/news/edit/:id" component={NewsEditPage} />

          <Route path="/evaluation" exact component={EvaluationPage} />
          <Route path="/evaluation/create" component={EvaluationCreatePage} />
          <Route path="/evaluation/edit/:id" component={EvaluationEditPage} />
          <Route
            path="/evaluation/question"
            exact
            component={QuestionEvaluationPage}
          />
          <Route
            path="/evaluation/quiz_class/:id"
            exact
            component={EvaluationQuizExamPage}
          />

          <Route
            path="/qc-evaluate-main"
            exact
            component={QCEvaluateMainPage}
          />
          <Route
            path="/qc-evaluate-group/:id"
            exact
            component={QCEvaluateGroupPage}
          />
          <Route path="/qc-evaluate/:id" exact component={QCEvaluatePage} />
          <Route path="/news/comments" exact component={NewsCommentPage} />
          <Route path="/lesson/comments" exact component={LessonCommentPage} />

          <Route path="/notification" exact component={NotificationPage} />
          <Route
            path="/notification/create"
            component={NotificationCreatePage}
          />
          <Route
            path="/notification/edit/:id"
            component={NotificationEditPage}
          />

          <Route path="/department" component={DepartmentPage} />
          <Route path="/all-result" component={HomePage} />
          <Route
            path="/branch/evaluation/:id/customer/:idCustomer"
            component={CustomerBranchEvaluationPage}
          />
          <Route
            path="/branch/evaluation/:id"
            component={EvaluationBranchPage}
          />
          
          <Route path="/area-page" component={AreaDetail} />
          <Route path="/from-user-history-page" component={FromUserHistoryPage} />
          <Route path="/help-page" component={HelpPage} />
          <Route path="/franchise-files" component={FranchisePage} />
          <Route path="/franchise-meetings" component={FranchiseMeetingPage} />
          <Route path="/franchise-reports" component={FranchiseReportPage} />
          <Route path="/franchise-customer" component={FranchiseCustomerPage} />
          <Route path="/franchise-mails" component={FranchiseMailPage} />
          <Route path="/franchise-post" component={FranchisePostPage} />
          <Route path="/franchise-help" component={FranchiseHelpPage} />
          <Route path="/franchise-contact" component={FranchiseContactPage} />
          <Route path="/franchise-register" component={FranchiseRegisterPage} />
          <Route path="/branch" component={Branch} />
          <Route path="/work-shift" component={ShiftManager} />
          <Route path="/job-board" component={JobBoard} />
          <Route path="/request-processing" component={RequestPage} />
          <Route path="/personnel-form" component={FormPage} />
          <Route path="/probation-review" component={FormProbationPage} />
          <Route path="/operate-form" component={OperateFormPage} />
          <Route path="/report-form" component={ReportFormPage} />
          <Route path="/personnel-report" component={ReportPersonnelPage} />
          <Route path="/operate-report" component={ReportOperatePage} />
          <Route path="/report-report" component={ReportReportPage} />
          <Route path="/incident-page" component={IncidenPage} />
          <Route path="/history-systems" component={HistorySystemsPage} />
          <Route path="/work-chedule" exact component={WorkSchedule} />
          <Route path="/work-chedule/putALot" exact component={PutALot} />
          <Route path="/staff-contract" exact component={StaffContract} />
          <Route path="/work-location" component={WorkLocation} />
          <Route path="/staff-temp" component={StaffPageTemp} />
          <Route path="/salary" component={Salary} />
          <Route path="/timekeeping-history" component={TimeKeepingHistory} />
          <Route path="/timekeeping-table" component={TimekeepingTable} />
          <Route
            path="/work-schedule-employee"
            component={WorkScheduleEmployee}
          />
          {/* <Route path="/time-keeping" component={Timekeeping} /> */}

          <Route
            path="/reviews-from-customers"
            component={ReviewsFromCustomer}
          />
          <Route
            path="/report-quality-control"
            component={ReportQualityControl}
          />
          <Route path="/bonus-config" component={BonusConfig} />
          <Route path="/allowances-contract" component={AllowancesContract} />
          <Route path="/setup-ot" component={SetupOT} />
          <Route path="/permission" component={Permisstion} />
          <Route path="/procedure" component={Procedure} />
          <Route path="/probation" component={Probation} />
          <Route path="/coupon" component={Coupon} />
          <Route path="/reviews" exact component={Reviews} />
          <Route path="/" exact component={StaffPageTemp} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </React.Suspense>
    );
  }

  return (
    <div>
      {(profile.status === c.SUCCESS || isLoginPage === true) && (
        <Router history={history}>
          <React.Fragment>
            <div id="wrapper">
              <Popup></Popup>
              <ModalNoti></ModalNoti>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <UID_Loading></UID_Loading>
              {isLoginPage === true ? (
                showRoutes()
              ) : (
                <>
                  <div className="col-12 col-12-wrapper">
                    <Header
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1049,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        borderBottom: "1px solid #0505050f",
                        height: "50px",
                        padding: "0 20px",
                        paddingInline: "0px",
                      }}
                    >
                      <a href="">
                        <img
                          src={`${constants.PATH_LOGO}`}
                          alt=""
                          style={{
                            borderRadius: "20%",
                            width: "45px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <Menu
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={["/staff-temp"]}
                        style={{ lineHeight: "50px", width: "100%" }}
                        items={menu2}
                        onClick={(e) => {
                          console.log(e);
                          var permission = getPermissionUtils(
                            e.key,
                            reportNumber.permissions
                          );
                          if (permission === true) {
                            history.push(e.key);
                          } else {
                            toast.error("Bạn không có quyền truy cập");
                          }
                        }}
                      />
                      <div style={{ flex: 1 }}></div>
                      <HeaderProfile></HeaderProfile>
                    </Header>
                    <div
                      id="content-wrapper"
                      className="d-flex flex-column mt-2"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="content">{showRoutes()}</div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>
        </Router>
      )}
      <ChatHome />
    </div>
  );
}
export default App;
