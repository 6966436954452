import React from "react";
import { useSelector } from "react-redux";
import { formatMoneyVND } from "../../../../helpers";

const ItemEmployee = ({ employee, handleRemoveEmployee }) => {
  const areas = useSelector((state) => state.area.areas);

  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  return (
    <tr className="hover-product even">
      <td>{employee?.name}</td>
      <td>{employee?.staff_code}</td>
      <td>{employee?.phone_number}</td>
      <td>{formatMoneyVND(employee?.contract?.salary ?? 0, true)}₫</td>
      <td>{getAreaById(employee?.area_id).name}</td>
      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          onClick={() => handleRemoveEmployee(employee?.id)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          xóa
        </button>
      </td>
    </tr>
  );
};

export default ItemEmployee;
