/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const AllowancesContractModal = ({
  visible,
  title,
  allowancesContractDetail,
  onSubmit,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    console.log("visible");
    if (visible) {
      console.log(allowancesContractDetail);
      reset(allowancesContractDetail);
    } else {
      console.log("dasda");
      reset({ title: "", description: "", value: null });
    }
  }, [allowancesContractDetail, visible]);

  const setStyleValidate = (name) =>
    errors[name] ? { border: "1px solid red" } : null;

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="form-group">
            <label>
              Tên phụ cấp <span className="text-danger">*</span>
            </label>
            <input
              name="title"
              type="text"
              className="form-control"
              placeholder="Nhập tên cấu hình thưởng"
              autoComplete="off"
              style={setStyleValidate("title")}
              {...register("title", {
                required: true,
              })}
            />
            {errors?.title?.type === "required" && (
              <small className="text-danger">Vui lòng nhập tên phụ cấp</small>
            )}
          </div>
          <div className="form-group">
            <label>Mô tả phụ cấp</label>
            <input
              name="title"
              type="text"
              className="form-control"
              placeholder="Nhập mô tả"
              autoComplete="off"
              style={setStyleValidate("description")}
              {...register("description", {
                required: false,
              })}
            />
          </div>
          <div className="form-group">
            <label>
              Số tiền thưởng <span className="text-danger">*</span>
            </label>
            <input
              name="value"
              type="number"
              className="form-control"
              placeholder="Nhập số tiền thưởng"
              autoComplete="off"
              style={setStyleValidate("value")}
              {...register("value", {
                required: true,
              })}
            />
            {errors?.value?.type === "required" && (
              <small className="text-danger">
                Vui lòng nhập số tiền thưởng
              </small>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button type="submit" className="btn btn-info">
            Lưu
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AllowancesContractModal;
