import styled from "styled-components";

export const StyledMessage = styled.div`
  width: 100%;
  .message {
    position: relative;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    &.isSamePerson {
      margin-bottom: 4px;
    }
    &.isShowAvatar {
      .message-avatar {
        visibility: hidden;
      }
    }
    .message-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 1px 2px #00000033;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .message-content {
      position: relative;
      width: fit-content;
      max-width: 550px;
      border-radius: 8px;
      margin-top: 8px;
      .message-show-emotion {
        cursor: pointer;
        position: absolute;
        bottom: -10px;
        right: -10px;
        background: white;
        box-shadow: 0 1px 2px #00000033;
        padding: 2px 6px;
        border-radius: 20px;
        display: flex;
        gap: 2px;
        align-items: center;

      }
      .message-revoked {
        padding: 10px 12px;
        background-color: #fff;
        color: #7589a3;
        box-shadow: 0 1px 2px #00000033;
        border-radius: 8px;
      }
      &.hide-all {
        margin-top: 0px;
      }
      .message-reply {
        padding: 6px 8px;
        background-color: #C7E0FF;
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        &__content {
          font-size: 12px;
          padding-left: 6px;
          border-left: 3px solid #3390EC;
        }
      }
      &__name {
        font-size: 12px;
        color: #7589a3;
      }
      &__content {
        font-size: 14px;
        color: #081c36;
        img {
          max-width: 275px;
          object-fit: cover;
          // border-radius: 8px;
          height: 100%;
        }
      }
      &__time {
        font-size: 12px;
        color: #476285;
        text-align: left;
        margin-top: 4px;
      }
      &.isText {
        background-color: #fff;
        box-shadow: 0 1px 2px #00000033;
        padding: 12px;
        margin-top: 0px;
        .message-content__time {
          margin-top: 20px;
        }
        .message-revoked {
          padding: 0;
          box-shadow: none;
        }
      }
      &__action {
        position: absolute;
        right: -60px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ffffff80;
        padding: 2px;
        border-radius: 50%;
        display: none;
      }
      &__emotion {
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ffffff80;
        box-shadow: 0 1px 2px #00000033;
        padding: 2px;
        border-radius: 50%;
        display: none;
      }
    }
    .action-item {
      display: flex;
      gap: 12px;
      .action-icon {
        width: 20px;
        height: 20px;
      }
    }
    &.send {
      justify-content: end;
      .message-content.isText {
        background-color: #e5efff;
      }
      .message-content__name {
        display: none;
        height: 0;
      }
      .message-content__action{
        left: -60px;
        right: unset;
      }
      .message-content__emotion{
        left: -30px;
        right: unset;
      }
      .message-revoked {
        padding: 10px 12px;
        background-color: #e5efff;
      }
    }
    &:hover {
      .message-content__action {
        display: block;
        cursor: pointer;
      }
    }
    &:hover {
      .message-content__emotion {
        display: block;
        cursor: pointer;
      }
    }
    .list-emotion {
      background-color: #fff;
      border-radius: 8px;
      padding: 4px 8px;
      display: flex;
      gap: 4px;
      box-shadow: 0 1px 2px #00000033;
    }
    
  }
`;
