import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatMoneyVND } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";

const Contact = ({ profile }) => {
  const [contract, setContract] = useState(null);
  const userInfo = useSelector((state) => state.user.userInfo);

  const fetchContract = async () => {
    try {
      const response = await shiftServices.fetchContactDetail(
        profile.area_id,
        profile.id
      );
      setContract(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchContract();
  }, []);

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Loại hợp đồng*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={contract?.type_label}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Hình thức lương*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={contract?.salary_label}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày bắt đầu*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={contract?.start}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Ngày kết thúc*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={contract?.end}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Mức lương theo giờ*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={formatMoneyVND(contract?.salary || 0)}
              />
            </div>
          </div>
          {contract?.type_key === "full_time" && (
            <>
              <div className="col-3">
                <div className="form-group">
                  <label>Lương P2*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off" 
                    disabled
                    value={formatMoneyVND(contract?.support_productive)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Lương P3*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.support_result)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Phụ cấp cơm trưa*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.pc_food)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Phụ cấp di chuyển*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.pc_move)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Phụ cấp điện thoại*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.pc_phone)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Lương vị trí P1*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.position_salary)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Số người phụ thuộc*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={contract?.dependent_count || 0}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Khoản miễn thuế khác*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.dependent_another || 0)}
                  />
                </div>
              </div>
              {/* <div className="col-3">
                <div className="form-group">
                  <label>Tổng mức lương*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    disabled
                    value={formatMoneyVND(contract?.total_salary || 0)}
                  />
                </div>
              </div> */}
              <div className="col-3 mt-1">
            <div className="form-group">
              <label>Bảo hiểm*</label>
              <div className="mb-2">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  checked={contract?.BHYT}
                />
                <span>Bảo hiểm y tế </span>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  checked={contract?.BHXH}
                />
                <span>Bảo hiểm xã hội </span>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  checked={contract?.BHTN}
                />
                <span>Bảo hiểm thất nghiệp </span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Thuế*</label>
              <div className="mb-2">
                <input
                  type="radio"
                  checked={contract?.tax === "CK" ? true : false}
                />
                <span> Cam kết tự đóng thuế</span>
              </div>
              <div className="mb-2">
                <input
                  type="radio"
                  checked={contract?.tax === "KT" ? true : false}
                />
                <span> Khấu trừ 10%</span>
              </div>
              <div className="mb-2">
                <input
                  type="radio"
                  checked={contract?.tax === "LT" ? true : false}
                />
                <span>
                  Giảm trừ bản thân 11tr và gia cảnh 4,4tr/người (nếu có)
                </span>
              </div>
            </div>
          </div>
            </>
          )}
          <div className="col-3">
            <div className="form-group">
              <label>Số ngày làm việc*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
                value={contract?.count_day_work}
              />
            </div>
          </div>
       
          {/* <div className="col-3">
            <div className="form-group">
              <label>Phụ cấp*</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                autoComplete="off"
                disabled
              />
            </div>
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default Contact;
