import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getStaffSubOverview({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/staff_sub_overview${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}


function getNumberStaff(areaId) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/number-staff`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getStaffByArea() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/statistic/staff_by_area`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getStaffChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/statistic/chart/staff${param}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getLeaveStaffChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/leave_staff${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProbationStaffChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/probation_staff${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}


function getWorkingHoursChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/working_hours${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getWorkingOvertimeChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/working_overtimes${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeRewardsChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_rewards${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeSalaryChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_adjust_salary${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeaAvancementChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_advancement${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeDisciplineChart({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_discipline${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposePayment({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_payment${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeRepair({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_repair${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeHrSupport({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_hr_support${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeBuyEquipment({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_buy_equipment${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeCancelOrder({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_cancel_order${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getRateCancelOrder({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/rate_cancel_order${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProposeTransportationStuff({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/propose_transportation_stuff${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getReportRevenue({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/report_revenue${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getReportBudget({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/report_budget${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getReportIncidents({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/report_incidents${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getCancelOrderByArea({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/statistic/chart/cancel_order_by_area${param}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

export const reportSysServices = {
  getStaffSubOverview,
  getStaffByArea,
  getStaffChart,
  getLeaveStaffChart,
  getProbationStaffChart,
  getWorkingHoursChart,
  getWorkingOvertimeChart,
  getProposeRewardsChart,
  getProposeSalaryChart,
  getProposeaAvancementChart,
  getProposeDisciplineChart,
  getProposePayment,
  getProposeRepair,
  getProposeHrSupport,
  getProposeBuyEquipment,
  getProposeCancelOrder,
  getRateCancelOrder,
  getProposeTransportationStuff,
  getReportRevenue,
  getReportBudget,
  getReportIncidents,
  getCancelOrderByArea,
  getNumberStaff,
};
