import { constants as c } from "../constants";

const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  user_watchs_list: {
    data: [],
    status: c.NONE,
  },
  quiz_deparment_list: {
    data: [],
    status: c.NONE,
  },
  report_quizs_staff: {
    data: {},
    status: c.NONE,
  },
  report_evaluation: {
    data: {},
    status: c.NONE,
  },
  report_evaluation_detail: {
    data: [],
    status: c.NONE,
  },
  report_course_staff: {
    data: [],
    status: c.NONE,
  },
  report_lesson_by_course: {
    data: [],
    status: c.NONE,
  },

  report_course_detail: {
    data: [],
    status: c.NONE,
  },
  info: {
    detail: {},
    status: c.NONE,
  },
  history_quiz: {
    data: [],
    status: c.NONE,
  },
  report_quizs_overview: {
    dataMock: [],
    status: c.NONE,
  },
  total_overview_course : {
    status : c.NONE
  },
  total_overview_term_quiz : {
    status : c.NONE
  },
  total_overview_evaluation: { 
    status : c.NONE
  },
  report_quizs_by_course : {
    data : [],
    status : c.NONE
  }
};

export function report(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_REPORT_STAFFS_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        list: {
          data: action.data,
          status: c.SUCCESS,
        },
     
      };
      

      case c.GET_ALL_REPORT_QUIZS_BY_COURSE_SUCCESS:
        console.log("action >>>", action);
        return {
          ...state,
          report_quizs_by_course: {
            data : action.data,
            status: c.SUCCESS,
          },
        };
        case c.GET_ALL_REPORT_QUIZS_BY_COURSE_FAILURE:
          console.log("action >>>", action);
          return {
            ...state,
            report_quizs_by_course: {
              data : [],
              status: c.NONE,
            },
          };
   

      case c.GET_TOTAL_OVERVIEW_EVALUATE_SUCCESS:
        console.log("action >>>", action);
        return {
          ...state,
          total_overview_evaluation: {
            ...action.data,
            status: c.SUCCESS,
          },
        };
        case c.GET_TOTAL_OVERVIEW_EVALUATE_FAILURE:
          console.log("action >>>", action);
          return {
            ...state,
            total_overview_evaluation: {
              status: c.NONE,
            },
          };
   
    
      case c.GET_TOTAL_OVERVIEW_COURSE_SUCCESS:
        console.log("action >>>", action);
        return {
          ...state,
          total_overview_course: {
            ...action.data,
            status: c.SUCCESS,
          },
        };
        case c.GET_TOTAL_OVERVIEW_COURSE_FAILURE:
          console.log("action >>>", action);
          return {
            ...state,
            total_overview_course: {
              status: c.NONE,
            },
          };
   

          case c.GET_TOTAL_OVERVIEW_TERM_QUIZ_SUCCESS:
            console.log("action >>>", action);
            return {
              ...state,
              total_overview_term_quiz: {
                ...action.data,
                status: c.SUCCESS,
              },
            };
            case c.GET_TOTAL_OVERVIEW_TERM_QUIZ_FAILURE:
              console.log("action >>>", action);
              return {
                ...state,
                total_overview_term_quiz: {
                  status: c.NONE,
                },
              };
       

          case c.GET_REPORT_USER_WATCHS_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        user_watchs_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_REPORT_USER_WATCHS_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        user_watchs_list: {
          data: [],
          status: c.NONE,
        },
      };
    case c.GET_ALL_REPORT_QUIZ_DEPARMENT_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        quiz_deparment_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_REPORT_QUIZS_STAFF_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_quizs_staff: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_REPORT_EVALUATION_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_evaluation: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_REPORT_EVALUATION_DETAIL_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_evaluation_detail: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_REPORT_LESSON_BY_COURSE_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_lesson_by_course: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_REPORT_LESSON_BY_COURSE_FAILURE:
      console.log("action >>>", action);
      return {
        ...state,
        report_lesson_by_course: {
          data: [],
          status: c.FAILURE,
        },
      };
    case c.RESET_ALL_REPORT_LESSON_BY_COURSE_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_lesson_by_course: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_ALL_REPORT_COURSE_STAFF_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_course_staff: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_REPORT_COURSE_STAFF_DETAIL_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        report_course_detail: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_ALL_REPORT_COURSE_STAFF_DETAIL_STATUS:
      return {
        ...state,
        report_course_detail: {
          data: [],
          status: c.NONE,
        },
      };

    case c.RESET_ALL_REPORT_QUIZ_STAFF_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_quizs_staff: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_ALL_REPORT_COURSE_STAFF_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_course_staff: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_ALL_REPORT_EVALUATION_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_evaluation: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_ALL_REPORT_EVALUATION_DETAIL_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_evaluation_detail: {
          data: [],
          status: c.NONE,
        },
      };
    case c.GET_REPORT_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };
    case c.GET_REPORT_EVALUATION_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };

    case c.GET_REPORT_EVALUATION_DETAIL_FAILURE:
      return {
        ...state,
        report_evaluation_detail: {
          data: [],
          status: c.FAILURE,
        },
      };
    case c.GET_ALL_HISTORY_QUIZ_SUCCESS:
      console.log("action >>>", action);
      return {
        ...state,
        history_quiz: {
         ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_HISTORY_QUIZ_FAILURE:
      console.log("action >>>", action);
      return {
        ...state,
        history_quiz: {
          data: [],
          status: c.FAILURE,
        },
      };
    case c.RESET_ALL_HISTORY_QUIZ_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        history_quiz: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_OVERVIEW_REPORT_QUIZS_SUCCESS:
      return {
        ...state,
        report_quizs_overview: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_OVERVIEW_REPORT_QUIZS_FAILURE:
      console.log("action >>>", action);
      return {
        ...state,
        report_quizs_overview: {
          status: c.FAILURE,
        },
      };
    case c.RESET_OVERVIEW_REPORT_QUIZS_STATUS:
      console.log("action >>>", action);
      return {
        ...state,
        report_quizs_overview: {
          dataMock: [],
          status: c.NONE,
        },
      };

    default:
      return state;
  }
}
