/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-ios-switch";
import { useForm } from "react-hook-form";
import { procedureServices } from "../../services/procedureServices";
import { constants } from "../../constants";
import PermissionChecker from "../../components/PermissionChecker";
import { toast } from "react-toastify";

const ConfigProcedure = ({ configProcedure, callBack }) => {
  const [config, setConfig] = useState(null);
  const [settingLeave, setSettingLeave] = useState(null);
  const [init, setInit] = useState(true);
  const dispatch = useDispatch();
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const {
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setConfig(configProcedure);
  }, [configProcedure]);

  useEffect(() => {
    reset({
      minimum_day: config?.minimum_day,
      number_request: config?.number_request,
    });
    if (config?.type === "leave") {
      getSettingLeave();
    }
    if (config?.type === "advance_salary") {
      reset({
        minimum_day: config?.minimum_day,
        number_request: config?.number_request,
        salary_advance_min_day: config?.salary_advance_min_day,
        salary_advance_date: config?.salary_advance_date,
        percent: config?.percent,
      });
    }
    if (config?.type === "overtime") {
      reset({
        minimum_day: config?.minimum_day,
        number_request: config?.number_request,
        scale_sunday: config?.scale_sunday,
        scale_normal: config?.scale_normal,
      });
    }
  }, [config]);

  const updateConfig = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.updateConfig({
        id: config?.id,
        data: config,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      toast.success("Cập nhật thành công");
      callBack();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const updateSettingLeave = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.updateSettingLeave({
        data: settingLeave,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getSettingLeave = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.getSettingLeave();
      setSettingLeave({
        unpaid_leave: response.data[0]?.value,
        compassionate_leave: response.data[1]?.value,
        marriage_leave: response.data[2]?.value,
      });
      reset({
        unpaid_leave: response.data[0]?.value,
        compassionate_leave: response.data[1]?.value,
        marriage_leave: response.data[2]?.value,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const componentAdvanceSalary = () => {
    return (
      <div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Ngày duyệt lương hàng tháng&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  max="31"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("salary_advance_date", {
                    onChange: (e) => {
                      setConfig({
                        ...config,
                        salary_advance_date: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Phần trăm được ứng lương&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  max="100"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("percent", {
                    onChange: (e) => {
                      setConfig({
                        ...config,
                        percent: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Số ngày làm tối thiểu&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("salary_advance_min_day", {
                    onChange: (e) => {
                      setConfig({
                        ...config,
                        salary_advance_min_day: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const componentOvertime = () => {
    return (
      <div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Hệ số tăng ca ngày thường&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  max="10"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("scale_normal", {
                    onChange: (e) => {
                      setConfig({
                        ...config,
                        scale_normal: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Hệ số tăng ca ngày chủ nhật&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  max="10"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("scale_sunday", {
                    onChange: (e) => {
                      setConfig({
                        ...config,
                        scale_sunday: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const componentLeave = () => {
    return (
      <div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Số ngày nghỉ không hưởng lương (Mặc định)&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("unpaid_leave", {
                    onChange: (e) => {
                      setSettingLeave({
                        ...settingLeave,
                        unpaid_leave: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Số ngày nghỉ tang người thân (Mặc định)&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("compassionate_leave", {
                    onChange: (e) => {
                      setSettingLeave({
                        ...settingLeave,
                        compassionate_leave: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-input-item mb-3" style={{ flex: "1 1 0%" }}>
          <div className="w-100 align-items-start d-flex ant-input--custom ">
            <div
              className="color-gray-10 fw-semi-bold"
              style={{
                width: "25%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Số ngày nghỉ kết hôn (Mặc định)&nbsp;
            </div>
            <div className="col-3">
              <div className="form-group">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  {...register("marriage_leave", {
                    onChange: (e) => {
                      setSettingLeave({
                        ...settingLeave,
                        marriage_leave: e.target.value,
                      });
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
      style={{ marginLeft: "0%" }}
    >
      <div
        role="tabpanel"
        aria-hidden="false"
        className="ant-tabs-tabpane ant-tabs-tabpane-active pt-3"
      >
        <div
          tabIndex={0}
          role="presentation"
          style={{
            width: "0px",
            height: "0px",
            overflow: "hidden",
            position: "absolute",
          }}
        />
        <div
          className="mb-3"
          style={{ fontSize: "18px", fontWeight: "700", color: "#10a0b5" }}
        >
          Cấu hình
        </div>
        <div>
          <div
            className="ant-card mx-2 mt-1 mb-3 color-gray-13 w-70 ant-card-bordered"
            style={{ borderRadius: "10px", borderColor: "var(--color-gray-3)" }}
          >
            <div className="ant-card-body">
              <div
                className="custom-input-item mb-3"
                style={{ flex: "1 1 0%" }}
              >
                <div className="w-100 align-items-start d-flex justify-content-between  ">
                  <div
                    className="color-gray-10 fw-semi-bold"
                    style={{
                      width: "25%",
                      marginTop: "10px",
                      marginBottom: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Trạng thái&nbsp;
                  </div>
                  <div
                    style={{
                      width: "75%",
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <Switch
                      checked={config?.is_active ?? false}
                      onChange={(checked) => {
                        if (config?.is_active === true) {
                          setConfig({ ...config, is_active: false });
                        } else {
                          setConfig({ ...config, is_active: true });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="custom-input-item mb-3"
                  style={{ flex: "1 1 0%" }}
                >
                  <div className="w-100 align-items-start d-flex ant-input--custom ">
                    <div
                      className="color-gray-10 fw-semi-bold"
                      style={{
                        width: "25%",
                        marginTop: "10px",
                        marginBottom: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      Số ngày yêu cầu trước khi gửi đơn&nbsp;
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          placeholder=""
                          autoComplete="off"
                          {...register("minimum_day", {
                            onChange: (e) => {
                              setConfig({
                                ...config,
                                minimum_day: e.target.value,
                              });
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="custom-input-item mb-3"
                  style={{ flex: "1 1 0%" }}
                >
                  <div className="w-100 align-items-start d-flex ant-input--custom ">
                    <div
                      className="color-gray-10 fw-semi-bold"
                      style={{
                        width: "25%",
                        marginTop: "10px",
                        marginBottom: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      Số yêu cầu giới hạn gửi lên trong tháng
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          placeholder=""
                          autoComplete="off"
                          {...register("number_request", {
                            onChange: (e) => {
                              setConfig({
                                ...config,
                                number_request: e.target.value,
                              });
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {config?.type === "leave" ? componentLeave() : <></>}
                {config?.type === "advance_salary" ? (
                  componentAdvanceSalary()
                ) : (
                  <></>
                )}
                {config?.type === "overtime" ? componentOvertime() : <></>}
                <div className="d-flex justify-content-end">
                  {PermissionChecker({
                    decent:
                      reportNumber.permissions?.config_procedure_edit ?? false,
                    child: (
                      <button
                        className="btn btn-success mr-2"
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          updateConfig();
                          if (config?.type === "leave") {
                            updateSettingLeave();
                          }
                        }}
                      >
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>Lưu
                      </button>
                    ),
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          tabIndex={0}
          role="presentation"
          style={{
            width: "0px",
            height: "0px",
            overflow: "hidden",
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
};

export default ConfigProcedure;
