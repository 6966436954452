import styled from "styled-components";

export const StyledModalSelectUser = styled.div`
  .ant-modal-content {
    padding: 16px;
  }
  .modal-content-container {
    padding: 16px;
  }
  .room-chat-item-container {
    margin-top: 8px;
    &:hover {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
  .room-chat-list {
    margin-top: 8px;
    height: 350px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      border-radius: 10px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d1e3f9;
      opacity: 0.5;
    }
  }
  .text-truncate-1 {
    display: block;
    // display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 280px;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
  }
`;
