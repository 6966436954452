/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Map = ({ latitude, longitude }) => {
  const url = `https://maps.google.com/maps?q=${latitude},${longitude}&output=embed`;

  return (
    <iframe
      src={url}
      className="maps-content"
      height="450"
      frameborder="0"
      allowfullscreen
    ></iframe>
  );
};

export default Map;
