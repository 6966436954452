import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import moment from "moment";
import { reportSysServices } from "../../../services/reportSysServices";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDetailOperateModal from "./child/ChartDetailOperateModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReportReportPage = () => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  useEffect(() => {
    getReportRevenue();
    getReportBudget();
    getReportIncidents();
    getReportInformation();
  }, [areaId]);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const [dataRevenue, setDataRevenue] = useState(null);
  const [datacash, setDataCash] = useState(null);
  const [dataTransfer, setDataTransfer] = useState(null);
  const [dataSwipe, setDataSwipe] = useState(null);
  const [dataShopee, setDataShopee] = useState(null);
  const [dataGrab, setDataGrab] = useState(null);
  const [dataBeamin, setDataBeamin] = useState(null);
  const [dataBefood, setDataBefood] = useState(null);
  const [dataOther, setDataOther] = useState(null);

  const [dataBudget, setDataBubget] = useState(null);
  const [dataIncident, setDataIncident] = useState(null);
  const [dataInfomation, setDataInfomation] = useState(null);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const getReportRevenue = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getReportRevenue({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );
        setDataRevenue({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_revenue ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataCash({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu tiền mặt`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_cash ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataTransfer({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu chuyển khoản`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_money_transfer ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataSwipe({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu quẹt thẻ`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_money_swipe ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataShopee({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu Shopee`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_shopee ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataGrab({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu Grab`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_grab ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataBeamin({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu Baemin`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_beamin ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataBefood({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu Befood`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_befood ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataOther({
          labels,
          datasets: [
            {
              label: `Tổng doanh thu khác`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.total_other_revenue ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        setDataRevenue({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_revenue ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataCash({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_cash ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataTransfer({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_money_transfer ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataSwipe({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_money_swipe ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataShopee({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_shopee ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataGrab({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_grab ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataBeamin({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_beamin ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataBefood({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_befood ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });

        setDataOther({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.total_befood ?? 0
              ),
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getReportBudget = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getReportBudget({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataBubget({
          labels,
          datasets: [
            {
              label: `Tổng số tiền tip`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money_tip ?? 0
        );

        setDataBubget({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };
  const getReportIncidents = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getReportIncidents({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataBubget({
          labels,
          datasets: [
            {
              label: `Tổng số biên bản xử phạt`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataIncident({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const getReportInformation = async () => {
    var from_time = getFirstAndLastDayInMonth().firstDay;
    var to_time = getFirstAndLastDayInMonth().lastDay;

    try {
      const response = await reportSysServices.getReportIncidents({
        param: `?area_id=${areaId}&from_time=${moment(
          from_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&to_time=${moment(
          to_time ?? Date()
        ).format(constants.FORMAT_YYYY_MM_DD)}&type=day&is_incident=false`,
      });

      if (areaId == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataBubget({
          labels,
          datasets: [
            {
              label: `Tổng số thông tin đã gửi`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        setDataInfomation({
          labels,
          datasets: [
            {
              label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
    }
  };

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const options = {
    responsive: true,
    aspectRatio: 1.7,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tổng số nhân viên toàn hệ thống",
      },
    },
  };

  const chartWidget = (title, dataChart, type) => {
    var options = {
      responsive: true,
      scales: {
        xAxes: {
          barPercentage: 1.6,
          grid: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            font: {
              size: 11,
            },

            major: {
              enable: false,
            },
          },
        },
      },
      aspectRatio: 1.5,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: title,
        },
      },
    };
    return (
      <div className="col-6 mb-5">
        <Bar
          options={options}
          onClick={() => {
            setModal({
              method: "DEATAIL",
              type: type,
              visible: true,
            });
          }}
          data={dataChart}
        />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Thống kê báo cáo
        </h4>
      </div>
      <div className="row mb-2 pt-4">
        {dataRevenue == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu", dataRevenue, constants.REVENUE)
        )}
        {datacash == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu tiền mặt", datacash, constants.CASH)
        )}
        {dataTransfer == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng doanh thu chuyển khoản",
            dataTransfer,
            constants.MONEY_TRANSFER
          )
        )}
        {dataSwipe == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng doanh thu quẹt thẻ",
            dataSwipe,
            constants.MONEY_SWIPE
          )
        )}
        {dataShopee == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu Shopee", dataShopee, constants.SHOPEE)
        )}
        {dataGrab == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu Grab", dataGrab, constants.GRAB)
        )}
        {dataBeamin == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu Beamin", dataBeamin, constants.BEAMIN)
        )}
        {dataBefood == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu Befood", dataBefood, constants.BEFOOD)
        )}
        {dataOther == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu khác", dataOther, constants.ORTHER)
        )}
        {dataBudget == null ? (
          <></>
        ) : (
          chartWidget("Tổng doanh thu nộp về công ty", dataBudget, constants.BUDGET)
        )}
        {dataIncident == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số nhân viên nhân bị lập biên bản",
            dataIncident,
            constants.INCIDENT
          )
        )}
        {dataInfomation == null ? (
          <></>
        ) : (
          chartWidget(
            "Tổng số thông tin đã gửi",
            dataInfomation,
            constants.INCIDENT_INFORMATION
          )
        )}
      </div>
      <ChartDetailOperateModal
        open={modal.visible && modal.method === "DEATAIL"}
        type={modal.type}
        closeModal={() => {
          handleCloseModal();
        }}
      ></ChartDetailOperateModal>
    </div>
  );
};

export default ReportReportPage;
