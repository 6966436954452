import { constants as c } from "../constants";

import axios from "axios";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function uploadImg(formData) {
  const instance = axios.create({
    baseURL: c.API_URL,
    headers: { token: tokenInfo, "Content-Type": "multipart/form-data" },
  });

  return instance.post(`/upload_v2/files`, formData).then((response) => {
    return response.data;
  });
}



function uploadDocs(formData) {
  const instance = axios.create({
    baseURL: c.API_URL,
    headers: { 'token': tokenInfo, 'Content-Type': 'multipart/form-data' },
  });

  return instance.post(`/upload_v2/files?type=doc`, formData)
    .then(response => {
      return response.data;
    })
}

function updateStatusOnl(info) {
  // const requestOptions = {
  //    method: "POST",
  //    headers: {
  //       "Content-Type": "application/json",
  //       token: tokenInfo ? tokenInfo : "",
  //    },
  //    body: JSON.stringify(info),

  // };
  // return fetch(`${c.API_URL}/check_online`, requestOptions)
  //    .then((res) => res.json())
  //    .then((json) => {
  //       console.log(json);
  //       return json;
  //    })
  //    .catch((err) => {
  //       console.log(err);
  //       return {};
  //    });
}



export const appServices = {
  uploadImg,
  uploadDocs,
  updateStatusOnl

};
