import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import { Space, Table, Select, Button, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../../services/franchiseServices";
import AddFileModal from "./child/AddFileModal";
import { FilterFilled } from "@ant-design/icons";
import moment from "moment";
import { get } from "jquery";
import { AsyncPaginate } from "react-select-async-paginate";
import PermissionChecker from "../../../../components/PermissionChecker";
const { Search } = Input;

export default function FranchiseFile(id) {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [fileList, setFileList] = useState(null);
  const [loading, setLoading] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [stateFilterCate, setStateFilterCate] = useState(false);
  const [filter, setFilter] = useState({
    customer_id: null,
    category_id: null,
  });

  const firstLoad = useRef(true);

  const [cate, setCate] = useState(null);

  const getFileFranchise = async (page, search, cateId) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getFileFranchise(
        `?search=${search ?? ""}&page=${page ?? 1}&category_id=${cateId ?? ""}`
      );
      setFileList(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const getFranchiseCate = async () => {
    try {
      const response = await franchiseServices.getFranchiseCate();
      setCate(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFile = async (id) => {
    try {
      const response = await franchiseServices.deleteFileFranchise(id);
      getFileFranchise(1);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  useEffect(() => {
    if (id) {
      setFilter({
        ...filter,
        customer_id: id,
      });
    }

    getFranchiseCate();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  useEffect(() => {
    if (filter?.customer_id != null || filter?.category_id != null) {
      getFileFranchise(1, filter?.customer_id, filter?.category_id);
    } else {
      if (id == null && firstLoad.current) {
        firstLoad.current = false;
        getFileFranchise(1);
      }
    }
  }, [filter]);

  const columns = [
    {
      title: "Tên file",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Khách hàng",
      dataIndex: "customer",
      key: "customer",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <AsyncPaginate
            value={customers}
            placeholder="Tìm kiếm khách hàng"
            loadOptions={getCustomerFranchise}
            isMulti={false}
            closeMenuOnSelect={false}
            onChange={(v) => {
              setFilter({
                ...filter,
                customer_id: v?.value ?? null,
              });
              setCustomers(v);
              if ((v?.value ?? null) === null && filter?.category_id === null) {
                getFileFranchise(1);
              }
            }}
            additional={{
              page: 1,
            }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 99999,
              }),
              width: "auto",
            }}
            menuPortalTarget={document.body}
            debounceTimeout={500}
            isClearable
            isSearchable
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filter?.customer_id !== null ? "#1677ff" : undefined,
          }}
        />
      ),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Loại báo cáo",
      dataIndex: "cate",
      key: "cate",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <Select
              showSearch
              placeholder="Loại báo cáo"
              optionFilterProp="children"
              style={{
                width: 300,
              }}
              onChange={(v) => {
                setFilter({
                  ...filter,
                  category_id: v,
                });
                setStateFilterCate(false);
              }}
              filterOption={filterOption}
              options={cate?.map((e) => {
                return {
                  value: e.id,
                  label: e.name,
                };
              })}
            />
            <Button
              onClick={() => {
                setFilter({
                  ...filter,
                  category_id: null,
                });
                getFileFranchise();
                setStateFilterCate(false);
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterDropdownOpen: stateFilterCate,
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filter?.category_id !== null ? "#1677ff" : undefined,
          }}
          onClick={() => {
            setStateFilterCate(!stateFilterCate);
          }}
        />
      ),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <a>{moment(created_at ?? Date.now()).format("DD-MM-YYYY HH:mm:ss")}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={(e) => {
              console.log(record);
              downloadFile(record.file_url);
            }}
          >
            Tải file
          </a>
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_file_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá file ?"
                description="Bạn có chắc chắn muốn xoá file này chứ ?"
                onConfirm={() => {
                  deleteFile(record.key);
                }}
                onCancel={() => {}}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="d-flex justify-content-start align-items-center">
          <Search
            placeholder="Tìm kiếm file"
            onSearch={(v) => {
              getFileFranchise(1, v);
            }}
            enterButton
            loading={loading}
            style={{
              width: 300,
              marginRight: "20px",
            }}
          />
        </div>
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_file_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Thêm file
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(fileList?.data ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            name: e.name ?? "--:--",
            customer: e?.customer?.name ?? "--:--",

            file_url: e?.file_url ?? "--:--",
            created_at: e?.created_at ?? "--:--",
            cate: e.file_category?.name ?? "--:--",
            file_catiegory_id: e.file_catiegory_id ?? "--:--",
          };
        })}
        pagination={{
          total: fileList?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getFileFranchise(e.current);
        }}
      />
      <AddFileModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getFileFranchise(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
