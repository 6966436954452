import React from "react";

const ItemDevice = ({ device, handleAccept, handleCancel }) => {
  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return "Từ chối";
      case 1:
        return "Chờ duyệt";
      case 2:
        return "Chấp nhận";
      default:
        break;
    }
  };
  return (
    <tr className="hover-product even">
      <td>{device?.device_id}</td>
      <td>{device?.staff?.name}</td>
      <td>{device?.name}</td>
      <td>{renderStatus(device.status)}</td>
      <td>{device?.staff?.total_device}</td>

      <td>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-primary btn-sm"
          type="button"
          data-toggle="modal"
          data-target="#modalUID"
          onClick={() =>
            handleAccept(
              `Bạn có chắc chắn đông ý thiết bị này không?`,
              "ACCEPT",
              device.id
            )
          }
        >
          <i className="fa fa-thumbs-up" aria-hidden="true"></i>
          Đồng ý
        </button>
        <button
          style={{
            border: "1px solid",
          }}
          class="btn btn-danger btn-sm"
          type="button"
          data-toggle="modal"
          data-target="#modalUID"
          onClick={() =>
            handleCancel(
              `Bạn có chắc chắn hủy thiết bị này không?`,
              "DELETE",
              device.id
            )
          }
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
          Hủy
        </button>
      </td>
    </tr>
  );
};

export default ItemDevice;
