import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
// import Modal from "react-modal";
import { AsyncPaginate } from "react-select-async-paginate";
import { areaServices } from "../../../services/areaServices";
import { shiftServices } from "../../../services/shiftServices";
import { Button, Form, Input, Select, Space, Modal } from "antd";
import { toast } from "react-toastify";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { probationServices } from "../../../services/probationServices";

// Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const InitProcedure = ({
  visible,
  procedureInput,
  closeModal,
  procedure_config_id,
  onSave,
  procedureConfigName,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const departments = useSelector((state) => state.staff.department_list);
  const [listArea, setListArea] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [incidentAreas, setIncidentAreas] = useState([]);
  const [incidentDepartments, setIncidentDepartments] = useState([]);
  const [listStaffRelate, setListStaffRelate] = useState([]);
  const [daywork, setDayWork] = useState("");
  const [failedNumber, setFaildNumber] = useState("");
  const [title, setTitle] = useState("");
  const [data, setData] = useState({
    option_relate: 0,
  });
  const [listStaffReview, setListStaffReview] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    if (procedureInput?.title !== null) {
      setListArea(
        procedureInput.areas.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      setListDepartment(
        procedureInput.departments.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
      setTitle(procedureInput?.title);
      setDayWork(procedureInput?.daywork);
      setFaildNumber(procedureInput?.failed_number);
      setListStaffReview([
        {
          value: procedureInput?.staff_review?.id,
          label: procedureInput?.staff_review?.name,
        },
      ]);
      if (procedureInput?.evaluation_question) {
        form.setFieldsValue({
          list_data: procedureInput?.evaluation_question.map((item) => {
            return {
              question: item.question,
              category_name: item.category_name,
              failed_number: item.failed_number,
            };
          }),
        });
      }
    } else {
      setTitle("");
      setListArea([]);
      setListDepartment([]);
      console.log(procedureConfigName);
    }

    if (procedureConfigName == "Biên bản/Thông tin") {
      if (procedureInput?.incident_areas) {
        setIncidentAreas(
          procedureInput.incident_areas.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }
      if (procedureInput?.incident_departments) {
        setIncidentDepartments(
          procedureInput.incident_departments.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }
      if (procedureInput?.incident_staffs) {
        setListStaffRelate(
          procedureInput.incident_staffs.map((e) => {
            return {
              value: e.id,
              label: e.name,
            };
          })
        );
      }

      if ((procedureInput?.incident_staffs ?? []).length > 0) {
        setData({ ...data, option_relate: 0 });
      }
    }
  }, [procedureInput]);

  useEffect(() => {
    if (visible) {
      getProbationCategories();
    }
  }, [visible]);

  const fetchStaffsManager = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAreas = async (search, __loadedOptions, { page }) => {
    try {
      const res = await areaServices.fetchAreas();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const createProcedure = async () => {
    if (title === "") {
      toast.error("Chưa nhập tên gợi nhớ");
      return;
    }

    if (listStaffReview.length === 0) {
      toast.error("Chưa chọn người quản lý đánh giá");
      return;
    }

    if (form.getFieldValue("list_data") === undefined) {
      toast.error("Chưa nhập tiêu chí đánh giá");
      return;
    }

    var evaluation_question = form.getFieldValue("list_data").map((item) => {
      return {
        question: item.question,
        category_name: categories.find((e) => e.name === item.category_name)
          ?.name,
        failed_number: categories.find((e) => e.name === item.category_name)
          ?.failed_number,
      };
    });

    const data = {
      procedure_config_id: procedure_config_id,
      is_active: true,
      title: title,
      daywork: daywork,
      staff_review_id: listStaffReview.value,
      list_area_id: listArea.map((e) => e.value),
      list_department_id: listDepartment.map((e) => e.value),
      evaluation_question: evaluation_question,
    };

    try {
      const response = await probationServices.createProcedure({ data: data });
      onSave(response.data);
      closeModal("load");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const updateProcedure = async () => {
    if (title === "") {
      toast.error("Chưa nhập tên gợi nhớ");
      return;
    }

    if (listStaffReview.length === 0) {
      toast.error("Chưa chọn người quản lý đánh giá");
      return;
    }

    if (form.getFieldValue("list_data") === undefined) {
      toast.error("Chưa nhập tiêu chí đánh giá");
      return;
    }

    console.log(categories);
    console.log(form.getFieldValue("list_data"));


    var evaluation_question = form.getFieldValue("list_data").map((item) => {
      return {
        question: item.question,
        category_name: categories.find((e) => e.name === item.category_name)
          .name,
        failed_number: categories.find((e) => e.name === item.category_name)
          .failed_number,
      };
    });

    const data = {
      is_active: true,
      title: title,
      list_area_id: listArea.map((e) => e.value),
      list_department_id: listDepartment.map((e) => e.value),
      daywork: daywork,
      failed_number: failedNumber,
      staff_review_id: listStaffReview.value,
      evaluation_question: evaluation_question,
    };
    try {
      const response = await probationServices.updateProcedure({
        procedureId: procedureInput.id,
        data: data,
      });
      onSave(response.data);
      closeModal("load");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const getProbationCategories = async () => {
    try {
      const response = await probationServices.getProbationCategories();
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    console.log(procedureInput.id);
    console.log(listStaffReview);
    if (procedureInput?.id != null) {
      updateProcedure();
    } else {
      createProcedure();
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      evaluation_question: procedureInput?.evaluation_question?.map((item) => {
        return {
          question: item.question,
          category_name: item.category_name,
          failed_number: item.failed_number,
        };
      }),
    });

    console.log(form.getFieldValue("evaluation_question"));
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={"Khởi tạo"}
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20, minWidth: 500, maxWidth: 1000 }}
      onCancel={() => {
        closeModal();
      }}
      // onOk={() => {
      //   if (procedureInput?.title === null) {
      //     createProcedure();
      //   } else {
      //     updateProcedure();
      //   }
      // }}
      footer={null}
    >
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div
          className="d-flex mb-3"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Tên gợi nhớ</div>
          <div style={{ width: "80%" }}>
            <input
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
            />
          </div>
        </div>
        <div
          className="d-flex mb-3"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Số ngày thử việc</div>
          <div style={{ width: "80%" }}>
            <input
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
              onChange={(e) => {
                setDayWork(e.target.value);
              }}
              value={daywork}
            />
          </div>
        </div>
        <div
          className="d-flex mb-3"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Số tiêu chí tối đa không đạt</div>
          <div style={{ width: "80%" }}>
            <input
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
              onChange={(e) => {
                setFaildNumber(e.target.value);
              }}
              value={failedNumber}
            />
          </div>
        </div>
        <div className="d-flex mb-3" style={{ alignItems: "center" }}>
          <div style={{ flex: 1 }}>Quản lý đánh giá</div>
          <div style={{ width: "80%" }}>
            <AsyncPaginate
              value={listStaffReview}
              loadOptions={fetchStaffsManager}
              isMulti={false}
              closeMenuOnSelect={true}
              onChange={setListStaffReview}
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              menuPortalTarget={document.body}
              debounceTimeout={500}
              isClearable
              isSearchable
            />
          </div>
        </div>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div style={{ flex: 1 }}>Chi nhánh</div>
          <div style={{ width: "80%" }}>
            <AsyncPaginate
              value={listArea}
              loadOptions={fetchAreas}
              isMulti
              closeMenuOnSelect={false}
              onChange={setListArea}
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              menuPortalTarget={document.body}
              debounceTimeout={500}
              isClearable
              isSearchable
            />
          </div>
        </div>
        <div
          className="d-flex"
          style={{ alignItems: "center", marginTop: "20px" }}
        >
          <div style={{ flex: 1 }}>Phòng ban</div>
          <div style={{ width: "80%" }}>
            <AsyncPaginate
              value={listDepartment}
              loadOptions={fetchAllDepartments}
              isMulti
              closeMenuOnSelect={false}
              onChange={setListDepartment}
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 99999,
                }),
              }}
              menuPortalTarget={document.body}
              debounceTimeout={500}
              isClearable
              isSearchable
            />
          </div>
        </div>
        <div style={{ flex: 1, marginTop: 20 }}>Tiêu chí đánh giá:</div>
        <div
          className="mt-4"
          style={{
            overflowY: "auto",
            height: "auto",
            maxHeight: "80vh",
            margin: "10px",
          }}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.List name="list_data">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "question"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "600px" }}
                          placeholder="Nhập nội dung đánh giá"
                        />
                      </Form.Item>
                      <Form.Item
                        name={[name, "category_name"]}
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          placeholder={"Chọn hạng mục xem xét"}
                          allowClear
                        >
                          {(categories ?? []).map((item) => {
                            return <Option value={item.name}></Option>;
                          })}
                        </Select>
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm tiêu chí đánh giá
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    onReset();
                  }}
                >
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default InitProcedure;
