import React, { useState, useEffect } from "react";
import { shiftServices } from "../../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import { toast } from "react-toastify";

const ListDevice = ({ profileInput }) => {
  const [listDevice, setListDevice] = useState([]);
  const [profile, setProfile] = useState(null);
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(profileInput);
    setProfile(profileInput);
  }, [profileInput]);

  useEffect(() => {
    fetchListDevice();
  }, [profile]);

  const fetchListDevice = async () => {
    try {
      const response = await shiftServices.fetchListDevice(
        profile.area_id,
        profile.id
      );
      setListDevice(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDevice = async (deviceId) => {
    try {
      const response = await shiftServices.deleteDevice(areaId, deviceId);
      toast.success(response.msg);
      fetchListDevice();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Danh sách thiết bị
        </h4>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Mã thiết bị</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {listDevice?.map((device, index) => {
                    return (
                      <tr key={index} className="table-row">
                        <td>{index + 1}</td>
                        <td>{device?.name ?? ""}</td>
                        <td>{device?.device_id ?? ""}</td>
                        <td className="group-action-btn">
                          <button
                            style={{
                              border: "1px solid",
                            }}
                            class="btn btn-danger btn-sm"
                            type="button"
                            onClick={() => {
                              if (
                                (reportNumber.permissions?.device_delete ??
                                  false) == true
                              ) {
                                deleteDevice(device?.id);
                              } else {
                                toast.error("Bạn không có quyền truy cập");
                              }
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            Xóa
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalRole
      visible={modal}
      onSubmitProps={(values) => {
        handleRole(values);
      }}
      closeModal={() => {
        setModal(false);
        setItemUpdate(null);
      }}
      data={permission}
      area={area}
      item={itemUpdate}
    /> */}
    </div>
  );
};

export default ListDevice;
