import Modal from "react-modal";
import React, { useEffect } from "react";
import { useState } from "react";
import { shiftServices } from "../../../../../services/shiftServices";
import { useSelector } from "react-redux";
import { convertObjToQueryString, secondsToTime } from "../../../../../helpers";
import moment from "moment";
import { constants } from "../../../../../constants";
Modal.setAppElement("#root");

const TimeWorkingModal = ({
  visible,
  data,
  closeModal,
  fromOverTime,
  fromForm,
  fromUser,
  refreshData,
}) => {
  const areaId = useSelector((state) => state.area.areaId);

  const [timeWorking, setTimeWorking] = useState([]);

  useEffect(() => {
    loadData();
  }, [fromOverTime, fromForm, fromUser, refreshData]);

  const loadData = async () => {
    try {
      if (fromOverTime !== null) {
        data = {
          ...data,
          from_overtime: fromOverTime,
        };
      }

      if (fromForm !== null) {
        data = {
          ...data,
          from_form: fromForm,
        };
      }

      if (fromUser !== null) {
        data = {
          ...data,
          from_user: fromUser,
        };
      }
      const queryString = convertObjToQueryString(data);

      console.log(queryString);

      const response = await shiftServices.fetchTimeWorking({
        areaId,
        queryString,
      });
      setTimeWorking(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const displayTime = (seconds) => {
    const secondsNew = Math.abs(seconds);
    const timeConvert = secondsNew
      ? secondsToTime(secondsNew)
      : {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };

    return `${timeConvert.hours} giờ ${timeConvert.minutes} phút ${timeConvert.seconds} giây`;
  };

  const customStyles = {
    content: {
      width: "80%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>
          {fromUser === 0
            ? "Giờ công thực tế"
            : fromForm === 1
            ? "Giờ công được duyệt"
            : fromOverTime === 1
            ? "Giờ công được thêm (chấm hộ, thêm giờ)"
            : ""}
        </h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="modal-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr>
                    <th className="text-center">Thời gian</th>
                    <th>Ca làm việc</th>
                    <th className="text-center">Thời gian</th>
                  </tr>
                </thead>
                {timeWorking?.map((item, index) => {
                  return (
                    <>
                      <tbody key={index}>
                        <tr>
                          <td className="text-center font-weight-bold">
                            {item.date}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      {item.history.map((history) => {
                        return (
                          <tbody key={history?.id}>
                            <tr>
                              <td className="text-center">---</td>
                              <td>{history?.timekeeping?.name || ""}</td>
                              <td className="text-center">
                                {displayTime(history?.out?.time_working)}
                              </td>
                              <td>
                                {history.is_bonus === true ? (
                                  <div>
                                    {history.status == 0 ||
                                    history.out?.status == 0 ? (
                                      <i
                                        className="fas fa-times"
                                        style={{ color: "red" }}
                                      ></i>
                                    ) : (
                                      <></>
                                    )}
                                    {history.status == 2 &&
                                    history.out?.status == 2 ? (
                                      <i
                                        className="fas fa-check"
                                        style={{ color: "green" }}
                                      ></i>
                                    ) : (
                                      <></>
                                    )}
                                    {history.status == 1 &&
                                    history.out?.status == 1 ? (
                                      <i
                                        className="fas fa-times"
                                        style={{ color: "yellow" }}
                                      ></i>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <i
                                    className="fas fa-times"
                                    style={{ color: "red" }}
                                  ></i>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TimeWorkingModal;
