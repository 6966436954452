import { Modal } from "antd";
import React, { useEffect, useState } from "react";

export default function ImportExcelModel({
  open,
  closeModal,
  onOk,
  data,
  header,
}) {
  const [arrHeader, setArrHeader] = useState([]);

  useEffect(() => {
    if (open == true) {
    }
  }, [open]);

  function changeOrderHeader(value) {
    var newValues = arrHeader.filter((v) => v.index !== value.index);
    if (value.value == "")
      var newValues = newValues.filter((v) => v.index !== value.index);
    else newValues = [...newValues, value];

    setArrHeader(newValues);
  }

  function showHeaderData(fileData, header) {
    var result = [];
    if (
      typeof fileData == "undefined" ||
      fileData?.length == 0 ||
      fileData == null
    ) {
      return result;
    }
    var data = fileData[0];
    var index = 0;
    Object.entries(data).forEach(([key, value], index) => {
      result.push(
        <th>
          <select
            onChange={(e) =>
              changeOrderHeader({ index, value: e.target.value })
            }
            name=""
            id="input"
            class="form-control"
            required="required"
          >
            <option value={""}>---</option>
            {header.map((v, i) => {
              return <option value={v.value}>{v.title}</option>;
            })}
          </select>
        </th>
      );
      // }
    });

    return result;
  }

  function showValue(v) {
    var result = [];
    for (const [key, value] of Object.entries(v)) {
      result.push(<td>{value}</td>);
    }
    return result;
  }

  function showData(data) {
    var result = [];
    if (typeof data == "undefined" || data?.length == 0 || data == null) {
      return result;
    }
    return data.map((v, i) => {
      return <tr>{showValue(v, v[0])}</tr>;
    });
  }

  function handleOnSave() {
    var multiCreate = [];
    for (const [index, element] of data.entries()) {
      var item = {};
      var i = 0;

      Object.entries(element).forEach(([_key, _value], _index) => {
        var objectItem = arrHeader.filter((v, i) => _index === v.index)[0];
        console.log(_value, objectItem);
        if (objectItem && objectItem.value != "")
          item = { ...item, [objectItem.value]: _value };
      });

      multiCreate.push({ ...item });
    }
    onOk(multiCreate);
  }

  console.log("header", arrHeader);
  return (
    <Modal
      title={"Import dữ liệu câu hỏi từ file excel"}
      centered
      open={open}
      width={"auto"}
      style={{ top: 20 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        handleOnSave();
      }}
    >
      <table
        class="table table-responsive table-border"
        style={{ fontSize: "13px" }}
      >
        <thead>{showHeaderData(data, header)}</thead>
        <tbody>{showData(data)}</tbody>
      </table>
    </Modal>
  );
}
