/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Button, Popconfirm, Table, Space } from "antd";
import { evaluationServices } from "../../../services/evaluationServices";
import PermissionChecker from "../../../components/PermissionChecker";
import history from "../../../history";
import AddQcEvaluateMainModal from "./child/AddQcEvaluateMainModal";
import ExportExcelQcModal from "./child/ExportExcelModal";

const QCEvaluateMainPage = () => {
  const areaId = useSelector((state) => state.area.areaId);

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
  });

  const [qcEvaluateMains, setQCEvaluateMains] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getQCEvaluateMain();
  }, []);

  const getQCEvaluateMain = async () => {
    try {
      setLoading(true);
      const resShifts = await evaluationServices.getQCEvaluateMain();
      setQCEvaluateMains(resShifts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const deleteQCEvaluateMain = async (id) => {
    try {
      setLoading(true);
      const resShifts = await evaluationServices.deleteQCEvaluateMain(id);
      await getQCEvaluateMain(resShifts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (stt) => <p>{stt}</p>,
    },
    {
      title: "Tên bài đánh giá",
      dataIndex: "name",
      key: "name",
      render: (name) => <p>{name}</p>,
    },
    {
      title: "Phân loại",
      dataIndex: "is_report",
      key: "is_report",
      render: (_, record) => (
        <p style={{ color: record.is_report ? record.departments != "" ? "purple" :  "green" : "red" }}>
          {record.is_report == true ? record.departments != "" ? "Báo cáo Checkout" :  "Báo cáo" : "Đánh giá"}
        </p>
      ),
    },
    {
      title: "Phòng ban",
      dataIndex: "departments",
      key: "departments",
      render: (departments) => <p>{departments}</p>,
    },
    {
      title: "Cập nhật lần cuối",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Col>
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_group_add ?? false,
            child: (
              <Button
                onClick={(e) => {
                  history.push(
                    `/qc-evaluate-group/${record?.key}?is_report=${
                      record?.is_report ?? ""
                    }`
                  );
                }}
              >
                {record?.is_report ? "Tạo nhóm báo cáo" : "Tạo nhóm đánh giá"}
              </Button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_main_update ?? false,
            child: (
              <Button
                className="mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    title: `Chỉnh sửa bài đánh giá`,
                    method: "UPDATE",
                    visible: true,
                    data: record?.data,
                    isReport: record?.is_report,
                  });
                }}
              >
                Sửa
              </Button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_main_delete ?? false,
            child: (
              <Popconfirm
                className="mt-2"
                title="Xoá bài đánh giá ?"
                description="Bạn có chắc chắn muốn xoá bài đánh giá này chứ ?"
                onConfirm={() => {
                  deleteQCEvaluateMain(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <Button danger={true}>Xoá</Button>
              </Popconfirm>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_main_update ?? false,
            child: (
              <Button
                className="mt-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    id: record?.key,
                    method: "EXPORT",
                    visible: true,
                    data: record?.data,
                  });
                }}
              >
                Export báo cáo
              </Button>
            ),
          })}
        </Col>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <h4 className="h4 title_content mb-0 text-gray-800">Bài đánh giá</h4>
        <Space>
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_main_add ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                data-toggle="modal"
                data-target="#modalShift"
                onClick={() => {
                  setModal({
                    title: "",
                    method: "ADD",
                    visible: true,
                    isReport: true,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">Thêm bài báo cáo</span>
              </button>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.qc_evaluate_main_add ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                data-toggle="modal"
                data-target="#modalShift"
                onClick={() => {
                  setModal({
                    title: "",
                    method: "ADD",
                    visible: true,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">Thêm bài đánh giá</span>
              </button>
            ),
          })}
        </Space>
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={(qcEvaluateMains ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            data: e,
            stt: index + 1,
            is_report: e.is_report,
            departments: e.departments.map((e) => e.name).join(", "),
            name: e.name ?? "--:--",
            updated_at: moment.utc(e.updated_at).format("DD-MM-YYYY HH:mm"),
          };
        })}
        pagination={false}
      />
      <AddQcEvaluateMainModal
        open={
          modal.visible && (modal.method === "ADD" || modal.method === "UPDATE")
        }
        dataInput={modal.data}
        isReport={modal.isReport}
        onOk={() => {
          getQCEvaluateMain();
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <ExportExcelQcModal
        open={modal.visible && modal.method === "EXPORT"}
        onSuccess={(data) => {}}
        id={modal.id}
        closeModal={() => {
          setModal({
            visible: false,
            method: "",
          });
        }}
      ></ExportExcelQcModal>
    </div>
  );
};

export default QCEvaluateMainPage;
