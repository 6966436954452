import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ListPinIcon } from "../../icons";
import { handleShowTypeMessage } from "../../utils";

export default function RoomChatPin() {
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const { room_chat_pin } = roomSelected;
  const [isShowAll, setShowAll] = useState(false);

  return (
    <>
      <div className="message-list">
        {room_chat_pin.map((item, index) => {
          if (!isShowAll && index > 0) return null;
          const { message, user } = item;
          return (
            <div key={index} className="message">
              <ListPinIcon style={{ width: "20px", height: "20px" }} />
              <div className="message__content">
                <p style={{ fontWeight: "600", color: "#0068ff" }}>
                  Danh sách ghim({room_chat_pin.length})
                </p>
                <p style={{ marginTop: "2px" }} className="text-truncate-1">{handleShowTypeMessage(message?.content, message?.type)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
