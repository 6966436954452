/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "../../constants";
import { shiftServices } from "../../services/shiftServices";
import ModalRole from "./child/ModalRole";
import "./styles.css";
import DeleteModal from "./child/DeleteModal";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";

const Permission = () => {
  const [role, setRole] = useState([]);
  const [permission, setPermission] = useState([]);
  const [modal, setModal] = useState({
    visible: false,
    title: "",
    method: "",
    role_id: null,
  });
  const [area, setArea] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const dispatch = useDispatch();
  const [itemUpdate, setItemUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchRole = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.fetchRole({ areaId });
      setRole(response.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };
  const fetchPermission = async () => {
    try {
      const response = await shiftServices.fetchPermission({ areaId });
      setPermission(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchArea = async () => {
    try {
      const response = await shiftServices.fetchArea();
      setArea(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRole = async (data) => {
    setLoading(true);
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const response = itemUpdate
        ? await shiftServices.updateRole({ areaId, data, id: itemUpdate?.id })
        : await shiftServices.createRole({ areaId, data });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      if (response.success) {
        setModal({ ...modal, visible: false, method: "" });
        fetchRole();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setItemUpdate(null);
    setLoading(false);
  };

  const deleteRole = async (id) => {
    setLoading(true);
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const response = await shiftServices.deleteRole({
        areaId,
        id: id,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      if (response.success) {
        setModal({ ...modal, visible: false, method: "" });
        fetchRole();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setItemUpdate(null);
    setLoading(false);
  };

  useEffect(() => {
    fetchRole();
    fetchPermission();
    fetchArea();
  }, [areaId]);

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Cài đặt phân quyền
        </h4>
        <button
          class={`btn btn-info btn-icon-split btn-sm`}
          onClick={() => {
            setItemUpdate(null);
            setModal({
              ...modal,
              visible: true,
              title: "Thêm phân quyền",
              method: "EDIT",
            });
          }}
        >
          <span class="icon text-white-50">
            <i class="fas fa-plus"></i>
          </span>
          <span class="text">Thêm cài đặt phân quyền</span>
        </button>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>Mã phân quyền</th>
                    <th>Tên</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {role?.map((role, index) => {
                    return (
                      <tr key={index} className="table-row">
                        <td>{role?.id}</td>
                        <td>{role?.title}</td>
                        <td className="group-action-btn">
                          <button
                            class={`btn-info btn-sm btn-edit`}
                            onClick={() => {
                              setItemUpdate(role);
                              setModal({
                                visible: true,
                                method: "EDIT",
                                title: "Chỉnh sửa phân quyền",
                              });
                            }}
                          >
                            <span className="icon text-white mr-1">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span className="">Sửa</span>
                          </button>
                          <button
                            class={`btn-sm btn-trash`}
                            onClick={() => {
                              setModal({
                                title: `Thông báo`,
                                method: "DELETE",
                                visible: true,
                                role_id: role.id,
                              });
                              // setShiftDetail(item);
                            }}
                            data-toggle="modal"
                            data-target="#modalUID"
                          >
                            <span className="icon text-white mr-1">
                              <i className="fas fa-trash"></i>
                            </span>
                            <span className="btn-trash-text">Xoá</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalRole
        visible={modal.visible && modal.method === "EDIT"}
        title={modal.title}
        onSubmitProps={(data) => {
          if (loading == false) {
            console.log(data);
            handleRole(data);
          }
        }}
        closeModal={() => {
          setModal({ ...modal, visible: false });
          setItemUpdate(null);
        }}
        area={area}
        item={itemUpdate}
      />
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={"Thông báo"}
        onSubmit={() => {
          deleteRole(modal.role_id);
        }}
        closeModal={() => {
          setModal({ ...modal, visible: false });
        }}
      />
    </div>
  );
};

export default Permission;
