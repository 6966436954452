import React, { useState } from "react";
import moment from "moment";
import { constants } from "../../../../constants";
import { formatMoneyVND } from "../../../../helpers";
import { useSelector } from "react-redux";

export default function FormItem({
  data,
  index,
  isSelected,
  onChoose,
  onClick,
  onClickStaffApprove,
  onClickStaffViewer,
  accept,
  cancel,
}) {
  const [imageError, setImageError] = useState(false);

  const onError = () => {
    setImageError(true);
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const checkPermission = (process) => {
    const index = (process?.approvers ?? []).findIndex(
      (e) => e.id === reportNumber.current_user?.id
    );
    if (index != -1) {
      return true;
    } else {
      return false;
    }
  };

  const infoForm = (type) => {
    var formData;
    if (data?.content != null && data?.content != "") {
      formData = JSON.parse(data?.content);
    }

    switch (type) {
      case constants.TYPE_PROPOSE_HR_SUPPORT:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Vị trí: </span>
                <span>{formData?.department ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Ngày cần hỗ trợ: </span>
                <span>
                  {moment(data?.date, "YYYY-MM-DD").format(
                    constants.FORMAT_DD_MM_YYYY
                  )}
                </span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Ca cần hỗ trợ: </span>
                <span>{formData?.shifts ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Phụ cấp: </span>
                <span>{formatMoneyVND(formData?.price ?? 0)}</span>
              </p>
            </span>
          </div>
        );

      case constants.TYPE_PROPOSE_REPAIR:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>{`${
                  formData?.is_hot == true ? "Gấp" : "Bình thường"
                }`}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin thiết bị: </span>
              </p>
              {(data?.products ?? []).slice(0, 3).map((e) => {
                return (
                  <div className="row">
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.name ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span
                      className="col"
                      style={{
                        fontSize: "12px",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {e?.note ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}></span>
                  </div>
                );
              })}
            </span>
          </div>
        );

      case constants.TYPE_PROPOSE_CANCEL_ORDER:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin sản phẩm: </span>
              </p>
              <p>
                <span style={{ fontWeight: "600", color: "blue" }}>
                  Xem chi tiết
                </span>
              </p>
            </span>
          </div>
        );

      case constants.TYPE_PROPOSE_BUY_EQUIPMENT:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin thiết bị: </span>
              </p>
              {(data?.products ?? []).slice(0, 3).map((e) => {
                return (
                  <div className="row">
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.name ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span
                      className="col"
                      style={{
                        fontSize: "12px",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {e?.note ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}></span>
                  </div>
                );
              })}
            </span>
          </div>
        );

      case constants.TYPE_REQUEST_ADVANCE:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin thiết bị: </span>
              </p>
              {(data?.products ?? []).slice(0, 3).map((e) => {
                return (
                  <div className="row">
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.name ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span
                      className="col"
                      style={{
                        fontSize: "12px",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {e?.note ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}></span>
                  </div>
                );
              })}
            </span>
          </div>
        );

      case constants.TYPE_PROPOSE_TRANSPORTATION_STUFF:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh gửi: </span>
                <span>{data?.area?.name ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Chi nhánh nhận: </span>
                <span>{data?.area_to?.name ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Lý do: </span>
                <span>{formData?.reason ?? "---:---"}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin thiết bị: </span>
              </p>
              {(data?.products ?? []).slice(0, 3).map((e) => {
                return (
                  <div className="row">
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.type ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.name ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span
                      className="col"
                      style={{
                        fontSize: "12px",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {e?.note ?? "---:---"}
                    </span>
                  </div>
                );
              })}
            </span>
          </div>
        );

      case constants.TYPE_PROPOSE_PAYMENT:
        return (
          <div className="row">
            <span className="col-5">
              <p>
                <span style={{ fontWeight: "600" }}>Tiêu đề: </span>
                <span>{formData?.title ?? "---:---"}</span>
              </p>

              <p>
                <span style={{ fontWeight: "600" }}>Lý do: </span>
                <span>{formData?.reason ?? "---:---"}</span>
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>Tổng tiền: </span>
                <span>{formatMoneyVND(formData?.total ?? 0)}</span>
              </p>
            </span>

            <span className="col-7">
              <p>
                <span style={{ fontWeight: "600" }}>Thông tin: </span>
              </p>
              {(data?.products ?? []).slice(0, 3).map((e) => {
                return (
                  <div className="row">
                    <span className="col" style={{ fontSize: "12px" }}>
                      {e?.name ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      SL: {e?.quantity ?? "---:---"}
                    </span>
                    <span className="col" style={{ fontSize: "12px" }}>
                      {formatMoneyVND(e?.price ?? 0)}
                    </span>
                  </div>
                );
              })}
            </span>
          </div>
        );

      default:
        return (
          <span>
            <p>
              <span style={{ fontWeight: "600" }}>Ca làm: </span>
              <span>{data?.timekeeping?.name ?? "---:---"}</span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Vào ca: </span>
              <span>
                {moment(new Date(data.from)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Ra ca: </span>
              <span>
                {moment(new Date(data.to)).format(
                  constants.FORMAT_DD_MM_YYYY_HH_MM
                )}
              </span>
            </p>
          </span>
        );
        break;
    }
  };

  return (
    <tr
      style={{ height: "120px", borderBottom: "1px solid lightgrey" }}
      onClick={(e) => {
        onClick(data.id);
      }}
    >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onClick={(e) => {
            e.stopPropagation();
            onChoose();
          }}
        />
      </td>
      <td className="text-center">{index + 1}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            {imageError || data.staff?.avatar === null ? (
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f6c23e",
                }}
              >
                <div style={{ fontWeight: "bold", color: "white" }}>
                  {`${data?.staff?.name ?? ""}`.charAt(0).toUpperCase()}
                </div>
              </div>
            ) : (
              <img
                src={data.staff?.avatar}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                }}
                onError={onError}
              />
            )}
          </div>

          <div>
            <div style={{ fontWeight: "bold", color: "black" }}>
              {data?.staff?.name ?? ""}
            </div>
            <div style={{ fontSize: "13px" }}>{data.title ?? ""}</div>
          </div>
        </div>
      </td>
      <td>{infoForm(data.type)}</td>
      <td>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.approvers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffApprove(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
        <ol style={{ paddingLeft: "10px" }}>
          {(data?.process?.viewers ?? []).map((xe) => {
            return (
              <li>
                <div
                  style={{
                    color: "blue",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStaffViewer(xe);
                  }}
                >
                  {xe?.name}
                </div>
              </li>
            );
          })}
        </ol>
      </td>
      <td style={{ textAlign: "right" }}>
        {moment.utc(data?.created_at).format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
      </td>
      {data?.status === 1 && checkPermission(data?.process) ? (
        <td style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                cancel();
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                accept();
              }}
            >
              Chấp nhận
            </button>
          </div>
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
}
