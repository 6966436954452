export default function EmojiIcon({ className, ...rest }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest}
    >
      <g clip-path="url(#clip0_524_39)">
        <path
          d="M9 0C4.03741 0 0 4.03741 0 9C0 13.9626 4.03741 18 9 18C13.9626 18 18 13.9626 18 9C18 4.03741 13.9627 0 9 0ZM9 17.027C6.79521 17.027 4.7953 16.1333 3.34318 14.6894C2.76328 14.1128 2.27101 13.4482 1.88771 12.7179C1.30392 11.6057 0.972986 10.3408 0.972986 9C0.972986 4.57389 4.57389 0.972986 9 0.972986C11.0994 0.972986 13.0128 1.78348 14.4446 3.1076C15.187 3.79404 15.8001 4.6184 16.2421 5.53982C16.745 6.58815 17.027 7.76175 17.027 9C17.027 13.4261 13.4261 17.027 9 17.027Z"
          fill="black"
        />
        <path
          d="M6.06088 7.96624C6.59824 7.96624 7.03386 7.53062 7.03386 6.99325C7.03386 6.45588 6.59824 6.02026 6.06088 6.02026C5.52351 6.02026 5.08789 6.45588 5.08789 6.99325C5.08789 7.53062 5.52351 7.96624 6.06088 7.96624Z"
          fill="black"
        />
        <path
          d="M12.0814 7.96624C12.6187 7.96624 13.0544 7.53062 13.0544 6.99325C13.0544 6.45588 12.6187 6.02026 12.0814 6.02026C11.544 6.02026 11.1084 6.45588 11.1084 6.99325C11.1084 7.53062 11.544 7.96624 12.0814 7.96624Z"
          fill="black"
        />
        <path
          d="M8.98266 13.9863C10.865 13.9863 12.669 13.0236 13.7019 11.4253L12.8846 10.8972C11.9362 12.3649 10.2013 13.184 8.46406 12.9835C7.11027 12.8274 5.85834 12.0475 5.11504 10.8972L4.29785 11.4253C5.19726 12.8172 6.71305 13.761 8.35253 13.9501C8.563 13.9744 8.77316 13.9863 8.98266 13.9863Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_524_39">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
