import { useEffect, useState } from "react";
import TimekeepingModal from "../TimekeepingModal";
import moment from "moment";
import { constants } from "../../../../../constants";

const TableInOut = ({ historyTimeKeeping = [] }) => {
  const [modal, setModal] = useState({
    id: null,
    visible: false,
  });

  const checkTime = (historyTimeKeeping) => {
    if ((historyTimeKeeping.history ?? []).length == 0) {
      return "NOT_CHECK_IN_OUT";
    } else {
      var firstHis = historyTimeKeeping.history[0];
      var lastHis =
        historyTimeKeeping.history[historyTimeKeeping.history.length - 1];
      if (
        historyTimeKeeping.history.length > 1 &&
        firstHis.is_checkin == false
      ) {
        var timeTracking = firstHis.time_tracking ?? moment.now();

        const endWorkHour = lastHis.timekeeping?.end_work_hour ?? 0;
        const endWorkMinute = lastHis.timekeeping?.end_work_minute ?? 0;

        const endOfWorkShift = `${moment(timeTracking).year()}-${
          moment(timeTracking).month() + 1 < 10 ? "0" : ""
        }${moment(timeTracking).month() + 1}-${
          moment(timeTracking).date() < 10 ? "0" : ""
        }${moment(timeTracking).date()} ${
          endWorkHour < 10 ? "0" : ""
        }${endWorkHour}:${endWorkMinute < 10 ? "0" : ""}${endWorkMinute}:00`;

        if (moment(timeTracking).isBefore(moment(endOfWorkShift))) {
          return "COME_BACK_SOON";
        }
      }

      if (
        moment(historyTimeKeeping.date ?? moment.now()).isAfter(moment.now())
      ) {
        return "NOT_TIME_YET";
      }

      if (lastHis.timekeeping == null) {
        return "NOT_WORK";
      }

      var timeTracking = lastHis.time_tracking ?? moment.now();

      const startWorkHour = lastHis.timekeeping?.start_work_hour ?? 0;
      const startWorkMinute = lastHis.timekeeping?.start_work_minute ?? 0;

      const startWorkShift = `${moment(timeTracking).year()}-${
        moment(timeTracking).month() + 1 < 10 ? "0" : ""
      }${moment(timeTracking).month() + 1}-${
        moment(timeTracking).date() < 10 ? "0" : ""
      }${moment(timeTracking).date()} ${
        startWorkHour < 10 ? "0" : ""
      }${startWorkHour}:${
        startWorkMinute < 10 ? "0" : ""
      }${startWorkMinute}:00`;

      if (moment(timeTracking).isAfter(moment(startWorkShift))) {
        return "LATE";
      }
    }

    return "ON_TIME";
  };

  const getText = (type) => {
    return type == "COME_BACK_SOON"
      ? "Về sớm"
      : type == "NOT_WORK"
      ? "Nghỉ"
      : type == "NOT_TIME_YET"
      ? "Chưa đến ca"
      : type == "NOT_CHECK_IN_OUT"
      ? "Chưa chấm công"
      : type == "LATE"
      ? "Đến muộn"
      : type == "ON_TIME"
      ? "Đúng giờ"
      : "Chưa đến ca";
  };

  const getColor = (type) => {
    return type == "COME_BACK_SOON"
      ? "red"
      : type == "NOT_WORK"
      ? "grey"
      : type == "NOT_TIME_YET"
      ? "#D9D9D9"
      : type == "NOT_CHECK_IN_OUT"
      ? "red"
      : type == "LATE"
      ? "#e14848"
      : type == "ON_TIME"
      ? "#82D475"
      : "red";
  };

  return (
    <div className="table-responsive">
      <div id="dataTable_wrapper">
        <table
          className="table no-footer dataTable"
          id="dataTable"
          width="100%"
          cellSpacing={0}
          aria-describedby="dataTable_info"
        >
          <thead>
            <tr>
              <th className="text-center">Thời gian</th>
              <th>Nhân viên</th>
              <th>Ca làm</th>
              <th>Trạng thái</th>
              <th className="text-center">Thời gian chấm công</th>
              <th>Chi nhánh</th>
            </tr>
          </thead>
          {historyTimeKeeping.map((item, index) => {
            return (
              <>
                <tbody key={index}>
                  <tr>
                    <td className="text-center font-weight-bold">
                      {moment(new Date(item.date)).format(
                        constants.FORMAT_DD_MM_YYYY
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          width: "100px",
                          textAlign: "center",
                          backgroundColor: getColor(checkTime(item)),
                          borderRadius: "6px",
                          cursor: "pointer",
                          padding: "6px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        {getText(checkTime(item))}
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                {item.history.map((history) => {
                  return (
                    <tbody
                      key={history?.id}
                      onClick={() => {
                        setModal({
                          id: history.id,
                          visible: true,
                        });
                      }}
                    >
                      <tr>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          ---
                        </td>
                        <td
                          style={{
                            verticalAlign: "middle",
                            color: history?.from_user !== null ? "red" : "",
                          }}
                        >
                          <span className="d-flex">
                            {history?.from_user !== null
                              ? history?.from_user?.name
                              : history?.staff?.name || ""}
                            <div style={{ width: "20px" }}></div>
                            {history.ot_id == null &&
                            history.ot_title != null ? (
                              <div
                                style={{
                                  width: "120px",
                                  transform: "rotate(5deg)",
                                  height: "25px",
                                  textAlign: "center",
                                  backgroundColor: "teal",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Lễ tết
                              </div>
                            ) : (
                              <></>
                            )}
                            {history.image != null ? (
                              <div
                                style={{
                                 
                                  transform: "rotate(5deg)",
                                  height: "25px",
                                  padding: "0px 10px",
                                  textAlign: "center",
                                  backgroundColor: "#ffc107",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Chấm tại chi nhánh
                              </div>
                            ) : (
                              <></>
                            )}
                            {history?.is_bonus == false ? (
                              <div
                                style={{
                                  width: "80px",
                                  transform: "rotate(10deg)",
                                  height: "25px",
                                  textAlign: "center",
                                  backgroundColor: "#ef907a",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Bớt công
                              </div>
                            ) : (
                              <></>
                            )}
                            {history?.from_user !== null &&
                            history?.is_bonus == true &&
                            history?.form == null ? (
                              <div
                                style={{
                                  width: "80px",
                                  transform: "rotate(5deg)",
                                  height: "25px",
                                  textAlign: "center",
                                  backgroundColor: "#9ced84",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Chấm hộ
                              </div>
                            ) : (
                              <></>
                            )}
                            {history?.form != null ? (
                              <div
                                style={{
                                  width: "auto",
                                  paddingRight: "10px",
                                  paddingLeft: "10px",
                                  transform: "rotate(5deg)",
                                  height: "25px",
                                  textAlign: "center",
                                  backgroundColor: "#9ced84",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {history?.form?.title ?? ""}
                              </div>
                            ) : (
                              <></>
                            )}
                          </span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {history?.timekeeping?.name || ""}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div
                            style={{
                              width: "60px",
                              height: "25px",
                              textAlign: "center",
                              backgroundColor: history?.is_checkin
                                ? "#56DA5B"
                                : "#E57262",
                              borderRadius: "6px",
                              cursor: "pointer",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "white",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {history?.is_checkin ? "Vào ca" : "Tan ca"}
                          </div>
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            verticalAlign: "middle",
                          }}
                        >
                          {history?.time_tracking
                            ? moment(new Date(history.time_tracking)).format(
                                "HH:mm:ss"
                              )
                            : ""}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {history?.area_tracking || ""}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            );
          })}
        </table>
      </div>
      <TimekeepingModal
        visible={modal.visible}
        historyId={modal.id}
        closeModal={() => setModal({ visible: false, id: null })}
      />
    </div>
  );
};

export default TableInOut;
