import React from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");

const TimekeepingAndRecognitionModal = ({ visible, title, closeModal }) => {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p className="text-center">
          <span className="text-success">{`Vào: 2022-10-10 21:49:08 `}</span>
          <span className="text-warning ml-2">{`Ra: 2022-10-10 21:49:08 `}</span>
          <span className="text-secondary ml-2">
            {`Ghi nhận: 2 giờ 2 phút 52 giây `}{" "}
          </span>
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={closeModal}>
          Đóng
        </button>
      </div>
    </Modal>
  );
};

export default TimekeepingAndRecognitionModal;
