import { constants as c } from "../constants";
import { chatServices as s } from "../services/chatADServices";
import { appServices as a } from "../services/appServices";

// FUNCTION PHÒNG CHAT
function getRoomChatList(queryString) {
  return (dispatch) => {
    s.getRoomChatList(queryString).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_ROOM_CHAT_SUCCESS_AD, data, search: queryString ? true : false };
  }
  function failure(code, message) {
    return { type: c.GET_ROOM_CHAT_FAILURE_AD, code, message };
  }
}

function getRoomChatListByUserId(query, isSearch = false) {
  return (dispatch) => {
    s.getRoomChatListByUserId(query).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_ROOM_CHAT_BY_USER_ID_AD, data, search: isSearch };
  }
  function failure(code, message) {
    return { type: c.GET_ROOM_CHAT_FAILURE_AD, code, message };
  }
}

function saveUserId(userId) {
  return (dispatch) => {
    dispatch(success(userId));
  }
  function success(data) {
    return { type: c.SAVE_USER_ID_AD, data };
  }
}

// tạo phòng chat
function createRoomChat(params) {
  return (dispatch) => {
    dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: true });
    s.createRoomChat(params).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
      }
      else dispatch(failure(res.code, res.msg));
      dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: false });
    });
  };
  function success(data) {
    return { type: c.CREATE_ROOM_CHAT_AD, data };
  }
  function failure(code, message) {
    return { type: c.CREATE_ROOM_CHAT_AD, code, message };
  }
}

function updateRoomChat(roomId, params) {
  return (dispatch) => {
    s.updateRoomChat(roomId, params).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.UPDATE_ROOM_CHAT_AD, data };
  }
  function failure(code, message) {
    return { type: c.UPDATE_ROOM_CHAT_AD, code, message };
  }
}

function getOneRoomChat(roomId, queryString) {
  return (dispatch) => {
    s.getOneRoomChat(roomId, queryString).then((res) => {
      if (res?.code === 200 || res?.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_ONE_ROOM_CHAT_AD, data };
  }
  function failure(code, message) {
    return { type: c.GET_ONE_ROOM_CHAT_AD, code, message };
  }
}

function deleteRoomChat(roomId) {
  return (dispatch) => {
    s.deleteRoomChat(roomId).then((res) => {
      if (res?.code === 200 || res?.code === 201) dispatch(success());
      else dispatch(failure(res?.code, res?.msg));
    });
  };
  function success() {
    return { type: c.DELETE_ROOM_CHAT_AD, data: roomId };
  }
  function failure(code, message) {
    return { type: c.DELETE_ROOM_CHAT_AD, code, message };
  }
}

const updateMemberRoomChat = (roomId, params) => {
  return (dispatch) => {
    dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: true });
    s.updateMemberRoomChat(roomId, params).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
      dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: false });
    });
  };
  function success(data) {
    return { type: c.UPDATE_MEMBER_ROOM_CHAT_AD, data };
  }
  function failure(code, message) {
    return { type: c.UPDATE_MEMBER_ROOM_CHAT_AD, code, message };
  }
}

function selectedRoomChat(room) {
  return (dispatch) => {
    dispatch(success(room));
  };
  function success(data) {
    return { type: c.SELECTED_ROOM_CHAT_AD, data };
  }
}

function handleChangeRoomList(roomChats) {
  return (dispatch) => {
    dispatch(success(roomChats));
  };
  function success(data) {
    return { type: c.CHANGE_ROOM_CHAT_AD, data };
  }
}

function searchMembers(queryString) {
  return (dispatch) => {
    dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: true });
    s.searchMembers(queryString).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
      dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: false });
    });
  };
  function success(data) {
    return { type: c.SEARCH_MEMBERS_AD, data };
  }
  function failure(code, message) {
    return { type: c.SEARCH_MEMBERS_AD, code, message };
  }
}

// FUNCTION TIN NHẮN
function getMessageList(roomId, queryString, way = "first", messageID = "", onSuccess = () => {}) {
  return (dispatch) => {
    s.getMessageList(roomId, queryString).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data, way))
          onSuccess(messageID)
      }
      else dispatch(failure(res.code, res.msg));
    });
  };
  function success(data, way) {
    return { type: c.GET_MESSAGE_LIST_AD, data, way };
  }
  function failure(code, message) {
    return { type: c.GET_MESSAGE_LIST_AD, code, message };
  }
}

function handleReceiveMessage(message) {
  return (dispatch) => {
    dispatch(success(message));
  };
  function success(data) {
    return { type: c.RECEIVE_MESSAGE_AD, data };
  }
}

function createMessage(params) {
   return (dispatch) => {
      s.createMessage(params)
      dispatch(success(params));
    };
    function success(data) {
      return { type: c.CREATE_MESSAGE_AD, data };
    }
}

function updateMessage(params) {
  return (dispatch) => {
     s.updateMessage(params)
     dispatch(success(params));
   };
   function success(data) {
     return { type: c.UPDATE_MESSAGE_AD, data };
   }
}

function handleSelectReplyMessage(message) {
  return (dispatch) => {
    dispatch(success(message));
  };
  function success(data) {
    return { type: c.SELECTED_REPLY_MESSAGE_AD, data };
  }
}

function handleAddPinMessage(params) {
  return (dispatch) => {
    s.addPinMessage(params);
    dispatch(success(params));
  };
  function success(data) {
    return { type: c.ADD_PIN_MESSAGE_AD, data };
  }
}

function callPageMessage(messageId, onSuccess = () => {}) {
  return () => {
    s.callPageMessage(messageId).then((res) => {
      if (res.code === 200 || res.code === 201) onSuccess(res.data)

    });
  };
}

function redirectMessage(params) {
  return (dispatch) => {
    dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: true });
    s.redirectMessage(params).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));
      dispatch({ type: c.SHOW_LOADING_MODAL_AD, data: false });
    });
  };
  function success(data) {
    return { type: c.REDIRECT_MESSAGE_AD, data };
  }
}

function getFileList(params) {
  return (dispatch) => {
    s.getFileList(params).then((res) => {
      if (res.code === 200 || res.code === 201) dispatch(success(res.data));

    });
  };
  function success(data) {
    return { type: c.GET_FILE_LIST_AD, data };
  }
}

// FUNCTION MODAL
function toggleModalSelectsUser(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return { type: c.TOGGLE_MODAL_SELECTS_USER_AD, data };
  }
}

function toggleModalUserInfo(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return { type: c.TOGGLE_MODAL_USER_INFO_AD, data };
  }
}

function toggleFullscreen(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return { type: c.TOGGLE_FULLSCREEN_AD, data };
  }
}

function toggleMainContent(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return { type: c.TOGGLE_HIDE_MAIN_CONTENT_AD, data };
  }
}

export const chatActions = {
  getRoomChatList,
  selectedRoomChat,
  handleReceiveMessage,
  createMessage,
  handleChangeRoomList,
  handleSelectReplyMessage,
  updateMessage,
  handleAddPinMessage,
  getMessageList,
  toggleModalSelectsUser,
  searchMembers,
  redirectMessage,
  getFileList,
  updateRoomChat,
  callPageMessage,
  updateMemberRoomChat,
  createRoomChat,
  deleteRoomChat,
  getOneRoomChat,
  toggleFullscreen,
  getRoomChatListByUserId,
  saveUserId,
  toggleMainContent,
  toggleModalUserInfo
};
