import moment from "moment";

function convertMsToDMY(diff) {
  var seconds = Math.floor(diff / 1000),
    minutes = Math.floor(seconds / 60),
    hours = Math.floor(minutes / 60),
    days = Math.floor(hours / 24),
    months = Math.floor(days / 30),
    years = Math.floor(days / 365);

  seconds %= 60;
  minutes %= 60;
  hours %= 24;
  days %= 30;
  months %= 12;

  return {
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  };
}

function getUrlParams(key) {
  const _queryString = window.location.search;
  const urlParams = new URLSearchParams(_queryString);
  var value = urlParams.get(key);
  return value || null;
}
function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "gif":
    case "bmp":
    case "png":
      //etc
      return true;
  }
  return false;
}

function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      // etc
      return true;
  }
  return false;
}

function isPdf(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "pdf":
      return true;
  }
  return false;
}
function getUrlExtensions(url) {
  if (url) return url.split(/[#?]/)[0].split(".").pop().trim();
  return null;
}

function isPhone(phone) {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  return vnf_regex.test(phone);
}

function isEmail(email) {
  if (email) var email = email.toString().replace(/ /g, "");
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function isEmpty(data) {
  if (data == null || typeof data == "undefined") return false;
  return data.toString().replace(/ /g, "").length > 0;
}

function convertObjToQueryString(obj) {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => {
        return `${key}=${encodeURIComponent(obj[key])}`;
      })
      .join("&")
  );
}

function convertNumberToDateStr(start, end) {
  if (
    start !== null &&
    start !== undefined &&
    end !== null &&
    end !== undefined
  ) {
    return `${start.toString().padStart(2, "0")}:${end
      .toString()
      .padStart(2, "0")}`;
  }

  return "";
}

function convertQueryToJson() {
  const search = window.location.search.substring(1);

  let jsonConvert = {};
  try {
    jsonConvert = !search
      ? {}
      : JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
  } catch (error) {
    jsonConvert = {};
  }

  return jsonConvert;
}
const formatMoney = (num) => {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
// regex
const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const phoneRegex = /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;

function formatDate(date, format) {
  return date && format ? moment(date).format(format) : "";
}

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " giờ " : " giờ ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " phút " : " phút ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " giây" : " giây") : "0";
  return hDisplay + mDisplay + sDisplay;
}

function getTypeOrder(number) {
  switch (number) {
    case 0:
      return "Nhóm món cơm";
    case 1:
      return "Nhóm món cá";
    case 2:
      return "Nhóm món rau";
    case 3:
      return "Nhóm món thịt";
    case 4:
      return "Nhóm món bò";
    case 5:
      return "Nhóm món khác";
    case 6:
      return "CCDC";
    case 7:
      return "Đồ dùng";
    default:
      return "Nhóm món cơm";
  }
}

function formatMoneyVND(number, roundUp = false) {
  const config = {
    style: "currency",
    currency: "VND",
    maximumFractionDigits: 9,
  };
  const numberFormat = roundUp ? (number ?? 0).toFixed(2) : number;
  return `${new Intl.NumberFormat("vi-VN", config)
    .format(numberFormat)
    .replace(/₫/g, "")}`;
}

function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisor_for_seconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    hours,
    minutes,
    seconds,
  };
  return obj;
}

const formatNumberV2 = (str) => {
  if (str === undefined || str === null) return "";
  const strFormat = str
    .toString()
    .replace(/[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, "");
  if (Number(strFormat) >= 1000) {
    return strFormat
      .split("")
      .reverse()
      .reduce((prev, next, index) => {
        return (index % 3 ? next : next + ".") + prev;
      });
  } else if (Number(strFormat) > 0 && Number(strFormat) < 1000) {
    return Number(strFormat);
  } else {
    return "";
  }
};

export {
  convertMsToDMY,
  getUrlParams,
  isImage,
  isVideo,
  isPdf,
  getUrlExtensions,
  isPhone,
  isEmail,
  isEmpty,
  convertObjToQueryString,
  convertNumberToDateStr,
  convertQueryToJson,
  formatDate,
  emailRegex,
  phoneRegex,
  formatMoneyVND,
  secondsToTime,
  getTypeOrder,
  formatMoney,
  formatNumberV2,
  secondsToHms,
};
