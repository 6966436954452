/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker, SelectPicker } from "rsuite";
import { constants } from "../constants";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const gregorian_en_lowercase = {
  name: "gregorian_en_lowercase",
  months: [
    ["Tháng 1", "jan"],
    ["Tháng 2", "feb"],
    ["Tháng 3", "mar"],
    ["Tháng 4", "apr"],
    ["Tháng 5", "may"],
    ["Tháng 6", "jun"],
    ["Tháng 7", "jul"],
    ["Tháng 8", "aug"],
    ["Tháng 9", "sep"],
    ["Tháng 10", "oct"],
    ["Tháng 11", "nov"],
    ["Tháng 12", "dec"],
  ],
  weekDays: [
    ["saturday", "T7"],
    ["sunday", "CN"],
    ["monday", "T2"],
    ["tuesday", "T3"],
    ["wednesday", "T4"],
    ["thursday", "T5"],
    ["friday", "T6"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

const ViewDate = ({
  className,
  customDate = false,
  handleChange,
  onChangeType,
  type = constants.DAY,
  showMulti = false,
}) => {
  const isRenderFirst = useRef(true);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    customDate: [],
  });

  const [typeDate, setTypeDate] = useState(type);

  useEffect(() => {
    changeDateByTypeDate(typeDate);
  }, [typeDate]);

  const [values, setValues] = useState([]);

  useEffect(() => {
    if (isRenderFirst.current === false) {
      console.log(isRenderFirst.current);
      console.log(date);
      handleChange({
        startDate: date.startDate,
        endDate: date.endDate,
        customDate: values,
        typeDate: typeDate,
      });
    } else {
      isRenderFirst.current = false;
    }
  }, [date, values]);

  const getFirstAndLastDayInWeek = () => {
    const curr = new Date();
    const first = curr.getDate() - curr.getDay() + 1;
    let last = first + 6;

    const lastDayOfMonth = new Date(
      curr.getFullYear(),
      curr.getMonth() + 1,
      0
    ).getDate();
    if (last > lastDayOfMonth) {
      last = lastDayOfMonth;
    }

    const firstDay = new Date(curr.getFullYear(), curr.getMonth(), first);
    const lastDay = new Date(curr.getFullYear(), curr.getMonth(), last);
    return { firstDay, lastDay };
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const getFirstAndLastDayInYear = () => {
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    const lastDay = new Date(new Date().getFullYear(), 11, 31);
    return { firstDay, lastDay };
  };

  const changeDateByTypeDate = (type) => {
    switch (type) {
      case constants.DAY:
        const today = new Date();
        setDate({
          startDate: today,
          endDate: today,
        });
        break;
      case constants.WEEK:
        const dateByWeek = getFirstAndLastDayInWeek();
        setDate({
          startDate: dateByWeek.firstDay,
          endDate: dateByWeek.lastDay,
        });
        break;
      case constants.MONTH:
        const dateByMonth = getFirstAndLastDayInMonth();
        setDate({
          startDate: dateByMonth.firstDay,
          endDate: dateByMonth.lastDay,
        });
        break;
      case constants.YEAR:
        const dateByYear = getFirstAndLastDayInYear();
        setDate({
          startDate: dateByYear.firstDay,
          endDate: dateByYear.lastDay,
        });
        break;
      default:
        setDate({
          startDate: date.startDate,
          endDate: date.endDate,
        });
        break;
    }
  };

  const handlePrev = (typeDate) => {
    let startDate = date.startDate;
    let endDate = date.endDate;

    if (typeDate === constants.DAY) {
      startDate = moment(date.startDate).subtract(1, "days").toDate();
      endDate = moment(date.startDate).subtract(1, "days").toDate();
    } else if (typeDate === constants.MONTH) {
      startDate = moment(date.startDate).subtract(1, "months").toDate();
      endDate = moment(endDate).subtract(1, "months").endOf("month").toDate();
    } else if (typeDate === constants.WEEK) {
      startDate = moment(date.startDate).subtract(1, "weeks").toDate();
      endDate = moment(endDate).subtract(1, "weeks").toDate();
    } else if (typeDate === constants.YEAR) {
      startDate = moment(date.startDate).subtract(1, "years").toDate();
      endDate = moment(endDate).subtract(1, "years").toDate();
    }

    setDate({
      startDate,
      endDate,
    });
  };

  const handleNext = (typeDate) => {
    let startDate = date.startDate;
    let endDate = date.endDate;

    if (typeDate === constants.DAY) {
      startDate = moment(date.startDate).add(1, "days").toDate();
      endDate = moment(date.startDate).add(1, "days").toDate();
    } else if (typeDate === constants.MONTH) {
      startDate = moment(date.startDate).add(1, "months").toDate();
      endDate = moment(startDate).add(1, "months").subtract(1, "days").toDate();
    } else if (typeDate === constants.WEEK) {
      startDate = moment(date.startDate).add(1, "weeks").toDate();
      endDate = moment(startDate).add(1, "weeks").subtract(1, "days").toDate();
    } else if (typeDate === constants.YEAR) {
      startDate = moment(date.startDate).add(1, "years").toDate();
      endDate = moment(startDate).add(1, "years").subtract(1, "days").toDate();
    }

    setDate({
      startDate,
      endDate,
    });
  };

  function listCurrent() {
    var listCheck = [
      {
        label: "Ngày",
        value: constants.DAY,
      },
      {
        label: "Tuần",
        value: constants.WEEK,
      },
      {
        label: "Tháng",
        value: constants.MONTH,
      },
      {
        label: "Năm",
        value: constants.YEAR,
      },
    ];
    if (showMulti === true) {
      listCheck.push({
        label: "Nhiều ngày",
        value: constants.MULTI_DATE,
      });
    }

    if (customDate === true) {
      listCheck.push({
        label: "Tuỳ chỉnh",
        value: constants.CUSTOM_DATE,
      });
    }

    return listCheck;
  }

  return (
    <div className={className} style={{ alignItems: "center" }}>
      <SelectPicker
        style={{ width: "150px", marginRight:'15px' }}
        cleanable={false}
        data={listCurrent()}
        placeholder="Chọn"
        block
        searchable={false}
        onChange={(e) => {
          setTypeDate(e);
        }}
      />
      <div style={{ flex: 1 }}>
        {typeDate === constants.CUSTOM_DATE ? (
          <div>
            <DateRangePicker
              format="dd-MM-yyyy"
              cleanable={false}
              value={[date.startDate, date.endDate]}
              onChange={(values) => {
                if (values?.length > 0) {
                  setDate({
                    startDate: values[0],
                    endDate: values[1],
                  });
                }
              }}
            />
          </div>
        ) : typeDate === constants.MULTI_DATE ? (
          <div>
            <DatePicker
              inputClass="custom-input"
              locale={gregorian_en_lowercase}
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
              containerStyle={{
                width: "300px",
              }}
              calendarPosition="bottom-center"
              multiple
              value={values}
              plugins={[<DatePanel />]}
              onChange={setValues}
            />
          </div>
        ) : (
          <div style={{ alignItems: "center" , display: 'flex', justifyContent:'center'}}>
            <span
              onClick={() => handlePrev(typeDate)}
              style={{ cursor: "pointer" }}
            >
              <MdArrowBackIos/>
            </span>
            {typeDate === constants.DAY ? (
              <span className="px-3">
                {moment(date.startDate).format(constants.FORMAT_DD_MM_YYYY)}
              </span>
            ) : (
              <span className="px-3">
                Từ {moment(date.startDate).format(constants.FORMAT_DD_MM_YYYY)}{" "}
                đến {moment(date.endDate).format(constants.FORMAT_DD_MM_YYYY)}
              </span>
            )}
            <span
              onClick={() => handleNext(typeDate)}
              style={{ cursor: "pointer" }}
            >
               <MdArrowForwardIos/>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDate;
