/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Form, Input, Select, Upload, Row, Col } from "antd";

import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";
import { probationServices } from "../../../services/probationServices";

const AddCategoryModal = ({ open, closeModal, onOk, dataInput }) => {
  const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
  const [cate, setCate] = useState(null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        // getOneHelp(dataInput.id);
      }
    } else {
      onReset();
    }
  }, [open]);

  const createProbationCategory = async (data) => {
    setLoading(true);
    try {
      const response = await probationServices.createProbationCategory(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateProbationCategory = async (data, id) => {
    try {
      const response = await probationServices.updateProbationCategory(
        id,
        data
      );
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    if (dataInput != null) {
      updateProbationCategory(values, dataInput.id);
    } else {
      console.log(values);
      createProbationCategory(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      name: dataInput?.name ?? "",
      failed_number: dataInput?.failed_number ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  const { Option } = Select;

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa hạng mục" : "Thêm hạng mục"}
      centered
      open={open}
      width={800}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="mt-4">
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Tên hạng mục xem xét"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tên hạng mục xem xét"} />
          </Form.Item>
          <Form.Item
            name="failed_number"
            label="Số lần không đạt sẽ bị rớt thử việc"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input type="number" placeholder={"Nhập số lần không đạt sẽ bị rớt thử việc"} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCategoryModal;
