import { Tabs } from "antd";
import FranchiseFile from "./File/FranchiseFile";
import FranchiseCate from "./FileCate/FranchiseCate";
import { useLocation } from 'react-router-dom';

export default function FranchisePage() {

  const location = useLocation();

  // Lấy tham số truy vấn từ URL
  const queryParams = new URLSearchParams(location.search);
  const customer_id = queryParams.get('customer_id');
  
  console.log(customer_id);
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
        label: "DANH SÁCH FILE",
          key: "1",
          children: FranchiseFile(customer_id),
        }, 
        {
          label: "LOẠI FILE",
          key: "2",
          children: FranchiseCate(),
        },
      ]}
    />
  );
}
