import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Drawer, Input, Spin } from "antd";
import axios from "axios";
import { debounce, throttle } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { chatActions as a } from "../../../../actions/chatActions";
import { constants as c } from "../../../../constants";
import { FileIcon, ImageIcon, ReplyIcon } from "../../icons";
import { socket } from "../../socket/socket";
import { handleShowTypeMessage } from "../../utils";
import Message from "../Message";
import EmotionGif from "./EmotionGif";
import { StyledRoomChat } from "./RoomChat.style";
import { toast } from "react-toastify";
import ViewedUsers from "./ViewedUsers";

const typeSendMess = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "custom",
  VIDEO: "video",
  GIF: "gif",
};

const typeSendFile = {
  IMAGE: 0,
  VIDEO: 1,
  FILE: 2,
};

const RoomChat = ({
  setOpenDrawerPin,
  OpenDrawerPin,
  openDrawerSearchMess,
  setOpenDrawerSearchMess,
}) => {
  const dispatch = useDispatch();
  const {
    messageReplySelected,
    roomSelected,
    messageList,
    allMessageInfo,
    searchMessageList,
    dataTyping,
    loadingDrawer,
  } = useSelector((state) => state.chat);

  const profile = useSelector((state) => state.user.profile);
  const token = JSON.parse(localStorage.getItem("tokenInfo"));

  const [valueInput, setValueInput] = useState("");
  const [currentPageOlder, setCurrentPageOlder] = useState(1);
  const [currentPageNewer, setCurrentPageNewer] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchKeyMess, setSearchKeyMess] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [popupTag, setPopupTag] = useState({
    show: false,
    positionLeft: 0,
    user_tag: [],
    userListFilter: [],
    focusedIndex: 0,
  });

  const inputRef = useRef(null);
  const roomChatRef = useRef(null);
  const typingRef = useRef(null);
  const roomIdPrev = useRef();

  const { list_last_message, room_chat_pin, users } = roomSelected;
  useEffect(() => {
    if (messageReplySelected?.id) {
      inputRef.current.focus();
    }
  }, [messageReplySelected?.id]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (openDrawerSearchMess) {
        handleSearchMessage();
      }
    }, 400);
    return () => clearTimeout(timer);
  }, [searchKeyMess]);

  const handleScroll = useCallback(
    debounce((currentPage) => {
      if (roomChatRef?.current?.scrollTop > -2) {
        fetchMessageNewer(currentPage);
      }
    }, 500),
    [roomSelected?.id]
  );

  useEffect(() => {
    if (currentPageNewer < 1) return;
    const handleRealScroll = () => {
      handleScroll(currentPageNewer);
    };

    roomChatRef?.current?.addEventListener("scroll", handleRealScroll);
    return () => {
      if (roomChatRef.current) {
        roomChatRef.current.removeEventListener("scroll", handleRealScroll);
      }
    };
  }, [handleScroll, currentPageNewer, roomSelected?.id]);

  useEffect(() => {
    if (!roomSelected?.id) return;

    if (profile) {
      socket.off(`chat:message_to_group:${roomIdPrev.current}`);
      socket.on(`chat:message_to_group:${roomSelected.id}`, (messages) => {
        // nếu tin nhắn là của mình hoặc là cảm xúc hoặc là typing thì không gửi hàm đi
        if (
          messages?.data?.user_id === profile.id
          // messages?.user?.id === profile?.id ||
          // messages.feel?.length
        )
          return;
        if (messages?.id || messages?.data || messages.feel?.length) {
          dispatch(a.handleReceiveMessage(messages));
          if (
            !messages?.data &&
            !messages.is_update_message &&
            roomChatRef &&
            roomChatRef.current
          )
            roomChatRef.current.scrollTop = 0;
        }
      });
    }
    setCurrentPageOlder(1);
    setCurrentPageNewer(1);
    setOpenDrawerPin(false);
    setOpenDrawerSearchMess(false);
    setPopupTag({
      show: false,
      positionLeft: 0,
      user_tag: [],
      userListFilter: [],
      focusedIndex: 0,
    });
    if (list_last_message && list_last_message.length < 20) setHasMore(false);
    else setHasMore(true);
    roomChatRef.current.scrollTop = -10;
    inputRef.current.focus();
    inputRef.current.innerText = "";
    roomIdPrev.current = roomSelected?.id;
    return () => {
      socket.off(`chat:message_to_group:${roomSelected.id}`, (roomChats) => {
        console.log("==========================>", roomChats);
      });
    };
  }, [roomSelected?.id]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleSplitFile(acceptedFiles);
    },
    [roomSelected?.id]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  const handleGetMessageList = (page, way = "first", keyword = "") => {
    const query = `?page=${page}&limit=20&search=${keyword}`;
    dispatch(a.getMessageList(roomSelected?.id, query, way));
  };

  const handleSplitFile = (acceptedFiles) => {
    let arrayImages = [];
    let arrayFiles = [];
    let arrayVideos = [];
    let largeFiles = [];
    acceptedFiles.forEach((file) => {
      if (file.size > 100 * 1024 * 1024) {
        largeFiles.push(file);
      } else {
        const type = handleMessageFileType(file?.type);
        if (type === typeSendFile.FILE) arrayFiles.push(file);
        else if (type === typeSendFile.VIDEO) arrayVideos.push(file);
        else arrayImages.push(file);
      }
    });
    if (largeFiles.length > 0) {
      toast.warning(`Có ${largeFiles.length} file vượt quá 100MB`);
    }
    if (arrayImages.length) onSendMultipleFile(arrayImages, "image");
    if (arrayFiles.length) onSendMultipleFile(arrayFiles, "custom");
    if (arrayVideos.length) onSendMultipleFile(arrayVideos, "video");
  };

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*";
    input.addEventListener("change", (e) =>
      onSendMultipleFile(e.target.files, "image")
    );
    document.body.appendChild(input);
    input.click();
    input.style.display = "none";
  };

  const handleFileClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.addEventListener("change", (e) =>
      handleSplitFile(Array.from(e.target.files))
    );
    document.body.appendChild(input);
    input.click();
    input.style.display = "none";
  };

  const validateAndReplace = (html, usernames) => {
    // Chuyển html sang DOM
    const dom = new DOMParser().parseFromString(html, "text/html");

    // Lấy tất cả các thẻ span được tag tên
    const spans = dom.querySelectorAll(".tag-name-hightlight");

    spans.forEach((span) => {
      const username = span.textContent;
      if (!usernames.includes(username) && username !== "@Tất cả") {
        const newUserName = username.replace("@", "");
        const userTag = popupTag.user_tag.filter(
          (item) => item.name.slice(0, -1) !== newUserName
        );
        setPopupTag((prev) => ({
          ...prev,
          user_tag: username === "@Tất cả" ? [] : userTag,
        }));

        const spaceSpan = document.createElement("span");
        spaceSpan.className = "space";
        spaceSpan.style.whiteSpace = "pre";
        spaceSpan.textContent = " ";
        span.replaceWith(spaceSpan);
      }
    });

    // Trả về innerHTML sau khi đã xử lý
    return dom.documentElement.innerHTML;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && popupTag.show) {
      e.preventDefault();
      handleTagUser(popupTag.userListFilter[popupTag.focusedIndex]);
    } else if (e.key === "Enter") {
      if (e.shiftKey) {
        setValueInput(valueInput + "\\n");
      } else {
        e.preventDefault();
        handleSendMessage(typeSendMess.TEXT);
      }
    }
    if (e.key === "ArrowUp" && popupTag.focusedIndex > 0) {
      setPopupTag((prev) => ({
        ...prev,
        focusedIndex: prev.focusedIndex - 1,
      }));
    } else if (
      e.key === "ArrowDown" &&
      popupTag.focusedIndex < popupTag.userListFilter?.length - 1
    ) {
      setPopupTag((prev) => ({
        ...prev,
        focusedIndex: prev.focusedIndex + 1,
      }));
    }
  };

  const handleSendMessage = async (type) => {
    console.log("getImageSrcs(): ", getImageSrcs());
    if (getImageSrcs().length) {
      getImageSrcs().forEach((src) => {
        if (!src.startsWith("data:image")) {
          axios.get(src, { responseType: "arraybuffer" }).then((response) => {
            const base64 = Buffer.from(response.data).toString("base64");
            const base64Image = `data:image/jpeg;base64,${base64}`;
            const byteString = atob(base64Image.split(",")[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i += 1) {
              ia[i] = byteString.charCodeAt(i);
            }
            const newBlob = new Blob([ab], {
              type: "image/jpeg",
            });
            const imageFile = new File([newBlob], "image.jpeg", {
              type: newBlob.type,
            });
            handleFileChange(imageFile);
            roomChatRef.current.scrollTop = 0;
            inputRef.current.innerText = "";
            setValueInput("");
          });
        } else {
          const byteString = atob(src.split(",")[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
          }
          const newBlob = new Blob([ab], {
            type: "image/jpeg",
          });
          const imageFile = new File([newBlob], "image.jpeg", {
            type: newBlob.type,
          });
          handleFileChange(imageFile);
          roomChatRef.current.scrollTop = 0;
          inputRef.current.innerText = "";
          setValueInput("");
        }
      });
      return;
    }
    if (!valueInput.trim() || valueInput.trim().length === 0) return;
    onSend(valueInput, type);
    roomChatRef.current.scrollTop = 0;
    inputRef.current.innerText = "";
    setValueInput("");
  };

  const handleMessageFileType = (type) => {
    if (!type) return typeSendFile.IMAGE;
    if (type.startsWith("image/")) return typeSendFile.IMAGE;
    if (type.startsWith("video/")) return typeSendFile.VIDEO;
    return typeSendFile.FILE;
  };

  const handleMessageType = (type) => {
    if (!type) return typeSendMess.IMAGE;
    if (type.startsWith("image/")) return typeSendMess.IMAGE;
    if (type.startsWith("video/")) return typeSendMess.VIDEO;
    return typeSendMess.FILE;
  };

  const sendTypingStatus = () => {
    if (!isTyping) {
      setIsTyping(true);
      const param = {
        room_id: roomSelected.id,
        data: {
          user_id: profile.id,
          status: "typing",
        },
      };
      socket.emit("chat:typing", param);
      //socketRef.current.emit("chat:typing", param);
      // socket.disconnect();
    }
    typingRef.current = setTimeout(() => {
      const param = {
        room_id: roomSelected.id,
        data: {
          user_id: profile.id,
          status: "typed",
        },
      };
      socket.emit("chat:typing", param);
      setIsTyping(false);
    }, 1000);
  };

  const throttledTyping = throttle(sendTypingStatus, 500);

  const handleUserListFilter = () => {
    let userListFilter = [];

    const inputText = inputRef.current.innerText;
    const lastWord = inputText.split(" ").pop();
    if (lastWord.includes("@")) {
      const lastWordTag = lastWord.split("@").pop();
      userListFilter = roomSelected?.users?.filter((user) =>
        removeVietnameseAccents(user.name.toLowerCase()).includes(
          removeVietnameseAccents(lastWordTag.toLowerCase())
        )
      );
    } else {
      userListFilter = roomSelected?.users;
    }
    userListFilter = userListFilter?.filter((user) => user.id != profile.id);
    userListFilter = userListFilter.filter(
      (user) => !popupTag.user_tag?.find((item) => item.id === user.id)
    );
    userListFilter = [{ id: -2, name: "Tất cả" }, ...userListFilter];
    return userListFilter;
  };

  function handleChangeInput(e) {
    console.log("e: ", e);
    const value = e.target.innerText;
    setValueInput(value);
    clearTimeout(typingRef.current);
    throttledTyping();
    if (value && value.split(" ").pop().startsWith("@")) {
      const selectionStart = window.getSelection().focusOffset;
      setPopupTag((prev) => ({
        ...prev,
        show: true,
        positionLeft: selectionStart,
        userListFilter: handleUserListFilter(),
      }));
    } else if (popupTag.show) {
      setPopupTag((prev) => ({
        ...prev,
        positionLeft: 0,
        userListFilter: [],
        focusedIndex: 0,
        show: false,
      }));
    }
    // if (e.nativeEvent.inputType === "deleteContentBackward") {
    //   const highlightedText = window.getSelection().toString();
    //   if (highlightedText) {
    //     window.getSelection().deleteFromDocument();
    //     e.preventDefault();
    //     document.execCommand("insertText", false, "");
    //   }
    //   let userNameTag = [];
    //   if (popupTag.user_tag?.length) {
    //     if (popupTag.user_tag[0].id === -2) userNameTag = ["@tất cả"];
    //     else userNameTag = popupTag.user_tag.map((item) => `@${item.name}`);
    //   }
    //   const newHtml = validateAndReplace(
    //     inputRef.current.innerHTML,
    //     userNameTag
    //   );
    //   if (popupTag.user_tag?.length) {
    //     inputRef.current.innerHTML = newHtml;
    //     const range = document.createRange();
    //     if(inputRef.current) range.selectNodeContents(inputRef.current);
    //     range.collapse(false);

    //     const selection = window.getSelection();
    //     selection.removeAllRanges();
    //     selection.addRange(range);
    //     inputRef.current.focus();
    //   } else {
    //     const anchorOffset = window.getSelection().anchorOffset;
    //     inputRef.current.innerHTML = newHtml;
    //     const range = document.createRange();
    //     if(inputRef.current.firstChild) range.selectNodeContents(inputRef.current.firstChild);
    //     range.collapse(true);

    //     if(inputRef.current.firstChild) range.setStart(inputRef.current.firstChild, anchorOffset)
    //     else range.setStart(inputRef.current, anchorOffset)

    //     const selection = window.getSelection();
    //     selection.removeAllRanges();
    //     selection.addRange(range);
    //     inputRef.current.focus();
    //   }
    // }
  }

  const handleSelectEmoji = (emoji) => {
    const { unified } = emoji;
    let unicodeNumber = parseInt(unified, 16);
    const emojiString = String.fromCodePoint(unicodeNumber);

    inputRef.current.innerHTML += emojiString;

    const event = new Event("input");
    inputRef.current.dispatchEvent(event);

    setValueInput(inputRef.current.innerHTML);
  };

  const fetchMoreMessage = () => {
    if (messageList.length >= allMessageInfo.total) {
      setHasMore(false);
      return;
    }
    handleGetMessageList(currentPageOlder + 1, "older");
    setCurrentPageOlder((pag) => pag + 1);
  };

  const fetchMessageNewer = (currentPage) => {
    if (currentPage < 2) return;
    roomChatRef.current.scrollTop = -500;
    handleGetMessageList(currentPage - 1, "newer");
    setCurrentPageNewer((pag) => pag - 1);
  };

  const handleFileChange = (file) => {
    const formData = new FormData();
    formData.append("image", file);
    if (file.size > 20 * 1024 * 1024) {
      toast.warning("File vượt quá 20MB");
      return;
    }
    dispatch(a.handleLoadingFile(true));
    https://crm-api.studyguide.dev/api/upload_v2/files?type=franchise
    axios
      .post(`${c.API_URL}/upload_v2/files?type=chat`, formData, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        const messageFile = [
          {
            name: file?.name,
            size: file?.size,
            type: handleMessageFileType(file?.type),
            url_file: response.data.data.image_url,
          },
        ];
        onSend({ ...messageFile }, handleMessageType(file?.type));
        roomChatRef.current.scrollTop = 0;
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(a.handleLoadingFile(false));
      });
  };

  const onSendMultipleFile = async (files, type) => {
    const fileListArray = Array.from(files);
    if (!fileListArray?.length) return;
    let messageFile = [];
    dispatch(a.handleLoadingFile(true));
    const uploadPromises = fileListArray.map((file) => {
      const formData = new FormData();
      formData.append("image", file);
      messageFile.push({
        name: file?.name,
        size: file?.size,
        type: handleMessageFileType(file?.type),
      });
      return axios.post(`${c.API_URL}/upload_v2/files?type=chat`, formData, {
        headers: {
          token: token,
        },
      });
    });
    const results = await Promise.all(uploadPromises);
    const imageFiles = results.map((res) => res.data.data.image_url);
    if (type === "image") {
      const messageFile2 = messageFile.map((file, index) => {
        return {
          ...file,
          url_file: imageFiles[index],
        };
      });
      onSend([...messageFile2], type);
    } else {
      messageFile.forEach((file, index) => {
        const messageFile2 = [
          {
            ...file,
            url_file: imageFiles[index],
          },
        ];
        onSend([...messageFile2], type);
      });
    }
    roomChatRef.current.scrollTop = 0;
  };

  const onSend = (content, type) => {
    const time = moment().utcOffset("+07:00");
    const params = {
      id: uuidv4(),
      content:
        type === typeSendMess.TEXT || type === typeSendMess.GIF
          ? content
          : null,
      type: type,
      room_chat_id: roomSelected.id,
      // "status": "sent",
      feel: null,
      hide_for_user: false,
      hide_all: false,
      page: 0,
      created_at: time.toString(),
      updated_at: time.toString(),
      message_reply: messageReplySelected?.id ? messageReplySelected : null,
      message_reply_id: messageReplySelected?.id || null,
      user: {
        id: profile.id,
        name: profile.name,
        avatar: profile.avatar,
        // latest_req_time: "2023-10-11 11:31:20",
      },
      user_tag_ids: popupTag.user_tag?.length
        ? popupTag.user_tag.map((item) => item.id)
        : null,
      message_file: type !== typeSendMess.TEXT ? content : null,
    };

    dispatch(a.createMessage(params));
    handleRemoveReplyMessage();
    setPopupTag({
      show: false,
      positionLeft: 0,
      user_tag: [],
      userListFilter: [],
      focusedIndex: 0,
    });
  };

  const getImageSrcs = () => {
    const div = document.querySelector(".input-chat");
    const images = div.querySelectorAll("img");

    const srcs = [];
    if (images && images.length) {
      images.forEach((image) => {
        srcs.push(image.currentSrc || image.src);
      });
    }
    return srcs;
  };

  const handleSearchMessage = () => {
    handleGetMessageList(1, "search", searchKeyMess);
  };

  const handleRemoveReplyMessage = () => {
    inputRef.current.focus();
    dispatch(a.handleSelectReplyMessage({}));
  };

  const onSendGif = (gifUrl) => {
    onSend(gifUrl, typeSendMess.GIF);
  };

  const hasContentForSend = () => {
    if (inputRef?.current?.innerHTML?.trim() || valueInput.trim()) return true;
    return false;
  };

  const scrollToMessage = (messageId) => {
    const messageEl = roomChatRef.current.querySelector(
      `[data-message-id="${messageId}"]`
    );

    if (messageEl) {
      messageEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const contentEl = messageEl.querySelector(".message-content");

      contentEl.classList.add("highlight");
      setTimeout(() => {
        contentEl.classList.remove("highlight");
      }, 4000);
    }
  };

  const handleMoveToMessage = (messageId) => {
    const messageEl = roomChatRef.current.querySelector(
      `[data-message-id="${messageId}"]`
    );
    setOpenDrawerPin(false);
    setOpenDrawerSearchMess(false);
    if (messageEl) {
      scrollToMessage(messageId);
      return;
    }
    const onSuccess = (res) => {
      const queryString = `?page=${res.page}`;
      dispatch(
        a.getMessageList(
          res.room_chat_id,
          queryString,
          "first",
          messageId,
          scrollToMessage
        )
      );
      setCurrentPageOlder(res.page);
      setCurrentPageNewer(res.page);
    };
    dispatch(a.callPageMessage(messageId, onSuccess));
  };

  const renderMessageListLink = (dataList) => {
    if (dataList?.length) {
      return dataList.map((item, index) => {
        const { user } = item;
        return (
          <div
            key={index}
            className="pin-message message"
            onClick={() => handleMoveToMessage(item?.message?.id || item.id)}
          >
            <div className="message__avatar">
              <img
                src={
                  user?.avatar ||
                  "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
                }}
                alt="avatar"
              />
            </div>
            <div className="message__content">
              <p className="message__name" style={{ fontWeight: "600" }}>
                {user?.name}
              </p>
              <p className="message__content text-truncate-1">
                {handleShowTypeMessage(
                  item?.message?.content || item.content,
                  item?.message?.type || item.type
                )}
              </p>
            </div>
          </div>
        );
      });
    }
    return <p style={{ textAlign: "center" }}>Không có tin nhắn nào</p>;
  };

  const handleTagUser = (user) => {
    const input = inputRef.current;
    const value = input.innerHTML;
    let newValue =
      value.replace(/@[^\s]*$/g, "") +
      `<span class="tag-name-hightlight" style="color: blue; display: inline-block; ">@${
        user?.name || ""
      }</span><span class="space" style="white-space: pre-wrap;"> </span>`;
    let valueInnerText = valueInput + `${user?.name}`;
    setValueInput(valueInnerText);
    input.innerHTML = newValue;
    if (user.id === -2) {
      setPopupTag((prev) => ({
        ...prev,
        show: false,
        user_tag: [user],
        focusedIndex: 0,
      }));
    } else {
      setPopupTag((prev) => ({
        ...prev,
        show: false,
        user_tag: prev?.user_tag?.length ? [...prev?.user_tag, user] : [user],
        focusedIndex: 0,
      }));
    }
    const spaceSpans = input.querySelectorAll(".space");
    const lastSpace = spaceSpans[spaceSpans.length - 1];
    if (lastSpace) {
      const range = document.createRange();
      range.selectNodeContents(lastSpace);
      range.collapse(false);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      input.focus();
    }
  };

  function removeVietnameseAccents(str) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return str;
  }

  const renderPopupTag = () => {
    if (popupTag.user_tag?.length && popupTag.user_tag[0].id === -2)
      return null;
    let userListFilter = popupTag.userListFilter;

    if (userListFilter.length === 0) return null;
    let heightPopup = userListFilter.length;

    return (
      <div
        className="popup-tag"
        style={{
          width: "350px",
          maxHeight: "400px",
          height: `${heightPopup * 48}px`,
          background: "white",
          position: "absolute",
          left: `${10 + popupTag.positionLeft * 5}px`,
          top: `-${userListFilter?.length > 8 ? 400 : heightPopup * 48}px`,
          boxShadow: "0 0px 2px #00000099",
          borderRadius: "2px",
          overflowY: "scroll",
        }}
      >
        {userListFilter.map((user, index) => {
          return (
            <div
              key={user.id}
              className="item"
              onClick={() => handleTagUser(user)}
              style={{
                background: popupTag.focusedIndex === index ? "#e6f7ff" : "",
              }}
            >
              <img
                src={
                  user?.avatar ||
                  "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
                }}
                alt="avatar"
              />
              <span>{user.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMessageList = useMemo(() => {
    return messageList.map((message, index) => {
      return (
        <div key={message.id} data-message-id={message.id}>
          <Message
            message={message}
            nextMessage={
              index + 1 <= messageList.length ? messageList[index + 1] : null
            }
            prevMessage={index - 1 >= 0 ? messageList[index - 1] : null}
            handleMoveToMessage={handleMoveToMessage}
          />
        </div>
      );
    });
  }, [messageList]);

  return (
    <StyledRoomChat>
      <div
        className="view-chat-container"
        id="scrollableMessageList"
        ref={roomChatRef}
      >
        <div {...getRootProps({ className: `dropzone` })}>
          {isDragActive && <div className={"dropzone--isActive"}></div>}
          {messageList && messageList.length ? (
            <InfiniteScroll
              dataLength={messageList?.length}
              next={fetchMoreMessage}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true} //
              hasMore={hasMore}
              // height={450}
              loader={
                <div style={{ textAlign: "center", height: "36px" }}>
                  <Spin></Spin>
                </div>
              }
              scrollableTarget="scrollableMessageList"
              endMessage={
                <p style={{ textAlign: "center", marginBottom: "8px" }}>
                  Cuối cùng của đoạn hội thoại
                </p>
              }
            >
              <ViewedUsers list_last_message={list_last_message} />
              {renderMessageList}
            </InfiniteScroll>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {OpenDrawerPin && (
        <Drawer
          title="Danh sách ghim"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawerPin(false)}
          open={OpenDrawerPin}
          getContainer={false}
        >
          <div className="list-pin-message">
            {renderMessageListLink(room_chat_pin)}
          </div>
        </Drawer>
      )}
      {openDrawerSearchMess && (
        <Drawer
          title="Tìm kiếm tin nhắn"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawerSearchMess(false)}
          open={openDrawerSearchMess}
          getContainer={false}
        >
          <Spin spinning={loadingDrawer}>
            <div className="list-pin-message">
              <div
                className="d-flex justify-content-center"
                style={{ gap: "8px", marginBottom: "16px" }}
              >
                <Input
                  placeholder="Tìm kiếm tin nhắn"
                  onChange={(e) => setSearchKeyMess(e.target.value)}
                  defaultValue={searchKeyMess}
                />
                {/* <Button type="primary" onClick={handleSearchMessage}>
                  Tìm kiếm
                </Button> */}
              </div>

              {renderMessageListLink(searchMessageList)}
            </div>
          </Spin>
        </Drawer>
      )}
      <div className="input-chat-container">
        {popupTag.show && renderPopupTag()}

        {dataTyping?.status === "typing" &&
          dataTyping?.roomId === roomSelected?.id && (
            <div
              style={{
                fontSize: "12px",
                color: "#000",
                background: "#fff",
                padding: "0 8px",
                width: "fit-content",
                position: "absolute",
                top: "-20px",
              }}
            >
              {
                users.find((user) => user.id === Number(dataTyping.user_id))
                  ?.name
              }{" "}
              đang soạn tin <MoreHorizIcon fontSize="small" color="#7589a3" />
            </div>
          )}
        {messageReplySelected?.id && (
          <div className="message-reply">
            <div className="d-flex align-items-center" style={{ gap: "8px" }}>
              <ReplyIcon style={{ color: "#3390EC" }} />
              <div
                style={{ borderLeft: "2px solid #3390EC", paddingLeft: "8px" }}
              >
                <p className="message__name">
                  {messageReplySelected.user.name}
                </p>
                <p className="message__content">
                  {handleShowTypeMessage(
                    messageReplySelected.content,
                    messageReplySelected.type
                  )}
                </p>
              </div>
            </div>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleRemoveReplyMessage}
            />
          </div>
        )}
        <div
          ref={inputRef}
          className="input-chat"
          contentEditable
          innerHTML={valueInput}
          onInput={handleChangeInput}
          onKeyDown={handleKeyDown}
        ></div>
        {/* </Highlight> */}
        <div className="input-chat-action">
          <div style={{ marginRight: "8px" }}>
            <EmotionGif
              handleSelectEmoji={handleSelectEmoji}
              onSendGif={onSendGif}
            />
          </div>
          <div
            className="input-chat-action__image"
            onClick={handleImageClick}
            title="Gửi ảnh hoặc video"
          >
            <ImageIcon style={{ width: "18px", height: "18px" }} />
          </div>
          <div
            className="input-chat-action__image"
            onClick={handleFileClick}
            title="Gửi file"
          >
            <FileIcon
              style={{ width: "18px", height: "18px", marginLeft: "8px" }}
            />
          </div>
          <p
            className={`input-chat-action__send ${
              !hasContentForSend() && `disabled`
            } `}
            onClick={() => handleSendMessage("text")}
          >
            Gửi
          </p>
        </div>
      </div>
    </StyledRoomChat>
  );
};

export default React.memo(RoomChat);
