import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import moment from "moment";
import AddPostModal from "./child/AddPostModal";
import PermissionChecker from "../../../components/PermissionChecker";
import { useSelector } from "react-redux";
const { Search } = Input;

export default function FranchisePostPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(null);

  const getPost = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getPost(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );
      setPosts(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deletePost = async (id) => {
    try {
      const response = await franchiseServices.deletePost(id);
      getPost(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPost();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "Hình ảnh",
      dataIndex: "image_url",
      key: "image_url",
      render: (_, record) => (
        <img
          src={record?.image_url}
          style={{
            height: "100px",
            width: "100px",
            fit: "cover",
            borderRadius: "10px",
          }}
        ></img>
      ),
    },
    {
      title: "tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (_, record) => <a>{record?.title ?? "--:--"}</a>,
    },
    {
      title: "Người đăng",
      dataIndex: "staff",
      key: "staff",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Ngày đăng",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <a>{moment(record.created_at).format("DD-MM-YYYY HH:mm") ?? "--:--"}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_post_edit ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: record.data,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_post_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá bài đăng ?"
                description="Bạn có chắc chắn muốn xoá bài đăng này chứ ?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deletePost(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm bài đăng"
          onSearch={(v) => {
            getPost(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_post_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Thêm bài đăng
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            style: {
              background: record.status === 0 ? "#f6f6f6" : "default",
            },
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: record.data,
              });
            },
          };
        }}
        dataSource={(posts?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            title: e.title ?? "--:--",
            image_url: e.image_url ?? "--:--",
            staff: e.staff?.name ?? "--:--",
            content: e?.content ?? "--:--",
            created_at: e?.created_at,
            data: e,
          };
        })}
        pagination={{
          total: posts?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getPost(e.current);
        }}
      />
      <AddPostModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getPost(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
