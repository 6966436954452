import { constants as c } from "../constants";
const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

function getAllIncident({ param }) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(`${c.API_URL}/incident/manager/form${param}`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function getProcedure() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
  };
  return fetch(
    `${c.API_URL}/procedure/manager/procedure?type=incident_infomation`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function createIncident({ data }) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/incident/staff/form/submit`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function deleteIncidentMany({ data }) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${c.API_URL}/incident/manager/form/many`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

function updateStatusIncident({ incidentId, data }) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo ? tokenInfo : "",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${c.API_URL}/incident/manager/form/${incidentId}`,
    requestOptions
  )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

export const incidentServices = {
  getAllIncident,
  createIncident,
  deleteIncidentMany,
  getProcedure,
  updateStatusIncident,
};
