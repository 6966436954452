import React, { useEffect, useState } from "react";
import { Space, Table, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import moment from "moment";
import { formatMoneyVND } from "../../../helpers";
import PermissionChecker from "../../../components/PermissionChecker";
import { useSelector } from "react-redux";
const { Search } = Input;

export default function FranchiseRegisterPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [registers, setRegisters] = useState(null);
  const [loading, setLoading] = useState(null);

  const getRegister = async (page, search) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getRegister(
        `?search=${search ?? ""}&page=${page ?? 1}`
      );
      setRegisters(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteRegister = async (id) => {
    try {
      const response = await franchiseServices.deleteRegister(id);
      getRegister(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegister();
  }, []);

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const columns = [
    {
      title: "Họ và tên",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <a>{record?.name ?? "--:--"}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text) => <a>{text}</a>,
    },

    {
      title: "Tỉnh thành",
      dataIndex: "province",
      key: "province",
      render: (text) => <a>{text}</a>,
    },

    {
      title: "Số vốn",
      dataIndex: "capital",
      key: "capital",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Quận huyện",
      dataIndex: "district",
      key: "district",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <a>{moment(record.created_at).format("DD-MM-YYYY HH:mm") ?? "--:--"}</a>
      ),
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_contact_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá đăng ký ?"
                description="Bạn có chắc chắn muốn xoá đăng ký này chứ ?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteRegister(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm theo số điện thoại"
          onSearch={(v) => {
            getRegister(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            style: {
              background: record.status === 0 ? "#f6f6f6" : "default",
            },
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: record.data,
              });
            },
          };
        }}
        dataSource={(registers?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            name: e.name ?? "--:--",
            email: e.email ?? "--:--",
            phone_number: e.phone_number ?? "--:--",
            province: e.province ?? "--:--",
            district: e.district ?? "--:--",
            capital: formatMoneyVND(e.capital ?? "--:--"),
            created_at: e?.created_at,
            data: e,
          };
        })}
        pagination={{
          total: registers?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getRegister(e.current);
        }}
      />
    </div>
  );
}
