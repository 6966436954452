import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import Item from "./child/Item";
import { Link, useParams } from "react-router-dom";
import Delete from "./child/Delete";
import MultiDelete from "./child/MultiDelete";

import ModalUID from "../../components/ModalUID";
import { useSelector, useDispatch } from "react-redux";
import { studyActions } from "../../actions/studyActions";
import { constants as c } from "../../constants";
import Loading from "../../components/Loading";
import ListStaff from "./child/ListStaff";
import { staffAction } from "../../actions/staffActions";
import SearchInput from "../../components/SeachInput";

export default function StudyPage() {
  const [state, setState] = useState({
    modal: {
      componentAction: null,
      title: "",
      method: null,
    },
    delete_id: null,
  });
  const { modal } = state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectStaffs, setSelectStaffs] = useState([]);

  const studies = useSelector((state) => state.study.list);
  const staffs = useSelector((state) => state.staff.list);
  const departmentList = useSelector((state) => state.staff.department_list);
  const [searchValue, setSearchValue] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");

  console.log(studies);

  useEffect(() => {
    if (studies.status === c.NONE) {
      dispatch(studyActions.getAllStudies(`?course_id=${id}`));
    }
    if (staffs.status === c.NONE) {
      dispatch(staffAction.getAllStaffs());
    }
    if (departmentList.status === c.NONE) {
      dispatch(staffAction.getAllDepartments());
    }
  });

  function handleDelete(id) {
    setState({
      ...state,
      delete_id: id,
      modal: {
        componentAction: <Delete></Delete>,
        title: "Xóa nhân viên",
        method: "DELETE",
      },
    });
  }
  function onChangeDepartment(e) {
    var { value } = e.target;
    dispatch(
      studyActions.getAllStudies(value === "" ? "" : `?department_id=${value}`)
    );
    setFilterDepartment(value);
  }
  function createMulti(data) {
    dispatch(studyActions.createMulti({ ...data, course_id: id }));
  }

  useEffect(() => {
    dispatch({ type: c.RESET_STUDIES_LIST_STATUS });
    dispatch({ type: c.RESET_STAFFS_LIST_STATUS });
    dispatch({ type: c.RESET_DEPARTMENT_LIST_STATUS });

    // dispatch(staffAction.getStaffInfo(id));
  }, []);

  function handleDelModal() {
    dispatch(studyActions.deleteStudy(state.delete_id));
  }

  function onChangeSearch(value) {
    setSearchValue(value);
  }

  function onSubmitSearch(value) {
    dispatch(studyActions.getAllStudies(`?search=${value}&course_id=${id}`));
    setSearchValue(value);
  }
  function handleClickItem(index) {
    dispatch(studyActions.getAllStudies(`?page=${index}&course_id=${id}`));
  }
  const perPage = studies.meta?.per_page;
  const currentPage = studies.meta?.current_page;

  function checkedStaff(id) {
    return selectStaffs.filter((v) => v == id).length;
  }

  function changeSelectStaffs(e, id) {
    var staffLists = [];
    var { checked } = e.target;
    if (id == null) {
      if (checked == true) {
        staffLists = studies.data?.filter((v) => {
          return (
            selectStaffs.filter((_v) => {
              return v.user?.id == _v;
            })?.length == 0
          );
        });

        staffLists = studies.data?.map((v) => v.user?.id);
        staffLists = [...selectStaffs, ...staffLists];
      } else {
        staffLists = selectStaffs.filter((v) => {
          return (
            studies.data?.filter((_v) => {
              return parseInt(v) === parseInt(_v.user.id);
            })?.length === 0
          );
        });
      }
    } else {
      if (checked == true) staffLists = [...selectStaffs, id];
      else staffLists = selectStaffs.filter((v) => v !== id);
    }

    setSelectStaffs(staffLists);
  }

  function handleDelMultiModal() {
    dispatch(
      studyActions.deleteMultiStudy({ course_id: id, user_id: selectStaffs })
    );
    setSelectStaffs([]);
  }

  function checkedAll() {
    return (
      studies.data?.filter((v) => {
        return (
          selectStaffs.filter((_v) => {
            return v.user.id == _v;
          })?.length > 0
        );
      })?.length === studies.data?.length
    );
  }

  var _selected = checkedAll();

  return (
    <>
      <div className="container-fluid">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="h4 title_content mb-0 text-gray-800">
            Danh sách học viên
          </h4>{" "}
          <div style={{ display: "flex" }}>
            <button
              className="btn btn-info btn-icon-split btn-sm show"
              onClick={() =>
                setState({
                  ...state,
                  modal: {
                    showFooter: false,
                    componentAction: (
                      <ListStaff
                        createMulti={createMulti}
                        departmentList={departmentList.data || []}
                        staffs={staffs.data || []}
                      ></ListStaff>
                    ),
                    title: "Danh sách nhân viên",
                    method: "EDIT",
                    size: "lg",
                  },
                })
              }
              data-toggle="modal"
              data-target="#modalUID"
            >
              <span className="icon text-white-50">
                <i className="fas fa-plus" />
              </span>
              <span className="text">Thêm nhân viên</span>
            </button>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <SearchInput
                placeHolder={"Tìm kiếm học viên"}
                onSubmitSearch={onSubmitSearch}
                onChange={onChangeSearch}
              ></SearchInput>
            </div>
          </div>
          <div className="card-body">
            {selectStaffs.length > 0 && (
              <button
                style={{ marginBottom: "10px" }}
                className="btn btn-danger btn-icon-split btn-sm show"
                onClick={() =>
                  setState({
                    ...state,
                    modal: {
                      componentAction: (
                        <MultiDelete selectStaffs={selectStaffs}></MultiDelete>
                      ),
                      title: `Xóa nhân viên`,
                      method: "DELETE",
                      action: "MULTI-DELETE",
                    },
                  })
                }
                data-toggle="modal"
                data-target="#modalUID"
              >
                <span className="icon text-white-50">
                  <i className="fas fa-remove" />
                </span>
                <span className="text">Xóa nhân viên</span>
              </button>
            )}

            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  className="table no-footer dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>
                        {" "}
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          checked={_selected}
                          onChange={(e) => changeSelectStaffs(e, null)}
                        />
                      </th>
                      <th
                        className="sorting_disabled sorting_asc"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="ID"
                      >
                        ID
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Họ và tên"
                      >
                        Họ và tên
                      </th>

                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Số điện thoại"
                      >
                        Số điện thoại
                      </th>

                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Số điện thoại"
                      >
                        Email
                      </th>
                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Số điện thoại"
                      >
                        Phòng ban
                      </th>

                      <th
                        className="sorting_disabled"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Hành động"
                      >
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {studies.data?.map((v, i) => {
                      return (
                        <Item
                          checkedStaff={checkedStaff}
                          changeSelectStaffs={changeSelectStaffs}
                          handleDelete={handleDelete}
                          v={v}
                          key={i}
                          index={(currentPage - 1) * perPage + i + 1}
                        ></Item>
                      );
                    })}
                  </tbody>
                </table>
                {studies.meta && studies.meta?.links.length > 0 && (
                  <Pagination
                    to={studies.meta?.to}
                    handleClickItem={handleClickItem}
                    links={studies.meta?.links}
                    lastPage={studies?.meta.last_page}
                    currentPage={studies?.meta.current_page}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalUID
          method={modal.method}
          showFooter={modal.showFooter}
          title={modal.title}
          size={modal.size}
          handleOnSave={() => {
            modal.action == "MULTI-DELETE"
              ? handleDelMultiModal()
              : handleDelModal();
          }}
        >
          {" "}
          {modal.componentAction}{" "}
        </ModalUID>
      </div>
    </>
  );
}
