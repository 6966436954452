/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import SunEditor from "suneditor-react";
import { constants } from "../../../../constants";
import { shiftServices } from "../../../../services/shiftServices";
import { areaServices } from "../../../../services/areaServices";
import { incidentServices } from "../../../../services/incidentServices";
import { appServices } from "../../../../services/appServices";
import { DatePicker, Uploader } from "rsuite";
import AddProductModal from "./AddProductModal";
import { procedureServices } from "../../../../services/procedureServices";
import ChooseShiftModal from "./ChooseShiftModal";
import { formatNumberV2, getTypeOrder } from "../../../../helpers";
import PaymentMethodModal from "./PaymentMethodModal";
import { da } from "date-fns/locale";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

Modal.setAppElement("#root");

const FORMAT_DATE = "dd-MM-yyyy";

const OperateCreateModal = ({
  visible,
  type,
  title,
  closeModal,
  onSuccess,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const userInfo = useSelector((state) => state.user.profile);
  const [listStaffCreate, setListStaffCreate] = useState([]);
  const [listStaffRelate, setListStaffRelate] = useState([]);
  const [listStaffViewer, setListStaffViewer] = useState([]);
  const [areaChoose, setAreaChoose] = useState(null);
  const [areaToChoose, setAreaToChoose] = useState(null);
  const [departmentChoose, setDepartmentChoose] = useState(null);
  const [listDataImage, setListDataImage] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [object, setObject] = useState({});

  const [data, setData] = useState({
    staff_id: null,
    type: null,
    title: null,
    reason: null,
    file_url: null,
    images: [],
    price: null,
    scale: null,
    area_id: null,
    content: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(type);
    setListStaffCreate({
      value: userInfo.id,
      label: `${userInfo.name}`,
    });
    setAreaChoose({
      value: userInfo.area_id ??  areas.data?.filter((area) => area.id == userInfo?.area_id)[0]?.id,
      label: `${
        areas.data?.filter((area) => area.id == userInfo?.area_id)[0]?.name ||
        "Chọn chi nhánh"
      }`,
    });
    setData({
      title: title,
      staff_id: userInfo.id,
      staff_propose_id: userInfo.id,
      staff_handover_id: userInfo.id,
      type: type,
      reason: null,
      file_url: null,
      images: [],
      price: null,
      scale: null,
      area_id: null,
      content: null,
    });
    setListDataImage([]);
    setFileList([]);
    setFile([]);
    setObject({
      list_product: [],
      is_hot: type === constants.TYPE_PROPOSE_REPAIR ? false : null,
      payment_method:
        type === constants.TYPE_PROPOSE_PAYMENT ? "Tiền mặt" : null,
      area: areas.data?.filter((area) => area.id == userInfo?.area_id)[0]?.name,
    });
  }, [type, visible]);

  const customStyles = {
    content: {
      width: "50%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const [modal, setModal] = useState({
    title: "",
    type: null,
    visible: false,
    product: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", visible: false, product: null });
  };

  const fetchStaffsManagerCreate = async (
    search,
    __loadedOptions,
    { page }
  ) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAreas = async (search, __loadedOptions, { page }) => {
    try {
      const res = await areaServices.fetchAreas();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const [fileList, setFileList] = React.useState([]);
  const uploader = React.useRef();

  const [file, setFile] = React.useState([]);
  const uploaderFile = React.useRef();

  async function sendImage(image, name) {
    var imageData = null;
    if (image) {
      imageData = new FormData();
      imageData.append("image", image);
    }
    var resImage = await appServices.uploadImg(imageData);

    listDataImage.push(resImage.data?.image_url);

    setListDataImage(listDataImage);
  }

  async function sendFile(file, name) {
    console.log(file);
    if (file == null) return;
    var imageData = null;
    if (file) {
      imageData = new FormData();
      imageData.append("file", file);
    }
    var resImage = await appServices.uploadDocs(imageData);
    fileUrl.push(resImage.data?.file_url);
    setFileUrl(fileUrl);
  }

  const getTotalMoney = () => {
    var total = 0.0;
    (object.list_product ?? []).forEach((e) => {
      total =
        total +
        (e.quantity || 0) * (e.price || 0).toString().replaceAll(".", "");
    });

    return total;
  };

  const infoProduct = (type) => {
    switch (type) {
      case constants.TYPE_PROPOSE_REPAIR:
        return (
          <div>
            {(object?.list_product || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tên thiết bị:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{e?.quantity ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Mô tả:</div>
                      <div>{e?.note ?? "--:--"}</div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = object?.list_product;
                      list.splice(index, 1);
                      setObject({ ...object, list_product: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );

      case constants.TYPE_PROPOSE_BUY_EQUIPMENT:
        return (
          <div>
            {(object?.list_product || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Mã hàng hoá:</div>
                      <div>{e?.code ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tên hàng hoá:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{e?.quantity ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Đơn giá:</div>
                      <div>{e?.price ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tổng tiền:</div>
                      <div>
                        {formatNumberV2(
                          (e?.price ?? 0).toString().replaceAll(".", "") *
                            (e?.quantity ?? 0)
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Mô tả:</div>
                      <div>{e?.note ?? "--:--"}</div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = object?.list_product;
                      list.splice(index, 1);
                      setObject({ ...object, list_product: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );

      case constants.TYPE_PROPOSE_CANCEL_ORDER:
        return (
          <div>
            {(object?.list_product || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Loại hàng:</div>
                      <div>{getTypeOrder(e?.type ?? 0)}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tên:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{`${e?.quantity ?? "--:--"} ${
                        e?.unit || "KG"
                      }`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Đơn giá:</div>
                      <div>{e?.price}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tổng tiền:</div>
                      <div>
                        {formatNumberV2(
                          (e?.price ?? 0).toString().replaceAll(".", "") *
                            (e?.quantity ?? 0)
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Ngày nhập:</div>
                      <div>
                        {moment(e?.import_date).format(constants.FORMAT_DD_MM_YYYY)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Ngày hết hạn:</div>
                      <div>
                        {moment(e?.expired_date).format(constants.FORMAT_DD_MM_YYYY)}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Lý do:</div>
                      <div>{e?.note ?? "--:--"}</div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = object?.list_product;
                      list.splice(index, 1);
                      setObject({ ...object, list_product: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );

      case constants.TYPE_PROPOSE_TRANSPORTATION_STUFF:
        return (
          <div>
            {(object?.list_product || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Loại hàng:</div>
                      <div>{getTypeOrder(e?.type ?? 0)}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tên:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{`${e?.quantity ?? "--:--"} ${
                        e?.unit || "KG"
                      }`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Ghi chú:</div>
                      <div>{e?.note ?? "--:--"}</div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = object?.list_product;
                      list.splice(index, 1);
                      setObject({ ...object, list_product: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );

      case constants.TYPE_PROPOSE_PAYMENT:
        return (
          <div>
            {(object?.list_product || []).map((e, index) => {
              return (
                <div className="d-flex align-item-center justify-content-between mt-3">
                  <div className="item-product col-11">
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tên:</div>
                      <div>{e?.name ?? "--:--"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Số lượng:</div>
                      <div>{`${e?.quantity ?? "--:--"} ${
                        e?.unit || "KG"
                      }`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Đơn giá:</div>
                      <div>{e?.price}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Tổng tiền:</div>
                      <div>
                        {formatNumberV2(
                          (e?.price ?? 0).toString().replaceAll(".", "") *
                            (e?.quantity ?? 0)
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col-2 text-product">Lý do:</div>
                      <div>{e?.note ?? "--:--"}</div>
                    </div>
                  </div>
                  <button
                    style={{
                      border: "1px solid",
                      height: "30px",
                    }}
                    class="btn btn-danger btn-sm"
                    type="button"
                    onClick={() => {
                      var list = object?.list_product;
                      list.splice(index, 1);
                      setObject({ ...object, list_product: list });
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    xóa
                  </button>
                </div>
              );
            })}
          </div>
        );
    }
  };

  const infoForm = (type) => {
    switch (type) {
      case constants.TYPE_PROPOSE_REPAIR:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-1">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="mb-2 mr-5 ml-1 mt-3">
                <input
                  className="mr-2"
                  type="radio"
                  checked={object?.is_hot === true}
                  onChange={(e) => {
                    setObject({ ...object, is_hot: true });
                  }}
                />
                <span>Gấp</span>
              </div>
              <div className="mb-2">
                <input
                  className="mr-2 mt-3"
                  type="radio"
                  checked={object?.is_hot === false}
                  onChange={(e) => {
                    setObject({ ...object, is_hot: false });
                  }}
                />
                <span>Bình thường</span>
              </div>
            </div>
            <div className="d-flex mt-2 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin thiết bị
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm thiết bị`,
                    method: "ADD_PRODUCT",
                    type: type,
                    visible: true,
                    product: null,
                  });
                }}
                class="btn btn-info"
              >
                Thêm thiết bị +
              </button>
            </div>
            {infoProduct(type)}
          </div>
        );
      case constants.TYPE_PROPOSE_HR_SUPPORT:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Vị trí cần hỗ trợ
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={departmentChoose}
                    loadOptions={fetchAllDepartments}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setDepartmentChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ngày cần hỗ trợ:
              </div>
              <div className="col-5">
                <DatePicker
                  value={object?.date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setObject({ ...object, date: e });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Ca cần hỗ trợ:
              </div>
              <div className="col-5">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.shifts?.name ?? "Chọn ca"}
                  onClick={(e) => {
                    setModal({
                      title: `Chọn ca`,
                      method: "ADD_SHIFT",
                      visible: true,
                      product: object.shifts,
                    });
                  }}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Phụ cấp:
              </div>
              <div className="col-5">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.price ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      price: formatNumberV2(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
            {infoProduct(type)}
          </div>
        );
      case constants.TYPE_PROPOSE_BUY_EQUIPMENT:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-1">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-2 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin thiết bị
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm thiết bị`,
                    method: "ADD_PRODUCT",
                    visible: true,
                    type: type,
                    product: null,
                  });
                }}
                class="btn btn-info"
              >
                Thêm thiết bị +
              </button>
            </div>
            {infoProduct(type)}
            <div className="row mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng tiền:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatNumberV2(getTotalMoney())}
                  readOnly
                />
              </div>
            </div>
          </div>
        );
      case constants.TYPE_PROPOSE_CANCEL_ORDER:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-1">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-2 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin mặt hàng
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm mặt hàng`,
                    method: "ADD_PRODUCT",
                    visible: true,
                    type: type,
                    product: null,
                  });
                }}
                class="btn btn-info"
              >
                Thêm mặt hàng +
              </button>
            </div>
            {infoProduct(type)}
            <div className="row mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng tiền:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatNumberV2(getTotalMoney())}
                  readOnly
                />
              </div>
            </div>
          </div>
        );
      case constants.TYPE_PROPOSE_TRANSPORTATION_STUFF:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Chi nhánh nhận hàng
              </div>
              <div className="col-9">
                <div style={{ width: "100%" }}>
                  <AsyncPaginate
                    value={areaToChoose}
                    loadOptions={fetchAreas}
                    isMulti={false}
                    closeMenuOnSelect={false}
                    onChange={setAreaToChoose}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 99999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    debounceTimeout={500}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Lý do:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.note ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-2 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin mặt hàng
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm mặt hàng`,
                    method: "ADD_PRODUCT",
                    visible: true,
                    type: type,
                    product: null,
                  });
                }}
                class="btn btn-info"
              >
                Thêm mặt hàng +
              </button>
            </div>
            {infoProduct(type)}
          </div>
        );
      case constants.TYPE_PROPOSE_PAYMENT:
        return (
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tiêu đề thanh toán:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object?.title ?? ""}
                  onChange={(e) => {
                    setObject({
                      ...object,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-2 align-items-center justify-content-between">
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                Thông tin thanh toán
              </div>
              <button
                style={{ backgroundColor: "#1cc88a" }}
                type="button"
                onClick={() => {
                  setModal({
                    title: `Thêm mặt hàng`,
                    method: "ADD_PRODUCT",
                    visible: true,
                    type: type,
                    product: null,
                  });
                }}
                class="btn btn-info"
              >
                Thêm thông tin +
              </button>
            </div>
            {infoProduct(type)}
            <div className="row mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Tổng tiền:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={formatNumberV2(getTotalMoney())}
                  readOnly
                />
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Phương thức thanh toán:
              </div>
              <div className="col-9">
                <input
                  name="support"
                  type="text"
                  className="form-control"
                  placeholder=""
                  autoComplete="off"
                  value={object.payment_method}
                  onClick={(e) => {
                    setModal({
                      title: `Chọn phương thức thanh toán`,
                      method: "PAYMENT_METHOD",
                      visible: true,
                      product: object.payment_method,
                    });
                  }}
                  readOnly
                />
              </div>
            </div>
            {object.payment_method === "Chuyển khoản" ? (
              <div>
                <div className="row mt-3">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Tên người thụ hưởng:
                  </div>
                  <div className="col-9">
                    <input
                      name="support"
                      type="text"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      value={object.name_account_bank}
                      onChange={(e) => {
                        setObject({
                          ...object,
                          name_account_bank: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Tên ngân hàng:
                  </div>
                  <div className="col-9">
                    <input
                      name="support"
                      type="text"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      value={object.name_bank}
                      onChange={(e) => {
                        setObject({
                          ...object,
                          name_bank: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Số tài khoản:
                  </div>
                  <div className="col-9">
                    <input
                      name="support"
                      type="text"
                      className="form-control"
                      placeholder=""
                      autoComplete="off"
                      value={object.bank_number}
                      onChange={(e) => {
                        setObject({
                          ...object,
                          bank_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
    }
  };

  const createOperateForm = async () => {
    var check = validatorForm(type);

    if (check == false) {
      return;
    }

    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });

    await Promise.all([
      ...fileList.map((e) => {
        const file = e.blobFile;
        return sendImage(file);
      }),
      ...file.map((e) => {
        const up = e.blobFile;
        return sendFile(up);
      }),
    ]).catch((error) => {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      console.error("Có lỗi xảy ra khi chạy các hàm gọi API", error);
    });

    console.log(listStaffCreate);

    setData({ ...data, content: JSON.stringify(object) });

    var content = {
      list_product: (object.list_product ?? []).map((e) => {
        return {
          code: e?.code,
          name: e?.name,
          quantity: e?.quantity,
          price: (e?.price || "").toString().replaceAll(".", ""),
          import_date: moment(e?.date).format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS),
          expired_date: moment(e?.expired).format(
            constants.FORMAT_YYYY_MM_DD_HH_MM_SS
          ),
          note: e?.note,
          type: e?.type,
          unit: e?.unit || "KG",
        };
      }),
      is_hot: object.is_hot,
      area: areaChoose?.label || "",
      area_to: areaToChoose?.label || "",
      department: departmentChoose?.label || "",
      price: (object.price || "").toString().replaceAll(".", ""),
      shifts: object.shifts?.name,
      reason: object.reason,
      title: object.title,
      payment_method: object.payment_method,
      name_account_bank: object.name_account_bank,
      name_bank: object.name_bank,
      bank_number: object.bank_number,
      date: moment(object?.date).format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS),
      total: (object.list_product ?? []).length !== 0 ? getTotalMoney() : null,
    };

    var body = {
      title: data.title,
      staff_id: listStaffCreate.value,
      staff_propose_id: listStaffCreate.value,
      staff_handover_id: listStaffCreate.value,
      area_id: areaChoose?.value || "",
      area_to_id: areaToChoose?.value || "",
      type: data.type,
      reason: null,
      file_url: fileUrl,
      images: listDataImage,
      price: null,
      scale: null,
      products: (object.list_product ?? []).map((e) => {
        return {
          type: e?.type,
          name: e?.name,
          size: e?.size,
          code: e?.code,
          quantity: e?.quantity,
          unit: e?.unit || "KG",
          price: (e?.price || "").toString().replaceAll(".", ""),
          import_date: moment(e?.date).format(
            constants.FORMAT_YYYY_MM_DD_HH_MM_SS
          ),
          expired_date: moment(e?.expired).format(
            constants.FORMAT_YYYY_MM_DD_HH_MM_SS
          ),
          note: e?.note,
        };
      }),
      content: JSON.stringify(content),
    };

    try {
      const res = await procedureServices.createOperateForm({
        data: body,
      });

      if (res?.success === true) {
        onSuccess();
        toast.success("Thành công");
      } else {
        toast.error(res?.msg || "");
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  const validatorForm = (type) => {
    var content = {
      list_product: object.list_product,
      is_hot: object.is_hot,
      area: areaChoose?.label || "",
      area_to: areaToChoose?.label || "",
      department: departmentChoose?.label || "",
      price: (object.price || "").toString().replaceAll(".", ""),
      shifts: object.shifts?.name,
      title: object.title,
      payment_method: object.payment_method,
      name_account_bank: object.name_account_bank,
      name_bank: object.name_bank,
      bank_number: object.bank_number,
      date:
        object.date == null
          ? null
          : moment(object?.date).format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS),
    };
    console.log(content?.date);
    if (type === constants.TYPE_PROPOSE_REPAIR) {
      if ((content.list_product || []).length === 0) {
        toast.error("Chưa chọn thiết bị");
        return false;
      }
      if (content.is_hot === null) {
        toast.error("Chưa chọn mức độ sửa chữa");
        return false;
      }
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
    }

    if (type === constants.TYPE_PROPOSE_HR_SUPPORT) {
      if ((departmentChoose?.label || "") == "") {
        toast.error("Chưa chọn vị trí cần hỗ trợ");
        return false;
      }
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh cần hỗ trợ");
        return false;
      }
      if ((content.date || "") === "") {
        toast.error("Chưa chọn ngày cần hỗ trợ");
        return false;
      }
      if ((content.shifts || "") === "") {
        toast.error("Chưa chọn ca cần hỗ trợ");
        return false;
      }
      if ((content.price || "") === "") {
        toast.error("Chưa nhập phụ cấp hỗ trợ");
        return false;
      }
    }

    if (type === constants.TYPE_PROPOSE_BUY_EQUIPMENT) {
      if ((content.list_product || []).length === 0) {
        toast.error("Chưa chọn thiết bị");
        return false;
      }
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
    }

    if (type === constants.TYPE_PROPOSE_CANCEL_ORDER) {
      if ((content.list_product || []).length === 0) {
        toast.error("Chưa chọn hàng hoá");
        return false;
      }
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
    }

    if (type === constants.TYPE_PROPOSE_TRANSPORTATION_STUFF) {
      if ((content.list_product || []).length === 0) {
        toast.error("Chưa chọn hàng hoá");
        return false;
      }
      if ((content.area || "") === "") {
        toast.error("Chưa chọn chi nhánh");
        return false;
      }
      if ((content.area_to || "") === "") {
        toast.error("Chưa chọn chi nhánh nhận hàng");
        return false;
      }
    }

    if (type === constants.TYPE_PROPOSE_PAYMENT) {
      if ((content.title || "") === "") {
        toast.error("Chưa nhập tiêu đề");
        return false;
      }
      if ((content.list_product || []).length === 0) {
        toast.error("Chưa chọn hàng hoá");
        return false;
      }

      if (content.payment_method === "Chuyển khoản") {
        if ((content.name_account_bank || "") === "") {
          toast.error("Chưa nhập tên người thụ hưởng");
          return false;
        }
        if ((content.name_bank || "") === "") {
          toast.error("Chưa nhập tên ngân hàng");
          return false;
        }
        if ((content.bank_number || "") === "") {
          toast.error("Chưa nhập số tài khoản");
          return false;
        }
      }
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>{title}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="ml-4">
          <div className="ml-1" style={{ width: "95%" }}>
            <div className="row mb-1">
              <div
                className="col-3"
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                Người lập phiếu
              </div>
              <div className="col-9">
                <AsyncPaginate
                  value={listStaffCreate}
                  loadOptions={fetchStaffsManagerCreate}
                  isMulti={false}
                  closeMenuOnSelect={false}
                  onChange={setListStaffCreate}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 99999,
                    }),
                  }}
                  menuPortalTarget={document.body}
                  debounceTimeout={500}
                  isClearable
                  isSearchable
                />
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          {infoForm(type)}
        </div>

        <Uploader
          multiple
          headers={{ token: tokenInfo, "Content-Type": "multipart/form-data" }}
          listType="picture"
          fileList={fileList}
          autoUpload={false}
          onChange={setFileList}
          onSuccess={(e) => {}}
          ref={uploader}
          action={`${constants.API_URL}/upload_v2/files?type=operate`}
          accept=".png,.jpeg,.jpg,.webp"
        >
          <button
            style={{ height: "66px", width: "66px", justifyContent: "center" }}
          >
            <div>
              <CameraRetroIcon />
              <div style={{ fontSize: "10px" }}>Chọn ảnh</div>
            </div>
          </button>
        </Uploader>
        <div style={{ height: "10px" }}></div>
        <Uploader
          multiple={true}
          headers={{ token: tokenInfo, "Content-Type": "multipart/form-data" }}
          fileList={file}
          autoUpload={false}
          onChange={setFile}
          onSuccess={(e) => {}}
          ref={uploaderFile}
          action={`${constants.API_URL}/upload_v2/files?type=doc`}
          locale={{
            uploadFile: "Upload file",
            upload: "File đính kèm",
            uploading: "Uploading...",
            removeFile: "Remove file",
            retry: "Retry",
            cancel: "Cancel",
            drag: "Drag file to here",
            choose: "Choose file",
            invalidType: "Invalid file type",
            invalidSize: "File size too large",
            uploadFailed: "Upload failed",
            retryUpload: "Retry upload",
            uploadSuccess: "Upload success",
          }}
        />
        <div
          className="my-4"
          style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
        ></div>

        <div className="my-4"></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            createOperateForm();
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
      <AddProductModal
        visible={modal.visible && modal.method === "ADD_PRODUCT"}
        productInput={modal.product}
        title={modal.title}
        type={modal?.type}
        onSave={(e) => {
          var list = object.list_product;
          list.push(e);
          setObject({ ...object, list_product: list });
        }}
        closeModal={handleCloseModal}
      ></AddProductModal>
      <ChooseShiftModal
        visible={modal.visible && modal.method === "ADD_SHIFT"}
        shiftsInput={modal.shifts}
        chooseOne={true}
        onSave={(shifts) => {
          console.log(shifts);
          setObject({ ...object, shifts: shifts[0] });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></ChooseShiftModal>
      <PaymentMethodModal
        visible={modal.visible && modal.method === "PAYMENT_METHOD"}
        onChoose={(paymentMethod) => {
          setObject({ ...object, payment_method: paymentMethod });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></PaymentMethodModal>
    </Modal>
  );
};

export default OperateCreateModal;
