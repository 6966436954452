import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { handleDataImport } from "../../../../helpers/sheet_staff";
import { useSelector } from "react-redux";
import { shiftServices } from "../../../../services/shiftServices";

export default function   ImportExcelModal({
  open,
  closeModal,
  onSuccess,
  data,
  header,
  roles,
}) {
  const [arrHeader, setArrHeader] = useState([]);

  const areas = useSelector((state) => state.area.areas);
  const departmentList = useSelector((state) => state.staff.department_list);

  function changeOrderHeader(value) {
    var newValues = arrHeader.filter((v) => v.index !== value.index);
    if (value.value == "")
      var newValues = newValues.filter((v) => v.index !== value.index);
    else newValues = [...newValues, value];
    setArrHeader(newValues);
  }

  function showHeaderData(fileData, header) {
    var result = [];
    if (typeof fileData == "undefined") {
      return result;
    }
    var data = fileData[0];

    console.log(data);

    if (data === undefined) {
      return result;
    }
    Object.entries(data).forEach(([key, value], index) => {
      result.push(
        <th>
          <select
            style={{ width: "200px" }}
            onChange={(e) =>
              changeOrderHeader({ index, value: e.target.value })
            }
            name=""
            id="input"
            class="form-control"
            required="required"
          >
            <option value={""}>---</option>
            {header.map((v, i) => {
              if (v.title === key) {
                return (
                  <option value={v.value} selected>
                    {v.title}
                  </option>
                );
              }
              return <option value={v.value}>{v.title}</option>;
            })}
          </select>
        </th>
      );
    });

    return result;
  }

  function showValue(v) {
    var result = [];
    for (const [key, value] of Object.entries(v)) {
      result.push(<td>{value}</td>);
    }
    return result;
  }

  function showData(data) {
    var result = [];
    
    if (data === undefined || data.seniority_from === "") {
      return result;
    }


    return data.map((v, i) => {
      return <tr>{showValue(v, v[0])}</tr>;
    });
  }

  function handleOnSave() {
    var multiCreate = [];
    // var obj = data[0];
    for (const [index, element] of data.entries()) {
      var item = {};
      var i = 0;
      // eslint-disable-next-line no-loop-func
      Object.entries(element).forEach(([_key, _value], _index) => {
        let objectItem = "";
        for (let index = 0; index < header.length; index++) {
          if (header[index].title == [_key]) {
            objectItem = header[index].value;
          }
        }
        if (objectItem && objectItem !== "")
          item = { ...item, [objectItem]: _value };
        // console.log(item.value);
      });

      multiCreate.push({ ...item });
    }
    const list_staffs = multiCreate.map((v, index) => {
      return handleDataImport(v, index, areas.data, departmentList.data, roles);
    });
    onSuccess(list_staffs);
  }

  return (
    <Modal
      title={"Import Nhân viên"}
      centered
      open={open}
      width={"90%"}
      style={{ top: 20 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        handleOnSave();
      }}
    >
      <div
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "70vh",
          margin: "10px",
        }}
      >
        <table
          class="table table-responsive table-border"
          style={{ fontSize: "13px" }}
        >
          {data && (
            <>
              <thead>{showHeaderData(data, header)}</thead>
              <tbody>{showData(data)}</tbody>
            </>
          )}
        </table>
      </div>
    </Modal>
  );
}
