/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Form, Input, Select, Upload, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";
import { franchiseServices } from "../../../../services/franchiseServices";
import SunEditor from "suneditor-react";
import { set } from "lodash";
import { AsyncPaginate } from "react-select-async-paginate";

const AddMailModal = ({ open, closeModal, onOk, dataInput }) => {
  const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
  const [contentText, setContentText] = useState(null);
  const [mailData, setMailData] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        getMailDetail(dataInput.id);
        getCustomerFranchise();
      }
    } else {
      onReset();
    }
  }, [open]);

  const addMail = async (data) => {
    setLoading(true);
    try {
      const response = await franchiseServices.addMail(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateMail = async (data, id) => {
    try {
      const response = await franchiseServices.updateMail(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const onFinish = (values) => {
    console.log(values);
    values.file_urls = fileUrl;
    values.content = contentText;
    values.staff_to_ids = customers.map((item) => item.value);
    values.upload = null;
    if (dataInput != null) {
      updateMail(values, dataInput.id);
    } else {
      addMail(values);
    }
  };

  const onFill = () => {
    setContentText(mailData?.content);
    form.setFieldsValue({
      subject: dataInput?.subject ?? "",
      staff_to_ids: dataInput?.staff_to_ids ?? "",
      file_urls: dataInput?.file_urls ?? "",
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  const { Option } = Select;

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error("File phải nhỏ hơn 20MB!");
      return false;
    }
    return isLt5M;
  };

  const [fileList, setFileList] = useState([]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    console.log(info.file.status);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
      });
      setFileList([...fileList, info.file]);
    }
  };

  const getMailDetail = async (id) => {
    setReset(true);
    try {
      const response = await franchiseServices.getMailDetail(id);
      setMailData(response.data);
      onFill();
    } catch (error) {
      console.log(error);
    }
    setReset(false);
  };

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa thông tin" : "Gửi thông tin"}
      centered
      open={open}
      width={800}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="mt-4">
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Row align="middle" className="mb-4">
            <Col flex="5">
              <div className="d-flex justify-content-between">
                <div></div>
                <p className="mr-2">Người nhận thông tin:</p>
              </div>
            </Col>
            <Col flex="19">
              <AsyncPaginate
                value={customers}
                loadOptions={getCustomerFranchise}
                isMulti
                closeMenuOnSelect={false}
                onChange={setCustomers}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            </Col>
          </Row>
          <Form.Item
            name="subject"
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tiêu đề"} />
          </Form.Item>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[
              {
                required:
                  (contentText ?? "<p><br></p>") != "<p><br></p>" &&
                  (contentText ?? "") != ""
                    ? false
                    : true,
                message:
                  (contentText ?? "<p><br></p>") != "" &&
                  (contentText ?? "") != ""
                    ? "Không được để trống !"
                    : "Không được để trống !",
              },
            ]}
          >
            <div className="editor">
              {reset == false ? (
                <SunEditor
                  name="content"
                  showToolbar={true}
                  setDefaultStyle="height: auto"
                  defaultValue={contentText}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "fontColor",
                        "textStyle",
                        "outdent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "preview",
                      ],
                    ],
                  }}
                  onChange={(e) => {
                    console.log(e);
                    setContentText(e);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </Form.Item>
          {dataInput?.id != null ? (
            <></>
          ) : (
            <Form.Item
              name="upload"
              label="File đính kèm"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: false,
                  message: "Chưa chọn file Upload!",
                  type: "array",
                },
              ]}
            >
              <Upload
                name="upload"
                label="Upload"
                maxCount={1}
                valuePropName="fileList"
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
                customRequest={({ file, onSuccess, onError }) => {
                  const formData = new FormData();
                  formData.append("image", file);
                  fetch("https://crm-api.studyguide.dev/api/upload_v2/files?type=franchise", {
                    method: "POST",
                    headers: {
                      token: tokenInfo,
                    },
                    body: formData,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      // console.log(data);
                      // setState({ ...state, image: data?.data?.image_url });
                      // props.onChangeFormCreate({
                      //   ...state,
                      //   image: data?.data?.image_url,
                      // });
                      setFileUrl(data?.data?.image_url);
                      onSuccess();
                    })
                    .catch((error) => {
                      onError();
                    });
                }}
              >
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>
            </Form.Item>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddMailModal;
