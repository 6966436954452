export default function ListPinIcon({ className, ...rest }) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...rest} 
    >
      <path
        d="M11.6225 2.19016L7.86575 2.11953C7.69499 2.11632 7.56467 2.25101 7.56167 2.43381L7.53768 3.8962C7.53393 4.1247 7.70319 4.21931 7.83126 4.22172L8.13009 4.22734L8.1076 5.59833C7.71889 5.86531 6.68159 6.62294 6.66509 7.62834C6.6621 7.81114 6.78792 7.95065 6.95868 7.95386L9.392 7.99961L9.34476 10.8787C9.34176 11.0615 9.46758 11.201 9.63834 11.2042C9.8091 11.2074 9.94017 11.027 9.94242 10.8899L9.98966 8.01085L12.423 8.05659C12.6581 8.03816 12.7465 7.85697 12.7271 7.74231C12.7435 6.73692 11.7317 5.94076 11.352 5.65933L11.3745 4.28834L11.6734 4.29396C11.8441 4.29717 11.9744 4.16247 11.9774 3.97967L12.0014 2.51728C11.919 2.33287 11.7932 2.19337 11.6225 2.19016ZM11.2996 3.64693L11.0008 3.64132C10.83 3.63811 10.6997 3.7728 10.6967 3.9556L10.687 5.85254C10.6847 5.98964 10.7259 6.08184 10.8315 6.1524C11.1273 6.34082 11.8021 6.85636 12.0058 7.45447L7.30994 7.36618C7.51142 6.79855 8.22442 6.28624 8.52625 6.10906C8.61238 6.06496 8.67829 5.95192 8.68054 5.81482L8.71128 3.94112C8.71578 3.66693 8.54577 3.61801 8.4177 3.61561L8.11887 3.60999L8.15408 2.76494L11.3131 2.82434L11.2996 3.64693Z"
        fill="#0068FF"
      />
      <rect y="2.89697" width="7" height="1.5" rx="0.75" fill="#0068FF" />
      <rect y="5.89697" width="6" height="1.5" rx="0.75" fill="#0068FF" />
      <rect y="8.89697" width="9" height="1.5" rx="0.75" fill="#0068FF" />
    </svg>
  );
}
