import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { constants } from "../../../../constants";
import { formatNumberV2, getTypeOrder } from "../../../../helpers";
import TypeProductModal from "./TypeProductModal";
import { DatePicker } from "rsuite";
import TypeUnitModal from "./TypeUnitModal";

Modal.setAppElement("#root");
const FORMAT_DATE = "dd-MM-yyyy";
const AddProductModal = ({
  visible,
  type,
  productInput,
  closeModal,
  onSave,
}) => {
  const [product, setProduct] = useState({
    name: "",
    quantity: null,
    unit: "KG",
    note: "",
  });

  useEffect(() => {
    setProduct(productInput);
    console.log(type);
    if (type === constants.TYPE_PROPOSE_CANCEL_ORDER) {
      setProduct({ ...product, unit: "KG" });
    }
    if (type === constants.TYPE_PROPOSE_PAYMENT) {
      setProduct({ ...product, unit: "KG" });
    }
  }, [productInput]);

  const validatorProduct = () => {
    if (product?.name == "") {
      toast.error("Chưa nhập tên");
      return;
    }

    if (product?.quantity == "") {
      toast.error("Chưa nhập số lượng");
      return;
    }
    onSave(product);
    setProduct(null);
    closeModal();
  };

  const customStyles = {
    content: {
      width: "40%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const [modal, setModal] = useState({
    method: "",
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, visible: false });
  };

  const infoForm = (type) => {
    switch (type) {
      case constants.TYPE_PROPOSE_REPAIR:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Tên thiết bị</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Mô tả</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, note: e.target.value });
                    }}
                    value={product?.note}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_BUY_EQUIPMENT:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Mã hàng hoá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, code: e.target.value });
                    }}
                    value={product?.code}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Tên hàng hoá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Đơn giá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        price: formatNumberV2(e.target.value),
                      });
                    }}
                    value={product?.price}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Mô tả</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, note: e.target.value });
                    }}
                    value={product?.note}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_CANCEL_ORDER:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Loại hàng hoá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onClick={(e) => {
                      setModal({
                        method: "TYPE_PRODUCT",
                        visible: true,
                      });
                    }}
                    value={getTypeOrder(product?.type ?? 0)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Tên</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                  <div
                    className="d-flex ml-2 mr-2 align-item-center"
                    style={{
                      alignItems: "center",
                      color: "blue",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setModal({
                        method: "TYPE_UNIT",
                        visible: true,
                      });
                    }}
                  >
                    {product?.unit ?? "KG"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Đơn giá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        price: formatNumberV2(e.target.value),
                      });
                    }}
                    value={product?.price}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div style={{ flex: 1 }}>Ngày nhập:</div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  value={product?.import_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setProduct({ ...product, import_date: e });
                  }}
                />
              </div>
            </div>
            <div className="d-flex mb-3">
              <div style={{ flex: 1 }}>Ngày hết hạn:</div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  value={product?.expired_date}
                  format={FORMAT_DATE}
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(e) => {
                    setProduct({ ...product, expired_date: e });
                  }}
                />
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Lý do</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, note: e.target.value });
                    }}
                    value={product?.note}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_TRANSPORTATION_STUFF:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Loại hàng hoá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onClick={(e) => {
                      setModal({
                        method: "TYPE_PRODUCT",
                        visible: true,
                      });
                    }}
                    value={getTypeOrder(product?.type ?? 0)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Tên</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                  <div
                    className="d-flex ml-2 mr-2 align-item-center"
                    style={{
                      alignItems: "center",
                      color: "blue",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setModal({
                        method: "TYPE_UNIT",
                        visible: true,
                      });
                    }}
                  >
                    {product?.unit ?? "KG"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Ghi chú</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, note: e.target.value });
                    }}
                    value={product?.note}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case constants.TYPE_PROPOSE_PAYMENT:
        return (
          <div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Tên</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, name: e.target.value });
                    }}
                    value={product?.name}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Số lượng</div>
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, quantity: e.target.value });
                    }}
                    value={product?.quantity}
                  />
                  <div
                    className="d-flex ml-2 mr-2 align-item-center"
                    style={{
                      alignItems: "center",
                      color: "blue",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setModal({
                        method: "TYPE_UNIT",
                        visible: true,
                      });
                    }}
                  >
                    {product?.unit ?? "KG"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Đơn giá</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        price: formatNumberV2(e.target.value),
                      });
                    }}
                    value={product?.price}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-flex mb-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>Lý do</div>
                <div style={{ width: "80%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => {
                      setProduct({ ...product, note: e.target.value });
                    }}
                    value={product?.note}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="ant-modal-content">
        <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
          <h4 style={{ color: "white" }}>Thêm thiết bị</h4>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
          {infoForm(type)}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            validatorProduct();
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
      <TypeProductModal
        visible={modal.visible && modal.method === "TYPE_PRODUCT"}
        onChoose={(e) => {
          setProduct({ ...product, type: e });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></TypeProductModal>
      <TypeUnitModal
        visible={modal.visible && modal.method === "TYPE_UNIT"}
        onChoose={(e) => {
          setProduct({ ...product, unit: e });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></TypeUnitModal>
    </Modal>
  );
};

export default AddProductModal;
