import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../../actions/chatADActions";
import MainContent from "./MainContent";
import SidebarLeft from "./SidebarLeft/index.jsx";
import { StyledBoxChat, StyledButton } from "./index.style";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

export default function ChatHome({profileInput}) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  const [isShowBox, setShowBox] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    dispatch(a.saveUserId(profileInput?.id));
    const queryString = `?user_id=${profileInput?.id}&page=1`
    dispatch(a.getRoomChatListByUserId(queryString));
  }, []);

  const onToggleScreen = (value) => {
    dispatch(a.toggleFullscreen(value));
    setFullScreen(value);
  };

  if (!profile?.id) return null;

  return (
    <div>

        <StyledBoxChat>
          <div
            id="box-chat-container"
            className={isFullScreen ? "fullscreen" : ""}
          >
            <SidebarLeft />
            <MainContent profileInput={profileInput}/>
            <div
              style={{
                width: "100%",
                background: "#eaedf0",
                height: "20px",
                position: "absolute",
                top: "-20px",
                boxShadow: "0 1px 2px #00000033",
              }}
            >
              {/* <HighlightOffIcon
                className="btn-close"
                onClick={() => setShowBox(false)}
              /> */}
              {!isFullScreen ? (
                <ZoomOutMapIcon
                  className="btn-zoom"
                  onClick={() => onToggleScreen(true)}
                />
              ) : (
                <ZoomInMapIcon
                  className="btn-zoom"
                  onClick={() => onToggleScreen(false)}
                />
              )}
            </div>
          </div>
        </StyledBoxChat>
      
    </div>
  );
}
