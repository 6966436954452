import React, { useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { Space, Table, Button, Popconfirm, Input } from "antd";
import { franchiseServices } from "../../../services/franchiseServices";
import AddMailModal from "./child/AddMailModal";
import MailModal from "./child/MailModal";
import moment from "moment";
import PermissionChecker from "../../../components/PermissionChecker";
import { useSelector } from "react-redux";
import { FilterFilled } from "@ant-design/icons";
import { AsyncPaginate } from "react-select-async-paginate";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const { Search } = Input;

export default function FranchiseMailPage() {
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });
  const [mailList, setMailList] = useState(null);
  const [loading, setLoading] = useState(null);

  const [filter, setFilter] = useState({
    customer_id: null,
    category_id: null,
  });

  const location = useLocation();

  // Lấy tham số truy vấn từ URL
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('customer_id');

  const [customers, setCustomers] = useState(null);

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  useEffect(() => {
    if (id) {
      setFilter({
        ...filter,
        customer_id: id,
      });
    }
  }, []);

  const getMail = async (page, search, staff_to_id) => {
    setLoading(true);
    try {
      const response = await franchiseServices.getMail(
        `?search=${search ?? ""}&page=${page ?? 1}&staff_to_id=${
          staff_to_id ?? ""
        }`
      );
      setMailList(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const firstLoad = useRef(true);

  const deleteMail = async (id) => {
    try {
      const response = await franchiseServices.deleteMail(id);
      getMail(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filter?.customer_id != null) {
      getMail(1, '', filter?.customer_id);
    } else {
      if (id == null && firstLoad.current) {
        firstLoad.current = false;
        getMail(1);
      }
    }
  }, [filter]);


  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  const columns = [
    {
      title: "tiêu đề",
      dataIndex: "subject",
      key: "subject",
      render: (_, record) => (
        <a style={{ fontWeight: record.status === 0 ? "bold" : "normal" }}>
          {record?.subject ?? "--:--"}
        </a>
      ),
    },
    {
      title: "Người gửi",
      dataIndex: "staff",
      key: "staff",
      render: (_, record) => <a>{record.staff?.name ?? "--:--"}</a>,
    },
    {
      title: "Gửi tới",
      dataIndex: "customers",
      key: "customers",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <AsyncPaginate
            value={customers}
            placeholder="Tìm kiếm khách hàng"
            loadOptions={getCustomerFranchise}
            isMulti={false}
            closeMenuOnSelect={false}
            onChange={(v) => {
              setFilter({
                ...filter,
                customer_id: v?.value ?? null,
              });
              setCustomers(v);
              if ((v?.value ?? null) === null) {
                getMail(1);
              }
            }}
            additional={{
              page: 1,
            }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 99999,
              }),
              width: "auto",
            }}
            menuPortalTarget={document.body}
            debounceTimeout={500}
            isClearable
            isSearchable
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filter?.customer_id !== null ? "#1677ff" : undefined,
          }}
        />
      ),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Thời gian",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <a> {moment(text).format("DD-MM HH:mm") ?? "--:--"}</a>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_mail_edit ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: {
                      id: record.key,
                      subject: record.subject,
                      staffs_to: record.staffs_to,
                      staff_to_ids: record.staff_to_ids,
                      staff: record.staff,
                      file_urls: record.file_urls,
                    },
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_mail_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá thông tin ?"
                description="Bạn có chắc chắn muốn xoá thông tin này chứ ?"
                onConfirm={() => {
                  deleteMail(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mt-2">
        {/* <h4 className="h4 title_content mb-0 text-gray-800">Câu hỏi hỗ trợ</h4> */}
        <Search
          placeholder="Tìm kiếm thông tin"
          onSearch={(v) => {
            getMail(1, v);
          }}
          enterButton
          loading={loading}
          style={{
            width: 300,
          }}
        />
        {PermissionChecker({
          decent: reportNumber.permissions?.franchise_mail_add ?? false,
          child: (
            <Button
              type="primary"
              shape="round"
              size={20}
              icon={<MdAdd />}
              onClick={(e) => {
                setModal({
                  method: "ADD",
                  visible: true,
                  data: null,
                });
              }}
            >
              Gửi thông tin
            </Button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        onRow={(record, index) => {
          return {
            style: {
              background: record.status === 0 ? "#f6f6f6" : "default",
            },
            onClick: (e) => {
              setModal({
                method: "WATCH",
                visible: true,
                data: {
                  id: record.key,
                  subject: record.subject,
                  staffs_to: record.staffs_to,
                  staff_to_ids: record.staff_to_ids,
                  staff: record.staff,
                  file_urls: record.file_urls,
                },
              });
            },
          };
        }}
        dataSource={(mailList?.data ?? []).map((e) => {
          return {
            key: `${e.id}`,
            subject: e.subject ?? "--:--",
            staff: e?.staff ?? "--:--",
            status: e?.status ?? 0,
            customers: e?.staffs_to?.map((e) => e.name).join(", ") ?? "--:--",
            staffs_to: e?.staffs_to?.map((e) => e.id),
            staff_to_ids: e?.staff_to_ids ?? [],
            file_urls: e?.file_urls ?? [],
            updated_at: e.updated_at ?? "--:--",
          };
        })}
        pagination={{
          total: mailList?.total ?? 0,
          pageSize: 20,
        }}
        onChange={(e) => {
          getMail(e.current);
        }}
      />
      <AddMailModal
        open={modal.visible && modal.method === "ADD"}
        dataInput={modal.data}
        onOk={() => {
          getMail(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
      <MailModal
        open={modal.visible && modal.method === "WATCH"}
        dataInput={modal.data}
        onOk={() => {
          getMail(1);
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
}
