/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import "./../style.css";
import moment from "moment";
import ChooseShiftModal from "../../../Procedure/OperateFormPage/child/ChooseShiftModal";
import { shiftServices } from "../../../../services/shiftServices";
import { constants } from "../../../../constants";
import { CiZoomIn, CiCirclePlus, CiCircleMore } from "react-icons/ci";
import HistoryCheckInOutModal from "./HistoryCheckInOutModal";
import { toast } from "react-toastify";
Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const ShiftModal = ({ visible, shiftsInput, staff, dateInput, closeModal }) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areaId = useSelector((state) => state.area.areaId);
  const dispatch = useDispatch();

  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    if (visible == true) {
      setShifts(
        (shiftsInput ?? []).map((e) => {
          return {
            ...e,
            is_input: true,
          };
        })
      );
    }
  }, [visible]);

  const [modal, setModal] = useState({
    title: "",
    method: "",
    visible: false,
    shiftsInput: [],
  });

  const handleCloseModal = () => {
    setModal({
      ...modal,
      title: "",
      method: "",
      visible: false,
      shiftsInput: [],
    });
  };

  const customStyles = {
    content: {
      width: "500px",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  function convertNumberToDateStr(start, end) {
    if (
      start !== null &&
      start !== undefined &&
      end !== null &&
      end !== undefined
    ) {
      return `${start.toString().padStart(2, "0")}:${end
        .toString()
        .padStart(2, "0")}`;
    }

    return "";
  }
  const checkTime = (historyTimeKeeping) => {
    if ((historyTimeKeeping.histories ?? []).length < 2) {
      if ((historyTimeKeeping.histories ?? []).length == 0) {
        const date = historyTimeKeeping.date;
        const startWorkHour = historyTimeKeeping?.start_work_hour ?? 0;
        const startWorkMinute = historyTimeKeeping?.start_work_minute ?? 0;

        const startWorkShift = `${moment(date).year()}-${
          moment(date).month() + 1 < 10 ? "0" : ""
        }${moment(date).month() + 1}-${
          moment(date).date() < 10 ? "0" : ""
        }${moment(date).date()} ${
          startWorkHour < 10 ? "0" : ""
        }${startWorkHour}:${
          startWorkMinute < 10 ? "0" : ""
        }${startWorkMinute}:00`;

        if (moment(startWorkShift ?? moment.now()).isAfter(moment.now())) {
          return "NOT_TIME_YET";
        } else {
          return "NOT_ALL_CHECK_IN_OUT";
        }
      }

      return "NOT_CHECK_IN_OUT";
    } else {
      var firstHis = historyTimeKeeping.histories[0];
      var lastHis =
        historyTimeKeeping.histories[historyTimeKeeping.histories.length - 1];
      if (
        historyTimeKeeping.histories.length > 1 &&
        firstHis.is_checkin == false
      ) {
        var timeTracking = firstHis.time_tracking ?? moment.now();

        const endWorkHour = historyTimeKeeping?.end_work_hour ?? 0;
        const endWorkMinute = historyTimeKeeping?.end_work_minute ?? 0;

        const endOfWorkShift = `${moment(timeTracking).year()}-${
          moment(timeTracking).month() + 1 < 10 ? "0" : ""
        }${moment(timeTracking).month() + 1}-${
          moment(timeTracking).date() < 10 ? "0" : ""
        }${moment(timeTracking).date()} ${
          endWorkHour < 10 ? "0" : ""
        }${endWorkHour}:${endWorkMinute < 10 ? "0" : ""}${endWorkMinute}:00`;

        if (moment(timeTracking).isBefore(moment(endOfWorkShift))) {
          return "COME_BACK_SOON";
        }
      }

      if (lastHis.id == null) {
        return "NOT_WORK";
      }

      var timeTracking = firstHis.time_tracking ?? moment.now();

      const startWorkHour = historyTimeKeeping?.start_work_hour ?? 0;
      const startWorkMinute = historyTimeKeeping?.start_work_minute ?? 0;

      const startWorkShift = `${moment(timeTracking).year()}-${
        moment(timeTracking).month() + 1 < 10 ? "0" : ""
      }${moment(timeTracking).month() + 1}-${
        moment(timeTracking).date() < 10 ? "0" : ""
      }${moment(timeTracking).date()} ${
        startWorkHour < 10 ? "0" : ""
      }${startWorkHour}:${
        startWorkMinute < 10 ? "0" : ""
      }${startWorkMinute}:00`;

      if (moment(timeTracking).isAfter(moment(startWorkShift))) {
        return "LATE";
      }
    }

    return "ON_TIME";
  };

  const getText = (type) => {
    return type == "COME_BACK_SOON"
      ? "Về sớm"
      : type == "NOT_WORK"
      ? "Nghỉ"
      : type == "NOT_TIME_YET"
      ? "Chưa đến ca"
      : type == "NOT_CHECK_IN_OUT"
      ? "Chưa vào/ra ca"
      : type == "LATE"
      ? "Đến muộn"
      : type == "ON_TIME"
      ? "Đúng giờ"
      : type == "NOT_ALL_CHECK_IN_OUT"
      ? "Không chấm công"
      : "Chưa đến ca";
  };

  const getColor = (type) => {
    return type == "COME_BACK_SOON"
      ? "rgb(255 200 136 / 20%)"
      : type == "NOT_WORK"
      ? "grey"
      : type == "NOT_TIME_YET"
      ? "rgba(242, 242, 242, 0.81)"
      : type == "NOT_CHECK_IN_OUT"
      ? "rgb(255 14 57 / 20%)"
      : type == "LATE"
      ? "rgb(255 200 136 / 20%)"
      : type == "ON_TIME"
      ? "rgb(30 204 120 / 20%)"
      : type == "NOT_ALL_CHECK_IN_OUT"
      ? "rgb(131 139 163 / 20%)"
      : "red";
  };

  const getColorBorder = (type) => {
    return type == "COME_BACK_SOON"
      ? "rgb(240, 171, 92)"
      : type == "NOT_WORK"
      ? "grey"
      : type == "NOT_TIME_YET"
      ? "rgb(172, 178, 193)"
      : type == "NOT_CHECK_IN_OUT"
      ? "rgb(255, 14, 57)"
      : type == "LATE"
      ? "rgb(240, 171, 92)"
      : type == "ON_TIME"
      ? "rgb(30, 204, 120)"
      : type == "NOT_ALL_CHECK_IN_OUT"
      ? "rgb(131 139 163 / 20%)"
      : "red";
  };

  const addStaffToShift = async (shift) => {
    try {
      const body = {
        staff_id: staff.id,
        shift_id: shift.id,
        date: dateInput,
      };
      const response = await shiftServices.addStaffToShift(areaId, body);

      if (response.code === 200) {
        console.log("done");
      } else {
        toast.success(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateTable = async () => {
    for (const shift of shifts ?? []) {
      console.log(shift);
      if (shift?.is_input !== true) {
        await addStaffToShift(shift);
      }
    }
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>{"Ca làm"}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        style={{
          height: "100%",
          margin: "10px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="row">
          {shifts.map((shift) => {
            return (
              <div className="container col-5 mb-3">
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    boxSizing: "border-box",
                    border: `1px solid ${getColorBorder(checkTime(shift))}`,
                    backgroundColor: getColor(checkTime(shift)),
                    borderRadius: "5px",
                    padding: "5px",
                    fontWeight: "700",
                    fontSize: "13px",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontSize: "12.5px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {shift?.name ?? ""} {"("}
                      {convertNumberToDateStr(
                        shift?.start_work_hour,
                        shift?.start_work_minute
                      )}
                      -
                      {convertNumberToDateStr(
                        shift?.end_work_hour,
                        shift?.end_work_minute
                      )}
                      {")"}
                    </div>
                    {(shift?.histories ?? []).length === 0 ? (
                      <div
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        Chưa chấm công
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        {moment(
                          new Date(shift?.histories[0].time_tracking)
                        ).format("HH:mm:ss")}
                        {" - "}
                        {shift?.histories.length >= 2 &&
                        shift?.histories[shift?.histories.length - 1]
                          .is_checkin === false
                          ? moment(
                              new Date(
                                shift?.histories[
                                  shift?.histories.length - 1
                                ].time_tracking
                              )
                            ).format("HH:mm:ss")
                          : "--:--"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="overlay">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      paddingLeft: "30px",
                      paddingRight: "25px",
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                      }}
                      onClick={(e) => {
                        setModal({
                          title: "",
                          method: "HISTORY",
                          visible: true,
                          staff: staff,
                          shiftHistory: shift,
                        });
                      }}
                    >
                      <CiZoomIn size={30} color={"white"}></CiZoomIn>
                    </button>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                      }}
                    >
                      <CiCircleMore size={30} color={"white"}></CiCircleMore>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="cl-12 d-flex justify-content-center align-item-center mt-1">
          <div
            style={{
              width: "50%",
              height: "60%",
              boxSizing: "border-box",
              borderRadius: "15px",
              padding: "5px",
              fontWeight: "700",
              border: "1px dashed grey",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
            }}
            onClick={(e) => {
              setModal({
                title: `Chọn ca`,
                method: "ADD_SHIFT",
                visible: true,
                shiftsInput: shifts,
              });
            }}
          >
            +
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>

        <button
          type="button"
          style={{
            backgroundColor: "#f6c23e",
            borderRadius: "8px",
            color: "white",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#f6c23e",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
          onClick={(e) => {
            updateTable();
          }}
        >
          Lưu
        </button>
      </div>
      <ChooseShiftModal
        visible={modal.visible && modal.method === "ADD_SHIFT"}
        shiftsInput={(modal.shiftsInput ?? []).map((e) => {
          return { ...e, id: e.shift_id ?? e.id };
        })}
        chooseOne={false}
        onSave={(shiftsData) => {
          console.log(shiftsData);
          setShifts(shiftsData);
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></ChooseShiftModal>
      <HistoryCheckInOutModal
        visible={modal.visible && modal.method === "HISTORY"}
        shiftInput={modal?.shiftHistory}
        staffInput={modal?.staff}
        closeModal={handleCloseModal}
      ></HistoryCheckInOutModal>
    </Modal>
  );
};

export default ShiftModal;
