/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { shiftServices } from "../../../services/shiftServices";
import "./style.css";
import PermissionChecker from "../../../components/PermissionChecker";
import { Space, Table, Popconfirm } from "antd";
import AddShiftGroupModal from "./components/AddShiftGroupModal";

const ShiftGroupPage = () => {
  const areaId = useSelector((state) => state.area.areaId);

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
  });

  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShiftGroups();
  }, []);

  const getShiftGroups = async () => {
    try {
      setLoading(true);
      const resShifts = await shiftServices.getShiftGroups(areaId);
      setShifts(resShifts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteShiftGroup = async (id) => {
    try {
      setLoading(true);
      const resShifts = await shiftServices.deleteShiftGroup(id);
      getShiftGroups();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const columns = [
    {
      title: "Tên nhóm ca",
      dataIndex: "name",
      key: "name",
      render: (name) => <p>{name}</p>,
    },
    {
      title: "Cập nhật lần cuối",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {PermissionChecker({
            decent: reportNumber.permissions?.shifts_edit ?? false,
            child: (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    title: `Chỉnh sửa nhóm ca`,
                    method: "UPDATE",
                    visible: true,
                    data: record?.data,
                  });
                }}
              >
                Sửa
              </a>
            ),
          })}
          {PermissionChecker({
            decent: reportNumber.permissions?.shifts_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá nhóm ca ?"
                description="Bạn có chắc chắn muốn xoá nhóm ca này chứ ?"
                onConfirm={() => {
                  deleteShiftGroup(record.key);
                }}
                onCancel={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Nhóm ca làm việc
        </h4>
        {PermissionChecker({
          decent: reportNumber.permissions?.shifts_add ?? false,
          child: (
            <button
              class={`btn btn-info btn-icon-split btn-sm`}
              data-toggle="modal"
              data-target="#modalShift"
              onClick={() => {
                setModal({
                  title: "Thêm nhóm ca chấm công",
                  method: "ADD",
                  visible: true,
                });
              }}
            >
              <span class="icon text-white-50">
                <i class="fas fa-plus"></i>
              </span>
              <span class="text">Thêm nhóm ca</span>
            </button>
          ),
        })}
      </div>
      <div style={{ height: "20px" }}></div>
      <Table
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={(shifts ?? []).map((e, index) => {
          return {
            key: `${e.id}`,
            data: e,
            stt: index + 1,
            name: e.name ?? "--:--",
            updated_at: moment.utc(e.updated_at).format("DD-MM-YYYY HH:mm"),
            description: (e.shifts ?? []).map((e) => e.name).join(", "),
          };
        })}
        pagination={false}
      />
      <AddShiftGroupModal
        open={
          modal.visible && (modal.method === "ADD" || modal.method === "UPDATE")
        }
        dataInput={modal.data}
        onOk={() => {
          getShiftGroups();
          handleCloseModal();
        }}
        closeModal={() => {
          handleCloseModal();
        }}
      />
    </div>
  );
};

export default ShiftGroupPage;
