/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const TypeProductModal = ({ visible, closeModal, onChoose }) => {
  const customStyles = {
    content: {
      width: "30%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>Chọn nguyên vật liệu</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(0);
          }}
        >
          Nhóm món cơm
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(1);
          }}
        >
          Nhóm món cá
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(2);
          }}
        >
          Nhóm món rau
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(3);
          }}
        >
          Nhóm món thịt
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(4);
          }}
        >
          Nhóm món bò
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(5);
          }}
        >
          Nhóm món khác
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(6);
          }}
        >
          CCDC
        </div>
       
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(7);
          }}
        >
          Đồ dùng
        </div>
        <div
          className="d-flex px-3 py-3"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={(e) => {
            onChoose(8);
          }}
        >
        Nguyên Vật Liệu
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
      </div>
    </Modal>
  );
};

export default TypeProductModal;
