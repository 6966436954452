import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  ConfigProvider,
} from "antd";
import viVN from "antd/locale/vi_VN";
import "suneditor/dist/css/suneditor.min.css";
import { franchiseServices } from "../../../../services/franchiseServices";
import SunEditor from "suneditor-react";
import dayjs from "dayjs";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";

const AddMeetingModal = ({ open, closeModal, onOk, dataInput }) => {
  const [contentText, setContentText] = useState(null);
  const [meeting, setMeeting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [reset, setReset] = useState(false);
  const [resetDate, setResetDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        getMeetingDetail(dataInput.id);
      }
    } else {
      onReset();
    }
  }, [open]);

  const addMeeting = async (data) => {
    setLoading(true);
    try {
      const response = await franchiseServices.addMeeting(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateMeeting = async (data, id) => {
    try {
      const response = await franchiseServices.updateMeeting(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    if (customers.length == 0) {
      toast.error("Vui lòng chọn nhân viên họp");
      return;
    }
    values.content = contentText;
    values.date = values.date.format("YYYY-MM-DD HH:mm:ss");
    values.invited_staffs = customers.map((item) => item.value);
    if (dataInput != null) {
      updateMeeting(values, dataInput.id);
    } else {
      addMeeting(values);
    }
  };

  const onFill = () => {
    setCustomers(
      (dataInput?.invited_staffs ?? []).map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
    form.setFieldsValue({
      title: dataInput?.title ?? "",
      invited_staffs: (dataInput?.invited_staffs ?? []).map((item) => item.id),
      date: dataInput?.date == null ? null : dayjs(dataInput?.date),
    });
    setReset(false);
  };

  const onReset = () => {
    setCustomers([]);
    setContentText(null);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  const getCustomerFranchise = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await franchiseServices.getCustomerFranchise(queryString);

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getMeetingDetail = async (id) => {
    setReset(true);
    try {
      const response = await franchiseServices.getMeetingDetail(id);
      setMeeting(response.data);
      setContentText(response.data?.content);
      onFill();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa thông tin" : "Gửi thông tin"}
      centered
      open={open}
      width={800}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Row align="middle" className="mb-4">
            <Col flex="5">
              <div className="d-flex justify-content-between">
                <div></div>
                <p className="mr-2">Người tham gia:</p>
              </div>
            </Col>
            <Col flex="19">
              <AsyncPaginate
                value={customers}
                loadOptions={getCustomerFranchise}
                isMulti
                closeMenuOnSelect={false}
                onChange={setCustomers}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isClearable
                isSearchable
              />
            </Col>
          </Row>
          <Form.Item
            name="date"
            label="Ngày hẹn:"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <ConfigProvider locale={viVN}>
              {resetDate != null && (
                <DatePicker
                  value={form.getFieldValue("date")}
                  showTime
                  onChange={(value) => {
                    form.setFieldValue("date", value);
                    setResetDate(!resetDate);
                  }}
                />
              )}
            </ConfigProvider>
          </Form.Item>
          <Form.Item
            name="title"
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tiêu đề"} />
          </Form.Item>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[
              {
                required:
                  (contentText ?? "<p><br></p>") != "<p><br></p>" &&
                  (contentText ?? "") != ""
                    ? false
                    : true,
                message:
                  (contentText ?? "<p><br></p>") != "" &&
                  (contentText ?? "") != ""
                    ? "Không được để trống !"
                    : "Không được để trống !",
              },
            ]}
          >
            <div className="editor">
              {reset == false ? (
                <SunEditor
                  name="content"
                  showToolbar={true}
                  setDefaultStyle="height: auto"
                  defaultValue={contentText}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "fontColor",
                        "textStyle",
                        "outdent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "preview",
                      ],
                    ],
                  }}
                  onChange={(e) => {
                    console.log(e);
                    setContentText(e);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddMeetingModal;
