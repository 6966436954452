import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  info: {
    data: {},
    status: c.NONE,
  },
  docs: {
    data: [],
    status: c.NONE
  }



};
export function lesson(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_LESSONS_SUCCESS:
      return {
        ...state,
        list: {
          data : action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_DOCS_LESSON_SUCCESS:
      return {
        ...state,
        docs: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_DOCS_LESSON_FAILURE:
      return {
        ...state,
        docs: {
          data: [],
          status: c.FAILURE,
        },
      };
    case c.RESET_ALL_DOCS_LESSON_STATUS:
      return {
        ...state,
        docs: {
          data: [],

          status: c.NONE,
        },
      };
    case c.GET_ALL_GROUP_TYPE_SUCCESS:
      return {
        ...state,
        groupType_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_LESSON_STATUS:
      return {
        ...state,
        info: {

          status: c.NONE,
        },
      };
    case c.RESET_LESSONS_LIST_STATUS:
      return {
        ...state,
        list: {
          data : [],
          status: c.NONE,
        },
      };




    case c.GET_LESSON_SUCCESS:
      return {
        ...state,
        info: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_LESSONS_FAILURE:
      return {
        ...state,
        list: {
          data : [],
          status: c.FAILURE,
        },
      };
    case c.GET_LESSON_FAILURE:
      return {
        ...state,
        info: {
          data: {},
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }

}
