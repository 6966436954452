import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  info: {
    data: {},
    status: c.NONE,
  },
  chapter_info: {
    status: c.NONE,
  },

  chapter_list: {
    data: [],
    status: c.NONE,
  },

  groupType_list: {
    data: [],
    status: c.NONE,
  },
};
export function course(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_COURSES_SUCCESS:
      return {
        ...state,
        list: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_COURSE_STATUS:
      return {
        ...state,
        info: {
          status: c.NONE,
        },
      };
    case c.RESET_COURSES_LIST_STATUS:
      return {
        ...state,
        list: {
          status: c.NONE,
        },
      };

    case c.RESET_CHAPTER_LIST_STATUS:
      return {
        ...state,
        chapter_list: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_ALL_GROUP_TYPE_SUCCESS:
      return {
        ...state,
        groupType_list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.RESET_GROUP_TYPE_LIST_STATUS:
      return {
        ...state,
        groupType_list: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter_info: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_COURSE_SUCCESS:
      return {
        ...state,
        info: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_COURSES_FAILURE:
      return {
        ...state,
        list: {
          status: c.FAILURE,
        },
      };
    case c.GET_COURSE_FAILURE:
      return {
        ...state,
        info: {
          data: {},
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
