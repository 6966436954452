import { areaServices } from "../services/areaServices";
import { constants as c } from "../constants";

function getAllArea(reportNumber, areaCurrentId, init) {
  return (dispatch) => {
    areaServices.fetchAreas().then((res) => {
      if (res.success === true) dispatch(success(res.data));
      else dispatch(failure(res.code, res.msg));
    });
  };

  function success(data) {
    console.log("====================================");
    console.log("========="+reportNumber);
    console.log("========="+areaCurrentId);
    return { type: c.GET_ALL_AREA_SUCCESS, data, reportNumber, areaCurrentId, init };
  }

  function failure(code, message) {
    return { type: c.FAILURE, code, message };
  }
}

function changeAreaId(data) {
  return (dispatch) => {
    dispatch({
      type: c.CHANGE_AREA_ID,
      data,
    });
  };
}

export const areaAction = {
  getAllArea,
  changeAreaId,
};
