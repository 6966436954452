import moment from "moment";
import React, { useEffect, useState } from "react";
import { shiftServices } from "../../../services/shiftServices";
import Modal from "react-modal";
Modal.setAppElement("#root");

const ChooseAreaModal = ({
  visible,
  listAreaCurrent,
  handleChoice,
  closeModal,
  onSave,
}) => {
  const [listArea, setListArea] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);

  useEffect(() => {
    if (visible == true) {
      fetchArea();
    }
    setAreaSelected(null);
  }, [visible]);

  const fetchArea = async () => {
    try {
      const res = await shiftServices.fetchArea();

      var data = [];

      res.data.forEach((area) => {
        if (listAreaCurrent.map((e) => e.id).includes(area.id) == false) {
          data.push(area);
        }
      });

      setListArea(data);
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    content: {
      width: "50%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>Chọn chi nhánh</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <table
          className="table no-footer dataTable"
          id="dataTable"
          width="100%"
          cellSpacing={0}
          aria-describedby="dataTable_info"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Chi nhánh</th>
              <th>Địa chỉ</th>
            </tr>
          </thead>
          <tbody>
            {listArea?.map((area) => {
              return (
                <tr
                  key={area.id}
                  className="hover-product even"
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={areaSelected?.id === area?.id}
                      onChange={(e) => {
                        setAreaSelected(area);
                        handleChoice(area);
                      }}
                    />
                  </td>
                  <td>{area?.name ?? ""}</td>
                  <td>{area?.address ?? ""}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        <button
          type="button"
          onClick={() => {
            onSave();
          }}
          class="btn btn-info"
        >
          Lưu
        </button>
      </div>
    </Modal>
  );
};

export default ChooseAreaModal;
