/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Form, Input, Select } from "antd";
import { helpServices } from "../../../services/helpServices";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { toast } from "react-toastify";
import { set } from "lodash";

const AddHelpModal = ({ open, closeModal, onOk, dataInput }) => {
  const areaId = useSelector((state) => state.area.areaId);
  const [contentText, setContentText] = useState(null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        getOneHelp(dataInput.id);
      }
    } else {
      onReset();
    }
  }, [open]);

  const addHelp = async (data) => {
    setLoading(true);
    try {
      const response = await helpServices.addHelp(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateHelp = async (data, id) => {
    try {
      const response = await helpServices.updateHelp(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const getOneHelp = async (id) => {
    setReset(true);
    try {
      const response = await helpServices.getOneHelp(id);
      setContentText(response.data.content);
    } catch (error) {
      console.log(error);
    }
    setReset(false);
  };

  const onFinish = (values) => {
    values.content = contentText;
    if (values.content == null) {
      toast.error("Không được để trống nội dung");
    }
    console.log(values);
    if (dataInput != null) {
      updateHelp(values, dataInput.id);
    } else {
      addHelp(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      title: dataInput?.title ?? "",
      key_word: dataInput?.key_word ?? "",
    });
    setContentText(dataInput?.content ?? "");
  };

  const onReset = () => {
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 19,
      span: 16,
    },
  };

  return (
    <Modal
      title={"Thêm câu hỏi"}
      centered
      open={open}
      width={900}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="mt-4">
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item
            name="title"
            label="Câu hỏi hỗ trợ"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập câu hỏi"} />
          </Form.Item>
          <Form.Item
            name="key_word"
            label="Từ khóa tìm kiếm"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập từ khoá (cách nhau bởi dấu phẩy)"} />
          </Form.Item>
          <Form.Item
            name="content"
            label="Câu trả lời"
            rules={[
              {
                required:
                  (contentText ?? "<p><br></p>") != "<p><br></p>" &&
                  (contentText ?? "") != ""
                    ? false
                    : true,
                message:
                  (contentText ?? "<p><br></p>") != "" &&
                  (contentText ?? "") != ""
                    ? "Không được để trống !"
                    : "Không được để trống !",
              },
            ]}
          >
            <div className="editor">
              {reset == false ? (
                <SunEditor
                  name="content"
                  showToolbar={true}
                  setDefaultStyle="height: auto"
                  defaultValue={contentText}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "fontColor",
                        "textStyle",
                        "outdent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "preview",
                      ],
                    ],
                    linkPopover: null,
                  }}
                  
                  onChange={(e) => {
                    console.log(e);
                    setContentText(e);
                  }}
                  
                />
              ) : (
                <></>
              )}
            </div>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit"  loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddHelpModal;
