import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { Tab, TabList, Tabs } from "react-tabs";
import ViewDate from "../../../components/ViewDate";
import { constants } from "../../../constants";
import { convertObjToQueryString } from "../../../helpers";
import { shiftServices } from "../../../services/shiftServices";
import { staffsServices } from "../../../services/staffsServices";
import TableInOut from "./child/TableInOut";
import TableJobBoard from "./child/TableJobBoard";
import "./styles.css";
import PermissionChecker from "../../../components/PermissionChecker";
import { toast } from "react-toastify";

const TimeKeepingHistory = (props) => {
  const dispatch = useDispatch();

  const selectRef = useRef(null);
  const isSetDefaultSelectRef = useRef(false);

  const areaId = useSelector((state) => state.area.areaId);

  const [type, setType] = useState({ value: "staffs", label: "staffs" });
  const [tabIndex, setTabIndex] = useState(0);
  const [query, setQuery] = useState({
    from: moment(new Date()).format(constants.FORMAT_YYYY_MM_DD),
    to: moment(new Date()).format(constants.FORMAT_YYYY_MM_DD),
  });
  const [historyTimeKeeping, setHistoryTimeKeeping] = useState([]);
  const [salaryOfStaff, setSalaryOfStaff] = useState();
  const [selectStaff, setSelectStaff] = useState({});
  const [hasLoad, setHasLoad] = useState(false);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  useEffect(() => {
    if (selectStaff?.value) {
      const queryString = convertObjToQueryString({
        ...query,
        staff_id: selectStaff.value,
      });
      fetchHistoryTimeKeeping(areaId, queryString);

      getSalaryOfStaff(areaId, queryString);
    }
  }, [areaId, selectStaff]);

  useEffect(() => {
    if (selectStaff?.value) {
      const queryString = convertObjToQueryString({
        ...query,
        staff_id: selectStaff.value,
      });
      fetchHistoryTimeKeeping(areaId, queryString);

      getSalaryOfStaff(areaId, queryString);
    }
  }, [query]);

  useEffect(() => {
    isSetDefaultSelectRef.current = false;
    setType({ value: "staffs", label: "staffs" });
  }, [areaId]);

  const fetchHistoryTimeKeeping = async (areaId, queryString) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await shiftServices.fetchHistoryTimeKeeping({
        areaId,
        queryString,
      });
      setHistoryTimeKeeping(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getSalaryOfStaff = async (areaId, queryString) => {
    try {
      const response = await shiftServices.getSalaryOfStaff({
        areaId,
        queryString,
      });
      setSalaryOfStaff(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadStaffOptions = async (search, __loadedOptions, { page }) => {
    const params = {
      search:search,  
      page: page,
      area_id: areaId,
    };

    try {
      var res = null;
      if (areaId == constants.AREA_ALL_ID) {
        res = await shiftServices.fetchStaffsManager({
          queryString: convertObjToQueryString(params),
        });
      } else {
        res = await shiftServices.fetchStaffsTemp({
          areaId: areaId,
          queryString: convertObjToQueryString(params),
        });
      }

      if (!isSetDefaultSelectRef.current && res.data?.data?.length > 0) {
        const staff = res.data?.data[0];
        isSetDefaultSelectRef.current = true;

        if (typeof props?.location?.state?.id === "undefined") {
          setSelectStaff({ value: staff.id, label: `${staff.name}` });
        } else {
          if (hasLoad == false) {
            setSelectStaff({
              value: props.location.state.id,
              label: `${props.location.state.name}`,
            });
            setHasLoad(true);
          } else {
            setSelectStaff({ value: staff.id, label: `${staff.name}` });
            setHasLoad(true);
          }
        }
      }

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.next_page_url != null,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const onChangeSelect = (selectValue) => {
    setSelectStaff(selectValue);
  };

  const renderTable = () => {
    switch (tabIndex) {
      case 0:
        return <TableInOut historyTimeKeeping={historyTimeKeeping} />;
      case 1:
        return (
          <TableJobBoard
            salaryOfStaff={salaryOfStaff}
            queryParams={{
              ...query,
              staff_id: selectStaff?.value,
            }}
          />
        );
      default:
        break;
    }
  };

  const handleChangeDate = (date) => {
    setQuery({
      from: moment(date.startDate).format(constants.FORMAT_YYYY_MM_DD),
      to: moment(date.endDate).format(constants.FORMAT_YYYY_MM_DD),
    });
  };

  const handleExportTimeKeeping = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      let month = moment(query.from);

    
      console.log(month.month());
      console.log(month.year());

      var data = {
        "month": month.month() + 1,
        "year": month.year()
    }

      const response = await shiftServices.exportTimeKeeping({ areaId: areaId, data: data });

      if (response.success) {
        const link = response.data?.link;
        const tagA = document.createElement("a");
        tagA.href = link;
        tagA.download = "timekeeping.xlsx";
        tagA.click();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
       
      }
    } catch (error) {
      console.log(error);
      let msg = "Lỗi khi export lịch sử chấm công";
      toast.error(msg);
     
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className="h4 title_content mb-0 text-gray-800">
          Lịch sử chấm công
        </h4>
        <div style={{ display: "flex" }}>
          <button
            onClick={handleExportTimeKeeping}
            style={{ marginRight: "10px" }}
            class={`btn btn-danger btn-icon-split btn-sm  `}
          >
            <span class="icon text-white-50">
              <i class="fas fa-file-export"></i>
            </span>
            <span style={{ color: "white" }} class="text">
              Export Excel
            </span>
          </button>
        </div>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <ViewDate
          className="d-flex mt-2 ml-4 align-items-center"
          customDate={true}
          type={constants.MONTH}
          handleChange={handleChangeDate}
        />
        <div className="ml-2 mt-2 col-lg-4 col-md-12">
          {PermissionChecker({
            decent: reportNumber.permissions?.staff_manager ?? false,
            child: (
              <AsyncPaginate
                selectRef={selectRef}
                defaultOptions
                placeholder="-- Chọn nhân viên --"
                value={selectStaff}
                isClearable={false}
                cacheUniqs={[type]}
                loadOptions={loadStaffOptions}
                onChange={onChangeSelect}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 99999,
                  }),
                }}
                menuPortalTarget={document.body}
                debounceTimeout={500}
                isSearchable
              />
            ),
          })}
        </div>
        <div className="card-tab mt-4">
          <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Vào/Ra</Tab>
              <Tab>Bảng công</Tab>
            </TabList>
          </Tabs>
        </div>
        <div className="card-body">{renderTable()}</div>
      </div>
    </div>
  );
};

export default TimeKeepingHistory;
