/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { constants } from "../../../../constants";
import { Spin, Modal } from "antd";
import { reportSysServices } from "../../../../services/reportSysServices";
import { Bar, Line } from "react-chartjs-2";
import { MdArrowForwardIos } from "react-icons/md";
import DatePickerModal from "./DatePickerModal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartDetailOperateModal = ({ open, type, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const areas = useSelector((state) => state.area.areas);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [dataChart, setDataChart] = useState(null);
  const [dataCancelOrderArea, setDataCancelOrderArea] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const [titleChart, SetTitleChart] = useState("Số lượng nhân viên mới");

  const [dataTypeOrderCancelChart, setDataTypeOrderCancelChart] =
    useState(null);

  const [dataRateOrderCancelChart, setDataRateOrderCancelChart] =
    useState(null);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  useEffect(() => {
    setFilter({
      type: "day",
      from_time: getFirstAndLastDayInMonth().firstDay,
      to_time: getFirstAndLastDayInMonth().lastDay,
      area_compare_id: null,
      area_id: areaId,
    });
  }, [open]);

  useEffect(() => {
    if (open) {
      getDataChart(type, filter.area_id);
    }
  }, [filter]);

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };
  const getTypeOrder = (type) => {
    switch (type) {
      case 0:
        return "Nhóm món cơm";
      case 1:
        return "Nhóm món cá";
      case 2:
        return "Nhóm món rau";
      case 3:
        return "Nhóm món thịt";
      case 4:
        return "Nhóm món bò";
      case 5:
        return "Nhóm món khác";
      case 6:
        return "CCDC";
      case 7:
        return "Đồ dùng";
      case 8:
        return "Nguyên Vật Liệu";
      default:
        return "Nhóm món cơm";
    }
  };

  const getDataChart = (type) => {
    switch (type) {
      case constants.PAYMENT:
        SetTitleChart("Tổng số tiền thanh toán");
        return getProposePayment();
      case constants.REPAIR:
        SetTitleChart("Tổng số lần sửa chữa");
        return getProposeRepair();
      case constants.SUPPORT:
        SetTitleChart("Tổng số lần hỗ trợ");
        return getProposeHrSupport();
      case constants.BUY_EQUIPMENT:
        SetTitleChart("Tổng số lần đề xuất mua CCDC, TSCD");
        return getProposeBuyEquipment();
      case constants.CANCEL_ORDER:
        SetTitleChart("Tổng số lần huỷ hàng");
        return getProposeCancelOrder();
      case constants.TYPE_MONEY_CANCEL_ORDER:
        SetTitleChart("Tiền huỷ hàng theo từng loại");
        return getProposeCancelOrder();
      case constants.TYPE_RATE_CANCEL_ORDER:
        SetTitleChart("Tỉ lệ huỷ hàng theo từng loại");
        return getRateCancelOrder();
      case constants.TRANFER:
        SetTitleChart("Tổng số lần điều chuyển hàng hoá");
        return getProposeTransportationStuff();
      default:
        return getProposePayment();
    }
  };

  const getProposePayment = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposePayment({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số tiền thanh toán`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_money ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_money ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeRepair = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeRepair({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần sửa chữa`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeHrSupport = async () => {
    setRefresh(true);

    try {
      const response = await reportSysServices.getProposeHrSupport({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần hỗ trợ`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeBuyEquipment = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeBuyEquipment({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần đề xuất mua CCDC, TSCD`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeCancelOrder = async () => {
    setRefresh(true);
    console.log(filter.area_id);

    if (filter.area_id == constants.AREA_ALL_ID) {
      getCancelOrderByArea();
      return;
    }

    try {
      const response = await reportSysServices.getProposeCancelOrder({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần huỷ hàng`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );
        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );
        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }

      if (type == constants.TYPE_MONEY_CANCEL_ORDER) {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );
        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        console.log(labels);

        setDataTypeOrderCancelChart({
          labels,
          datasets: [
            {
              label: `${getTypeOrder(0)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_0 ?? 0
              ),
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${getTypeOrder(1)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_1 ?? 0
              ),
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
            {
              label: `${getTypeOrder(2)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_2 ?? 0
              ),
              borderColor: "#077c59",
              backgroundColor: "#077c59",
            },
            {
              label: `${getTypeOrder(3)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_3 ?? 0
              ),
              borderColor: "#2c44a7",
              backgroundColor: "#2c44a7",
            },
            {
              label: `${getTypeOrder(4)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_4 ?? 0
              ),
              borderColor: "#8f3ec9",
              backgroundColor: "#8f3ec9",
            },
            {
              label: `${getTypeOrder(5)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_5 ?? 0
              ),
              borderColor: "#ac487a",
              backgroundColor: "#ac487a",
            },
            {
              label: `${getTypeOrder(6)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_6 ?? 0
              ),
              borderColor: "#d56668",
              backgroundColor: "#d56668",
            },
            {
              label: `${getTypeOrder(7)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_7 ?? 0
              ),
              borderColor: "#95b66a",
              backgroundColor: "#95b66a",
            },
            {
              label: `${getTypeOrder(8)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_8 ?? 0
              ),
              borderColor: "#6a86b6",
              backgroundColor: "#6a86b6",
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getRateCancelOrder = async () => {
    setRefresh(true);
    console.log(filter.area_id);

    try {
      const response = await reportSysServices.getRateCancelOrder({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });

      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );
        setDataRateOrderCancelChart({
          labels,
          datasets: [
            {
              label: `${getTypeOrder(0)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_0 ?? 0
              ),
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${getTypeOrder(1)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_1 ?? 0
              ),
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
            {
              label: `${getTypeOrder(2)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_2 ?? 0
              ),

              borderColor: "#077c59",
              backgroundColor: "#077c59",
            },
            {
              label: `${getTypeOrder(3)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_3 ?? 0
              ),
              borderColor: "#2c44a7",
              backgroundColor: "#2c44a7",
            },
            {
              label: `${getTypeOrder(4)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_4 ?? 0
              ),
              borderColor: "#8f3ec9",
              backgroundColor: "#8f3ec9",
            },
            {
              label: `${getTypeOrder(5)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_5 ?? 0
              ),
              borderColor: "#ac487a",
              backgroundColor: "#ac487a",
            },
            {
              label: `${getTypeOrder(6)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_6 ?? 0
              ),
              borderColor: "#d56668",
              backgroundColor: "#d56668",
            },
            {
              label: `${getTypeOrder(7)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_7 ?? 0
              ),
              borderColor: "#95b66a",
              backgroundColor: "#95b66a",
            },
            {
              label: `${getTypeOrder(8)}`,
              data: (response?.data?.charts ?? []).map(
                (item) => item.area?.type_8 ?? 0
              ),
              borderColor: "#6a86b6",
              backgroundColor: "#6a86b6",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        setDataRateOrderCancelChart({
          labels,
          datasets: [
            {
              label: `${getTypeOrder(0)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_0 ?? 0
              ),
              borderColor: "#96DBAF",
              backgroundColor: "#96DBAF",
            },
            {
              label: `${getTypeOrder(1)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_1 ?? 0
              ),
              borderColor: "#febf89",
              backgroundColor: "#febf89",
            },
            {
              label: `${getTypeOrder(2)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_2 ?? 0
              ),
              borderColor: "#077c59",
              backgroundColor: "#077c59",
            },
            {
              label: `${getTypeOrder(3)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_3 ?? 0
              ),
              borderColor: "#2c44a7",
              backgroundColor: "#2c44a7",
            },
            {
              label: `${getTypeOrder(4)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_4 ?? 0
              ),
              borderColor: "#8f3ec9",
              backgroundColor: "#8f3ec9",
            },
            {
              label: `${getTypeOrder(5)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_5 ?? 0
              ),
              borderColor: "#ac487a",
              backgroundColor: "#ac487a",
            },
            {
              label: `${getTypeOrder(6)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_6 ?? 0
              ),
              borderColor: "#d56668",
              backgroundColor: "#d56668",
            },
            {
              label: `${getTypeOrder(7)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_7 ?? 0
              ),
              borderColor: "#95b66a",
              backgroundColor: "#95b66a",
            },
            {
              label: `${getTypeOrder(8)}`,
              data: (response?.data?.charts[0]?.data ?? []).map(
                (item) => item.type_8 ?? 0
              ),
              borderColor: "#6a86b6",
              backgroundColor: "#6a86b6",
            },
          ],
        });
      }
      setRefresh(false);
    } catch (error) {
      setRefresh(false);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getCancelOrderByArea = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getCancelOrderByArea({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}`,
      });

      var labels = (response?.data ?? []).map((item) => item?.name ?? "--:--");

      var dataStaff = (response?.data ?? []).map((item) =>
        (item.form_product_cancel_order ?? [])
          .filter((item) => item.type_product === filter.type_product)
          .reduce((total, item) => total + item.total_price_product, 0)
      );

      setDataCancelOrderArea({
        labels,
        datasets: [
          {
            label: `Số tiền huỷ hàng toàn hệ thống`,
            data: dataStaff,
            backgroundColor: "#96DBAF",
          },
        ],
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getProposeTransportationStuff = async () => {
    setRefresh(true);
    try {
      const response = await reportSysServices.getProposeTransportationStuff({
        param: `?area_id=${
          filter.area_id === constants.AREA_ALL_ID ? "" : filter.area_id
        }&from_time=${moment(filter.from_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&to_time=${moment(filter.to_time ?? Date()).format(
          constants.FORMAT_YYYY_MM_DD
        )}&type=${filter.type ?? "day"}&area_compare_id=${
          filter.area_compare_id ?? ""
        }`,
      });
      if (filter.area_id == constants.AREA_ALL_ID) {
        var labels = (response?.data?.charts ?? []).map(
          (item) => item?.area?.name ?? "--:--"
        );

        var dataStaff = (response?.data?.charts ?? []).map(
          (item) => item.area?.total ?? 0
        );

        setDataChart({
          labels,
          datasets: [
            {
              label: `Tổng số lần điều chuyển hàng hoá`,
              data: dataStaff,
              backgroundColor: "#96DBAF",
            },
          ],
        });
      } else {
        var labels = (response?.data?.charts[0]?.data ?? []).map((item) =>
          filter?.type == "month"
            ? moment(item.time ?? Date()).format("MM-YYYY")
            : filter?.type == "year"
            ? moment(item.time ?? Date()).format("YYYY")
            : moment(item.time ?? Date()).format("DD-MM-YYYY")
        );

        var dataStaff = (response?.data?.charts[0]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        var dataStaffCP = (response?.data?.charts[1]?.data ?? []).map(
          (item) => item.total_form ?? 0
        );

        if (filter.area_compare_id == null) {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                backgroundColor: "#96DBAF",
              },
            ],
          });
        } else {
          setDataChart({
            labels,
            datasets: [
              {
                label: `${response?.data?.charts[0]?.area?.name ?? ""}`,
                data: dataStaff,
                borderColor: "#96DBAF",
                backgroundColor: "#96DBAF",
              },
              {
                label: `${response?.data?.charts[1]?.area_compare?.name ?? ""}`,
                data: dataStaffCP,
                borderColor: "#febf89",
                backgroundColor: "#febf89",
              },
            ],
          });
        }
      }
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } finally {
      setRefresh(false);
    }
  };

  const getTypeString = () => {
    if (filter?.type == "month") return "tháng";
    if (filter?.type == "year") return "Năm";
    if (filter?.type == "day") return "ngày";
    if (filter?.type == "quarter") return "quý";
    return "Ngày";
  };

  return (
    <Modal
      title=""
      centered
      open={open}
      width="70%"
      onOk={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        <div className="d-flex">
          <span
            className="px-3 pb-3 d-flex justify-content-start align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal({
                ...modal,
                method: "PICKER",
                visible: true,
                hideCompare:
                  type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                typeChart: type,
              });
            }}
          >
            Theo {getTypeString()}{" "}
            {filter?.type == "month"
              ? moment(filter.from_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.from_time ?? Date()).format("YYYY")
              : moment(filter.from_time ?? Date()).format("DD-MM-YYYY")}{" "}
            đến{" "}
            {filter?.type == "month"
              ? moment(filter.to_time ?? Date()).format("MM-YYYY")
              : filter?.type == "year"
              ? moment(filter.to_time ?? Date()).format("YYYY")
              : moment(filter.to_time ?? Date()).format("DD-MM-YYYY")}
            <MdArrowForwardIos className="ml-1" size={13} />
          </span>
          {filter.area_compare_id != null ? (
            <div
              onClick={() => {
                setModal({
                  ...modal,
                  method: "PICKER",
                  visible: true,
                  hideCompare:
                    type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                  typeChart: type,
                });
              }}
            >{`Đang so sánh: ${
              areas.data.filter((a) => a.id === filter.area_id)[0].name ?? ""
            } với ${
              areas.data.filter((a) => a.id === filter.area_compare_id)[0]
                .name ?? ""
            }`}</div>
          ) : (
            <div
              style={{ cursor: "pointer", color: "#096dd9" }}
              onClick={() => {
                setModal({
                  ...modal,
                  method: "PICKER",
                  visible: true,
                  hideCompare:
                    type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                  typeChart: type,
                });
              }}
            >{`Chi nhánh: ${
              areas.data.filter((a) => a.id === filter.area_id)[0]?.name ?? ""
            }`}</div>
          )}
          {filter.type_product != null ? (
            <div
              className="ml-3"
              style={{ cursor: "pointer", color: "#9e4f17" }}
              onClick={() => {
                setModal({
                  ...modal,
                  method: "PICKER",
                  visible: true,
                  hideCompare:
                    type == constants.TYPE_MONEY_CANCEL_ORDER ? true : false,
                  typeChart: type,
                });
              }}
            >{`Nhóm đang lọc: ${getTypeOrder(filter.type_product) ?? ""}`}</div>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex justify-content-center">
          {dataChart === null || refresh == true ? (
            <Spin style={{ textAlign: "center" }} />
          ) : type === constants.TYPE_MONEY_CANCEL_ORDER ? (
            filter.area_id == constants.AREA_ALL_ID &&
            dataCancelOrderArea != null ? (
              <Bar
                options={{
                  responsive: true,
                  aspectRatio: 2.5,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Tiền huỷ hàng theo từng loại",
                    },
                  },
                }}
                data={dataCancelOrderArea}
              />
            ) : dataTypeOrderCancelChart == null ? (
              <></>
            ) : (
              <Line
                options={{
                  responsive: true,
                  aspectRatio: 2.5,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Tiền huỷ hàng theo từng loại",
                    },
                  },
                }}
                data={dataTypeOrderCancelChart}
                style={{
                  maxWidth: "100%",
                }}
              />
            )
          ) : type === constants.TYPE_RATE_CANCEL_ORDER ? <></> : (dataChart.datasets ?? []).length == 1  ? (
            <Bar
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth: "100%",
              }}
            />
          ) : (
            <Line
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: titleChart,
                  },
                },
              }}
              data={dataChart}
              style={{
                maxWidth: "100%",
              }}
            />
          )}
        </div>
       
        {type === constants.TYPE_RATE_CANCEL_ORDER ? dataRateOrderCancelChart === null || refresh == true ? (
            <Spin style={{ textAlign: "center" }} />
          ) : (
            <Line
              options={{
                responsive: true,
                aspectRatio: 2.5,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Tỉ lệ huỷ hàng theo từng loại",
                  },
                },
              }}
              data={dataRateOrderCancelChart}
              style={{
                maxWidth: "100%",
              }}
            />
          ): (
          <></>
        )}

        <DatePickerModal
          visible={modal.visible && modal.method === "PICKER"}
          closeModal={() => {
            handleCloseModal();
          }}
          hideCompare={modal.hideCompare}
          typeChart={modal.typeChart}
          filterInput={filter}
          onOk={(value, type, areaChart, areaCompare, typeProduct) => {
            setFilter({
              ...filter,
              type: type,
              area_id: areaChart,
              area_compare_id: areaCompare,
              from_time: value[0],
              to_time: value[1],
              type_product: typeProduct,
            });
            handleCloseModal();
          }}
        ></DatePickerModal>
      </div>
    </Modal>
  );
};

export default ChartDetailOperateModal;
