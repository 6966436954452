import React, { useEffect, useState } from "react";

export default function ModalCreate(props) {




  return (
      <form role="form" >
        <div class="form-group">
          <label for="product_name">Xóa {props.selectStaffs?.length} nhân viên?</label>
      
        </div>
 
      </form>
  );
}
