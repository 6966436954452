/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import Modal from "react-modal";
import React, { useEffect } from "react";
import Map from "../Map";
import { shiftServices } from "../../../../../services/shiftServices";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { constants } from "../../../../../constants";
import { Image } from "antd";

Modal.setAppElement("#root");

const TimekeepingModal = ({ visible, historyId, closeModal }) => {
  const areaId = useSelector((state) => state.area.areaId);

  const [timeKeeping, setTimekeeping] = useState();

  useEffect(() => {
    if (visible) {
      getTimeKeepingById(historyId);
    }
  }, [historyId]);

  const getTimeKeepingById = async (historyId) => {
    try {
      const response = await shiftServices.getTimeKeepingById({
        areaId,
        historyId,
      });
      setTimekeeping(response.data);
    } catch (error) {}
  };
  const customStyles = {
    content: {
      width: "50%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{"Chi tiết chấm công"}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className="modal-body"
        style={{ overflowY: "auto", height: "100%", margin: "10px" }}
      >
        <div className="form-group">
          <label>Nhân viên</label>
          <input
            value={timeKeeping?.staff?.name || ""}
            type="text"
            className="form-control"
            autoComplete="off"
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Thời gian</label>
          <input
            value={
              timeKeeping?.time_tracking
                ? moment(new Date(timeKeeping.time_tracking)).format(
                    constants.FORMAT_DD_MM_YYYY_HH_MM_SS
                  )
                : ""
            }
            type="text"
            className="form-control"
            autoComplete="off"
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Tên ca làm</label>
          <input
            value={timeKeeping?.timekeeping?.name || ""}
            type="text"
            className="form-control"
            autoComplete="off"
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Chi nhánh</label>
          <input
            value={timeKeeping?.area?.name || ""}
            type="text"
            className="form-control"
            autoComplete="off"
            readOnly
          />
        </div>
        {timeKeeping?.image != null ? (
          <div className="form-group">
            <label>Hình ảnh chấm công tại chi nhánh</label>
            <Image src={timeKeeping?.image ?? ""}></Image>
          </div>
        ) : (
          <></>
        )}
        <div className="form-group">
          <label>Vị trí</label>
          <Map
            latitude={timeKeeping?.map_lat}
            longitude={timeKeeping?.map_long}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TimekeepingModal;
