/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import { shiftServices } from "../../../services/shiftServices";
import { DatePicker, SelectPicker, Uploader } from "rsuite";
import { areaServices } from "../../../services/areaServices";
import { incidentServices } from "../../../services/incidentServices";
import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { appServices } from "../../../services/appServices";
import SunEditor from "suneditor-react";
import FileUploader from "../../../components/Uploader/FileUploader";
import { Modal, Space } from "antd";
import ChooseProcedureModal from "./ChooseProcedureModal";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const FORMAT_DATE = "dd-MM-yyyy";
const IncidentCreateModal = ({ visible, type, closeModal, onSuccess }) => {
  const areas = useSelector((state) => state.area.areas);
  const userInfo = useSelector((state) => state.user.profile);
  const [listStaffCreate, setListStaffCreate] = useState([]);
  const [listStaffRelate, setListStaffRelate] = useState([]);
  const [listStaffViewer, setListStaffViewer] = useState([]);
  const [listStaffSeen, setListStaffSeen] = useState([]);
  const [listArea, setListArea] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listDataImage, setListDataImage] = useState([]);
  const [listFileUrl, setListFileUrl] = useState([]);
  const [listProcedure, setListProcedure] = useState([]);
  const [contentText, setContentText] = useState("");
  const [procedureChoose, setProcedureChoose] = useState(null);

  const [modal, setModal] = useState({
    visible: false,
    listProcedure: [],
  });

  const [data, setData] = useState({
    content: "",
    address_happen: "",
    time_happen: null,
    type: null,
    option_relate: 0,
    list_id_staff_create: [],
    list_id_staff_relate: [],
    list_id_staff_viewer: [],
    list_area_id: [],
    list_department_id: [],
    images: [],
  });

  const [isProcedure, setIsProcedure] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setListStaffCreate([
      {
        value: userInfo.id,
        label: `${userInfo.name}`,
      },
    ]);
    setData({
      title: "",
      content: "",
      address_happen: "",
      time_happen: null,
      type: type,
      option_relate: 0,
      list_id_staff_create: [],
      list_id_staff_relate: [],
      list_id_staff_viewer: [],
      list_area_id: [],
      list_department_id: [],
    });
    if (visible === true) {
      getProcedure();
    }
  }, [type, visible]);

  const fetchStaffsManagerCreate = async (
    search,
    __loadedOptions,
    { page }
  ) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchStaffsManager = async (search, __loadedOptions, { page }) => {
    const queryString = `?search=${search}&page=${page}`;

    try {
      const res = await shiftServices.fetchStaffsManager({
        queryString: queryString,
      });

      return {
        options: res.data.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAreas = async (search, __loadedOptions, { page }) => {
    try {
      const res = await areaServices.fetchAreas();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchAllDepartments = async (search, __loadedOptions, { page }) => {
    try {
      const res = await shiftServices.fetchAllDepartments();
      return {
        options: res.data.map((i) => {
          return {
            value: i.id,
            label: `${i.name}`,
          };
        }),
        hasMore: res.data.length === 20,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const createIncident = async (procedureId) => {
    if (data?.title === "") {
      toast.error("Chưa nhập tiêu đề");
      return;
    }

    if (listStaffCreate.length === 0) {
      toast.error("Chưa chọn người lập biên bản");
      return;
    }
    if (procedureId === null) {
      if (data?.option_relate === 0) {
        if (listStaffRelate.length === 0) {
          toast.error(
            data?.type === 1
              ? "Chưa chọn bên liên quan trực tiếp"
              : "Chưa chọn bên nhận thông tin"
          );
          return;
        }
      } else {
        if (listArea.length === 0) {
          toast.error("Chưa chọn chi nhánh");
          return;
        }
      }
    }

    if (data?.time_happen === null) {
      toast.error("Chưa chọn thời gian diễn ra");
      return;
    }

    if (data?.address_happen === null || data?.address_happen === "") {
      toast.error("Chưa nhập địa điểm diễn ra");
      return;
    }

    if (contentText === null || contentText === "") {
      toast.error("Chưa nhập nội dung");
      return;
    }

    await Promise.all(
      fileList.map((e) => {
        const file = e.blobFile;
        return sendImage(file);
      })
    ).catch((error) => {
      console.error("Có lỗi xảy ra khi chạy các hàm gọi API", error);
    });

    var bodyRequest = {
      title: data?.title,
      content: contentText,
      address_happen: data?.address_happen,
      time_happen: moment(data?.time_happen).format(
        constants.FORMAT_YYYY_MM_DD_HH_MM_SS
      ),
      type: data?.type,
      is_procedure: isProcedure,
      list_id_staff_create: listStaffCreate.map((e) => e.value),
      list_id_staff_relate: listStaffRelate.map((e) => e.value),
      list_id_staff_viewer: listStaffViewer.map((e) => e.value),
      list_id_staff_seen: listStaffSeen.map((e) => e.value),
      file_url: listFileUrl.length == 0 ? null : listFileUrl,
      list_area_id: listArea.map((e) => e.value),
      list_department_id: listDepartment.map((e) => e.value),
      images: listDataImage,
      procedure_id: procedureId,
    };

    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await incidentServices.createIncident({
        data: bodyRequest,
      });
      if (response.code != 401) {
        toast.success("Tạo biên bản thành công");
        setData({
          title: "",
          content: "",
          address_happen: "",
          time_happen: null,
          type: type,
          option_relate: 0,
          list_id_staff_create: [],
          list_id_staff_relate: [],
          list_id_staff_viewer: [],
          list_area_id: [],
          list_department_id: [],
          images: [],
        });

        setListStaffCreate([]);
        setListStaffRelate([]);
        setListStaffViewer([]);
        setListArea([]);
        setListDepartment([]);
        setListDataImage([]);
        setFileList([]);
        onSuccess();
        return;
      } else {
        setFileList([]);
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getProcedure = async () => {
    try {
      const response = await incidentServices.getProcedure();
      setListProcedure(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const [fileList, setFileList] = React.useState([]);
  const uploader = React.useRef();

  async function sendImage(image, name) {
    var imageData = null;
    if (image) {
      imageData = new FormData();
      imageData.append("image", image);
    }
    var resImage = await appServices.uploadImg(imageData);

    listDataImage.push(resImage.data?.image_url);

    setListDataImage(listDataImage);
  }

  return (
    <>
      <Modal
        title={type === 1 ? "Tạo biên bản" : "Tạo thông tin"}
        centered
        open={visible}
        width={"auto"}
        style={{ top: 20, minWidth: 500, maxWidth: 1000 }}
        onCancel={() => {
          closeModal();
        }}
        // onOk={() => {
        //   if (procedureInput?.title === null) {
        //     createProcedure();
        //   } else {
        //     updateProcedure();
        //   }
        // }}
        footer={null}
      >
        <div
          style={{
            overflowY: "auto",
            height: "auto",
            maxHeight: "70vh",
            margin: "10px",
          }}
        >
          <Space>
            <div
              className="py-3 mr-5"
              style={{ fontSize: "15px", fontWeight: "700" }}
            >
              KIỂU GỬI:
            </div>
            <div className="mr-5">
              <input
                className="mr-2"
                type="radio"
                checked={isProcedure === false}
                onChange={(e) => {
                  setIsProcedure(false);
                }}
              />
              <span>Gửi trực tiếp</span>
            </div>
            <div className="">
              <input
                className="mr-2"
                type="radio"
                checked={isProcedure === true}
                onChange={(e) => {
                  setIsProcedure(true);
                }}
              />
              <span>Theo quy trình</span>
            </div>
          </Space>
          {isProcedure && (
            <div
              className="row py-3 justify-content-between align-items-center"
              style={{ fontSize: "15px", fontWeight: "700" }}
            >
              <p className="col-3">Quy trình:</p>
              <SelectPicker
                className="col-9"
                data={listProcedure.map((e) => {
                  return {
                    label: e.title,
                    value: e.id,
                  };
                })}
                value={procedureChoose}
                placeholder="Chọn quy trình"
                cleanable={false}
                searchable={false}
                onChange={(e) => {
                  setProcedureChoose(e);
                  console.log(e);
                }}
              />
            </div>
          )}
          <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
            I. THÀNH PHẦN LIÊN QUAN:
          </div>
          <div className="ml-4">
            <div className="d-flex mb-3">
              <span>1.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    {type === 1 ? "Tiêu đề biên bản" : "Tiêu đề thông tin"}
                  </div>
                  <div className="col-9">
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="VD: Biên bản xử lý vi phạm nội quy, quy định, ..."
                      autoComplete="off"
                      value={data?.title}
                      onChange={(e) => {
                        setData({ ...data, title: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <span>2.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    {type === 1 ? "Người lập biên bản" : "Người tạo thông tin"}
                  </div>
                  <div className="col-9">
                    <AsyncPaginate
                      value={listStaffCreate}
                      loadOptions={fetchStaffsManagerCreate}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={setListStaffCreate}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 99999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      debounceTimeout={500}
                      isClearable
                      isSearchable
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "20px" }}></div>
            {isProcedure ? (
              <></>
            ) : (
              <div className="d-flex mb-3">
                <span>3.</span>
                <div className="ml-1" style={{ width: "100%" }}>
                  <div className="row mb-1">
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      {type === 1
                        ? "Bên liên quan trực tiếp:"
                        : "Bên nhận thông tin"}
                    </div>
                    <div className="col-9">
                      <div className="d-flex">
                        <div className="mb-2 mr-5">
                          <input
                            className="mr-2"
                            type="radio"
                            checked={data?.option_relate === 0}
                            onChange={(e) => {
                              setData({ ...data, option_relate: 0 });
                            }}
                          />
                          <span>Theo những cá nhân</span>
                        </div>
                        <div className="mb-2">
                          <input
                            className="mr-2"
                            type="radio"
                            checked={data?.option_relate === 1}
                            onChange={(e) => {
                              setData({ ...data, option_relate: 1 });
                            }}
                          />
                          <span>Theo chi nhánh/ Phòng ban</span>
                        </div>
                      </div>
                      {data?.option_relate === 0 ? (
                        <AsyncPaginate
                          value={listStaffRelate}
                          loadOptions={fetchStaffsManager}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={setListStaffRelate}
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 99999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          debounceTimeout={500}
                          isClearable
                          isSearchable
                        />
                      ) : (
                        <div>
                          <div
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div style={{ flex: 1 }}>Chi nhánh</div>
                            <div style={{ width: "80%" }}>
                              <AsyncPaginate
                                value={listArea}
                                loadOptions={fetchAreas}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={setListArea}
                                additional={{
                                  page: 1,
                                }}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 99999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                debounceTimeout={500}
                                isClearable
                                isSearchable
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{ alignItems: "center", marginTop: "20px" }}
                          >
                            <div style={{ flex: 1 }}>Phòng ban</div>
                            <div style={{ width: "80%" }}>
                              <AsyncPaginate
                                value={listDepartment}
                                loadOptions={fetchAllDepartments}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={setListDepartment}
                                additional={{
                                  page: 1,
                                }}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 99999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                debounceTimeout={500}
                                isClearable
                                isSearchable
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isProcedure == false && (
              <>
                {type === 1 ? (
                  <div className="d-flex mb-3">
                    <span>4.</span>
                    <div className="ml-1" style={{ width: "100%" }}>
                      <div className="row mb-1">
                        <div
                          className="col-3"
                          style={{ fontSize: "15px", fontWeight: "700" }}
                        >
                          Bên chứng kiến sự việc:
                        </div>
                        <div className="col-9">
                          <AsyncPaginate
                            value={listStaffViewer}
                            loadOptions={fetchStaffsManager}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={setListStaffViewer}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 99999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            debounceTimeout={500}
                            isClearable
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {type === 1 ? (
                  <div className="d-flex">
                    <span>5.</span>
                    <div className="ml-1" style={{ width: "100%" }}>
                      <div className="row mb-1">
                        <div
                          className="col-3"
                          style={{ fontSize: "15px", fontWeight: "700" }}
                        >
                          Người được xem biên bản:
                        </div>
                        <div className="col-9">
                          <AsyncPaginate
                            value={listStaffSeen}
                            loadOptions={fetchStaffsManager}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={setListStaffSeen}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 99999,
                              }),
                            }}
                            menuPortalTarget={document.body}
                            debounceTimeout={500}
                            isClearable
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
            {type === 1 ? "II. CHI TIẾT SỰ VIỆC:" : "II. CHI TIẾT THÔNG TIN:"}
          </div>
          <div className="ml-4">
            <div className="d-flex mb-3" style={{ width: "100%" }}>
              <span>1.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div>
                  <div className="row mb-1">
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Thời gian diễn ra:
                    </div>
                    <div className="col-5">
                      <DatePicker
                        value={data?.time_happen}
                        format={FORMAT_DATE}
                        cleanable={false}
                        placement="topStart"
                        className="time-picker"
                        onChange={(e) => {
                          setData({ ...data, time_happen: e });
                        }}
                      />
                    </div>
                    {data?.time_happen !== null ? (
                      <div className="col-4">
                        <DatePicker
                          value={data?.time_happen}
                          format="HH:mm"
                          cleanable={false}
                          placement="topStart"
                          className="time-picker"
                          onChange={(e) => {
                            setData({ ...data, time_happen: e });
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mb-3">
              <span>2.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Địa điểm diễn ra:
                  </div>
                  <div className="col-9">
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Nhập địa điểm"
                      autoComplete="off"
                      value={data?.address_happen}
                      onChange={(e) => {
                        setData({ ...data, address_happen: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <span>3.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    {type === 1 ? "Nội dung sự việc:" : "Nội dung thông tin:"}
                  </div>
                  <div className="col-9">
                    <div className="editor">
                      <SunEditor
                        showToolbar={true}
                        setDefaultStyle="height: auto"
                        setOptions={{
                          buttonList: [
                            [
                              "undo",
                              "redo",
                              "font",
                              "fontSize",
                              "formatBlock",
                              "paragraphStyle",
                              "blockquote",
                              "bold",
                              "underline",
                              "italic",
                              "fontColor",
                              "textStyle",
                              "outdent",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "table",
                              "link",
                              "image",
                              "video",
                              "audio",
                              "fullScreen",
                              "preview",
                            ],
                          ],
                        }}
                        onChange={(e) => {
                          console.log(e);
                          console.log(data);
                          setContentText(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Uploader
            multiple
            headers={{
              token: tokenInfo,
              "Content-Type": "multipart/form-data",
            }}
            listType="picture"
            fileList={fileList}
            autoUpload={false}
            onChange={setFileList}
            onSuccess={(e) => {}}
            ref={uploader}
            action={`${constants.API_URL}/upload_v2/files?type=incident`}
          >
            <button
              style={{
                height: "66px",
                width: "66px",
                justifyContent: "center",
              }}
            >
              <CameraRetroIcon />
            </button>
          </Uploader>
          <div className="mt-3">
            <FileUploader
              maxCount={5}
              onDone={(listFileUrl) => {
                setListFileUrl(listFileUrl);
              }}
            ></FileUploader>
          </div>
          <div
            className="my-4"
            style={{ height: "1px", backgroundColor: "#f2f7ff", width: "100%" }}
          ></div>
          <div className="my-4"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button
            type="button"
            onClick={() => {
              if (isProcedure) {
                if (procedureChoose == null) {
                  toast.error("Chưa chọn quy trình");
                  return;
                }
                createIncident(procedureChoose);
              } else {
                createIncident({ procedure_id: null });
              }
            }}
            class="btn btn-info"
          >
            Lưu
          </button>
        </div>
        {/* <ChooseProcedureModal
          visible={modal.visible}
          listProcedure={modal.listProcedure}
          closeModal={() => {
            setModal({ ...modal, visible: false });
          }}
          onChoose={(item) => {
            console.log(item);
            setModal({ ...modal, visible: false });
            createIncident(item.id);
          }}
        ></ChooseProcedureModal> */}
      </Modal>
    </>
  );
};

export default IncidentCreateModal;
