/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import { areaServices } from "../../services/areaServices";
import HistoryReviews from "./child/HistoryReviews";
import Questions from "./child/Questions";
import ReportReviews from "./child/ReportReviews";
import "./style.css";

const EvaluationBranchPage = () => {
  const history = useHistory();

  const areaId = useSelector((state) => state.area.areaId);

  const { id } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const [area, setArea] = useState();
  const [rating, setRating] = useState(null);

  const fetchRatingAreas = async () => {
    try {
      const response = await areaServices.fetchRatingAreas();
      const ratingArea = response.data.find(
        (rating) => rating.area_id === areaId
      );
      if (ratingArea) {
        setRating(ratingArea);
      } else {
        setRating(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getAreaById(id);
    }
  }, [id]);

  useEffect(() => {
    history.replace(`/branch/evaluation/${areaId}?key=branch`);
    fetchRatingAreas();
  }, [areaId]);

  const getAreaById = async (id) => {
    try {
      const response = await areaServices.getAreaById(id);
      setArea(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderView = () => {
    switch (tabIndex) {
      case 0:
        return <ReportReviews />;
      case 1:
        return <HistoryReviews />;
      case 2:
        return <Questions />;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <h4 className="h4 title_content mb-0 text-gray-800">
            {`Danh sách đánh giá chi nhánh ${area?.name || ""}`}
          </h4>
          <h4 className="h4 title_content mb-0">
            Số sao trung bình:
            <span style={{ color: "#F9C114" }}>
              {" "}
              {rating === null ? 0 : rating?.star}
            </span>
          </h4>
        </div>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Báo cáo đánh giá</Tab>
              <Tab>Lịch sử đánh giá</Tab>
              <Tab>Câu hỏi</Tab>
            </TabList>
          </Tabs>
        </div>
        <div className="card-body">{renderView()}</div>
      </div>
    </div>
  );
};

export default EvaluationBranchPage;
