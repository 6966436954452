import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Button, Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../../../actions/chatActions";
import { constants as c } from "../../../../constants";
import { StyledModalSelectUser } from "./ModalSelectUser.style";
import RoomChatItem from "./RoomChatItem";
import { handleShowTypeMessage } from "../../utils";
import { SearchOutlined } from "@material-ui/icons";

const typeModal = {
  FORWARD: "forward",
  ADD_MEMBER: "addMember",
  CREATE_GROUP: "createRoom",
  SEARCH_ROOM: "searchRoom",
  ADD_USER_TO_GROUP: "addUserToGroup",
};

const initialRedirectParams = {
  user_ids: [],
  room_ids: [],
  message_id: "",
  content: "",
};

export default function ModalSelectUser() {
  const dispatch = useDispatch();
  const modalSelectUser = useSelector((state) => state.chat.modalSelectUser);
  const roomSelected = useSelector((state) => state.chat.roomSelected);
  const profile = useSelector((state) => state.user.profile);
  const searchRoomRecentlyList = useSelector(
    (state) => state.chat.searchRoomRecentlyList
  );
  const searchMemberList = useSelector((state) => state.chat.searchMemberList);
  const roomList = useSelector((state) => state.chat.roomChatList);
  const fullScreen = useSelector((state) => state.chat.fullscreen);

  const token = JSON.parse(localStorage.getItem("tokenInfo"));

  const [params, setParams] = useState(initialRedirectParams);
  const [searchKey, setSearchKey] = useState("");
  const [roomName, setRoomName] = useState("");
  const [roomAvatar, setRoomAvatar] = useState("");

  const inputImageRef = useRef(null);
  const inputSearchRef = useRef(null);

  useEffect(() => {
    const query = `?search=&limit=50&is_hide_same=false&search=${searchKey}`;
    dispatch(a.searchMembers(query));
    if (modalSelectUser?.type === typeModal.ADD_USER_TO_GROUP) {
      dispatch(a.getRoomChatList("?is_group=true&page=1"));
    }
    inputSearchRef?.current?.input?.focus()
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchMember();
    }, 400);
    return () => clearTimeout(timer);
  }, [searchKey]);

  const configInitByType = () => {
    switch (modalSelectUser.type) {
      case typeModal.FORWARD:
        return {
          title: "Chia sẻ",
          confirmButton: "Chia sẻ",
          onConfirm: () => handleRedirectMessage(),
          hideFooter: false,
        };
      case typeModal.ADD_MEMBER:
        return {
          title: "Thêm thành viên",
          confirmButton: "Thêm",
          onConfirm: () => handleAddMember(),
          hideFooter: false,
        };
      case typeModal.CREATE_GROUP:
        return {
          title: "Tạo nhóm",
          confirmButton: "Tạo nhóm",
          onConfirm: () => handleCreateRoom(),
          hideFooter: false,
        };
      case typeModal.SEARCH_ROOM:
        return {
          title: "Tìm kiếm cuộc trò chuyên",
          confirmButton: "Trò chuyện",
          hideFooter: true,
        };
      case typeModal.ADD_USER_TO_GROUP:
        return {
          title: "Thêm vào nhóm",
          confirmButton: "Xác nhận",
          hideFooter: true,
        };
      default:
        return;
    }
  };

  const handleSearchMember = () => {
    const query = `?search=&limit=50&is_hide_same=false&search=${searchKey}`;
    dispatch(a.searchMembers(query));
    dispatch(a.getRoomChatList(query));
  };

  const handleCloseModal = () => {
    dispatch(a.toggleModalSelectsUser({ isOpen: false }));
  };

  const handleSelectRoom = (room) => {
    if (modalSelectUser.type === typeModal.SEARCH_ROOM) {
      dispatch(a.selectedRoomChat(room));
      dispatch(a.toggleModalSelectsUser({ isOpen: false }));
    }
    if (modalSelectUser.type === typeModal.ADD_USER_TO_GROUP) {
      const paramsAddUser = {
        user_ids: [modalSelectUser.data],
        is_add_member: true,
      };
      dispatch(a.updateMemberRoomChat(room.id, paramsAddUser));
    }
    if (modalSelectUser.type === typeModal.ADD_MEMBER) {
      const userId = room.users.find((item) => item.id !== profile.id)?.id;
      const roomIndex = params.room_ids.indexOf(userId);
      if (roomIndex !== -1) {
        setParams((prev) => {
          return {
            ...prev,
            room_ids: prev.room_ids.filter((id) => id !== userId),
          };
        });
      } else {
        setParams((prev) => {
          return {
            ...prev,
            room_ids: [...prev.room_ids, userId],
          };
        });
      }
    }
    else {
      const roomIndex = params.room_ids.indexOf(room.id);
      if (roomIndex !== -1) {
        setParams((prev) => {
          return {
            ...prev,
            room_ids: prev.room_ids.filter((id) => id !== room.id),
          };
        });
      } else {
        setParams((prev) => {
          return {
            ...prev,
            room_ids: [...prev.room_ids, room.id],
          };
        });
      }
    }
  };

  const handleSelectUser = (user) => {
    if (modalSelectUser.type === typeModal.SEARCH_ROOM) {
      const paramsCreateRoom = {
        name_room: "",
        room_avatar: "",
        user_ids: [user.id, profile.id],
      };
      dispatch(a.createRoomChat(paramsCreateRoom));
    }
    const roomIndex = params.user_ids.indexOf(user.id);
    if (roomIndex !== -1) {
      setParams((prev) => {
        return {
          ...prev,
          user_ids: prev.user_ids.filter((id) => id !== user.id),
        };
      });
    } else {
      setParams((prev) => {
        return {
          ...prev,
          user_ids: [...prev.user_ids, user.id],
        };
      });
    }
  };

  const handleRedirectMessage = () => {
    dispatch(
      a.redirectMessage({ ...params, message_id: modalSelectUser?.data?.id })
    );
  };

  const handleAddMember = () => {
    const paramsAddMember = {
      user_ids: [...params.user_ids, ...params.room_ids],
      is_add_member: true,
    };
    dispatch(a.updateMemberRoomChat(roomSelected.id, paramsAddMember));
  };

  const handleCreateRoom = () => {
    let usersIdFromRoomIds = [];
    if (params.room_ids?.length) {
      params.room_ids.forEach((roomId) => {
        const room = roomList.find((room) => room.id === roomId);
        if (room?.users?.length) {
          room.users.forEach((user) => {
            if (user.id !== profile.id) {
              usersIdFromRoomIds.push(user.id);
            }
          });
        }
      });
    }
    const paramsCreateRoom = {
      name_room: roomName,
      room_avatar: roomAvatar,
      user_ids: [...params.user_ids, profile.id, ...usersIdFromRoomIds],
    };
    if (modalSelectUser.data)
      paramsCreateRoom.user_ids.push(modalSelectUser.data);
    dispatch(a.createRoomChat(paramsCreateRoom));
  };

  const handleImageClick = () => {
    inputImageRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    const formData = new FormData();
    formData.append("image", file[0]);
    axios 
      .post(`${c.API_URL}/upload_v2/files?type=chat`, formData, {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        setRoomAvatar(response.data.data.image_url);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleDisableConfirmButton = () => {
    if (typeModal.CREATE_GROUP === modalSelectUser.type) {
      if (params.user_ids?.length < 2 && params.room_ids?.length < 2)
        return true;
    } else if (!params.user_ids?.length && !params.room_ids?.length)
      return true;
    return false;
  };

  const renderContentModal = () => {
    return (
      <StyledModalSelectUser>
        <div className="modal-content-container">
          {modalSelectUser?.type === typeModal.CREATE_GROUP && (
            <div
              className="d-flex justify-content-center"
              style={{ gap: "8px", marginBottom: "16px" }}
            >
              <div
                className="input-chat-action__image"
                onClick={handleImageClick}
                title="Avatar nhóm"
                style={{
                  cursor: "pointer",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "1px solid #ccc",
                  flexShrink: "0",
                }}
              >
                {roomAvatar ? (
                  <img
                    src={roomAvatar}
                    style={{
                      width: "36px",
                      height: "36px",
                      color: "#ccc",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt="avatar room"
                  />
                ) : (
                  <CameraAltIcon
                    style={{ width: "18px", height: "18px", color: "#ccc" }}
                  />
                )}
                <input
                  type="file"
                  ref={inputImageRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
              <input
                placeholder="Nhập tên nhóm"
                onChange={(e) => setRoomName(e.target.value)}
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #ddd",
                  width: "100%",
                  outline: "none",
                }}
              />
            </div>
          )}
          <div className="d-flex justify-content-center" style={{ gap: "8px" }}>
            <Input
              placeholder="Tìm kiếm hội thoại"
              onChange={(e) => setSearchKey(e.target.value)}
              prefix={<SearchOutlined />}
              ref={inputSearchRef}
            />
            {/* <Button type="primary" onClick={handleSearchMember}>
              Tìm kiếm
            </Button> */}
          </div>
          <div className="room-chat-list" style={{ height: fullScreen ? "550px": "350px"}}>
            <div>
              <p style={{ fontWeight: "600", marginTop: "10px" }}>
                Trò chuyện gần đây
              </p>
              {searchRoomRecentlyList && searchRoomRecentlyList.length ? (
                searchRoomRecentlyList.map((room, index) => {
                  if (
                    (modalSelectUser.type === typeModal.CREATE_GROUP ||
                      modalSelectUser.type === typeModal.ADD_MEMBER) &&
                    room?.is_group
                  )
                    return null;
                  const checkUserWasInRoom = () => {
                    const userId = room.users.find(
                      (item) => item.id !== profile.id
                    )?.id;

                    if (modalSelectUser.type === typeModal.CREATE_GROUP) {
                      if (modalSelectUser.data === userId) return true;
                    }
                    if (modalSelectUser.type === typeModal.ADD_MEMBER) {
                      return roomSelected &&
                        roomSelected.users.length &&
                        roomSelected.users.find((item) => item.id === userId)
                          ?.id
                        ? true
                        : false;
                    }
                    return false;
                  };
                  return (
                    <div key={index} onClick={() => handleSelectRoom(room)}>
                      <RoomChatItem
                        room={room}
                        wasInRoom={checkUserWasInRoom()}
                      />
                    </div>
                  );
                })
              ) : (
                <p>Không tìm thấy cuộc trò chuyện nào gần đây</p>
              )}
            </div>
            {modalSelectUser?.type !== typeModal.ADD_USER_TO_GROUP && (
              <div>
                <p style={{ fontWeight: "600", marginTop: "10px" }}>Liên hệ</p>
                {searchMemberList && searchMemberList.length ? (
                  searchMemberList.map((user, index) => {
                    if (profile?.id === user.id) return null;
                    const checkUserWasInRoom = () => {
                      if (modalSelectUser.type === typeModal.ADD_MEMBER) {
                        return roomSelected &&
                          roomSelected.users.length &&
                          roomSelected.users.find((item) => item.id === user.id)
                            ?.id
                          ? true
                          : false;
                      }
                      return false;
                    };
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          !checkUserWasInRoom() ? handleSelectUser(user) : {}
                        }
                      >
                        <RoomChatItem
                          room={user}
                          wasInRoom={checkUserWasInRoom()}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p>Không có liên hệ nào</p>
                )}
              </div>
            )}
          </div>
          {modalSelectUser?.type === typeModal.FORWARD && (
            <div
              style={{
                borderTop: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
                padding: "4px",
              }}
            >
              <p style={{ fontWeight: "600" }}>Nội dung chia sẻ</p>
              <p>{handleShowTypeMessage(modalSelectUser?.data.content, modalSelectUser?.data.type)}</p>
            </div>
          )}
          {!configInitByType().hideFooter && (
            <div
              className="d-flex justify-content-end"
              style={{ gap: "8px", marginTop: "8px" }}
            >
              <Button onClick={handleCloseModal}>Hủy</Button>
              <Button
                type="primary"
                onClick={configInitByType()?.onConfirm}
                disabled={handleDisableConfirmButton()}
              >
                {configInitByType()?.confirmButton}
              </Button>
            </div>
          )}
        </div>
      </StyledModalSelectUser>
    );
  };
  if (!modalSelectUser.isOpen) return null;
  return (
    <div>
      <Modal
        title={configInitByType()?.title}
        open={modalSelectUser.isOpen}
        onCancel={handleCloseModal}
        getContainer={"#box-chat-container"}
        styles={{ padding: "16px", height: fullScreen ? "1000px" : "500px" }}
        footer={null}
        centered={true}
      >
        <Spin spinning={modalSelectUser.loading}>{renderContentModal()}</Spin>
      </Modal>
    </div>
  );
}
