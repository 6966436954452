import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { staffsServices } from "../../../services/staffsServices";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../helpers";
import { constants } from "../../../constants";

const HistoryReviewItem = () => {
  const [historyReviewStaff, setHistoryReviewStaff] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);
  const { id } = useParams();

  const fetchHistory = async () => {
    try {
      const response = await staffsServices.fetchHistoryReviewStaff(areaId, id);
      setHistoryReviewStaff(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchHistory();
  }, [areaId]);

  return (
    <div className="table-responsive">
      <div id="dataTable_wrapper">
        <table
          className="table no-footer dataTable"
          id="dataTable"
          width="100%"
          cellSpacing={0}
          aria-describedby="dataTable_info"
        >
          <thead>
            <tr>
              <th>STT</th>
              <th className="text-center">Tên nhân viên</th>
              <th>Vị trí</th>
              <th>Ngày đánh giá</th>
              <th>Số sao</th>
              <th>Phản hồi</th>
            </tr>
          </thead>
          <tbody>
            {historyReviewStaff?.data?.map((history, index) => {
              return (
                <tr key={history?.id}>
                  <td>{index + 1}</td>
                  <td>{history?.staff?.name}</td>
                  <td>{history?.staff?.position}</td>
                  <td>
                    {formatDate(
                      history?.created_at,
                      constants.FORMAT_DD_MM_YYYY
                    )}
                  </td>
                  <td>{history?.star}</td>
                  <td>{history?.feedback}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryReviewItem;
