import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectPicker } from "rsuite";
import moment from "moment";
import { MdOutlineClear } from "react-icons/md";
import { BiFilterAlt } from "react-icons/bi";
import { HiFilter } from "react-icons/hi";
import EmployeeFormModal from "../Procedure/FormPage/child/EmployeeFormModal";
import { constants } from "../../constants";
import ViewDate from "../../components/ViewDate";
import PermissionChecker from "../../components/PermissionChecker";
import HistoryItem from "./child/HistoryItem";
import { historySysServices } from "../../services/historySystems";

const HistorySystemsPage = () => {
  const [listHistory, setListHistory] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [listIncidentSelected, setListIncidentSelected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    search: "",
    action: "",
    init: true,
    page: 1,
    type: "",
    from_time: "",
    to_time: "",
  });

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);
  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      from_time: getFirstAndLastDayInMonth().firstDay,
      to_time: getFirstAndLastDayInMonth().lastDay,
      init: true,
    }));
  }, []);

  useEffect(() => {
    if (filter.init !== true) {
      getHistorySystems();
    }
  }, [filter]);

  const getHistorySystems = async (isFunctionLoad) => {
    let date_from = moment(filter.from_time).format(
      constants.FORMAT_YYYY_MM_DD
    );
    let date_to = moment(filter.to_time).format(constants.FORMAT_YYYY_MM_DD);

    const queryString = `?search=${filter.search ?? ""}&type=${
      filter.type ?? ""
    }&type_child=${
      filter.type_child ?? ""
    }&action=${filter.action ?? ""}&staff_action_id=${
      filter.staff_action?.id ?? ""
    }&from_time=${date_from ?? ""}&to_time=${date_to ?? ""}&page=${page ?? ""}`;

    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await historySysServices.getHistorySystems({
        param: queryString,
      });

      if (response.code != 200) {
        return;
      }
      if (response.data.next_page_url === null) {
        console.log("het page");
        setHasmore(false);
      } else {
        console.log("con page");
        setPage(page + 1);
        setHasmore(true);
      }

      if (isFunctionLoad === true) {
        setListHistory((data) => [...data, ...response.data.data]);
      } else {
        setListHistory(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    process: null,
    indexInput: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Lịch sử hệ thống
        </h4>
      </div>
      <div style={{ height: "15px" }}></div>
      <div className="d-flex  align-items-center">
        <SelectPicker
          style={{ width: "180px" }}
          searchable={false}
          data={[
            {
              label: "Tạo mới",
              value: "create",
            },
            {
              label: "Chỉnh sửa",
              value: "update",
            },
            {
              label: "Xoá",
              value: "delete",
            },
          ]}
          placeholder="-- Chọn hành động --"
          block
          value={filter.departmentId}
          onChange={(e) => {
            setPage(1);
            setFilter({
              action: e,
              type_child: filter.type_child,
              staff_action: filter.staff_action,
              type: filter.type,
              from_time: filter.from_time,
              to_time: filter.to_time,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <SelectPicker
          style={{ width: "200px" }}
          data={[
            {
              label: "Nhân viên",
              value: "staff",
            },
            {
              label: "Bảng lương",
              value: "salary",
            },
            {
              label: "Lịch làm việc",
              value: "work_calendar",
            },
            {
              label: "Thông tin/Biên bản",
              value: "incident",
            },
            {
              label: "Yêu cầu",
              value: "procedure",
            },
            {
              label: "Hệ thống",
              value: "system",
            },
          ]}
          placeholder="-- Kiểu --"
          value={filter.type}
          searchable={false}
          onChange={(e) => {
            setPage(1);
            setFilter({
              action: filter.action,
              type_child: filter.type_child,
              staff_action: filter.staff_action,
              type: e,
              from_time: filter.from_time,
              to_time: filter.to_time,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        {filter.type == "system" ? (
          <SelectPicker
            style={{ width: "180px", marginRight: "10px" }}
            searchable={false}
            data={[
              {
                label: "Phân quyền",
                value: "role",
              },
              {
                label: "Chi nhánh",
                value: "area",
              },
              {
                label: "Phòng ban",
                value: "department",
              },
              {
                label: "Ca làm việc",
                value: "shift",
              },
              
              {
                label: "Cấu hình thưởng",
                value: "bonus_config",
              },
              {
                label: "Cài đặt Phụ cấp",
                value: "support_config",
              },
            ]}
            placeholder="-- Chọn kiểu --"
            block
            value={filter.type_child}
            onChange={(e) => {
              setPage(1);
              setFilter({
                action: filter.action,
                type_child: e,
                staff_action: filter.staff_action,
                type: filter.type,
                from_time: filter.from_time,
                to_time: filter.to_time,
              });
            }}
          />
        ) : (
          <></>
        )}
        {filter.type == "procedure" ? (
          <SelectPicker
            style={{ width: "180px", marginRight: "10px" }}
            searchable={false}
            data={[
              {
                label: "Cấu hình yêu cầu",
                value: "config",
              },
              {
                label: "Cài đặt quy trình",
                value: "process",
              },
            ]}
            placeholder="-- Chọn kiểu --"
            block
            value={filter.type_child}
            onChange={(e) => {
              setPage(1);
              setFilter({
                action: filter.action,
                type_child: e,
                staff_action: filter.staff_action,
                type: filter.type,
                from_time: filter.from_time,
                to_time: filter.to_time,
              });
            }}
          />
        ) : (
          <></>
        )}
        <ViewDate
          className="d-flex justify-content-between"
          type={constants.MONTH}
          handleChange={(date) => {
            setPage(1);
            setFilter({
              action: filter.action,
              staff_action: filter.staff_action,
              type: filter.type,
              type_child: filter.type_child,
              from_time: date.startDate,
              to_time: date.endDate,
            });
          }}
        />
        {listIncidentSelected.length >= 1 ? (
          PermissionChecker({
            decent: reportNumber.permissions?.incident_delete ?? false,
            child: (
              <button
                onClick={() => {
                  setModal({
                    title: `Bạn có chắc chắn muốn xoá ${listIncidentSelected.length} biên bản đã chọn chứ ?`,
                    method: "DELETE",
                    visible: true,
                  });
                }}
                className="button-handle"
              >
                {`Xoá (${listIncidentSelected.length})`}
              </button>
            ),
          })
        ) : (
          <></>
        )}
      </div>
      <div className="mb-2 pt-4">
        <div style={{ width: "10px" }}></div>
        <div
          id="scrollableDiv"
          style={{
            height: `${(window.innerHeight * 79) / 100}px`,
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={listHistory.length}
            next={() => {
              getHistorySystems(true);
            }}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table>
              <colgroup>
                <col style={{ width: "50px", minWidth: "50px" }} />
                <col
                  style={{
                    width: "15%",
                    minWidth: "100px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "150px",
                  }}
                />
                <col
                  style={{
                    width: "30%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "20%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "20%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "150px",
                  }}
                />
              </colgroup>
              <thead
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  padding: "10px !important",
                }}
              >
                <tr style={{ backgroundColor: "#f2f7ff", height: "50px" }}>
                  <th style={{ textAlign: "start" }}>
                    <span>STT</span>
                  </th>
                  <th>
                    <div className="d-flex flex-column">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => {
                          setModal({
                            title: ``,
                            method: "CREATE_FILTER",
                            visible: true,
                          });
                        }}
                      >
                        <div>Người chỉnh sửa</div>
                        {(filter?.staff_action?.name ?? "") !== "" ? (
                          <HiFilter color="blue"></HiFilter>
                        ) : (
                          <BiFilterAlt></BiFilterAlt>
                        )}
                      </div>

                      {(filter?.staff_action?.name ?? "") !== "" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "rgb(50 136 255)",
                            color: "white",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            maxWidth: "200px",
                          }}
                        >
                          <div>{filter.staff_action?.name ?? ""}</div>
                          <div
                            style={{
                              width: "10px",
                            }}
                          ></div>
                          <MdOutlineClear
                            onClick={(e) => {
                              setPage(1);
                              setFilter({
                                action: filter.action,
                                staff_action: null,
                                type: filter.type,
                                type_child: filter.type_child,
                                from_time: filter.from_time,
                                to_time: filter.to_time,
                              });
                            }}
                          ></MdOutlineClear>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                  <th>
                    <span>Hành động</span>
                  </th>
                  <th>
                    <span>Tiêu đề</span>
                  </th>
                  <th>
                    <span>Data cũ</span>
                  </th>
                  <th>
                    <span>Data mới</span>
                  </th>

                  <th>
                    <span>Thời gian</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listHistory.map((e, index) => {
                  return (
                    <HistoryItem
                      key={index}
                      data={e}
                      index={index}
                      onClickStaffRelate={(staff) => {
                        setPage(1);
                        setFilter({
                          action: filter.action,
                          staff_action: staff,
                          type: filter.type,
                          type_child: filter.type_child,
                          from_time: filter.from_time,
                          to_time: filter.to_time,
                        });
                      }}
                      onClickStaffViewer={(staff) => {
                        // getHistorySystems({
                        //   reset: true,
                        //   status: filter.status,
                        //   pageInput: 1,
                        //   type: filter.type,
                        //   from_time: filter.from_time,
                        //   to_time: filter.to_time,
                        //   list_id_staff_create: filter.list_id_staff_create,
                        //   list_id_staff_relate: filter.list_id_staff_relate,
                        //   list_id_staff_viewer: filter.list_id_staff_viewer,
                        //   list_department_id: filter.list_department_id,
                        // });
                      }}
                      accept={() => {
                        // acceptOrCancel({ formId: e.id, isApprove: true });
                      }}
                      cancel={() => {
                        // acceptOrCancel({ formId: e.id, isApprove: false });
                      }}
                    ></HistoryItem>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <EmployeeFormModal
        visible={
          modal.visible &&
          (modal.method === "CREATE_FILTER" || modal.method === "RELATE_FILTER")
        }
        departmentsInput={departments}
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleCloseModal}
        handleChoice={(staff) => {
          if (modal.method === "CREATE_FILTER") {
            setPage(1);
            setFilter({
              action: filter.action,
              staff_action: staff,
              type: filter.type,
              type_child: filter.type_child,
              from_time: filter.from_time,
              to_time: filter.to_time,
            });
          } else {
            setPage(1);
            setFilter({
              action: filter.action,
              staff_action: staff,
              type: filter.type,
              type_child: filter.type_child,
              from_time: filter.from_time,
              to_time: filter.to_time,
            });
            // getHistorySystems({
            //   reset: true,
            //   status: filter.status,
            //   pageInput: 1,
            //   type: filter.type,
            //   from_time: filter.from_time,
            //   to_time: filter.to_time,
            // });
            // setFilter({ ...filter, staff_relate: staff });
          }

          handleCloseModal();
        }}
      ></EmployeeFormModal>
    </div>
  );
};

export default HistorySystemsPage;
