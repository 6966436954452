import styled from "styled-components";

export const StyledRoomChat = styled.div`
  width: 100%;
  flex: 1;
  height: 510px;
  position: relative;
  display: flex;
  flex-direction: column;
  .view-chat-container {
    // position: relative;
    padding: 16px;
    padding-bottom: 24px;
    background-color: #eef0f1;
    flex: 1;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      border-radius: 10px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d1e3f9;
      opacity: 0.5;
    }
    .message-content.highlight {
      animation: highlight 4s ease;
    }
    .infinite-scroll-component {
      max-width: 1000px;
      margin: 0 auto;
    }

    @keyframes highlight {
      0% {
        background-color: yellow;
      }
      100% {
        background-color: none !important;
      }
    }
    display: flex;
    flex-direction: column-reverse;
    // gap: 16px;
  }
  .list-pin-message {
    .message {
      padding: 6px 10px;
      display: flex;
      gap: 10px;
      align-items: center;
      border-radius: 8px;
      &:hover {
        background-color: #f3f5f6;
        cursor: pointer;
      }
      &__avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .input-chat-container {
    position: relative;
    width: 100%;
    // align-items: center;
    .message-reply {
      margin: 4px 16px;
      padding: 0 8px;
      padding-left: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      .message__name {
        font-weight: 600;
        color: #3390ec;
      }
      .message__content {
        margin-top: 0;
      }
    }
    .input-chat {
      width: 100%;
      border-top: 1px solid #ccc;
      padding: 16px;
      padding-right: 140px;
      max-height: 176px;
      overflow-y: scroll;
      word-wrap: break-word;
      &:focus {
        outline: none;
        border-top: 1px solid #4e73df;
      }
      img {
        height: 72px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #ccc;
        margin-bottom: 16px;
      }
    }
    .input-chat-action {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 16px;
      bottom: -8px;
      transform: translateY(-50%);
      align-items: center;
      &__send {
        color: #0068ff;
        font-weight: 600;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #e1e4ea;
        }
        &.disabled {
          color: #ccc;
          cursor: not-allowed;
        }
      }
      &__image {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        justify-content: center;
        align-items: center;
        display: flex;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .dropzone {
    .dropzone--isActive {
      position: absolute;
      inset: 0;
      background-color: #000;
      z-index: 100;
      opacity: 0.2;
    }
  }
  .popup-tag {
    .item {
      padding: 8px 12px;
      display: flex;
      gap: 12px;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: #f3f5f6;
      }
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .text-truncate-1 {
    display: block;
    // display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 280px;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
  }
`;
