import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  info: {
    data: {},
    status: c.NONE,
  },

  questions: {
    data: [],
    status: c.NONE,
  },

  staff_exams: {
    data: [],
    status: c.NONE,
  },

  detail_answer: {
    data: [],
    status: c.NONE,
  },
};
export function quiz(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_QUIZS_SUCCESS:
      return {
        ...state,
        list: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_STAFF_EXAMS_SUCCESS:
      return {
        ...state,
        staff_exams: {
          ...action.data,
          status: c.SUCCESS,
        },
      };

      case c.GET_ALL_DETAIL_ANSWER_SUCCESS:
      return {
        ...state,
        detail_answer: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.RESET_ALL_DETAIL_ANSWER_STATUS:
      return {
        ...state,
        detail_answer: {
          data: [],
          status: c.NONE,
        },
      };

    case c.RESET_ALL_STAFF_EXAMS_STATUS:
      return {
        ...state,
        staff_exams: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: {
          data: action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_ALL_QUESTIONS_STATUS:
      return {
        ...state,
        questions: {
          data: [],
          status: c.NONE,
        },
      };
    case c.RESET_QUIZ_STATUS:
      return {
        ...state,
        info: {
          status: c.NONE,
        },
      };
    case c.RESET_QUIZS_LIST_STATUS:
      return {
        ...state,
        list: {
          data: [],
          status: c.NONE,
        },
      };

    case c.GET_QUIZ_SUCCESS:
      return {
        ...state,
        info: {
          data: action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_QUIZS_FAILURE:
      return {
        ...state,
        list: {
          status: c.FAILURE,
        },
      };
    case c.GET_QUIZ_FAILURE:
      return {
        ...state,
        info: {
          data: {},
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
