import React, { useState, useEffect } from "react";
import "./style.css";
import { Tab, Tabs, TabList } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import { procedureServices } from "../../../services/procedureServices";
import FormItem from "./child/FormItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { RiArrowDropDownLine } from "react-icons/ri";
import SearchInput from "../../../components/SeachInput";
import { SelectPicker } from "rsuite";
import SearchInputForm from "./child/SearchInputForm";
import DialogYesNo from "../child/DialogYesNo";
import { shiftServices } from "../../../services/shiftServices";
import ViewDate from "../../../components/ViewDate";
import moment from "moment";
import FormModal from "./child/FormModal";
import { MdOutlineClear } from "react-icons/md";
import { BiFilterAlt } from "react-icons/bi";
import { HiFilter } from "react-icons/hi";
import EmployeeFormModal from "./child/EmployeeFormModal";
import { toast } from "react-toastify";
import { probationServices } from "../../../services/probationServices";

const FormProbationPage = () => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [listForm, setListForm] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [badgeType, setBadgeType] = useState(null);
  const [listFormSelected, setListFormSelected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    search: "",
    status: -1,
    type: "",
    from: "",
    to: "",
    init: true,
    followDate: 1,
    departmentId: null,
    approveStaff: null,
    viewerStaff: null,
    staff: null,
  });

  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);
  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      from: getFirstAndLastDayInMonth().firstDay,
      to: getFirstAndLastDayInMonth().lastDay,
    }));
    fetchDepartment();
  }, []);

  useEffect(() => {
    if (filter.init !== true) {
      getForm();
    } else {
      getBadgetInit();
    }
  }, [filter]);

  const getForm = async (isFunctionLoad) => {
    let date_from = moment(filter.from)
      .startOf("day")
      .format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS);
    let date_to = moment(filter.to)
      .endOf("day")
      .format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS);

    const queryString = `?search=${filter.search ?? ""}&status=${
      filter.status === -1 ? "" : filter.status ?? ""
    }&type=${filter.type ?? ""}&${
      filter.followDate === 0
        ? `from=${date_from ?? ""}&to=${date_to ?? ""}`
        : `created_from=${date_from ?? ""}&created_to=${date_to ?? ""}`
    }&page=${page ?? ""}&deparment_id=${filter.departmentId ?? ""}&area_id=${
      filter.areaId == constants.AREA_ALL_ID ? "" : filter.areaId ?? ""
    }&approver_id=${filter.approveStaff?.id ?? ""}&viewer_id=${
      filter.viewerStaff?.id ?? ""
    }&staff_id=${filter.staff?.id ?? ""}`;

    try {
      var response;
      if (filter.status == -1) {
        response = await probationServices.getFormProbation({
          param: queryString,
        });
      } else {
        response = await probationServices.getForm({ param: queryString });
        setBadgeType({
          count_approve: response?.count_approve ?? 0,
          count_my_form: response?.count_my_form ?? 0,
          count_pending: response?.count_pending ?? 0,
          count_reject: response?.count_reject ?? 0,
        });
      }

      if (response.code != 200) {
        return;
      }
      if (response.data.next_page_url === null) {
        console.log("het page");
        setHasmore(false);
      } else {
        console.log("con page");
        setPage(page + 1);
        setHasmore(true);
      }

      if (isFunctionLoad === true) {
        setListForm((data) => [...data, ...response.data.data]);
      } else {
        setListForm(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getBadgetInit = async (isFunctionLoad) => {
    let date_from = moment(filter.from)
      .startOf("day")
      .format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS);
    let date_to = moment(filter.to)
      .endOf("day")
      .format(constants.FORMAT_YYYY_MM_DD_HH_MM_SS);

    const queryString = `?search=${filter.search ?? ""}&status=${
      filter.status === -1 ? "" : filter.status ?? ""
    }&type=${filter.type ?? ""}&${
      filter.followDate === 0
        ? `from=${date_from ?? ""}&to=${date_to ?? ""}`
        : `created_from=${date_from ?? ""}&created_to=${date_to ?? ""}`
    }&page=${page ?? ""}&deparment_id=${filter.departmentId ?? ""}&area_id=${
      filter.areaId == constants.AREA_ALL_ID ? "" : filter.areaId ?? ""
    }&approver_id=${filter.approveStaff?.id ?? ""}&viewer_id=${
      filter.viewerStaff?.id ?? ""
    }&staff_id=${filter.staff?.id ?? ""}`;

    try {
      var response;
      response = await probationServices.getForm({ param: queryString });
      setBadgeType({
        count_approve: response?.count_approve ?? 0,
        count_my_form: response?.count_my_form ?? 0,
        count_pending: response?.count_pending ?? 0,
        count_reject: response?.count_reject ?? 0,
      });

      if (response.code != 200) {
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    process: null,
    indexInput: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const deleteForm = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.deleteForm({
        listFormId: {
          list_form_id: listFormSelected.map((e) => e.id),
        },
      });
      if (response.code != 200) {
        return;
      }
      setListFormSelected([]);
      setPage(1);
      setFilter({
        search: filter.search,
        status: filter.status,
        type: filter.type,
        reset: true,
        from: filter.from,
        to: filter.to,
        areaId: filter.areaId,
        departmentId: filter.departmentId,
        followDate: filter.followDate,
        approveStaff: filter.approveStaff,
        viewerStaff: filter.viewerStaff,
        staff: filter.staff,
      });
      handleCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const acceptOrCancel = async ({ formId, isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.acceptOrCancel({
        formId: formId,
        isApprove: {
          is_approve: isApprove,
        },
      });
      if (response.code != 200) {
        return;
      }
      setListFormSelected([]);
      setPage(1);
      setFilter({
        search: filter.search,
        status: filter.status,
        pageInput: 1,
        type: filter.type,
        reset: true,
        from: filter.from,
        areaId: filter.areaId,
        to: filter.to,
        departmentId: filter.departmentId,
        followDate: filter.followDate,
        approveStaff: filter.approveStaff,
        viewerStaff: filter.viewerStaff,
        staff: filter.staff,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const acceptOrCancelMany = async ({ isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.acceptOrCancelMany({
        data: {
          is_approve: isApprove,
          list_form_id: listFormSelected.map((e) => e.id),
        },
      });
      if (response.code != 200) {
        return;
      }
      setListFormSelected([]);
      setPage(1);
      setFilter({
        search: filter.search,
        status: filter.status,
        pageInput: 1,
        type: filter.type,
        reset: true,
        from: filter.from,
        to: filter.to,
        areaId: filter.areaId,
        departmentId: filter.departmentId,
        followDate: filter.followDate,
        approveStaff: filter.approveStaff,
        viewerStaff: filter.viewerStaff,
        staff: filter.staff,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await shiftServices.fetchAllDepartments();
      const result = response.data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setDepartments(result);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAdvanceSalary = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      let month = moment(filter.from);

      console.log(moment(filter.from).format(constants.FORMAT_YYYY_MM_DD));
      console.log(month.month());
      console.log(month.year());

      const response = await procedureServices.exportAdvanceSalary({
        param: `?month=${month.month() + 1}&year=${month.year()}`,
      });

      if (response.success) {
        const link = response.data?.link;
        const tagA = document.createElement("a");
        tagA.href = link;
        tagA.download = "advance_salary.xlsx";
        tagA.click();
        toast.success(response.msg);
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      let msg = "Lỗi khi export bảng lương";
      toast.error(msg);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Xử lý đánh giá thử việc
        </h4>
      </div>
      <div style={{ height: "15px" }}></div>
      <div className="d-flex  align-items-center">
        <SelectPicker
          style={{ width: "180px" }}
          data={areas?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder="-- Chọn chi nhánh --"
          block
          value={filter.areaId}
          onChange={(e) => {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              reset: true,
              from: filter.from,
              to: filter.to,

              departmentId: filter.departmentId,
              areaId: e,
              followDate: filter.followDate,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.viewerStaff,
              staff: filter.staff,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <SelectPicker
          style={{ width: "180px" }}
          data={departments}
          placeholder="-- Chọn phòng ban --"
          block
          value={filter.departmentId}
          onChange={(e) => {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              pageInput: 1,
              areaId: filter.areaId,
              type: filter.type,
              reset: true,
              from: filter.from,
              to: filter.to,
              departmentId: e,
              followDate: filter.followDate,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.viewerStaff,
              staff: filter.staff,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <SelectPicker
          style={{ width: "200px" }}
          data={[
            {
              label: "Theo ngày gửi",
              value: 1,
            },
            {
              label: "Theo ngày yêu cầu",
              value: 0,
            },
          ]}
          value={filter.followDate}
          cleanable={false}
          searchable={false}
          onChange={(e) => {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              from: filter.from,
              to: filter.to,
              areaId: filter.areaId,
              departmentId: filter.departmentId,
              followDate: e,
              reset: true,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.viewerStaff,
              staff: filter.staff,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <ViewDate
          className="d-flex justify-content-between"
          type={constants.MONTH}
          handleChange={(date) => {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              areaId: filter.areaId,
              from: date.startDate,
              to: date.endDate,
              departmentId: filter.departmentId,
              followDate: filter.followDate,
              reset: true,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.viewerStaff,
              staff: filter.staff,
            });
          }}
        />
        {listFormSelected.length !== 0 && (
          <div class="dropdown">
            <button className="button-handle">
              <span className="text">{`Đã chọn ${listFormSelected.length}`}</span>
              <RiArrowDropDownLine></RiArrowDropDownLine>
            </button>
            <div class="dropdown-menu">
              <button
                onClick={() => {
                  acceptOrCancelMany({ isApprove: true });
                }}
                className="btn-sm dropdown-item"
              >
                Chấp thuận
              </button>
              <button
                onClick={() => {
                  acceptOrCancelMany({ isApprove: false });
                }}
                className="btn-sm dropdown-item"
              >
                Từ chối
              </button>
              <button
                onClick={() => {
                  if ((reportNumber.permissions?.is_admin ?? false) == true) {
                    setModal({
                      title: `Bạn có chắc chắn muốn xoá ${listFormSelected.length} yêu cầu này chứ ?`,
                      method: "DELETE",
                      visible: true,
                    });
                  } else {
                    toast.message("Bạn không có quyền xoá");
                  }
                }}
                className="btn-sm dropdown-item"
              >
                Xoá
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mb-2 pt-4">
        <Tabs
          defaultIndex={0}
          onSelect={(index) => {
            setPage(1);
            setFilter({
              search: filter.search,
              status: index === 0 ? -1 : index === 1 ? 1 : index === 2 ? 2 : 0,
              pageInput: 1,
              reset: true,
              areaId: filter.areaId,
              type: filter.type,
              from: filter.from,
              to: filter.to,
              departmentId: filter.departmentId,
              followDate: filter.followDate,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.viewerStaff,
              staff: filter.staff,
            });
          }}
        >
          <TabList style={{ marginBottom: "0px" }}>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Cần đánh giá`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Yêu cầu (${badgeType?.count_pending ?? 0})`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Chấp thuận (${badgeType?.count_approve ?? 0})`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Từ chối (${badgeType?.count_reject ?? 0})`}</Tab>
          </TabList>
        </Tabs>
        <div style={{ width: "10px" }}></div>
        <div
          id="scrollableDiv"
          style={{
            height: `${(window.innerHeight * 75) / 100}px`,
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={listForm.length}
            next={() => {
              getForm(true);
            }}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table>
              <colgroup>
                <col style={{ width: "20px", minWidth: "20px" }} />
                <col style={{ width: " 50px", minWidth: "50px" }} />
                <col
                  style={{
                    width: "17%",
                    minWidth: "200px",
                  }}
                />
                <col
                  style={{
                    width: "30%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "25%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "150px",
                  }}
                />
                <col
                  style={{
                    width: "15%",
                    minWidth: "150px",
                  }}
                />
              </colgroup>
              <thead
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  padding: "10px !important",
                }}
              >
                <tr style={{ backgroundColor: "#f2f7ff", height: "50px" }}>
                  <th style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={listForm.length === listFormSelected.length}
                      onClick={(e) => {
                        if (listForm.length === listFormSelected.length) {
                          setListFormSelected([]);
                        } else {
                          setListFormSelected(listForm);
                        }
                      }}
                    />
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <span>STT</span>
                  </th>
                  <th>
                    <div className="d-flex flex-column">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => {
                          setModal({
                            title: ``,
                            method: "EMP",
                            visible: true,
                          });
                        }}
                      >
                        <div>Người yêu cầu</div>
                        {filter?.staff !== null ? (
                          <HiFilter color="blue"></HiFilter>
                        ) : (
                          <BiFilterAlt></BiFilterAlt>
                        )}
                      </div>

                      {filter?.staff !== null ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "rgb(50 136 255)",
                            color: "white",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            width: "200px",
                          }}
                        >
                          <div>{filter.staff?.name ?? ""}</div>
                          <div
                            style={{
                              width: "10px",
                            }}
                          ></div>
                          <MdOutlineClear
                            onClick={(e) => {
                              setPage(1);
                              setFilter({
                                search: filter.search,
                                status: filter.status,
                                pageInput: 1,
                                areaId: filter.areaId,
                                type: filter.type,
                                reset: true,
                                from: filter.from,
                                to: filter.to,
                                departmentId: filter.departmentId,
                                followDate: filter.followDate,
                                approveStaff: filter.approveStaff,
                                viewerStaff: filter.viewerStaff,
                                staff: null,
                              });
                            }}
                          ></MdOutlineClear>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                  <th>
                    <span>Thông tin yêu cầu</span>
                  </th>

                  <th>
                    <div className="d-flex flex-column">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => {
                          setModal({
                            title: ``,
                            method: "APR",
                            visible: true,
                          });
                        }}
                      >
                        <div>Người nhận</div>
                        {filter?.approveStaff !== null ||
                        filter?.viewerStaff !== null ? (
                          <HiFilter color="blue"></HiFilter>
                        ) : (
                          <BiFilterAlt></BiFilterAlt>
                        )}
                      </div>

                      {filter?.approveStaff !== null ||
                      filter?.viewerStaff !== null ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "rgb(50 136 255)",
                            color: "white",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                          }}
                        >
                          {filter.approveStaff?.name !== null ? (
                            <div>{filter.approveStaff?.name ?? ""}</div>
                          ) : (
                            <></>
                          )}
                          {filter.viewerStaff?.name !== null ? (
                            <div>{filter.viewerStaff?.name ?? ""}</div>
                          ) : (
                            <></>
                          )}
                          <div
                            style={{
                              width: "10px",
                            }}
                          ></div>
                          <MdOutlineClear
                            onClick={(e) => {
                              setPage(1);
                              setFilter({
                                search: filter.search,
                                status: filter.status,
                                pageInput: 1,
                                type: filter.type,
                                areaId: filter.areaId,
                                reset: true,
                                from: filter.from,
                                to: filter.to,
                                departmentId: filter.departmentId,
                                followDate: filter.followDate,
                                approveStaff: null,
                                viewerStaff: null,
                                staff: filter.staff,
                              });
                            }}
                          ></MdOutlineClear>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                  <th style={{ textAlign: "right" }}>
                    <span>Ngày gửi</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listForm.map((e, index) => {
                  return (
                    <FormItem
                      key={index}
                      data={e}
                      index={index}
                      isSelected={listFormSelected
                        .map((e) => e.id)
                        .includes(e.id)}
                      onChoose={() => {
                        if (listFormSelected.find((item) => item.id === e.id)) {
                          const newList = listFormSelected.filter(
                            (item) => item.id !== e.id
                          );
                          setListFormSelected(newList);
                        } else {
                          const newList = [...listFormSelected, e];
                          setListFormSelected(newList);
                        }
                      }}
                      onClick={(formId) => {
                        setModal({
                          title: ``,
                          method: "DETAIL",
                          visible: true,
                          formId: formId,
                        });
                      }}
                      onClickStaffApprove={(staff) => {
                        setPage(1);
                        setFilter({
                          search: filter.search,
                          status: filter.status,
                          areaId: filter.areaId,
                          pageInput: 1,
                          type: filter.type,
                          reset: true,
                          from: filter.from,
                          to: filter.to,
                          departmentId: filter.departmentId,
                          followDate: filter.followDate,
                          approveStaff: staff,
                          viewerStaff: null,
                          staff: filter.staff,
                        });
                      }}
                      onClickStaffViewer={(staff) => {
                        setPage(1);
                        setFilter({
                          search: filter.search,
                          status: filter.status,
                          areaId: filter.areaId,
                          pageInput: 1,
                          type: filter.type,
                          reset: true,
                          from: filter.from,
                          to: filter.to,
                          departmentId: filter.departmentId,
                          followDate: filter.followDate,
                          approveStaff: null,
                          viewerStaff: staff,
                          staff: filter.staff,
                        });
                      }}
                      accept={() => {
                        acceptOrCancel({ formId: e.id, isApprove: true });
                      }}
                      cancel={() => {
                        acceptOrCancel({ formId: e.id, isApprove: false });
                      }}
                    ></FormItem>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <DialogYesNo
        visible={modal.visible && modal.method === "DELETE"}
        title={modal.title}
        yes={(data) => {
          deleteForm();
        }}
        no={handleCloseModal}
      />
      <FormModal
        visible={modal.visible && modal.method === "DETAIL"}
        formId={modal?.formId}
        departmentsInput={departments}
        onSuccess={() => {
          setPage(1);
          setFilter({
            search: filter.search,
            status: filter.status,
            areaId: filter.areaId,
            pageInput: 1,
            type: filter.type,
            reset: true,
            from: filter.from,
            to: filter.to,
            departmentId: filter.departmentId,
            followDate: filter.followDate,
            approveStaff: filter.approveStaff,
            viewerStaff: filter.viewerStaff,
            staff: filter.staff,
          });
        }}
        closeModal={handleCloseModal}
      ></FormModal>
      <EmployeeFormModal
        visible={
          modal.visible && (modal.method === "EMP" || modal.method === "APR")
        }
        departmentsInput={departments}
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleCloseModal}
        handleChoice={(staff) => {
          if (modal.method === "EMP") {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              areaId: filter.areaId,
              reset: true,
              from: filter.from,
              to: filter.to,
              departmentId: filter.departmentId,
              followDate: filter.followDate,
              approveStaff: filter.approveStaff,
              viewerStaff: filter.approveStaff,
              staff: staff,
            });
          } else {
            setPage(1);
            setFilter({
              search: filter.search,
              status: filter.status,
              areaId: filter.areaId,
              pageInput: 1,
              type: filter.type,
              reset: true,
              from: filter.from,
              to: filter.to,
              departmentId: filter.departmentId,
              followDate: filter.followDate,
              approveStaff: staff,
              viewerStaff: filter.approveStaff,
              staff: filter.staff,
            });
          }
        }}
      ></EmployeeFormModal>
    </div>
  );
};

export default FormProbationPage;
