import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Col, Form, Row, Space, Avatar, Divider, Spin, Button } from "antd";
import { MdAccessTimeFilled } from "react-icons/md";
import "suneditor/dist/css/suneditor.min.css";
import { franchiseServices } from "../../../../services/franchiseServices";
import SunEditor from "suneditor-react";
import moment from "moment";

const MeetingModal = ({ open, closeModal, onOk, dataInput }) => {
  const [meetings, setMeetings] = useState(null);
  useEffect(() => {
    if (dataInput != null) {
      if (open == true) {
        setMeetings(null);
        getMeetingDetail(dataInput.id);
      }
    } else {
    }
  }, [open]);

  const getMeetingDetail = async (id) => {
    try {
      const response = await franchiseServices.getMeetingDetail(id);
      setMeetings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={"Thông tin lịch họp"}
      centered
      open={open}
      width={800}
      style={{ top: 10 }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div className="d-flex col-12 justify-content-center align-items-center mb-2">
        <p className="col-3">Danh sách tham gia:</p>
        <span className="col-9">
          {(meetings?.invited_staffs ?? [])
            .map((e) => {
              return e.name ?? "--:--";
            })
            .join(", ")}
        </span>
      </div>
      <div className="d-flex col-12 justify-content-center align-items-center mb-2">
        <p className="col-3">Ngày tham gia:</p>
        <span className="col-9">
          {moment(meetings?.date).format("DD-MM-YYYY HH:mm:ss")}
        </span>
      </div>
      <div className="d-flex col-12 justify-content-center align-items-center mb-2">
        <p className="col-3">Tiêu đề:</p>
        <span className="col-9">{meetings?.title ?? "--:--"}</span>
      </div>
      <div className="d-flex col-12 justify-content-center align-items-center mb-2">
        <p className="col-3">Nội dung:</p>
        {meetings == null ? (
          <div className="col-9">
            {" "}
            <Spin></Spin>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              marginTop: "10px",
              background: "#f5f5f5",
              padding: "20px",
              borderRadius: "10px",
            }}
            dangerouslySetInnerHTML={{
              __html: meetings?.content ?? "",
            }}
          ></div>
        )}
      </div>
      <div className="d-flex col-12 justify-content-center align-items-center mb-2">
        <p className="col-3">Lịch sử:</p>
        <div className="col-9">
          {(meetings?.history_meeting_actions ?? []).map((e) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                <MdAccessTimeFilled
                  color={
                    e?.type === 1 ? "green" : e?.type === 0 ? "#ddb410" : "grey"
                  }
                  className="mr-1"
                ></MdAccessTimeFilled>
                <span
                  className="mr-1"
                  style={{
                    color:
                      e?.type === 1
                        ? "green"
                        : e?.type === 0
                        ? "#ddb410"
                        : "grey",
                  }}
                >
                  {e.staff?.name ?? "--:--"}
                </span>
                <span
                  style={{
                    color:
                      e?.type === 1
                        ? "green"
                        : e?.type === 0
                        ? "#ddb410"
                        : "grey",
                  }}
                >
                  {e?.type === 0
                    ? ` đã xem`
                    : e?.type === 1
                    ? ` tham gia`
                    : e?.type === 2
                    ? ` không tham gia`
                    : ` không tham gia`}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default MeetingModal;
