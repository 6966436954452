/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch,useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { constants } from "../../constants";
import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import CreateProcedure from "./child/CreateProbation";
import DialogYesNo from "./child/DialogYesNo";
import PermissionChecker from "../../components/PermissionChecker";
import { probationServices } from "../../services/probationServices";

const StepProbation = ({ configProcedure }) => {
  const [config, setConfig] = useState(null);
  const dispatch = useDispatch();
  const [listProcedure, setListProcedure] = useState([]);
  const reportNumber = useSelector((state) => state.reportNumber.report);

  const {
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setConfig(configProcedure);
    getProcedure();
  }, [configProcedure]);

  const getProcedure = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.getProcedure({
        procedure_config_id: configProcedure?.id,
      });
      setListProcedure(response.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    componentContentModal: null,
    procedureId: null,
    procedureDeleteId: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const deleteProcedure = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await probationServices.deleteProcedure({
        id: id,
      });
      setModal({
        ...modal,
        title: "",
        method: "",
        visible: false,
        procedureDeleteId: null,
      });
      getProcedure();
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  return (
    <div className="pt-3">
      <div
        className="d-flex "
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="heading--underscore mb-3"
          style={{ fontSize: "18px", fontWeight: "700", color: "#10a0b5" }}
        >
          Quy trình duyệt
        </div>
        {PermissionChecker({
          decent: reportNumber.permissions?.probation_add ?? false,
          child: (
            <button
              type="button"
              style={{
                color: "white",
                backgroundColor: "#1ecc78",
                borderRadius: "10px",
                padding: "5px",
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
              onClick={() =>
                setModal({
                  title: `Thêm mới quy trình`,
                  method: "CREATE",
                  visible: true,
                  procedureId: null,
                })
              }
            >
              <BsPlusCircle />
              <span> </span>
              <span>Tạo mới</span>
            </button>
          ),
        })}
      </div>
      <div className="px-2">
        <table>
          <colgroup>
            <col style={{ width: "50px", minWidth: "50px" }} />
            <col style={{ width: "80%", minWidth: "50px" }} />
            <col style={{ width: "20%", minWidth: "50px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>STT</th>
              <th className>Tên</th>
              <th
                className="ant-table-align-right ant-table-row-cell-last"
                style={{ textAlign: "right" }}
              >
                <span className="ant-table-header-column">
                  <div>
                    <span className="ant-table-column-title" />
                    <span className="ant-table-column-sorter" />
                  </div>
                </span>
              </th>
            </tr>
          </thead>
          <div style={{ height: "10px" }}></div>
          {(listProcedure ?? []).map((e, index) => {
            return (
              <tr key={index} style={{ height: "50px", borderBottom:"1px solid #e6e6e6" }}>
                <td>{index + 1}</td>
                <td>
                  <div style={{ display: "flex" }}>{e.title}</div>
                </td>
                <td style={{ textAlign: "right" }}>
                  <span className="d-flex justify-content-end">
                    {PermissionChecker({
                      decent:
                        reportNumber.permissions?.probation_edit ??
                        false,
                      child: (
                        <button
                          type="button"
                          style={{
                            color: "white",
                            backgroundColor: "#1ecc78",
                            borderRadius: "10px",
                            padding: "5px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                          }}
                          onClick={() => {
                            setModal({
                              title: `Cập nhật quy trình`,
                              method: "UPDATE",
                              visible: true,
                              procedureId: e.id,
                            });
                          }}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            className
                            data-icon="edit"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" />
                          </svg>
                          <span> Sửa</span>
                        </button>
                      ),
                    })}
                    <span style={{width:'10px'}}> </span>
                    {PermissionChecker({
                      decent:
                        reportNumber.permissions?.probation_delete ??
                        false,
                      child: (
                        <button
                          type="button"
                          style={{
                            color: "white",
                            backgroundColor: "#ff3e61",
                            borderRadius: "10px",
                            padding: "5px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                          }}
                          onClick={() => {
                            setModal({
                              ...modal,
                              title: `Bạn có chắc chắn muốnn xoá quy trình này chứ ?`,
                              method: "DELETE-PROCEDURE",
                              visible: true,
                              procedureDeleteId: e.id,
                            });
                          }}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            className
                            data-icon="delete"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                          </svg>
                          <span> Xóa</span>
                        </button>
                      ),
                    })}
                  </span>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
      <CreateProcedure
        visible={
          (modal.visible && modal.method === "CREATE") ||
          modal.method === "UPDATE"
        }
        title={modal.title}
        procedureConfigId={configProcedure?.id}
        procedureId={modal?.procedureId}
        onSuccess={() => {
          getProcedure();
        }}
        closeModal={handleCloseModal}
        procedureConfigName={configProcedure?.title}
      ></CreateProcedure>
      <DialogYesNo
        visible={modal.visible && modal.method === "DELETE-PROCEDURE"}
        title={modal.title}
        yes={(data) => {
          deleteProcedure({ id: modal.procedureDeleteId });
        }}
        no={handleCloseModal}
        // onSubmit={handleSubmitStaffModal}
        // onSave={() => {
        //   convertData(dataTemp);
        // }}
      />
    </div>
  );
};

export default StepProbation;
