export const handleShowTypeMessage = (lastMessage, type) => {
  switch (type) {
    case "image":
      return "Hình ảnh";
    case "video":
      return "Video";
    case "custom":
      return "Tệp đính kèm";
    case "gif":
      return "Gif";
    default:
      return lastMessage;
  }
};

export const handleShowRoomNameAvatar = (room, profile) => {
  if (room?.is_group) {
    const roomName =
      room.name_room || room?.users?.map((user) => user.name).join(", ");
    return { name: roomName, avatar: room.room_avatar };
  } else {
    const user = room?.users?.filter((user) => user.id !== profile.id)[0];
    if(!user) {
      return { name: room.name, avatar: room.avatar, id: user?.id || room?.id}
    }
    const name = room?.name_room || user?.name;
    return { name: name, avatar: user?.avatar, id: user?.id };
  }
};
