/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  ConfigProvider,
} from "antd";
import viVN from "antd/locale/vi_VN";
import "suneditor/dist/css/suneditor.min.css";
import { franchiseServices } from "../../../../services/franchiseServices";
import SunEditor from "suneditor-react";
import dayjs from "dayjs";
import { AsyncPaginate } from "react-select-async-paginate";
import UploadImageSingle from "../../../../components/UploadImageSingle";
import { da } from "date-fns/locale";

const AddPostModal = ({ open, closeModal, onOk, dataInput }) => {
  const [contentText, setContentText] = useState(null);
  const [post, setPost] = useState(false);
  const [reset, setReset] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataInput != null) {
      onFill();
      if (open == true) {
        getPostDetail(dataInput.id);
      }
    } else {
      onReset();
    }
  }, [open]);

  const addPost = async (data) => {
    setLoading(true);
    try {
      const response = await franchiseServices.addPost(data);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updatePost = async (data, id) => {
    try {
      const response = await franchiseServices.updatePost(data, id);
      onOk();
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    values.content = contentText;
    values.image_url = imageUrl;
    if (dataInput != null) {
      updatePost(values, dataInput.id);
    } else {
      addPost(values);
    }
  };

  const onFill = () => {
    form.setFieldsValue({
      title: dataInput?.title ?? "",
      image_url: dataInput?.image_url ?? "",
      description: dataInput?.description ?? "",
    });
    setReset(false);
  };  

  const onReset = () => {
    setContentText(null);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 22,
      span: 28,
    },
  };

  const getPostDetail = async (id) => {
    setReset(true);
    try {
      const response = await franchiseServices.getPostDetail(id);
      setImageUrl(response.data?.image_url);
      setContentText(response.data?.content);
      onFill();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={dataInput?.id != null ? "Sửa bài đăng" : "Tạo bài đăng"}
      centered
      open={open}
      width={1000}
      style={{ top: 20 }}
      hildren={form}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        className="mt-4"
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "80vh",
          margin: "10px",
        }}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ width: "95%" }}
        >
          <Form.Item name="image_url" label="Ảnh:  " hasFeedback>
            <UploadImageSingle
              imageInput={imageUrl ?? null}
              title={"Ảnh"}
              onDone={(e) => {
                setImageUrl(e);
              }}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập tiêu đề"} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
            rules={[
              {
                required: true,
                message: "Không được để trống !",
              },
            ]}
          >
            <Input placeholder={"Nhập mô tả"} />
          </Form.Item>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[
              {
                required:
                  (contentText ?? "<p><br></p>") != "<p><br></p>" &&
                  (contentText ?? "") != ""
                    ? false
                    : true,
                message:
                  (contentText ?? "<p><br></p>") != "" &&
                  (contentText ?? "") != ""
                    ? "Không được để trống !"
                    : "Không được để trống !",
              },
            ]}
          >
            <div className="editor">
              {reset == false ? (
                <SunEditor
                  name="content"
                  showToolbar={true}
                  setDefaultStyle="height: auto"
                  defaultValue={contentText}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "fontColor",
                        "textStyle",
                        "outdent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "preview",
                      ],
                    ],
                  }}
                  onChange={(e) => {
                    console.log(e);
                    setContentText(e);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddPostModal;
