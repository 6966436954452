/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect } from "react";
import { useState, useCallback } from "react";

import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import moment from "moment";
import { MdAccessTimeFilled } from "react-icons/md";
import { procedureServices } from "../../../services/procedureServices";
import { toast } from "react-toastify";
import ImageViewer from "react-simple-image-viewer";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import { Tabs } from "antd";

Modal.setAppElement("#root");

const FORMAT_DATE = "MM-yyyy";
const IncidentModal = ({
  visible,
  incident,
  closeModal,
  departmentsInput,
  onSuccess,
}) => {
  const areas = useSelector((state) => state.area.areas);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [incidentRes, setincidentRes] = useState(null);

  useEffect(() => {
    if (visible == true) {
      console.log("Asdasdad", incident);
      console.log("SSSSS", departmentsInput);
      getIncidentDetail({ id: incident?.id });
    }
  }, [incident]);

  const customStyles = {
    content: {
      width: "50%",
      height: "auto",
      maxHeight: "90%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const updateStatusIncident = async ({ isApprove }) => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.updateStatusIncident({
        incidentId: incidentRes?.id,
        data: {
          status: isApprove == true ? 2 : 3,
        },
      });
      closeModal();
      onSuccess();
      if (response.code != 200) {
        return;
      }
      toast.success(isApprove == true ? "Đã xác nhận" : "Đã từ chối");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };
  const getIncidentDetail = async ({ id }) => {
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.SHOW_LOADING,
    });
    try {
      const res = await procedureServices.getIncidentDetail({
        id: id,
      });

      setincidentRes(res.data);
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: constants.CHANGE_LOADING,
      typeLoading: constants.NONE_LOADING,
    });
  };

  function downloadFile(url) {
    const tagA = document.createElement("a");
    tagA.href = url;
    tagA.download = "file";
    tagA.target = "_blank";
    tagA.click();
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header" style={{ backgroundColor: "#f6c23e" }}>
        <h4 style={{ color: "white" }}>Thông tin chi tiết</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          I. THÀNH PHẦN LIÊN QUAN:
        </div>
        <div className="ml-4">
          <div className="d-flex">
            <span>1.</span>
            <div className="ml-1" style={{ width: "100%" }}>
              <div className="row mb-1">
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  {incidentRes?.type == 1
                    ? "Người lập biên bản:"
                    : "Người lập thông tin"}
                </div>
                <div className="col-9">
                  {incidentRes?.staff_create[0]?.staff?.name ?? "Hệ thống"}
                </div>
              </div>
              <div className="row mb-1">
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  Phòng ban:
                </div>
                <div className="col-3">
                  {departmentsInput != null
                    ? departmentsInput.filter((v) => {
                        return (
                          v.value ==
                            incidentRes?.staff_create[0]?.staff
                              ?.department_id ?? ""
                        );
                      })[0]?.label ?? "---:---"
                    : "---:---"}
                </div>
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  Chi nhánh:
                </div>
                <div className="col-3">
                  {areas.data?.filter(
                    (area) =>
                      area.id == incidentRes?.staff_create[0]?.staff?.area_id ??
                      ""
                  )[0]?.name ?? "---:---"}
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          <div className="d-flex">
            <span>2.</span>
            <div className="ml-1" style={{ width: "100%" }}>
              <div className="row mb-1">
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  {incidentRes?.type == 1
                    ? "Bên liên quan trực tiếp:"
                    : "Bên nhận thông tin"}
                </div>
                <div>
                  <div>
                    {`${(incidentRes?.area ?? [])
                      .map((e) => e?.area?.name ?? "")
                      .join(", ")}`}
                  </div>
                  <div style={{ height: "5px" }}></div>
                  <div>
                    {`${(incidentRes?.department ?? [])
                      .map((e) => e?.department?.name ?? "")
                      .join(", ")}`}
                  </div>
                </div>
                <div className="col-9">
                  {(incidentRes?.staff_relate ?? [])
                    .map((e) => e.staff?.name)
                    .join(", ")}
                </div>
              </div>
              {(incidentRes?.staff_relate ?? []).length === 1 ? (
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Phòng ban:
                  </div>
                  <div className="col-3">
                    {departmentsInput != null
                      ? departmentsInput.filter((v) => {
                          return (
                            v.value ==
                              incidentRes?.staff_relate[0]?.staff
                                ?.department_id ?? ""
                          );
                        })[0]?.label ?? "---:---"
                      : "---:---"}
                  </div>
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Chi nhánh:
                  </div>
                  <div className="col-3">
                    {areas.data?.filter(
                      (area) =>
                        area.id ==
                          incidentRes?.staff_relate[0]?.staff?.area_id ?? ""
                    )[0]?.name ?? "---:---"}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {(incidentRes?.staff_relate ?? []).length === 1 &&
              incidentRes?.type == 1 ? (
                <div className="row mb-1">
                  <div
                    className="col-2"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    CMND/CCCD:
                  </div>
                  <div className="col-2">
                    {incidentRes?.staff_relate[0].staff?.cmnd ?? "---:---"}
                  </div>
                  <div
                    className="col-2"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Ngày cấp:
                  </div>
                  <div className="col-2">
                    {incidentRes?.staff_relate[0].staff?.date_range ??
                      "---:---"}
                  </div>
                  <div
                    className="col-2"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Nơi cấp:
                  </div>
                  <div className="col-2">
                    {incidentRes?.staff_relate[0].staff?.issued_by ?? "---:---"}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          {incidentRes?.type == 1 ? (
            <div className="d-flex">
              <span>3.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Bên chứng kiến:
                  </div>

                  <div className="col-9">
                    {(incidentRes?.staff_viewer ?? [])
                      .map((e) => e.staff?.name)
                      .join(", ")}
                  </div>
                </div>
                {(incidentRes?.staff_viewer ?? []).length === 1 ? (
                  <div className="row mb-1">
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Phòng ban:
                    </div>
                    <div className="col-3">
                      {departmentsInput != null
                        ? departmentsInput.filter((v) => {
                            return (
                              v.value ==
                                incidentRes?.staff_viewer[0]?.staff
                                  ?.department_id ?? ""
                            );
                          })[0]?.label ?? "---:---"
                        : "---:---"}
                    </div>
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Chi nhánh:
                    </div>
                    <div className="col-3">
                      {areas.data?.filter(
                        (area) =>
                          area.id ==
                            incidentRes?.staff_viewer[0]?.staff?.area_id ?? ""
                      )[0]?.name ?? "---:---"}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {(incidentRes?.staff_viewer ?? []).length === 1 ? (
                  <div className="row mb-1">
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      CMND/CCCD:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_viewer[0].staff?.cmnd ?? "---:---"}
                    </div>
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Ngày cấp:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_viewer[0].staff?.date_range ??
                        "---:---"}
                    </div>
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Nơi cấp:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_viewer[0].staff?.issued_by ??
                        "---:---"}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div style={{ height: "20px" }}></div>
          {incidentRes?.type == 1 ? (
            <div className="d-flex">
              <span>4.</span>
              <div className="ml-1" style={{ width: "100%" }}>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Người được xem:
                  </div>

                  <div className="col-9">
                    {(incidentRes?.staff_seen ?? [])
                      .map((e) => e.staff?.name)
                      .join(", ")}
                  </div>
                </div>
                {(incidentRes?.staff_seen ?? []).length === 1 ? (
                  <div className="row mb-1">
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Phòng ban:
                    </div>
                    <div className="col-3">
                      {departmentsInput != null
                        ? departmentsInput.filter((v) => {
                            return (
                              v.value ==
                                incidentRes?.staff_seen[0]?.staff
                                  ?.department_id ?? ""
                            );
                          })[0]?.label ?? "---:---"
                        : "---:---"}
                    </div>
                    <div
                      className="col-3"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Chi nhánh:
                    </div>
                    <div className="col-3">
                      {areas.data?.filter(
                        (area) =>
                          area.id ==
                            incidentRes?.staff_seen[0]?.staff?.area_id ?? ""
                      )[0]?.name ?? "---:---"}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {(incidentRes?.staff_seen ?? []).length === 1 ? (
                  <div className="row mb-1">
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      CMND/CCCD:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_seen[0].staff?.cmnd ?? "---:---"}
                    </div>
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Ngày cấp:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_seen[0].staff?.date_range ??
                        "---:---"}
                    </div>
                    <div
                      className="col-2"
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Nơi cấp:
                    </div>
                    <div className="col-2">
                      {incidentRes?.staff_seen[0].staff?.issued_by ?? "---:---"}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          II. CHI TIẾT:
        </div>
        <div className="ml-4">
          <div className="d-flex" style={{ width: "100%" }}>
            <span>1.</span>
            <div className="ml-1" style={{ width: "100%" }}>
              <div>
                <div className="row mb-1">
                  <div
                    className="col-3"
                    style={{ fontSize: "15px", fontWeight: "700" }}
                  >
                    Thời gian diễn ra:
                  </div>
                  <div className="col-9">
                    {incidentRes?.time_happen === null
                      ? "---:---"
                      : moment
                          .utc(incidentRes?.time_happen)
                          .format(constants.FORMAT_DD_MM_YYYY_HH_MM)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <span>2.</span>
            <div className="ml-1" style={{ width: "100%" }}>
              <div className="row mb-1">
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  Địa điểm diễn ra:
                </div>
                <div className="col-9">
                  {incidentRes?.address_happen ?? "---:---"}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <span>3.</span>
            <div className="ml-1" style={{ width: "100%" }}>
              <div className="row mb-1">
                <div
                  className="col-3"
                  style={{ fontSize: "15px", fontWeight: "700" }}
                >
                  Nội dung:
                </div>
                <div
                  className="col-9"
                  dangerouslySetInnerHTML={{
                    __html: incidentRes?.content ?? "",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {(incidentRes?.file_url ?? null) !== null && (
          <div className="d-flex justify-content-start align-items-start mt-3">
            <div style={{ fontWeight: "700" }}>File đính kèm:</div>
            <div className="ml-3">
              {(incidentRes?.file_url ?? []).map((f, index) => {
                return (
                  <div className="mb-2">
                    <div
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        console.log(f);
                        downloadFile(f);
                      }}
                    >
                      <BsFileEarmarkArrowDownFill
                        style={{ fontSize: "20px" }}
                      ></BsFileEarmarkArrowDownFill>
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginLeft: "5px",
                        }}
                      >
                        {f.split("/").pop().substring(0, 20)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {(incidentRes?.images ?? []).length > 0 ? (
          <div>
            <div className="mt-2" style={{ fontWeight: "700" }}>
              Hình ảnh đính kèm:
            </div>
            <div className="d-flex flex-wrap">
              {(incidentRes?.images ?? []).map((e, index) => {
                return (
                  <div className="my-3 col-4">
                    <img
                      src={e?.image ?? ""}
                      onClick={(e) => {
                        openImageViewer(index);
                      }}
                      style={{
                        width: "200px",
                        height: "300px",
                        borderRadius: "5%",
                        objectFit: "cover",
                        border: "1px solid #ccc",
                      }}
                      // onError={onError}
                    />
                  </div>
                );
              })}
              {isViewerOpen && (
                <ImageViewer
                  src={(incidentRes?.images ?? []).map((e) => e.image ?? "")}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  disableScroll={false}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                  closeOnClickOutside={true}
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="py-3" style={{ fontSize: "15px", fontWeight: "700" }}>
          III. LỊCH SỬ:
        </div>
        <Tabs
          defaultActiveKey="1"
          onTabClick={(e) => {
            // if (e == 1) {
            //   getHistoryLogArea(1, 0);
            // }
            // if (e == 3) {
            //   getHistoryLogArea(1, 1);
            // }
            // setIndexTab(e);
          }}
          items={[
            {
              label: "Tất cả",
              key: "1",
              children: (incidentRes?.histories ?? []).map((e) => {
                return (
                  <div className="d-flex align-items-center mb-2">
                    <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                    <span>
                      <span
                        style={{ fontWeight: "700", marginLeft: "5px" }}
                      >{`${e?.staff?.name ?? ""}`}</span>
                      {(incidentRes?.staff_viewer ?? [])
                        .map((e) => e.staff_id)
                        .includes(e?.staff_id) ? (
                        <span
                          style={{
                            fontWeight: "700",
                            marginLeft: "5px",
                            color: "#06950a",
                          }}
                        >
                          (chứng kiến)
                        </span>
                      ) : (
                        <></>
                      )}
                      <span
                        style={{
                          color:
                            e?.status === 0
                              ? "amber"
                              : e?.status === 1
                              ? "blue"
                              : e?.status === 2
                              ? "green"
                              : e?.status === 3
                              ? "red"
                              : "gray",
                          fontWeight: "600",
                        }}
                      >
                        {e?.status === 0
                          ? ` đã tạo ${
                              incidentRes?.type == 1 ? "biên bản" : "thông tin"
                            }`
                          : e?.status === 1
                          ? ` đã xem ${
                              incidentRes?.type == 1 ? "biên bản" : "thông tin"
                            }`
                          : e?.status === 2
                          ? ` đã xác nhận ${
                              incidentRes?.type == 1 ? "biên bản" : "thông tin"
                            }`
                          : e?.status === 3
                          ? ` đã từ chối ${
                              incidentRes?.type == 1 ? "biên bản" : "thông tin"
                            }`
                          : ` chưa xem ${
                              incidentRes?.type == 1 ? "biên bản" : "thông tin"
                            }`}
                      </span>
                    </span>
                    {e?.created_at !== null ? (
                      <div className="flex-grow-1" style={{ textAlign: "end" }}>
                        {moment(e?.created_at).format(
                          constants.FORMAT_DD_MM_YYYY_HH_MM
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }),
            },
            {
              label: `Chưa xem ${
                (incidentRes?.histories ?? []).filter(
                  (e) =>
                    e.status != 0 &&
                    e.status != 1 &&
                    e.status != 2 &&
                    e.status != 3
                ).length
              }`,
              key: "2",
              children: (incidentRes?.histories ?? [])
                .filter(
                  (e) =>
                    e.status != 0 &&
                    e.status != 1 &&
                    e.status != 2 &&
                    e.status != 3
                )
                .map((e) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                      <span>
                        <span
                          style={{ fontWeight: "700", marginLeft: "5px" }}
                        >{`${e?.staff?.name ?? ""}`}</span>
                        {(incidentRes?.staff_viewer ?? [])
                          .map((e) => e.staff_id)
                          .includes(e?.staff_id) ? (
                          <span
                            style={{
                              fontWeight: "700",
                              marginLeft: "5px",
                              color: "#06950a",
                            }}
                          >
                            (chứng kiến)
                          </span>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            color:
                              e?.status === 0
                                ? "amber"
                                : e?.status === 1
                                ? "blue"
                                : e?.status === 2
                                ? "green"
                                : e?.status === 3
                                ? "red"
                                : "gray",
                            fontWeight: "600",
                          }}
                        >
                          {e?.status === 0
                            ? ` đã tạo ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 1
                            ? ` đã xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 2
                            ? ` đã xác nhận ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 3
                            ? ` đã từ chối ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : ` chưa xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`}
                        </span>
                      </span>
                      {e?.created_at !== null ? (
                        <div
                          className="flex-grow-1"
                          style={{ textAlign: "end" }}
                        >
                          {moment(e?.created_at).format(
                            constants.FORMAT_DD_MM_YYYY_HH_MM
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }),
            },
            {
              label: `Đã xem ${incidentRes?.status_1 ?? 0}`,
              key: "3",
              children: (incidentRes?.histories ?? [])
                .filter((e) => e.status == 1)
                .map((e) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                      <span>
                        <span
                          style={{ fontWeight: "700", marginLeft: "5px" }}
                        >{`${e?.staff?.name ?? ""}`}</span>
                        {(incidentRes?.staff_viewer ?? [])
                          .map((e) => e.staff_id)
                          .includes(e?.staff_id) ? (
                          <span
                            style={{
                              fontWeight: "700",
                              marginLeft: "5px",
                              color: "#06950a",
                            }}
                          >
                            (chứng kiến)
                          </span>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            color:
                              e?.status === 0
                                ? "amber"
                                : e?.status === 1
                                ? "blue"
                                : e?.status === 2
                                ? "green"
                                : e?.status === 3
                                ? "red"
                                : "gray",
                            fontWeight: "600",
                          }}
                        >
                          {e?.status === 0
                            ? ` đã tạo ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 1
                            ? ` đã xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 2
                            ? ` đã xác nhận ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 3
                            ? ` đã từ chối ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : ` chưa xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`}
                        </span>
                      </span>
                      {e?.created_at !== null ? (
                        <div
                          className="flex-grow-1"
                          style={{ textAlign: "end" }}
                        >
                          {moment(e?.created_at).format(
                            constants.FORMAT_DD_MM_YYYY_HH_MM
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }),
            },
            {
              label: `Đã xác nhận ${incidentRes?.status_2 ?? 0}`,
              key: "4",
              children: (incidentRes?.histories ?? [])
                .filter((e) => e.status == 2)
                .map((e) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                      <span>
                        <span
                          style={{ fontWeight: "700", marginLeft: "5px" }}
                        >{`${e?.staff?.name ?? ""}`}</span>
                        {(incidentRes?.staff_viewer ?? [])
                          .map((e) => e.staff_id)
                          .includes(e?.staff_id) ? (
                          <span
                            style={{
                              fontWeight: "700",
                              marginLeft: "5px",
                              color: "#06950a",
                            }}
                          >
                            (chứng kiến)
                          </span>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            color:
                              e?.status === 0
                                ? "amber"
                                : e?.status === 1
                                ? "blue"
                                : e?.status === 2
                                ? "green"
                                : e?.status === 3
                                ? "red"
                                : "gray",
                            fontWeight: "600",
                          }}
                        >
                          {e?.status === 0
                            ? ` đã tạo ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 1
                            ? ` đã xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 2
                            ? ` đã xác nhận ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 3
                            ? ` đã từ chối ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : ` chưa xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`}
                        </span>
                      </span>
                      {e?.created_at !== null ? (
                        <div
                          className="flex-grow-1"
                          style={{ textAlign: "end" }}
                        >
                          {moment(e?.created_at).format(
                            constants.FORMAT_DD_MM_YYYY_HH_MM
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }),
            },
            {
              label: `Đã từ chối ${incidentRes?.status_3 ?? 0}`,
              key: "5",
              children: (incidentRes?.histories ?? [])
                .filter((e) => e.status == 3)
                .map((e) => {
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <MdAccessTimeFilled color="grey"></MdAccessTimeFilled>
                      <span>
                        <span
                          style={{ fontWeight: "700", marginLeft: "5px" }}
                        >{`${e?.staff?.name ?? ""}`}</span>
                        {(incidentRes?.staff_viewer ?? [])
                          .map((e) => e.staff_id)
                          .includes(e?.staff_id) ? (
                          <span
                            style={{
                              fontWeight: "700",
                              marginLeft: "5px",
                              color: "#06950a",
                            }}
                          >
                            (chứng kiến)
                          </span>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            color:
                              e?.status === 0
                                ? "amber"
                                : e?.status === 1
                                ? "blue"
                                : e?.status === 2
                                ? "green"
                                : e?.status === 3
                                ? "red"
                                : "gray",
                            fontWeight: "600",
                          }}
                        >
                          {e?.status === 0
                            ? ` đã tạo ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 1
                            ? ` đã xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 2
                            ? ` đã xác nhận ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : e?.status === 3
                            ? ` đã từ chối ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`
                            : ` chưa xem ${
                                incidentRes?.type == 1
                                  ? "biên bản"
                                  : "thông tin"
                              }`}
                        </span>
                      </span>
                      {e?.created_at !== null ? (
                        <div
                          className="flex-grow-1"
                          style={{ textAlign: "end" }}
                        >
                          {moment(e?.created_at).format(
                            constants.FORMAT_DD_MM_YYYY_HH_MM
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }),
            },
          ]}
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Đóng
        </button>
        {incidentRes?.procedure_status == 0 ||
        incidentRes?.procedure_status == 1 ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                borderColor: "#f6c23e",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                updateStatusIncident({ isApprove: false });
              }}
            >
              Từ chối
            </button>
            <div style={{ width: "10px" }}></div>
            <button
              type="button"
              style={{
                backgroundColor: "#f6c23e",
                borderRadius: "8px",
                color: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#f6c23e",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                updateStatusIncident({ isApprove: true });
              }}
            >
              Chấp nhận
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default IncidentModal;
