/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { shiftServices } from "../../services/shiftServices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DeleteModal from "./child/DeleteModal";
import SetupOTModal from "./child/SetupOTModal";
import { constants } from "../../constants";
import "./styles.css";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";

const setupOTDefault = {
  title: "",
  description: "",
  value: null,
  dates: [],
};
const SetupOT = () => {
  const dispatch = useDispatch();

  const areaId = useSelector((state) => state.area.areaId);

  const [setupOT, setSetupOT] = useState();
  const [setupOTDetail, setSetupOTDetail] = useState(setupOTDefault);
  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
  });
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    fetchSetupOT();
  }, [areaId, isReload]);

  const fetchSetupOT = async () => {
    try {
      const response = await shiftServices.fetchSetupOT(areaId);
      setSetupOT(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSave = async (data) => {
    try {
      let response = null;
      if (modal.method === "CREATE" || modal.method === "UPDATE") {
        const dates = [];
        const list_department_id = [];

        data?.dates.forEach((item) => {
          if (item.date?.trim()) {
            dates.push(item.date);
          }
        });

        data?.departments.forEach((item) => {
          list_department_id.push(item.id);
        });

        const setupOTUpdate = { ...data, dates, list_department_id };

        console.log(setupOTUpdate);
        if (modal.method === "CREATE") {
          response = await shiftServices.createSetupOT({
            areaId: areaId,
            body: setupOTUpdate,
          });
        } else if (modal.method === "UPDATE") {
          response = await shiftServices.updateSetupOT({
            id: data.id,
            areaId: areaId,
            body: setupOTUpdate,
          });
        }
      } else if (modal.method === "DELETE") {
        response = await shiftServices.deleteSetupOT({
          id: setupOTDetail.id,
          areaId: areaId,
        });
      }
      if (response.success) {
        setSetupOTDetail();
        setIsReload(!isReload);
        handleCloseModal();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
     
      }
    } catch (error) {
      console.log(error);
      let msg = "";

      if (modal.method === "CREATE") {
        msg = "Lỗi khi tạo";
      } else if (modal.method === "UPDATE") {
        msg = "Lỗi khi cập nhật";
      } else if (modal.method === "DELETE") {
        msg = "Lỗi khi xóa";
      }
      toast.error(msg);
    
    }
  };

  const handleCloseModal = () => {
    setModal({ title: "", method: "", visible: false });
  };

  return (
    <div className="container-fluid">
      <div className="header-page">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Cài đặt ngày lễ / tăng ca
        </h4>
        <button
          class={`btn btn-info btn-icon-split btn-sm`}
          onClick={() => {
            setSetupOTDetail(setupOTDefault);
            setModal({
              title: `Thêm cài đăt ngày lễ / tăng ca`,
              method: "CREATE",
              visible: true,
            });
          }}
        >
          <span class="icon text-white-50">
            <i class="fas fa-plus"></i>
          </span>
          <span class="text">Thêm cài đăt ngày lễ / tăng ca</span>
        </button>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper">
              <table
                className="table no-footer dataTable table-striped"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                aria-describedby="dataTable_info"
              >
                <thead>
                  <tr className="table-row">
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Mô tả</th>
                    <th className="text-center">Hệ số lương</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {setupOT?.map((item, index) => (
                    <tr key={index} className="table-row">
                      <td>{index + 1}</td>
                      <td>{item?.title || ""}</td>
                      <td>{item?.description || ""}</td>
                      <td className="text-center">{item?.scale || ""}</td>
                      <td className="group-action-btn">
                        <button
                          class={`btn-info btn-sm btn-edit`}
                          onClick={() => {
                            setSetupOTDetail(item);
                            setModal({
                              title: `Chỉnh sửa`,
                              method: "UPDATE",
                              visible: true,
                            });
                          }}
                          data-toggle="modal"
                          data-target="#modalShift"
                        >
                          <span className="icon text-white mr-1">
                            <i class="fas fa-edit"></i>
                          </span>
                          <span className="">Sửa</span>
                        </button>
                        <button
                          class={`btn-sm btn-trash`}
                          onClick={() => {
                            setSetupOTDetail(item);
                            setModal({
                              title: `Thông báo`,
                              method: "DELETE",
                              visible: true,
                            });
                          }}
                        >
                          <span className="icon text-white mr-1">
                            <i className="fas fa-trash"></i>
                          </span>
                          <span className="btn-trash-text">Xoá</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={modal.visible && modal.method === "DELETE"}
        title={"Thông báo"}
        onSubmit={handleOnSave}
        closeModal={handleCloseModal}
      />
      <SetupOTModal
        visible={
          modal.visible &&
          (modal.method === "CREATE" || modal.method === "UPDATE")
        }
        title={modal.title}
        setupOTDetail={setupOTDetail}
        onSubmit={handleOnSave}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

export default SetupOT;
