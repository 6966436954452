import { Button, DatePicker, Select, Space, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "rsuite";
import { evaluationServices } from "../../services/evaluationServices";
import { reportServices } from "../../services/reportServices";
import "./styles.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as XLSX from "xlsx-js-style";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Column, ColumnGroup, HeaderCell, Cell } = Table;

const handleTabs = (data) => {
  const tabs = [];
  data.map((item) => {
    tabs.push({
      key: item.id,
      label: item.name,
      children: <div></div>,
    });
  });
  return tabs;
};

const processDataForTable = (data) => {
  if (!data.is_report) {
    const structuredData = {};
    data.qc_evaluete_groups.forEach((group) => {
      group.qc_evaluetes.forEach((evaluate) => {
        const key = `${group.name}_${evaluate.title}`;

        if (!structuredData[key]) {
          structuredData[key] = {
            groupName: group.name,
            title: evaluate.title,
            level: evaluate.level,
          };
        }

        evaluate.area_results.forEach((area) => {
          const passedKey = `passed_${area.area_id}`;
          const failedKey = `failed_${area.area_id}`;
          const areaNameKey = `areaName_${area.area_id}`;

          structuredData[key][passedKey] = area.done_number;
          structuredData[key][failedKey] = area.faild_number;
          structuredData[key][areaNameKey] = area.area_name;
        });
      });
    });
    return Object.values(structuredData);
  }
  const structuredData = {};
  data.qc_evaluete_groups.forEach((group) => {
    group.qc_evaluetes.forEach((evaluate) => {
      const key = `${group.name}_${evaluate.title}`;

      if (!structuredData[key]) {
        structuredData[key] = {
          groupName: group.name,
          title: evaluate.title,
          level: evaluate.level,
        };
      }

      evaluate.area_results.forEach((area) => {
        const pointTotalKey = `pointTotal_${area.area_id}`;
        const areaNameKey = `areaName_${area.area_id}`;
        structuredData[key][pointTotalKey] = area.report_input || 0;
        structuredData[key][areaNameKey] = area.area_name;
      });
    });
  });
  return Object.values(structuredData);
};

const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
const endOfMonth = dayjs().endOf("month").format("YYYY-MM-DD");

const listLevelError = [
  {
    value: "lowError",
    label: "Lỗi nhẹ",
  },
  {
    value: "heavyError",
    label: "Lỗi nặng",
  },
  {
    value: "seriousError",
    label: "Nghiêm trọng",
  },
];

function ReportQualityControl() {
  const [tabs, setTabs] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [rangeDatePicker, setRangeDatePicker] = useState([]);
  const [reviewArticle, setReviewArticle] = useState(null);
  const listBranch = useSelector((state) => state.area.areas);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [listGroupName, setListGroupName] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState([]);
  const [selectedLevelError, setSelectedLevelError] = useState([]);
  const [data, setData] = useState({});

  const handleFilterReportQC = async () => {
    try {
      setIsLoadingFilter(true);

      const res = await reportServices.exportReportQc(
        reviewArticle,
        selectedBranch || "",
        rangeDatePicker[0] || startOfMonth,
        rangeDatePicker[1] || endOfMonth
      );

      if (res.code === 200) {
        const result = processDataForTable(res.data);
        let filteredData = result;
        if (selectedLevelError.length > 0) {
          filteredData = filteredData.filter((item) =>
            selectedLevelError.includes(item.level)
          );

          if (selectedGroupName.length > 0) {
            filteredData = filteredData.filter((item) =>
              selectedGroupName.includes(item.groupName)
            );
          }
        }
        setDataTable(filteredData);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingFilter(false);
    }
  };

  const handleGetDataAfterSoft = () => {
    if (sortColumn && sortType) {
      return dataTable.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return dataTable;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const res = await evaluationServices.getQCEvaluateMain();
        const arrayTabs = handleTabs(res.data);

        setTabs(arrayTabs);
      } catch (error) {
        console.log("error", error);
      } finally {
        // setIsLoading(false);
      }
    };
    fetch();
  }, []);

  const handleFetchReportQC = async (key) => {
    try {
      setReviewArticle(key);
      setIsLoading(true);
      const res = await reportServices.exportReportQc(
        key,
        selectedBranch || "",
        rangeDatePicker[0] || startOfMonth,
        rangeDatePicker[1] || endOfMonth
      );

      const listLevel = [];
      res.data.qc_evaluete_groups.forEach((item, index) => {
        listLevel.push({ value: item.name, label: item.name });
      });
      setListGroupName(listLevel);

      const result = processDataForTable(res.data);
      setData(res.data);
      setDataTable(result);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tabs) {
      handleFetchReportQC(tabs[0]?.key);
    }
  }, [tabs]);

  const areaIds = new Set();
  dataTable.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key.startsWith("passed_") || key.startsWith("failed_")) {
        areaIds.add(key.split("_")[1]);
      }
      if (key.startsWith("pointTotal_")) {
        areaIds.add(key.split("_")[1]);
      }
    });
  });

  function convertDataExcel(obj) {
    if (data.is_report) {
      const orderedKeys = ["title", "level", "groupName"];

      const result = [];

      orderedKeys.forEach((key) => {
        result.push(obj[key]);
      });

      areaIds.forEach((key) => {
        result.push(obj[`pointTotal_${key}`]);
      });

      return result;
    } else {
      const orderedKeys = ["title", "level", "groupName"];

      const result = [];

      orderedKeys.forEach((key) => {
        result.push(obj[key]);
      });

      areaIds.forEach((key) => {
        result.push(obj[`passed_${key}`]);
        result.push(obj[`failed_${key}`]);
      });

      return result;
    }
  }

  const handleExportReportQc = () => {
    const dataRowExcel = dataTable.map(convertDataExcel);

    const areaNames = Object.keys(dataTable[0]).filter((key) =>
      key.startsWith("areaName")
    );

    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet([[]]);

    let startColumnIndex = 0;
    const merges = [];

    const newColumns = [["Câu hỏi", "Mức độ", "Loại câu hỏi"]];
    XLSX.utils.sheet_add_aoa(ws, newColumns, { origin: { r: 0, c: 0 } });

    merges.push({ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } });
    merges.push({ s: { r: 0, c: 1 }, e: { r: 1, c: 1 } });
    merges.push({ s: { r: 0, c: 2 }, e: { r: 1, c: 2 } });

    startColumnIndex += 3;
    if (!data.is_report) {
      areaNames.forEach((areaName) => {
        const columnHeaders = [
          [`${dataTable[0][areaName]}`, null],
          ["Đạt", "Không đạt"],
        ];
        XLSX.utils.sheet_add_aoa(ws, columnHeaders, {
          origin: { r: 0, c: startColumnIndex },
        });
        merges.push({
          s: { r: 0, c: startColumnIndex },
          e: { r: 0, c: startColumnIndex + 1 },
        });
        startColumnIndex += 2;
      });
      ws["!merges"] = merges;
    } else {
      areaNames.forEach((areaName) => {
        const columnHeaders = [[`${dataTable[0][areaName]}`]];
        XLSX.utils.sheet_add_aoa(ws, columnHeaders, {
          origin: { r: 0, c: startColumnIndex },
        });
        startColumnIndex += 1;
      });
    }
    XLSX.utils.sheet_add_aoa(ws, dataRowExcel, { origin: { r: 2, c: 0 } });

    const wscols = [];
    const wsrows = [];

    const numCols = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
    const numRows = XLSX.utils.decode_range(ws["!ref"]).e.r + 1;

    for (let i = 0; i < numCols; i++) {
      switch (i) {
        case 0:
          wscols.push({ wch: 120 });
          break;
        default:
          wscols.push({ wch: 20 });
          break;
      }
    }
    for (let i = 0; i <= numRows; i++) {
      wsrows.push({ hpx: 60 });
    }
    ws["!cols"] = wscols;
    ws["!rows"] = wsrows;

    const firstColumnStyle = {
      fill: { patternType: "solid", fgColor: { rgb: "f9c114" } },
      border: {
        top: { style: "dotted" },
        left: { style: "dotted" },
        bottom: { style: "dotted" },
        right: { style: "dotted" },
      },
      alignment: { horizontal: "center", vertical: "center", wrapText: true },
    };
    const range = XLSX.utils.decode_range(ws["!ref"]);

    for (let R = range.s.r; R <= range.s.r + 1; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (!ws[cell_ref]) {
          continue;
        }
        ws[cell_ref].s = firstColumnStyle;
      }
    }

    for (let R = range.s.r; R <= range.e.r + 1; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (!ws[cell_ref]) {
          continue;
        }
        if (!ws[cell_ref].s) {
          ws[cell_ref].s = {
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
          };
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "report-qc.xlsx");
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxHeight: "90vh" }}
    >
      <Tabs
        items={tabs}
        onChange={handleFetchReportQC}
        defaultActiveKey=""
        size="small"
      />
      <Space direction="horizontal" style={{ marginBottom: "8px" }}>
        <RangePicker
          changeOnBlur={true}
          onChange={(dates, dateStrings) => {
            console.log("dateStrings", dateStrings);
            setRangeDatePicker(dateStrings);
          }}
          format="YYYY-MM-DD"
          defaultValue={[
            dayjs(startOfMonth, "YYYY-MM-DD"),
            dayjs(endOfMonth, "YYYY-MM-DD"),
          ]}
        />
        <Select
          style={{
            width: "350px",
          }}
          mode="multiple"
          value={selectedBranch}
          onChange={(value) => {
            console.log("value", value);
            if (value.includes("999999999999999999")) {
              setSelectedBranch([]);
              return;
            }
            setSelectedBranch(value);
          }}
          placeholder="Chọn chi nhánh"
          defaultValue={["Văn phòng tổng"]}
          maxTagCount="responsive"
        >
          {listBranch?.data?.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
        <Select
          style={{
            width: "200px",
          }}
          value={selectedLevelError}
          onChange={(value) => {
            console.log("selectedLevelError", value);
            setSelectedLevelError(value);
          }}
          placeholder="Mức độ"
          maxTagCount="responsive"
          mode="multiple"
        >
          {listLevelError.map((item) => {
            return (
              <Option value={item.label} key={item.label}>
                {item.label}
              </Option>
            );
          })}
        </Select>
        <Select
          style={{
            width: "200px",
          }}
          value={selectedGroupName}
          onChange={(value) => {
            setSelectedGroupName(value);
          }}
          placeholder="Loại câu hỏi"
          options={listGroupName}
          maxTagCount="responsive"
          mode="multiple"
        />
        <Button
          type="primary"
          loading={isLoadingFilter}
          onClick={handleFilterReportQC}
        >
          Lọc
        </Button>
        <Button
          type="primary"
          loading={isLoadingFilter || isLoading}
          onClick={handleExportReportQc}
        >
          Export
        </Button>
      </Space>

      <Table
        data={handleGetDataAfterSoft()}
        fillHeight
        headerHeight={80}
        bordered
        cellBordered
        loading={isLoading || isLoadingFilter}
        wordWrap="break-word"
        resizable
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column align="center" verticalAlign="middle" width={300} fixed>
          <HeaderCell>Câu hỏi</HeaderCell>
          <Cell dataKey="title" />
        </Column>

        <Column align="center" verticalAlign="middle" width={150} fixed>
          <HeaderCell>Mức độ</HeaderCell>
          <Cell dataKey="level" />
        </Column>

        <Column align="center" verticalAlign="middle" width={180} fixed>
          <HeaderCell>Loại câu hỏi</HeaderCell>
          <Cell dataKey="groupName" />
        </Column>

        {!data.is_report
          ? Array.from(areaIds).map((areaId) => (
              <ColumnGroup
                key={areaId}
                header={dataTable[0][`areaName_${areaId}`]}
                align="center"
                affixHeader
              >
                <Column
                  width={100}
                  align="center"
                  verticalAlign="middle"
                  affixHeader
                  sortable
                >
                  <HeaderCell>Đạt</HeaderCell>
                  <Cell dataKey={`passed_${areaId}`} />
                </Column>
                <Column
                  width={120}
                  align="center"
                  verticalAlign="middle"
                  affixHeader
                  sortable
                >
                  <HeaderCell>Không Đạt</HeaderCell>
                  <Cell dataKey={`failed_${areaId}`} />
                </Column>
              </ColumnGroup>
            ))
          : Array.from(areaIds).map((areaId) => (
              <Column
                width={100}
                align="center"
                verticalAlign="middle"
                affixHeader
                sortable
              >
                <HeaderCell>{dataTable[0][`areaName_${areaId}`]}</HeaderCell>
                <Cell dataKey={`pointTotal_${areaId}`} />
              </Column>
            ))}
      </Table>
    </div>
  );
}

export default ReportQualityControl;
