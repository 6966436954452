import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";

const ModalRole = ({
  visible,
  title,
  closeModal,
  onSubmitProps,
  area,
  item,
}) => {
  const [permission, SetPermission] = useState({
    is_admin: false,
    manager_area: false,
    manager_calendar_shift: false,
    manager_config_general: false,
    manager_department: false,
    manager_notification: false,
    manager_report: false,
    manager_request: false,
    manager_role: false,
    manager_salary: false,
    manager_shift: false,
    manager_staff: false,
    manager_timekeeping: false,
    manager_timekeeping_bonus_less: false,
    manager_training: false,
    staff_manager: false,
    staff_add: false,
    staff_edit: false,
    staff_delete: false,
    form_manager: false,
    form_add: false,
    form_edit: false,
    form_delete: false,
    shifts_manager: false,
    shifts_add: false,
    shifts_edit: false,
    shifts_delete: false,
    table_timekeeping_manager: false,
    calender_shifts_manager: false,
    calender_shifts_add: false,
    calender_shifts_edit: false,
    calender_shifts_delete: false,
    calculate_manager: false,
    calculate_add: false,
    calculate_edit: false,
    calculate_delete: false,
    area_manager: false,
    area_add: false,
    area_edit: false,
    area_delete: false,
    department_manager: false,
    department_add: false,
    department_edit: false,
    department_delete: false,
    bonus_config_manager: false,
    bonus_config_add: false,
    bonus_config_edit: false,
    bonus_config_delete: false,
    support_config_manager: false,
    support_config_add: false,
    support_config_edit: false,
    support_config_delete: false,
    ot_manager: false,
    ot_add: false,
    ot_edit: false,
    ot_delete: false,
    decent_manager: false,
    decent_add: false,
    decent_edit: false,
    decent_delete: false,
    config_procedure_manager: false,
    config_procedure_add: false,
    config_procedure_edit: false,
    config_procedure_delete: false,
    coupon_manager: false,
    coupon_add: false,
    coupon_edit: false,
    coupon_delete: false,
    salary_manager: false,
    salary_add: false,
    salary_edit: false,
    salary_delete: false,
    timekeeping_manager: false,
    timekeeping_add: false,
    timekeeping_edit: false,
    timekeeping_delete: false,
    device_manager: false,
    device_delete: false,
    area_timekeeping_manager: false,
    contract_expiration: false,
    choose_role: false,
    training_banner: false,
    training_all_result: false,
    training_group: false,
    training_test: false,
    training_post: false,
    training_review: false,
    training_cmt: false,
    training_cmt_post: false,
    training_help: false,
    review_from_customer: false,
    review_question_add: false,
    review_history: false,
    reset_contract: false,
    edit_contract: false,
    send_salary_table: false,
    personal_form_manager: false,
    operate_form_manager: false,
    report_form_manager: false,
    incident_manager: false,
    add_incident_1: false,
    add_incident_2: false,
    incident_delete: false,
    filter_person_incident: false,
    history_system_manager: false,
    help_manager: false,
    help_add: false,
    help_edit: false,
    help_delete: false,
    franchise_customer_manager: false,
    franchise_customer_add: false,
    franchise_customer_edit: false,
    franchise_customer_delete: false,
    franchise_file_manager: false,
    franchise_file_add: false,
    franchise_file_edit: false,
    franchise_file_delete: false,
    franchise_report_manager: false,
    franchise_report_add: false,
    franchise_report_edit: false,
    franchise_report_delete: false,
    franchise_mail_manager: false,
    franchise_mail_add: false,
    franchise_mail_edit: false,
    franchise_mail_delete: false,
    franchise_meeting_manager: false,
    franchise_meeting_add: false,
    franchise_meeting_edit: false,
    franchise_meeting_delete: false,
    franchise_post_manager: false,
    franchise_post_add: false,
    franchise_post_edit: false,
    franchise_post_delete: false,
    franchise_help_manager: false,
    franchise_help_add: false,
    franchise_help_edit: false,
    franchise_help_delete: false,
    franchise_contact_manager: false,
    franchise_contact_delete: false,
    franchise_register_manager: false,
    franchise_register_delete: false,
    noti_birthday_user: false,
    noti_new_user: false,
    area_file_add: false,
    area_review_add: false,
    area_violate_add: false,
    qc_evaluate_main_manager: false,
    qc_evaluate_main_add: false,
    qc_evaluate_main_update: false,
    qc_evaluate_main_delete: false,
    qc_evaluate_group_manager: false,
    qc_evaluate_group_add: false,
    qc_evaluate_group_update: false,
    qc_evaluate_group_delete: false,
    qc_evaluate_manager: false,
    qc_evaluate_add: false,
    qc_evaluate_update: false,
    qc_evaluate_delete: false,
    probation_manager: false,
    probation_add: false,
    probation_edit: false,
    probation_delete: false,
  });

  const [titleRole, setTitleRole] = useState(null);

  const [areaSelect, setAreaSelect] = useState([]);

  const handleChoiceArea = (id) => {
    console.log(id);
    let result = [...areaSelect];
    const check = areaSelect.findIndex((area) => area === id);
    if (check !== -1) {
      result.splice(check, 1);
    } else {
      result.push(id);
    }
    setAreaSelect(result);
  };

  const onSubmit = () => {
    if ((titleRole ?? "") === "") {
      toast.error("Chưa nhập tên phân quyền");
      return;
    }

    if (permission.is_admin === true) {
      var data = {
        is_admin: true,
        manager_area: true,
        manager_calendar_shift: true,
        manager_config_general: true,
        manager_department: true,
        manager_notification: true,
        manager_report: true,
        manager_request: true,
        manager_role: true,
        manager_salary: true,
        manager_shift: true,
        manager_staff: true,
        manager_timekeeping: true,
        manager_timekeeping_bonus_less: true,
        manager_training: true,
        staff_manager: true,
        staff_add: true,
        staff_edit: true,
        staff_delete: true,
        form_manager: true,
        form_add: true,
        form_edit: true,
        form_delete: true,
        shifts_manager: true,
        shifts_add: true,
        shifts_edit: true,
        shifts_delete: true,
        table_timekeeping_manager: true,
        calender_shifts_manager: true,
        calender_shifts_add: true,
        calender_shifts_edit: true,
        calender_shifts_delete: true,
        calculate_manager: true,
        calculate_add: true,
        calculate_edit: true,
        calculate_delete: true,
        area_manager: true,
        area_add: true,
        area_edit: true,
        area_delete: true,
        department_manager: true,
        department_add: true,
        department_edit: true,
        department_delete: true,
        bonus_config_manager: true,
        bonus_config_add: true,
        bonus_config_edit: true,
        bonus_config_delete: true,
        support_config_manager: true,
        support_config_add: true,
        support_config_edit: true,
        support_config_delete: true,
        ot_manager: true,
        ot_add: true,
        ot_edit: true,
        ot_delete: true,
        decent_manager: true,
        decent_add: true,
        decent_edit: true,
        decent_delete: true,
        config_procedure_manager: true,
        config_procedure_add: true,
        config_procedure_edit: true,
        config_procedure_delete: true,
        coupon_manager: true,
        coupon_add: true,
        coupon_edit: true,
        coupon_delete: true,
        salary_manager: true,
        salary_add: true,
        salary_edit: true,
        salary_delete: true,
        timekeeping_manager: true,
        timekeeping_add: true,
        timekeeping_edit: true,
        timekeeping_delete: true,
        device_manager: true,
        device_delete: true,
        area_timekeeping_manager: true,
        contract_expiration: true,
        choose_role: true,
        training_banner: true,
        training_all_result: true,
        training_group: true,
        training_test: true,
        training_post: true,
        training_review: true,
        training_cmt: true,
        training_cmt_post: true,
        training_help: true,
        review_from_customer: true,
        review_question_add: true,
        review_history: true,
        send_salary_table: true,
        edit_contract: true,
        reset_contract: true,
        personal_form_manager: true,
        operate_form_manager: true,
        report_form_manager: true,
        incident_manager: true,
        add_incident_1: true,
        add_incident_2: true,
        incident_delete: true,
        filter_person_incident: true,
        history_system_manager: true,
        help_manager: true,
        help_add: true,
        help_edit: true,
        help_delete: true,
        franchise_customer_manager: true,
        franchise_customer_add: true,
        franchise_customer_edit: true,
        franchise_customer_delete: true,
        franchise_file_manager: true,
        franchise_file_add: true,
        franchise_file_edit: true,
        franchise_file_delete: true,
        franchise_report_manager: true,
        franchise_report_add: true,
        franchise_report_edit: true,
        franchise_report_delete: true,
        franchise_mail_manager: true,
        franchise_mail_add: true,
        franchise_mail_edit: true,
        franchise_mail_delete: true,
        franchise_meeting_manager: true,
        franchise_meeting_add: true,
        franchise_meeting_edit: true,
        franchise_meeting_delete: true,
        franchise_post_manager: true,
        franchise_post_add: true,
        franchise_post_edit: true,
        franchise_post_delete: true,
        franchise_help_manager: true,
        franchise_help_add: true,
        franchise_help_edit: true,
        franchise_help_delete: true,
        franchise_contact_manager: true,
        franchise_contact_delete: true,
        franchise_register_manager: true,
        franchise_register_delete: true,
        noti_birthday_user: true,
        noti_new_user: true,
        area_file_add: true,
        area_review_add: true,
        area_violate_add: true,
        qc_evaluate_main_manager: true,
        qc_evaluate_main_add: true,
        qc_evaluate_main_update: true,
        qc_evaluate_main_delete: true,
        qc_evaluate_group_manager: true,
        qc_evaluate_group_add: true,
        qc_evaluate_group_update: true,
        qc_evaluate_group_delete: true,
        qc_evaluate_manager: true,
        qc_evaluate_add: true,
        qc_evaluate_update: true,
        qc_evaluate_delete: true,
        probation_manager: true,
        probation_add: true,
        probation_edit: true,
        probation_delete: true,
      };
      onSubmitProps({
        title: titleRole,
        area_ids: areaSelect,
        ...data,
      });
    } else {
      onSubmitProps({
        title: titleRole,
        area_ids: areaSelect,
        ...permission,
      });
    }
  };

  useEffect(() => {
    if ((permission.is_admin ?? false) === true) {
      setAreaSelect(
        area.map((e) => {
          return e.id;
        })
      );
    }
  }, [permission]);

  useEffect(() => {
    if (item !== null) {
      setAreaSelect(item.area_ids);
      setTitleRole(item.title);
      SetPermission(item.permissions);
    } else {
      setAreaSelect([]);
      setTitleRole(null);
      SetPermission({
        is_admin: false,
        manager_area: false,
        manager_calendar_shift: false,
        manager_config_general: false,
        manager_department: false,
        manager_notification: false,
        manager_report: false,
        manager_request: false,
        manager_role: false,
        manager_salary: false,
        manager_shift: false,
        manager_staff: false,
        manager_timekeeping: false,
        manager_timekeeping_bonus_less: false,
        manager_training: false,
        staff_manager: false,
        staff_add: false,
        staff_edit: false,
        staff_delete: false,
        form_manager: false,
        form_add: false,
        form_edit: false,
        form_delete: false,
        shifts_manager: false,
        shifts_add: false,
        shifts_edit: false,
        shifts_delete: false,
        table_timekeeping_manager: false,
        calender_shifts_manager: false,
        calender_shifts_add: false,
        calender_shifts_edit: false,
        calender_shifts_delete: false,
        calculate_manager: false,
        calculate_add: false,
        calculate_edit: false,
        calculate_delete: false,
        area_manager: false,
        area_add: false,
        area_edit: false,
        area_delete: false,
        department_manager: false,
        department_add: false,
        department_edit: false,
        department_delete: false,
        bonus_config_manager: false,
        bonus_config_add: false,
        bonus_config_edit: false,
        bonus_config_delete: false,
        support_config_manager: false,
        support_config_add: false,
        support_config_edit: false,
        support_config_delete: false,
        ot_manager: false,
        ot_add: false,
        ot_edit: false,
        ot_delete: false,
        decent_manager: false,
        decent_add: false,
        decent_edit: false,
        decent_delete: false,
        config_procedure_manager: false,
        config_procedure_add: false,
        config_procedure_edit: false,
        config_procedure_delete: false,
        coupon_manager: false,
        coupon_add: false,
        coupon_edit: false,
        coupon_delete: false,
        salary_manager: false,
        salary_add: false,
        salary_edit: false,
        salary_delete: false,
        timekeeping_manager: false,
        timekeeping_add: false,
        timekeeping_edit: false,
        timekeeping_delete: false,
        device_manager: false,
        device_delete: false,
        area_timekeeping_manager: false,
        contract_expiration: false,
        choose_role: false,
        training_banner: false,
        training_all_result: false,
        training_group: false,
        training_test: false,
        training_post: false,
        training_review: false,
        training_cmt: false,
        training_cmt_post: false,
        training_help: false,
        review_from_customer: false,
        review_question_add: false,
        review_history: false,
        reset_contract: false,
        edit_contract: false,
        send_salary_table: false,
        personal_form_manager: false,
        operate_form_manager: false,
        report_form_manager: false,
        incident_manager: false,
        add_incident_1: false,
        add_incident_2: false,
        incident_delete: false,
        filter_person_incident: false,
        history_system_manager: false,

        help_manager: false,
        help_add: false,
        help_edit: false,
        help_delete: false,

        franchise_customer_manager: false,
        franchise_customer_add: false,
        franchise_customer_edit: false,
        franchise_customer_delete: false,

        franchise_file_manager: false,
        franchise_file_add: false,
        franchise_file_edit: false,
        franchise_file_delete: false,

        franchise_report_manager: false,
        franchise_report_add: false,
        franchise_report_edit: false,
        franchise_report_delete: false,

        franchise_mail_manager: false,
        franchise_mail_add: false,
        franchise_mail_edit: false,
        franchise_mail_delete: false,

        franchise_meeting_manager: false,
        franchise_meeting_add: false,
        franchise_meeting_edit: false,
        franchise_meeting_delete: false,

        franchise_post_manager: false,
        franchise_post_add: false,
        franchise_post_edit: false,
        franchise_post_delete: false,

        franchise_help_manager: false,
        franchise_help_add: false,
        franchise_help_edit: false,
        franchise_help_delete: false,

        franchise_contact_manager: false,
        franchise_contact_delete: false,

        franchise_register_manager: false,
        franchise_register_delete: false,
        noti_birthday_user: false,
        noti_new_user: false,

        area_file_add: false,
        area_review_add: false,
        area_violate_add: false,

        qc_evaluate_main_manager: false,
        qc_evaluate_main_add: false,
        qc_evaluate_main_update: false,
        qc_evaluate_main_delete: false,
        qc_evaluate_group_manager: false,
        qc_evaluate_group_add: false,
        qc_evaluate_group_update: false,
        qc_evaluate_group_delete: false,
        qc_evaluate_manager: false,
        qc_evaluate_add: false,
        qc_evaluate_update: false,
        qc_evaluate_delete: false,

        probation_manager: false,
        probation_add: false,
        probation_edit: false,
        probation_delete: false,
      });
    }
  }, [item]);

  const customStyles = {
    content: {
      width: "80%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexFlow: "column",
    },
  };

  const group = (title, children) => {
    return (
      <div className="mt-3 col-3">
        <label style={{ fontWeight: "700" }}>{title}</label>
        {children.map((e) => {
          return e;
        })}
      </div>
    );
  };

  const itemPermission = ({ title, value, onChange }) => {
    return (
      <div className="col-12 mb-2" key={title}>
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="ml-2">{title}</span>
      </div>
    );
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="my-modal"
      overlayClassName="my-modal-overlay"
      style={customStyles}
    >
      <div className="modal-header">
        <h4 style={{ color: "white" }}>{title || ""}</h4>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{ overflowY: "auto", height: "100%", margin: "10px" }}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  name="value"
                  type="text"
                  className="form-control"
                  placeholder="Nhập tên quyền"
                  autoComplete="off"
                  value={titleRole ?? ""}
                  onChange={(e) => {
                    setTitleRole(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {area?.map((a) => {
              return (
                <div
                  key={a?.id}
                  onClick={() => handleChoiceArea(a?.id)}
                  style={{
                    margin: "5px",
                    cursor: "pointer",
                    backgroundColor: areaSelect.includes(a.id)
                      ? "#f9c114"
                      : "#DAD1D1",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  {a?.name}
                </div>
              );
            })}
          </div>
          <div className="mt-3">
            {group("Quản lý tổng", [
              itemPermission({
                title: "IS ADMIN",
                value: permission.is_admin ?? false,

                onChange: (e) => {
                  SetPermission({
                    ...permission,
                    is_admin: !(permission.is_admin ?? false),
                  });
                },
              }),
            ])}
          </div>
          {(permission.is_admin ?? false) === false ? (
            <div>
              <div className="d-flex flex-wrap mt-3">
                {group("Quản lý chi nhánh", [
                  itemPermission({
                    title: "Quản lý chi nhánh",
                    value: permission?.area_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_manager: !(permission.area_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm chi nhánh",
                    value: permission.area_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_add: !(permission.area_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa chi nhánh",
                    value: permission.area_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_edit: !(permission.area_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá chi nhánh",
                    value: permission.area_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_delete: !(permission.area_delete ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Nơi làm việc của nhân viên",
                    value: permission.area_timekeeping_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_timekeeping_manager: !(
                          permission.area_timekeeping_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm file tài liệu",
                    value: permission.area_file_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_file_add: !(permission.area_file_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm đánh giá",
                    value: permission.area_review_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_review_add: !(permission.area_review_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm vi phạm",
                    value: permission.area_violate_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        area_violate_add: !(
                          permission.area_violate_add ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý nhân viên", [
                  itemPermission({
                    title: "Thống kê nhân viên",
                    value: permission.manager_staff ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        manager_staff: !(permission.manager_staff ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Nhận thông báo hết hạn hợp đồng",
                    value: permission.contract_expiration ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        contract_expiration: !(
                          permission.contract_expiration ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Nhận thông báo sinh nhật",
                    value: permission.noti_birthday_user ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        noti_birthday_user: !(
                          permission.noti_birthday_user ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Nhận thông báo nhân viên mới",
                    value: permission.noti_new_user ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        noti_new_user: !(permission.noti_new_user ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Danh sách nhân viên",
                    value: permission.staff_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        staff_manager: !(permission.staff_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm nhân viên",
                    value: permission.staff_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        staff_add: !(permission.staff_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa thông tin nhân viên",
                    value: permission.staff_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        staff_edit: !(permission.staff_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa hợp đồng nhân viên",
                    value: permission.edit_contract ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        edit_contract: !(permission.edit_contract ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá nhân viên",
                    value: permission.staff_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        staff_delete: !(permission.staff_delete ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Cho phép chọn phân quyền",
                    value: permission.choose_role ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        choose_role: !(permission.choose_role ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý yêu cầu", [
                  itemPermission({
                    title: "Danh sách yêu cầu",
                    value: permission.form_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        form_manager: !(permission.form_manager ?? false),
                      });
                    },
                  }),

                  itemPermission({
                    title: "Yêu cầu nhân sự",
                    value: permission.personal_form_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        personal_form_manager: !(
                          permission.personal_form_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Yêu cầu vận hành",
                    value: permission.operate_form_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        operate_form_manager: !(
                          permission.operate_form_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Yêu cầu báo cáo",
                    value: permission.report_form_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        report_form_manager: !(
                          permission.report_form_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa yêu cầu",
                    value: permission.form_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        form_edit: !(permission.form_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm yêu cầu",
                    value: permission.form_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        form_add: !(permission.form_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá yêu cầu",
                    value: permission.form_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        form_delete: !(permission.form_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý quy trình thử việc", [
                  itemPermission({
                    title: "Quản lý đánh giá thử việc",
                    value: permission.probation_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        probation_manager: !(
                          permission.probation_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm mới quy trình thử việc",
                    value: permission.probation_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        probation_add: !(permission.probation_add ?? false),
                      });
                    },
                  }),

                  itemPermission({
                    title: "Chỉnh sử quy trình thử việc",
                    value: permission.probation_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        probation_edit: !(permission.probation_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá quy trình thử việc",
                    value: permission.probation_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        probation_delete: !(
                          permission.probation_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý biên bản/Thông tin", [
                  itemPermission({
                    title: "Danh sách biên bản/TT",
                    value: permission.incident_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        incident_manager: !(
                          permission.incident_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Lọc người gửi Biên bản/TT",
                    value: permission.filter_person_incident ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        filter_person_incident: !(
                          permission.filter_person_incident ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Tạo biên bản",
                    value: permission.add_incident_1 ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        add_incident_1: !(permission.add_incident_1 ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Tạo thông tin",
                    value: permission.add_incident_2 ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        add_incident_2: !(permission.add_incident_2 ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá biên bản/thông tin",
                    value: permission.incident_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        incident_delete: !(permission.incident_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý ca làm việc", [
                  itemPermission({
                    title: "Danh sách ca làm việc",
                    value: permission.shifts_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        shifts_manager: !(permission.shifts_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm ca làm việc",
                    value: permission.shifts_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        shifts_add: !(permission.shifts_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa ca làm việc",
                    value: permission.shifts_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        shifts_edit: !(permission.shifts_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá ca làm việc",
                    value: permission.shifts_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        shifts_delete: !(permission.shifts_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý lịch làm việc", [
                  itemPermission({
                    title: "Danh sách lịch làm việc",
                    value: permission.calender_shifts_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calender_shifts_manager: !(
                          permission.calender_shifts_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm lịch làm việc",
                    value: permission.calender_shifts_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calender_shifts_add: !(
                          permission.calender_shifts_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa lịch làm việc",
                    value: permission.calender_shifts_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calender_shifts_edit: !(
                          permission.calender_shifts_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá lịch làm việc",
                    value: permission.calender_shifts_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calender_shifts_delete: !(
                          permission.calender_shifts_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý bảng công", [
                  itemPermission({
                    title: "Danh sách bảng công",
                    value: permission.calculate_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calculate_manager: !(
                          permission.calculate_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Bổ sung chấm công hộ",
                    value: permission.calculate_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        calculate_edit: !(permission.calculate_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chạy lại dữ liệu bảng công",
                    value: permission.reset_contract ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        reset_contract: !(permission.reset_contract ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý phòng ban", [
                  itemPermission({
                    title: "Danh sách phòng ban",
                    value: permission.department_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        department_manager: !(
                          permission.department_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm phòng ban",
                    value: permission.department_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        department_add: !(permission.department_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa phòng ban",
                    value: permission.department_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        department_edit: !(permission.department_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá phòng ban",
                    value: permission.department_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        department_delete: !(
                          permission.department_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt chung", [
                  itemPermission({
                    title: "Truy cập cài đặt chung",
                    value: permission.manager_config_general ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        manager_config_general: !(
                          permission.manager_config_general ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Truy cập lịch sử hệ thống",
                    value: permission.history_system_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        history_system_manager: !(
                          permission.history_system_manager ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt thưởng", [
                  itemPermission({
                    title: "Danh sách thưởng",
                    value: permission.bonus_config_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        bonus_config_manager: !(
                          permission.bonus_config_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm thưởng",
                    value: permission.bonus_config_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        bonus_config_add: !(
                          permission.bonus_config_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa thưởng",
                    value: permission.bonus_config_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        bonus_config_edit: !(
                          permission.bonus_config_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá thưởng",
                    value: permission.bonus_config_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        bonus_config_delete: !(
                          permission.bonus_config_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt phụ cấp", [
                  itemPermission({
                    title: "Danh sách phụ cấp",
                    value: permission.support_config_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        support_config_manager: !(
                          permission.support_config_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm phụ cấp",
                    value: permission.support_config_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        support_config_add: !(
                          permission.support_config_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa phụ cấp",
                    value: permission.support_config_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        support_config_edit: !(
                          permission.support_config_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá phụ cấp",
                    value: permission.support_config_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        support_config_delete: !(
                          permission.support_config_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt ngày lễ", [
                  itemPermission({
                    title: "Danh sách ngày lễ",
                    value: permission.ot_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        ot_manager: !(permission.ot_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm ngày lễ",
                    value: permission.ot_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        ot_add: !(permission.ot_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa ngày lễ",
                    value: permission.ot_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        ot_edit: !(permission.ot_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá ngày lễ",
                    value: permission.ot_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        ot_delete: !(permission.ot_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt phân quyền", [
                  itemPermission({
                    title: "Danh sách phân quyền",
                    value: permission.decent_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        decent_manager: !(permission.decent_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm phân quyền",
                    value: permission.decent_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        decent_add: !(permission.decent_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa phân quyền",
                    value: permission.decent_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        decent_edit: !(permission.decent_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá phân quyền",
                    value: permission.decent_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        decent_delete: !(permission.decent_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt yêu cầu", [
                  itemPermission({
                    title: "Cấu hình yêu cầu",
                    value: permission.config_procedure_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        config_procedure_manager: !(
                          permission.config_procedure_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm quy trình",
                    value: permission.config_procedure_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        config_procedure_add: !(
                          permission.config_procedure_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa quy trình",
                    value: permission.config_procedure_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        config_procedure_edit: !(
                          permission.config_procedure_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá quy trình",
                    value: permission.config_procedure_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        config_procedure_delete: !(
                          permission.config_procedure_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Cài đặt mã khuyến mãi", [
                  itemPermission({
                    title: "Danh sách mã khuyến mãi",
                    value: permission.coupon_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        coupon_manager: !(permission.coupon_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm mã khuyến mãi",
                    value: permission.coupon_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        coupon_add: !(permission.coupon_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa mã khuyến mãi",
                    value: permission.coupon_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        coupon_edit: !(permission.coupon_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá mã khuyến mãi",
                    value: permission.coupon_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        coupon_delete: !(permission.coupon_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý bảng lương", [
                  itemPermission({
                    title: "Danh sách bảng lương (Export)",
                    value: permission.salary_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        salary_manager: !(permission.salary_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm bảng lương",
                    value: permission.salary_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        salary_add: !(permission.salary_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa bảng lương",
                    value: permission.salary_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        salary_edit: !(permission.salary_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá bảng lương",
                    value: permission.salary_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        salary_delete: !(permission.salary_delete ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Gửi bảng lương",
                    value: permission.send_salary_table ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        send_salary_table: !(
                          permission.send_salary_table ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Lịch sử chấm công", [
                  itemPermission({
                    title: "Danh sách lịch sử chấm công",
                    value: permission.timekeeping_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        timekeeping_manager: !(
                          permission.timekeeping_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá lịch sử chấm công",
                    value: permission.timekeeping_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        timekeeping_delete: !(
                          permission.timekeeping_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý thiết bị", [
                  itemPermission({
                    title: "Danh sách thiết bị nhân viên",
                    value: permission.device_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        device_manager: !(permission.device_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá thiết bị nhân viên",
                    value: permission.device_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        device_delete: !(permission.device_delete ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xếp ca và chấm công",
                    value: permission.table_timekeeping_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        table_timekeeping_manager: !(
                          permission.table_timekeeping_manager ?? false
                        ),
                      });
                    },
                  }),
                ])}

                {group("Quản lý câu hỏi thường gặp", [
                  itemPermission({
                    title: "Quản lý câu hỏi thường gặp",
                    value: permission?.help_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        help_manager: !(permission.help_manager ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm câu hỏi thường gặp",
                    value: permission.help_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        help_add: !(permission.help_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa câu hỏi thường gặp",
                    value: permission.help_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        help_edit: !(permission.help_edit ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá câu hỏi thường gặp",
                    value: permission.help_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        help_delete: !(permission.help_delete ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý đánh giá từ khách hàng", [
                  itemPermission({
                    title: "Báo cáo đánh giá từ khách hàng",
                    value: permission.review_from_customer ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        review_from_customer: !(
                          permission.review_from_customer ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Cài đặt câu hỏi",
                    value: permission.review_question_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        review_question_add: !(
                          permission.review_question_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Lịch sử đánh giá từ khách hàng",
                    value: permission.review_history ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        review_history: !(permission.review_history ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý đào tạo", [
                  itemPermission({
                    title: "Quản lý banner",
                    value: permission.training_banner ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_banner: !(permission.training_banner ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Tổng hợp kết quả",
                    value: permission.training_all_result ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_all_result: !(
                          permission.training_all_result ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Nhóm khoá học",
                    value: permission.training_group ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_group: !(permission.training_group ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Bài test định kỳ",
                    value: permission.training_test ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_test: !(permission.training_test ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Bài viết",
                    value: permission.training_post ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_post: !(permission.training_post ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Đánh giá",
                    value: permission.training_review ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_review: !(permission.training_review ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Bình luận bài học",
                    value: permission.training_cmt ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_cmt: !(permission.training_cmt ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Bình luận bài viết",
                    value: permission.training_cmt_post ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_cmt_post: !(
                          permission.training_cmt_post ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Góp ý",
                    value: permission.training_help ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        training_help: !(permission.training_help ?? false),
                      });
                    },
                  }),
                ])}
                {group("Quản lý QC đánh giá", [
                  itemPermission({
                    title: "Quản lý bài đánh giá",
                    value: permission.qc_evaluate_main_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_main_manager: !(
                          permission.qc_evaluate_main_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm bài đánh giá",
                    value: permission.qc_evaluate_main_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_main_add: !(
                          permission.qc_evaluate_main_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Sửa bài đánh giá",
                    value: permission.qc_evaluate_main_update ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_main_update: !(
                          permission.qc_evaluate_main_update ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá bài đánh giá",
                    value: permission.qc_evaluate_main_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_main_delete: !(
                          permission.qc_evaluate_main_delete ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Quản lý nhóm đánh giá",
                    value: permission.qc_evaluate_group_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_group_manager: !(
                          permission.qc_evaluate_group_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm nhóm đánh giá",
                    value: permission.qc_evaluate_group_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_group_add: !(
                          permission.qc_evaluate_group_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Sửa nhóm đánh giá",
                    value: permission.qc_evaluate_group_update ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_group_update: !(
                          permission.qc_evaluate_group_update ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá nhóm đánh giá",
                    value: permission.qc_evaluate_group_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_group_delete: !(
                          permission.qc_evaluate_group_delete ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Quản lý tiêu chí đánh giá",
                    value: permission.qc_evaluate_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_manager: !(
                          permission.qc_evaluate_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm tiêu chí đánh giá",
                    value: permission.qc_evaluate_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_add: !(permission.qc_evaluate_add ?? false),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Sửa tiêu chí đánh giá",
                    value: permission.qc_evaluate_update ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        qc_evaluate_update: !(
                          permission.qc_evaluate_update ?? false
                        ),
                      });
                    },
                  }),
                ])}
              </div>
              <h6 className="my-4">Phân quyền nhượng quyền:</h6>
              <div className="d-flex flex-wrap mt-0">
                {group("Quản lý khách hàng", [
                  itemPermission({
                    title: "Quản lý khách hàng",
                    value: permission?.franchise_customer_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_customer_manager: !(
                          permission.franchise_customer_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm khách hàng",
                    value: permission.franchise_customer_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_customer_add: !(
                          permission.franchise_customer_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa khách hàng",
                    value: permission.franchise_customer_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_customer_edit: !(
                          permission.franchise_customer_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá khách hàng",
                    value: permission.franchise_customer_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_customer_delete: !(
                          permission.franchise_customer_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý file", [
                  itemPermission({
                    title: "Quản lý file",
                    value: permission?.franchise_file_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_file_manager: !(
                          permission.franchise_file_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm file",
                    value: permission.franchise_file_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_file_add: !(
                          permission.franchise_file_add ?? false
                        ),
                      });
                    },
                  }),

                  itemPermission({
                    title: "Xoá file",
                    value: permission.franchise_file_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_file_delete: !(
                          permission.franchise_file_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý báo cáo", [
                  itemPermission({
                    title: "Quản lý báo cáo",
                    value: permission?.franchise_report_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_report_manager: !(
                          permission.franchise_report_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm báo cáo",
                    value: permission.franchise_report_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_report_add: !(
                          permission.franchise_report_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa báo cáo",
                    value: permission.franchise_report_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_report_edit: !(
                          permission.franchise_report_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá báo cáo",
                    value: permission.franchise_report_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_report_delete: !(
                          permission.franchise_report_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý trao đổi thông tin", [
                  itemPermission({
                    title: "Quản lý trao đổi thông tin",
                    value: permission?.franchise_mail_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_mail_manager: !(
                          permission.franchise_mail_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm trao đổi",
                    value: permission.franchise_mail_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_mail_add: !(
                          permission.franchise_mail_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa trao đổi",
                    value: permission.franchise_mail_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_mail_edit: !(
                          permission.franchise_mail_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá trao đổi",
                    value: permission.franchise_mail_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_mail_delete: !(
                          permission.franchise_mail_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý lịch họp", [
                  itemPermission({
                    title: "Quản lý lịch họp",
                    value: permission?.franchise_meeting_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_meeting_manager: !(
                          permission.franchise_meeting_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm lịch họp",
                    value: permission.franchise_meeting_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_meeting_add: !(
                          permission.franchise_meeting_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa lịch họp",
                    value: permission.franchise_meeting_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_meeting_edit: !(
                          permission.franchise_meeting_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá lịch họp",
                    value: permission.franchise_meeting_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_meeting_delete: !(
                          permission.franchise_meeting_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý thông tin, tin tức", [
                  itemPermission({
                    title: "Quản lý thông tin, tin tức",
                    value: permission?.franchise_post_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_post_manager: !(
                          permission.franchise_post_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm thông tin, tin tức",
                    value: permission.franchise_post_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_post_add: !(
                          permission.franchise_post_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa thông tin, tin tức",
                    value: permission.franchise_post_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_post_edit: !(
                          permission.franchise_post_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá thông tin, tin tức",
                    value: permission.franchise_post_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_post_delete: !(
                          permission.franchise_post_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý câu hỏi thường gặp", [
                  itemPermission({
                    title: "Quản lý câu hỏi thường gặp",
                    value: permission?.franchise_help_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_help_manager: !(
                          permission.franchise_help_manager ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Thêm câu hỏi thường gặp",
                    value: permission.franchise_help_add ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_help_add: !(
                          permission.franchise_help_add ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Chỉnh sửa câu hỏi thường gặp",
                    value: permission.franchise_help_edit ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_help_edit: !(
                          permission.franchise_help_edit ?? false
                        ),
                      });
                    },
                  }),
                  itemPermission({
                    title: "Xoá câu hỏi thường gặp",
                    value: permission.franchise_help_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_help_delete: !(
                          permission.franchise_help_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý liên hệ và hỗ trợ", [
                  itemPermission({
                    title: "Quản lý liên hệ và hỗ trợ",
                    value: permission?.franchise_contact_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_contact_manager: !(
                          permission.franchise_contact_manager ?? false
                        ),
                      });
                    },
                  }),

                  itemPermission({
                    title: "Xoá liên hệ và hỗ trợ",
                    value: permission.franchise_contact_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_contact_delete: !(
                          permission.franchise_contact_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
                {group("Quản lý DS đăng ký", [
                  itemPermission({
                    title: "Quản lý DS đăng ký",
                    value: permission?.franchise_register_manager ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_register_manager: !(
                          permission.franchise_register_manager ?? false
                        ),
                      });
                    },
                  }),

                  itemPermission({
                    title: "Xoá DS đăng ký",
                    value: permission.franchise_register_delete ?? false,
                    onChange: (e) => {
                      SetPermission({
                        ...permission,
                        franchise_register_delete: !(
                          permission.franchise_register_delete ?? false
                        ),
                      });
                    },
                  }),
                ])}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={closeModal}
          >
            Đóng
          </button>
          <button
            type="submit"
            className="btn btn-info"
            onClick={(e) => {
              onSubmit();
            }}
          >
            Lưu
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRole;
