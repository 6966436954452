export default function DeleteIcon({ className, ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7017 1.875H10.3579V1.40625C10.3579 0.630844 9.72707 0 8.95166 0H5.20166C4.42625 0 3.79541 0.630844 3.79541 1.40625V1.875H1.45166C0.676254 1.875 0.0454102 2.50584 0.0454102 3.28125C0.0454102 3.904 0.452441 4.43316 1.01432 4.61753L1.85041 14.7105C1.91063 15.4336 2.52619 16 3.25179 16H10.9015C11.6272 16 12.2427 15.4336 12.303 14.7103L13.139 4.6175C13.7009 4.43316 14.1079 3.904 14.1079 3.28125C14.1079 2.50584 13.4771 1.875 12.7017 1.875ZM4.73291 1.40625C4.73291 1.14778 4.94319 0.9375 5.20166 0.9375H8.95166C9.21013 0.9375 9.42041 1.14778 9.42041 1.40625V1.875H4.73291V1.40625ZM11.3687 14.6327C11.3486 14.8737 11.1434 15.0625 10.9015 15.0625H3.25179C3.00994 15.0625 2.80475 14.8737 2.78472 14.6329L1.96085 4.6875H12.1925L11.3687 14.6327ZM12.7017 3.75H1.45166C1.19319 3.75 0.98291 3.53972 0.98291 3.28125C0.98291 3.02278 1.19319 2.8125 1.45166 2.8125H12.7017C12.9601 2.8125 13.1704 3.02278 13.1704 3.28125C13.1704 3.53972 12.9601 3.75 12.7017 3.75Z"
        fill="#FD3C3C"
      />
      <path 
        d="M6.12413 13.6273L5.65538 6.06477C5.63935 5.80636 5.41569 5.60983 5.15854 5.62592C4.90013 5.64195 4.70366 5.86439 4.71966 6.12277L5.18841 13.6853C5.20382 13.9338 5.41019 14.125 5.65582 14.125C5.92729 14.125 6.14079 13.8964 6.12413 13.6273Z"
        fill="#FD3C3C"
      />
      <path
        d="M8 5.625C7.74112 5.625 7.53125 5.83487 7.53125 6.09375V13.6562C7.53125 13.9151 7.74112 14.125 8 14.125C8.25888 14.125 8.46875 13.9151 8.46875 13.6562V6.09375C8.46875 5.83487 8.25888 5.625 8 5.625Z"
        fill="#FD3C3C"
      />
      <path
        d="M10.8415 5.62592C10.5837 5.60988 10.3607 5.80635 10.3447 6.06476L9.87591 13.6273C9.85994 13.8856 10.0564 14.1081 10.3148 14.1241C10.5733 14.1401 10.7956 13.9435 10.8116 13.6853L11.2804 6.12276C11.2964 5.86435 11.0999 5.64192 10.8415 5.62592Z"
        fill="#FD3C3C"
      />
    </svg>
  );
}
