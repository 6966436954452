import React, { useState, useEffect } from "react";
import "./style.css";
import { Tab, Tabs, TabList } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { SelectPicker } from "rsuite";
import moment from "moment";
import { MdOutlineClear } from "react-icons/md";
import { BiFilterAlt } from "react-icons/bi";
import { HiFilter } from "react-icons/hi";
import { incidentServices } from "../../services/incidentServices";
import DialogYesNo from "../Procedure/child/DialogYesNo";
import EmployeeFormModal from "../Procedure/FormPage/child/EmployeeFormModal";
import IncidentItem from "./child/IncidentItem";
import SearchInputForm from "../Procedure/FormPage/child/SearchInputForm";
import { constants } from "../../constants";
import ViewDate from "../../components/ViewDate";
import { shiftServices } from "../../services/shiftServices";
import IncidentModal from "./child/IncidentModal";
import IncidentCreateModal from "./child/IncidentCreateModal";
import PermissionChecker from "../../components/PermissionChecker";
import { toast } from "react-toastify";

const IncidenPage = () => {
  const [listIncident, setListIncident] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [listIncidentSelected, setListIncidentSelected] = useState([]);
  const [badge, setBadge] = useState({
    num_form_progressing: 0,
    num_form_viewed: 0,
    num_form_approved: 0,
    num_form_rejected: 0,
  });
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    status: 0,
    page: 1,
    type: 3,
    from_time: "",
    to_time: "",
    staff_create: null,
    staff_relate: null,
    list_id_staff_create: [],
    list_id_staff_relate: [],
    list_id_staff_viewer: [],
    list_department_id: [],
  });

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);
  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      from_time: getFirstAndLastDayInMonth().firstDay,
      to_time: getFirstAndLastDayInMonth().lastDay,
      list_id_staff_create:
        (reportNumber.current_user?.id || null) != null
          ? [reportNumber.current_user?.id]
          : [],
    }));
    fetchDepartment();
    // getAllIncident({
    //   status: 1,
    //   pageInput: 1,
    //   type: "",
    //   reset: true,
    //   from: getFirstAndLastDayInMonth().firstDay,
    //   to: getFirstAndLastDayInMonth().lastDay,
    //   followDate: 1, // 0 follow create_at, 1 follow date request
    //   approveStaff: null,
    //   viewerStaff: null,
    //   staff: null,
    // });
  }, []);

  const getAllIncident = async ({
    search,
    type,
    status,
    pageInput,
    reset,
    from_time,
    to_time,
    list_id_staff_create,
    list_id_staff_relate,
    list_id_staff_viewer,
    list_department_id,
  }) => {
    setFilter((prevState) => ({
      ...prevState,
      search: search,
      status: status,
      type: type,
      page: pageInput,
      from_time: from_time,
      to_time: to_time,
      list_id_staff_create: list_id_staff_create,
      list_id_staff_relate: list_id_staff_relate,
      list_id_staff_viewer: list_id_staff_viewer,
      list_department_id: list_department_id,
    }));

    if (reset === true) {
      setFilter((prevState) => ({ ...prevState, page: 1 }));
    }

    let date_from = moment(from_time).format(constants.FORMAT_YYYY_MM_DD);
    let date_to = moment(to_time).format(constants.FORMAT_YYYY_MM_DD);

    var queryString = `?search=${search ?? ""}&type=${type ?? ""}&from_time=${
      date_from ?? ""
    }&to_time=${date_to ?? ""}&page=${pageInput ?? ""}&list_id_staff_create=${
      (list_id_staff_create ?? "") == ""
        ? [reportNumber.current_user?.id]
        : list_id_staff_create
    }&list_id_staff_relate=${list_id_staff_relate ?? ""}&list_id_staff_viewer=${
      list_id_staff_viewer ?? ""
    }&list_department_id=${list_department_id ?? ""}`;

    if (type == 3) {
      queryString = `?search=${search ?? ""}&from_time=${
        date_from ?? ""
      }&to_time=${date_to ?? ""}&page=${pageInput ?? ""}&list_id_staff_relate=${
        list_id_staff_relate ?? ""
      }&list_id_staff_viewer=${list_id_staff_viewer ?? ""}&list_department_id=${
        list_department_id ?? ""
      }&is_procedure=true`;
    }

    if (type == 4) {
      queryString = `?search=${search ?? ""}&from_time=${
        date_from ?? ""
      }&to_time=${date_to ?? ""}&page=${pageInput ?? ""}&list_id_staff_relate=${
        list_id_staff_relate ?? ""
      }&list_id_staff_viewer=${list_id_staff_viewer ?? ""}&list_department_id=${
        list_department_id ?? ""
      }&is_from_education=true`;
    }

    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await incidentServices.getAllIncident({
        param: queryString,
      });
      setBadge({
        num_form_progressing: response.num_form_progressing,
        num_form_viewed: response.num_form_viewed,
        num_form_approved: response.num_form_approved,
        num_form_rejected: response.num_form_rejected,
      });
      if (response.code != 200) {
        return;
      }
      if (response.data.next_page_url === null) {
        console.log("het page");
        setHasmore(false);
      } else {
        console.log("con page");
        setFilter((prevState) => ({ ...prevState, page: filter.page + 1 }));
        setHasmore(true);
      }
      if (reset === true) {
        setListIncident(response.data.data);
      } else {
        setListIncident((data) => [...data, ...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getFirstAndLastDayInMonth = () => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return { firstDay, lastDay };
  };

  const [modal, setModal] = useState({
    title: "",
    method: null,
    visible: false,
    process: null,
    indexInput: null,
  });

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const deleteIncidentMany = async () => {
    try {
      const response = await incidentServices.deleteIncidentMany({
        data: {
          list_form_id: listIncidentSelected.map((e) => e.id),
        },
      });
      setListIncidentSelected([]);
      handleCloseModal();
      getAllIncident({
        reset: true,
        status: filter.status,
        pageInput: 1,
        type: filter.type,
        from_time: filter.from_time,
        to_time: filter.to_time,
        list_id_staff_create: filter.list_id_staff_create,
        list_id_staff_relate: filter.list_id_staff_relate,
        list_id_staff_viewer: filter.list_id_staff_viewer,
        list_department_id: filter.list_department_id,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const acceptIncident = async (incidentId, isApprove) => {
    try {
      const response = await incidentServices.updateStatusIncident({
        incidentId: incidentId,
        data: {
          is_approve: isApprove,
        },
      });
      getAllIncident({
        reset: true,
        status: filter.status,
        pageInput: 1,
        type: filter.type,
        from_time: filter.from_time,
        to_time: filter.to_time,
        list_id_staff_create: filter.list_id_staff_create,
        list_id_staff_relate: filter.list_id_staff_relate,
        list_id_staff_viewer: filter.list_id_staff_viewer,
        list_department_id: filter.list_department_id,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await shiftServices.fetchAllDepartments();
      const result = response.data.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setDepartments(result);
    } catch (error) {
      toast.error(error);
    }
  };

  const onSubmitSearch = (value) => {
    getAllIncident({
      search: value,
      reset: true,
      status: filter.status,
      pageInput: 1,
      type: filter.type,
      from_time: filter.from_time,
      to_time: filter.to_time,
      list_id_staff_create: filter.list_id_staff_relate,
      list_id_staff_relate: filter.list_id_staff_relate,
      list_id_staff_viewer: filter.list_id_staff_viewer,
      list_department_id: filter.list_department_id,
    });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Biên bản và thông tin
        </h4>
        <div className="d-flex">
          {PermissionChecker({
            decent: reportNumber.permissions?.add_incident_1 ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                data-toggle="modal"
                data-target="#modalShift"
                onClick={() => {
                  setModal({
                    title: ``,
                    method: "CREATE",
                    visible: true,
                    type: 1,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">{"Tạo biên bản"}</span>
              </button>
            ),
          })}
          <div style={{ width: "10px" }}></div>
          {PermissionChecker({
            decent: reportNumber.permissions?.add_incident_2 ?? false,
            child: (
              <button
                class={`btn btn-info btn-icon-split btn-sm`}
                data-toggle="modal"
                data-target="#modalShift"
                onClick={() => {
                  setModal({
                    title: ``,
                    method: "CREATE",
                    visible: true,
                    type: 2,
                  });
                }}
              >
                <span class="icon text-white-50">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="text">{"Tạo thông tin"}</span>
              </button>
            ),
          })}
        </div>
      </div>
      <div style={{ height: "15px" }}></div>
      <div className="d-flex  align-items-center">
        <SearchInputForm
          placeHolder={"Tìm kiếm theo tiêu đề"}
          onSubmitSearch={onSubmitSearch}
        ></SearchInputForm>
        <div style={{ width: "10px" }}></div>
        <SelectPicker
          style={{ width: "180px" }}
          data={departments}
          placeholder="-- Chọn phòng ban --"
          block
          value={filter.departmentId}
          onChange={(e) => {
            getAllIncident({
              reset: true,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              from_time: filter.from_time,
              to_time: filter.to_time,
              list_id_staff_create: filter.list_id_staff_create,
              list_id_staff_relate: filter.list_id_staff_relate,
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: [e],
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <SelectPicker
          style={{ width: "200px" }}
          data={[
            {
              label: "Quy trình",
              value: 3,
            },
            {
              label: "Biên bản",
              value: 1,
            },
            {
              label: "Thông tin",
              value: 2,
            },
            {
              label: "Đào tạo",
              value: 4,
            },
          ]}
          value={filter.type}
          cleanable={false}
          searchable={false}
          onChange={(e) => {
            console.log(filter);
            getAllIncident({
              reset: true,
              status: filter.status,
              pageInput: 1,
              type: e,
              from_time: filter.from_time,
              to_time: filter.to_time,
              list_id_staff_create: filter.list_id_staff_create,
              list_id_staff_relate: filter.list_id_staff_relate,
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: filter.list_department_id,
            });
          }}
        />
        <div style={{ width: "10px" }}></div>
        <ViewDate
          className="d-flex justify-content-between"
          type={constants.MONTH}
          handleChange={(date) => {
            getAllIncident({
              reset: true,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              from_time: date.startDate,
              to_time: date.endDate,
              list_id_staff_create: filter.list_id_staff_create,
              list_id_staff_relate: filter.list_id_staff_relate,
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: filter.list_department_id,
            });
          }}
        />
        {listIncidentSelected.length >= 1 ? (
          PermissionChecker({
            decent: reportNumber.permissions?.incident_delete ?? false,
            child: (
              <button
                onClick={() => {
                  setModal({
                    title: `Bạn có chắc chắn muốn xoá ${listIncidentSelected.length} biên bản đã chọn chứ ?`,
                    method: "DELETE",
                    visible: true,
                  });
                }}
                className="button-handle"
              >
                {`Xoá (${listIncidentSelected.length})`}
              </button>
            ),
          })
        ) : (
          <></>
        )}
      </div>
      <div className="mb-2 pt-4">
        {/* <Tabs
          defaultIndex={0}
          onSelect={(index) => {
            getAllIncident({
              reset: true,
              status: index,
              pageInput: 1,
              type: filter.type,
              from_time: filter.from_time,
              to_time: filter.to_time,
              list_id_staff_create: filter.list_id_staff_create,
              list_id_staff_relate: filter.list_id_staff_relate,
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: filter.list_department_id,
            });
          }}
        >
          <TabList style={{ marginBottom: "0px" }}>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Đã gửi (${badge?.num_form_progressing ?? 0})`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Đã xem (${badge?.num_form_viewed ?? 0})`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Đã xác nhận (${badge?.num_form_approved ?? 0})`}</Tab>
            <Tab
              selectedClassName="selected-tab"
              style={{ fontWeight: "700" }}
            >{`Đã từ chối (${badge?.num_form_rejected ?? 0})`}</Tab>
          </TabList>
        </Tabs> */}
        <div style={{ width: "10px" }}></div>
        <div
          id="scrollableDiv"
          style={{
            height: `${(window.innerHeight * 76) / 100}px`,
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={listIncident.length}
            next={() => {
              getAllIncident({
                status: filter.status,
                pageInput: filter.page,
                type: filter.type,
                from_time: filter.from_time,
                to_time: filter.to_time,
                list_id_staff_create: filter.list_id_staff_create,
                list_id_staff_relate: filter.list_id_staff_relate,
                list_id_staff_viewer: filter.list_id_staff_viewer,
                list_department_id: filter.list_department_id,
              });
            }}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table>
              <colgroup>
                <col style={{ width: "20px", minWidth: "20px" }} />
                <col style={{ width: " 50px", minWidth: "50px" }} />
                <col
                  style={{
                    width: "15%",
                    minWidth: "100px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "250px",
                  }}
                />
                <col
                  style={{
                    width: "13%",
                    minWidth: "150px",
                  }}
                />
                <col
                  style={{
                    width: "17%",
                    minWidth: "150px",
                  }}
                />
                <col
                  style={{
                    width: "10%",
                    minWidth: "150px",
                  }}
                />
              </colgroup>
              <thead
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  padding: "10px !important",
                }}
              >
                <tr style={{ backgroundColor: "#f2f7ff", height: "50px" }}>
                  <th style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={
                        listIncident.length === listIncidentSelected.length
                      }
                      onClick={(e) => {
                        if (
                          listIncident.length === listIncidentSelected.length
                        ) {
                          setListIncidentSelected([]);
                        } else {
                          setListIncidentSelected(listIncident);
                        }
                      }}
                    />
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <span>STT</span>
                  </th>
                  <th>
                    <div className="d-flex flex-column">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => {
                          if (
                            (reportNumber.permissions?.filter_person_incident ??
                              false) == true
                          ) {
                            setModal({
                              title: ``,
                              method: "CREATE_FILTER",
                              visible: true,
                            });
                          } else {
                            toast.error(
                              "Bạn không có quyền truy cập chức năng này"
                            );
                          }
                        }}
                      >
                        <div>Người lập</div>
                        {filter?.type !== 3 && (
                          <>
                            {(filter?.staff_create?.name ?? "") !== "" ? (
                              <HiFilter color="blue"></HiFilter>
                            ) : (
                              <BiFilterAlt></BiFilterAlt>
                            )}
                          </>
                        )}
                      </div>
                      {(filter?.staff_create?.name ?? "") !== "" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "rgb(50 136 255)",
                            color: "white",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            maxWidth: "200px",
                          }}
                        >
                          <div>{filter.staff_create?.name ?? ""}</div>
                          <div
                            style={{
                              width: "10px",
                            }}
                          ></div>
                          <MdOutlineClear
                            onClick={(e) => {
                              getAllIncident({
                                reset: true,
                                status: filter.status,
                                pageInput: 1,
                                type: filter.type,
                                from_time: filter.from_time,
                                to_time: filter.to_time,
                                list_id_staff_create:
                                  filter.list_id_staff_relate,
                                list_id_staff_relate:
                                  filter.list_id_staff_relate,
                                list_id_staff_viewer:
                                  filter.list_id_staff_viewer,
                                list_department_id: filter.list_department_id,
                              });
                              setFilter({ ...filter, staff_create: null });
                            }}
                          ></MdOutlineClear>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                  <th>
                    <span>Tiêu đề</span>
                  </th>
                  <th>
                    <span>Nội dung</span>
                  </th>
                  <th>
                    <div className="d-flex flex-column">
                      <div
                        className="d-flex justify-content-start align-items-center"
                        onClick={(e) => {
                          setModal({
                            title: ``,
                            method: "RELATE_FILTER",
                            visible: true,
                          });
                        }}
                      >
                        <div>Người liên quan</div>
                        {(filter?.staff_relate?.name ?? "") !== "" ? (
                          <HiFilter color="blue"></HiFilter>
                        ) : (
                          <BiFilterAlt></BiFilterAlt>
                        )}
                      </div>

                      {(filter?.staff_relate?.name ?? "") !== "" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "rgb(50 136 255)",
                            color: "white",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            maxWidth: "200px",
                          }}
                        >
                          {filter.staff_relate?.name !== null ? (
                            <div>{filter.staff_relate?.name ?? ""}</div>
                          ) : (
                            <></>
                          )}
                          <div
                            style={{
                              width: "10px",
                            }}
                          ></div>
                          <MdOutlineClear
                            onClick={(e) => {
                              getAllIncident({
                                reset: true,
                                status: filter.status,
                                pageInput: 1,
                                type: filter.type,
                                from_time: filter.from_time,
                                to_time: filter.to_time,
                                list_id_staff_create:
                                  filter.list_id_staff_relate,
                                list_id_staff_relate:
                                  filter.list_id_staff_relate,
                                list_id_staff_viewer:
                                  filter.list_id_staff_viewer,
                                list_department_id: filter.list_department_id,
                              });
                              setFilter({ ...filter, staff_relate: null });
                            }}
                          ></MdOutlineClear>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                  <th>
                    <span>Địa điểm diễn ra</span>
                  </th>
                  <th>
                    <span>Thời gian</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listIncident.map((e, index) => {
                  console.log(e?.staff_create[0]?.staff?.name);
                  return (
                    <IncidentItem
                      key={index}
                      data={e}
                      index={index}
                      isSelected={listIncidentSelected
                        .map((e) => e.id)
                        .includes(e.id)}
                      onChoose={() => {
                        if (
                          listIncidentSelected.find((item) => item.id === e.id)
                        ) {
                          const newList = listIncidentSelected.filter(
                            (item) => item.id !== e.id
                          );
                          setListIncidentSelected(newList);
                        } else {
                          const newList = [...listIncidentSelected, e];
                          setListIncidentSelected(newList);
                        }
                      }}
                      onClick={(incident) => {
                        setModal({
                          title: ``,
                          method: "DETAIL",
                          visible: true,
                          incident: incident,
                        });
                      }}
                      onClickStaffRelate={(staff) => {
                        getAllIncident({
                          reset: true,
                          status: filter.status,
                          pageInput: 1,
                          type: filter.type,
                          from_time: filter.from_time,
                          to_time: filter.to_time,
                          list_id_staff_create: filter.list_id_staff_create,
                          list_id_staff_relate: [staff.id],
                          list_id_staff_viewer: filter.list_id_staff_viewer,
                          list_department_id: filter.list_department_id,
                        });
                        setFilter({ ...filter, staff_relate: staff });
                      }}
                      onClickStaffViewer={(staff) => {}}
                      accept={() => {
                        acceptIncident(e.id, true);
                      }}
                      cancel={() => {
                        acceptIncident(e.id, false);
                      }}
                    ></IncidentItem>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <DialogYesNo
        visible={modal.visible && modal.method === "DELETE"}
        title={modal.title}
        yes={(data) => {
          deleteIncidentMany();
        }}
        no={handleCloseModal}
      />
      <IncidentModal
        visible={modal.visible && modal.method === "DETAIL"}
        incident={modal?.incident}
        departmentsInput={departments}
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleCloseModal}
      ></IncidentModal>
      <IncidentCreateModal
        visible={modal.visible && modal.method === "CREATE"}
        incident={modal?.incident}
        departmentsInput={departments}
        type={modal.type}
        onSuccess={() => {
          getAllIncident({
            reset: true,
            status: filter.status,
            pageInput: 1,
            type: filter.type,
            from_time: filter.from_time,
            to_time: filter.to_time,
            list_id_staff_create: filter.list_id_staff_create,
            list_id_staff_relate: filter.list_id_staff_relate,
            list_id_staff_viewer: filter.list_id_staff_viewer,
            list_department_id: filter.list_department_id,
          });
          handleCloseModal();
        }}
        closeModal={handleCloseModal}
      ></IncidentCreateModal>
      <EmployeeFormModal
        visible={
          modal.visible &&
          (modal.method === "CREATE_FILTER" || modal.method === "RELATE_FILTER")
        }
        departmentsInput={departments}
        onSuccess={() => {
          // getProcedure();
        }}
        closeModal={handleCloseModal}
        handleChoice={(staff) => {
          if (modal.method === "CREATE_FILTER") {
            console.log(staff);
            getAllIncident({
              reset: true,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              from_time: filter.from_time,
              to_time: filter.to_time,
              list_id_staff_create: [staff.id],
              list_id_staff_relate: filter.list_id_staff_relate,
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: filter.list_department_id,
            });
            setFilter({ ...filter, staff_create: staff });
          } else {
            getAllIncident({
              reset: true,
              status: filter.status,
              pageInput: 1,
              type: filter.type,
              from_time: filter.from_time,
              to_time: filter.to_time,
              list_id_staff_create: filter.list_id_staff_create,
              list_id_staff_relate: [staff.id],
              list_id_staff_viewer: filter.list_id_staff_viewer,
              list_department_id: filter.list_department_id,
            });
            setFilter({ ...filter, staff_relate: staff });
          }

          handleCloseModal();
        }}
      ></EmployeeFormModal>
    </div>
  );
};

export default IncidenPage;
