import axios from "axios";
import { constants as c } from "../constants";

const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));

const requestOptions = ({ method, bodyData }) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      token: tokenInfo || "",
    },
  };
  bodyData && (options.body = JSON.stringify(bodyData));

  return options;
};

/* ------------------ WorkShift Api ----------------------------------- */
const fetchShifts = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/shift`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateShift = async ({ id, areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/shift/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      console.log("err", err);
      throw err;
    });
};

const createShift = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/shift`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteShift = async ({ id, areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/shift/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Department Api ----------------------------------- */

const fetchAllDepartments = async () => {
  return fetch(`${c.API_URL}/departments`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const createDepartments = async (data) => {
  return fetch(
    `${c.API_URL}/departments`,
    requestOptions({ method: "POST", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const deleteDepartments = async (id) => {
  return fetch(
    `${c.API_URL}/departments/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const updateDepartments = async (id, body) => {
  return fetch(
    `${c.API_URL}/departments/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Staffs Api ----------------------------------- */
const fetchAllStaffs = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/all/select`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Job Board Api ----------------------------------- */
const createBonusLess = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/timekeeping/bonus-less`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchCalculate = async ({ areaId, queryString }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/timekeeping/calculate${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchCalculateAll = async ({ queryString }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/timekeeping/calculate${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const exportSalary = ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/excel/export-all`,
    requestOptions({
      method: "POST",
      bodyData: body,
    })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const exportTimeKeeping = ({ areaId, data }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar-shift/excel/export`,
    requestOptions({ method: "POST", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Work Schedule ----------------------------------- */
const fetchCalendarShift = async (areaID, queryString) => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/calendar-shift${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchTimekeepingStaff = async (areaID, queryString) => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/timekeeping/list${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchEmployeeByArea = async (areaID, queryString = "") => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/calendar-shift/staff${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateEmployeeForShift = async (areaID, body) => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/calendar-shift/put-one-shift`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const addStaffToShift = async (areaID, body) => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/calendar-shift/add-staff`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createWorkManySchedule = async (body, areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar-shift/put-many`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

// Location

const fetchProvinces = async () => {
  return fetch(`${c.API_URL}/zone/province`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchDistricts = async (provinceId) => {
  return fetch(
    `${c.API_URL}/zone/province/${provinceId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchWarns = async (districtId) => {
  return fetch(
    `${c.API_URL}/zone/district/${districtId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchOptionContact = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/contract/option`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchStaffs = async ({ areaId, queryString = "" }) => {
  const url = `https://crm-api.studyguide.dev/api/staffs${queryString}`;
  return fetch(
    // `${c.API_URL}/personnel/${areaId}/staff${queryString}`,
    url,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchStaffsTemp = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchStaffsManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/staff${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getStaffById = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createEmployee = async (body, areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const updateEmployee = async (body, areaId, employeeId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${employeeId}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getStaffStatus = async (staff_id) => {
  return fetch(
    `${c.API_URL}/personnel/${0}/staff-status?staff_id=${staff_id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const addStaffStatus = async (body, areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff-status`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateStaffStatus = async (body, areaId, id) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff-status/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteStaffStatus = async (id) => {
  return fetch(
    `${c.API_URL}/personnel/${0}/staff-status/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const removeEmployee = async (areaId, employeeId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${employeeId}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchEmployeeById = async (areaId, employeeId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${employeeId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createContact = async (body, areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/contract`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchSupportConfig = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/support-config`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchContactDetail = async (areaId, staffId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/contract/detail?staff_id=${staffId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchListTimekeepingArea = async (areaId, staffId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${staffId}/area`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchListDevice = async (areaId, staffId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/device?staff_id=${staffId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteDevice = async (areaId, deviceId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/device/${deviceId}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteTimekeepingArea = async (areaId, staffId, areaTimekeepingId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${staffId}/area/remove`,
    requestOptions({
      method: "DELETE",
      bodyData: {
        area_id: areaTimekeepingId,
      },
    })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const addTimekeepingArea = async (areaId, staffId, areaTimekeepingId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/${staffId}/area/add`,
    requestOptions({
      method: "POST",
      bodyData: {
        area_id: areaTimekeepingId,
      },
    })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateContact = async (body, areaId, contractId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/contract/${contractId}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
/* ------------------ Request ----------------------------------- */

const fetchRequest = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/handle-request/timekeeping`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchRequestManager = async () => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/tracking-request`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const handleRequest = async (areaId, requestId, body) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/handle-request/timekeeping/${requestId}/status`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchDevice = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/handle-request/device`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchDeviceManager = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/device-request`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const removeDevice = async (areaId, deviceId, body) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/handle-request/device/${deviceId}/status`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
/* ------------------ Timekeeping ----------------------------------- */

const fetchTimekeeping = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/timekeeping/today`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchCalenderStaff = async (areaId, queryString) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchCalenderStaffById = async (areaId, id) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff/detail?timekeeping_id=${id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Salary Api ----------------------------------- */
async function fetchSalaryList({ areaId, queryString = "" }) {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

const deleteSalary = async ({ areaId, salaryId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/${salaryId}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

async function fetchBonusConfig({ areaId }) {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/bonus-config`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
}

const updateSalaryTable = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/update/status`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteSalaryMany = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/delete/many`,
    requestOptions({ method: "DELETE", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createBonusConfig = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/bonus-config`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateBonusConfig = async ({ areaId, id, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/bonus-config/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteBonusConfig = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/bonus-config/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

//

const createSupportConfig = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/support-config`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateSupportConfig = async ({ areaId, id, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/support-config/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteSupportConfig = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/support-config/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
//
const createSetupOT = async ({ areaId, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/ot`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateSetupOT = async ({ areaId, id, body }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/ot/${id}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteSetupOT = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/ot/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchSetupOT = async (areaId) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/ot`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getPayCheckByEmployee = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/staff/list`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const getPayCheckById = async ({ areaId, Id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/staff/${Id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

/* ------------------ Profile Api ----------------------------------- */

const getFullProfile = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/number`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const getRole = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const resetSalary = async ({ body, areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/reset`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createSalary = async ({ body, areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary`,
    requestOptions({ method: "POST", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const resetDataStaff = async ({ body, areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/timekeeping/history-many-staff`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateSalary = async ({ body, areaId, salaryId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/${salaryId}`,
    requestOptions({ method: "PUT", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getSalaryById = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/salary/${id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchHistoryTimeKeeping = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff/history${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getTimeKeepingById = async ({ areaId, historyId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff/history/detail?history_id=${historyId}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getSalaryOfStaff = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff/salary${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchRole = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchPermission = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role/permission/list`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchArea = async () => {
  return fetch(`${c.API_URL}/areas`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const fetchAreaById = async (id) => {
  return fetch(`${c.API_URL}/areas/${id}`, requestOptions({ method: "GET" }))
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const createRole = async ({ areaId, data }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role`,
    requestOptions({ method: "POST", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const updateRole = async ({ areaId, data, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role/${id}`,
    requestOptions({ method: "PUT", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteRole = async ({ areaId, id }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/role/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const exportExcel = async ({ areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/excel/get-all`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const exportMasterData = async (area_id) => {
  return fetch(
    `${c.API_URL}/personnel/export/staff_contracts?area_id=${area_id}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const importExcel = async ({ areaId, list_staffs }) => {
  console.log(list_staffs);
  return fetch(
    `${c.API_URL}/personnel/${areaId}/staff/excel/import`,
    requestOptions({ method: "POST", bodyData: { list_staffs: list_staffs } })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};
const listNotify = async (areaIdSelect) => {
  return fetch(
    `${c.API_URL}/personnel/${areaIdSelect}/notification`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const readNotify = async ({ areaId, notificationId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/notification/read/${notificationId}`,
    requestOptions({ method: "PUT" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const listNotifyManager = async () => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/notification`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeHaveShift = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/list-staff-shift${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeHaveShiftManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/list-staff-shift${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchNotTracking = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/list-staff-not-tracking${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchNotTrackingManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/list-staff-not-tracking${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeHaveTracking = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/list-staff-tracking${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeHaveTrackingManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/list-staff-tracking${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeLate = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/list-staff-start-late${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeLateManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/list-staff-start-late${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeEarly = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/report/list-staff-end-early${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const employeeEarlyManager = async ({ queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/manager/all/list-staff-end-early${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchTimeWorking = async ({ areaId, queryString = "" }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/calendar/staff/salary/time-working${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const fetchCalendarShiftTable = async (areaID, queryString) => {
  return fetch(
    `${c.API_URL}/personnel/${areaID}/calendar-shift/table${queryString}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteTimekeepingStaff = async ({ body, areaId }) => {
  return fetch(
    `${c.API_URL}/personnel/${areaId}/timekeeping/delete`,
    requestOptions({ method: "DELETE", bodyData: body })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getShiftGroups = async () => {
  return fetch(
    `${c.API_URL}/personnel/${0}/shift-group`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const addShiftGroup = async (data) => {
  return fetch(
    `${c.API_URL}/personnel/${0}/shift-group`,
    requestOptions({ method: "POST", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const updateShiftGroup = async (id, data) => {
  return fetch(
    `${c.API_URL}/personnel/${0}/shift-group/${id}`,
    requestOptions({ method: "PUT", bodyData: data })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const deleteShiftGroup = async (id) => {
  return fetch(
    `${c.API_URL}/personnel/${0}/shift-group/${id}`,
    requestOptions({ method: "DELETE" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

const getClockInColleague = async (page, search, areaId, month, year) => {
  return fetch(
    `${
      c.API_URL
    }/personnel/manager/all/clock-in-colleague?page=${page}&search=${
      search || ""
    }&area_id=${areaId || ""}&month=${month}&year=${year}`,
    requestOptions({ method: "GET" })
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    });
};

export const shiftServices = {
  fetchCalendarShift,
  fetchEmployeeByArea,
  updateEmployeeForShift,
  fetchShifts,
  updateShift,
  createShift,
  deleteShift,
  fetchAllDepartments,
  createWorkManySchedule,
  fetchAllStaffs,
  fetchProvinces,
  fetchDistricts,
  fetchWarns,
  fetchOptionContact,
  createEmployee,
  createContact,
  createBonusLess,
  fetchEmployeeById,
  updateEmployee,
  fetchCalculate,
  fetchRequest,
  handleRequest,
  fetchSupportConfig,
  fetchContactDetail,
  updateContact,
  fetchDevice,
  removeDevice,
  fetchStaffs,
  getStaffById,
  fetchStaffsTemp,
  fetchStaffsManager,
  removeEmployee,
  fetchTimekeeping,
  createDepartments,
  deleteDepartments,
  updateDepartments,
  fetchSalaryList,
  deleteSalary,
  getFullProfile,
  getRole,
  getPayCheckByEmployee,
  getPayCheckById,
  fetchBonusConfig,
  resetSalary,
  createSalary,
  resetDataStaff,
  getSalaryById,
  fetchCalenderStaff,
  fetchCalenderStaffById,
  fetchHistoryTimeKeeping,
  getTimeKeepingById,
  getSalaryOfStaff,
  createBonusConfig,
  updateBonusConfig,
  deleteBonusConfig,
  createSupportConfig,
  updateSupportConfig,
  deleteSupportConfig,
  createSetupOT,
  updateSetupOT,
  deleteSetupOT,
  fetchSetupOT,
  fetchRole,
  fetchPermission,
  fetchArea,
  fetchAreaById,
  createRole,
  updateRole,
  deleteRole,
  exportExcel,
  importExcel,
  listNotify,
  exportSalary,
  employeeHaveShift,
  employeeHaveShiftManager,
  employeeEarly,
  employeeHaveTracking,
  employeeLate,
  fetchTimeWorking,
  employeeHaveTrackingManager,
  employeeEarlyManager,
  employeeLateManager,
  listNotifyManager,
  fetchRequestManager,
  fetchDeviceManager,
  fetchNotTracking,
  fetchNotTrackingManager,
  readNotify,
  exportTimeKeeping,
  updateSalary,
  fetchCalendarShiftTable,
  fetchListDevice,
  deleteDevice,
  updateSalaryTable,
  fetchTimekeepingStaff,
  fetchListTimekeepingArea,
  deleteTimekeepingArea,
  addTimekeepingArea,
  deleteTimekeepingStaff,
  deleteSalaryMany,
  fetchCalculateAll,
  addStaffToShift,
  getShiftGroups,
  addShiftGroup,
  updateShiftGroup,
  deleteShiftGroup,
  getStaffStatus,
  addStaffStatus,
  updateStaffStatus,
  deleteStaffStatus,
  exportMasterData,
  getClockInColleague,
};
