import { constants as c } from "../constants";
const initialState = {
  list: {
    data: [],
    status: c.NONE,
  },
  info: {
    status: c.NONE,
  },



};
export function study(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_STUDIES_SUCCESS:
      return {
        ...state,
        list: {
          ...action.data,
          status: c.SUCCESS,
        },
      };

    case c.RESET_STUDY_STATUS:
      return {
        ...state,
        info: {
          
          status: c.NONE,
        },
      };
    case c.RESET_STUDIES_LIST_STATUS:
      return {
        ...state,
        list: {
          status: c.NONE,
        },
      };



    case c.GET_STUDY_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_ALL_STUDIES_FAILURE:
      return {
        ...state,
        list: {
          status: c.FAILURE,
        },
      };
    case c.GET_STUDY_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
        },
      };
    default:
      return state;
  }
}
