import React from "react";

const WorkLocation = () => {
  return (
    <div className="container-fluid">
      <h4 className="h4 title_content mb-0 text-gray-800">Địa điểm làm việc</h4>
      <div className="card shadow p-3">
        <p>Vui lòng thiết lập trong app</p>
      </div>
    </div>
  );
};

export default WorkLocation;
