import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tab, TabList, Tabs } from "react-tabs";
import { shiftServices } from "../../services/shiftServices";
import Contact from "./child/Contact";
import Profile from "./child/Profile";
import Salary from "./child/Salary";
import ListDevice from "./child/ListDevice";
import ListTimekeepingArea from "./child/ListTimekeepingArea";
import HistoryStaff from "./child/HistoryStaff";
import ChatHome from "./Chat";
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import expressionParser from "docxtemplater/expressions";
import robationaryContracts from "./robationaryContracts.docx";
import laborContract from "./laborContract.docx";
import serviceContract from "./serviceContract.docx";
import vocationalTrainingContract from "./vocationalTrainingContract.docx";
import { Button, Modal, Select } from "antd";

function convertDateFormat(dateString) {
  var parts = dateString.split("-");

  var year = parts[0];
  var month = parts[1];
  var day = parts[2];

  var newDateFormat = day + "-" + month + "-" + year;

  return newDateFormat;
}

const data = [
  robationaryContracts,
  laborContract,
  serviceContract,
  vocationalTrainingContract,
];

const ProfileNew = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [profile, setProfile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [template, setTemplate] = useState(null);
  const areaId = useSelector((state) => state.area.areaId);
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const fetchEmployee = async (staffId) => {
    try {
      const response = await shiftServices.fetchEmployeeById(areaId, staffId);
      setProfile(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const staffId = params.get("key");
    console.log("staffId", staffId);
    fetchEmployee(staffId);
  }, []);

  useEffect(() => {
    console.log("profile", profile);
  }, [profile]);

  const loadFile = (url) => {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);
        });
      });
  };

  const generateDocument = async (template) => {
    try {
      const fileName = template.toString().split("/")[3];
      const content = await loadFile(template);
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        parser: expressionParser,
      });
      doc.render({
        name: profile.name,
        birthday: convertDateFormat(profile.birthday) || "",
        cmnd: profile.cmnd || "",
        dateRange: convertDateFormat(profile.date_range) || "",
        dateRang: convertDateFormat(profile.date_range) || "",
        issuedBy: profile.issued_by || "",
        temporaryAddress: profile.temporary_address || "",
        position: profile.position || "",
        department: profile.department.name || "",
        food: profile.contract.pc_food.toLocaleString("vi-VN") || 0,
        move: profile.contract.pc_move.toLocaleString("vi-VN") || 0,
        phone: profile.contract.pc_phone.toLocaleString("vi-VN") || 0,
        salary: (
          parseFloat(profile.contract.support_productive ?? 0) +
          parseFloat(profile.contract.support_result ?? 0) +
          parseFloat(profile.contract.pc_food ?? 0) +
          parseFloat(profile.contract.pc_phone ?? 0) +
          parseFloat(profile.contract.pc_move ?? 0)
        ).toLocaleString("vi-VN"),
        result: parseFloat(profile.contract.support_result ?? 0),
        salaryBasic: parseFloat(profile.contract.support_productive ?? 0),
      });
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(out, fileName);
    } catch (error) {
      console.error("Error generating DOCX:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderView = (tab) => {
    switch (tab) {
      case 0:
        return <Profile profile={profile} />;
      case 1:
        return <Contact profile={profile} />;
      case 2:
        return <ListDevice profileInput={profile} />;
      case 3:
        return <ListTimekeepingArea profileInput={profile} />;
      case 4:
        return <HistoryStaff profileInput={profile} />;
      case 5:
        return <ChatHome profileInput={profile} />;
      default:
        break;
    }
  };

  return (
    <div className="container-fluid">
      <Modal
        title="Chọn kiểu export"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div style={{ display: "flex", gap: "16px" }}>
            <Select
              style={{ width: 200 }}
              showSearch
              placeholder="Chọn mẫu"
              style={{ width: 200 }}
              optionFilterProp="children"
              onChange={(value) => setTemplate(data[value])}
              options={[
                {
                  value: "0",
                  label: "Hợp đồng thử việc",
                },
                {
                  value: "1",
                  label: "Hợp đồng lao động",
                },
                {
                  value: "2",
                  label: "Hợp đồng dịch vụ",
                },
                {
                  value: "3",
                  label: "Hợp đồng đào tạo nghề",
                },
              ]}
              defaultValue={"0"}
            />
            <Button
              type="primary"
              onClick={() => {
                if (!template) {
                  return;
                }
                generateDocument(template);
              }}
              disabled={!template}
            >
              Export
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button type="primary" onClick={showModal}>
          Export
        </Button>
      </div>
      <div className="card shadow mb-4 pt-4 pb-4">
        <div className="card-tab">
          <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>Thông tin cá nhân</Tab>
              <Tab>Hợp đồng lao động</Tab>
              <Tab>Danh sách thiết bị</Tab>
              <Tab>Địa điểm chấm công</Tab>
              <Tab>Lịch sử nhân viên</Tab>
              {reportNumber?.permissions?.is_admin === true ? (
                <Tab>Chat</Tab>
              ) : null}
            </TabList>
          </Tabs>
        </div>
        <div className="card-body">{renderView(tabIndex)}</div>
      </div>
    </div>
  );
};

export default ProfileNew;
