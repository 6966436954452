import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { areaServices } from "../../../services/areaServices";
import { formatDate } from "../../../helpers";
import { constants } from "../../../constants";

const HistoryReviews = () => {
  const dispatch = useDispatch();

  const [histories, setHistories] = useState([]);
  const areaId = useSelector((state) => state.area.areaId);

  const fetchHistory = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await areaServices.fetchHistoryReviewAreas(areaId);
      setHistories(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [areaId]);

  return (
    <div className="table-responsive">
      <div id="dataTable_wrapper">
        <table
          className="table no-footer dataTable"
          id="dataTable"
          width="100%"
          cellSpacing={0}
          aria-describedby="dataTable_info"
        >
          <thead>
            <tr>
              <th>STT</th>
              <th className="text-center">Tên khách hàng</th>
              <th>Số điện thoại</th>
              <th>Ngày đánh giá</th>
              <th>Phản hồi</th>
            </tr>
          </thead>
          <tbody>
            {histories?.data?.map((history, index) => {
              return (
                <tr key={history?.id}>
                  <td>{index + 1}</td>
                  <td>{history?.name}</td>
                  <td>{history?.phone}</td>
                  <td>
                    {formatDate(
                      history?.created_at,
                      constants.FORMAT_DD_MM_YYYY
                    )}
                  </td>
                  <td>
                    {history?.answers?.map((answer) => {
                      return (
                        <div key={answer?.id}>
                          <div>
                            - {answer?.question}{" "}
                            <span style={{ color: "#F9C114" }}>
                              ({answer?.star || 0}{" "}
                              <i className="fa fa-star" aria-hidden="true"></i>)
                            </span>
                          </div>
                          {answer?.options?.map((option) => {
                            return <div>+ {option?.option}</div>;
                          })}
                          {answer?.feedback && (
                            <div>* Góp ý khác: {answer?.feedback}</div>
                          )}
                        </div>
                      );
                    })}
                    {history?.staff && (
                      <div>
                        - {history?.staff?.staff?.name} -{" "}
                        {history?.staff?.feedback} - (
                        <span style={{ color: "#F9C114" }}>
                          {history?.staff?.star || 0}
                          <i className="fa fa-star" aria-hidden="true"></i>)
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryReviews;
