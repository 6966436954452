import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { staffAction } from "../../../actions/staffActions";
import { useSelector, useDispatch } from "react-redux";
import SearchInput from "../../../components/SeachInput";
import { staffsServices } from "../../../services/staffsServices";
import { get, set } from "lodash";

export default function ListStaff(props) {
  const [choose, setChoose] = useState(0);
  const [require, setRequire] = useState(true);
  const [selectStaffs, setSelectStaffs] = useState([]);
  const [selectDepartments, setSelectDepartments] = useState([]);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [staffs, setStaffs] = useState({});
  const { departmentList } = props;

  useEffect(() => {
    getAllStaffs('', 1);
  }, []);

  function changeSelectStaffs(e, id) {
    var staffLists = [];
    var { checked } = e.target;
    if (id == null) {
      if (checked == true) {
        staffLists = staffs.data?.filter((v) => {
          return (
            selectStaffs.filter((_v) => {
              return v.id == _v;
            })?.length == 0
          );
        });

        staffLists = staffs.data?.map((v) => v.id);
        staffLists = [...selectStaffs, ...staffLists];
      } else {
        staffLists = selectStaffs.filter((v) => {
          return (
            staffs.data?.filter((_v) => {
              return parseInt(v) === parseInt(_v.id);
            })?.length === 0
          );
        });
      }
    } else {
      if (checked == true) staffLists = [...selectStaffs, id];
      else staffLists = selectStaffs.filter((v) => v !== id);
    }

    setSelectStaffs(staffLists);
  }

  function changeSelectDepartments(e, id) {
    var departmentLists = [];
    var { checked } = e.target;
    if (id == null) {
      if (checked == true) departmentLists = departmentList.map((v) => v.id);
      else departmentLists = [];
    } else {
      if (checked == true) departmentLists = [...selectDepartments, id];
      else departmentLists = selectDepartments.filter((v) => v !== id);
    }

    setSelectDepartments(departmentLists);
  }

  function checkedAll() {
    return (
      staffs.data?.filter((v) => {
        return (
          selectStaffs.filter((_v) => {
            return v.id == _v;
          })?.length > 0
        );
      })?.length === staffs.data?.length
    );
  }

  function showForStaffs() {
    var _selected = checkedAll();

    console.log(_selected);

    return (
      <tr>
        <th>
          {" "}
          <input
            type="checkbox"
            name=""
            id=""
            checked={_selected}
            onChange={(e) => changeSelectStaffs(e, null)}
          />
        </th>
        <th>Tên</th>
        <th>Số điện thoại</th>
        <th>Email</th>

        <th>Chức vụ</th>
      </tr>
    );
  }

  function showForDepartments() {
    return (
      <tr>
        <th>
          {" "}
          <input
            type="checkbox"
            name=""
            id=""
            onChange={(e) => changeSelectDepartments(e, null)}
          />
        </th>
        <th>Phòng ban</th>
      </tr>
    );
  }

  function checkedStaff(id) {
    return selectStaffs.filter((v) => v == id).length;
  }
  function checkedDepartment(id) {
    return selectDepartments.filter((v) => v == id).length;
  }
  function showStaffData(staffs) {
    console.log(staffs);
    return staffs?.map((v, i) => {
      return (
        <tr>
          <td>
            <input
              type="checkbox"
              name=""
              checked={checkedStaff(v.id)}
              onChange={(e) => changeSelectStaffs(e, v.id)}
            />
          </td>
          <td>{v.name}</td>
          <td>{v.phone_number}</td>
          <td>{v.email}</td>
          <td>{v.position}</td>
        </tr>
      );
    });
  }
  function showDepartmentData(departments) {
    return departments?.map((v, i) => {
      return (
        <tr>
          <td style={{ width: "50px" }}>
            <input
              type="checkbox"
              name=""
              id=""
              checked={checkedDepartment(v.id)}
              onChange={(e) => changeSelectDepartments(e, v.id)}
            />
          </td>
          <td>{v.name}</td>
        </tr>
      );
    });
  }

  function handleClickItem(index) {
    
    getAllStaffs(searchValue, index);
  }

  function createMulti() {
    var data = {};
    if (choose == "0")
      data = {
        list_user_id: selectStaffs,
        require: require,
      };
    else
      data = {
        list_deparment_id: selectDepartments,
        require: require,
      };

    props.createMulti(data);
  }

  

  const getAllStaffs = async (query, page) => {
    const param = `?page=${page}&search=${query}`;
    try {
      const res = await staffsServices.getAllStaffs(param);
  
     setStaffs(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <select
          onChange={(e) => setChoose(e.target.value)}
          name=""
          id=""
          value={choose}
          className="form-control"
          style={{ maxWidth: "300px" }}
        >
          <option value="0">Theo nhân viên</option>

          <option value="1">Theo phòng ban</option>
        </select>
        <div className="input-group mb-6" style={{ padding: "0px 20px" }}>
          <input
            type="search"
            value={searchValue}
            name="txtSearch"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="form-control"
            placeholder="Tìm kiếm"
            style={{
              maxWidth: "400px",
              minWidth: "300px",
            }}
          />
          <div className="input-group-append">
            <div
              className="btn btn-primary"
              onClick={() =>
                getAllStaffs(searchValue, 1)
              }
            >
              <i className="fa fa-search" />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className="table  table-hover table-border"
          style={{ color: "black" }}
        >
          <thead>{choose == 0 ? showForStaffs() : showForDepartments()}</thead>
          <tbody>
            {choose == 0
              ? showStaffData(staffs.data ?? [])
              : showDepartmentData(departmentList)}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {choose == 0 && (
          <Pagination
            to={staffs.to}
            handleClickItem={handleClickItem}
            links={staffs.links}
            lastPage={staffs?.last_page}
            currentPage={staffs?.current_page}
          ></Pagination>
        )}
        <div>
          <button
            style={{ marginLeft: "10px" }}
            type="button"
            onClick={createMulti}
            // style={{ float: "right" }}
            class="btn btn-info"
          >
            Lưu{" "}
          </button>
        </div>
      </div>
    </>
  );
}
