/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { chatActions as a } from "../../../../actions/chatActions";
import { useHistory } from "react-router-dom";

const InfoCustomerModal = ({ open, closeModal, onSuccess, customer_id }) => {
  const [loading, setLoading] = useState(false);
  const modalUserInfo = useSelector((state) => state.chat.modalUserInfo);
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const history = useHistory();

  const handleSendMessage = () => { 
    const onSuccess = (roomChat) => {
      dispatch(a.toggleModalUserInfo({ isOpen: false }));
      if (roomChat) {
        dispatch(a.selectedRoomChat(roomChat));
      } else {
        const paramsCreateRoom = {
          name_room: "",
          room_avatar: "",
          user_ids: [parseInt(customer_id), profile.id],
        };
        dispatch(a.createRoomChat(paramsCreateRoom));
      }
      closeModal();
      dispatch(a.toggleShowBox(true));
    };



    dispatch(a.checkSingleRoom({ staff_id: parseInt(customer_id)}, onSuccess));
  };

  return (
    <>
      <Modal
        title={"Chọn:"}
        centered
        open={open}
        width={600}
        style={{ top: 20 }}
        onCancel={() => {
          closeModal();
        }}
        onOk={() => {}}
        footer={null}
      >
        <div className="d-flex justify-content-center align-items-center">
          <Space>
            <Button loading={loading} onClick={() => {
              handleSendMessage();
            }}>
              Chat với khách hàng
            </Button>
            <Button loading={loading} onClick={() => {
              history.push(`/franchise-files?customer_id=${customer_id}`);
            }}>
              Xem tài liệu
            </Button>
            <Button loading={loading} onClick={() => {
                history.push(`/franchise-mails?customer_id=${customer_id}`);
            }}>
              Trao đổi thông tin
            </Button>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default InfoCustomerModal;
