/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { Input, Select } from "antd";
import { areaServices } from "../../../services/areaServices";

const AddHistoryModal = ({ open, closeModal, onOk, type }) => {
  useEffect(() => {}, [open]);

  const areaId = useSelector((state) => state.area.areaId);
  const [note, setNote] = useState(null);
  const [typeReview, setTypeReview] = useState(null);
  const [typeViolate, setTypeViolate] = useState(null);

  const addHistoryLogArea = async (data) => {
    try {
      const response = await areaServices.addHistoryLogArea(data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Modal
      title={type == 0 ? "Thêm đánh giá" : "Thêm vi phạm hành chính"}
      centered
      open={open}
      onOk={() => {
        addHistoryLogArea({
          area_id: areaId,
          note: note,
          type: type,
          type_review: type == 0 ? typeReview : null,
          type_violate: type == 1 ? typeViolate : null,
        });
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div>
        {type == 0 ? (
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span>Chọn nguồn đánh giá:</span>
            <div style={{ width: "10px" }}></div>
            <Select
              value={typeReview}
              placeholder="Chọn nguồn đánh giá"
            
              onChange={setTypeReview}
              options={[
                {
                  value: 1,
                  label: "QR tại quán",
                },
                {
                  value: 2,
                  label: "Google",
                },
                {
                  value: 3,
                  label: "App Food",
                },
                {
                  value: 4,
                  label: "Zalo",
                },
                {
                  value: 5,
                  label: "Fanpage",
                },
                {
                  value: 6,
                  label: "Hotline",
                },
                {
                  value: 7,
                  label: "Khác",
                },
              ]}
            />
          </div>
        ) : (
            <div className="d-flex justify-content-between align-items-center mb-3">
            <span>Chọn nguồn đánh giá:</span>
            <div style={{ width: "10px" }}></div>
            <Select
              value={typeViolate}
              placeholder="Chọn nguồn đánh giá"
              
              onChange={setTypeViolate}
              options={[
                {
                  value: 1,
                  label: "Vi phạm hành chính",
                },
                {
                  value: 2,
                  label: "Khác",
                },
              ]}
            />
          </div>
         
        )}

        <Input
          placeholder={type == 0 ? "Nhập đánh giá" : "Nhập vi phạm hành chính"}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default AddHistoryModal;
