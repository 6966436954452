/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { constants } from "../../constants";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "./mui-tabs.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import "./styles.css";
import { probationServices } from "../../services/probationServices";
import ConfigProbation from "./config";
import StepProbation from "./step";

const Probation = () => {
  const dispatch = useDispatch();

  const [listConfig, setListConfig] = useState([]);

  const getConfig = async () => {
    try {console.log('ssssss');
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await probationServices.getConfig({type_parent: ""});
      setListConfig(response.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  useEffect(() => {
    
    getConfig();
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabChild, setActiveChild] = useState(0);

  const onTabClick = (e, index) => {
    console.log(index);
    setActiveTab(index);
  };
  const onTabClickChild = (e, index) => {
    console.log(index);
    setActiveChild(index);
  };

  const renderView = () => {
    switch (activeTabChild) {
      case 0:
        return (
          <ConfigProbation
            configProcedure={listConfig[activeTab]}
            callBack={() => {
              getConfig();
            }}
          />
        );
      case 1:
        return <StepProbation configProcedure={listConfig[activeTab]} />;
      default:
        break;
    }
  };

  let drawerMethods = null;

  const openDrawerHandle = () => {
    drawerMethods.open();
  };

  return (
    <div className="container-fluid">
      <div className="card shadow">
        <div className="card-body">
          {/* <div>
            <Tabs
              activeTab={activeTab}
              onTabClick={onTabClick}
              hideNavBtnsOnMobile={false}
              leftBtnIcon={<FiChevronLeft size={"1.5em"} />}
              rightBtnIcon={<FiChevronRight size={"1.5em"} />}
            >
              {listConfig.map((item) => (
                <Tab key={item.id}> {item.title}</Tab>
              ))}
            </Tabs>
          </div> */}
          <div>
            <Tabs
              activeTab={activeTabChild}
              onTabClick={onTabClickChild}
              hideNavBtnsOnMobile={false}
              leftBtnIcon={<FiChevronLeft size={"1.5em"} />}
              rightBtnIcon={<FiChevronRight size={"1.5em"} />}
            >
              <Tab> Cấu hình</Tab>
              <Tab> Quy trình duyệt</Tab>
            </Tabs>
          </div>
          <div>{renderView()}</div>
        </div>
      </div>
      {/* <ModalRole
        visible={modal}
        onSubmitProps={(values) => {
          handleRole(values);
        }}
        closeModal={() => {
          setModal(false);
          setItemUpdate(null);
        }}
        data={permission}
        area={area}
        item={itemUpdate}
      /> */}
    </div>
  );
};

export default Probation;
