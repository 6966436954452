import styled from "styled-components";

export const StyledSidebarLeft = styled.div`
  width: 300px;
  flex-shrink: 0;
  border-right: 1px solid #ddd;
  position: relative;
  .contact-search-container {
    padding: 18px 16px ;
    border-bottom: 1px solid #ddd;
    // padding-bottom: 40px;
    .gap-1 {
      gap: 4px;
    }
    .contact-search {
      position: relative;
      flex: 1;
      &__input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        background-color: #eaedf0;
        border: 1px solid #eaedf0;
        padding: 6px 16px;
        padding-left: 30px;
        border-radius: 4px;
        &:focus {
          background-color: #fff;
          border: 1px solid #FAC20A;
        }
      }
      &__search-icon {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 20px;
      }
      &__close-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 14px;
        &:hover {
          color: #FAC20A;
        }
      }
    }
    .btn-feature-side {
      .btn {
        padding: 6px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #dfe2e7;
        }
      }
    }
  }
`;
