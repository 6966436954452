/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-ios-switch";
import { useForm } from "react-hook-form";
import { procedureServices } from "../../services/procedureServices";
import { constants } from "../../constants";
import PermissionChecker from "../../components/PermissionChecker";
import { toast } from "react-toastify";
import { Table, Space, Popconfirm, Button } from "antd";
import { probationServices } from "../../services/probationServices";
import moment from "moment";
import { MdAdd } from "react-icons/md";
import AddCategoryModal from "./child/AddCategoryModal";

const ConfigProbation = ({ configProcedure, callBack }) => {
  const [config, setConfig] = useState(null);
  const [settingLeave, setSettingLeave] = useState(null);
  const [init, setInit] = useState(true);
  const dispatch = useDispatch();
  const reportNumber = useSelector((state) => state.reportNumber.report);
  const [loading, setLoading] = useState(null);
  const [categories, setCategories] = useState(null);
  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const {
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setConfig(configProcedure);
    getProbationCategories();
  }, [configProcedure]);
  const updateConfig = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.updateConfig({
        id: config?.id,
        data: config,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
      toast.success("Cập nhật thành công");
      callBack();
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const updateSettingLeave = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.updateSettingLeave({
        data: settingLeave,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getSettingLeave = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await procedureServices.getSettingLeave();
      setSettingLeave({
        unpaid_leave: response.data[0]?.value,
        compassionate_leave: response.data[1]?.value,
        marriage_leave: response.data[2]?.value,
      });
      reset({
        unpaid_leave: response.data[0]?.value,
        compassionate_leave: response.data[1]?.value,
        marriage_leave: response.data[2]?.value,
      });
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const columns = [
    {
      title: "Tên hạng mục xem xét",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Số lần không đạt sẽ bị rớt thử việc",
      dataIndex: "failed_number",
      key: "failed_number",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => <a>{created_at ?? ""}</a>,
    },
    {
      title: "Hành động",
      key: "action",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={(e) => {
              e.stopPropagation();
              setModal({
                method: "ADD",
                visible: true,
                data: record.data,
              });
            }}
          >
            Sửa
          </a>
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_file_delete ?? false,
            child: (
              <Popconfirm
                title="Xoá hạng mục ?"
                description="Bạn có chắc chắn muốn xoá hạng mục này chứ ?"
                onConfirm={() => {
                  deleteProbationCategories(record.key);
                }}
                onCancel={() => {}}
                okText="Có"
                cancelText="Không"
              >
                <a style={{ color: "red" }}>Xoá</a>
              </Popconfirm>
            ),
          })}
        </Space>
      ),
    },
  ];

  const getProbationCategories = async () => {
    setLoading(true);
    try {
      const response = await probationServices.getProbationCategories();
      setCategories(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteProbationCategories = async (id) => {
    setLoading(true);
    try {
      const response = await probationServices.deleteProbationCategory(id);
      setCategories(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  return (
    <div
      className="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
      style={{ marginLeft: "0%" }}
    >
      <div
        role="tabpanel"
        aria-hidden="false"
        className="ant-tabs-tabpane ant-tabs-tabpane-active pt-3"
      >
        <div
          tabIndex={0}
          role="presentation"
          style={{
            width: "0px",
            height: "0px",
            overflow: "hidden",
            position: "absolute",
          }}
        />
        <div className="d-flex justify-content-between alignt-items-center">
          <div
            className="mb-3"
            style={{ fontSize: "18px", fontWeight: "700", color: "#10a0b5" }}
          >
            Cấu hình
          </div>
          {PermissionChecker({
            decent: reportNumber.permissions?.franchise_customer_add ?? false,
            child: (
              <Button
                type="primary"
                shape="round"
                size={20}
                icon={<MdAdd />}
                onClick={(e) => {
                  setModal({
                    method: "ADD",
                    visible: true,
                    data: null,
                  });
                }}
              >
                Thêm hạng mục
              </Button>
            ),
          })}
        </div>
        <div>
          <div
            className="ant-card mx-2 mt-1 mb-3 color-gray-13 w-70 ant-card-bordered"
            style={{ borderRadius: "10px", borderColor: "var(--color-gray-3)" }}
          >
            <div className="ant-card-body">
              <Table
                loading={loading}
                columns={columns}
                dataSource={(categories ?? []).map((e, index) => {
                  return {
                    key: `${e.id}`,
                    name: e.name ?? "--:--",
                    failed_number: e.failed_number,
                    data: e,
                    created_at: e?.created_at ?? "--:--",
                  };
                })}
                pagination={null}
              />
              <AddCategoryModal
                open={modal.visible && modal.method === "ADD"}
                dataInput={modal.data}
                onOk={() => {
                  getProbationCategories();
                  handleCloseModal();
                }}
                closeModal={() => {
                  handleCloseModal();
                }}
              />
            </div>
          </div>
        </div>
        <div
          tabIndex={0}
          role="presentation"
          style={{
            width: "0px",
            height: "0px",
            overflow: "hidden",
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
};

export default ConfigProbation;
