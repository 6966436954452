import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../components/SeachInput";
import { shiftServices } from "../../../services/shiftServices";
import { staffsServices } from "../../../services/staffsServices";
import { constants } from "../../../constants";
import { Link } from "react-router-dom";
import { SelectPicker } from "rsuite";
import Switch from "react-ios-switch";
import PermissionChecker from "../../../components/PermissionChecker";
import { Space, Table, Popconfirm, Image } from "antd";
import SeniorityFilterModal from "./child/SeniorityFilterModal";
import { TiDelete } from "react-icons/ti";
import { AiOutlineFilter, AiFillFilter } from "react-icons/ai";
import { reportSysServices } from "../../../services/reportSysServices";
import { toast } from "react-toastify";
import ExportExcelModal from "./child/ExportExcelModal";
import { readFileStaff } from "../../../helpers/sheet_staff";
import ImportExcelModal from "./child/ImportExcelModal";

const StaffPage = () => {
  const areaId = useSelector((state) => state.area.areaId);
  const departments = useSelector((state) => state.staff.department_list);

  const [filter, setFilter] = useState({
    departmentId: null,
    search: null,
    page: 1,
    leave: 0,
    probation: 0,
    seniority_from: null,
    seniority_to: null,
  });
  const [selectStaffs, setSelectStaffs] = useState([]);
  const [selectFilter, setSelectFilter] = useState(0);
  const [loading, setLoading] = useState(false);
  const fileElm = useRef(null);
  const onSubmitSearch = (value) => {
    fetchStaffs({
      reset: true,
      search: value,
      departmentId: filter.departmentId,
      page: 1,
      leave: filter.leave,
      probation: filter.probation,
      seniority_from: filter.seniority_from,
      seniority_to: filter.seniority_to,
    });
    setFilter({ ...filter, search: value });
  };
  const onChangeSearch = () => {};
  const [staffs, setStaffs] = useState([]);
  const [state, setState] = useState({
    modal: {
      componentAction: null,
      title: "",
      method: null,
    },
    delete_id: null,
  });
  const { modal } = state;
  const dispatch = useDispatch();
  const newUrl = new URL(window.location);
  const [badgeStaff, setBadgeStaff] = useState(null);

  const fetchStaffs = async ({
    reset,
    search,
    page,
    departmentId,
    leave,
    probation,
    seniority_from,
    seniority_to,
  }) => {
    setLoading(true);
    if (search != null) {
      newUrl.searchParams.set("search", search ?? "");
    }
    if (page != null) {
      newUrl.searchParams.set("page", page || 1);
    }
    if (departmentId != null) {
      newUrl.searchParams.set("departmentId", departmentId || "");
    }
    if (leave != null) {
      newUrl.searchParams.set("leave", leave || "");
    }
    if (probation != null) {
      newUrl.searchParams.set("probation", probation || "");
    }

    if (seniority_from != null) {
      newUrl.searchParams.set("seniority_from", seniority_from || "");
    }

    if (seniority_to != null) {
      newUrl.searchParams.set("seniority_to", seniority_to ?? "");
    }

    window.history.pushState({ path: newUrl.href }, "", newUrl.href);

    setFilter({
      ...filter,
      search: search,
      page: page ?? 1,
      departmentId:
        departmentId ?? newUrl.searchParams.get("departmentId") ?? "",
      leave: leave ?? newUrl.searchParams.get("leave") ?? 0,
      probation: probation ?? newUrl.searchParams.get("probation") ?? 0,
      seniority_from:
        seniority_from ?? newUrl.searchParams.get("seniority_from") ?? "",
      seniority_to:
        seniority_to ?? newUrl.searchParams.get("seniority_to") ?? "",
    });

    if (reset === true) {
      if (page != null) {
        newUrl.searchParams.set("page", page ?? 1);
      }
      window.history.pushState({ path: newUrl.href }, "", newUrl.href);
      setFilter((prevState) => ({ ...prevState, page: 1 }));
    }

    const searchPr = newUrl.searchParams.get("search") ?? search;
    const pagePr = newUrl.searchParams.get("page") || page;
    const departmentIdPr =
      newUrl.searchParams.get("departmentId") || departmentId;
    const leavePr = newUrl.searchParams.get("leave") || leave;
    const probationPr = newUrl.searchParams.get("probation") || probation;
    const seniorityFrom =
      newUrl.searchParams.get("seniority_from") || seniority_from;
    const seniorityTo = newUrl.searchParams.get("seniority_to") || seniority_to;

    const queryString = `?search=${searchPr || ""}&department_id=${
      departmentIdPr || ""
    }&page=${pagePr || ""}&leave=${leavePr || ""}&probation=${
      probationPr == 0 ? "" : probationPr || ""
    }&seniority_from=${seniorityFrom || ""}&seniority_to=${seniorityTo || ""}`;

    try {
      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.fetchStaffsManager({
          queryString,
        });
        setStaffs(response.data);
      } else {
        const response = await shiftServices.fetchStaffsTemp({
          areaId,
          queryString,
        });
        setStaffs(response.data);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const [modalData, setModalData] = useState({
    method: null,
    visible: false,
  });

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchStaffs({
      reset: true,
    });
  }, [areaId]);

  useEffect(() => {
    //fetchDepartment();
    // getNumberStaff();
    fetchAllAllRole();
  }, []);

  function handleDelete(id) {
    staffsServices.deleteMultiStaff({ user_id: [id] }).then((res) => {
      if (res.success == true) {
        window.$(".modal").modal("hide");
        fetchStaffs({
          search: filter.search,
          departmentId: filter.departmentId,
          page: filter.page,
          leave: filter.leave,
          probation: filter.probation,
          seniority_from: filter.seniority_from,
          seniority_to: filter.seniority_to,
        });
      } else {
        toast.error(res.msg);
      }
    });
  }

  const reportNumber = useSelector((state) => state.reportNumber.report);

  const getNumberStaff = async () => {
    try {
      const response = await reportSysServices.getNumberStaff(areaId);
      setBadgeStaff(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function handleDelMultiModal() {
    staffsServices.deleteMultiStaff({ user_id: selectStaffs }).then((res) => {
      if (res.success == true) {
        window.$(".modal").modal("hide");
        fetchStaffs({
          search: filter.search,
          departmentId: filter.departmentId,
          page: filter.page,
          leave: filter.leave,
          probation: filter.probation,
          seniority_from: filter.seniority_from,
          seniority_to: filter.seniority_to,
        });
      } else {
        toast.error(res.msg);
      }
    });

    setSelectStaffs([]);
  }

  const headerImport = [
    { value: "id", title: "ID" },
    { value: "staff_code", title: "Mã nhân viên" },
    { value: "name", title: "Họ và tên" },
    { value: "phone_number", title: "Số điện thoại" },
    { value: "email", title: "Email" },
    { value: "position", title: "Vị trí" },
    { value: "password", title: "Mật khẩu" },
    { value: "start_join_time", title: "Ngày vào làm" },
    { value: "sex", title: "Giới tính" },
    { value: "department_id", title: "Phòng ban" },
    { value: "area_id", title: "Chi nhánh" },
    { value: "role_id", title: "Phân quyền" },
    { value: "birthday", title: "Ngày sinh" },
    { value: "cmnd", title: "Số CMND/CCCD" },
    { value: "date_range", title: "Ngày cấp" },
    { value: "issued_by", title: "Nơi cấp" },
    { value: "permanent_address", title: "Địa chỉ thường trú" },
    { value: "temporary_address", title: "Địa chỉ tạm trú" },
    { value: "bank_number", title: "Số tài khoản" },
    { value: "bank_name", title: "Tên ngân hàng" },
    { value: "bank_account_name", title: "Tên chủ tài khoản" },
    { value: "bank_branch", title: "Chi nhánh ngân hàng" },
    { value: "school", title: "Trường học" },
    { value: "level", title: "Trình độ" },
    { value: "specialized", title: "Chuyên ngành" },
    { value: "course", title: "Khóa học" },
    { value: "height", title: "Chiều cao" },
    { value: "weight", title: "Cân nặng" },
    { value: "congenital", title: "Bệnh di truyền" },
    { value: "married", title: "Tình trạng hôn nhân" },
    { value: "nation", title: "Dân tộc" },
    { value: "religion", title: "Tôn giáo" },
    { value: "cmnd_front", title: "Ảnh mặt trước CMND" },
    { value: "cmnd_back_side", title: "Ảnh mặt sau CMND" },
    { value: "role_id", title: "Phân quyền" },
    /// contract
    { value: "type_key", title: "Loại hợp đồng" },
    { value: "start", title: "Ngày bắt đầu" },
    { value: "end", title: "Ngày kết thúc" },
    { value: "kpi_percent", title: "% KPI" },
    { value: "salary", title: "Lương theo giờ" },
    { value: "dependent_count", title: "Số người phụ thuộc" },
    { value: "dependent_another", title: "Khoản miễn thuế khác" },
    { value: "position_salary", title: "Lương vị trí (P1)" },
    { value: "support_productive", title: "Lương P2" },
    { value: "support_result", title: "Lương P3" },
    { value: "cong_doan", title: "Công đoàn" },
    { value: "pc_food", title: "PC ăn trưa" },
    { value: "pc_phone", title: "PC điện thoại" },
    { value: "pc_move", title: "PC di chuyển" },
    { value: "BHXH", title: "BHXH" },
    { value: "BHYT", title: "BHYT" },
    { value: "BHTN", title: "BHTN" },
    { value: "tax", title: "Thuế TNCN" },
    { value: "annual_leave", title: "Ngày phép năm" },
    { value: "unpaid_leave", title: "Ngày nghỉ không lương" },
    { value: "compassionate_leave", title: "Ngày nghỉ tang" },
    { value: "marriage_leave", title: "Ngày nghỉ kết hôn" },
    { value: "count_day_work", title: "Số ngày công" },
    { value: "leave", title: "Tình trạng hợp đồng" },
    { value: "probation", title: "Đang thử việc" },
    { value: "seniority_percent", title: "Phụ cấp thâm niên" },
    { value: "seniority_limit", title: "Hạn mức phụ cấp thâm niên" },
    { value: "image_url", title: "Hình ảnh hợp đồng" },
    { value: "supports", title: "Phụ cấp (ID)" },
  ];

  const fetchAllAllRole = async () => {
    try {
      const response = await shiftServices.getRole({ areaId: 31 });

      setRoles(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleOnSave(data) {
    try {
      var list_staffs = data;
      console.log("list_staffs", list_staffs);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      const response = await shiftServices.importExcel({ areaId, list_staffs });
      if (response.code === 200) {
        setModalData({
          method: null,
          visible: false,
        });
        fetchStaffs({
          search: filter.search,
          departmentId: filter.departmentId,
          page: filter.page,
          leave: filter.leave,
          probation: filter.probation,
          seniority_from: filter.seniority_from,
          seniority_to: filter.seniority_to,
        });
        toast.success("Thành công");
      } else {
        dispatch({
          type: constants.FAILURE,
          code: response.code,
          message: response.message,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  }

  function showDialogImportExcel() {
    fileElm.current.click();
  }

  async function onChangeExcel(evt) {
    const readData = await readFileStaff(evt);
    var multiCreate = [];
    for (const [index, element] of readData.entries()) {
      // console.log(element);
      var item = {};
      Object.entries(element).forEach(([_key, _value], _index) => {
         console.log(_key.trim());
        item = { ...item, [_key.trim()]: _value };
       
      });
      multiCreate.push({ ...item });
    }
    console.log("multiCreate", multiCreate);
    setModalData({
      method: "IMPORT",
      visible: true,
      data: multiCreate,
      header: headerImport,
    });
  }

  const fetchShift = async (type) => {
    var param = `?search=${filter?.search}&department_id=${
      filter?.department || ""
    }`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.employeeHaveShiftManager({
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.employeeHaveShift({
          areaId,
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const fetchNotTracking = async (type) => {
    var param = `?search=${filter?.search}&department_id=${
      filter?.department || ""
    }`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.fetchNotTrackingManager({
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.fetchNotTracking({
          areaId,
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const fetchTracking = async (type) => {
    var param = `?search=${filter?.search}&department_id=${
      filter?.department || ""
    }`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.employeeHaveTrackingManager({
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.employeeHaveTracking({
          areaId,
          param,
        });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };
  const fetchEarly = async (type) => {
    var param = `?search=${filter?.search}&department_id=${
      filter?.departmentId || ""
    }`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.employeeEarlyManager({ param });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.employeeEarly({ areaId, param });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const fetchLate = async (type) => {
    var param = `?search=${filter?.search}&department_id=${
      filter?.departmentId || ""
    }`;
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });

      if (areaId == constants.AREA_ALL_ID) {
        const response = await shiftServices.employeeLateManager({ param });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      } else {
        const response = await shiftServices.employeeLate({ areaId, param });
        setStaffs(response.data);
        dispatch({
          type: constants.CHANGE_LOADING,
          typeLoading: constants.NONE_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const areas = useSelector((state) => state.area.areas);
  const getAreaById = (areaId) => {
    var result = areas.data.filter((a) => a.id === areaId);

    if (result.length == 0) return;
    return result[0];
  };

  const columns = [
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      width: 400,
      key: "name",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            {
              <Image
                src={
                  (record?.avatar ?? "").includes("http") ? record.avatar : ""
                }
                height={35}
                width={35}
                style={{
                  borderRadius: "20%",
                  objectFit: "cover",
                  border: "1px solid #ccc",
                  marginRight: "16px",
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://static2.yan.vn/YanNews/2167221/202003/dan-mang-du-trend-thiet-ke-avatar-du-kieu-day-mau-sac-tu-anh-mac-dinh-b0de2bad.jpg";
                }}
              ></Image>
            }
            <div>
              {reportNumber.permissions?.staff_manager ?? false == true ? (
                <Link to={`/profile?key=${record.key}`}>
                  {
                    <div style={{ fontWeight: "bold", color: "black" }}>
                      {record.name}
                    </div>
                  }
                </Link>
              ) : (
                <div style={{ fontWeight: "bold", color: "black" }}>
                  {record.name}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Chi nhánh",
      key: "area_id",
      dataIndex: "area_id",
      width: 300,
      render: (areaId) => <a>{getAreaById(areaId)?.name ?? "--:--"}</a>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Thâm niên",
      key: "start_join_time",
      dataIndex: "start_join_time",
      render: (start_join_time) => {
        const joinDate = new Date(start_join_time);
        const currentDate = new Date();
        const timeDiff = currentDate - joinDate;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (daysDiff >= 365) {
          const yearsDiff = Math.floor(daysDiff / 365);
          const remainingDays = daysDiff % 365;
          return (
            <a>
              {yearsDiff} năm {Math.floor(remainingDays / 30)} tháng{" "}
              {remainingDays % 30} ngày
            </a>
          );
        } else {
          const months = Math.floor(daysDiff / 30); // Giả định một tháng là 30 ngày
          const remainingDays = daysDiff % 30;
          return (
            <a>
              {months} tháng {remainingDays} ngày
            </a>
          );
        }
      },
    },
    {
      title: "Vai trò",
      key: "position",
      dataIndex: "position",
      render: (position) => {
        return <a>{position}</a>;
      },
    },
    {
      title: "Hành động",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div class="dropdown">
            <button style={{ color: "green", fontWeight: "bold" }}>
              . . .
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {PermissionChecker({
                decent: reportNumber.permissions?.staff_edit ?? false,
                child: (
                  <Link
                    name="action"
                    to={`/staff/${record.key}?key=staff`}
                    className="btn-success btn-sm dropdown-item"
                  >
                    <i className="fas fa-edit" /> Sửa thông tin
                  </Link>
                ),
              })}
              {PermissionChecker({
                decent: reportNumber.permissions?.edit_contract ?? false,
                child: (
                  <Link
                    name="action"
                    to={`/contract/${record.key}?key=staff&area_id=${record.area_id}`}
                    className="btn-success btn-sm dropdown-item"
                  >
                    <i className="fas fa-edit" /> Sửa hợp đồng
                  </Link>
                ),
              })}
              {PermissionChecker({
                decent: reportNumber.permissions?.staff_delete ?? false,
                child: (
                  <Popconfirm
                    title="Xoá nhân viên ?"
                    description="Bạn có chắc chắn muốn xoá nhân viên này chứ ?"
                    onConfirm={() => {
                      handleDelete(record.key);
                    }}
                    onCancel={() => {}}
                    okText="Có"
                    cancelText="Không"
                  >
                    <a
                      data-toggle="modal"
                      data-target="#modalUID"
                      className="btn-danger btn-sm dropdown-item"
                    >
                      <i className="fa fa-trash" /> Xóa
                    </a>
                  </Popconfirm>
                ),
              })}
            </div>
          </div>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectStaffs(selectedRows.map((v) => v.key));
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div className="container-fluid">
      <input
        ref={fileElm}
        id="file-excel-import" 
        type="file"
        name="name"
        style={{ display: "none" }}
        onChange={onChangeExcel}
      />
      <div className="d-flex justify-content-between align-items-center py-2">
        <h4 className="h4 title_content mb-0 text-gray-800">Nhân viên</h4>{" "}
        <div className="d-flex">
          <div
            style={{
              width: "120px",
              textAlign: "center",
              lineHeight: "37px",
              backgroundColor: "#FDC089",
              borderRadius: "6px",
              cursor: "pointer",
              margin: "0px 20px",
              fontWeight: "bold",
              color: selectFilter === 1 ? "black" : "white",
              border: selectFilter === 1 ? "1px solid black" : "none",
            }}
            onClick={() => {
              fetchShift(true);
              setSelectFilter(1);
            }}
          >
            Có ca: {badgeStaff?.staff_working ?? 0}
          </div>
          <div
            style={{
              width: "120px",
              textAlign: "center",
              lineHeight: "37px",
              backgroundColor: "#e77f6a",
              borderRadius: "6px",
              cursor: "pointer",
              margin: "0px 20px",
              fontWeight: "bold",
              color: selectFilter === 5 ? "black" : "white",
              border: selectFilter === 5 ? "1px solid black" : "none",
            }}
            onClick={() => {
              fetchNotTracking(true);
              setSelectFilter(5);
            }}
          >
            Chưa vào: {badgeStaff?.staff_not_tracking ?? 0}
          </div>
          <div
            style={{
              width: "120px",
              textAlign: "center",
              lineHeight: "37px",
              backgroundColor: "#67C1BF",
              borderRadius: "6px",
              cursor: "pointer",
              margin: "0px 20px",
              fontWeight: "bold",
              color: selectFilter === 2 ? "black" : "white",
              border: selectFilter === 2 ? "1px solid black" : "none",
            }}
            onClick={() => {
              fetchTracking(true);
              setSelectFilter(2);
            }}
          >
            Chấm công: {badgeStaff?.staff_tracking ?? 0}
          </div>
          <div
            style={{
              width: "120px",
              textAlign: "center",
              lineHeight: "37px",
              backgroundColor: "#96DBAF",
              borderRadius: "6px",
              cursor: "pointer",
              margin: "0px 20px",
              fontWeight: "bold",
              color: selectFilter === 3 ? "black" : "white",
              border: selectFilter === 3 ? "1px solid black" : "none",
            }}
            onClick={() => {
              fetchLate(true);
              setSelectFilter(3);
            }}
          >
            Đi trễ: {badgeStaff?.staff_start_late ?? 0}
          </div>
          <div
            style={{
              width: "120px",
              textAlign: "center",
              lineHeight: "37px",
              backgroundColor: "#F4C2C3",
              borderRadius: "6px",
              cursor: "pointer",
              margin: "0px 20px",
              fontWeight: "bold",
              color: selectFilter === 4 ? "black" : "white",
              border: selectFilter === 4 ? "1px solid black" : "none",
            }}
            onClick={() => {
              fetchEarly(true);
              setSelectFilter(4);
            }}
          >
            Về sớm: {badgeStaff?.staff_end_early ?? 0}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <button
            // onClick={exportData}
            onClick={(v) => {
              setModalData({
                visible: true,
                method: "EXPORT",
              });
            }}
            style={{ marginRight: "10px" }}
            class={`btn btn-danger btn-icon-split btn-sm  `}
          >
            <span class="icon text-white-50">
              <i class="fas fa-file-export"></i>
            </span>
            <span style={{ color: "white" }} class="text">
              Export Excel
            </span>
          </button>
          <button
            onClick={showDialogImportExcel}
            style={{ marginRight: "10px" }}
            class={`btn btn-primary btn-icon-split btn-sm  `}
          >
            <span class="icon text-white-50">
              <i class="fas fa-file-import"></i>
            </span>
            <span style={{ color: "white" }} class="text">
              Import Excel
            </span>
          </button>
          {PermissionChecker({
            decent: reportNumber.permissions?.staff_add ?? false,
            child: (
              <Link
                className="btn btn-info btn-icon-split btn-sm show"
                to="/staff-contract?key=staff"
              >
                <span className="icon text-white-50">
                  <i className="fas fa-plus" />
                </span>
                <span className="text">Thêm nhân viên</span>
              </Link>
            ),
          })}
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-start align-items-center mt-3">
          <SearchInput
            placeHolder={"Tìm kiếm nhân viên"}
            onSubmitSearch={onSubmitSearch}
            onChange={onChangeSearch}
          ></SearchInput>
          <SelectPicker
            style={{ width: "180px" }}
            data={departments.data.map((v) => {
              return { label: v.name, value: v.id };
            })}
            onClean={() =>
              fetchStaffs({
                search: filter.search,
                departmentId: "",
                page: filter.page,
                leave: filter.leave,
                probation: filter.probation,
                seniority_from: filter.seniority_from,
                seniority_to: filter.seniority_to,
              })
            }
            placeholder="-- Chọn phòng ban --"
            block
            value={filter.departmentId}
            onChange={(value) => {
              fetchStaffs({
                search: filter.search,
                departmentId: value,
                page: filter.page,
                leave: filter.leave,
                probation: filter.probation,
                seniority_from: filter.seniority_from,
                seniority_to: filter.seniority_to,
              });
            }}
          />
          <div
            className="d-flex justify-content-between align-items-center "
            style={{
              width: "130px",
              marginLeft: "40px",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                fontWeight: "700",
              }}
            >
              Thử việc&nbsp;
            </div>
            <Switch
              checked={filter.probation == 0 ? false : true}
              onChange={(checked) => {
                if (filter.probation == 0) {
                  fetchStaffs({
                    search: filter.search,
                    departmentId: filter.departmentId,
                    page: filter.page,
                    leave: filter.leave,
                    probation: 1,
                    seniority_from: filter.seniority_from,
                    seniority_to: filter.seniority_to,
                  });
                } else {
                  fetchStaffs({
                    search: filter.search,
                    departmentId: filter.departmentId,
                    page: filter.page,
                    leave: filter.leave,
                    probation: 0,
                    seniority_from: filter.seniority_from,
                    seniority_to: filter.seniority_to,
                  });
                }
              }}
            />
          </div>
          <div
            className="d-flex justify-content-between align-items-center "
            style={{
              width: "130px",
              marginLeft: "40px",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                fontWeight: "700",
              }}
            >
              Nghỉ việc&nbsp;
            </div>
            <Switch
              checked={filter.leave == 0 ? false : true}
              onChange={(checked) => {
                if (filter.leave == 0) {
                  fetchStaffs({
                    search: filter.search,
                    departmentId: filter.departmentId,
                    page: filter.page,
                    leave: 1,
                    probation: filter.probation,
                    seniority_from: filter.seniority_from,
                    seniority_to: filter.seniority_to,
                  });
                } else {
                  fetchStaffs({
                    search: filter.search,
                    departmentId: filter.departmentId,
                    page: filter.page,
                    leave: 0,
                    probation: filter.probation,
                    seniority_from: filter.seniority_from,
                    seniority_to: filter.seniority_to,
                  });
                }
              }}
            />
          </div>
          <div className="d-flex ml-5 justify-content-center align-items-center">
            {(filter.seniority_from ?? "") != "" ? (
              <AiFillFilter size={18} color="rgb(75, 161, 159)" />
            ) : (
              <AiOutlineFilter size={19} />
            )}
            <span
              style={{
                fontWeight: "700",
                cursor: "pointer",
                marginLeft: "10px",
                marginRight: "10px",
                color:
                  (filter.seniority_from ?? "") != ""
                    ? "rgb(75, 161, 159)"
                    : "black",
              }}
              onClick={(e) => {
                setModalData({
                  visible: true,
                  method: "FILTER",
                  data: {
                    seniority_to: filter.seniority_to,
                    seniority_from: filter.seniority_from,
                  },
                });
              }}
            >
              {(filter.seniority_from ?? "") != ""
                ? "Đang lọc thâm niên"
                : "Lọc thâm niên"}
            </span>
          </div>
          {(filter.seniority_from ?? "") != "" ? (
            <span> {filter.seniority_from}</span>
          ) : (
            <></>
          )}
          {(filter.seniority_to ?? "") != "" ? (
            <span>{`- ${filter.seniority_to} ngày`}</span>
          ) : (
            <></>
          )}
          {(filter.seniority_from ?? "") != "" ? (
            <div
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={(e) => {
                fetchStaffs({
                  search: filter.search,
                  departmentId: filter.departmentId,
                  page: filter.page,
                  leave: filter.leave,
                  probation: filter.probation,
                  seniority_from: "",
                  seniority_to: "",
                });
              }}
            >
              <TiDelete size={20} color="red" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="card-body">
          {selectStaffs.length > 0 &&
            PermissionChecker({
              decent: reportNumber.permissions?.staff_delete ?? false,
              child: (
                <Popconfirm
                  title="Xoá nhân viên ?"
                  description="Bạn có chắc chắn muốn xoá các nhân viên đã chọn chứ ?"
                  onConfirm={() => {
                    handleDelMultiModal();
                  }}
                  onCancel={() => {}}
                  okText="Có"
                  cancelText="Không"
                >
                  <button
                    style={{ marginBottom: "10px" }}
                    className="btn btn-danger btn-icon-split btn-sm show"
                    data-toggle="modal"
                    data-target="#modalUID"
                  >
                    <span className="icon text-white-50">
                      <i className="fas fa-remove" />
                    </span>
                    <span className="text">Xóa nhân viên</span>
                  </button>
                </Popconfirm>
              ),
            })}
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            loading={loading}
            columns={columns}
            dataSource={(staffs?.data ?? []).map((e, index) => {
              return {
                key: `${e.id}`,
                avatar: `${e.avatar}`,
                name: e.name ?? "--:--",
                area_id: e.area_id ?? "--:--",
                phone_number: e.phone_number ?? "--:--",
                start_join_time: e.start_join_time ?? "--:--",
                position: e.position ?? "--:--",
              };
            })}
            pagination={{
              total: staffs?.total ?? 0,
              pageSize: 20,
            }}
            onChange={(e) => {
              fetchStaffs({
                search: filter.search,
                departmentId: filter.departmentId,
                page: e.current,
                leave: filter.leave,
                probation: filter.probation,
                seniority_from: filter.seniority_from,
                seniority_to: filter.seniority_to,
              });
            }}
          />
        </div>
      </div>
      <SeniorityFilterModal
        open={modalData.visible && modalData.method === "FILTER"}
        onOk={(from, to) => {
          fetchStaffs({
            search: filter.search,
            departmentId: filter.departmentId,
            page: filter.current,
            leave: filter.leave,
            probation: filter.probation,
            seniority_from: from,
            seniority_to: to,
          });
          setModalData({
            visible: false,
            method: "",
          });
        }}
        dataInput={modalData.data}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      />
      <ExportExcelModal
        open={modalData.visible && modalData.method === "EXPORT"}
        onSuccess={(data) => {}}
        roles={roles}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></ExportExcelModal>
      <ImportExcelModal
        open={modalData.visible && modalData.method === "IMPORT"}
        onSuccess={(data) => {
          console.log("data", data);
            handleOnSave(data);
        }}
        roles={roles}
        data={modalData.data}
        header={headerImport}
        closeModal={() => {
          setModalData({
            visible: false,
            method: "",
          });
        }}
      ></ImportExcelModal>
    </div>
  );
};

export default StaffPage;
