import { Button, Divider, Dropdown, Popover, Space, Tabs } from "antd";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import React, { useState } from "react";
import ReactGiphySearchbox from "react-giphy-searchbox";
import vi from "@emoji-mart/data/i18n/vi.json";
import facebook from "@emoji-mart/data/sets/14/facebook.json";
import styled from "styled-components";
import { EmojiIcon } from "../../icons";

const StyledEmojiGifBox = styled.div`
  .reactGiphySearchbox-listWrapper {
    height: 369px !important;
    width: 348px !important;
    ::-webkit-scrollbar {
      border-radius: 10px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d1e3f9;
      opacity: 0.5;
    }
  }
`;

export default function EmotionGif({ handleSelectEmoji, onSendGif }) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = () => {
    setOpen(true);
  };

  function togglePopover() {
    setVisible(!visible);
  }

  const onSelectEmoji = (emoji) => {
    handleSelectEmoji(emoji);
  };

  const handleSelectGif = (gif) => {
    onSendGif(gif.images.original.url);
  };

  const itemsTabs = [
    {
      key: "1",
      label: <div style={{ padding: "0 20px", fontWeight: "600" }}>Emoji</div>,
      children: (
        <Picker
          data={data}
          onEmojiSelect={onSelectEmoji}
          locale={vi}
          set={facebook}
          previewPosition={"none"}
        />
      ),
    },
    {
      key: "2",
      label: <div style={{ padding: "0 20px", fontWeight: "600" }}>Gif</div>,
      children: (
        <StyledEmojiGifBox>
          <ReactGiphySearchbox
            apiKey="8DWNKp30johOVCrIgrMXo4HyNbssnh4c"
            onSelect={(item) => handleSelectGif(item)}
            masonryConfig={[
              { columns: 2, imageWidth: 110, gutter: 5 },
              { mq: "700px", columns: 3, imageWidth: 110, gutter: 5 },
            ]}
            style={{ height: "513px" }}
          />
        </StyledEmojiGifBox>
      ),
    },
  ];

  const renderLabel = <Tabs defaultActiveKey="1" centered items={itemsTabs} />;

  const items = [
    {
      label: renderLabel,
      key: "0",
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={{ background: "white" }}>
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: 8 }}>{renderLabel}</Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <div style={{cursor: "pointer"}}>
        <EmojiIcon style={{width: "20px", height: "20px"}}/>
        </div>
      </a>
    </Dropdown>
  );
}
