import { constants as c } from "../constants";
const initialState = {
   currentPopup: c.NO_POPUP,
   messageInfo: null,
   loadingIcon: c.NONE_LOADING,
   badges : {
      status : c.NONE,
   }
};
export function app(state = initialState, action) {
   switch (action.type) {
      case c.UID_SUCCESS:
      case c.UID_ERROR:
         return {
            ...state,
            currentPopup: c.UID_POPUP,
            messageInfo: action.info,
         };
      case c.CHANGE_LOADING:
         return {
            ...state,
            loadingIcon: action.typeLoading,
         };
      case c.CHANGE_POPUP:
         return {
            ...state,
            currentPopup: action.popupType,
         };
      case c.NOT_FOUND:
         window.location.href = `/${action.code}?code=${action.code}&msg=${action.msg}`;
         break;
      case c.USER_NOT_AUTHENTICATION: {
         break;
      }
      case c.GET_BADGES_SUCCESS:
         return {
           ...state,
           badges: {
             ...action.data,
             status: c.SUCCESS,
           },
         };
       case c.GET_BADGES_FAILURE:
         return {
           ...state,
           badges: {
             status: c.FAILURE,
           },
         };
      default:
         return state;
   }
}
