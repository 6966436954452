/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { DatePicker } from "rsuite";
import { convertNumberToDateStr, formatDate } from "../../../../helpers";
import { Modal } from "antd";
import { shiftServices } from "../../../../services/shiftServices";

const FORMAT_DATE = "HH:mm";
const dateOfWeeksDefault = [
  {
    label: "Thứ 2",
    value: 2,
    active: false,
  },
  {
    label: "Thứ 3",
    value: 3,
    active: false,
  },
  {
    label: "Thứ 4",
    value: 4,
    active: false,
  },
  {
    label: "Thứ 5",
    value: 5,
    active: false,
  },
  {
    label: "Thứ 6",
    value: 6,
    active: false,
  },
  {
    label: "Thứ 7",
    value: 7,
    active: false,
  },
  {
    label: "Chủ nhật",
    value: 8,
    active: false,
  },
];

const WorkShiftModal = ({
  visible,
  title,
  shiftDetail,
  onSubmit,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const [dateOfWeeks, setDateOfWeeks] = useState(dateOfWeeksDefault);
  const [checkAll, setCheckAll] = useState(false);
  const [shiftGroups, setShiftGroups] = useState([]);
  const [shiftGroupIds, setShiftGroupIds] = useState([]);

  useEffect(() => {
    if (visible) {
      getShiftGroups();
      reset(shiftDetail);
      handleActiveDateOfWeek(shiftDetail?.days_of_week_list);
      setShiftGroupIds(shiftDetail?.shift_group_ids ?? []);
    } else {
      reset();
    }
  }, [shiftDetail, visible]);

  const convertDateOfWeekOfShift = (dateOfWeeks) => {
    let daysOfWeekList = [];
    dateOfWeeks.forEach((item) => {
      if (item.active) {
        daysOfWeekList.push(item.value);
      }
    });

    return daysOfWeekList;
  };

  const getShiftGroups = async () => {
    try {
      const res = await shiftServices.getShiftGroups();
      setShiftGroups(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleActiveDateOfWeek = (dateOfWeekList) => {
    if (Array.isArray(dateOfWeekList)) {
      const data = dateOfWeeks.map((item) => {
        const index = dateOfWeekList.findIndex((value) => value === item.value);
        return {
          ...item,
          active: index !== -1,
        };
      });

      setDateOfWeeks([...data]);
    } else {
      setCheckAll(false);
      setDateOfWeeks(dateOfWeeksDefault);
    }
  };

  const convertNumberToDate = (start, end) => {
    if (
      start !== undefined &&
      start !== null &&
      end !== undefined &&
      end !== null
    ) {
      const dateStr = convertNumberToDateStr(start, end);
      return moment(dateStr, "HH:mm").toDate();
    }

    return null;
  };

  const convertDateToNumber = (date) => {
    const dateMoment = moment(date);

    return {
      hours: dateMoment.get("hours"),
      minute: dateMoment.get("minutes"),
    };
  };

  const handleCheckAllDate = (event) => {
    if (event.target.checked) {
      setDateOfWeeks(dateOfWeeks.map((item) => ({ ...item, active: true })));
    } else {
      setDateOfWeeks(dateOfWeeks.map((item) => ({ ...item, active: false })));
    }

    setCheckAll(event.target.checked);
  };

  const setStyleValidate = (name) =>
    errors[name] ? { border: "1px solid red" } : null;

  const handleOk = () => { 

    const daysOfWeek = convertDateOfWeekOfShift(dateOfWeeks);
    setCheckAll(dateOfWeeks.every((value) => value.active));

    reset({
      ...getValues(),
      days_of_week_list: daysOfWeek,
      days_of_week: daysOfWeek,
      shift_group_ids: shiftGroupIds,
    });

    onSubmit(getValues());
  }

  return (
    <Modal
      title={title}
      centered
      open={visible}
      width={900}
      style={{ top: 0 }}
      onOk={() => {
        handleOk();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <div
        style={{
          overflowY: "auto",
          height: "auto",
          maxHeight: "75vh",
          margin: "10px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="form-group">
              <label>
                Tên ca <span className="text-danger">*</span>
              </label>
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Nhập tên ca"
                autoComplete="off"
                style={setStyleValidate("name")}
                {...register("name", {
                  required: true,
                })}
              />
              {errors?.name?.type === "required" && (
                <small className="text-danger">Vui lòng nhập tên ca</small>
              )}
            </div>
            <div className="form-group">
              <label>Thời gian ca</label>
              <div className="form-group">
                <DatePicker
                  value={convertNumberToDate(
                    getValues("start_work_hour"),
                    getValues("start_work_minute")
                  )}
                  format="HH:mm"
                  cleanable={false}
                  className="time-picker"
                  onChange={(val) => {
                    const startAndEndTime = convertDateToNumber(val);
                    reset({
                      ...getValues(),
                      start_work_hour: startAndEndTime.hours,
                      start_work_minute: startAndEndTime.minute,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <DatePicker
                  value={convertNumberToDate(
                    getValues("end_work_hour"),
                    getValues("end_work_minute")
                  )}
                  format="HH:mm"
                  cleanable={false}
                  className="time-picker"
                  onChange={(val) => {
                    const startAndEndTime = convertDateToNumber(val);
                    reset({
                      ...getValues(),
                      end_work_hour: startAndEndTime.hours,
                      end_work_minute: startAndEndTime.minute,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Thời gian nghỉ giữa ca</label>
              <div className="form-group">
                <DatePicker
                  value={convertNumberToDate(
                    getValues("start_break_hour"),
                    getValues("start_break_minute")
                  )}
                  format="HH:mm"
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(val) => {
                    const startAndEndTime = convertDateToNumber(val);
                    reset({
                      ...getValues(),
                      start_break_hour: startAndEndTime.hours,
                      start_break_minute: startAndEndTime.minute,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <DatePicker
                  value={convertNumberToDate(
                    getValues("end_break_hour"),
                    getValues("end_break_minute")
                  )}
                  format="HH:mm"
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(val) => {
                    const startAndEndTime = convertDateToNumber(val);
                    reset({
                      ...getValues(),
                      end_break_hour: startAndEndTime.hours,
                      end_break_minute: startAndEndTime.minute,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Thời gian cho phép chấm công</label>
              <div className="form-group">
                <DatePicker
                  value={
                    getValues("access_tracking_start")
                      ? moment(
                          getValues("access_tracking_start"),
                          "HH:mm"
                        ).toDate()
                      : null
                  }
                  format="HH:mm"
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(val) => {
                    reset({
                      ...getValues(),
                      access_tracking_start: formatDate(val, FORMAT_DATE),
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <DatePicker
                  value={
                    getValues("access_tracking_end")
                      ? moment(
                          getValues("access_tracking_end"),
                          "HH:mm"
                        ).toDate()
                      : null
                  }
                  format="HH:mm"
                  cleanable={false}
                  placement="topStart"
                  className="time-picker"
                  onChange={(val) => {
                    reset({
                      ...getValues(),
                      access_tracking_end: formatDate(val, FORMAT_DATE),
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group scale-input">
              <label>
                Tiền lương theo tháng <span className="text-danger">*</span>
              </label>
              <input
                name="scale"
                type="number"
                className="form-control"
                placeholder="Nhập tiền lương theo tháng"
                autoComplete="off"
                style={setStyleValidate("scale")}
                {...register("scale", {
                  required: true,
                })}
              />
              <span className="scale-input-text">VNĐ/tháng</span>
              {errors?.scale?.type === "required" && (
                <small className="text-danger">
                  Vui lòng nhập lương theo tháng
                </small>
              )}
            </div>
            <div className="form-group d-flex justify-content-between">
              <label>Ngày trong tuần:</label>
              <div>
                <input
                  type="checkbox"
                  checked={checkAll}
                  onChange={handleCheckAllDate}
                />
                <label className="ml-1">Tất cả</label>
              </div>
            </div>
            <div className="form-group d-flex flex-wrap">
              {dateOfWeeks.map((item, index) => (
                <div
                  key={index}
                  className='p-2 text-white mx-1 date-of-week'
                  style={{
                    backgroundColor: item.active == true
                      ? "#ebb01a"
                      : "#cfcecb",
                  }}
                  onClick={() => {
                    setDateOfWeeks((values) => {
                      values[index].active = !values[index].active;
                      return [...values];
                    });
                  }}
                >
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
            <div className="form-group scale-input">
              <label>Chọn nhóm ca làm việc:</label>
            </div>
            <div className="form-group d-flex flex-wrap">
              {(shiftGroups ?? []).map((item, index) => (
                <div
                  key={index}
                  className="p-2 text-white mx-1 date-of-week"
                  style={{
                    backgroundColor: shiftGroupIds.includes(item.id)
                      ? "#ebb01a"
                      : "#cfcecb",
                  }}
                  onClick={() => {
                    if (shiftGroupIds.includes(item.id)) {
                      setShiftGroupIds(
                        shiftGroupIds.filter((value) => value !== item.id)
                      );
                    } else {
                      setShiftGroupIds((values) => [...values, item.id]);
                    }
                  }}
                >
                  <span>{item?.name ?? ""}</span>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default WorkShiftModal;
