import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Divider, Modal, Row, Select, Space } from "antd";
import { useSelector } from "react-redux";
import { constants } from "../../../constants";
import { toast } from "react-toastify";
import { probationServices } from "../../../services/probationServices";

const OverrideProcedureModal = ({
  visible,
  dataInput,
  processInput,
  procedureId,
  closeModal,
}) => {
  const { Option } = Select;

  const [listTo, setListTo] = useState([]);

  const [areaSelected, setAreaSelected] = useState(null);

  const [listToChoose, setListToChoose] = useState([]);

  const areas = useSelector((state) => state.area.areas);

  useEffect(() => {
    setListToChoose([]);
    setListTo([]);
    if (dataInput?.listTo !== null) {
      setListTo(dataInput?.listTo);
      console.log(dataInput?.listTo);
      console.log(dataInput?.listFrom);

      setListToChoose(
        (dataInput?.listFrom ?? []).map((e) => {
          return null;
        })
      );
    }
  }, [visible]);

  const updateStaffApprove = async (staff_from, staff_to) => {
    if (staff_to == null || staff_from == null) return;

    const data = {
      staff_from: staff_from,
      staff_to: staff_to,
      area_id: areaSelected,
      process_id: processInput.id,
    };

    console.log(areaSelected);
    console.log(procedureId);
    console.log(data);

    try {
      const response = await probationServices.updateStaffApprove({
        procedureId: procedureId,
        data: data,
      });
    } catch (error) {}
  };

  return (
    <Modal
      title={
        "Chuyển đổi người duyệt quy trình cho các phiếu chưa được duyệt tại bước duyệt"
      }
      centered
      open={visible}
      width={"auto"}
      style={{ top: 20 }}
      onCancel={() => {
        closeModal();
      }}
      onOk={async () => {
        console.log(dataInput?.listFrom ?? []);
        console.log(listToChoose);

        await Promise.all(
          (dataInput?.listFrom ?? []).map((e, index) => {
            return updateStaffApprove(
              e.value,
              index > listToChoose.length - 1 ? null : listToChoose[index]
            );
          })
        );
        toast.success("Cập nhật thành công");
        closeModal();
      }}
    >
      <div style={{ overflowY: "auto", height: "100%", margin: "20px" }}>
        <div className="d-flex justify-content-start align-items-center my-3">
          <p className="mx-3">Chi nhánh áp dụng:</p>
          <Select
            placeholder="Chọn chi nhánh áp dụng"
            onChange={(value) => {
              setAreaSelected(value);
            }}
            value={areaSelected}
            allowClear
          >
            {(areas?.data ?? [])
              .filter((e) => {
                return e.id !== constants.AREA_ALL_ID;
              })
              ?.map((e) => {
                return <Option value={e.id}>{e.name}</Option>;
              })}
          </Select>
        </div>
        <Divider />
        {(dataInput?.listFrom ?? []).map((e, index) => {
          return (
            <div
              key={index}
              className="d-flex justify-content-center align-items-center my-3"
            >
              <p className="col-3">{e.label ?? "--:--"}</p>
              <div
                style={{
                  width: "300px",
                  backgroundColor: "red",
                  height: "1px",
                }}
              ></div>
              <div style={{ color: "red" }}>{`>`}</div>
              <div className="col-4">
                <Select
                  placeholder="Chọn người duyệt"
                  value={
                    listToChoose[index] != null
                      ? {
                          value: listToChoose[index],
                          label:
                            listTo?.find((e) => {
                              return e?.value == listToChoose[index];
                            })?.label ?? "Chọn người duyệt",
                        }
                      : null
                  }
                  onChange={(value) => {
                    // cập nhật giá trị vào listToChoose tại vị trí index

                    const newListToChoose = [...listToChoose];

                    newListToChoose[index] = value;

                    setListToChoose(newListToChoose);
                  }}
                  allowClear
                >
                  {(listTo ?? [])
                    .filter((e) => !(listToChoose ?? []).includes(e.value))
                    ?.map((e) => {
                      return <Option value={e.value}>{e.label}</Option>;
                    })}
                </Select>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default OverrideProcedureModal;
