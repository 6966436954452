import React, { useState, useEffect, PureComponent } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../constants";
import { procedureServices } from "../../../services/procedureServices";
import ViewDate from "../../../components/ViewDate";
import moment from "moment";
import { Spin } from "antd";
import {
  BsFillPersonLinesFill,
  BsPersonWorkspace,
  BsCaretUpSquareFill,
} from "react-icons/bs";
import { MdTimeToLeave } from "react-icons/md";
import { FaBusinessTime, FaGift, FaMoneyBill } from "react-icons/fa";
import { RiTimerFlashFill } from "react-icons/ri";
import { SiMoneygram } from "react-icons/si";
import { BiSolidDislike } from "react-icons/bi";
import { reportSysServices } from "../../../services/reportSysServices";
import { formatMoneyVND, secondsToHms } from "../../../helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DatePickerModal from "./child/DatePickerModal";
import ChartDetailModal from "./child/ChartDetailModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReportPersonnelPage = () => {
  const dispatch = useDispatch();
  const areaId = useSelector((state) => state.area.areaId);

  useEffect(() => {
    getStaffSubOverview();
    getStaffByArea();
    getNumberStaff();
  }, [areaId]);

  const [modal, setModal] = useState({
    method: null,
    visible: false,
  });

  const [dataChart, setDataChart] = useState(null);

  const [dataSub, setDataSub] = useState({});
  const [badgeStaff, setBadgeStaff] = useState({});
  const [loadingBadge, setLoadingBadge] = useState(false);

  const getStaffSubOverview = async () => {
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await reportSysServices.getStaffSubOverview({
        param: `?area_id=${areaId === constants.AREA_ALL_ID ? "" : areaId}`,
      });
      setDataSub(response.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const getNumberStaff = async () => {
    setLoadingBadge(true);
    try {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.SHOW_LOADING,
      });
      const response = await reportSysServices.getNumberStaff(areaId);
      setBadgeStaff(response.data);
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
    setLoadingBadge(false);
  };

  const getStaffByArea = async () => {
    try {
      const response = await reportSysServices.getStaffByArea();

      var labels = (response?.data ?? []).map((item) => item.name ?? "--:--");
      var dataStaff = (response?.data ?? []).map(
        (item) => item.total_staff_count ?? 0
      );

      console.log(labels, dataStaff);

      setDataChart({
        labels,
        datasets: [
          {
            label: "Tổng số nhân viên",
            data: dataStaff,
            backgroundColor: "#96DBAF",
          },
        ],
      });
    } catch (error) {
      dispatch({
        type: constants.CHANGE_LOADING,
        typeLoading: constants.NONE_LOADING,
      });
    }
  };

  const itemData = (title, color, icon, data) => {
    return (
      <div
        className="row mb-4 ml-0 align-items-center"
        style={{
          height: "80px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          borderLeftColor: color,
          borderLeftWidth: "5px",
        }}
      >
        <div className="col d-flex flex-column justify-content-around py-1">
          <div style={{ fontSize: "14px", fontWeight: "700", color: color }}>
            {`${title}`}
          </div>
          <div style={{ fontSize: "18px", fontWeight: "700" }}>{data ?? 0}</div>
          <div>Tháng này</div>
        </div>
        <div className="pr-2">{icon}</div>
      </div>
    );
  };

  const itemStaff = (title, color, number) => {
    return (
      <div
        className="row mb-4 ml-0 align-items-center"
        style={{
          height: "120px",
          backgroundColor: color,
          borderRadius: "10px",
        }}
      >
        <div className="col d-flex flex-column justify-content-around py-1">
          <div style={{ fontSize: "17px", fontWeight: "700", color: "black" }}>
            {`${title}`}
          </div>
          {loadingBadge ? (
            <div
              style={{
                height: "43px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div
              style={{ fontSize: "30px", fontWeight: "700", color: "white" }}
            >
              {number ?? 0}
            </div>
          )}
          <div style={{ fontSize: "14px", fontWeight: "700", color: "white" }}>
            Nhân viên
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setModal({ ...modal, title: "", method: "", visible: false });
  };

  const options = {
    responsive: true,
    aspectRatio: 1.7,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tổng số nhân viên toàn hệ thống",
      },
    },
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <h4 className="h4 title_content mb-0 text-gray-800">
          Thống kê nhân sự
        </h4>
      </div>
      <div className="mb-2 pt-4">
        <div className="row">
          <div className="col-4">
            <div>
              <div className="row">
                <div className="col-4">
                  <div
                    className="row mb-4 ml-0 align-items-center"
                    style={{ height: "120px", borderRadius: "10px" }}
                  >
                    <div className="col d-flex flex-column justify-content-around py-1">
                      <div
                        style={{
                          fontSize: "25px",
                          fontWeight: "700",
                          color: "black",
                        }}
                      >
                        Hôm nay:
                      </div>
                      <span style={{ fontWeight: "700", fontSize: 20 }}>
                        {" "}
                        {moment().format("DD/MM/YYYY")}
                      </span>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        Nhân viên
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  {itemStaff("Có ca", "#febf89", badgeStaff.staff_working)}
                </div>
                <div className="col-4">
                  {itemStaff(
                    "Chưa vào",
                    "#e77f6a",
                    badgeStaff.staff_not_tracking
                  )}
                </div>
                <div className="col-4">
                  {itemStaff("Chấm công", "#96DBAF", badgeStaff.staff_tracking)}
                </div>
                <div className="col-4">
                  {itemStaff("Đi trễ", "#67C1BF", badgeStaff.staff_start_late)}
                </div>
                <div className="col-4">
                  {itemStaff("Về sớm", "#F4C2C3", badgeStaff.staff_end_early)}
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.NEW_STAFF,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Nhân viên mới",
                  "#67C1BF",
                  <BsFillPersonLinesFill size={30} color="#c1bfbf" />,
                  dataSub?.new_staff
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.PROBATION,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Thử việc",
                  "#96DBAF",
                  <BsPersonWorkspace size={30} color="#c1bfbf" />,
                  dataSub?.probation_staff
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.LEAVE_STAFF,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Nghỉ việc",
                  "#e77f6a",
                  <MdTimeToLeave size={30} color="#c1bfbf" />,
                  dataSub?.leave_staff
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.WORKING_HOURS,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Tổng số giờ làm việc",
                  "#57b795",
                  <FaBusinessTime size={30} color="#c1bfbf" />,
                  secondsToHms(dataSub?.total_hour_work ?? 0)
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.OVERTIME,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Tổng Số giờ làm thêm",
                  "#ca9a0a",
                  <RiTimerFlashFill size={30} color="#c1bfbf" />,
                  secondsToHms(dataSub?.total_hour_overtime ?? 0)
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.REWARD,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Số NV đề xuất khen thưởng",
                  "#14d0c6",
                  <FaGift size={30} color="#c1bfbf" />,
                  dataSub?.total_staff_reward_proposal
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.ADJUST_SALARY,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Số nhân viên điều chỉnh lương",
                  "#4642ba",
                  <SiMoneygram size={30} color="#c1bfbf" />,
                  dataSub?.total_staff_adjust_salary
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.ADVANCEMENT,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Số nhân viên thăng tiến",
                  "#ce7c38",
                  <BsCaretUpSquareFill size={30} color="#c1bfbf" />,
                  dataSub?.total_staff_promote
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.DISCIPLINE,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Số nhân viên bị kỷ luật",
                  "#b33535",
                  <BiSolidDislike size={30} color="#c1bfbf" />,
                  dataSub?.total_staff_discipline
                )}
              </div>
              <div
                className="col-6"
                onClick={() => {
                  setModal({
                    method: "DEATAIL",
                    type: constants.WORKING_MONEY,
                    visible: true,
                  });
                }}
              >
                {itemData(
                  "Số tiền lương thực tế",
                  "#98c147",
                  <FaMoneyBill size={30} color="#c1bfbf" />,
                  formatMoneyVND(dataSub?.total_actual_salary ?? 0, 2)
                )}
              </div>
            </div>
          </div>
          <div className="col-8">
            {dataChart === null ? (
              <></>
            ) : (
              <Bar options={options} data={dataChart} />
            )}
          </div>
        </div>
      </div>
      <ChartDetailModal
        open={modal.visible && modal.method === "DEATAIL"}
        type={modal.type}
        closeModal={() => {
          handleCloseModal();
        }}
      ></ChartDetailModal>
    </div>
  );
};

export default ReportPersonnelPage;
